import * as React from "react";
import { PropsWithChildren } from "react";
import { selectCurrentSubscription, selectCurrentUser, selectLedgers, setLedgers } from "shared/state/store";
import { ILedgerEditorModal, LedgerEditorModal } from "@web/modals/ledger_editor_modal/LedgerEditorModal";
import { useIntlFormatters } from "shared/utils/formatters";
import { useUpdateLedgerMutation } from "shared/state/endpoints/app/ledgers_api";
import { BLANK_LEDGER, FEATURE_LEVEL, Ledger, ROLE, User } from "shared/utils/api_types";
import { MSG_helpCenterTitle } from "shared/strings/help";
import { MSG_settingsTitle } from "shared/strings/settings";
import { MSG_getStartedTitle } from "shared/strings/get_started";
import { FreePlanNoticePopover } from "@web/popovers/locked_envelope_popover/FreePlanNoticePopover";
import { StyledWindowContainer } from "@web/components/styled/StyledWindowContainer";
import { LinkButton } from "@web/components/LinkButton";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-light-svg-icons/faLock";
import { faPartyHorn } from "@fortawesome/pro-light-svg-icons/faPartyHorn";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { faScrewdriverWrench } from "@fortawesome/pro-light-svg-icons/faScrewdriverWrench";
import { faSparkles } from "@fortawesome/pro-light-svg-icons/faSparkles";
import { useDispatch, useSelector } from "react-redux";
import { NestedNavLink } from "@web/components/shared/NestedNavLink";
import { MSG_whatsNewTitle } from "shared/strings/generic";
import { useNavigate } from "react-router";
import { faSquareDown, faSquareUp } from "@fortawesome/pro-solid-svg-icons";
import { MSG_newLedgerButton } from "shared/strings/ledgers";

interface IProps {}

const AuthenticatedBody: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const subscription = useSelector(selectCurrentSubscription)!;
  const ledgers = useSelector(selectLedgers).filter(l => !l.archived);
  const ledgerEditorModalRef = React.useRef<ILedgerEditorModal>(null);
  const {formatMessage, formatCurrency} = useIntlFormatters();
  const dispatch = useDispatch();
  const [updateAccount] = useUpdateLedgerMutation();
  const freePlanNoticePopoverRef = React.useRef<any>(null);
  const freePlanNoticeTargetRef = React.useRef<any>(null);
  const currentUser = useSelector(selectCurrentUser) as User
  const navigate = useNavigate();

  async function savePositions(newLedgers: Ledger[], e: React.MouseEvent) {
    if (currentUser.role === ROLE.OBSERVER) return;

    let _ledgers = [...newLedgers];
    _ledgers.sort((a, b) => {
      if (a === null || b === null) return 0;
      if ((a.sequenceNumber || 0) < (b.sequenceNumber || 0)) return -1;
      if ((a.sequenceNumber || 0) > (b.sequenceNumber || 0)) return 1;
      return 0;
    });
    _ledgers = _ledgers.map((a, i) => {
      a.sequenceNumber = i;
      return a;
    });
    dispatch(setLedgers(_ledgers));
    e.stopPropagation();
    e.preventDefault();

    _ledgers.forEach((a, i) => {
      if (ledgers[i].id !== a.id) {
        void updateAccount({ledger: {id: a.id, sequenceNumber: i}});
      }
    });
  }

  function moveUp(e: React.MouseEvent, pos: number) {
    void savePositions(ledgers.map((a, i) => {
      let newPos = i;
      if (i === pos) newPos = i - 1;
      else if (i === pos - 1) newPos = i + 1;
      return {...a, sequenceNumber: newPos};
    }), e);
  }

  function moveDown(e: React.MouseEvent, pos: number) {
    void savePositions(ledgers.map((a, i) => {
      let newPos = i;
      if (i === pos) newPos = i + 1;
      else if (i === pos + 1) newPos = i - 1;
      return {...a, sequenceNumber: newPos};
    }), e);
  }

  const newItems = [];

  return (
    <React.Fragment>
      <div>
        <OuterSidebarContainer>

          {currentUser?.flags?.getStartedVisible && (
            <NestedNavLink className="tab" to="get_started" rootPath="/app/get_started">
              <Icon><FontAwesomeIcon icon={faPartyHorn}/></Icon>
              <span className="main-label text-bold">{formatMessage(MSG_getStartedTitle)}</span>
            </NestedNavLink>)}
          {newItems.length > 0 && (
            <NestedNavLink className="tab" to="news" rootPath="/app/news">
              <Icon><FontAwesomeIcon icon={faSparkles}/></Icon>
              <span className="main-label">{formatMessage(MSG_whatsNewTitle)}</span>
              <NavBadge>1</NavBadge>
            </NestedNavLink>)}
          <NestedNavLink className="tab" to="help_center/articles" rootPath="/app/help_center">
            <Icon><FontAwesomeIcon icon={faQuestionCircle}/></Icon>
            <span className="main-label">{formatMessage(MSG_helpCenterTitle)}</span>
          </NestedNavLink>
          <NestedNavLink className="tab" to="settings/appearance" rootPath="/app/settings">
            <Icon><FontAwesomeIcon icon={faScrewdriverWrench}/></Icon>
            <span className="main-label">{formatMessage(MSG_settingsTitle)}</span>
          </NestedNavLink>

          <br/>

          {ledgers.map((ledger, i) => {
            const locked = subscription?.featureLevel === FEATURE_LEVEL.FREE && ledgers.length >= 1 && i >= 1;
            const rootPath = `/app/ledgers/${ledger.id}`;
            const active = location.pathname.startsWith(rootPath);
            return (
              <NestedNavLink key={ledger.id}
                             className="tab"
                             to={`ledgers/${ledger.id}/envelopes`}
                             rootPath={rootPath}
                             style={{position: 'relative'}}
                             id={`ledger-${ledger.id}`}
                             onClick={(e) => {
                               if (locked) {
                                 freePlanNoticeTargetRef.current = document.getElementById(`ledger-${ledger.id}`);
                                 freePlanNoticePopoverRef.current?.show();
                                 e.preventDefault();
                               }
                             }}>
                <LedgerLabel>
                  <LedgerName className="main-label">
                    {ledger.name}
                  </LedgerName>
                  <LedgerAmount>
                    {formatCurrency(ledger.amount, subscription)}
                    {(ledger.unallocatedCount || 0) > 0 &&
                      <React.Fragment>
                        {active
                          ? <RedBadge>{ledger.unallocatedCount}</RedBadge>
                          : <RedBadgeDarker>{ledger.unallocatedCount}</RedBadgeDarker>}
                      </React.Fragment>}
                  </LedgerAmount>
                </LedgerLabel>

                <div className="flex-grow-1"/>

                <div className="move-icons">
                  <FontAwesomeIcon icon={faSquareUp} onClick={(e) => moveUp(e, i)}/>
                  <FontAwesomeIcon icon={faSquareDown} onClick={(e) => moveDown(e, i)}/>
                </div>

                {locked && (
                  <LockedIndicatorContainer>
                    <FontAwesomeIcon icon={faLock}/>
                  </LockedIndicatorContainer>)}
              </NestedNavLink>);
          })}

          {currentUser.role !== ROLE.OBSERVER && (
            <SubtleButton className="tab"
                          id="new-ledger-button"
                          onClick={() => {
                            freePlanNoticeTargetRef.current = document.getElementById('new-ledger-button');
                            if (subscription?.featureLevel === FEATURE_LEVEL.FREE && ledgers.length >= 1) {
                              freePlanNoticePopoverRef.current?.show();
                            } else {
                              ledgerEditorModalRef.current?.show(BLANK_LEDGER);
                            }
                          }}>
              <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
              <span className="main-label">{formatMessage(MSG_newLedgerButton)}</span>
            </SubtleButton>)}


          <LedgerEditorModal ref={ledgerEditorModalRef}/>
          <FreePlanNoticePopover
            ref={freePlanNoticePopoverRef}
            targetRef={freePlanNoticeTargetRef}
          />

        </OuterSidebarContainer>
      </div>

      <div>
        <MainContainer>
          {props.children}
        </MainContainer>
      </div>

      <HelpCenterFloatingButton onClick={() => navigate('/app/help_center')}>
        <FontAwesomeIcon icon={faQuestionCircle}/>
      </HelpCenterFloatingButton>
    </React.Fragment>
  );
};

const OuterSidebarContainer = styled.div`
  padding: 1rem 0;

  a.tab {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    background: ${({theme}) => theme.sidebar.unfocusedTabBackgroundColor};
    color: ${({theme}) => theme.sidebar.unfocusedTabTextColor};
    padding: 0.5rem 1rem;
    margin-bottom: 0.25rem;
    width: 11rem;
    text-decoration: none;
    border-top-left-radius: ${({theme}) => theme.sidebar.tabBorderRadius};
    border-bottom-left-radius: ${({theme}) => theme.sidebar.tabBorderRadius};

    .move-icons {
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: -0.75rem;
    }

    &:hover {
      .main-label {
        text-decoration: underline;
      }

      .move-icons {
        opacity: 0.35;
      }
    }

    &.active {
      background: ${({theme}) => theme.sidebar.focusedTabBackgroundColor};
      color: ${({theme}) => theme.sidebar.focusedTabTextColor};

      &:hover {
        .main-label {
          text-decoration: underline;
        }
        .move-icons {
          opacity: 0.35;
        }
      }
    }
  }
`;

const MainContainer = styled(StyledWindowContainer)`
  padding: 0;
  height: 100%;
  min-height: 40rem;
  display: flex;
  flex-direction: row;
`;

const SubtleButton = styled(LinkButton)`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({theme}) => theme.colors.backgroundTextColor };
  text-decoration: none;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
`;

const LedgerLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LedgerName = styled.div`
  line-height: 1.2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

const LedgerAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 90%;
  opacity: 0.75;
  line-height: 1rem;
  margin-bottom: 0.25rem;
`;

const Icon = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LockedIndicatorContainer = styled.div`
  position: absolute;
  color: ${({theme}) => theme.colors.warning};
  right: 0.5rem;
  bottom: 0.5rem;
  opacity: 0.65;

  svg {
    font-size: 150%;
  }
`;

const NavBadge = styled.div`
  background: ${({theme}) => theme.colors.danger};
  color: #fff;
  padding: 0.25rem 0.25rem;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1rem;
  text-align: center;
  margin-left: 0.5rem;
`;

const HelpCenterFloatingButton = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.75rem;
  text-align: center;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 0 1rem rgba(0,0,0,0.75);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const RedBadge = styled.div`
  display: inline-block;
  background: ${({theme}) => theme.colors.alertRedColor};
  color: #fff;
  border-radius: 1rem;
  padding: 0 0.5rem;
  margin: -0.5rem 0;
  margin-left: 0.5rem;
`;

const RedBadgeDarker = styled(RedBadge)`
  opacity: 0.5;
`;

export { AuthenticatedBody };
