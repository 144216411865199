import styled from "styled-components";
import { StyledRaisedContainer } from "./StyledRaisedContainer";

export const StyledEnvelopeContainer = styled(StyledRaisedContainer)`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;

  &.has-focus-border, &.hover-focus-border:hover {
    &:before {
      content: '';
      overflow: hidden;
      display: block;
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      border-radius: 0.5rem;
      border: 3px solid #000;
    }

    &.focus-border-danger {
      &:before {
        border-color: ${({theme}) => theme.colors.danger};
      }
    }

    &.focus-border-highlight {
      &:before {
        border-color: ${({theme}) => theme.colors.success};
      }
    }
  }
`;
