import { PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../../utils/api_types";
import { IDataState } from "../data_slice";
import { dataStateSetters } from "./setters";

export const userDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  setUser: (state, action: PayloadAction<User>) => {
    dataStateSetters.setUser(state, action.payload);
  },

  setUsers: (state, action: PayloadAction<User[]>) => {
    state.usersHash = {};
    action.payload.forEach(user => {
      dataStateSetters.setUser(state, user);
    });
  },
};
