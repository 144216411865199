// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("shared/fonts/open-sans-v17-latin-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("shared/fonts/open-sans-v17-latin-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("shared/fonts/open-sans-v17-latin-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("shared/fonts/open-sans-v17-latin-regular.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("shared/fonts/open-sans-v17-latin-600.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("shared/fonts/open-sans-v17-latin-600.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("shared/fonts/open-sans-v17-latin-600.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("shared/fonts/open-sans-v17-latin-600.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "?#OpenSans" });
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___, { hash: "?#OpenSans" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* open-sans-400 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'), /* Super Modern Browsers */
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff'), /* Modern Browsers */
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'), /* Safari, Android, iOS */
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'), /* Super Modern Browsers */
  url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff'), /* Modern Browsers */
  url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype'), /* Safari, Android, iOS */
  url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('svg'); /* Legacy iOS */
}
`, "",{"version":3,"sources":["webpack://./src/pages/app/styles.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB;;;;yDAI6E,EAAE,eAAe;AAChG;;AAEA,0BAA0B;AAC1B;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB;;;;uDAIuE,EAAE,eAAe;AAC1F","sourcesContent":["/* open-sans-400 - latin */\n@font-face {\n  font-family: 'Open Sans';\n  font-style: normal;\n  font-weight: 400;\n  src: local('Open Sans Regular'), local('OpenSans-Regular'),\n    url(\"shared/fonts/open-sans-v17-latin-regular.woff2\") format('woff2'), /* Super Modern Browsers */\n    url(\"shared/fonts/open-sans-v17-latin-regular.woff\") format('woff'), /* Modern Browsers */\n    url(\"shared/fonts/open-sans-v17-latin-regular.ttf\") format('truetype'), /* Safari, Android, iOS */\n    url(\"shared/fonts/open-sans-v17-latin-regular.svg?#OpenSans\") format('svg'); /* Legacy iOS */\n}\n\n/* open-sans-600 - latin */\n@font-face {\n  font-family: 'Open Sans';\n  font-style: normal;\n  font-weight: 600;\n  src: local('Open Sans Regular'), local('OpenSans-Regular'),\n  url(\"shared/fonts/open-sans-v17-latin-600.woff2\") format('woff2'), /* Super Modern Browsers */\n  url(\"shared/fonts/open-sans-v17-latin-600.woff\") format('woff'), /* Modern Browsers */\n  url(\"shared/fonts/open-sans-v17-latin-600.ttf\") format('truetype'), /* Safari, Android, iOS */\n  url(\"shared/fonts/open-sans-v17-latin-600.svg?#OpenSans\") format('svg'); /* Legacy iOS */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
