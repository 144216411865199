import * as React from "react";
import { PropsWithChildren } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { Page } from "@web/components/shared/Page";
import { MSG_appearanceTitle, MSG_billingTitle, MSG_settingsTitle, MSG_usersTitle } from "shared/strings/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { faScrewdriverWrench } from "@fortawesome/pro-light-svg-icons/faScrewdriverWrench";
import { faSliders } from "@fortawesome/pro-light-svg-icons/faSliders";
import { faUsers } from "@fortawesome/pro-light-svg-icons/faUsers";
import { useIntlFormatters } from "shared/utils/formatters";
import { StyledVerticalOptions } from "@web/components/styled/StyledVerticalOptions";
import { NavLink } from "react-router-dom";

interface IProps {}

const SettingsRoot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/app/settings') {
      navigate('/app/settings/appearance');
    }
  }, [location.pathname]);

  return (
    <Page
      header={
        <Header>
          <LeftContainer>
            <Heading><FontAwesomeIcon icon={faScrewdriverWrench}/> {formatMessage(MSG_settingsTitle)}</Heading>
          </LeftContainer>
          <RightContainer>
          </RightContainer>
        </Header>
      }
      sidebar={
        <StyledVerticalOptions className="nowrap" style={{width: '12.5rem'}}>
          <NavLink className="option" to="appearance">
            <FontAwesomeIcon icon={faSliders}/>
            {formatMessage(MSG_appearanceTitle)}
          </NavLink>
          <NavLink className="option" to="users" end>
            <FontAwesomeIcon icon={faUsers}/>
            {formatMessage(MSG_usersTitle)}
          </NavLink>
          <NavLink className="option" to="billing">
            <FontAwesomeIcon icon={faCreditCard}/>
            {formatMessage(MSG_billingTitle)}
          </NavLink>
        </StyledVerticalOptions>
      }>

      <BodyWrapper>
        <Outlet/>
      </BodyWrapper>

    </Page>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;

const Heading = styled.div`
  font-size: 160%;
  margin-right: 1rem;
  opacity: 0.75;
`;

const BodyWrapper = styled.div`
  padding: 1rem;
`;

export {SettingsRoot};
