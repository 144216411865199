export const MSG_signUpTitle = {
  id: "b1af84d4-9ae3-4987-b64b-b06714874ea7",
  description: "",
  defaultMessage: "Get Started Today"
};


export const MSG_firstNameLabel = {
  id: "afe07582-d4ba-40e8-904b-bea765ae92db",
  description: "The label for the first name field in the signup form.",
  defaultMessage: "First name"
};

export const MSG_lastNameLabel = {
  id: "aa52c1dc-5635-47ee-bd32-1d3f07668a95",
  description: "The label for the last name field in the signup form.",
  defaultMessage: "Last name"
};

export const MSG_noCreditCardRequired = {
  id: "b715c9f8-1ebf-4d6b-a8b8-69a88fe55311",
  description: "",
  defaultMessage: "No credit card required!"
};


export const MSG_signUpForFreeTitle = {
  id: "d8ceca21-4eb4-44f3-a8f3-56828876d6bc",
  description: "The title of a button that they click to sign up for a new account.",
  defaultMessage: "Sign Up For Free"
};

