export const MSG_importTitle = {
  id: "82c34388-e640-4804-80ea-c4fc4eb6bdb5",
  description: "The title for the feature where they can import transactions from their bank into NeoBudget.",
  defaultMessage: "Import"
};

export const MSG_importAccountName = {
  id: '7cf98efa-bd53-4b35-92ba-859bbc279ac1',
  description: 'The label above a section where they can import transactions for a specific account.',
  defaultMessage: 'Import {accountName}'
};

export const MSG_dragDropImportMessage = {
  id: "398417d3-5095-46a7-9a48-bc6cbf773e31",
  description: "",
  defaultMessage: "Drag your downloaded bank transactions file here for {accountName}."
};

export const MSG_importFormatsAccepted = {
  id: "e5b52619-c1cf-4b9c-9b1b-5e5ec59d1e34",
  description: "",
  defaultMessage: "We can handle Quicken (QIF or QFX) and Microsoft Money (OFX) formats."
};

export const MSG_orImportButton = {
  id: "46107668-eb65-4e54-b879-a15957ac7f54",
  description: "On the import screen, a box is shown at the top where they can drag files into to import them.  Below that box is this message.  It gives them an alternative to click a button to import a file.",
  defaultMessage: "or {button}"
};

export const MSG_importButton = {
  id: "21d61f35-3efc-4047-bcb8-9641da010d3b",
  description: "",
  defaultMessage: "Select a File for {accountName}"
};

export const MSG_fileTooLarge = {
  id: "b3ab4399-0ce0-46a7-bc8c-0605b9fb068f",
  description: "",
  defaultMessage: "The file you are trying to upload is too large to be a list of bank transactions.  Make sure you are uploading the right type of file."
};

export const MSG_uploadingLabel = {
  id: "330c1d1a-9da7-482c-9a3a-a900fe8f3c3c",
  description: "A message to display while a file is uploading.",
  defaultMessage: "Uploading..."
};

export const MSG_fileNameLabel = {
  id: "7eaddc3b-b5da-4378-9ebe-24602df3afcc",
  description: "A label in front of a file name.",
  defaultMessage: "File name: {fileName}"
};

export const MSG_fileSizeLabel = {
  id: "64848358-ebc2-4648-aae8-a72657ad4590",
  description: "A label in front of the size of a file.",
  defaultMessage: "File size: {fileSize}"
};

export const MSG_importX = {
  id: "565f8bac-3131-4c68-b9f2-b8d24de9a785",
  description: "A button that lets them import X transactions (where X is the number of transactions that are selected)",
  defaultMessage: "Import {count}"
};

export const MSG_selectImportAccount = {
  id: "de672da8-37c5-42e9-8d81-c5c7a4295e23",
  description: "When they are importing transactions, and the import file has multiple accounts, this message is shown to ask them which account they would like to import.",
  defaultMessage: "{numAccounts, plural, 1 {1 account was found.} other {# accounts were found.}}"
};

export const MSG_whichToImportQuestion = {
  id: "59bdb8c6-9c23-4e9b-8b96-14ee06fb720f",
  description: "A question asking which of the multiple accounts in the import file they would like to import into NeoBudget.  This is shown when they have multiple accounts in their import file.",
  defaultMessage: "Which would you like to import?"
};

export const MSG_importAccountNumber = {
  id: "d2c3ead7-5ea8-4f5b-b8c1-53eab828ac55",
  description: "A label in front of the account number of an account in the import file.",
  defaultMessage: "Account number: {number}"
};

export const MSG_importAccountTransactionCount = {
  id: "3642e238-befa-46cb-a728-00fd31db0920",
  description: "A label in front of the number of transactions in an account in the import file.",
  defaultMessage: "{count, plural, 1 {1 transaction} other {# transactions}}"
};

export const MSG_importedCount = {
  id: "1b2755e7-c49f-4fa5-b6b9-d7c35f3b71ae",
  description: "A message shown after they have imported transactions.  It tells them how many transactions were imported.",
  defaultMessage: "{count, plural, 1 {1 transaction was imported.} other {# transactions were imported.}}"
};

export const MSG_importTransactionAlreadyImported = {
  id: "68f5a33d-cad3-4a58-a52e-d8e5d671af59",
  description: "A message shown when they are importing transactions, and one of the transactions in the import file has already been imported.",
  defaultMessage: "This transaction has already been imported."
};

export const MSG_importTransactionCheckNumberExists = {
  id: "bf565ac5-88ba-4d48-930d-3e5c6098fc3d",
  description: "A message shown when they are importing transactions, and one of the transactions in the import file has the same check number as a transaction that already exists in NeoBudget.",
  defaultMessage: "This check number already exists."
};

export const MSG_importTransactionSimilarTransactionExists = {
  id: "74614372-75c0-4b83-94d9-41414609086a",
  description: "A message shown when they are importing transactions, and one of the transactions in the import file is similar to a transaction that already exists in NeoBudget.",
  defaultMessage: "A similar transaction already exists."
};

export const MSG_importIntoAccountLabel = {
  id: "550c7562-7cac-4dd4-9db0-f92e06279464",
  description: "A label in front of a select input that lets them choose which account to import transactions into.",
  defaultMessage: "Import into account"
};
