import * as React from "react";
import { RefObject } from "react";
import { Modal } from "@web/components/Modal";
import styled from "styled-components";
import { compact, uniq } from "lodash";
import { TRANSACTIONS_PER, useAlert, useTransactionPager } from "@web/utils/hooks";
import { Spinner } from "@web/components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { EnvelopeGrid } from "@web/components/shared/EnvelopeGrid";
import { EnvelopeBox } from "@web/components/shared/EnvelopeBox";
import { EmptyEnvelopeListExplanation } from "@web/components/shared/EmptyEnvelopeListExplanation";
import { useSelector } from "react-redux";
import {
  MSG_filterUnallocatedPlaceholder,
  MSG_showingXofY,
  MSG_unallocatedTransactionsExplanation,
  MSG_unallocatedTransactionsTitle
} from "shared/strings/transactions";
import { useIntlFormatters } from "shared/utils/formatters";
import { DraggableTransaction } from "./DraggableTransaction";
import { TextInput } from "@web/components/inputs/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlass";
import { Button } from "@web/components/Button";
import { MSG_closeButton, MSG_unexpectedError } from "shared/strings/generic";
import { useDebounce } from "use-debounce";
import {
  ITransactionTextEditorModal,
  TransactionTextEditorModal
} from "../transaction_text_editor_modal/TransactionTextEditorModal";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Envelope, Transaction } from "shared/utils/api_types";
import {
  useUpdateTransactionMutation,
  useUpdateTransactionsMutation
} from "shared/state/endpoints/app/transactions_api";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { IncomeDropbox } from "./IncomeDropbox";
import { SplitDropbox } from "./SplitDropbox";
import { DeleteDropbox } from "./DeleteDropbox";
import { ITransactionEditorModal } from "../transaction_editor_modal/TransactionEditorModal";
import { TransactionDragLayer } from "./TransactionDragLayer";
import { AllocationModalContext } from "./context";
import { useReloadLedger } from "shared/hooks/use_reload";
import {
  selectEnvelopesForLedger,
  selectLedger,
  selectTransactionsList,
  selectTransactionsListPage,
  selectTransactionsListTotalCount
} from "shared/state/store";
import { parseApiError } from "shared/utils/api_errors";
import { TransactionModalContext } from "shared/utils/transaction_modal_context";

interface IProps {
  ledgerId: string;
  transactionEditorModalRef: RefObject<ITransactionEditorModal>;
}

interface IAllocationModal {
  show: () => any;
  hide: () => any;
}

const AllocationModal = React.forwardRef<IAllocationModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [searchStr, setSearchStr] = React.useState<string>('');
  const [debouncedSearchStr] = useDebounce(searchStr, 250);
  const transactions = useSelector(selectTransactionsList('unallocated'));
  const totalCount = useSelector(selectTransactionsListTotalCount('unallocated'));
  const page = useSelector(selectTransactionsListPage('unallocated'));
  const {fetchToPage, fetchNextPage} = useTransactionPager('unallocated', {
    ledgerId: props.ledgerId, q: debouncedSearchStr, onlyUnallocated: true
  });
  const [rightDiv, setRightDiv] = React.useState<HTMLDivElement | null>(null);
  const [innerHeight, setInnerHeight] = React.useState<number>(0);
  const ledger = useSelector(selectLedger(props.ledgerId));
  const envelopes = useSelector(selectEnvelopesForLedger(props.ledgerId));
  const {formatMessage} = useIntlFormatters();
  const transactionEditorModalRef = React.useRef<ITransactionTextEditorModal>(null);
  const transactionModalContext = React.useContext(TransactionModalContext);
  const [updateTransaction] = useUpdateTransactionMutation();
  const [updateTransactions] = useUpdateTransactionsMutation();
  const reloadLedger = useReloadLedger();
  const [workingTransactions, setWorkingTransactions] = React.useState<Transaction[]>([]);
  const [selectedTransactions, setSelectedTransactions] = React.useState<Transaction[]>([]);
  const [draggingTransaction, setDraggingTransaction] = React.useState<Transaction | null>(null);
  const textEditRef = React.useRef<HTMLInputElement>(null);
  const sideDivRef = React.useRef<HTMLDivElement>(null);
  const selectedIds = compact(selectedTransactions.map(t => t.id));
  const {showErrorAlert} = useAlert();
  if (draggingTransaction?.id && !selectedIds.includes(draggingTransaction.id)) {
    selectedIds.unshift(draggingTransaction.id);
  }

  let allSelected = [...selectedTransactions];
  if (draggingTransaction && !allSelected.find(t => t.id === draggingTransaction.id)) {
    allSelected.push(draggingTransaction);
  }

  React.useEffect(() => {
    if (rightDiv) {
      setInnerHeight(Math.max(rightDiv.clientHeight + 30, 600));
    } else {
      setInnerHeight(window.innerHeight);
    }
  }, [rightDiv]);

  React.useEffect(() => {
    transactionModalContext.registerRefreshParent(refresh);
    return () => transactionModalContext.unregisterRefreshParent(refresh);
  }, [props.ledgerId, page, debouncedSearchStr]);

  React.useEffect(() => {
    if (!visible) return;
    void fetchToPage(1);
  }, [visible, props.ledgerId, debouncedSearchStr]);

  React.useImperativeHandle<any, IAllocationModal>(ref, () => ({
    show: () => {
      void fetchToPage(1);
      setSelectedTransactions([]);
      setWorkingTransactions([]);
      setDraggingTransaction(null);
      show();
    },

    hide: () => {
      hide();
    }
  }));

  async function refresh() {
    await fetchToPage(page);
  }

  function show() {
    setVisible(true);
  }

  function hide() {
    setVisible(false);
  }

  function toggleSelection(transaction: Transaction) {
    if (selectedTransactions.find(t => t.id === transaction.id)) {
      setSelectedTransactions(selectedTransactions.filter(t => t.id !== transaction.id));
    } else {
      setSelectedTransactions(uniq([...selectedTransactions, transaction]));
    }
  }

  async function allocateItems(envelope: Envelope) {
    if (selectedTransactions.length) {
      setWorkingTransactions(selectedTransactions);
    } else {
      setWorkingTransactions([draggingTransaction as Transaction]);
    }
    try {
      const {success, errors} = await updateTransactions({
        ledgerId: props.ledgerId,
        transactions: compact(selectedIds.map(id => transactions.find(t => t.id === id))).map(t => ({
          id: t.id,
          allocations: [{envelopeId: envelope.id || '', amount: t.amount}]
        }))
      }).unwrap();
      if (!success) {
        showErrorAlert(errors[0]);
        setWorkingTransactions([]);
        return;
      }
    } catch (e) {
      let error = parseApiError(e);
      showErrorAlert(error.errorType === 'message' ? error.message : formatMessage(MSG_unexpectedError));
      setWorkingTransactions([]);
      return;
    }
    await reloadLedger(props.ledgerId);
    await transactionModalContext.reloadParent();
    setSelectedTransactions([]);
    setWorkingTransactions([]);
    textEditRef.current?.select();
    textEditRef.current?.focus();
  }

  function resetSelections() {
    setSelectedTransactions([]);
    setWorkingTransactions([]);
    setDraggingTransaction(null);
  }

  if (!ledger || !envelopes) return null;

  return (
    <DndProvider backend={HTML5Backend}>
    <AllocationModalContext.Provider value={{
      toggleSelection,
      selected: allSelected, setSelected: setSelectedTransactions,
      dragging: draggingTransaction, setDragging: setDraggingTransaction,
      working: workingTransactions, setWorking: setWorkingTransactions,
      reset: resetSelections,
    }}>
    <TransactionDragLayer transactions={transactions.filter(t => selectedIds.includes(t.id || ''))}
                          maxWidth={sideDivRef.current?.clientWidth || 0}/>
    <Modal
      isOpen={visible}
      onRequestClose={hide}
      windowStyle={{width: '85rem'}}
      bodyStyle={{height: innerHeight, position: 'relative', overflow: 'hidden'}}
      header={
        <div>
          <h2>{formatMessage(MSG_unallocatedTransactionsTitle)}</h2>
          <div>{formatMessage(MSG_unallocatedTransactionsExplanation)}</div>
        </div>
      }
      footer={
        <div className="text-right">
          <Button onClick={hide}>
            {formatMessage(MSG_closeButton)}
          </Button>
        </div>
      }
    >

      <StyledUnallocatedTransactions id="unallocated-transactions" style={{direction: 'rtl'}}>
        <div style={{direction: 'ltr'}} ref={sideDivRef}>
          <TextInput
            autoFocus
            className="mb-2"
            value={searchStr}
            onChange={e => setSearchStr(e.target.value)}
            placeholder={formatMessage(MSG_filterUnallocatedPlaceholder)}
            ref={textEditRef}
            leftElement={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
            rightElement={searchStr
              ? (
                <FontAwesomeIcon icon={faTimes} style={{cursor: 'pointer'}} onClick={() => {
                  setSearchStr('');
                  textEditRef.current?.focus();
                }}/>
              )
              : undefined}
          />

          <div className="mb-2 text-center">
            {formatMessage(MSG_showingXofY, {count: totalCount || 0, total: ledger.unallocatedCount || 0})}
          </div>

          <InfiniteScroll
            dataLength={transactions.length}
            next={fetchNextPage}
            scrollableTarget={"unallocated-transactions"}
            hasMore={page * TRANSACTIONS_PER < (totalCount || 0)}
            loader={<Spinner padded centered/>}>

            <TransitionGroup>
              {transactions.map(t => (
                <CSSTransition key={t.id} timeout={150} classNames="unallocated-transaction">
                  <DraggableTransaction
                    transaction={t}
                    editorRef={transactionEditorModalRef}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </InfiniteScroll>
        </div>
      </StyledUnallocatedTransactions>

      <StyledRightContainer>
        <div ref={setRightDiv}>
          <div className="dropboxes">
            <SplitDropbox
              hoverFocusBorder={selectedIds.length > 0}
              forbidden={selectedIds.length !== 1}
              transactionEditorModalRef={props.transactionEditorModalRef}
              afterDrop={() => {
                textEditRef.current?.select();
                textEditRef.current?.focus();
              }}
            />
            <IncomeDropbox
              hoverFocusBorder={selectedIds.length > 0}
              forbidden={selectedIds.length !== 1}
              transactionEditorModalRef={props.transactionEditorModalRef}
              afterDrop={() => {
                textEditRef.current?.select();
                textEditRef.current?.focus();
              }}
            />
            <DeleteDropbox
              hoverFocusBorder={selectedIds.length > 0}
              accountId={props.ledgerId}
              afterDrop={() => {
                textEditRef.current?.select();
                textEditRef.current?.focus();
              }}
              afterDelete={() => {
                void refresh();
              }}
            />
          </div>
          <div className="p-4">
            {envelopes.filter(e => !e.archived).length ? (
              <EnvelopeGrid
                ledgerId={props.ledgerId}
                renderEnvelope={env => (
                  <EnvelopeBox
                    envelope={env}
                    acceptDrop="unallocated-transaction"
                    style={{cursor: 'default'}}
                    hoverFocusBorder={selectedIds.length > 0}
                    onDrop={() => allocateItems(env)}
                  />
                )}/>
            ) : (
              <EmptyEnvelopeListExplanation screen="envelopes"/>
            )}
          </div>
        </div>
      </StyledRightContainer>

      <TransactionTextEditorModal
        ref={transactionEditorModalRef}
        ledgerId={props.ledgerId}
      />
    </Modal>
    </AllocationModalContext.Provider>
    </DndProvider>
  );
});

const StyledUnallocatedTransactions = styled.div`
  background-color: ${({theme}) => theme.unallocatedWindow.sidebarBackgroundColor };
  scrollbar-color: #fff9 ${({theme}) => theme.unallocatedWindow.sidebarBackgroundColor };
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 22rem;
  overflow-y: scroll;
  padding: 1rem;

  .unallocated-transaction-exit-active {
    opacity: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
    transform: scale(0);
    transform-origin: top;
    transition: all 150ms ease-in-out;
  }

  .unallocated-transaction-enter-active {
    opacity: 1;
    font-size: 16px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    transform: scale(1);
    transform-origin: top;
    transition: all 150ms ease-in-out;
  }

  .unallocated-transaction-enter {
    opacity: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
    transform: scale(0);
    transform-origin: top;
    transition: all 150ms ease-in-out;
  }

`;

const StyledRightContainer = styled.div`
  position: absolute;
  top: 0;
  left: 22rem;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 1rem;

  .dropboxes {
    margin: 1rem 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
  }
`;

export {AllocationModal, IAllocationModal};
