import { baseApi } from "../base_api";
import { Subscription, User } from "../../../utils/api_types";
import { fromApiSubscription, fromApiUser, toApiUser } from "../../../utils/api_transformations";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    authenticate: builder.mutation<{user: User | null, subscription: Subscription | null}, {email: string, password: string}>({
      query: (params) => ({
        url: `/users/authenticate`,
        method: 'POST',
        body: {user: params, expand: {user: ['api_key']}}
      }),
      transformResponse: (response: any) => {
        return {
          user: fromApiUser(response['user']),
          subscription: fromApiSubscription(response['subscription']),
        }
      }
    }),

    initiatePasswordReset: builder.mutation<{success: boolean}, {email: string}>({
      query: (params) => ({
        url: `/users/initiate_password_reset`,
        method: 'POST',
        body: params
      }),
      transformResponse: (response: any) => {
        return {
          success: response.success,
        }
      }
    }),

    finalizePasswordReset: builder.mutation<{success: boolean}, {password: string, passwordConfirmation: string, token: string}>({
      query: (params) => ({
        url: `/users/finalize_password_reset`,
        method: 'POST',
        body: {
          password: params.password,
          password_confirmation: params.passwordConfirmation,
          token: params.token,
        }
      }),
      transformResponse: (response: any) => {
        return {
          success: response.success || false,
          errorMessage: response.message || null,
        }
      }
    }),

    getUsers: builder.query<User[], void>({
      query: () => `/users`,
      transformResponse: (response: any) => {
        return response['users'].map(fromApiUser);
      }
    }),

    getUser: builder.query<User, number>({
      query: (id) => `/users/${id}`,
      transformResponse: (response: any) => {
        return fromApiUser(response['user']);
      }
    }),

    createUser: builder.mutation<User, {user: Partial<User>}>({
      query: (params) => ({
        url: `/users`,
        method: 'POST',
        body: toApiUser(params.user)
      }),
      transformResponse: (response: any) => {
        return fromApiUser(response['user']);
      }
    }),

    updateUser: builder.mutation<User, {user: Partial<User & {password: string, passwordConfirmation: string, currentPassword: string}>}>({
      query: (params) => {
        const obj = toApiUser(params.user);
        if (params.user.password) obj['password'] = params.user.password;
        if (params.user.passwordConfirmation) obj['password_confirmation'] = params.user.passwordConfirmation;
        if (params.user.currentPassword) obj['current_password'] = params.user.currentPassword;
        return {
          url: `/users/${params.user.id}`,
          method: 'PUT',
          body: obj
        };
      },
      transformResponse: (response: any) => {
        return fromApiUser(response['user']);
      }
    }),

    sendInvitation: builder.mutation<User, string>({
      query: (id) => ({
        url: `/users/${id}/send_invitation`,
        method: 'POST',
      }),
      transformResponse: (response: any) => {
        return fromApiUser(response['user']);
      }
    }),

    acceptInvitation: builder.mutation<User, {token: string, user: Partial<User & {password: string, passwordConfirmation: string}>}>({
      query: (params) => {
        const obj = {...toApiUser(params.user), token: params.token};
        if (params.user.password) obj['password'] = params.user.password;
        if (params.user.passwordConfirmation) obj['password_confirmation'] = params.user.passwordConfirmation;
        return {
          url: `/users/accept_invitation`,
          method: 'POST',
          body: obj
        };
      },
      transformResponse: (response: any) => {
        return fromApiUser(response['user']);
      }
    }),
  }),
  overrideExisting: false,
});

export const {
  useAuthenticateMutation,
  useInitiatePasswordResetMutation,
  useFinalizePasswordResetMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useSendInvitationMutation,
  useAcceptInvitationMutation,
} = extendedApi;

