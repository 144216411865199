export const MSG_loginTitle = {
  id: "8ce4dfb4-44e6-4ec4-8671-4a4b06a2e6a8",
  description: "The title of the login page, which is the page where they sign in to the app.",
  defaultMessage: "Login"
};

export const MSG_loginButton = {
  id: "e8e4ba0c-0097-4a30-8270-9ede8ff8f219",
  description: "The label for the 'Login' button, which they click on the login form to sign in to the app.",
  defaultMessage: "Login"
};

export const MSG_emailFormLabel = {
  id: "2670feb7-6633-4b08-81be-7fba09c1776a",
  description: "The label for the input field where they enter their email address in the login form.",
  defaultMessage: "Email"
};

export const MSG_passwordFormLabel = {
  id: "f6680a44-3d4f-457f-be38-9d814f1ed6be",
  description: "The label for the input field where they enter their password in the login form",
  defaultMessage: "Password"
};

export const MSG_forgotYourPasswordLink = {
  id: "22b71b41-f37c-44fc-a28c-5962c5a39eae",
  description: "The link to the form that lets them reset their password.",
  defaultMessage: "Forgot your password?"
};

export const MSG_forgotPasswordLink = {
  id: "88222541-3bbf-4c9b-b2e7-4333295d9b8b",
  description: "A shorter version of the link to the form that lets them reset their password.",
  defaultMessage: "Forgot Password?"
};

export const MSG_notRegisteredQuestion = {
  id: "efcc5308-47ea-4a02-8a6f-f479c800e47f",
  description: "A bit of text displayed before we show the sign up button to create a new account.",
  defaultMessage: "Not registered?"
};

export const MSG_createAnAccountLink = {
  id: "ed55a799-0c02-4c76-84a1-97f49d1960cb",
  description: "The link to the sign up form.",
  defaultMessage: "Create an account"
};

export const MSG_loggedInAs = {
  id: "9b8c4e4e-1e8b-4b24-88ac-c4e7bbdd467a",
  description: "A message that displays the name of the user who is currently logged in to the system.",
  defaultMessage: "Logged in as {name}"
};

export const MSG_logoutButton = {
  id: "e611d8b7-fd36-4e8d-9d82-9ce4d8d8f4d2",
  description: "The label for the button that logs them out of the system.",
  defaultMessage: "Logout"
};

export const MSG_forgotYourPassword = {
  id: "5b8d1b51-b262-4770-93d8-712e0a1399e6",
  description: "This is the label for the link they can click if they need to reset their password.",
  defaultMessage: "Forgot your password?"
};

export const MSG_forgotPasswordExplanation = {
  id: "aa497afe-66ce-4cb4-a004-b692cfd971e5",
  defaultMessage: "Enter your email address and we'll send you a link to reset your password."
};

export const MSG_goBack = {
  id: "15438f33-d6af-4bde-8a40-1e9082356a87",
  description: "A label for a button that takes them to the previous screen.",
  defaultMessage: "Go Back"
};

export const MSG_send = {
  id: "a5a3d2bd-eb6d-433f-a25d-3e057045cb9b",
  description: "The label for a button that sends an email.",
  defaultMessage: "Send"
};

export const MSG_checkYourEmail = {
  id: "53f45d1a-1815-42d2-a155-413925390d79",
  description: "This is the heading we show after they reset their password.",
  defaultMessage: "Check your email!"
};

export const MSG_checkEmailExplanation = {
  id: "32ca41fe-265f-43c9-b30f-0dec7f7806ed",
  defaultMessage: "We just sent you the instructions to reset your password."
};

export const MSG_checkYourEmailTitle = {
  id: "b1862fa9-20ad-4c86-ae41-5512df2f12c5",
  description: "The title of an alert box that tells them to check their email.",
  defaultMessage: "Check Your Email"
};


export const MSG_checkEmailMobileExplanation = {
  id: "88990e10-33c4-4a7d-9247-410f612f573d",
  description: "",
  defaultMessage: "We just sent an email to {email}.  Check your email and follow those instructions to reset your password."
};


export const MSG_emailEntered = {
  id: "fb12ac6e-ac66-427d-b730-aca740455b1f",
  description: "This is used when they are resetting their password and we want to confirm the email address they entered.",
  defaultMessage: "You entered: {emailAddress}."
};

export const MSG_resetYourPassword = {
  id: "006c2987-e228-4927-809b-9b9a426296b7",
  description: "This is the label we show when we ask them to reset their password.",
  defaultMessage: "Reset your password."
};

export const MSG_resetPasswordExplanation = {
  id: "71eba5ca-8d1a-4b73-acdc-84da83b0772c",
  defaultMessage: "What would you like your new password to be?"
};

export const MSG_newPassword = {
  id: "e39fc9aa-f113-4d8b-8d20-c8074f9dd515",
  description: "The label for when we ask them to type in a new pasword.",
  defaultMessage: "New password"
};

export const MSG_confirmYourNewPassword = {
  id: "c50fe411-5cb3-4d0d-b86f-7759f60f95de",
  description: "This is the label for when we ask them to type in their password again.",
  defaultMessage: "Confirm your new password"
};

export const MSG_resetComplete = {
  id: "1812710c-08e8-4ae3-b9cb-ecf0c30d3a09",
  description: "This is what we show when they have successfully reset their password and they are finished.",
  defaultMessage: "Done!"
};

export const MSG_resetCompleteInstructions = {
  id: "faa4c758-82cb-4446-9a83-12b270c15ffc",
  description: "These are instructions we show them after they have successfully reset their password.",
  defaultMessage: "Now let's try out that new password."
};

export const MSG_newAccountMobileSuggestion = {
  id: "cf4f04f6-0556-41ad-8eff-889e8128e0b8",
  description: "",
  defaultMessage: "You can't sign up for NeoBudget in the app.  Join us and come back to manage your budget on this device."
};
