export const MSG_helpCenterTitle = {
  id: "f903972d-7461-4222-b618-705f10ed0ce3",
  description: "The title for the 'Help Center' section of the site where they can find answers to their questions and learn how to use the product.",
  defaultMessage: "Help Center"
};

export const MSG_helpGroupFormLabel = {
  id: "53d5bfdf-787e-40b0-998c-0f10c4d825da",
  description: "",
  defaultMessage: "Group Name"
};

export const MSG_helpGroupSlugLabel = {
  id: "73baa275-70bf-4a6d-8c75-6522d2250bbf",
  description: "A 'slug' is a short, lowercase, URL-friendly name for something.  For example, the slug for an article titled 'How to use the app' might be 'how-to-use-the-app'.",
  defaultMessage: "Slug"
};

export const MSG_helpCenterCaption = {
  id: "e5613ed1-eed7-41eb-a2e8-7d7ecf501596",
  description: "",
  defaultMessage: "Find answers to your questions about NeoBudget."
};

export const MSG_whatIsYourQuestion = {
  id: "c1ec9984-d323-4d1d-bfe0-61dd4915ce9b",
  description: "",
  defaultMessage: "What do you need help with?"
};

export const MSG_numHelpArticles = {
  id: "f30c357e-789f-46da-b467-3887407e0da6",
  description: "",
  defaultMessage: "{numArticles, plural, =0 {0 articles} one {1 article} other {# articles}}"
};

export const MSG_helpHome = {
  id: "678ec241-8695-49e3-9ea8-27c12d07d0c1",
  description: "The label for the 'Home' link in the help center.",
  defaultMessage: "Home"
};
