import styled from "styled-components";

export const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: ${({theme}) => theme.colors.backgroundTextColor };

  a {
    color: ${({theme}) => theme.colors.linkColor};
  }
  a:hover {
    color: ${({theme}) => theme.colors.linkColor}aa;
  }
`;

export const StyledStackedFooterContainer = styled(StyledFooterContainer)`
  flex-direction: column;
  align-items: center;
`;
