import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { sortBy } from "lodash";

export const selectEnvelope = (envelopeId: string) => createSelector(
  (state: RootState) => state.data.envelopesForLedgerHash,
  (envelopesForLedgerHash) => {
    for (const ledgerId in envelopesForLedgerHash) {
      if (envelopesForLedgerHash[ledgerId][envelopeId]) {
        return envelopesForLedgerHash[ledgerId][envelopeId];
      }
    }
    return null;
  }
)

export const selectEnvelopesForLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.envelopesForLedgerHash,
  (envelopesForLedgerHash) => {
    const envelopes = Object.values(envelopesForLedgerHash[ledgerId] || {});
    return sortBy(envelopes, ['mobileSequenceNumber', 'name']);
  }
);

export const selectActiveEnvelopesForLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.envelopesForLedgerHash,
  (envelopesForLedgerHash) => {
    const envelopes = Object.values(envelopesForLedgerHash[ledgerId] || {});
    return sortBy(envelopes.filter(e => !e.archived), ['mobileSequenceNumber', 'name']);
  }
);

export const selectArchivedEnvelopesForLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.envelopesForLedgerHash,
  (envelopesForLedgerHash) => {
    const envelopes = Object.values(envelopesForLedgerHash[ledgerId] || {});
    return sortBy(envelopes.filter(e => e.archived), ['name']);
  }
);
