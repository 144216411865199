import { createSlice } from "@reduxjs/toolkit";
import { resetState } from "../global_actions";
import { reconcileGlobalReducers } from "./global_reducers/reconcile_global_reducers";

export type TransactionsListType = 'main' | 'unallocated' | 'unreconciled';
export type TransactionListSortType = 'date' | 'amount' | 'description';
export type TransactionListSortDirection = 'asc' | 'desc';

export interface IGlobalState {
  reconcileBankBalanceByAccount: {[key: string]: number};
}

const initialState: IGlobalState = {
  reconcileBankBalanceByAccount: {}
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);
  },
  reducers: {
    ...reconcileGlobalReducers,
  }
});

export const {
  setReconcileBankBalanceByAccount
} = globalSlice.actions;

export * from './global_selectors/reconcile_global_selectors';
