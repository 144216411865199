import * as React from "react";
import { PropsWithChildren } from "react";
import { ApiError, ErrorMessage, ModelErrors } from "shared/utils/api_types";
import { omit, uniq } from "lodash";
import { Label } from "./Label";
import styled from "styled-components";
import classNames from "classnames";

export interface IProps extends PropsWithChildren {
  errors?: ApiError | string | string[];
  errorPath?: string;
  label?: string;
  note?: string;
  className?: string;
  style?: React.CSSProperties;
}

const FormElement = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  let errors: string[] = [];
  // ignore these
  if ((props.errors as ErrorMessage)?.errorType === 'message') {
    errors = [];

  // pluck off any that apply to us
  } else if ((props.errors as ModelErrors)?.errorType === 'models') {
    const e = props.errors as ModelErrors;
    const [modelName, attrName] = props.errorPath?.split('.') || [];
    if (e.errors[modelName] && e.errors[modelName][attrName]) {
      errors = e.errors[modelName][attrName];
    }

  // handle the string errors
  } else {
    if (Array.isArray(props.errors)) {
      errors = props.errors;
    } else if (typeof props.errors === 'string') {
      errors.push(props.errors);
    }
  }

  return (
    <FormElementStyled
      {...omit(props, ['children', 'tabIndex', 'ref'])}
      className={classNames(props.className, {'with-error': errors.length})}
      ref={ref}
    >
      {props.label && <Label>{props.label}</Label>}
      {props.children}
      {uniq(errors).map((e, i) =>
        <ErrorMessageStyled key={i}>{e}</ErrorMessageStyled>)}
      {props.note && <div className="text-muted mt-1">{props.note}</div>}
    </FormElementStyled>
  );
});

const FormElementStyled = styled.div`
  margin-bottom: 1rem;

  &.with-error {
    label {
      color: ${({theme}) => theme.colors.errorTextColor };
    }

    input[type="text"], input[type="password"], textarea {
      border-color: ${({theme}) => theme.colors.errorTextColor };
    }
  }

  // If a label contains a checkbox, vertically center the checkbox
  label.has-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;

    input[type="checkbox"], input[type="radio"] {
      margin-top: 0.15rem;
      height: 1.2rem;
      width: 1.2rem;
      margin-right: 0.25rem;
    }
  }
`;

const ErrorMessageStyled = styled.p`
  margin-top: 0.25rem;
  color: ${({theme}) => theme.colors.errorTextColor };
`;

export {FormElement};
