import * as React from "react";
import { PropsWithChildren } from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_allReconciledForAccount,
  MSG_lastReconciledOnDate,
  MSG_noUnreconciledTransactionsFound,
  MSG_noUnreconciledTransactionsFoundInEnvelope,
  MSG_noUnreconciledTransactionsFoundMatchingQuery,
  MSG_reconcileBankBalanceLabel,
  MSG_reconcileBankBalanceMore,
  MSG_reconcileEnterCurrentBankBalance,
  MSG_reconcilePreviousBalanceLabel,
  MSG_reconcileSelectedTotalLabel,
  MSG_reconcileSelectedTransactionsMore,
  MSG_reconcileThisShouldBe,
  MSG_reconcileTitle
} from "shared/strings/reconcile";
import { CurrencyInput } from "@web/components/inputs/CurrencyInput";
import styled from "styled-components";
import { Button } from "@web/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { ITransactionList, TransactionList } from "@web/components/shared/TransactionList";
import { useParams } from "react-router-dom";
import { BorderlessStyledCard, StyledCardHeader } from "@web/components/styled/StyledCard";
import { MSG_selectAll, MSG_selectNone } from "shared/strings/generic";
import {
  useLazyGetAccountQuery,
  useReconcileMutation,
  useUpdatePendingReconcileIdsMutation
} from "shared/state/endpoints/app/accounts_api";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@web/components/Spinner";
import { useReloadReconciledTotal } from "shared/hooks/use_reload";
import { LedgerContext } from "../_LedgersRoot";
import {
  selectAccountsForLedger,
  selectCurrentSubscription,
  selectReconciledTotalForAccount,
  setAccount
} from "shared/state/store";
import { selectReconcileBankBalances } from "shared/state/slices/global_selectors/reconcile_global_selectors";
import { setReconcileBankBalanceByAccount } from "shared/state/slices/global_slice";
import { MSG_accountLabel } from "shared/strings/transactions";
import { SelectInput } from "@web/components/inputs/SelectInput";
import { MSG_addAnAccountMessage } from "shared/strings/accounts";
import arrowImg from "shared/images/drawn-arrow.png";
import { Account } from "shared/utils/api_types";

interface IProps {}

const ReconcilePage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage, formatDate, formatCurrency} = useIntlFormatters();
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const accounts = useSelector(selectAccountsForLedger(ledgerId));
  const [accountId, setAccountId] = React.useState<string | undefined>(accounts[0]?.id);
  const account: Account = accounts.find(a => a.id === accountId) || accounts[0];
  const reconcileBankBalances = useSelector(selectReconcileBankBalances);
  const [currentBankBalance, setCurrentBankBalance] = React.useState<number>(reconcileBankBalances[accountId || 0] || 0);
  const transactionListRef = React.useRef<ITransactionList>(null);
  const accountContext = React.useContext(LedgerContext);
  const [updatePendingReconcileIds] = useUpdatePendingReconcileIdsMutation();
  const reloadReconciledTotal = useReloadReconciledTotal(ledgerId);
  const reconciledTotal = useSelector(selectReconciledTotalForAccount(ledgerId, accountId!));
  const subscription = useSelector(selectCurrentSubscription)!;
  const [reconcileMutation] = useReconcileMutation();
  const [working, setWorking] = React.useState<boolean>(false);
  const [getAccount] = useLazyGetAccountQuery();
  const [transactionCount, setTransactionCount] = React.useState<number | null>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
  }, []);

  React.useEffect(() => {
    if (!accountId && accounts.length > 0) {
      setAccountId(accounts[0].id);
    }
  }, [accounts]);

  React.useEffect(() => {
    if (accountId) {
      void reloadReconciledTotal(accountId);
      getAccount({ledgerId, accountId}).then(() => {
        setCurrentBankBalance(reconcileBankBalances[accountId] || 0);
      });
    }
  }, [accountId]);

  async function reconcile() {
    setWorking(true);
    try {
      let selection = transactionListRef.current?.getSelection();
      if (selection) {
        let _account = await reconcileMutation({ledgerId, accountId: accountId!, selection}).unwrap();
        setCurrentBankBalance(0);
        dispatch(setReconcileBankBalanceByAccount({accountId: accountId, balance: 0}));
        dispatch(setAccount(_account));
        await Promise.all([
          reloadReconciledTotal(accountId!),
          transactionListRef.current?.fetchToPage(1)
        ]);
      }
    } catch (e) {
      console.error(e);
    }
    setWorking(false);
  }

  if (accounts.length === 0) {
    return (
      <div className="text-center m-4">
        <div className="flex-column align-items-start" style={{marginTop: 200}}>
          <InstructionsContainer>
            {formatMessage(MSG_addAnAccountMessage)}
          </InstructionsContainer>
          <ArrowImg src={arrowImg}/>
        </div>
      </div>
    );
  }

  const pendingReconcileAmount = account?.pendingReconcileAmount || 0;
  const total = (reconciledTotal || 0) + pendingReconcileAmount - currentBankBalance;

  return (
    <div>
      <BorderlessStyledCard className="mb-4">
        <StyledCardHeader>
          <table>
            <tbody>
            <tr>
              <td className="text-right pe-4 text-nowrap">
                {formatMessage(MSG_accountLabel)}
              </td>
              <td/>
              <td className="text-right text-medium text-bold">
                <SelectInput
                  style={{width: '100%'}}
                  value={accountId}
                  onChange={e => {
                    setAccountId(e.currentTarget.value);
                  }}
                >
                  {accounts.map(a => (
                    <option key={a.id} value={a.id}>{a.name}</option>
                  ))}
                </SelectInput>
              </td>
              <td/>
            </tr>

            {transactionCount !== 0 && (
              <React.Fragment>
                <tr>
                  <td className="text-right pe-4 text-nowrap">
                    {formatMessage(MSG_reconcilePreviousBalanceLabel)}
                  </td>
                  <td/>
                  <td className="text-right text-medium text-bold pe-3">
                    {reconciledTotal !== null ? formatCurrency(reconciledTotal, subscription) : <Spinner/>}
                  </td>
                  <td className="ps-4">
                    {account?.lastReconciledAt &&
                      <div className="d-flex flex-row align-items-center">
                        <FontAwesomeIcon icon={faCaretLeft}/>
                        <div className="ps-1">{formatMessage(MSG_lastReconciledOnDate, {date: formatDate(account.lastReconciledAt, subscription)})}</div>
                      </div>}
                  </td>
                </tr>
                <tr>
                  <td className="text-right pe-4 text-nowrap">
                    {formatMessage(MSG_reconcileSelectedTotalLabel)}
                  </td>
                  <td className="text-bold text-center pe-2">
                    <FontAwesomeIcon icon={faPlus}/>
                  </td>
                  <td className="text-right text-medium text-bold pe-3">
                    {formatCurrency(pendingReconcileAmount, subscription)}
                  </td>
                </tr>
                <tr>
                  <td className="text-right pe-4 text-nowrap">
                    {formatMessage(MSG_reconcileBankBalanceLabel)}
                  </td>
                  <td className="text-bold text-center pe-2">
                    <FontAwesomeIcon icon={faMinus}/>
                  </td>
                  <td className="text-right text-bold">
                    <CurrencyInput
                      disabled={working}
                      value={currentBankBalance}
                      style={{width: 150}}
                      inputClassName="text-medium text-right"
                      blankZerosOnFocus
                      onChange={val => {
                        setCurrentBankBalance(val);
                        dispatch(setReconcileBankBalanceByAccount({accountId: accountId, balance: val}));
                      }}
                    />
                  </td>
                  <td className="ps-4">
                    <div className="d-flex flex-row align-items-center">
                      <FontAwesomeIcon icon={faCaretLeft}/>
                      <div className="ps-1">{formatMessage(MSG_reconcileEnterCurrentBankBalance)}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <LineStyled/>
                  </td>
                </tr>
                <tr>
                  <td/>
                  <td/>
                  <td className="text-right text-medium text-bold pe-3">
                    {formatCurrency(total, subscription)}
                  </td>
                  <td className="ps-4">
                    <div className="d-flex flex-row align-items-center">
                      <FontAwesomeIcon icon={faCaretLeft}/>
                      <span className="ps-1">{formatMessage(MSG_reconcileThisShouldBe, {amount: formatCurrency(0, subscription)})}</span>
                      {total === 0 && <FontAwesomeIcon icon={faCheckCircle} className="ps-1 text-success"/>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td/>
                  <td/>
                  <td className="text-right pt-3 pb-3">
                    <Button onClick={reconcile} disabled={working || total !== 0} spinner={working}>
                      {formatMessage(MSG_reconcileTitle)}
                    </Button>
                  </td>
                  <td className="ps-4">
                    {total !== 0 ? (
                      <div className="d-flex flex-row align-items-center text-alert-red">
                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                        <div className="ps-2" style={{maxWidth: 500}}>
                          {total > 0
                            ? formatMessage(MSG_reconcileSelectedTransactionsMore)
                            : formatMessage(MSG_reconcileBankBalanceMore)}
                        </div>
                      </div>
                    ) : (
                      pendingReconcileAmount !== 0 && (
                        <HorizontalBounce className="d-flex flex-row align-items-center text-large text-warning">
                          <FontAwesomeIcon icon={faArrowLeft}/>
                        </HorizontalBounce>
                      )
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )}
            </tbody>
          </table>

          {transactionCount === 0 && (
            <div className="text-center mt-8 mb-8">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success text-large me-2"/>
              <span className="text-success text-large">{formatMessage(MSG_allReconciledForAccount)}</span>
            </div>
          )}
        </StyledCardHeader>
      </BorderlessStyledCard>

      <div className="d-flex flex-row justify-content-space-between mb-2">
        <div/>
        <div>
          <Button className="me-2"
                  onClick={() => transactionListRef.current?.selectAll()}>
            {formatMessage(MSG_selectAll)}
          </Button>

          <Button onClick={() => transactionListRef.current?.selectNone()}>
            {formatMessage(MSG_selectNone)}
          </Button>
        </div>
      </div>

      <TransactionList
        ref={transactionListRef}
        transactionEditorModalRef={accountContext.transactionEditorModalRef}
        ledgerId={ledgerId}
        accountId={accountId}
        onlyUnreconciled
        disabled={working}
        emptyMessage={formatMessage(MSG_noUnreconciledTransactionsFound)}
        emptyMessageForQuery={formatMessage(MSG_noUnreconciledTransactionsFoundMatchingQuery)}
        emptyMessageInEnvelope={formatMessage(MSG_noUnreconciledTransactionsFoundInEnvelope)}
        setTransactionCount={setTransactionCount}
        onSelectionChanged={async (selection) => {
          try {
            const data = await updatePendingReconcileIds({ledgerId, accountId: account.id, selection}).unwrap();
            dispatch(setAccount(data));
          } catch (e) {
            console.error(e);
          }
        }}
      />
    </div>
  );
};

const LineStyled = styled.hr`
  border: 0;
  border-top: 1px solid ${({theme}) => theme.colors.textColor};
  margin: 0.5rem 0;
`;

const HorizontalBounce = styled.div`
  @keyframes horizontalBounce {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(1rem); /* Adjust the value for more or less movement */
    }
  }

  .fa-arrow-left {
    animation: horizontalBounce 1s ease-in-out infinite;
  }
`;

const InstructionsContainer = styled.div`
  max-width: 24rem;
  font-size: 1.2rem;
  font-weight: bold;
  transform: rotate(-2deg);
`;

const ArrowImg = styled.img`
  height: 7rem;
  margin-left: 3rem;
  margin-right: 2rem;
  transform: scaleX(-1) scaleY(-1) rotate(-120deg);
`;

export {ReconcilePage};
