import * as React from "react";
import { PropsWithChildren } from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import { useUpdateUserMutation } from "shared/state/endpoints/app/users_api";
import { selectCurrentUser, setUser } from "shared/state/store";
import {
  MSG_checkOutMobileApp,
  MSG_closeThisGuideButton,
  MSG_searchForMobileApp,
  MSG_welcomeToNeoBudgetTitle
} from "shared/strings/get_started";
import { MSG_or } from "shared/strings/generic";
import { Button } from "@web/components/Button";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/pro-solid-svg-icons/faCirclePlay";
import { faPartyHorn } from "@fortawesome/pro-light-svg-icons/faPartyHorn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IVideoModal, VideoModal } from "@web/components/shared/VideoModal";

import iosBadge from "shared/images/badge-apple-app-store.png";
import androidBadge from "shared/images/badge-google-play.png";
import thumbnail from "shared/images/thumbnail-get-started.jpg";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { DANGER_COLOR } from "NeoBudget/utils/constants";

interface IProps {}

const GetStartedPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const [savingFlag, setSavingFlag] = React.useState<boolean>(false);
  const [updateUserMutation] = useUpdateUserMutation();
  const currentUser = useSelector(selectCurrentUser)!;
  const videoModalRef = React.useRef<IVideoModal>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function close() {
    setSavingFlag(true);
    dispatch(setUser(await updateUserMutation({
      user: {
        id: currentUser.id,
        flags: {getStartedVisible: false}
      }
    }).unwrap()));
    navigate('/app');
    setSavingFlag(false);
  }

  return (
    <React.Fragment>
      <PageWrapper style={{overflow: 'hidden'}}>
        <Block/>

        <div className="flex-row align-items-top justify-content-center">
          <div>
            <div className="text-center mb-6">
              <FontAwesomeIcon icon={faPartyHorn} style={{fontSize: '4rem'}}/>
              <h2 className="mt-4 text-bold">{formatMessage(MSG_welcomeToNeoBudgetTitle)}</h2>
            </div>

            <InstructionsContainer>
              <p>
                We went ahead and created your first ledger and your first account for you.  Feel free to change the
                names! Go ahead and click on it to start adding your own envelopes.
              </p>

              <Step>
                <Number>1</Number>
                <Instructions>
                  Click on the My Ledger tab in the left sidebar and <strong>add some envelopes</strong>.
                </Instructions>
              </Step>
              <Step>
                <Number>2</Number>
                <Instructions>
                  Configure your budget by <strong>adding income sources and expenses</strong>.
                </Instructions>
              </Step>
              <Step>
                <Number>3</Number>
                <Instructions>
                  <strong>Import some transactions</strong> from your bank.
                </Instructions>
              </Step>
            </InstructionsContainer>

            <div className="text-center mt-6">
              &ndash; {formatMessage(MSG_or)} &ndash;
            </div>

            <div className="text-center mt-6">
              <p className="mb-4">Watch a quick 5-minute video that shows you how to get started.</p>

              <VideoThumbnailContainer className="clickable" onClick={() => videoModalRef.current?.show()}>
                <img src={thumbnail} width="400"/>
                <FontAwesomeIcon icon={faCircle} style={{color: '#fff'}}/>
                <FontAwesomeIcon icon={faCirclePlay} className="fg" style={{color: DANGER_COLOR}}/>

              </VideoThumbnailContainer>

              <div className="mt-4">
                <Button className="me-2" color="secondary" link
                        onClick={close} disabled={savingFlag} spinner={savingFlag}>
                  {formatMessage(MSG_closeThisGuideButton)}
                </Button>
              </div>
            </div>
          </div>

          <PhoneSidebar>
            <p className="mb-4">{formatMessage(MSG_checkOutMobileApp)}</p>

            <a href="https://apps.apple.com/us/app/neobudget/id922560210" target="_blank"><img src={iosBadge} style={{height: 50}} className="mt-4"/></a>
            <a href="https://play.google.com/store/apps/details?id=com.neobudget.mobile" target="_blank"><img src={androidBadge} style={{height: 50}} className="mt-4"/></a>

            <p className="mt-4">{formatMessage(MSG_searchForMobileApp)}</p>
          </PhoneSidebar>
        </div>

        <VideoModal ref={videoModalRef} museId="tEkUEv5"/>

      </PageWrapper>
    </React.Fragment>
  );
};

const PageWrapper = styled.div`
  position: relative;
  padding: 2rem;
  width: 100%;

  strong {
    background-color: #fea;
  }
`;

const Block = styled.div`
  background-color: ${({theme}) => theme.colors.primary};
  opacity: 0.075;
  position: absolute;
  top: -5rem;
  left: 0;
  right: 0;
  height: 16rem;
  transform: skewY(-6deg);
`;

const InstructionsContainer = styled.div`
  width: 40rem;
  margin: auto;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
`;

const Number = styled.div`
  background: ${({theme}) => theme.colors.info};
  color: #fff;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
`;

const Instructions = styled.div`
`;

const PhoneSidebar = styled.div`
  margin-left: 4rem;
  width: 18rem;
  background: #f6f6f6;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 2rem;
`;

const VideoThumbnailContainer = styled.div`
  position: relative;
  display: inline-block;

  img {
    overflow: hidden;
    border-radius: 0.5rem;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;

    &.fg {
      opacity: 1;
    }
  }

  &:hover svg.fg {
    opacity: 0.9;
  }
`;

export {GetStartedPage};
