import * as React from "react";
import { PropsWithChildren } from "react";
import {
  StyledCardSettingsActions,
  StyledCardSettingsBody,
  StyledCardSettingsIcon,
  StyledCardSettingsLabel,
  StyledCardSettingsValue
} from "@web/components/styled/StyledCard";
import { MSG_numberFormatLabel } from "shared/strings/settings";
import { useIntlFormatters } from "shared/utils/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/pro-light-svg-icons/faCalculator";
import { faEdit } from "@fortawesome/pro-light-svg-icons/faEdit";
import { ISettingsEditorModal, SettingsEditorModal } from "@web/modals/settings_editor_modal/SettingsEditorModal";
import { useUpdateSubscriptionMutation } from "shared/state/endpoints/app/subscriptions_api";
import { selectCurrentSubscription, selectCurrentUser, setCurrentSubscription } from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { OptionSelectorProvider } from "@web/components/shared/OptionSelector";
import { NUMBER_FORMATS } from "shared/utils/helpers";
import { ROLE } from "shared/utils/api_types";

interface IProps {
}

const NumberFormatSettings: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage, formatNumber} = useIntlFormatters();
  const modalRef = React.useRef<ISettingsEditorModal>(null);
  const subscription = useSelector(selectCurrentSubscription)!;
  const [value, setValue] = React.useState<string>(subscription.numberFormat);
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const editable = currentUser?.role === ROLE.OWNER;

  React.useEffect(() => setValue(subscription.numberFormat), [subscription.numberFormat]);

  async function save() {
    const subscription = await updateSubscription({numberFormat: value}).unwrap();
    dispatch(setCurrentSubscription(subscription));
  }

  function cancel() {
    setValue(subscription.numberFormat);
  }

  const OptionsSelector = OptionSelectorProvider<string>();
  const number = 12345.67;

  return (
    <React.Fragment>
      <StyledCardSettingsBody className={editable ? "clickable" : ''}
                              onClick={editable ? () => modalRef.current?.show() : undefined}>
        <StyledCardSettingsIcon>
          <FontAwesomeIcon icon={faCalculator}/>
        </StyledCardSettingsIcon>
        <StyledCardSettingsLabel className="clickable-title">
          {formatMessage(MSG_numberFormatLabel)}
        </StyledCardSettingsLabel>
        <StyledCardSettingsValue>
          {formatNumber(number, subscription)}
        </StyledCardSettingsValue>
        {editable && (
          <StyledCardSettingsActions>
            <FontAwesomeIcon icon={faEdit}/>
          </StyledCardSettingsActions>)}
      </StyledCardSettingsBody>

      <SettingsEditorModal save={save} onCancel={cancel} ref={modalRef} width="20rem">
        <OptionsSelector
          value={value}
          onChange={setValue}
          options={NUMBER_FORMATS.map(format => ({
            value: format,
            label: formatNumber(number, subscription, {format})
          }))}
        />
      </SettingsEditorModal>
    </React.Fragment>
  );
};

export {NumberFormatSettings};
