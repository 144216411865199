export const MSG_exportTitle = {
  id: "f2307464-ef83-4bb5-bc2e-0f095826dac9",
  description: "The label for the button that lets them export transactions.",
  defaultMessage: "Export"
};

export const MSG_exportExplanation = {
  id: "62f81340-fc1b-45be-8ca5-e55da71abcb5",
  description: "",
  defaultMessage: "Download your transaction history."
};

export const MSG_downloadTitle = {
  id: "32b47132-6f96-442b-9c10-ad8675cf147c",
  description: "The label for the button that lets them download the transactions they are exporting.",
  defaultMessage: "Download"
};

export const MSG_timeframeLabel = {
  id: "e6e073cd-eafc-4292-8561-ac50c0b54671",
  description: "The label for where they can select the timeframe for the transactions they are exporting (e.g. year-to-date, last year, last month, etc).",
  defaultMessage: "Timeframe"
};

export const MSG_timeframeAllTimeLabel = {
  id: "56e48e80-6a6d-4d42-b028-2f5b3dd901fa",
  description: "A label when selecting the timeframe to export transactions. This is the option to export all transactions.",
  defaultMessage: "All time"
};

export const MSG_timeframeYearToDateLabel = {
  id: "83853e60-c2b5-4b38-a1a7-27bc5bea2fb2",
  description: "A label when selecting the timeframe to export transactions. This is the option to export transactions from the beginning of the year to the current date.",
  defaultMessage: "Year to date"
};

export const MSG_timeframeMonthToDateLabel = {
  id: "cca9a7c8-295e-4972-b9a9-335f9a8d124f",
  description: "A label when selecting the timeframe to export transactions. This is the option to export transactions from the beginning of the month to the current date.",
  defaultMessage: "Month to date"
};

export const MSG_timeframeLastMonthLabel = {
  id: "4903da64-3165-4d35-8c6f-be8cbbb535eb",
  description: "A label when selecting the timeframe to export transactions. This is the option to export transactions from the beginning of the previous month to the end of the previous month.",
  defaultMessage: "Last month"
};

export const MSG_timeframeLastYearLabel = {
  id: "7aa5956d-ee81-40ed-9660-f10a62f2636a",
  description: "A label when selecting the timeframe to export transactions. This is the option to export transactions from the beginning of the previous year to the end of the previous year.",
  defaultMessage: "Last year"
};

export const MSG_timeframeTodayLabel = {
  id: "4827f824-ad1e-4fa0-b0fe-3c949be1b452",
  description: "",
  defaultMessage: "Today"
};

export const MSG_allEnvelopesTitle = {
  id: "017571e4-8f4c-4323-ad7e-ea067c3cdd9a",
  description: "The caption for a button that lets them select all envelopes.",
  defaultMessage: "All Envelopes"
};

export const MSG_neobudgetExportFilename = {
  id: "e13633f3-a596-43c9-a470-25aba3f0c7c5",
  description: "The filename for the exported transactions.",
  defaultMessage: "NeoBudget Export"
};
