export const MSG_monthDuration = {
  id: "f4a6e580-aef0-4024-a9b8-1d52d9958d53",
  description: "This will read like:  '1 month' or '3 months'",
  defaultMessage: "{months, plural, one {1 month} other {# months}}"
};

export const MSG_yearMonthDuration = {
  id: "9dcd0c59-9eea-4fa2-8cab-88dbd6b82782",
  description: "This will read like: '1 year and 7 months'",
  defaultMessage: "{years, plural, one {1 year} other {# years}} and {months, plural, one {1 month} other {# months}}"
};

export const MSG_yearDuration = {
  id: "394cf6ac-d64b-4d2f-9550-9ab303ad7500",
  description: "This will read like: '1 year' or '3 years'",
  defaultMessage: "{years, plural, one {1 year} other {# years}}"
};

export const MSG_searchLabel = {
  id: "0c6ec2f6-cfc5-4541-9501-78bd11844862",
  description: "A placeholder text that appears inside of a search field that lets them know they can type in here to search for something.",
  defaultMessage: "Search..."
};

export const MSG_selectOneLabel = {
  id: "cf152882-9945-4085-abdd-d1f783221d65",
  description: "This is a label for a drop-down list where they are prompted to select one of the options from the list.",
  defaultMessage: "Select one..."
};

export const MSG_saveButton = {
  id: "2bbe32c9-7f1e-47e5-b39e-02ff2050c676",
  description: "The button that saves something to the database.",
  defaultMessage: "Save"
};

export const MSG_saveAndNewButton = {
  id: "190e020d-6d8f-496a-85cf-7603f68781ab",
  description: "The button that saves something to the database and then shows a new form to enter a new item.",
  defaultMessage: "Save & New"
};

export const MSG_saveAndCloseButton = {
  id: "0c4a2bd1-edd3-4503-abd3-70c4b366598b",
  description: "The button that saves something to the database and then closes the current window.",
  defaultMessage: "Save & Close"
};

export const MSG_cancelButton = {
  id: "c9a85631-272d-462d-9e72-5b1a0bbe0dec",
  description: "The button that cancels all changes",
  defaultMessage: "Cancel"
};

export const MSG_okButton = {
  id: "6e5a68a2-e2f8-4253-b3c4-d60b273ef2b1",
  description: "The button that confirms that the user wants to do the action.",
  defaultMessage: "OK"
};


export const MSG_deleteButton = {
  id: "625fbb19-e3f6-4dcd-9ebe-ee61c65528c5",
  description: "The button that deletes something from the database.",
  defaultMessage: "Delete"
};

export const MSG_editButton = {
  id: "53c44f59-e682-4921-98bb-e4d0182625c1",
  description: "The button that edits something to change it.",
  defaultMessage: "Edit"
};

export const MSG_unknownLabel = {
  id: "d69b83af-4bdd-46bf-bdd8-4223b7b56b76",
  description: "A placeholder for when a value is not known.",
  defaultMessage: "Unknown"
};

export const MSG_changeButton = {
  id: "89203cef-f425-4740-b245-ac85e5344f1f",
  description: "The button that lets them change something.",
  defaultMessage: "Change"
};

export const MSG_closeButton = {
  id: "c7c8e572-77c8-4f8a-b368-7720448a2e33",
  description: "A button they click to close a window.",
  defaultMessage: "Close"
};

export const MSG_yesButton = {
  id: "a5c1185f-8fc1-4d63-8389-5712e906208b",
  description: "A button they click to confirm that 'Yes' they want to do the action.",
  defaultMessage: "Yes"
};

export const MSG_selectAll = {
  id: "dbe9c34a-2c8f-4acc-88f5-59776f1566bb",
  description: "A button that lets the user select all the options at once.",
  defaultMessage: "Select All"
};

export const MSG_selectNone = {
  id: "268a90ee-8687-4425-8676-2dd0adc84d2a",
  description: "A button that lets the user unselect all the options at once (e.g. select none)",
  defaultMessage: "Select None"
};

export const MSG_selectLikely = {
  id: "df5d2195-4be2-4248-8af7-ba5fb0e03655",
  description: "A button that lets the user select the options that we think they are likely to want.",
  defaultMessage: "Select Likely"
};

export const MSG_none = {
  id: "757fdab8-166a-409b-a02c-ace34dc8468d",
  description: "An indicator that none of the options have been selected.",
  defaultMessage: "None"
};


export const MSG_unexpectedError = {
  id: "5e41f453-b5fd-431f-94f6-5ed22dfe9540",
  description: "",
  defaultMessage: "An unexpected error occurred.  Please reload the page and try again."
};

export const MSG_errorTitle = {
  id: "523b19d8-0e4c-4b56-bd53-81844ad791f2",
  description: "A title for an error message.",
  defaultMessage: "Error"
};


export const MSG_firstOrdinal = {
  id: "85cc69c7-d343-4fef-87d1-5dad6dc16f8d",
  description: "The first ordinal number.  This is used in a list of items to show the order of the items.  For example, '1st', '2nd', '3rd', '4th', etc.",
  defaultMessage: "1st"
};

export const MSG_secondOrdinal = {
  id: "b0b5b2a1-9b9a-4b9f-8b1f-1b9b9b9b9b9b",
  description: "The second ordinal number.  This is used in a list of items to show the order of the items.  For example, '1st', '2nd', '3rd', '4th', etc.",
  defaultMessage: "2nd"
};

export const MSG_thirdOrdinal = {
  id: "c0c5c2a1-9c9a-4c9f-8c1f-1c9c9c9c9c9c",
  description: "The third ordinal number.  This is used in a list of items to show the order of the items.  For example, '1st', '2nd', '3rd', '4th', etc.",
  defaultMessage: "3rd"
};

export const MSG_fourthOrdinal = {
  id: "d0d5d2a1-9d9a-4d9f-8d1f-1d9d9d9d9d9d",
  description: "The fourth ordinal number.  This is used in a list of items to show the order of the items.  For example, '1st', '2nd', '3rd', '4th', etc.",
  defaultMessage: "4th"
};

export const MSG_fifthOrdinal = {
  id: "e0e5e2a1-9e9a-4e9f-8e1f-1e9e9e9e9e9e",
  description: "The fifth ordinal number.  This is used in a list of items to show the order of the items.  For example, '1st', '2nd', '3rd', '4th', etc.",
  defaultMessage: "5th"
};

export const MSG_termsOfService = {
  id: "2a69db2f-1df1-4770-8a7e-08967f22f830",
  description: "",
  defaultMessage: "Terms of Service"
};

export const MSG_privacyPolicy = {
  id: "0da116c2-0c58-49cc-8fc5-482ac35c2a92",
  description: "",
  defaultMessage: "Privacy Policy"
};

export const MSG_copyrightNotice = {
  id: "639b1ea4-8ba5-486a-aeeb-222e564a00fe",
  description: "",
  defaultMessage: "{copyrightDate} NeoBudget, LLC. All rights reserved."
};

export const MSG_clearButton = {
  id: "8e2ecb69-2419-4561-af8c-e5982c9a6571",
  description: "The button that clears a selection (as in, resets it)",
  defaultMessage: "Clear"
};

export const MSG_selectFileButton = {
  id: "74c9e7c9-e241-4cb2-91ad-bffc52b06178",
  description: "A button that they can click to select a file to upload.",
  defaultMessage: "Select File"
};

export const MSG_or = {
  id: "8610711c-96db-456b-9342-9085129bac83",
  description: "A word that means 'or' and fits between two optional buttons for them to click. Example:  <Button1> or <Button2>",
  defaultMessage: "or"
};

export const MSG_pageNotFound1 = {
  id: "c73670a1-5da0-4915-9753-0e1a64a78c0b",
  description: "",
  defaultMessage: "The page you are looking for could not be found."
};

export const MSG_pageNotFound2 = {
  id: "a07a3910-c609-44a1-9e7e-43981b778e73",
  description: "",
  defaultMessage: "Please select a tab on the left to continue."
};

export const MSG_whatsNewTitle = {
  id: "842e113b-2ad1-4c31-a7aa-0e40ce5844bf",
  description: "A title for the 'What's New' section of the site where they can find out what's new in the latest version of the app. This word/phrase to be no more than 12 characters long.",
  defaultMessage: "What's New"
};

export const MSG_serverErrorMessage = {
  id: "38ce9a4d-9638-4f24-a88a-a5d13e38e911",
  description: "This is a message we show when something goes wrong.",
  defaultMessage: "We are currently experiencing technical difficulties."
};

export const MSG_tryAgainLater = {
  id: "942423cf-2271-4697-bbec-f906caf11ebd",
  description: "This is a message that appears when something goes wrong and we don't know why.  It tells them to try again later.",
  defaultMessage: "Please try again in a little while."
};

export const MSG_noticeTitle = {
  id: "6aab7b0e-a62d-44d1-b941-11246f2d6e3f",
  description: "A title for a notice that we want to show them.  This word/phrase to be no more than 12 characters long.",
  defaultMessage: "Notice"
};

export const MSG_whatIsThis = {
  id: "a47ba0c6-e9d3-40c5-b39d-b9a982db095a",
  description: "",
  defaultMessage: "What is this?"
};

export const MSG_howDoesThisWork = {
  id: "dcde2159-d0a9-497d-aa61-d9998de684cb",
  description: "",
  defaultMessage: "How does this work?"
};

