import * as React from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_cancelButton, MSG_closeButton, MSG_saveButton } from "shared/strings/generic";
import { Modal } from "@web/components/Modal";
import { Button } from "@web/components/Button";
import {
  ApiError,
  BudgetedExpense,
  Envelope,
  IncomeAllocation,
  IncomeSource,
  ROLE,
  User
} from "shared/utils/api_types";
import { parseApiError } from "shared/utils/api_errors";
import { EnvelopeName } from "@web/components/shared/EnvelopeName";
import {
  MSG_addExpenseToGetStarted,
  MSG_fundedAmountWarning,
  MSG_missingIncomeSourcesExplanation,
  MSG_monthlyExpensesTitle,
  MSG_monthlyFundingTitle,
  MSG_newExpenseTitle,
  MSG_noExpensesForEnvelope,
  MSG_ordinalCheckTitle,
  MSG_splitAmountEvenlyAcrossAllChecks,
  MSG_totalMonthlyExpensesLabel,
  MSG_totalMonthlyFunding
} from "shared/strings/budget";
import { useSelector } from "react-redux";
import { BudgetedExpenseInputRow } from "./BudgetedExpenseInputRow";
import arrowImg from "shared/images/drawn-arrow.png";
import styled, { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MonthlyFundingInputRow } from "./MonthlyFundingInputRow";
import { calculateBudgetValues, frequencyHasExtra, getNumChecks } from "shared/utils/helpers";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { useReloadLedger } from "shared/hooks/use_reload";
import {
  selectBudgetedExpensesForLedger,
  selectCurrentSubscription,
  selectCurrentUser,
  selectIncomeAllocationsForLedger,
  selectIncomeSourcesForLedger
} from "shared/state/store";
import { useUpdateBudgetForEnvelopeMutation } from "shared/state/endpoints/app/ledgers_api";
import { LinkButton } from "@web/components/LinkButton";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { SplitFundingPopover } from "@web/popovers/split_funding_popover/SplitFundingPopover";
import { IPopover } from "@web/components/Popover";

interface IProps {
  ledgerId: string;
  numChecks: number;
}

interface IEnvelopeBudgetEditorModal {
  show: (_envelope: Envelope) => any;
  hide: () => any;
}

const EnvelopeBudgetEditorModal = React.forwardRef<IEnvelopeBudgetEditorModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const {formatOrdinal, formatMessage, formatCurrency} = useIntlFormatters();
  const [envelope, setEnvelope] = React.useState<Envelope | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [expenses, setExpenses] = React.useState<Partial<BudgetedExpense>[]>([]);
  const budgetedExpenses = useSelector(selectBudgetedExpensesForLedger(props.ledgerId));
  const incomeAllocations = useSelector(selectIncomeAllocationsForLedger(props.ledgerId));
  const incomeSources = useSelector(selectIncomeSourcesForLedger(props.ledgerId));
  const reloadAccount = useReloadLedger();
  const [allocations, setAllocations] = React.useState<{[incomeSourceId: string]: Partial<IncomeAllocation>[]}>({});
  const [updateBudgetForEnvelope] = useUpdateBudgetForEnvelopeMutation();
  const subscription = useSelector(selectCurrentSubscription)!;
  const [autoFocusLast, setAutoFocusLast] = React.useState<boolean>(false);
  const splitFundingPopoverRef = React.useRef<IPopover>(null);
  const splitEvenlyButtonRef = React.useRef<HTMLButtonElement>(null);
  const currentUser = useSelector(selectCurrentUser) as User;
  const theme = useTheme();

  React.useImperativeHandle<any, IEnvelopeBudgetEditorModal>(ref, () => ({
    show: (_envelope: Envelope) => {
      setSaving(false);
      setErrors(undefined);
      setVisible(true);
      setEnvelope(_envelope);
      setExpenses(budgetedExpenses.filter(expense => expense.envelopeId === _envelope.id));
      setAutoFocusLast(false);

      const _allocations: {[incomeSourceId: string]: IncomeAllocation[]} = {};
      incomeAllocations.filter(alloc => alloc.envelopeId === _envelope.id).forEach((allocation) => {
        const incomeSourceId = allocation.incomeSourceId as string;
        if (!_allocations[incomeSourceId]) _allocations[incomeSourceId] = [];
        _allocations[incomeSourceId][allocation.sequenceNumber] = allocation;
      });
      setAllocations(_allocations);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    try {
      setSaving(true);

      await updateBudgetForEnvelope({
        ledgerId: props.ledgerId,
        envelopeId: envelope?.id as string,
        budgetedExpenses: expenses,
        incomeAllocations: Object.values(allocations).flat()
      }).unwrap();

      void reloadAccount(props.ledgerId);
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  function cancel() {
    setVisible(false);
  }

  function splitEvenly(source?: IncomeSource, includingExtra?: boolean) {
    if (!envelope) return;

    const newAllocations: {[incomeSourceId: string]: Partial<IncomeAllocation>[]} = {};
    let numChecks = 0;
    incomeSources.forEach(incomeSource => {
      if (!source || source.id === incomeSource.id) {
        let n = getNumChecks(incomeSource.frequency, {fractional: true});
        if (!includingExtra) {
          n = Math.floor(n);
        }
        numChecks += n;
      }
    });

    const splitAmount = Math.floor(-1 * totalExpenses / numChecks);
    let allocated = 0;

    incomeSources.forEach(incomeSource => {
      let n = getNumChecks(incomeSource.frequency, {fractional: true});
      if (!includingExtra) n = Math.floor(n);
      newAllocations[incomeSource.id] = Array(Math.ceil(n))
        .fill({})
        .map((val, i) => {
          let amount = (!source || source.id === incomeSource.id) ? splitAmount : 0;
          allocated += amount;
          return {
            envelopeId: envelope.id as string,
            method: 'fixed',
            incomeSourceId: incomeSource.id as string,
            sequenceNumber: i,
            amount: amount
          };
        });
    });

    let diff = (-1 * totalExpenses) - allocated;
    incomeSources.forEach(incomeSource => {
      let n = getNumChecks(incomeSource.frequency, {fractional: true});
      if (!includingExtra) n = Math.floor(n);
      Object.values(newAllocations[incomeSource.id as string]).forEach((allocation, i) => {
        if (diff > 0 && allocation?.amount) {
          diff -= 1;
          allocation.amount += 1;
        }
      });
    });

    setAllocations(newAllocations);
  }

  if (!envelope) return null;
  const localAllocations: Partial<IncomeAllocation>[] = [];
  incomeSources.forEach(source => {
    if (allocations[source.id as string]) {
      allocations[source.id as string].forEach((allocation, i) => {
        localAllocations.push(allocation);
      });
    }
  });
  let localBudgetedExpenses: Partial<BudgetedExpense>[] = [];
  expenses.forEach(expense => {
    localBudgetedExpenses.push(expense);
  });
  const envelopeValues = calculateBudgetValues(incomeSources, localAllocations, localBudgetedExpenses, [envelope]);
  const totalExpenses = envelopeValues[envelope.id].expensesSummary.average;
  const totalFunding = envelopeValues[envelope.id].fundingSummary.average;
  let hasExtra = incomeSources.some(source => frequencyHasExtra(source.frequency));

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      defaultFormAction={save}
      windowStyle={{width: '55rem'}}
      bodyStyle={{padding: '1rem', paddingBottom: '2rem'}}
      header={
        <div className="d-flex flex-row align-items-center justify-content-space-between">
          <div style={{width: '12rem'}}>
            <EnvelopeName envelope={envelope}/>
          </div>
          <table width="100%" className="ms-4">
            <tbody>
              <tr>
                <td className="text-right pe-2 text-nowrap">{formatMessage(MSG_totalMonthlyExpensesLabel)}</td>
                <td className="text-right">
                  <h2 style={{
                    color: totalExpenses < 0 ? theme.colors.negativeEnvelopeTextColor : undefined,
                    minWidth: '7rem',
                  }}>{formatCurrency(totalExpenses, subscription)}</h2>
                </td>
                <td width="50%">
                </td>
              </tr>
              <tr>
                <td className="text-right pe-2 text-nowrap">{formatMessage(MSG_totalMonthlyFunding)}</td>
                <td className="text-right">
                  <h2>{formatCurrency(totalFunding, subscription)}</h2>
                </td>
                <td width="50%">
                  {totalFunding + totalExpenses < 0 && (
                    <div className="d-flex flex-row align-items-center ps-2 text-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} className="me-2"/>
                      <div className="text-small" style={{lineHeight: '1.2em'}}>
                        {formatMessage(MSG_fundedAmountWarning)}
                      </div>
                    </div>)}
                  {totalFunding !== 0 && totalExpenses !== 0 && totalFunding + totalExpenses >= 0 && (
                    <div className="d-flex flex-row align-items-center ps-2 text-success" style={{fontSize: '150%'}}>
                      <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
                    </div>)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
      footer={
        <div className="d-flex flex-row align-items-center justify-content-space-between">
          <div>
          </div>
          {currentUser.role === ROLE.OBSERVER ? (
            <div>
              <Button color="secondary" onClick={cancel}
                      className="me-2">
                {formatMessage(MSG_closeButton)}
              </Button>
            </div>
          ) : (
            <div>
              <Button color="secondary" disabled={saving}
                      onClick={cancel}
                      className="me-2">
                {formatMessage(MSG_cancelButton)}
              </Button>
              <Button onClick={save}
                      disabled={saving} spinner={saving}>
                {formatMessage(MSG_saveButton)}
              </Button>
            </div>
          )}
        </div>
      }>

      {errors?.errorType === 'message' && (
        <div className="d-flex flex-row">
          <StyledErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            <div>{errors.message}</div>
          </StyledErrorMessage>
        </div>
      )}

      <div className="d-flex flex-row align-items-center justify-content-space-between"
           style={{padding: '0.5rem', margin: '0 -0.5rem', backgroundColor: theme.colors.lightBackgroundColor,
             borderRadius: theme.window.borderRadius
           }}>
        <h2>{formatMessage(MSG_monthlyExpensesTitle)}</h2>
        {currentUser.role !== ROLE.OBSERVER && (
          <Button onClick={() => {
            setExpenses([...expenses, {
              envelopeId: envelope.id as string,
              name: '',
              amount: 0,
              frequency: 'monthly'
            }]);
            setAutoFocusLast(true);
          }}>
            {formatMessage(MSG_newExpenseTitle)}
          </Button>
        )}
      </div>
      {expenses?.length === 0 && (
        currentUser.role === ROLE.OBSERVER ? (
          <div className="mt-4">
            {formatMessage(MSG_noExpensesForEnvelope)}
          </div>
        ) : (
          <div className="d-flex flex-row align-items-start justify-content-end"
               style={{marginRight: 75}}>
            <GetStartedText>
              {formatMessage(MSG_addExpenseToGetStarted)}
            </GetStartedText>
            <ArrowImg src={arrowImg} width="20"/>
          </div>
        )
      )}
      {expenses?.map((expense, i) => (
        <BudgetedExpenseInputRow
          key={i}
          expense={expense}
          autoFocus={(autoFocusLast && i === expenses.length - 1) || (!autoFocusLast && i === 0)}
          onChange={(newExpense) => {
            const newExpenses = [...expenses];
            newExpenses[i] = newExpense;
            setExpenses(newExpenses);
          }}
          onDelete={() => {
            const newExpenses = [...expenses];
            newExpenses.splice(i, 1);
            setExpenses(newExpenses);
          }}
        />))}

      <div className="d-flex flex-row align-items-center justify-content-space-between mt-8 mb-2"
           style={{padding: '0.5rem', margin: '0 -0.5rem', backgroundColor: theme.colors.lightBackgroundColor,
             borderRadius: theme.window.borderRadius
           }}>
        <h2>{formatMessage(MSG_monthlyFundingTitle)}</h2>
        {!!totalExpenses && currentUser.role !== ROLE.OBSERVER && (
          <div className="flex-grow-1 ms-4 text-small text-muted">
            {hasExtra ? (
              <React.Fragment>
                <LinkButton
                  ref={splitEvenlyButtonRef}
                  className="text-small"
                  onClick={() => {
                    splitFundingPopoverRef.current?.show();
                  }}>
                  {formatMessage(MSG_splitAmountEvenlyAcrossAllChecks, {amount: formatCurrency(-1 * totalExpenses, subscription)})}
                </LinkButton>
                &nbsp;<FontAwesomeIcon icon={faAngleRight}/>

                <SplitFundingPopover
                  ref={splitFundingPopoverRef}
                  targetRef={splitEvenlyButtonRef}
                  onSelect={async (includingExtra: boolean) => {
                    splitEvenly(undefined, includingExtra);
                  }}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <LinkButton
                  className="text-small"
                  onClick={() => {
                    splitEvenly();
                  }}>
                  {formatMessage(MSG_splitAmountEvenlyAcrossAllChecks, {amount: formatCurrency(-1 * totalExpenses, subscription)})}
                </LinkButton>
                &nbsp;<FontAwesomeIcon icon={faAngleRight}/>
              </React.Fragment>
            )}
          </div>)}
      </div>

      {incomeSources.length === 0 && (
        <div className="flex-row align-items-center p-4" style={{transform: 'rotate(-2deg)', width: '80%', margin: 'auto'}}>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{fontSize: '2rem', color: theme.colors.warning}}/>
          <div className="ms-3">{formatMessage(MSG_missingIncomeSourcesExplanation)}</div>
        </div>
      )}
      {incomeSources.length > 0 && (
        <table>
          <thead>
            <tr>
              <th/>
              {Array(props.numChecks).fill(null).map((val, i) => (
                <th key={i} className="text-center">
                  {formatMessage(MSG_ordinalCheckTitle, {ordinal: formatOrdinal(i + 1)})}
                </th>))}
            </tr>
          </thead>
          <tbody>
            {incomeSources.map((source, i) => (
              <MonthlyFundingInputRow
                key={source.id}
                index={i}
                numChecks={props.numChecks}
                totalExpenses={totalExpenses}
                envelopeId={envelope.id as string}
                incomeSource={source}
                incomeAllocations={allocations[source.id as string] || []}
                onChange={(newAllocation) => {
                  const newAllocations: {[id: string]: Partial<IncomeAllocation>[]} = {...allocations};
                  if (!newAllocations[source.id as string]) newAllocations[source.id as string] = [];
                  if (newAllocation.sequenceNumber === undefined) {
                    newAllocations[source.id as string].push(newAllocation);
                  } else {
                    newAllocations[source.id as string][newAllocation.sequenceNumber] = newAllocation;
                  }
                  setAllocations(newAllocations);
                }}
                onSplitEvenly={(includingExtra?: boolean) => {
                  splitEvenly(source, includingExtra);
                }}
              />
            ))}
          </tbody>
        </table>)}
    </Modal>
  );
});


const GetStartedText = styled.div`
  text-align: right;
  font-weight: bold;
  font-style: italic;
  margin-right: 15px;
  margin-top: 15px;
  font-size: 1.2rem;
  transform: rotate(-1deg);
`;

const ArrowImg = styled.img`
  transform: scaleX(-1) rotate(150deg);
`;

export {EnvelopeBudgetEditorModal, IEnvelopeBudgetEditorModal};
