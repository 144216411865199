import styled from "styled-components";

export const StyledWindowContainer = styled.div`
  border-style: solid;
  border-width: ${({theme}) => theme.window.borderWidth};
  border-radius: ${({theme}) => theme.window.borderRadius};
  border-color: ${({theme}) => theme.window.borderColor};
  padding: ${({theme}) => theme.window.padding};
  background-color: ${({theme}) => theme.window.backgroundColor};
`;
