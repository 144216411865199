import * as React from "react";
import { PropsWithChildren } from "react";
import styled, { useTheme } from "styled-components";

interface IProps {}

const LoadingPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const theme = useTheme();
  return (
    <Layout>
      <LoadingContainer>
        <SpinningBox className="rotate-center">
          <IconImg src={theme.brand.iconImg}/>
        </SpinningBox>
      </LoadingContainer>
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: 100px;
`;

const SpinningBox = styled.div`
  margin: auto;
  width: calc(100px + ${({theme}) => theme.brand.iconOffset });
  height: calc(100px + ${({theme}) => theme.brand.iconOffset });
  display: flex;
  flex-direction: column;
  ${({theme}) => {
    if (theme.brand.iconAlign === 'top') {
      return `
        align-items: center;
        justify-content: flex-start;
      `;
    } else if (theme.brand.iconAlign === 'bottom') {
      return `
        align-items: center;
        justify-content: flex-end;
      `;
    } else if (theme.brand.iconAlign === 'left') {
      return `
        align-items: flex-start;
        justify-content: center;
      `;
    } else if (theme.brand.iconAlign === 'right') {
      return `
        align-items: flex-end;
        justify-content: center;
      `;
    }
  }}
`;

const IconImg = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

const LoadingContainer = styled.div`
  color: ${({theme}) => theme.loadingWindow.textColor};
  text-align: center;
  font-size: 2rem;
  animation: fade-in 500ms ease-in both;

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .rotate-center {
    -webkit-animation: rotate-center 2s ease-in-out infinite both;
    animation: rotate-center 1.25s ease-in-out infinite both;
  }

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export {LoadingPage};
