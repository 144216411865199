import * as React from "react";
import { forwardRef } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { StyledInput } from "../styled/StyledInput";
import { omit } from "lodash";
import { opacify } from "color2k";

interface ITextInputRef extends HTMLInputElement {}

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement>{
  className?: string;
  inputClassName?: string;
  leftElementPadding?: CSSStyleDeclaration['padding'];
  leftElement?: React.ReactElement;
  rightElementPadding?: CSSStyleDeclaration['padding'];
  rightElement?: React.ReactElement;
  placeholder?: string;
  rows?: number;
}

const TextInput = forwardRef<ITextInputRef, ITextInputProps>((props, ref) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const Input = (props.rows || 0) > 1 ? StyledTextareaInput : StyledTextInput;

  return (
    <InputContainer className={props.className}>
      <Input
        type="text"
        ref={ref}
        placeholder={props.placeholder}
        onFocus={(e) => {
          setIsFocused(true);
          if (props.onFocus) props.onFocus(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          if (props.onBlur) props.onBlur(e);
        }}
        style={{
          paddingLeft: props.leftElementPadding || undefined,
          paddingRight: props.rightElementPadding || undefined,
          ...props.style,
        }}
        className={classNames({
          'has-left-element': props.leftElement,
          'has-right-element': props.rightElement,
        }, props.inputClassName)}
        {...omit(props, 'style', 'leftElementPadding', 'leftElement', 'rightElementPadding', 'rightElement', 'className', 'onFocus', 'onBlur')}
      />
      {props.leftElement && (
        <LeftContainer className={isFocused ? 'focused' : ''}>{props.leftElement}</LeftContainer>)}
      {props.rightElement && (
        <RightContainer className={isFocused ? 'focused' : ''}>{props.rightElement}</RightContainer>)}
    </InputContainer>
  );
});

const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const StyledTextInput = styled(StyledInput)`
  &.has-left-element {
    padding-left: 2.5rem;
  }
  &.has-right-element {
    padding-right: 2.5rem;
  }
`;

const StyledTextareaInput = styled(StyledInput).attrs({as: 'textarea'})`
  font-size: 0.9rem;
  font-weight: normal;
`;

const ElementContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 100%;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftContainer = styled(ElementContainer)`
  left: 0;
  color: ${({theme}) => opacify(theme.colors.textColor, -0.75)};
  &.focused {
    color: ${({theme}) => theme.colors.textColor};
  }
`;

const RightContainer = styled(ElementContainer)`
  right: 0;
  color: ${({theme}) => opacify(theme.colors.textColor, -0.75)};
  &.focused {
    color: ${({theme}) => theme.colors.textColor};
  }
`;


export {TextInput, ITextInputRef, ITextInputProps};
