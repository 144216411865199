import * as React from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_cancelButton, MSG_closeButton, MSG_saveButton } from "shared/strings/generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@web/components/Modal";
import { Button } from "@web/components/Button";
import {
  MSG_addIncomeSourceTitle,
  MSG_addIncomeSourceToGetStarted,
  MSG_incomeSourcesTitle,
  MSG_irregularIncomeExplanation
} from "shared/strings/budget";
import { ApiError, IncomeSource } from "shared/utils/api_types";
import {
  useCreateIncomeSourceMutation,
  useDeleteIncomeSourceMutation,
  useUpdateIncomeSourceMutation
} from "shared/state/endpoints/app/income_sources_api";
import { useReloadLedgers } from "shared/hooks/use_reload";
import { faLightbulb } from "@fortawesome/pro-light-svg-icons/faLightbulb";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import arrowImg from "shared/images/drawn-arrow.png";
import styled from "styled-components";
import { IncomeSourceInput } from "./IncomeSourceInput";
import { parseApiError } from "shared/utils/api_errors";

interface IProps {}

interface IIncomeSourcesEditorModal {
  show: (ledgerId: string, incomeSources: IncomeSource[]) => any;
  hide: () => any;
}

const IncomeSourcesEditorModal = React.forwardRef<IIncomeSourcesEditorModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const {formatMessage} = useIntlFormatters();
  const [saving, setSaving] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError[]>([]);
  const [incomeSources, setIncomeSources] = React.useState<Partial<IncomeSource>[]>([]);
  const [ledgerId, setLedgerId] = React.useState<string | null>(null);
  const [updateIncomeSource] = useUpdateIncomeSourceMutation();
  const [createIncomeSource] = useCreateIncomeSourceMutation();
  const [deleteIncomeSource] = useDeleteIncomeSourceMutation();
  const reloadLedgers = useReloadLedgers();

  React.useImperativeHandle<any, IIncomeSourcesEditorModal>(ref, () => ({
    show: (_ledgerId: string, _incomeSources: IncomeSource[]) => {
      setSaving(false);
      setErrors([]);
      setVisible(true);
      setLedgerId(_ledgerId);
      setIncomeSources(_incomeSources);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    if (!ledgerId || !incomeSources || !incomeSources.length) return false;

    let success = true;
    let newIncomeSources: Partial<IncomeSource>[] = [...incomeSources];
    await Promise.all(incomeSources.map(async (incomeSource, i) => {
      try {
        if (incomeSource.id) {
          newIncomeSources[i] = await updateIncomeSource({
            ledgerId: ledgerId,
            incomeSource: {
              id: incomeSource.id,
              name: incomeSource.name,
              amount: incomeSource.amount,
              frequency: incomeSource.frequency
            }
          }).unwrap();
        } else {
          newIncomeSources[i] = await createIncomeSource({
            ledgerId: ledgerId,
            incomeSource: {
              name: incomeSource.name,
              amount: incomeSource.amount,
              frequency: incomeSource.frequency
            }
          }).unwrap();
        }
        setIncomeSources(newIncomeSources);
      } catch (e) {
        setErrors((errors) => {
          const newErrors = [...errors];
          newErrors[i] = parseApiError(e);
          return newErrors;
        });
        setSaving(false);
        success = false;
      }
    }));

    await reloadLedgers();
    if (success) {
      setVisible(false);
    }
  }

  function cancel() {
    setVisible(false);
  }

  return (
    <Modal
      isOpen={visible}
      defaultFormAction={save}
      onRequestClose={() => setVisible(false)}
      windowStyle={{width: '50rem'}}
      bodyStyle={{padding: '1rem'}}
      header={
        <h2>{formatMessage(MSG_incomeSourcesTitle)}</h2>
      }
      footer={
        <div className="d-flex flex-row align-items-center justify-content-space-between">
          <div>
            <Button
              disabled={saving}
              spinner={saving}
              onClick={() => {
                setIncomeSources([...incomeSources, {
                  name: '',
                  amount: 0,
                  frequency: 'semimonthly',
                }]);
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle}/>{' '}
              {formatMessage(MSG_addIncomeSourceTitle)}
            </Button>
          </div>
          <div/>
          {incomeSources.length > 0 ? (
            <div>
              <Button color="secondary" disabled={saving}
                      onClick={cancel}
                      className="me-2">
                {formatMessage(MSG_cancelButton)}
              </Button>
              <Button onClick={save}
                      disabled={saving} spinner={saving}>
                {formatMessage(MSG_saveButton)}
              </Button>
            </div>
          ) : (
            <Button onClick={cancel}
              disabled={saving} spinner={saving}>
              {formatMessage(MSG_closeButton)}
            </Button>
          )}
        </div>
      }>

      {incomeSources.length > 0 && (
        <AlertBox>
          <FontAwesomeIcon icon={faLightbulb} className="text-large"/>
          <div className="ms-4">
            {formatMessage(MSG_irregularIncomeExplanation)}
          </div>
        </AlertBox>
      )}

      {incomeSources.length === 0 && (
        <div className="flex-row align-items-start mt-6">
          <ArrowImg src={arrowImg}/>
          <InstructionsContainer>
            {formatMessage(MSG_addIncomeSourceToGetStarted)}
          </InstructionsContainer>
        </div>
      )}

      {incomeSources.map((incomeSource, i) => (
        <IncomeSourceInput
          initialIncomeSource={incomeSource}
          errors={errors[i]}
          hideLabels={i !== 0}
          autoFocus={i === incomeSources.length - 1 && !incomeSources[i].name}
          key={i}
          onChange={(incomeSource) => {
             setIncomeSources(incomeSources.map((_incomeSource, j) => {
               if (i === j) {
                 return incomeSource;
               } else {
                 return _incomeSource;
               }
             }));
           }}
          onDelete={async () => {
            if (incomeSource.id && ledgerId) {
              await deleteIncomeSource({ledgerId: ledgerId, incomeSourceId: incomeSource.id});
              await reloadLedgers();
            }
            setIncomeSources(incomeSources.filter((_, j) => i !== j));
          }}
        />
      ))}
    </Modal>
  );
});

const ArrowImg = styled.img`
  height: 7rem;
  margin-left: 3rem;
  margin-right: 2rem;
  transform: scaleX(-1) rotate(-20deg);
`;

const InstructionsContainer = styled.div`
  max-width: 14rem;
  font-size: 1.2rem;
  font-weight: bold;
  transform: rotate(-2deg);
`;

const AlertBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: ${({theme}) => theme.window.borderRadius};
  margin-bottom: 1rem;
`;

export {IncomeSourcesEditorModal, IIncomeSourcesEditorModal};
