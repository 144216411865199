import * as React from "react";
import { PropsWithChildren } from "react";
import { StyledCardBody } from "@web/components/styled/StyledCard";
import { selectCurrentSubscription, selectCurrentUser, selectUser, setUser } from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { INVITATION_STATUS, ROLE, User } from "shared/utils/api_types";
import { StyledBadge } from "@web/components/styled/StyledBadge";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_activateButton,
  MSG_changePasswordButton,
  MSG_deactivateButton,
  MSG_deactivateLabel,
  MSG_invitationSentAlert,
  MSG_invitedLabel,
  MSG_lastBrowserAccessLabel,
  MSG_lastMobileAccessLabel,
  MSG_neverLoginLabel,
  MSG_observerLabel,
  MSG_ownerLabel,
  MSG_resendInvitationButton,
  MSG_stewardLabel
} from "shared/strings/users";
import { ProfilePicture } from "@web/components/shared/ProfilePicture";
import { MSG_editButton, MSG_unexpectedError } from "shared/strings/generic";
import { Button } from "@web/components/Button";
import { useSendInvitationMutation, useUpdateUserMutation } from "shared/state/endpoints/app/users_api";
import { useAlert } from "@web/utils/hooks";
import { faEnvelopeCircleCheck } from "@fortawesome/pro-light-svg-icons/faEnvelopeCircleCheck";
import { extractErrorMessages, parseApiError } from "shared/utils/api_errors";
import { getFullName } from "shared/utils/helpers";

interface IProps {
  userId: string;
  onEditClick?: () => any;
  onChangePasswordClick?: () => any;
}

const UserCardBody: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const currentUser = useSelector(selectCurrentUser) as User;
  const user = useSelector(selectUser(props.userId));
  const subscription = useSelector(selectCurrentSubscription)!;
  const {formatMessage, formatDate} = useIntlFormatters();
  const [updateUser] = useUpdateUserMutation();
  const [sendInvitation] = useSendInvitationMutation();
  const [activeToggling, setActiveToggling] = React.useState<boolean>(false);
  const [sendingInvitation, setSendingInvitation] = React.useState<boolean>(false);
  const {showAlert, showErrorAlert} = useAlert();
  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  async function toggleActive(e?: React.MouseEvent<Element, MouseEvent>) {
    e?.stopPropagation();
    setActiveToggling(true);
    try {
      dispatch(setUser(await updateUser({user: {id: user.id, active: !user.active}}).unwrap()));
    } catch (e) {
      showErrorAlert(extractErrorMessages(parseApiError(e))[0] || formatMessage(MSG_unexpectedError));
    } finally {
      setActiveToggling(false);
    }
  }

  async function handleInvitation(e?: React.MouseEvent<Element, MouseEvent>) {
    e?.stopPropagation();
    setSendingInvitation(true);
    try {
      dispatch(setUser(await sendInvitation(user.id).unwrap()));
      showAlert(formatMessage(MSG_invitationSentAlert, {email: user.email}), faEnvelopeCircleCheck);
    } finally {
      setSendingInvitation(false);
    }
  }

  async function handleChangePassword(e?: React.MouseEvent<Element, MouseEvent>) {
    e?.stopPropagation();
    props.onChangePasswordClick?.();
  }

  return (
    <StyledCardBody className={props.onEditClick ? 'clickable' : ''} onClick={props.onEditClick}>
      <div className="flex-row">
        <span style={{opacity: user.active ? 1 : 0.5 }}>
          <ProfilePicture imageUrl={user?.profilePictureUrl} size={48}/>
        </span>
        <div className="flex-grow-1 ms-4 me-4" style={{opacity: user.active ? 1 : 0.5 }}>
          <div className="text-bold">
            <span className="clickable-title">{getFullName(user)}</span>
            {user.role === ROLE.OWNER && <StyledBadge className="info ms-2">{formatMessage(MSG_ownerLabel)}</StyledBadge>}
            {user.role === ROLE.STEWARD && <StyledBadge className="info ms-2">{formatMessage(MSG_stewardLabel)}</StyledBadge>}
            {user.role === ROLE.OBSERVER && <StyledBadge className="info ms-2">{formatMessage(MSG_observerLabel)}</StyledBadge>}
            {!user.active && <StyledBadge className="danger ms-2">{formatMessage(MSG_deactivateLabel)}</StyledBadge>}
            {user.invitationStatus === INVITATION_STATUS.SENT && user.active && <StyledBadge className="warning ms-2">{formatMessage(MSG_invitedLabel)}</StyledBadge>}
          </div>
          <div>{user.email}</div>
          <div className="text-muted">
            {formatMessage(MSG_lastBrowserAccessLabel)}
            {' '}
            {user.lastWebAccessAt
              ? formatDate(user.lastWebAccessAt, subscription)
              : formatMessage(MSG_neverLoginLabel)}
          </div>
          <div className="text-muted">
            {formatMessage(MSG_lastMobileAccessLabel)}
            {' '}
            {user.lastMobileAccessAt
              ? formatDate(user.lastMobileAccessAt, subscription)
              : formatMessage(MSG_neverLoginLabel)}
          </div>
        </div>
        <div>
          {user.invitationStatus === INVITATION_STATUS.SENT && user.active && currentUser.role === ROLE.OWNER && (
            <Button className="me-2" color="secondary" onClick={handleInvitation}
                    disabled={sendingInvitation} spinner={sendingInvitation}>
              {formatMessage(MSG_resendInvitationButton)}
            </Button>
          )}
          {user.id !== currentUser.id && currentUser.role === ROLE.OWNER && (
            <Button className="me-2" color="secondary" onClick={toggleActive}
                    disabled={activeToggling} spinner={activeToggling}>
              {formatMessage(user.active ? MSG_deactivateButton : MSG_activateButton)}
            </Button>
          )}
          {user.id === currentUser.id && (
            <Button className="me-2" color="secondary" onClick={handleChangePassword}>
              {formatMessage(MSG_changePasswordButton)}
            </Button>
          )}
          {props.onEditClick && (
            <Button onClick={props.onEditClick}>
              {formatMessage(MSG_editButton)}
            </Button>)}
        </div>
      </div>
    </StyledCardBody>
  );
};

export {UserCardBody};
