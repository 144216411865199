import * as React from "react";
import { PropsWithChildren } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { StyledWindowContainer } from "./styled/StyledWindowContainer";
import { darken } from "color2k";
import { omit } from "lodash";

interface IProps {
  isOpen: boolean;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => any;
  defaultFormAction?: () => any;
  autoFocus?: boolean;
  windowStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  customContainer?: (children: React.ReactElement) => React.ReactElement;
}

ReactModal.setAppElement('#root');

const Modal: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const prevScrollTop = React.useRef<number>(0);

  React.useEffect(() => {
    if (props.isOpen) {
      let top = window.scrollY;
      prevScrollTop.current = top;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${top}px`;
      document.body.style.left = '0';
      document.body.style.right = '0';
    } else {
      document.body.style.position = 'static';
      window.scrollBy(0, prevScrollTop.current);
    }
  }, [props.isOpen]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // If the event target is a textarea, return early
    if ((event.target as HTMLElement).nodeName === 'TEXTAREA') {
      return;
    }

    if (event.key === 'Enter' && props.defaultFormAction) {
      event.preventDefault();
      props.defaultFormAction();
    }
  }

  return (
    <ReactModal isOpen={props.isOpen}
                closeTimeoutMS={100}
                contentElement={(contentProps, contentChildren) => {
                  let content = (
                    <React.Fragment>
                      {/*<div onKeyDown={handleKeyDown} className="flex-grow-1">*/}
                      {props.header && <StyledHeader>{props.header}</StyledHeader>}
                      <StyledBody style={{
                        ...props.bodyStyle
                      }}>
                        {contentChildren}
                      </StyledBody>
                      {props.footer && <StyledFooter>{props.footer}</StyledFooter>}
                      {/*</div>*/}
                    </React.Fragment>
                  );

                  if (props.customContainer) {
                    content = props.customContainer(content);
                  } else {
                    content = (
                      <StyledContainer style={props.innerStyle}>
                        {content}
                      </StyledContainer>
                    );
                  }

                  return (
                    <StyledWrapper {...omit(contentProps, 'style')} style={props.windowStyle}>
                      {content}
                    </StyledWrapper>
                  );
                }}
                shouldFocusAfterRender={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                shouldCloseOnOverlayClick={false}
                onRequestClose={props.onRequestClose}
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0,0,0,0.65)',
                    padding: '2rem',
                    zIndex: 1,
                  },
                  content: {
                    border: 0,
                    padding: 0,
                    margin: 0,
                    backgroundColor: 'transparent'
                  }
                }}>
      {props.children}
    </ReactModal>
  );
};

const StyledWrapper = styled.div`
  width: 30rem;
  margin: auto;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 2rem;
  position: absolute;
  outline: none;
  border: none;
`;

const StyledContainer = styled(StyledWindowContainer)`
  padding: 0;
  border-color: ${({theme}) => theme.colors.primary };
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`;

const StyledHeader = styled.div`
  padding: 1rem;
  background: ${({theme}) => theme.colors.lightBackgroundColor };
  border-bottom: 1px solid ${({theme}) => darken(theme.colors.lightBackgroundColor, 0.05) };
  z-index: 100;
  flex-shrink: 1;
  box-shadow: 0 0 1rem #0008;

  h2, h3 {
    font-weight: bold;
  }
`;

const StyledBody = styled.div`
  background: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow-y: scroll;

  h2, h3 {
    font-weight: bold;
  }
`;

const StyledFooter = styled.div`
  padding: 1rem;
  background: ${({theme}) => theme.colors.lightBackgroundColor };
  border-top: 1px solid ${({theme}) => darken(theme.colors.lightBackgroundColor, 0.05) };
  z-index: 100;
  flex-shrink: 1;
  box-shadow: 0 0 1rem #0008;
`;

export {Modal};
