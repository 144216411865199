import * as React from "react";
import { forwardRef, PropsWithChildren } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { StyledInput } from "../styled/StyledInput";
import { omit } from "lodash";
import { opacify } from "color2k";

interface ISelectInputRef extends HTMLInputElement {}

interface ISelectInputProps extends React.InputHTMLAttributes<HTMLInputElement>, PropsWithChildren {
  className?: string;
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
}

const SelectInput = forwardRef<ISelectInputRef, ISelectInputProps>((props, ref) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  return (
    <InputContainer className={props.className}>
      <StyledSelectInput
        ref={ref}
        placeholder={props.placeholder}
        onFocus={(e) => {
          setIsFocused(true);
          if (props.onFocus) props.onFocus(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          if (props.onBlur) props.onBlur(e);
        }}
        className={classNames({
          'has-left-element': props.leftElement,
          'has-right-element': props.rightElement,
          'not-bold': props.className?.includes('not-bold'),
        })}
        {...omit(props, 'leftElement', 'rightElement', 'className', 'onFocus', 'onBlur')}
      >
        {props.children}
      </StyledSelectInput>

      {props.leftElement && (
        <LeftContainer className={isFocused ? 'focused' : ''}>{props.leftElement}</LeftContainer>)}
      {props.rightElement && (
        <RightContainer className={isFocused ? 'focused' : ''}>{props.rightElement}</RightContainer>)}
    </InputContainer>
  );
});

const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const StyledSelectInput = styled(StyledInput).attrs({as: 'select'})`
  background: url('data:image/svg+xml,<svg height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M362.7 203.9l-159.1 144c-6.125 5.469-15.31 5.469-21.44 0L21.29 203.9C14.73 197.1 14.2 187.9 20.1 181.3C26.38 174.4 36.5 174.5 42.73 180.1L192 314.5l149.3-134.4c6.594-5.877 16.69-5.361 22.62 1.188C369.8 187.9 369.3 197.1 362.7 203.9z"></path></svg>') no-repeat;
  background-color: #fff;
  font-size: 1rem;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance:none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  width: auto;
  cursor: pointer;

  &.not-bold {
    font-weight: normal;
  }
  &.has-left-element {
    padding-left: 2.5rem;
  }
  &.has-right-element {
    padding-right: 2.5rem;
  }
`;

const ElementContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 100%;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftContainer = styled(ElementContainer)`
  left: 0;
  color: ${({theme}) => opacify(theme.colors.textColor, -0.75)};
  &.focused {
    color: ${({theme}) => theme.colors.textColor};
  }
`;

const RightContainer = styled(ElementContainer)`
  right: 0;
  color: ${({theme}) => opacify(theme.colors.textColor, -0.75)};
  &.focused {
    color: ${({theme}) => theme.colors.textColor};
  }
`;


export {SelectInput, ISelectInputRef, ISelectInputProps};
