import * as React from "react";
import { Modal } from "@web/components/Modal";
import styled from "styled-components";
import { ApiError, Envelope } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { EnvelopeBox } from "@web/components/shared/EnvelopeBox";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import {
  MSG_bigProjectsEnvelopeName,
  MSG_carGasEnvelopeName,
  MSG_carInsuranceEnvelopeName,
  MSG_carMaintenanceEnvelopeName,
  MSG_carPaymentEnvelopeName,
  MSG_clothingEnvelopeName,
  MSG_commonEnvelopesLabel,
  MSG_diningOutEnvelopeName,
  MSG_electricEnvelopeName,
  MSG_emergencyEnvelopeName,
  MSG_entertainmentEnvelopeName,
  MSG_envelopeColorLabel,
  MSG_envelopeNameLabel,
  MSG_groceriesEnvelopeName,
  MSG_homeExpensesCategory,
  MSG_homeImprovementEnvelopeName,
  MSG_homeInsuranceEnvelopeName,
  MSG_internetEnvelopeName,
  MSG_livingExpensesCategory,
  MSG_maintenanceEnvelopeName,
  MSG_mortgageEnvelopeName,
  MSG_personalCareEnvelopeName,
  MSG_phoneEnvelopeName,
  MSG_propaneEnvelopeName,
  MSG_propertyTaxEnvelopeName,
  MSG_regularBillsCategory,
  MSG_rentEnvelopeName,
  MSG_retirementEnvelopeName,
  MSG_SavingsCategory,
  MSG_spendingMoneyEnvelopeName,
  MSG_transportationCategory,
  MSG_travelEnvelopeName,
  MSG_waterEnvelopeName
} from "shared/strings/envelopes";
import { ColorSample } from "./ColorSample";
import { useCreateEnvelopeMutation, useUpdateEnvelopeMutation } from "shared/state/endpoints/app/envelopes_api";
import { parseApiError } from "shared/utils/api_errors";
import { IPopover } from "@web/components/Popover";
import { FormErrors } from "@web/components/forms/FormErrors";
import { setEnvelope } from "shared/state/store";
import { useDispatch } from "react-redux";

interface IProps {
  ledgerId: string;
}

interface IEnvelopeEditorModal  {
  show: (envelope: Partial<Envelope>) => any;
  hide: () => any;
}

const EnvelopeEditorModal = React.forwardRef<IEnvelopeEditorModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [id, setId] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string>('');
  const [color, setColor] = React.useState<string>('#efefef');
  const [icon, setIcon] = React.useState<string | null>(null);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [originalPosition, setOriginalPosition] = React.useState<{appX: number, appY: number} | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [showAdvanced, setShowAdvanced] = React.useState<boolean>(false);
  const [updateEnvelope] = useUpdateEnvelopeMutation();
  const [createEnvelope] = useCreateEnvelopeMutation();
  const emojiPickerPopoverRef = React.useRef<IPopover>(null);
  const emojiSuggestionsPopoverRef = React.useRef<IPopover>(null);
  const changeIconButtonRef = React.useRef<HTMLButtonElement>(null);
  const {formatMessage} = useIntlFormatters();
  const suggestButtonRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  React.useImperativeHandle<any, IEnvelopeEditorModal>(ref, () => ({
    show: (_envelope: Partial<Envelope>) => {
      setId(_envelope.id || null);
      setName(_envelope.name || '');
      setColor(_envelope.color || '#efefef');
      setIcon(_envelope.icon || null);
      if (!_envelope.id && _envelope.appX !== undefined && _envelope.appY !== undefined) {
        setOriginalPosition({appX: _envelope.appX || 0, appY: _envelope.appY || 0});
      } else {
        setOriginalPosition(null);
      }
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    try {
      setSaving(true);
      if (id) {
        dispatch(setEnvelope(await updateEnvelope({
          ledgerId: props.ledgerId,
          envelope: {id, color, name, icon: icon || null}
        }).unwrap()));
      } else {
        let newEnvelope: Partial<Envelope> = {color, name, icon};
        if (originalPosition?.appX !== undefined) newEnvelope.appX = originalPosition.appX;
        if (originalPosition?.appY !== undefined) newEnvelope.appY = originalPosition.appY;
        dispatch(setEnvelope(await createEnvelope({
          ledgerId: props.ledgerId,
          envelope: newEnvelope
        }).unwrap()));
      }
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  return (
    <Modal
      isOpen={visible}
      defaultFormAction={save}
      onRequestClose={() => setVisible(false)}
      windowStyle={{
        width: '55rem',
      }}
      innerStyle={{
      }}
      bodyStyle={{
        overflowY: 'auto',
        flexDirection: 'row',
      }}
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={saving}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
        </div>
      }
    >

      <div className="flex-row" style={{width: '100%'}}>
      <EditorContent>
        <FormErrors errors={errors}/>

        <EnvelopeContainer>
          <EnvelopeBox envelope={{name, color, icon}}/>
        </EnvelopeContainer>

        <FormElement errors={errors} errorPath="envelope.name"
                     className="mt-4"
                     label={formatMessage(MSG_envelopeNameLabel)}>
          <TextInput
            autoFocus
            type="text"
            disabled={saving}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </FormElement>

        {/*<FormElement errors={errors} errorPath="envelope.emoji"*/}
        {/*             className="mt-4"*/}
        {/*             label="Icon">*/}
        {/*  <div className="flex-row align-items-center">*/}
        {/*    <StyledCard>*/}
        {/*      <StyledCardBody style={{padding: '0 0.75rem', height: '2.5rem', width: '3.5rem'}}*/}
        {/*                      className="flex-row align-items-center justify-content-center">*/}
        {/*        {icon*/}
        {/*          ? <span style={{fontSize: '1.5rem'}}>{icon}</span>*/}
        {/*          : <span style={{lineHeight: 1}} className="text-muted"><FontAwesomeIcon icon={faEmptySet}/></span>}*/}
        {/*      </StyledCardBody>*/}
        {/*    </StyledCard>*/}
        {/*    <Button className="ms-4" color="secondary" onClick={() => {*/}
        {/*      emojiPickerPopoverRef.current?.show();*/}
        {/*    }} ref={changeIconButtonRef}>Change</Button>*/}
        {/*    <Button className="ms-2" color="secondary"*/}
        {/*            ref={suggestButtonRef}*/}
        {/*            onClick={() => {*/}
        {/*              emojiSuggestionsPopoverRef.current?.show();*/}
        {/*            }}>*/}
        {/*      {formatMessage(MSG_MSG_suggestButton)}*/}
        {/*    </Button>*/}
        {/*    <Button className="ms-2" color="secondary"*/}
        {/*            onClick={() => setIcon(null)}>*/}
        {/*      {formatMessage(MSG_clearButton)}*/}
        {/*    </Button>*/}

        {/*    <EmojiSuggestionsPopover*/}
        {/*      ref={emojiSuggestionsPopoverRef}*/}
        {/*      targetRef={suggestButtonRef}*/}
        {/*      onChange={setIcon}*/}
        {/*      accountId={props.accountId}*/}
        {/*      term={name}*/}
        {/*    />*/}
        {/*    <EmojiPickerPopover*/}
        {/*      ref={emojiPickerPopoverRef}*/}
        {/*      targetRef={changeIconButtonRef}*/}
        {/*      value={icon || ''}*/}
        {/*      onChange={(value) => setIcon(value)}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</FormElement>*/}

        <FormElement errors={errors} errorPath="envelope.color"
                     label={formatMessage(MSG_envelopeColorLabel)}>

          <div className="d-flex flex-row">
            <div className="d-flex flex-grow-1 me-2">
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#eeeeee" selected={color === '#eeeeee'} onClick={setColor}/>
                <ColorSample color="#cccccc" selected={color === '#cccccc'} onClick={setColor}/>
                <ColorSample color="#aaaaaa" selected={color === '#aaaaaa'} onClick={setColor}/>
              </div>
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#98db4c" selected={color === '#98db4c'} onClick={setColor}/>
                <ColorSample color="#76b333" selected={color === '#76b333'} onClick={setColor}/>
                <ColorSample color="#598c21" selected={color === '#598c21'} onClick={setColor}/>
              </div>
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#debaf0" selected={color === '#debaf0'} onClick={setColor}/>
                <ColorSample color="#b691c8" selected={color === '#b691c8'} onClick={setColor}/>
                <ColorSample color="#9a6eb0" selected={color === '#9a6eb0'} onClick={setColor}/>
              </div>
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#a4b1bc" selected={color === '#a4b1bc'} onClick={setColor}/>
                <ColorSample color="#738899" selected={color === '#738899'} onClick={setColor}/>
                <ColorSample color="#506679" selected={color === '#506679'} onClick={setColor}/>
              </div>
            </div>

            <div className="d-flex flex-grow-1">
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#ffff8a" selected={color === '#ffff8a'} onClick={setColor}/>
                <ColorSample color="#e9e942" selected={color === '#e9e942'} onClick={setColor}/>
                <ColorSample color="#d4d425" selected={color === '#d4d425'} onClick={setColor}/>
              </div>
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#d5c685" selected={color === '#d5c685'} onClick={setColor}/>
                <ColorSample color="#a1914e" selected={color === '#a1914e'} onClick={setColor}/>
                <ColorSample color="#766626" selected={color === '#766626'} onClick={setColor}/>
              </div>
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#ffcb4f" selected={color === '#ffcb4f'} onClick={setColor}/>
                <ColorSample color="#d78e2f" selected={color === '#d78e2f'} onClick={setColor}/>
                <ColorSample color="#bb6a22" selected={color === '#bb6a22'} onClick={setColor}/>
              </div>
              <div className="d-flex flex-row mb-2">
                <ColorSample color="#a3ece2" selected={color === '#a3ece2'} onClick={setColor}/>
                <ColorSample color="#8bc9c0" selected={color === '#8bc9c0'} onClick={setColor}/>
                <ColorSample color="#6b9b95" selected={color === '#6b9b95'} onClick={setColor}/>
              </div>
            </div>
          </div>

          <div style={{cursor: 'pointer'}}>
            <input type="checkbox"
                   checked={showAdvanced}
                   onChange={e => setShowAdvanced(e.target.checked)}
                   id="show-advanced"/>
            <label htmlFor="show-advanced" className="ps-2">
              Advanced color selection
            </label>
          </div>

          {showAdvanced && (
            <div>
              <TextInput value={color}
                         onChange={e => setColor(e.target.value)}
                         style={{width: '10rem'}}/>
            </div>)}

        </FormElement>
      </EditorContent>
      <HelpContent>

        <h2 className="mb-4">{formatMessage(MSG_commonEnvelopesLabel)}</h2>

        <div className="d-flex flex-row">
          <div className="d-flex flex-column flex-grow-1">
            <div className="mb-4">
              <h3>{formatMessage(MSG_homeExpensesCategory)}</h3>
              <div>{formatMessage(MSG_mortgageEnvelopeName)}</div>
              <div>{formatMessage(MSG_rentEnvelopeName)}</div>
              <div>{formatMessage(MSG_homeInsuranceEnvelopeName)}</div>
              <div>{formatMessage(MSG_propertyTaxEnvelopeName)}</div>
              <div>{formatMessage(MSG_homeImprovementEnvelopeName)}</div>
              <div>{formatMessage(MSG_maintenanceEnvelopeName)}</div>
            </div>
            <div className="mb-4">
              <h3>{formatMessage(MSG_regularBillsCategory)}</h3>
              <div>{formatMessage(MSG_electricEnvelopeName)}</div>
              <div>{formatMessage(MSG_waterEnvelopeName)}</div>
              <div>{formatMessage(MSG_propaneEnvelopeName)}</div>
              <div>{formatMessage(MSG_phoneEnvelopeName)}</div>
              <div>{formatMessage(MSG_internetEnvelopeName)}</div>
            </div>
          </div>

          <div className="d-flex flex-column flex-grow-1">
            <div className="mb-4">
              <h3>{formatMessage(MSG_livingExpensesCategory)}</h3>
              <div>{formatMessage(MSG_groceriesEnvelopeName)}</div>
              <div>{formatMessage(MSG_personalCareEnvelopeName)}</div>
              <div>{formatMessage(MSG_clothingEnvelopeName)}</div>
              <div>{formatMessage(MSG_entertainmentEnvelopeName)}</div>
              <div>{formatMessage(MSG_diningOutEnvelopeName)}</div>
              <div>{formatMessage(MSG_spendingMoneyEnvelopeName)}</div>
            </div>
            <div className="mb-4">
              <h3>{formatMessage(MSG_SavingsCategory)}</h3>
              <div>{formatMessage(MSG_travelEnvelopeName)}</div>
              <div>{formatMessage(MSG_retirementEnvelopeName)}</div>
              <div>{formatMessage(MSG_bigProjectsEnvelopeName)}</div>
              <div>{formatMessage(MSG_emergencyEnvelopeName)}</div>
            </div>
            <div className="mb-4">
              <h3>{formatMessage(MSG_transportationCategory)}</h3>
              <div>{formatMessage(MSG_carGasEnvelopeName)}</div>
              <div>{formatMessage(MSG_carInsuranceEnvelopeName)}</div>
              <div>{formatMessage(MSG_carPaymentEnvelopeName)}</div>
              <div>{formatMessage(MSG_carMaintenanceEnvelopeName)}</div>
            </div>
          </div>
        </div>
      </HelpContent>
      </div>
    </Modal>
  );
});

const EnvelopeContainer = styled.div`
  margin: auto;
  width: 12.5rem;
  height: 6.25rem;
`;

const EditorContent = styled.div`
  padding: 1.5rem;
  width: 50%;
`;

const HelpContent = styled.div`
  background: ${({theme}) => theme.colors.lightBackgroundColor };
  padding: 1.5rem;
  width: 50%;
`;

export {EnvelopeEditorModal, IEnvelopeEditorModal};
