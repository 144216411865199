import * as React from "react";
import { PropsWithChildren } from "react";
import { StyledTextHeader } from "@web/components/styled/StyledText";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_displaySettingsTitle,
  MSG_globalSettings,
  MSG_globalSettingsExplanation,
  MSG_personalSettings,
  MSG_personaSettingsExplanation,
  MSG_regionalSettingsTitle
} from "shared/strings/settings";
import styled from "styled-components";
import { StyledCard } from "@web/components/styled/StyledCard";
import { StyledBadge } from "@web/components/styled/StyledBadge";
import { Tooltip } from "react-tooltip";
import { DateFormatSettings } from "@web/pages/app/authenticated/settings/appearance/DateFormatSettings";
import { TimeFormatSettings } from "@web/pages/app/authenticated/settings/appearance/TimeFormatSettings";
import { NumberFormatSettings } from "@web/pages/app/authenticated/settings/appearance/NumberFormatSettings";
import { CurrencyDecimalsSettings } from "@web/pages/app/authenticated/settings/appearance/CurrencyDecimalsSettings";
import { InterfaceScaleSettings } from "@web/pages/app/authenticated/settings/appearance/InterfaceScaleSettings";

interface IProps {}

const AppearancePage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const { formatMessage } = useIntlFormatters();

  return (
    <div className="flex-row align-items-start justify-content-stretch">
      <Column>
        <div className="flex-row align-items-center mb-4">
          <StyledTextHeader className="mb-0">
            {formatMessage(MSG_regionalSettingsTitle)}
          </StyledTextHeader>
          <StyledBadge className="warning ms-2"
                       data-tooltip-id="global-settings"
                       data-tooltip-content={formatMessage(MSG_globalSettingsExplanation)}>
            {formatMessage(MSG_globalSettings)}
          </StyledBadge>
          <Tooltip id="global-settings"/>
        </div>
        <StyledCard>
          <DateFormatSettings/>
          <TimeFormatSettings/>
          <NumberFormatSettings/>
          <CurrencyDecimalsSettings/>
        </StyledCard>
      </Column>
      <Column>
        <div className="flex-row align-items-center mb-4">
          <StyledTextHeader className="mb-0">
            {formatMessage(MSG_displaySettingsTitle)}
          </StyledTextHeader>
          <StyledBadge className="info ms-2"
                       data-tooltip-id="global-settings"
                       data-tooltip-content={formatMessage(MSG_personaSettingsExplanation)}>
            {formatMessage(MSG_personalSettings)}
          </StyledBadge>
          <Tooltip id="global-settings"/>
        </div>

        <StyledCard>
          <InterfaceScaleSettings/>
        </StyledCard>
      </Column>
    </div>
  );
};

const Column = styled.div`
  flex: 1;
  margin-right: 2rem;
  width: 50%;
`;

export { AppearancePage };
