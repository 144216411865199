import * as React from "react";
import { PropsWithChildren } from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import { useNavigate, useParams } from "react-router-dom";
import { faCloudArrowDown } from "@fortawesome/pro-light-svg-icons/faCloudArrowDown";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MSG_searchLabel } from "shared/strings/generic";
import { useQueryString } from "@web/utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { EnvelopeSelector } from "@web/components/selectors/EnvelopeSelector";
import { TextInput } from "@web/components/inputs/TextInput";
import { Button } from "@web/components/Button";
import { MSG_exportTitle } from "shared/strings/export";
import { ExportModal, IExportModal } from "@web/modals/export_modal/ExportModal";
import { TransactionList } from "@web/components/shared/TransactionList";
import { resetTransactionsList, selectAccountsForLedger } from "shared/state/store";
import { LedgerContext } from "@web/pages/app/authenticated/ledger/_LedgersRoot";
import { SelectInput } from "@web/components/inputs/SelectInput";
import { MSG_allAccountsLabel } from "shared/strings/accounts";
import classnames from "classnames";

interface IProps {}

const TransactionsPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const query = useQueryString<{eid?: string, q?: string}>();
  const {ledgerId, accountId} = useParams<{ledgerId: string, accountId: string}>();
  const [searchStr, setSearchStr] = React.useState<string>(query.q || '');
  const [debouncedSearchStr] = useDebounce(searchStr, 250);
  const {formatMessage} = useIntlFormatters();
  const navigate = useNavigate();
  const exportModalRef = React.useRef<IExportModal>(null);
  const firstRenderRef = React.useRef<boolean>(true);
  const dispatch = useDispatch();
  const ledgerContext = React.useContext(LedgerContext);
  const accounts = useSelector(selectAccountsForLedger(ledgerId!));

  React.useEffect(() => {
    if (debouncedSearchStr) {
      query.q = debouncedSearchStr;
    } else {
      delete query.q;
    }
    navigate(`/app/ledgers/${ledgerId}/transactions/${accountId}?${new URLSearchParams(query).toString()}`, {replace: true});
  }, [debouncedSearchStr]);

  React.useEffect(() => {
    setSearchStr(query.q || '');
  }, [query.q, query.eid, accountId]);

  React.useEffect(() => {
    // If this is the first render of this component and there is an envelope ID in the query
    // string, reset the transactions list. This makes sure that the transactions list is reset when
    // navigating directly to an envelope history (by clicking on the envelope in the envelope
    // list).  Without this, the cached transaction list that was previously fetched would be
    // displayed briefly.  Not a big deal, but this is a nice touch.
    if (firstRenderRef.current && query.eid) {
      firstRenderRef.current = false;
      dispatch(resetTransactionsList({listType: 'main'}));
    }
  }, []);

  // #####################################################

  return (
    <React.Fragment>
      <div className="d-flex flex-row align-items-top">
        <div className="flex-grow-1 d-flex flex-row align-items-start">
          <TextInput
            style={{width: '20rem'}}
            className="me-2"
            placeholder={formatMessage(MSG_searchLabel)}
            autoFocus
            value={searchStr}
            onChange={e => {
              setSearchStr(e.target.value);
            }}
            leftElement={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
          />
          <EnvelopeSelector
            placement="bottom"
            envelopeId={query.eid || null}
            ledgerId={ledgerId!}
            onChange={eid => {
              if (eid) {
                query.eid = eid;
              } else {
                delete query.eid;
              }
              navigate(`/app/ledgers/${ledgerId}/transactions/${accountId}?${new URLSearchParams(query).toString()}`);
            }}
          />
          <SelectInput
            className={classnames({'not-bold': accountId === 'all'})}
            style={{marginLeft: '0.5rem', maxWidth: '12rem'}}
            name="accountId"
            value={accountId || 'all'}
            onChange={e => {
              let aid = e.target.value || 'all';
              navigate(`/app/ledgers/${ledgerId}/transactions/${aid}?${new URLSearchParams(query).toString()}`);
            }}>
            <option value="all">{formatMessage(MSG_allAccountsLabel)}</option>
            {accounts.map((account) => (
              <option key={account.id} value={account.id}>{account.name}</option>
            ))}
          </SelectInput>
        </div>
        <div className="flex-grow-1 text-right">
          <Button onClick={() => exportModalRef.current?.show(query.eid)}>
            <FontAwesomeIcon icon={faCloudArrowDown}/>{' '}{formatMessage(MSG_exportTitle)}
          </Button>

          <ExportModal ref={exportModalRef}/>
        </div>
      </div>

      <TransactionList
        transactionEditorModalRef={ledgerContext.transactionEditorModalRef}
        ledgerId={ledgerId!}
        accountId={accountId === 'all' ? undefined : accountId}
        envelopeId={query.eid}
        queryStr={query.q}
      />
    </React.Fragment>
  );
};

export {TransactionsPage};
