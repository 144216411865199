import * as React from "react";
import { PropsWithChildren } from "react";
import { StyledCardBody, StyledCardIcon } from "@web/components/styled/StyledCard";
import { getCardBrandIcon } from "shared/utils/helpers";
import {
  MSG_billingAddPaymentMethodButton,
  MSG_billingNone,
  MSG_billingPaymentMethodLabel
} from "shared/strings/billing";
import { Button } from "@web/components/Button";
import { MSG_changeButton } from "shared/strings/generic";
import { PAYMENT_METHOD, ROLE, Subscription, User } from "shared/utils/api_types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { IPaymentMethodModal } from "@web/modals/payment_method_modal/PaymentMethodModal";
import { useIntlFormatters } from "shared/utils/formatters";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { PaymentMethodSummary } from "@web/components/shared/PaymentMethodSummary";
import { selectCurrentUser } from "shared/state/slices/data_selectors/core_data_selectors";
import { useSelector } from "react-redux";

interface IProps {
  subscription: Subscription;
  paymentMethodModalRef: React.RefObject<IPaymentMethodModal>;
}

const PaymentMethodCardBody: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const theme = useTheme();
  const {formatMessage} = useIntlFormatters();
  const {subscription, paymentMethodModalRef} = props;
  const currentUser = useSelector(selectCurrentUser) as User;
  const editable = currentUser?.role === ROLE.OWNER;

  if (subscription.stripePaymentMethod?.type === PAYMENT_METHOD.CARD && subscription.stripePaymentMethod.cardBrand) {
    return (
      <StyledCardBody className="flex-row align-items-center justify-content-stretch">
        <StyledCardIcon>
          <FontAwesomeIcon icon={getCardBrandIcon(subscription.stripePaymentMethod.cardBrand)}
                           color={theme.colors.info}/>
        </StyledCardIcon>

        <div className="flex-grow-1">
          {formatMessage(MSG_billingPaymentMethodLabel)}{' '}
          <PaymentMethodSummary paymentMethod={subscription.stripePaymentMethod}/>
        </div>

        {editable && (
          <Button color="secondary"
                  onClick={() => paymentMethodModalRef.current?.show()}>
            {formatMessage(MSG_changeButton)}
          </Button>)}
      </StyledCardBody>
    );
  } else {
    return (
      <StyledCardBody className="flex-row align-items-center justify-content-stretch">
        <StyledCardIcon>
          <FontAwesomeIcon icon={faCreditCard}/>
        </StyledCardIcon>

        <div className="flex-grow-1">
          {formatMessage(MSG_billingPaymentMethodLabel)}{' '}
          <strong>{formatMessage(MSG_billingNone)}</strong>
        </div>

        {editable && (
          <Button color="secondary"
                  onClick={() => paymentMethodModalRef.current?.show()}>
            {formatMessage(MSG_billingAddPaymentMethodButton)}
          </Button>)}
      </StyledCardBody>
    );
  }
};

export {PaymentMethodCardBody};
