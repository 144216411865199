import * as React from "react";
import { Modal } from "@web/components/Modal";
import { ApiError } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton } from "shared/strings/generic";
import { FormElement } from "@web/components/forms/FormElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import {
  MSG_allEnvelopesTitle,
  MSG_downloadTitle,
  MSG_exportExplanation,
  MSG_exportTitle,
  MSG_timeframeAllTimeLabel,
  MSG_timeframeLabel,
  MSG_timeframeLastMonthLabel,
  MSG_timeframeLastYearLabel,
  MSG_timeframeMonthToDateLabel,
  MSG_timeframeTodayLabel,
  MSG_timeframeYearToDateLabel
} from "shared/strings/export";
import { faCloudArrowDown } from "@fortawesome/pro-light-svg-icons/faCloudArrowDown";
import { SelectInput } from "@web/components/inputs/SelectInput";
import dayjs from "dayjs";
import { MSG_envelopeTitle } from "shared/strings/envelopes";
import { EnvelopeSelector } from "@web/components/selectors/EnvelopeSelector";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLazyGetExportTransactionsQuery } from "shared/state/endpoints/app/exports_api";
import { selectCurrentSubscription, selectLedger } from "shared/state/store";

interface IProps {
}

interface IExportModal  {
  show: (envelopeId?: string | null) => any;
  hide: () => any;
}

const ExportModal = React.forwardRef<IExportModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [timeframe, setTimeframe] = React.useState<any>(null);
  const [envelopeId, setEnvelopeId] = React.useState<string | null>(null);
  const [working, setWorking] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const {formatDate, formatMessage} = useIntlFormatters();
  const ledgerId = useParams<{ledgerId: string}>().ledgerId!;
  const account = useSelector(selectLedger(ledgerId as string));
  const subscription = useSelector(selectCurrentSubscription)!;
  const [exportTransactions] = useLazyGetExportTransactionsQuery();

  React.useImperativeHandle<any, IExportModal>(ref, () => ({
    show: (localEnvelopeId?: string | null) => {
      setEnvelopeId(localEnvelopeId || null);
      setVisible(true);
      setErrors(undefined);
      setWorking(false);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  function getFilename(): string {
    let timeframeStr = formatMessage(MSG_timeframeAllTimeLabel);
    if (timeframe === 'year-to-date') {
      timeframeStr = `${dayjs().format('YYYY')} (${formatMessage(MSG_timeframeYearToDateLabel)})`;
    } else if (timeframe === 'month-to-date') {
      timeframeStr = `${dayjs().format('MMM YYYY')} (${formatMessage(MSG_timeframeMonthToDateLabel)})`;
    } else if (timeframe === 'last-month') {
      timeframeStr = `${dayjs().subtract(1, 'month').format('MMM YYYY')}`;
    } else if (timeframe === 'last-year') {
      timeframeStr = `${dayjs().subtract(1, 'year').format('YYYY')}`;
    }
    let envelopeName = envelopeId ? account?.envelopes?.find(env => env.id === envelopeId)?.name : formatMessage(MSG_allEnvelopesTitle);
    return `NeoBudget Export - ${envelopeName} - ${timeframeStr}.csv`;
  }

  async function execute() {
    if (!ledgerId) return;

    const data = await exportTransactions({
      ledgerId: ledgerId,
      envelopeId: envelopeId || undefined,
      timeframe: timeframe || 'all-time',
    }).unwrap();

    const blob = new Blob([data.exportedCsv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = getFilename();

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setVisible(false);
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      bodyStyle={{padding: '1rem'}}
      header={
        <div>
          <h2>{formatMessage(MSG_exportTitle)}</h2>
          <div>{formatMessage(MSG_exportExplanation)}</div>
        </div>
      }
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={working}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={execute}
                  disabled={working} spinner={working}>
            <FontAwesomeIcon icon={faCloudArrowDown}/>{' '}{formatMessage(MSG_downloadTitle)}
          </Button>
        </div>
      }>

      {errors?.errorType === 'message' && (
        <div className="d-flex flex-row">
          <StyledErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            <div>{errors.message}</div>
          </StyledErrorMessage>
        </div>
      )}

      <FormElement className="flex-grow-1"
                   label={formatMessage(MSG_envelopeTitle)}>
        <EnvelopeSelector
          ledgerId={ledgerId}
          envelopeId={envelopeId}
          emptyLabel={formatMessage(MSG_allEnvelopesTitle)}
          onChange={setEnvelopeId}
        />
      </FormElement>

      <FormElement className="flex-grow-1"
                   label={formatMessage(MSG_timeframeLabel)}>
        <SelectInput name="timeframe"
                     value={timeframe || ''}
                     onChange={e => setTimeframe(e.target.value || '')}>
          <option value="">{formatMessage(MSG_timeframeAllTimeLabel)}</option>
          <option value="month-to-date">
            {formatMessage(MSG_timeframeMonthToDateLabel)}{' '}
            ({formatDate(dayjs().startOf('month'), subscription)} &rarr; {formatMessage(MSG_timeframeTodayLabel)})
          </option>
          <option value="last-month">
            {formatMessage(MSG_timeframeLastMonthLabel)}{' '}
            ({formatDate(dayjs().subtract(1, 'month').startOf('month'), subscription)} &rarr; {formatDate(dayjs().subtract(1, 'month').endOf('month'), subscription)})
          </option>
          <option value="year-to-date">
            {formatMessage(MSG_timeframeYearToDateLabel)}{' '}
            ({formatDate(dayjs().startOf('year'), subscription)} &rarr; {formatMessage(MSG_timeframeTodayLabel)})
          </option>
          <option value="last-year">
            {formatMessage(MSG_timeframeLastYearLabel)}{' '}
            ({formatDate(dayjs().subtract(1, 'year').startOf('year'), subscription)} &rarr; {formatDate(dayjs().subtract(1, 'year').endOf('year'), subscription)})
          </option>
        </SelectInput>
      </FormElement>

    </Modal>
  );
});


export {ExportModal, IExportModal};
