import * as React from "react";
import { PropsWithChildren } from "react";
import { ITextInputRef, TextInput } from "@web/components/inputs/TextInput";
import { useIntlFormatters } from "shared/utils/formatters";
import { useFinalizePasswordResetMutation } from "shared/state/endpoints/app/users_api";
import { ApiError } from "shared/utils/api_types";
import { parseApiError } from "shared/utils/api_errors";
import {
  MSG_confirmYourNewPassword,
  MSG_goBack,
  MSG_loginButton,
  MSG_newPassword,
  MSG_resetComplete,
  MSG_resetCompleteInstructions,
  MSG_resetPasswordExplanation,
  MSG_resetYourPassword
} from "shared/strings/login";
import { Button } from "@web/components/Button";
import { Form } from "@web/components/forms/Form";
import { FormErrors } from "@web/components/forms/FormErrors";
import { FormElement } from "@web/components/forms/FormElement";
import { MSG_saveButton } from "shared/strings/generic";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import { faShieldCheck } from "@fortawesome/pro-light-svg-icons/faShieldCheck";
import styled from "styled-components";

interface IProps {}

const ConfirmPasswordResetPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const navigate = useNavigate();
  const params = useParams<{token: string}>();
  const passwordRef = React.useRef<ITextInputRef>(null);
  const [complete, setComplete] = React.useState(false);
  const [password, setPassword] = React.useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState<string>('');
  const {formatMessage} = useIntlFormatters();
  const [working, setWorking] = React.useState<boolean>(false);
  const [finalizePasswordReset]: any = useFinalizePasswordResetMutation();
  const [apiError, setApiError] = React.useState<ApiError | null>(null);

  const handleSubmit = async () => {
    if (!password) return;
    setWorking(true);
    try {
      await finalizePasswordReset({
        password, passwordConfirmation, token: params.token
      }).unwrap();
      setComplete(true);
    } catch (e) {
      setComplete(false);
      setApiError(parseApiError(e));
    }
    setWorking(false);
  };

  if (complete) {
    return (
      <div>
        <div className="text-center p-4">
          <FontAwesomeIconStyled icon={faShieldCheck}/>
          <HeadingStyled>{formatMessage(MSG_resetComplete)}</HeadingStyled>
        </div>

        <p className="text-center pb-4">
          {formatMessage(MSG_resetCompleteInstructions)}
        </p>

        <div className="text-center">
          <Button onClick={() => navigate('/app/login')}>
            {formatMessage(MSG_loginButton)}
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="text-center p-4">
          <FontAwesomeIconStyled icon={faShieldCheck}/>
          <HeadingStyled>{formatMessage(MSG_resetYourPassword)}</HeadingStyled>
        </div>

        <p className="text-muted text-center pb-4">
          {formatMessage(MSG_resetPasswordExplanation)}
        </p>

        <Form onSubmit={handleSubmit}>
          {apiError && <FormErrors errors={apiError} className="mb-4"/>}

          <FormElement label={formatMessage(MSG_newPassword)}>
            <TextInput
              type="password"
              ref={passwordRef}
              autoFocus
              tabIndex={1}
              disabled={working}
              value={password}
              onChange={e => setPassword(e.target.value)}/>
          </FormElement>

          <FormElement label={formatMessage(MSG_confirmYourNewPassword)}>
            <TextInput
              type="password"
              tabIndex={2}
              disabled={working}
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}/>
          </FormElement>

          <div className="flex-row align-items-center justify-content-stretch">
            <div className="flex-grow-1">
              <Link to="/app/login" tabIndex={4}>
                <FontAwesomeIcon icon={faArrowLeft} className="me-2"/>
                {formatMessage(MSG_goBack)}
              </Link>
            </div>
            <Button tabIndex={3} spinner={working} disabled={working}>
              {formatMessage(MSG_saveButton)}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
};

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeadingStyled = styled.h1`
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export {ConfirmPasswordResetPage};
