import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyledEnvelopeContainer } from "../styled/StyledEnvelopeContainer";
import { Envelope } from "shared/utils/api_types";

interface IProps {
  envelope: Envelope;
  width?: number;
  className?: string;
}

const EnvelopeName: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const envelope = props.envelope;
  const width = props.width || 12;

  return (
    <StyledEnvelopeContainer style={{cursor: 'inherit', backgroundColor: envelope.color, width: `${width}rem`, height: `${width/2}rem`}}>
      <Name>{envelope.name}</Name>
    </StyledEnvelopeContainer>
  );
};

const Name = styled.div`
  width: 100%;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export {EnvelopeName};
