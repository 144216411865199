import { localStorageDriver } from "./local";
import { resetState } from "../state/global_actions";
import { setApiKey } from "../state/slices/data_slice";
import { useDispatch } from "react-redux";
import { Account, Transaction } from "./api_types";
import { useCreateTransactionMutation, useUpdateTransactionMutation } from "../state/endpoints/app/transactions_api";

export function useLogin() {
  const dispatch =  useDispatch();

  return async (apiKey: string, options?: {impersonate: boolean}) => {
    await localStorageDriver.setItem('NB_API_KEY', apiKey);

    if (options?.impersonate) {
      await localStorageDriver.setItem('NB_SUPPORT_SESSION', 'true');
    }

    dispatch(resetState());
    dispatch(setApiKey(apiKey));
  };
}

export function useLogout() {
  const dispatch =  useDispatch();

  return async () => {
    await Promise.all([
      localStorageDriver.removeItem('NB_API_KEY'),
      localStorageDriver.removeItem('NB_SUPPORT_SESSION'),
    ]);

    dispatch(resetState());
  };
}

export function useSaveTransaction() {
  const [updateTransaction] = useUpdateTransactionMutation();
  const [createTransaction] = useCreateTransactionMutation();

  return async (ledgerId: string, transaction: Partial<Transaction>, allocations: {[envelopeId: string]: number}, options?: {toAccount?: Account, allocatedAmount?: number}) => {
    if (!transaction) return;

    // apply the multiplier to reverse the multiplier effect (e.g. turn expenses back into negatives)
    let amount = transaction?.amount || 0;
    if (transaction?.kind === 'transfer') {
      amount = options?.allocatedAmount || 0;
    } else if (transaction?.kind === 'move') {
      amount = 0;
    }
    const localTransaction: Partial<Transaction> = {
      ...transaction,
      accountId: transaction.kind === 'move' ? undefined : transaction.accountId,
      amount: amount,
      allocations: Object.keys(allocations).map(id => ({envelopeId: id, amount: allocations[id]}))
    };
    if (transaction.kind === 'transfer') {
      localTransaction.toAccountId = options?.toAccount?.id;
    }

    const fn = localTransaction?.id ? updateTransaction : createTransaction;
    await fn({ledgerId, transaction: localTransaction}).unwrap();
  }
}
