import * as React from "react";
import styled from "styled-components";
import { FormElement } from "../forms/FormElement";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as PopperJS from '@popperjs/core';
import { usePopper } from "react-popper";
import { omit } from "lodash";
import { EnvelopeGrid } from "../shared/EnvelopeGrid";
import { TinyEnvelope } from "../shared/TinyEnvelope";
import { MSG_cancelButton } from "shared/strings/generic";
import { useIntlFormatters } from "shared/utils/formatters";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { ITextInputRef, TextInput } from "../inputs/TextInput";
import { MSG_searchByEnvelopeName } from "shared/strings/transactions";
import { Button, StyledButton } from "../Button";
import { opacify } from "color2k";
import { selectEnvelopesForLedger } from "shared/state/store";
import { useSelector } from "react-redux";
import { MSG_selectEnvelopeTitle } from "shared/strings/envelopes";

interface IProps {
  ledgerId: string;
  envelopeId: string | null;
  onChange: (id: string | null) => any;
  emptyLabel?: string;
  placement?: PopperJS.Placement
}

const EnvelopeSelector: React.FunctionComponent<IProps> = (props) => {
  const targetRef = React.useRef<any>(null);
  const popperRef = React.useRef<any>(null);
  const [searchStr, setSearchStr] = React.useState<string>('');
  const searchInputRef = React.useRef<ITextInputRef>(null);
  const [listVisible, setListVisible] = React.useState<boolean>(false);
  const {formatMessage} = useIntlFormatters();
  const envelopes = useSelector(selectEnvelopesForLedger(props.ledgerId));
  const { styles, attributes } = usePopper(targetRef.current, popperRef.current, {
    placement: props.placement || 'bottom-start',
    modifiers: [
      {name: 'offset', options: {offset: [0, 2]}},
    ],
  });

  React.useEffect(() => {
    if (listVisible) {
      searchInputRef.current?.select();
      searchInputRef.current?.focus();

      const detectClick = (e: any) => {
        if (popperRef.current && !popperRef.current.contains(e.target)) {
          setListVisible(false);
        }
      };

      document.addEventListener("click", detectClick);
      return () => {
        document.removeEventListener("click", detectClick);
      };
    }
  }, [listVisible, popperRef]);

  const selected = envelopes.find(env => env.id === props.envelopeId);

  const toggle = () => {
    // Doing this in a setTimeout so the click event that triggered this
    // completes before listVisible is set to true.  Without this, the
    // detectClick event above is triggered immediately because the click
    // event isn't completed, so adding another event to handle is executed
    // immediately.  setTimeout forces it to happen async.
    setTimeout(() => {
      setSearchStr('');
      setListVisible(!listVisible);
    });
  };

  return (
    <FormElement {...omit(props, 'label', 'onChange')}>
      <div style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-start'}}>
        <DropdownContainerStyled ref={targetRef} onClick={toggle}
                                 className={selected ? 'with-close' : 'input'}
                                 style={{
                                   backgroundColor: selected?.color,
                                   clipPath: 'inset(-5px -5px -5px -5px)',
                                   color: '#333'
                                 }}>
          <div className="label text-nowrap-ellipsis" style={{maxWidth: '12rem'}}>
            {selected?.icon && <span className="me-2">{selected.icon}</span>}
            {selected?.name || props.emptyLabel || formatMessage(MSG_selectEnvelopeTitle)}
            <FontAwesomeIcon icon={faAngleDown} className="ms-2"/>
          </div>
        </DropdownContainerStyled>
        {selected && (
          <StyledButton
            onClick={() => props.onChange(null)}
            style={{
              backgroundColor: selected?.color,
              clipPath: 'inset(-5px -5px -5px 0)',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              marginLeft: -2,
              color: '#333'
            }}>
            <div className="label">
              <FontAwesomeIcon icon={faTimes}/>
            </div>
          </StyledButton>
          )}
      </div>

      <DropdownListContainerStyled
        ref={popperRef}
        style={{...styles.popper, visibility: listVisible ? 'inherit' : 'hidden'}}
        {...attributes.popper}>
        <div className="d-flex flex-row mb-4">
          <TextInput
            ref={searchInputRef}
            className="mb-0 me-2 flex-grow-1"
            value={searchStr}
            onChange={e => {
              setSearchStr(e.target.value);
              e.stopPropagation();
            }}
            placeholder={formatMessage(MSG_searchByEnvelopeName)}
          />
          <Button color="secondary" onClick={() => toggle()}>
            {formatMessage(MSG_cancelButton)}
          </Button>
        </div>
        <EnvelopeGrid
          ledgerId={props.ledgerId}
          cellHeight="4rem"
          renderEnvelope={env => (
            <TinyEnvelope
              envelope={env}
              subdued={!env.name.toLowerCase().includes(searchStr.toLowerCase())}
              onClick={e => {
                props.onChange(e?.id || null);
                toggle();
              }}
            />
          )}/>
      </DropdownListContainerStyled>
    </FormElement>
  );
};

const DropdownContainerStyled = styled(StyledButton).attrs({as: 'button'})`
  width: auto;
  white-space: nowrap;
  cursor: pointer;

  &:focus:after {
    outline-color: ${({theme}) => opacify(theme.colors.textColor, -0.5)} !important;
  }

  &.with-close {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 1px;
  }
`;

const DropdownListContainerStyled = styled.div`
  border-style: solid;
  border-width: 1px;
  border-radius: ${({theme}) => theme.inputContainer.borderRadius};
  border-color: ${({theme}) => theme.colors.lightBorder};
  background-color: ${({theme}) => theme.inputContainer.backgroundColor};
  box-shadow: 0 0.75rem 2rem rgba(0,0,0,0.65);
  min-width: 40rem;
  padding: 0.5rem;
  z-index: 1000;
`;

export {EnvelopeSelector};
