import * as React from "react";
import { forwardRef, RefObject } from "react";
import { IPopover, Popover } from "@web/components/Popover";
import styled, { useTheme } from "styled-components";
import {
  MSG_billingLockedEnvelopeBottomNotice,
  MSG_billingLockedEnvelopeFreePlanLimits1,
  MSG_billingLockedEnvelopeFreePlanLimits2,
  MSG_billingLockedEnvelopeFreePlanLimits3,
  MSG_billingLockedEnvelopeTopNotice,
  MSG_billingUpgradeNowButton,
  MSG_freeSubscriptionTitle
} from "shared/strings/billing";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { useNavigate } from "react-router-dom";
import { MSG_cancelButton } from "shared/strings/generic";

interface IProps {
  targetRef: RefObject<any>;
}

const FreePlanNoticePopover = forwardRef<IPopover, IProps>((props, ref) => {
  const {formatMessage} = useIntlFormatters();
  const popoverRef = React.useRef<IPopover>(null);
  const navigate = useNavigate();
  const theme = useTheme();

  React.useImperativeHandle<any, IPopover>(ref, () => ({
    show: () => popoverRef.current?.show(),
    hide: () => popoverRef.current?.hide(),
  }));

  return (
    <Popover ref={popoverRef}
             targetRef={props.targetRef}
             placement={'bottom'}>
      <Container>
        <h3 style={{color: theme.colors.danger}}>{formatMessage(MSG_freeSubscriptionTitle)}</h3>
        <p>{formatMessage(MSG_billingLockedEnvelopeTopNotice)}</p>
        <ul>
          <li>{formatMessage(MSG_billingLockedEnvelopeFreePlanLimits1)}</li>
          <li>{formatMessage(MSG_billingLockedEnvelopeFreePlanLimits2)}</li>
          <li>{formatMessage(MSG_billingLockedEnvelopeFreePlanLimits3)}</li>
        </ul>
        <p>{formatMessage(MSG_billingLockedEnvelopeBottomNotice)}</p>

        <div className="text-center">
          <Button color="secondary" className="me-2" onClick={(e) => {
            popoverRef.current?.hide();
            e?.stopPropagation();
          }}>
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button color="primary" onClick={() => {
            popoverRef.current?.hide();
            navigate('/app/settings/billing');
          }}>
            {formatMessage(MSG_billingUpgradeNowButton)}
          </Button>
        </div>
      </Container>
    </Popover>
  );
});

const Container = styled.div`
  padding: 1rem;
  width: 35rem;

  h3 {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;
    list-style: disc;
    margin-left: 2rem;
  }
`;

export {FreePlanNoticePopover};
