import * as React from "react";
import { HTMLAttributes, PropsWithChildren } from "react";
import { StyledLabel } from "../styled/StyledLabel";

interface IProps extends HTMLAttributes<HTMLLabelElement> {}

const Label: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  return (
    <StyledLabel {...props}>
      {props.children}
    </StyledLabel>
  );
};

export {Label};
