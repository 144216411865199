import * as React from "react";
import { PropsWithChildren } from "react";
import { Outlet, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useReloadBootstrapData } from "shared/hooks/use_reload";
import styled from "styled-components";
import { AuthenticatedHeader } from "./AuthenticatedHeader";
import { AuthenticatedBody } from "./AuthenticatedBody";
import { AuthenticatedFooter } from "./AuthenticatedFooter";
import { LoadingPage } from "./LoadingPage";
import { selectApiKey, selectBootstrapping } from "shared/state/store";
import { useSync } from "shared/utils/sync";

interface IProps {}

const AuthenticatedRoot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const reloadBootstrapData = useReloadBootstrapData();
  const bootstrapping = useSelector(selectBootstrapping);
  const apiKey = useSelector(selectApiKey);
  const navigate = useNavigate();
  useSync();

  React.useEffect(() => {
    if (apiKey) {
      void reloadBootstrapData();
    } else {
      navigate("/app/login");
    }
  }, [apiKey]);

  // Show the fancy loading spinner while we are waiting.  Since this is doing a couple
  // round trips to the server, it could take a bit depending on network speeds.  This
  // nice animation makes that wait more pleasant.
  if (bootstrapping) {
    return <LoadingPage/>;
  }

  return (
    <CenteredContainer>
      <LayoutContainer>
        <AuthenticatedHeader/>
        <AuthenticatedBody>
          <Outlet/>
        </AuthenticatedBody>
        <AuthenticatedFooter/>
      </LayoutContainer>
    </CenteredContainer>
  );
};

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  margin: auto;
`;

const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr;
  width: 88rem;
`;

export {AuthenticatedRoot};
