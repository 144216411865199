import React from "react";
import ReactDOMClient from "react-dom/client";
import { GenericError } from "./GenericError";
import { AuthenticatedRoot } from "./authenticated/_AuthenticatedRoot";
import { LedgersRoot } from "./authenticated/./ledger/_LedgersRoot";
import { HelpCenterRoot } from "./authenticated/help_center/_HelpCenterRoot";
import { SettingsRoot } from "./authenticated/settings/_SettingsRoot";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AppRoot } from "./_AppRoot";
import { LoginPage } from "./unauthenticated/login/LoginPage";
import { SignupPage } from "./unauthenticated/signup/SignupPage";
import { UnauthenticatedRoot } from "./unauthenticated/_UnauthenticatedRoot";
import { InitiatePasswordResetPage } from "./unauthenticated/password_reset/InitiatePasswordResetPage";
import { ConfirmPasswordResetPage } from "./unauthenticated/password_reset/ConfirmPasswordResetPage";
import { UsersPage } from "./authenticated/settings/users/UsersPage";
import { AppearancePage } from "./authenticated/settings/appearance/AppearancePage";
import { BillingPage } from "./authenticated/settings/billing/BillingPage";
import { GetStartedPage } from "./authenticated/get_started/GetStartedPage";
import { HelpCenterGroupPage } from "./authenticated/help_center/HelpCenterGroupPage";
import { HelpCenterArticlePage } from "./authenticated/help_center/HelpCenterArticlePage";
import { HelpCenterPage } from "./authenticated/help_center/HelpCenterPage";
import { EnvelopesPage } from "./authenticated/./ledger/envelopes/EnvelopesPage";
import { TransactionsPage } from "./authenticated/./ledger/transactions/TransactionsPage";
import { BudgetPage } from "./authenticated/./ledger/budget/BudgetPage";
import { ImportPage } from "./authenticated/./ledger/import/ImportPage";
import { ReconcilePage } from "./authenticated/./ledger/reconcile/ReconcilePage";
import { ReportsRoot } from "./authenticated/ledger/reports/_ReportsRoot";
import { InvitationPage } from "./unauthenticated/invitation/InvitationPage";
import { PageNotFound } from "./PageNotFound";
import { BudgetVsActualReport } from "./authenticated/ledger/reports/BudgetVsActualReport";
import { IncomeVsExpenseReport } from "./authenticated/ledger/reports/IncomeVsExpenseReport";
import { ContactPage } from "./authenticated/help_center/ContactPage";
import { setLocalStorageDriver } from "shared/utils/local";
import { WebLocalStorageDriver } from "@web/utils/localstorage_driver";
import { LedgerSettingsPage } from "./authenticated/ledger/settings/LedgerSettingsPage";

setLocalStorageDriver(WebLocalStorageDriver);
const router = createBrowserRouter([
  {
    path: "/app",
    errorElement: <GenericError/>,
    element: <AppRoot/>,
    children: [
      {path: "login/*", element: <UnauthenticatedRoot element={<LoginPage/>}/>},
      {path: "password_reset/*",
        children: [
          {path: "confirm/:token", element: <UnauthenticatedRoot element={<ConfirmPasswordResetPage/>}/>},
          {path: "*", element: <UnauthenticatedRoot element={<InitiatePasswordResetPage/>}/>}
        ]},
      {path: "signup/*", element: <UnauthenticatedRoot element={<SignupPage/>}/>},
      {path: "invitation/:token", element: <UnauthenticatedRoot element={<InvitationPage/>}/>},
      {path: "*", element: <AuthenticatedRoot/>,
        children: [
          {path: "ledgers/:ledgerId/*", element: <LedgersRoot/>,
            children: [
              {path: "envelopes/*", element: <EnvelopesPage/>},
              {path: "transactions/:accountId", element: <TransactionsPage/>},
              {path: "budget/*", element: <BudgetPage/>},
              {path: "import/*", element: <ImportPage/>},
              {path: "reconcile/*", element: <ReconcilePage/>},
              {path: "reports/*", element: <ReportsRoot/>,
                children: [
                  {path: "budget_vs_actual", element: <BudgetVsActualReport/>},
                  {path: "income_vs_expense", element: <IncomeVsExpenseReport/>},
                  {path: "*", element: <PageNotFound/>},
                ]},
              {path: "settings/*", element: <LedgerSettingsPage/>},
              {path: "*", element: <PageNotFound/>},
            ]},
          {path: "get_started/*", element: <GetStartedPage/>},
          {path: "help_center/*", element: <HelpCenterRoot/>,
            children: [
              {path: "articles/:groupId/:groupSlug", element: <HelpCenterGroupPage/>},
              {path: "articles/:groupId/:groupSlug/:articleId/:articleSlug", element: <HelpCenterArticlePage/>},
              {path: "articles/*", element: <HelpCenterPage/>},
              {path: "contact", element: <ContactPage/>},
              {path: "*", element: <PageNotFound/>},
            ]},
          {path: "settings/*", element: <SettingsRoot/>,
            children: [
              {path: "appearance/*", element: <AppearancePage/>},
              {path: "billing/*", element: <BillingPage/>},
              {path: "users/*", element: <UsersPage/>},
              {path: "*", element: <PageNotFound/>},
            ]
          },

          // We need this default placeholder so the /app route has an element at its endpoint.
          // We need this so the parent AppRoot and AuthenticatedRoot components are rendered.  We
          // need that because AuthenticatedRoot handles redirecting when the route is /app.  It
          // needs to be in the AuthenticatedRoot that is where bootstrapping occurs, and we can't
          // know where to redirect until we have bootstrapped.
          {path: "*", element: <PageNotFound/>}
        ]
      }
    ]
  }
]);

ReactDOMClient
  .createRoot(document.getElementById("root") as HTMLElement)
  .render(<RouterProvider router={router}/>);
