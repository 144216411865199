import classnames from "classnames";
import { omit } from "lodash";
import * as React from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

interface IProps extends NavLinkProps {
  rootPath: string;
  className: string;
}

const NestedNavLink: React.FunctionComponent<IProps> = (props) => {
  const location = useLocation();
  const active = location.pathname.startsWith(props.rootPath);

  return (
    <NavLink {...omit(props, 'rootPath')} className={classnames(props.className, {active})}/>
  );
};

export {NestedNavLink};
