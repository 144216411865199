import { baseApi } from "../base_api";
import { Account } from "../../../utils/api_types";
import { fromApiAccount, toApiAccount } from "../../../utils/api_transformations";
import { ISelection } from "../../../utils/interfaces";

export const accountsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<Account[], string>({
      query: (ledgerId: string) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_amount');
        params.append('expand[]', 'pending_reconcile_ids');
        return ({
          url: `/ledgers/${ledgerId}/accounts?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return response.accounts.map(fromApiAccount)
      }
    }),

    getAccount: builder.query<Account, {ledgerId: string, accountId: string}>({
      query: (args) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_amount');
        params.append('expand[]', 'pending_reconcile_ids');
        return ({
          url: `/ledgers/${args.ledgerId}/accounts/${args.accountId}?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return fromApiAccount(response.account);
      },
    }),

    updateAccount: builder.mutation<Account, {account: Partial<Account>}>({
      query: ({account}) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_amount');
        // Intentionally not refreshing pending_reconcile_ids because it can cause the checkboxes to flash on/off.
        // params.append('expand[]', 'pending_reconcile_ids');
        return ({
          url: `/ledgers/${account.ledgerId}/accounts/${account.id}?${params.toString()}`,
          method: 'PATCH',
          body: {account: toApiAccount(account)},
        });
      },
      transformResponse: (response: any) => {
        return fromApiAccount(response.account);
      },
    }),

    createAccount: builder.mutation<Account, {account: Partial<Account>}>({
      query: ({account}) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_amount');
        // Intentionally not refreshing pending_reconcile_ids because it can cause the checkboxes to flash on/off.
        // params.append('expand[]', 'pending_reconcile_ids');
        return ({
          url: `/ledgers/${account.ledgerId}/accounts?${params.toString()}`,
          method: 'POST',
          body: {account: toApiAccount(account)},
        });
      },
      transformResponse: (response: any) => {
        return fromApiAccount(response.account);
      },
    }),

    deleteAccount: builder.mutation<{success: boolean}, {ledgerId: string, accountId: string}>({
      query: ({ledgerId, accountId}) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_amount');
        // Intentionally not refreshing pending_reconcile_ids because it can cause the checkboxes to flash on/off.
        // params.append('expand[]', 'pending_reconcile_ids');
        return ({
          url: `/ledgers/${ledgerId}/accounts/${accountId}?${params.toString()}`,
          method: 'DELETE',
        });
      },
      transformResponse: (response: any) => {
        return {success: true};
      },
    }),

    updatePendingReconcileIds: builder.mutation<Account, {ledgerId: string, accountId: string, selection: ISelection}>({
      query: ({ledgerId, accountId, selection}) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_ids');
        params.append('expand[]', 'pending_reconcile_amount');
        // Intentionally not refreshing pending_reconcile_ids because it can cause the checkboxes to flash on/off.
        // params.append('expand[]', 'pending_reconcile_ids');
        return ({
          url: `/ledgers/${ledgerId}/accounts/${accountId}/pending_reconcile_ids?${params.toString()}`,
          method: 'POST',
          body: {...selection},
        });
      },
      transformResponse: (response: any) => {
        return fromApiAccount(response.account);
      },
    }),

    getReconciledTotal: builder.query<number, {ledgerId: string, accountId: string}>({
      query: ({ledgerId, accountId}) => {
        return ({
          url: `/ledgers/${ledgerId}/accounts/${accountId}/reconciled_total`,
        });
      },
      transformResponse: (response: any) => {
        return response.total;
      },
    }),

    reconcile: builder.mutation<Account, {ledgerId: string, accountId: string, selection: ISelection}>({
      query: ({ledgerId, accountId, selection}) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_amount');
        // Intentionally not refreshing pending_reconcile_ids because it can cause the checkboxes to flash on/off.
        // params.append('expand[]', 'pending_reconcile_ids');
        return ({
          url: `/ledgers/${ledgerId}/accounts/${accountId}/reconcile?${params.toString()}`,
          method: 'POST',
          body: {...selection},
        });
      },
      transformResponse: (response: any) => {
        return fromApiAccount(response.account);
      },
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetAccountsQuery, useLazyGetAccountsQuery,
  useGetAccountQuery, useLazyGetAccountQuery,
  useUpdateAccountMutation,
  useCreateAccountMutation,
  useDeleteAccountMutation,
  useUpdatePendingReconcileIdsMutation,
  useGetReconciledTotalQuery, useLazyGetReconciledTotalQuery,
  useReconcileMutation,
} = accountsApi;
