import * as React from "react";
import { PropsWithChildren, RefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSplit } from "@fortawesome/pro-light-svg-icons/faSplit";
import {
  MSG_splitExplanation,
  MSG_splitTitle,
  MSG_transactionsMustSplitIndividually
} from "shared/strings/transactions";
import { useIntlFormatters } from "shared/utils/formatters";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import { ITransactionEditorModal } from "../transaction_editor_modal/TransactionEditorModal";
import { AllocationModalContext } from "./context";
import { StyledDropbox } from "@web/components/styled/StyledDropbox";
import { useAlert } from "@web/utils/hooks";

interface IProps {
  transactionEditorModalRef: RefObject<ITransactionEditorModal>;
  hoverFocusBorder?: boolean;
  forbidden?: boolean;
  afterDrop?: () => void;
}

const SplitDropbox: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const context = React.useContext(AllocationModalContext);
  const {formatMessage} = useIntlFormatters();
  let isOver, dropRef;
  const {showAlert} = useAlert();
  [{isOver}, dropRef] = useDrop(() => ({
      accept: 'unallocated-transaction',
      drop: onDrop,
      collect: monitor => ({
        isOver: monitor.isOver(),
      })
    }
  ), [context.selected]);

  function onDrop() {
    if (context.selected.length > 1) {
      showAlert(formatMessage(MSG_transactionsMustSplitIndividually));
      props.afterDrop?.();
      return;
    }
    if (props.forbidden || context.selected.length === 0) return;
    props.transactionEditorModalRef.current?.show(context.selected[0], props.afterDrop);
    context.reset();
  }

  return (
    <StyledDropbox ref={dropRef}
                   style={{width: '33.33%'}}
                   className={classNames({active: isOver, 'hover-focus-border': props.hoverFocusBorder, forbidden: props.forbidden})}
                   onClick={onDrop}>
      <div className="title">
        <FontAwesomeIcon icon={faSplit} className="me-2"/>
        {formatMessage(MSG_splitTitle)}
      </div>
      <div>
        {formatMessage(MSG_splitExplanation)}
      </div>
    </StyledDropbox>
  );
};

export {SplitDropbox};
