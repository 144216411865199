import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { selectProducts } from "./product_data_selectors";

export const selectBootstrapping = (state: RootState) => {
  return state.data.bootstrapping;
}

export const selectApiKey = (state: RootState) => {
  return state.data.apiKey;
};

export const selectCurrentSubscription = createSelector(
  (state: RootState) => state.data.currentSubscription,
  (currentSubscription) => currentSubscription
);

export const selectCurrentUser = (state: RootState) => {
  return state.data.currentUser;
}

export const selectCurrentProduct = (state: RootState) => {
  const products = selectProducts(state);
  const subscription = selectCurrentSubscription(state);
  if (!products || !subscription) return null;
  return products.find(product => product.productKey === subscription.productKey) || null;
}

export const selectErrorCount = (state: RootState) => {
  return state.data.errorCount;
}
