import { ApiError } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { parseApiError } from "shared/utils/api_errors";
import { Modal } from "@web/components/Modal";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import React, { PropsWithChildren } from "react";
import { FormErrors } from "@web/components/forms/FormErrors";

interface IProps {
  // Important note!  This method needs to call .unwrap() on the result of the API call.
  // This is so that errors are thrown as expected.
  save: () => Promise<any>;
  onCancel?: () => any;
  width?: string;
}

interface ISettingsEditorModal  {
  show: () => any;
  hide: () => any;
}

const SettingsEditorModal = React.forwardRef<ISettingsEditorModal, IProps & PropsWithChildren>((props: IProps & PropsWithChildren, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const {formatMessage} = useIntlFormatters();

  React.useImperativeHandle<any, ISettingsEditorModal>(ref, () => ({
    show: () => {
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
    },

    hide: () => cancel
  }));

  function cancel() {
    setVisible(false);
    props.onCancel?.();
  }

  async function save() {
    try {
      setSaving(true);
      await props.save();
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  return (
    <Modal
      isOpen={visible}
      defaultFormAction={save}
      onRequestClose={cancel}
      windowStyle={{width: props.width || '35rem'}}
      bodyStyle={{padding: 0}}
      footer={
        <div className="text-right row-reverse">
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
          <Button color="secondary" disabled={saving}
                  onClick={cancel}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
        </div>
      }>

      <FormErrors errors={errors}/>

      {props.children}

      <div className="pt-2"/>
    </Modal>
  );
});

export { SettingsEditorModal, ISettingsEditorModal };
