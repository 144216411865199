import * as React from "react";
import { PropsWithChildren } from "react";
import {
  StyledCardSettingsActions,
  StyledCardSettingsBody,
  StyledCardSettingsIcon,
  StyledCardSettingsLabel,
  StyledCardSettingsValue
} from "@web/components/styled/StyledCard";
import { MSG_timeFormatLabel } from "shared/strings/settings";
import { useIntlFormatters } from "shared/utils/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons/faClock";
import { faEdit } from "@fortawesome/pro-light-svg-icons/faEdit";
import dayjs from "dayjs";
import { ISettingsEditorModal, SettingsEditorModal } from "@web/modals/settings_editor_modal/SettingsEditorModal";
import { useUpdateSubscriptionMutation } from "shared/state/endpoints/app/subscriptions_api";
import { selectCurrentSubscription, selectCurrentUser, setCurrentSubscription } from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { getTimeFormatLabel, TIME_FORMATS } from "shared/utils/helpers";
import { OptionSelectorProvider } from "@web/components/shared/OptionSelector";
import { ROLE } from "shared/utils/api_types";

interface IProps {}

const TimeFormatSettings: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage, formatTime} = useIntlFormatters();
  const modalRef = React.useRef<ISettingsEditorModal>(null);
  const subscription = useSelector(selectCurrentSubscription)!;
  const [value, setValue] = React.useState<string>(subscription.timeFormat);
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const editable = currentUser?.role === ROLE.OWNER;

  React.useEffect(() => setValue(subscription.timeFormat), [subscription.timeFormat]);

  async function save() {
    const subscription = await updateSubscription({timeFormat: value}).unwrap();
    dispatch(setCurrentSubscription(subscription));
  }

  function cancel() {
    setValue(subscription.timeFormat);
  }

  const timeAm = dayjs().hour(9).minute(30);
  const timePm = dayjs().hour(17).minute(30);
  const OptionsSelector = OptionSelectorProvider<string>();
  return (
    <React.Fragment>
      <StyledCardSettingsBody className={editable ? "clickable" : ''}
                              style={{paddingTop: '0.75rem', paddingBottom: '0.75rem'}}
                              onClick={editable ? () => modalRef.current?.show() : undefined}>
        <StyledCardSettingsIcon>
          <FontAwesomeIcon icon={faClock}/>
        </StyledCardSettingsIcon>
        <StyledCardSettingsLabel className="clickable-title">
          {formatMessage(MSG_timeFormatLabel)}
        </StyledCardSettingsLabel>
        <StyledCardSettingsValue>
          <div>{formatTime(timeAm, subscription)} / {formatTime(timePm, subscription)}</div>
          <div className="text-small">{getTimeFormatLabel(subscription.timeFormat, formatMessage)}</div>
        </StyledCardSettingsValue>
        {editable && (
          <StyledCardSettingsActions>
            <FontAwesomeIcon icon={faEdit}/>
          </StyledCardSettingsActions>)}
      </StyledCardSettingsBody>

      <SettingsEditorModal save={save} onCancel={cancel} ref={modalRef} width="20rem">
        <OptionsSelector
          value={value}
          onChange={setValue}
          options={TIME_FORMATS.map(format => ({
            value: format,
            label: `${formatTime(timeAm, subscription, {format})} / ${formatTime(timePm, subscription, {format})}`,
            sublabel: getTimeFormatLabel(format, formatMessage)
          }))}
        />
      </SettingsEditorModal>
    </React.Fragment>
  );
};

export {TimeFormatSettings};
