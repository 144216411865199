import * as React from "react";
import { ChangeEvent, PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_dragDropImportMessage,
  MSG_fileTooLarge,
  MSG_importAccountName,
  MSG_importButton,
  MSG_importFormatsAccepted,
  MSG_orImportButton
} from "shared/strings/import";
import { Button } from "@web/components/Button";
import { faCloudArrowUp } from "@fortawesome/pro-light-svg-icons/faCloudArrowUp";
import { useParams } from "react-router-dom";
import { IImportModal, ImportModal } from "@web/modals/import_modal/ImportModal";
import { useSelector } from "react-redux";
import { selectAccountsForLedger } from "shared/state/slices/data_selectors/account_data_selectors";
import { useAlert } from "@web/utils/hooks";
import { MSG_addAnAccountMessage } from "shared/strings/accounts";
import styled, { useTheme } from "styled-components";
import arrowImg from "shared/images/drawn-arrow.png";
import { StyledHorizontalDropbox } from "@web/components/styled/StyledDropbox";

interface IProps {}

const ImportPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const inputRefs = React.useRef<{ [key: string]: HTMLInputElement | null }>({});
  const [dragActive, setDragActive] = React.useState<string | undefined>(undefined);
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const accounts = useSelector(selectAccountsForLedger(ledgerId));
  const importModalRef = React.useRef<IImportModal>(null);
  const {showAlert} = useAlert();
  const theme = useTheme();
  
  const handleDrag = function (e: any, accountId: string) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(accountId);
    } else if (e.type === "dragleave") {
      setDragActive(undefined);
    }
  };

  const handleDrop = function (e: any, accountId: string) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(undefined);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(accountId, e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e: ChangeEvent<HTMLInputElement>, accountId: string) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(accountId, e.target.files[0]);
    }
  };

  const handleFile = function (accountId: string, file: File) {
    if (file.size > 1024*1024) {
      showAlert(formatMessage(MSG_fileTooLarge));
      return;
    }
    console.log(accountId);
    importModalRef.current?.show(file, accountId);
  };

  if (accounts.length === 0) {
    return (
      <div className="text-center m-4">
        <div className="flex-column align-items-start" style={{marginTop: 200}}>
          <InstructionsContainer>
            {formatMessage(MSG_addAnAccountMessage)}
          </InstructionsContainer>
          <ArrowImg src={arrowImg}/>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="text-center mb-4">
        <div className="mt-2">{formatMessage(MSG_importFormatsAccepted)}</div>
      </div>

      {accounts.map((account, i) => (
        <div key={i}>
          <form id={`form-file-upload-${i}`}
            onDragEnter={e => handleDrag(e, account.id)} 
            onDragOver={e => handleDrag(e, account.id)} 
            onDragLeave={e => handleDrag(e, account.id)} 
            onDrop={e => handleDrop(e, account.id)}
            onSubmit={(e) => e.preventDefault()}>
            <input type="file" id={`input-file-upload-${i}`} multiple={false}
              style={{ display: 'none' }}
              onChange={e => handleChange(e, account.id)}
              ref={ref => inputRefs.current[account.id] = ref} />
            <StyledHorizontalDropbox
              onClick={() => inputRefs.current[account.id]?.click()}
              style={{
                padding: '1rem',
                marginBottom: '1rem',
                fontSize: '100%',
                backgroundColor: dragActive === account.id ? '#efefef' : 'inherit'
              }}
            >
              <div>
                <div className="flex-row align-items-center"
                  style={{ 
                    color: theme.colors.mutedTextColor
                  }}
                >
                  <FontAwesomeIcon icon={faCloudArrowUp} style={{ fontSize: '150%' }} className="me-3" />
                  <div className="text-large">{formatMessage(MSG_importAccountName, {accountName: account.name})}</div>
                </div>
                <div>{formatMessage(MSG_dragDropImportMessage, {accountName: account.name})}</div>
              </div>

              <div>
                {formatMessage(MSG_orImportButton, {
                  button: (
                    <Button className="ms-3" onClick={ undefined }>
                      {formatMessage(MSG_importButton, {accountName: account.name})}
                    </Button>
                  )
                })}
              </div>
            </StyledHorizontalDropbox>
          </form>
        </div>
      ))}

      <ImportModal
        ledgerId={ledgerId}
        ref={importModalRef}
      />
    </React.Fragment>
  );
};

const InstructionsContainer = styled.div`
  max-width: 24rem;
  font-size: 1.2rem;
  font-weight: bold;
  transform: rotate(-2deg);
`;

const ArrowImg = styled.img`
  height: 7rem;
  margin-left: 3rem;
  margin-right: 2rem;
  transform: scaleX(-1) scaleY(-1) rotate(-120deg);
`;

export {ImportPage};
