import * as React from "react";
import { PropsWithChildren } from "react";
import { StyledFooterContainer, StyledStackedFooterContainer } from "../styled/StyledFooterContainer";
import { MSG_copyrightNotice, MSG_privacyPolicy, MSG_termsOfService } from "shared/strings/generic";
import { useIntlFormatters } from "shared/utils/formatters";
import dayjs from "dayjs";

interface IProps {
  stacked?: boolean;
}

const AppFooter: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();

  const Container = props.stacked ? StyledStackedFooterContainer : StyledFooterContainer;

  return (
    <Container className="mt-2">
      <div>&copy; {formatMessage(MSG_copyrightNotice, {copyrightDate: dayjs().format('YYYY')})}</div>
      <div>
        <a href="/terms">{formatMessage(MSG_termsOfService)}</a>
        <span style={{marginRight: '0.5rem', marginLeft: '0.5rem'}}>|</span>
        <a href="/privacy">{formatMessage(MSG_privacyPolicy)}</a>
      </div>
    </Container>
  );
};

export {AppFooter};
