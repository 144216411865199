import * as React from "react";
import { INumberInput, INumberInputProps, NumberInput } from "./NumberInput";
import { omit } from "lodash";
import { useSelector } from "react-redux";
import { selectCurrentSubscription } from "shared/state/store";

interface ICurrencyInputProps extends INumberInputProps {
};

const CurrencyInput = React.forwardRef<INumberInput, ICurrencyInputProps>((props, ref) => {
  const subscription = useSelector(selectCurrentSubscription)!;
  const divisor = Math.pow(10, subscription.currencyDecimals);
  return (
    <NumberInput
      {...omit(props, ['onChange', 'value'])}
      ref={ref}
      style={props.style}
      precision={typeof props.precision === 'undefined' ? subscription.currencyDecimals : props.precision}
      totalValue={props.totalValue !== undefined ? props.totalValue / divisor : undefined}
      value={props.value ? props.value / divisor : 0}
      onChange={val => props.onChange(Math.round((val || 0) * divisor))}
    />
  );
});

export {CurrencyInput};
