import styled from "styled-components";

export const StyledVerticalOptions = styled.div`
  border: 1px solid ${({theme}) => theme.innerSidebar.borderColor };
  border-radius: ${({theme}) => theme.innerSidebar.tabBorderRadius };
  overflow: hidden;
  &.noborder {
    border: none;
  }

  .option {
    outline: none;
    display: block;
    padding: 0.5rem 1rem;
    background: ${({theme}) => theme.innerSidebar.unfocusedTabBackgroundColor};
    color: ${({theme}) => theme.innerSidebar.unfocusedTabTextColor};
    text-decoration: none;
    border-bottom: 1px solid ${({theme}) => theme.innerSidebar.borderColor };
    font-size: 1rem;
    text-align: left;

    &.nowrap {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:last-child {
      border-bottom: none;
    }

    svg {
      margin-right: 0.5rem;
    }
    &.alert-red {
      svg {
        color: ${({theme}) => theme.colors.danger };
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &.active {
      color: ${({theme}) => theme.innerSidebar.focusedTabTextColor };
      background: ${({theme}) => theme.innerSidebar.focusedTabBackgroundColor };

      .text-muted {
        color: #ffffff99;
      }
    }
  }

  &.disabled {
    .option {
      pointer-events: none;
      opacity: 0.5;
    }
  }
`;
