import * as React from "react";
import { PropsWithChildren } from "react";
import { useParams } from "react-router";
import { useLazyGetIncomeVsExpenseReportQuery } from "shared/state/endpoints/app/reports_api";
import { Spinner } from "@web/components/Spinner";
import { useIntlFormatters } from "shared/utils/formatters";
import { selectCurrentSubscription } from "shared/state/slices/data_selectors/core_data_selectors";
import { useSelector } from "react-redux";
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledTableRow
} from "@web/components/styled/StyledTable";
import classnames from "classnames";
import {
  MSG_allEnvelopesLabel,
  MSG_averageLabel,
  MSG_differenceHeader,
  MSG_lastYears,
  MSG_pastYearsToDate,
  MSG_totalLabel
} from "shared/strings/reports";
import { IncomeVsExpenseDatum } from "shared/utils/api_types";
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components";
import { MSG_incomeTitle } from "shared/strings/transactions";
import { MSG_expensesLabel } from "shared/strings/budget";
import { EnvelopeSelector } from "@web/components/selectors/EnvelopeSelector";
import { SelectInput } from "@web/components/inputs/SelectInput";
import { selectEnvelopesForLedger } from "shared/state/slices/data_selectors/envelope_data_selectors";

interface IProps {}

const IncomeVsExpenseReport: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgerId = useParams<{ ledgerId: string }>().ledgerId as string;
  const [getReport] = useLazyGetIncomeVsExpenseReportQuery();
  const [report, setReport] = React.useState<{months: {[date: string]: IncomeVsExpenseDatum}} | null>(null);
  const theme = useTheme();
  const subscription = useSelector(selectCurrentSubscription)!;
  const {formatMonth, formatCurrency, formatMessage} = useIntlFormatters();
  const envelopes = useSelector(selectEnvelopesForLedger(ledgerId));
  const [envelopeId, setEnvelopeId] = React.useState<string | null>(null);
  const [range, setRange] = React.useState<string>('1yr-to-date');

  React.useEffect(() => {
    async function execute() {
      try {
        setReport(await getReport({ledgerId, envelopeId, range}).unwrap());
      } catch (e) {
        console.error(e);
      }
    }
    void execute();
  }, [envelopeId, range]);

  const data = React.useMemo(() => {
    if (!report) {
      return [];
    }
    return Object.keys(report.months).map((month) => {
      return {
        month: formatMonth(month+'-01', subscription),
        income: report.months[month].income,
        expense: -report.months[month].expense,
        difference: report.months[month].difference
      };
    });
  }, [report]);

  let totalIncome = React.useMemo(() => {
    if (!report) return 0;
    return Object.values(report.months).reduce((acc, curr) => acc + curr.income, 0);
  }, [report]);
  let averageIncome = React.useMemo(() => {
    if (!report) return 0;
    const months = Object.keys(report.months).length;
    return months > 0 ? totalIncome / months : 0;
  }, [report, totalIncome]);

  let totalExpense = React.useMemo(() => {
    if (!report) return 0;
    return Object.values(report.months).reduce((acc, curr) => acc + curr.expense, 0);
  }, [report]);
  let averageExpense = React.useMemo(() => {
    if (!report) return 0;
    const months = Object.keys(report.months).length;
    return months > 0 ? totalExpense / months : 0;
  }, [report, totalExpense]);

  let totalDifference = React.useMemo(() => {
    if (!report) return 0;
    return Object.values(report.months).reduce((acc, curr) => acc + curr.difference, 0);
  }, [report]);
  let averageDifference = React.useMemo(() => {
    if (!report) return 0;
    const months = Object.keys(report.months).length;
    return months > 0 ? totalDifference / months : 0;
  }, [report, totalDifference]);

  return (
    <div>
      {report ? (
        <div>
          <div className="flex-row">
            <EnvelopeSelector
              ledgerId={ledgerId}
              emptyLabel={formatMessage(MSG_allEnvelopesLabel)}
              placement="bottom"
              envelopeId={envelopeId}
              onChange={setEnvelopeId}
            />
            <SelectInput
              className="ms-4"
              value={range}
              onChange={(e) => setRange(e.target.value)}>
              <option value="1yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 1})}</option>
              <option value="2yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 2})}</option>
              <option value="5yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 5})}</option>
              <option value="10yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 10})}</option>
              <option value="1yr-full">{formatMessage(MSG_lastYears, {years: 1})}</option>
              <option value="2yr-full">{formatMessage(MSG_lastYears, {years: 2})}</option>
              <option value="5yr-full">{formatMessage(MSG_lastYears, {years: 5})}</option>
              <option value="10yr-full">{formatMessage(MSG_lastYears, {years: 10})}</option>
            </SelectInput>
          </div>

          <div style={{width: '100%', height: '25rem'}} className="mt-4">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={300}
                data={data}
                barGap={0}
                margin={{left: 25, right: 50}}
              >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                  dataKey="month"
                />
                <YAxis
                  tickFormatter={(tick) => {
                    return formatCurrency(tick, subscription, {removeDecimals: true});
                  }}
                />
                <YAxis yAxisId="right" hide={true}/>
                <Tooltip
                  formatter={(value: number) => {
                    return formatCurrency(value, subscription);
                  }}
                  labelFormatter={(label) => {
                    return formatMonth(label + '-01', subscription);
                  }}
                />
                <Bar
                  isAnimationActive={false}
                  name={formatMessage(MSG_incomeTitle)}
                  dataKey="income"
                  fill={theme.colors.success}/>
                <Bar
                  isAnimationActive={false}
                  name={formatMessage(MSG_expensesLabel)}
                  dataKey="expense"
                  fill={theme.colors.negativeEnvelopeTextColor}/>
                <Line
                  yAxisId="right"
                  isAnimationActive={false}
                  type="monotone"
                  name={formatMessage(MSG_differenceHeader)}
                  dataKey="difference"
                  stroke={theme.colors.textColor}
                  strokeWidth={0}
                  dot={false}
                  activeDot={{r: 0, strokeWidth: 0}}/>
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          <StyledTable className="mt-4">
            <StyledTableHead>
              <StyledTableHeadRow>
                <StyledTableHeadCell style={{width: '20%'}}></StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}}></StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}} className="text-right">
                  {formatMessage(MSG_incomeTitle)}
                </StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}} className="text-right">
                  {formatMessage(MSG_expensesLabel)}
                </StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}} className="text-right">
                  {formatMessage(MSG_differenceHeader)}
                </StyledTableHeadCell>
              </StyledTableHeadRow>
            </StyledTableHead>
            <StyledTableBody>
              {Object.keys(report.months).map((month) => {
                return (
                  <StyledTableRow key={month}>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>{formatMonth(month + '-01', subscription)}</StyledTableCell>
                    <StyledTableCell className={classnames('text-right', {'text-negative': report.months[month].income < 0})}
                                     style={{width: '20%'}}>
                      {formatCurrency(report.months[month].income, subscription)}
                    </StyledTableCell>
                    <StyledTableCell className={classnames('text-right', {'text-negative': report.months[month].expense < 0})}
                                     style={{width: '20%'}}>
                      {formatCurrency(report.months[month].expense, subscription)}
                    </StyledTableCell>
                    <StyledTableCell className={classnames('text-right', {'text-negative': report.months[month].difference < 0})}
                                     style={{width: '20%'}}>
                      {formatCurrency(report.months[month].difference, subscription)}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
              <StyledTableRow style={{borderTopColor: theme.colors.textColor, borderTopWidth: 2}}>
                <StyledTableCell>{formatMessage(MSG_totalLabel)}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell className={classnames('text-right text-bold', {'text-negative': totalIncome < 0})}>
                  {formatCurrency(totalIncome, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right text-bold', {'text-negative': totalExpense < 0})}>
                  {formatCurrency(totalExpense, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right text-bold', {'text-negative': totalIncome - totalExpense < 0})}>
                  {formatCurrency(totalDifference, subscription)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow style={{borderTopColor: theme.colors.textColor}}>
                <StyledTableCell>{formatMessage(MSG_averageLabel)}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell className={classnames('text-right', {'text-negative': averageIncome < 0})}>
                  {formatCurrency(averageIncome, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right', {'text-negative': averageExpense < 0})}>
                  {formatCurrency(averageExpense, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right', {'text-negative': averageDifference < 0})}>
                  {formatCurrency(averageDifference, subscription)}
                </StyledTableCell>
              </StyledTableRow>
            </StyledTableBody>
          </StyledTable>
        </div>
      ) : (
        <div className="text-center mt-4">
          <Spinner large/>
        </div>
      )}
    </div>
  );
};

export { IncomeVsExpenseReport };
