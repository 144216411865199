import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectIncomeAllocation = (incomeAllocationId: string) => createSelector(
  (state: RootState) => state.data.incomeAllocationsForLedgerHash,
  (incomeAllocationsForLedgerHash) => {
    for (const ledgerId in incomeAllocationsForLedgerHash) {
      if (incomeAllocationsForLedgerHash[ledgerId][incomeAllocationId]) {
        return incomeAllocationsForLedgerHash[ledgerId][incomeAllocationId];
      }
    }
    return null;
  }
)

export const selectIncomeAllocationsForLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.incomeAllocationsForLedgerHash[ledgerId],
  (incomeAllocationsHash) => {
    return Object.values(incomeAllocationsHash)
  }
);
