import styled from "styled-components";

export const StyledErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  color: ${({theme}) => theme.colors.danger };
  display: flex;
  flex-direction: row;
  align-items: center;
  div { margin-left: 0.5rem; }
`;
