import { PayloadAction } from "@reduxjs/toolkit";
import { IDataState } from "../data_slice";
import { Envelope } from "../../../utils/api_types";
import { dataStateSetters } from "./setters";

export const envelopeDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  resetEnvelopes: (state) => {
    state.envelopesForLedgerHash = {};
  },

  setEnvelope: (state, action: PayloadAction<Envelope>) => {
    dataStateSetters.setEnvelope(state, action.payload);
  },

  setEnvelopesForLedger: (state, action: PayloadAction<{ledgerId: string, envelopes: Envelope[]}>) => {
    dataStateSetters.setEnvelopesForLedger(state, action.payload.ledgerId, action.payload.envelopes);
  },

  removeEnvelope: (state, action: PayloadAction<string>) => {
    for (const ledgerId in state.envelopesForLedgerHash) {
      if (state.envelopesForLedgerHash[ledgerId][action.payload]) {
        delete state.envelopesForLedgerHash[ledgerId][action.payload];
      }
    }
  }
};
