import { PayloadAction } from "@reduxjs/toolkit";
import { IDataState } from "../data_slice";
import { IncomeSource } from "../../../utils/api_types";
import { dataStateSetters } from "./setters";

export const incomeSourceDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  resetIncomeSource: (state) => {
    state.incomeSourcesForLedgerHash = {};
  },

  setIncomeSource: (state, action: PayloadAction<IncomeSource>) => {
    dataStateSetters.setIncomeSource(state, action.payload);
  },

  setIncomeSourcesForLedger: (state, action: PayloadAction<{ledgerId: string, envelopes: IncomeSource[]}>) => {
    dataStateSetters.setIncomeSourcesForLedger(state, action.payload.ledgerId, action.payload.envelopes);
  },

  removeIncomeSource: (state, action: PayloadAction<string>) => {
    for (const ledgerId in state.incomeSourcesForLedgerHash) {
      if (state.incomeSourcesForLedgerHash[ledgerId][action.payload]) {
        delete state.incomeSourcesForLedgerHash[ledgerId][action.payload];
      }
    }
  }
};
