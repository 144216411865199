import * as React from "react";
import { PropsWithChildren } from "react";
import { PaymentMethod } from "shared/utils/api_types";
import { MSG_billingExpiresLabel } from "shared/strings/billing";
import { getFormattedCardBrand } from "shared/utils/helpers";
import { useIntlFormatters } from "shared/utils/formatters";

interface IProps {
  paymentMethod: PaymentMethod
}

const PaymentMethodSummary: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {paymentMethod} = props;
  const {formatMessage} = useIntlFormatters();

  return (
    <React.Fragment>
      <strong>{getFormattedCardBrand(paymentMethod.cardBrand)}</strong>{' '}
      <strong className="me-1">{paymentMethod.cardLast4}</strong>
      ({formatMessage(MSG_billingExpiresLabel, {
        month: paymentMethod.cardExpMonth,
        year: paymentMethod.cardExpYear,
      })})
    </React.Fragment>
  );
};

export {PaymentMethodSummary};
