import * as React from "react";
import { PropsWithChildren } from "react";
import DatePicker from "react-datepicker";
import dayjs, { Dayjs } from "dayjs";
import { ITextInputProps, TextInput } from "./TextInput";
import { omit } from "lodash";
import { convertDateFormatToDateFns, parseDateInputString } from "shared/utils/helpers";
import { selectCurrentSubscription } from "shared/state/slices/data_selectors/core_data_selectors";
import { useSelector } from "react-redux";

interface IDateInputProps extends Omit<ITextInputProps, 'onChange' | 'value'> {
  value: Dayjs
  onChange: (date: Dayjs) => any;
}

const DateInput: React.FunctionComponent<IDateInputProps & PropsWithChildren> = (props) => {
  const subscription = useSelector(selectCurrentSubscription)!;

  const handleRawChange = (event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (event) {
      const target = event.target as HTMLInputElement;
      const value = parseDateInputString(target.value, subscription);
      if (value) {
        props.onChange(value);
      }
    }
  };

  const handleChange = (date: Date | null, event: React.SyntheticEvent<any> | undefined) => {
    if (event?.type === 'change') {
      return;
    }
    props.onChange(dayjs(date));
  };


  return (
    <DatePicker
      portalId="root"
      selected={props.value.toDate()}
      onChange={handleChange}
      onChangeRaw={handleRawChange}
      dateFormat={convertDateFormatToDateFns(subscription.dateFormat)}
      disabledKeyboardNavigation
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      customInput={(
        <TextInput
          {...omit(props, ['onChange', 'value'])}
        />)}
    />
  );
};

export {DateInput};
