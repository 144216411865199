import * as React from "react";
import { PropsWithChildren } from "react";
import { ITextInputRef, TextInput } from "@web/components/inputs/TextInput";
import { useIntlFormatters } from "shared/utils/formatters";
import { useInitiatePasswordResetMutation } from "shared/state/endpoints/app/users_api";
import { ApiError } from "shared/utils/api_types";
import { parseApiError } from "shared/utils/api_errors";
import {
  MSG_checkEmailExplanation,
  MSG_checkYourEmail,
  MSG_emailEntered,
  MSG_emailFormLabel,
  MSG_forgotPasswordExplanation,
  MSG_forgotYourPassword,
  MSG_goBack,
  MSG_send
} from "shared/strings/login";
import { Form } from "@web/components/forms/Form";
import { FormErrors } from "@web/components/forms/FormErrors";
import { FormElement } from "@web/components/forms/FormElement";
import { Button } from "@web/components/Button";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faMailBulk } from "@fortawesome/pro-light-svg-icons/faMailBulk";
import { faUserHeadset } from "@fortawesome/pro-light-svg-icons/faUserHeadset";
import { Link } from "react-router-dom";

interface IProps {}

const InitiatePasswordResetPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const emailRef = React.useRef<ITextInputRef>(null);
  const [email, setEmail] = React.useState('');
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const {formatMessage} = useIntlFormatters();
  const [working, setWorking] = React.useState<boolean>(false);
  const [initiatePasswordReset]: any = useInitiatePasswordResetMutation();
  const [apiError, setApiError] = React.useState<ApiError | null>(null);

  const handleSubmit = async () => {
    if (!email) return;
    setWorking(true);
    try {
      await initiatePasswordReset({email: email}).unwrap();
      setSubmitted(true);
    } catch (e) {
      setSubmitted(false);
      setApiError(parseApiError(e));
    }
    setWorking(false);
  };

  if (submitted) {
    return (
      <Container>
        <div className="text-center p-4">
          <FontAwesomeIconStyled icon={faMailBulk}/>
          <HeadingStyled>{formatMessage(MSG_checkYourEmail)}</HeadingStyled>
        </div>

        <p className="text-center pb-4">
          {formatMessage(MSG_emailEntered, {emailAddress: email})}
        </p>
        <p className="text-center pb-4">
          {formatMessage(MSG_checkEmailExplanation)}
        </p>

        <div className="text-center">
          <Link to="/app/login" tabIndex={4}>
            <FontAwesomeIcon icon={faArrowLeft} className="me-2"/>
            {formatMessage(MSG_goBack)}
          </Link>
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <div className="text-center p-4">
          <FontAwesomeIconStyled icon={faUserHeadset}/>
          <HeadingStyled>{formatMessage(MSG_forgotYourPassword)}</HeadingStyled>
        </div>

        <div className="text-muted text-center pb-4">
          {formatMessage(MSG_forgotPasswordExplanation)}
        </div>

        <Form onSubmit={handleSubmit}>
          {apiError && <FormErrors errors={apiError} className="mb-4"/>}

          <FormElement label={formatMessage(MSG_emailFormLabel)}>
            <TextInput
              ref={emailRef}
              autoFocus
              tabIndex={1}
              disabled={working}
              value={email}
              onChange={e => setEmail(e.target.value)}
              leftElement={<FontAwesomeIcon icon={faEnvelope}/>}/>
          </FormElement>

          <div className="flex-row align-items-center justify-content-stretch">
            <div className="flex-grow-1">
              <Link to="/app/login" tabIndex={4}>
                <FontAwesomeIcon icon={faArrowLeft} className="me-2"/>
                {formatMessage(MSG_goBack)}
              </Link>
            </div>
            <Button tabIndex={3} spinner={working} disabled={working}>
              {formatMessage(MSG_send)}
            </Button>
          </div>
        </Form>
      </Container>
    );
  }
};

const Container = styled.div`
  width: 22.25rem;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeadingStyled = styled.h1`
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export {InitiatePasswordResetPage};
