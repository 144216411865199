import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

interface IProps {
  header?: React.ReactNode,
  sidebar?: React.ReactNode,
}

const Page: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  return (
    <PageStyled>
      {props.header && (
        <PageHeaderStyled>
          {props.header}
        </PageHeaderStyled>
      )}
      <PageBodyContainerStyled>
        {props.sidebar && (
          <PageBodySidebarStyled>
            {props.sidebar}
          </PageBodySidebarStyled>
        )}
        <PageBodyContentStyled>
          {props.children}
        </PageBodyContentStyled>
      </PageBodyContainerStyled>
    </PageStyled>
  );
};

export const PageStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const PageHeaderStyled = styled.div`
  background: ${({theme}) => theme.pageHeader.backgroundColor };;
  color: ${({theme}) => theme.pageHeader.color };
  padding: 1rem;
`;

export const PageBodyContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  width: 100%;
`;

export const PageBodySidebarStyled = styled.div`
  padding: ${({theme}) => theme.innerSidebar.padding};
  background: ${({theme}) => theme.innerSidebar.backgroundColor};
  flex-shrink: 1;
`;

export const PageBodyContentStyled = styled.div`
  flex-grow: 1;
`;

export {Page};
