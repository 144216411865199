import { ITheme } from "../utils/interfaces";
import logoImg from "./images/neobudget-logo-darkbg.png";
import iconImg from "./images/neobudget-icon.png";

const NB_GREEN = "#5c9a2b";
const TEXT_COLOR = "#303030";

export const NeoBudgetTheme: ITheme = {
  brand: {
    id: '0de1cbe8-b812-45fb-b503-e10a0e8da288',
    slug: 'neobudget',
    name: 'NeoBudget',
    logoImg: logoImg,
    iconImg: iconImg,
    iconAlign: 'top',
    iconOffset: '20px',
  },
  colors: {
    primary: NB_GREEN,
    info: "#5BB7D5",
    danger: "#cb4330",
    warning: "#E6AF00",
    success: NB_GREEN,
    secondary: "#dfdfdf",
    fundingPool: "#dfdfdf",
    textColor: TEXT_COLOR,
    linkColor: "#7a7a7a",
    mutedTextColor: "#999",
    backgroundTextColor: "#707070",
    background: "#222",
    lightBorder: "#dbdbdb",
    errorBackground: "#fbf0ef",
    errorTextColor: "#be3f2d",
    lightBackgroundColor: "#efefef",
    darkBackgroundColor: "#dfdfdf",
    expenseColor: "#cb4330",
    incomeColor: NB_GREEN,
    moveColor: "#E6AF00",
    transferColor: "#E6AF00",
    negativeTextColor: "#cc0000",
    negativeEnvelopeTextColor: "#ff0000",
    rowHighlightColor: "#f9f9f9",
    alertRedColor: "#cb2310",
  },
  tabBar: {
    backgroundColor: '#666',
    textColor: '#333',
    activeTabBackgroundColor: '#fff',
  },
  pageHeader: {
    backgroundColor: "#366511",
    color: "#fff",
  },
  sidebar: {
    focusedTabBackgroundColor: NB_GREEN,
    focusedTabTextColor: '#fff',
    unfocusedTabBackgroundColor: '#395523',
    unfocusedTabTextColor: '#799662',
    tabBorderRadius: '6px',
  },
  innerSidebar: {
    padding: '1rem',
    backgroundColor: '#efefef',
    focusedTabBackgroundColor: NB_GREEN,
    unfocusedTabBackgroundColor: '#fff',
    focusedTabTextColor: '#fff',
    unfocusedTabTextColor: TEXT_COLOR,
    tabBorderRadius: '6px',
    borderColor: '#dfdfdf',
  },
  window: {
    borderColor: NB_GREEN,
    borderRadius: '6px',
    borderWidth: '8px',
    padding: '1.5rem',
    backgroundColor: '#fff'
  },
  loadingWindow: {
    textColor: '#fff',
  },
  raisedContainer: {
    borderRadius: '4px',
    innerBorderRadius: '3px',
  },
  inputContainer: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    highlightedBackgroundColor: '#fff8d0',
    textColor: TEXT_COLOR,
  },
  unallocatedWindow: {
    sidebarBackgroundColor: '#aaa',
    dropboxActiveBackgroundColor: '#DAEDCC',
    dropboxActiveTextColor: TEXT_COLOR,
    dropboxBorderRadius: '8px',
  }
};
