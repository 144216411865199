import * as React from "react";
import { PropsWithChildren } from "react";
import {
  MSG_billingDangerZone,
  MSG_billingForgetMe,
  MSG_billingForgetMeConfirm,
  MSG_billingForgetMeWarning
} from "shared/strings/billing";
import { parseApiError } from "shared/utils/api_errors";
import { MSG_unexpectedError } from "shared/strings/generic";
import { StyledTextHeader } from "@web/components/styled/StyledText";
import { StyledCard, StyledCardBody } from "@web/components/styled/StyledCard";
import { Button } from "@web/components/Button";
import { ROLE, User } from "shared/utils/api_types";
import { selectCurrentUser } from "shared/state/slices/data_selectors/core_data_selectors";
import { useAlert } from "@web/utils/hooks";
import { useLogout } from "shared/utils/hooks";
import { useIntlFormatters } from "shared/utils/formatters";
import { useSelector } from "react-redux";
import { useForgetMeMutation } from "shared/state/endpoints/app/subscriptions_api";
import { useTheme } from "styled-components";

interface IProps {}

const ForgetMeBox: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const currentUser = useSelector(selectCurrentUser) as User;
  const {formatMessage} = useIntlFormatters();
  const editable = currentUser?.role === ROLE.OWNER;
  const {showErrorAlert} = useAlert();
  const [forgetMeMutation] = useForgetMeMutation();
  const logout = useLogout();
  const theme = useTheme();

  async function forgetMe() {
    if (confirm(formatMessage(MSG_billingForgetMeConfirm))) {
      try {
        await forgetMeMutation({}).unwrap();
        await logout();
      } catch (e) {
        let error = parseApiError(e);
        showErrorAlert(error.errorType === 'message' ? error.message : formatMessage(MSG_unexpectedError));
      }
    }
  }

  if (!editable) return null;
  return (
    <React.Fragment>
      <StyledTextHeader className="mt-4 text-danger text-center">{formatMessage(MSG_billingDangerZone)}</StyledTextHeader>
      <StyledCard style={{borderColor: theme.colors.danger, borderStyle: 'dashed'}}>
        <StyledCardBody className="flex-row align-items-center justify-content-stretch">
          <Button color="danger" onClick={forgetMe}>
            <span className="text-nowrap">{formatMessage(MSG_billingForgetMe)}</span>
          </Button>
          <div className="ms-4">
            {formatMessage(MSG_billingForgetMeWarning)}
          </div>
        </StyledCardBody>
      </StyledCard>
    </React.Fragment>
  );
};

export {ForgetMeBox};
