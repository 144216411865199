import { faBalanceScale } from "@fortawesome/pro-light-svg-icons/faBalanceScale";
import { faChartLine } from "@fortawesome/pro-light-svg-icons/faChartLine";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { faDesktop } from "@fortawesome/pro-light-svg-icons/faDesktop";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faExchangeAlt } from "@fortawesome/pro-light-svg-icons/faExchangeAlt";
import { faFileImport } from "@fortawesome/pro-light-svg-icons/faFileImport";
import { faLifeRing } from "@fortawesome/pro-light-svg-icons/faLifeRing";
import { faMobileAlt } from "@fortawesome/pro-light-svg-icons/faMobileAlt";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { faRocket } from "@fortawesome/pro-light-svg-icons/faRocket";
import { faTools } from "@fortawesome/pro-light-svg-icons/faTools";
import styled from "styled-components";

export const ArticleIcon = styled.div`
  font-size: 2rem;
  margin-right: 1rem;
`;

export const GroupIcon = styled.div`
  font-size: 2rem;
  width: 3rem;
  text-align: center;
  margin-right: 1rem;
`;

export const GroupName = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const GroupDescription = styled.div`
  color: ${({theme}) => theme.colors.mutedTextColor};
`;

export const ArticleTitle = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
  margin: 1.5rem 0;
`;

export function getHelpGroupIcon(iconStr: string | undefined) {
  let icon = faLifeRing;
  if (iconStr === 'rocket') icon = faRocket;
  if (iconStr === 'envelope-open-text') icon = faEnvelope;
  if (iconStr === 'desktop') icon = faDesktop;
  if (iconStr === 'mobile-alt') icon = faMobileAlt;
  if (iconStr === 'exchange-alt') icon = faExchangeAlt;
  if (iconStr === 'file-import') icon = faFileImport;
  if (iconStr === 'chart-line') icon = faChartLine;
  if (iconStr === 'credit-card') icon = faCreditCard;
  if (iconStr === 'question-circle') icon = faQuestionCircle;
  if (iconStr === 'tools') icon = faTools;
  if (iconStr === 'balance-scale') icon = faBalanceScale;
  return icon;
}
