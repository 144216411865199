import * as React from "react";
import { forwardRef, RefObject } from "react";
import { IPopover, Popover } from "../Popover";
import {
  MSG_expenseTypeExplanation,
  MSG_expenseTypeLabel,
  MSG_incomeTypeExplanation,
  MSG_incomeTypeLabel,
  MSG_moveTypeExplanation,
  MSG_moveTypeLabel,
  MSG_transferTypeExplanation,
  MSG_transferTypeLabel
} from "shared/strings/transactions";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { TransactionKind } from "shared/utils/api_types";

interface IProps {
  targetRef: RefObject<any>;
  onSelect: (kind: TransactionKind) => any;
}

const TransactionKindPopover = forwardRef<IPopover, IProps>((props, ref) => {
  const popoverRef = React.useRef<IPopover>(null);
  const {formatMessage} = useIntlFormatters();

  React.useImperativeHandle<any, IPopover>(ref, () => ({
    show: () => popoverRef.current?.show(),
    hide: () => popoverRef.current?.hide(),
  }));

  function select(kind: TransactionKind) {
    props.onSelect(kind);
    popoverRef.current?.hide();
  }

  return (
    <Popover ref={popoverRef}
             targetRef={props.targetRef}
             placement={'bottom'}>
      <KindList>
        <KindItem className="expense" onClick={() => select('expense')}>
          <div className="label">{formatMessage(MSG_expenseTypeLabel)}</div>
          <div className="explanation">{formatMessage(MSG_expenseTypeExplanation)}</div>
        </KindItem>
        <KindItem className="income" onClick={() => select('income')}>
          <div className="label">{formatMessage(MSG_incomeTypeLabel)}</div>
          <div className="explanation">{formatMessage(MSG_incomeTypeExplanation)}</div>
        </KindItem>
        <KindItem className="move" onClick={() => select('move')}>
          <div className="label">{formatMessage(MSG_moveTypeLabel)}</div>
          <div className="explanation">{formatMessage(MSG_moveTypeExplanation)}</div>
        </KindItem>
        <KindItem className="transfer" onClick={() => select('transfer')}>
          <div className="label">{formatMessage(MSG_transferTypeLabel)}</div>
          <div className="explanation">{formatMessage(MSG_transferTypeExplanation)}</div>
        </KindItem>
      </KindList>
    </Popover>
  );
});

const KindList = styled.div`
  padding: 1rem;
`;

const KindItem = styled.div`
  padding: 1rem 1.5rem;
  margin-bottom: 2px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  
  &:first-child {
    border-top-left-radius: ${({theme}) => theme.raisedContainer.borderRadius };
    border-top-right-radius: ${({theme}) => theme.raisedContainer.borderRadius };
  }
  &:last-child {
    border-bottom-left-radius: ${({theme}) => theme.raisedContainer.borderRadius };
    border-bottom-right-radius: ${({theme}) => theme.raisedContainer.borderRadius };
  }

  .label {
    font-size: 1.1rem;
    font-weight: bold;
  }
  .explanation {
    font-size: 0.9rem;
  }

  &:hover {
    box-shadow: inset 0 0 0 0.35rem #fff8;
    .label {
      text-decoration: underline;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.expense {
    background: ${({theme}) => theme.colors.expenseColor };
  }
  &.income {
    background: ${({theme}) => theme.colors.incomeColor };
  }
  &.move {
    background: ${({theme}) => theme.colors.moveColor };
  }
  &.transfer {
    background: ${({theme}) => theme.colors.transferColor };
  }
`;

export {TransactionKindPopover};
