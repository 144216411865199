import { fromApiError } from "./api_transformations";
import { ApiError } from "./api_types";

export function parseApiError(e: any): ApiError {
  if (e.status >= 400 && e.status <= 499) {
    return fromApiError(e?.data)
  } else if (e.status >= 500 && e.status <= 599) {
    return {errorType: 'message', message: e.message};
  } else if (e['error']) {
    return {errorType: 'message', message: e['error']};
  } else if (e.message) {
    return {errorType: 'message', message: e.message};
  } else {
    return {errorType: 'message', message: 'An unexpected error occurred.'};
  }
}

export function extractErrorMessages(e: ApiError): string[] {
  if (e.errorType === 'message') {
    return [e.message];
  } else if (e.errorType === 'models') {
    const messages: string[] = [];
    Object.keys(e.errors).forEach(key => {
      if (e.errors[key].base && e.errors[key].base.length > 0) {
        messages.push(e.errors[key].base[0]);
      }
    });
    return messages;
  } else if (e['error']) {
    return [e['error']];
  } else {
    return [];
  }
}
