import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectAccount = (accountId: string | undefined) => createSelector(
  (state: RootState) => state.data.accountsForLedgerHash,
  (accountsForLedgerHash) => {
    if (!accountId) return null;
    for (const ledgerId in accountsForLedgerHash) {
      if (accountsForLedgerHash[ledgerId][accountId]) {
        return accountsForLedgerHash[ledgerId][accountId];
      }
    }
    return null;
  }
)

export const selectAccountsForLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.accountsForLedgerHash,
  (accountsForLedgerHash) => {
    return Object
      .values(accountsForLedgerHash[ledgerId])
      .sort((a, b) => {
        if (a.sequenceNumber === null) return 1;
        if (b.sequenceNumber === null) return -1;
        if (a.sequenceNumber < b.sequenceNumber) return -1;
        if (a.sequenceNumber > b.sequenceNumber) return 1;
        return 0;
      });
  }
);

export const selectAccountsByLedger = (state: RootState) => state.data.accountsForLedgerHash;

export const selectReconciledTotalForAccount = (ledgerId: string, accountId: string) => createSelector(
  (state: RootState) => state.data.reconciledTotalForAccountsHash,
  (reconciledTotalForAccountHash) => {
    return reconciledTotalForAccountHash[ledgerId]?.[accountId] || 0;
  }
);
