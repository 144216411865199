import * as React from "react";
import { PropsWithChildren } from "react";
import {
  StyledCardSettingsActions,
  StyledCardSettingsBody,
  StyledCardSettingsIcon,
  StyledCardSettingsLabel,
  StyledCardSettingsValue
} from "@web/components/styled/StyledCard";
import { MSG_interfaceScaleLabel } from "shared/strings/settings";
import { useIntlFormatters } from "shared/utils/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons/faEdit";
import { faDisplay } from "@fortawesome/pro-light-svg-icons/faDisplay";
import { ISettingsEditorModal, SettingsEditorModal } from "@web/modals/settings_editor_modal/SettingsEditorModal";
import { useDispatch, useSelector } from "react-redux";
import Slider from "rc-slider";
import { selectCurrentUser, setCurrentUser } from "shared/state/store";
import { useUpdateUserMutation } from "shared/state/endpoints/app/users_api";
import { useTheme } from "styled-components";

interface IProps {}

const InterfaceScaleSettings: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage, formatTime} = useIntlFormatters();
  const modalRef = React.useRef<ISettingsEditorModal>(null);
  const user = useSelector(selectCurrentUser)!;
  const [value, setValue] = React.useState<number>(user.uiScale);
  const [updateUser] = useUpdateUserMutation();
  const dispatch = useDispatch();
  const theme = useTheme();

  React.useEffect(() => setValue(user.uiScale), [user.uiScale]);

  async function save() {
    const _user = await updateUser({user: {id: user.id, uiScale: value}}).unwrap();
    dispatch(setCurrentUser(_user));
  }

  function cancel() {
    setValue(user.uiScale);
  }

  return (
    <React.Fragment>
      <StyledCardSettingsBody className="clickable"
                              style={{paddingTop: '0.75rem', paddingBottom: '0.75rem'}}
                              onClick={() => modalRef.current?.show()}>
        <StyledCardSettingsIcon>
          <FontAwesomeIcon icon={faDisplay}/>
        </StyledCardSettingsIcon>
        <StyledCardSettingsLabel className="clickable-title">
          {formatMessage(MSG_interfaceScaleLabel)}
        </StyledCardSettingsLabel>
        <StyledCardSettingsValue>
          {user.uiScale}%
        </StyledCardSettingsValue>
        <StyledCardSettingsActions>
          <FontAwesomeIcon icon={faEdit}/>
        </StyledCardSettingsActions>
      </StyledCardSettingsBody>

      <SettingsEditorModal save={save} onCancel={cancel} ref={modalRef} width="30rem">

        <div className="p-8">
          <div className="flex-row align-items-end justify-content-stretch mb-4">
            <div style={{width: '3rem'}} className="text-center">
              <FontAwesomeIcon icon={faDisplay} style={{fontSize: '1rem'}}/>
            </div>
            <div className="flex-grow-1"/>
            <div style={{width: '3rem'}} className="text-center">
              <FontAwesomeIcon icon={faDisplay} style={{fontSize: '2rem'}}/>
            </div>
            <div className="flex-grow-1"/>
            <div style={{width: '3rem'}} className="text-center">
              <FontAwesomeIcon icon={faDisplay} style={{fontSize: '3rem'}}/>
            </div>
          </div>

          <Slider
            value={value}
            step={10}
            min={50}
            max={150}
            onChange={(value) => setValue(value as number)}
            trackStyle={{backgroundColor: theme.colors.primary}}
            handleStyle={{borderColor: theme.colors.primary, backgroundColor: theme.colors.primary, boxShadow: 'none'}}
          />

          <div className="mt-4 text-center">
            {value}%
          </div>
        </div>

      </SettingsEditorModal>
    </React.Fragment>
  );
};

export {InterfaceScaleSettings};
