import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyledWindowContainer } from "@web/components/styled/StyledWindowContainer";
import { Form } from "@web/components/forms/Form";
import { useIntlFormatters } from "shared/utils/formatters";
import { ApiError } from "shared/utils/api_types";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import { MSG_firstNameLabel, MSG_lastNameLabel } from "shared/strings/signup";
import { MSG_emailFormLabel } from "shared/strings/login";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faLock } from "@fortawesome/pro-light-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@web/components/Button";
import { faFaceParty } from "@fortawesome/pro-light-svg-icons/faFaceParty";
import { faPartyHorn } from "@fortawesome/pro-light-svg-icons/faPartyHorn";
import { parseApiError } from "shared/utils/api_errors";
import { FormErrors } from "@web/components/forms/FormErrors";
import { useNavigate, useParams } from "react-router-dom";
import { useAcceptInvitationMutation } from "shared/state/endpoints/app/users_api";
import {
  MSG_acceptConfirmation,
  MSG_acceptInvitationButton,
  MSG_chooseAPassword,
  MSG_invitationExplanation,
  MSG_invitationHeader,
  MSG_someoneLabel,
  MSG_usedForLoginNotice
} from "shared/strings/users";
import { useAlert } from "@web/utils/hooks";

interface IProps {}

const InvitationPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = useParams<{token: string}>().token as string;
  const [acceptInvitation]: any = useAcceptInvitationMutation();
  const {formatMessage} = useIntlFormatters();
  const [apiError, setApiError] = React.useState<ApiError | undefined>(undefined);
  const [firstName, setFirstName] = React.useState<string>(searchParams.get('first') || '');
  const [lastName, setLastName] = React.useState<string>(searchParams.get('last') || '');
  const [email, setEmail] = React.useState<string>(searchParams.get('email') || '');
  const [password, setPassword] = React.useState<string>('');
  const [working, setWorking] = React.useState<boolean>(false);
  const {showAlert} = useAlert();
  const navigate = useNavigate();

  async function handleSubmit() {
    setWorking(true);
    try {
      await acceptInvitation({token, user: {email, password, passwordConfirmation: password, firstName, lastName}}).unwrap();
      showAlert(formatMessage(MSG_acceptConfirmation), faFaceParty);
      navigate('/app/login');
    } catch (e) {
      setApiError(parseApiError(e));
    }
    setWorking(false);
  }

  return (
    <Container>
      <div className="text-center p-4">
        <FontAwesomeIconStyled icon={faPartyHorn}/>
        <HeadingStyled>{formatMessage(MSG_invitationHeader)}</HeadingStyled>
        <p>{formatMessage(MSG_invitationExplanation, {name: searchParams.get('from') || formatMessage(MSG_someoneLabel)})}</p>
      </div>

      <Form onSubmit={handleSubmit}>
        {apiError && <FormErrors errors={apiError} className="mb-4"/>}

        <FormElement label={formatMessage(MSG_firstNameLabel)} errorPath="user.first_name" errors={apiError}>
          <TextInput
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            disabled={working}
            autoFocus
            tabIndex={1}
          />
        </FormElement>

        <FormElement label={formatMessage(MSG_lastNameLabel)} errorPath="user.last_name" errors={apiError}>
          <TextInput
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            disabled={working}
            style={{width: '65%'}}
            tabIndex={2}
          />
        </FormElement>

        <FormElement label={formatMessage(MSG_emailFormLabel)}
                     note={formatMessage(MSG_usedForLoginNotice)}
                     errorPath="user.email" errors={apiError}>
          <TextInput
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={working}
            leftElement={<FontAwesomeIcon icon={faEnvelope}/>}
            tabIndex={3}
          />
        </FormElement>

        <FormElement label={formatMessage(MSG_chooseAPassword)} errorPath="user.password" errors={apiError}>
          <TextInput
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={working}
            leftElement={<FontAwesomeIcon icon={faLock}/>}
            tabIndex={4}
          />
        </FormElement>

        <div className="flex-row align-items-center justify-content-stretch">
          <div className="flex-grow-1">
          </div>
          <Button tabIndex={6} spinner={working} disabled={working}>
            {formatMessage(MSG_acceptInvitationButton)}
          </Button>
        </div>
      </Form>

    </Container>
  );
};

const Container = styled.div`
  width: 26rem;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeadingStyled = styled.h1`
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Layout = styled.div`
  margin-top: 6rem;
`;

const LogoImage = styled.img`
  margin: 0 auto;
  margin-bottom: 0.5rem;
  display: block;
  height: 3.75rem;
  max-width: 90%;
`;

const SignupContainer = styled(StyledWindowContainer)`
  margin: 0 auto;
  width: 32rem;
  max-width: 95%;
`;

const PostCodeContainer = styled.div`
  display: none;
`;

export {InvitationPage};
