import { baseApi } from "../base_api";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getExportTransactions: builder.query<{
      exportedCsv: string,
    }, {
      ledgerId: string,
      timeframe: string,
      envelopeId?: string,
    }>({
      query: (args) => {
        return ({
          url: `/ledgers/${args.ledgerId}/export`,
          method: 'POST',
          body: {
            timeframe: args.timeframe,
            envelope_id: args.envelopeId,
          },
        });
      },
      transformResponse: (response: any) => {
        return {
          exportedCsv: response['exported_csv'],
        }
      }
    })
  })
});

export const {
  useGetExportTransactionsQuery,
  useLazyGetExportTransactionsQuery,
} = extendedApi;
