import { StyledVerticalOptions } from "@web/components/styled/StyledVerticalOptions";
import * as React from "react";
import { PropsWithChildren } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_budgetVsActualTitle, MSG_incomeVsExpenseTitle } from "shared/strings/reports";

interface IProps {}

const ReportsRoot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const location = useLocation();
  const navigate = useNavigate();
  const ledgerId = useParams<{ ledgerId: string }>().ledgerId as string;

  React.useEffect(() => {
    if (location.pathname === `/app/ledgers/${ledgerId}/reports`) {
      navigate(`/app/ledgers/${ledgerId}/reports/budget_vs_actual`);
    }
  }, [location.pathname]);


  return (
    <Container>
      <LeftColumn>
        <StyledVerticalOptions className="nowrap" style={{width: '12.5rem', marginTop: '11.5rem'}}>
          <NavLink className="option" to="budget_vs_actual">
            {formatMessage(MSG_budgetVsActualTitle)}
          </NavLink>
          <NavLink className="option" to="income_vs_expense">
            {formatMessage(MSG_incomeVsExpenseTitle)}
          </NavLink>
        </StyledVerticalOptions>
      </LeftColumn>
      <RightColumn>
        <Outlet/>
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  margin: -1rem;
  height: calc(100% + 2rem);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;

const LeftColumn = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 12.5rem;
  background-color: ${props => props.theme.colors.lightBackgroundColor};
  padding: 1rem;
  padding-left: 0;
  height: 100%;
`;

const RightColumn = styled.div`
  flex-grow: 1;
  padding: 1rem;
  height: 100%;
`;

export {ReportsRoot};
