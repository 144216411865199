import * as React from "react";
import { PropsWithChildren } from "react";
import {
  selectCurrentProduct,
  selectCurrentSubscription,
  selectCurrentUser
} from "shared/state/slices/data_selectors/core_data_selectors";
import { useIntlFormatters } from "shared/utils/formatters";
import { ChangePlanModal, IChangePlanModal } from "@web/modals/change_plan_modal/ChangePlanModal";
import { IPaymentMethodModal, PaymentMethodModal } from "@web/modals/payment_method_modal/PaymentMethodModal";
import { selectProducts } from "shared/state/slices/data_selectors/product_data_selectors";
import { useCancelPlanMutation, useUndoCancelPlanMutation } from "shared/state/endpoints/app/subscriptions_api";
import { useAlert } from "@web/utils/hooks";
import { FEATURE_LEVEL, Product, ROLE, Subscription, User } from "shared/utils/api_types";
import { Spinner } from "@web/components/Spinner";
import { findProduct, hasSubscriptionProblems, isLifetimePlan } from "shared/utils/helpers";
import { setCurrentSubscription } from "shared/state/slices/data_slice";
import { parseApiError } from "shared/utils/api_errors";
import { MSG_unexpectedError } from "shared/strings/generic";
import {
  MSG_billingAccountCreditExplanation,
  MSG_billingAccountCreditLabel,
  MSG_billingCancelPlan,
  MSG_billingChangePlan,
  MSG_billingDetailsTitle,
  MSG_billingNextPaymentLabel,
  MSG_billingNone
} from "shared/strings/billing";
import { BillingTier } from "@web/pages/app/authenticated/settings/billing/BillingTier";
import { Button } from "@web/components/Button";
import { StyledCard, StyledCardBody, StyledCardIcon } from "@web/components/styled/StyledCard";
import {
  SubscriptionProblemsCardBody
} from "@web/pages/app/authenticated/settings/billing/SubscriptionProblemsCardBody";
import { StyledTextHeader } from "@web/components/styled/StyledText";
import { PaymentMethodCardBody } from "@web/pages/app/authenticated/settings/billing/PaymentMethodCardBody";
import { ContextHelp } from "@web/components/shared/ContextHelp";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faSackDollar } from "@fortawesome/pro-light-svg-icons";
import { ForgetMeBox } from "@web/pages/app/authenticated/settings/billing/components/ForgetMeBox";

interface IProps {}

const IndividualBillingDetails: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const subscription = useSelector(selectCurrentSubscription);
  const {formatMessage, formatCurrency, formatDate} = useIntlFormatters();
  const changePlanModalRef = React.useRef<IChangePlanModal>(null);
  const product = useSelector(selectCurrentProduct);
  const paymentMethodModalRef = React.useRef<IPaymentMethodModal>(null);
  const allProducts = useSelector(selectProducts);
  const [cancelPlanMutation] = useCancelPlanMutation();
  const [undoCancelPlanMutation] = useUndoCancelPlanMutation();
  const [cancelingPlan, setCancelingPlan] = React.useState<any>(null);
  const dispatch = useDispatch();
  const {showErrorAlert} = useAlert();
  const currentUser = useSelector(selectCurrentUser) as User;
  const editable = currentUser?.role === ROLE.OWNER;

  if (!subscription || !allProducts) {
    return <Spinner/>;
  }

  let scheduledProduct: Product | null = null;
  if (subscription?.scheduledProductKey) {
    scheduledProduct = findProduct(subscription.scheduledProductKey, allProducts) || null;
  }

  async function cancelPlan(doCancel: boolean) {
    if (!subscription) return;
    setCancelingPlan(true);
    try {
      let newSubscription: Subscription;

      if (doCancel) {
        newSubscription = await cancelPlanMutation({}).unwrap();
      } else {
        newSubscription = await undoCancelPlanMutation({}).unwrap();
      }
      dispatch(setCurrentSubscription(newSubscription));
    } catch (e) {
      let error = parseApiError(e);
      showErrorAlert(error.errorType === 'message' ? error.message : formatMessage(MSG_unexpectedError));
    }
    setCancelingPlan(false);
  }

  return (
    <React.Fragment>
      <div className="flex-row align-items-start justify-content-start">
        <ChangePlanModal ref={changePlanModalRef}/>

        <div>
          <BillingTier product={product || null}
                       currentProduct={product || null}
                       scheduledProduct={scheduledProduct || null}
                       subscription={subscription}
                       currentPlanLocation="top">
            {!(scheduledProduct || subscription?.stripeCancelAt) && editable && (
              <div>
                <Button onClick={() => changePlanModalRef.current?.show()}>
                  {formatMessage(MSG_billingChangePlan)}
                </Button>
              </div>
            )}
          </BillingTier>
        </div>

        <div className="flex-grow-1" style={{marginLeft: '2rem'}}>

          {hasSubscriptionProblems(subscription) && (
            <StyledCard className="mb-4 danger">
              <SubscriptionProblemsCardBody subscription={subscription}/>
            </StyledCard>)}

          <StyledTextHeader className="text-center">{formatMessage(MSG_billingDetailsTitle)}</StyledTextHeader>

          <StyledCard>
            <PaymentMethodCardBody
              subscription={subscription}
              paymentMethodModalRef={paymentMethodModalRef}
            />

            <StyledCardBody>
              <div className="flex-row align-items-center justify-content-stretch">
                <StyledCardIcon>
                  <FontAwesomeIcon icon={faCalendar}/>
                </StyledCardIcon>

                <div className="flex-grow-1">
                  <div>
                    {formatMessage(MSG_billingNextPaymentLabel)}{' '}
                    {(subscription.featureLevel === FEATURE_LEVEL.FREE || (subscription.stripeCancelAt && subscription.stripeCancelAt <= subscription.stripePeriodEndsAt) || isLifetimePlan(subscription.productKey))
                      ? <strong>{formatMessage(MSG_billingNone)}</strong>
                      : <strong>{formatDate(subscription.stripePeriodEndsAt, subscription)}</strong>}
                  </div>
                </div>

                {!subscription.stripeCancelAt && !(subscription.featureLevel === FEATURE_LEVEL.FREE || isLifetimePlan(subscription.productKey)) && editable ? (
                  <Button color="secondary" onClick={() => cancelPlan(true)} disabled={cancelingPlan} spinner={cancelingPlan}>
                    {formatMessage(MSG_billingCancelPlan)}
                  </Button>
                ) : (
                  <React.Fragment/>
                )}
              </div>
            </StyledCardBody>
            {!!subscription.stripeAccountBalance && (
              <StyledCardBody className="flex-row align-items-center justify-content-stretch">
                <StyledCardIcon>
                  <FontAwesomeIcon icon={faSackDollar}/>
                </StyledCardIcon>

                <div className="flex-grow-1">
                  {formatMessage(MSG_billingAccountCreditLabel)}{' '}
                  <strong>{formatCurrency(subscription.stripeAccountBalance, subscription, {showSymbol: true})}</strong>{' '}
                  <ContextHelp text={formatMessage(MSG_billingAccountCreditExplanation)}/>
                </div>
              </StyledCardBody>)}
          </StyledCard>

          <ForgetMeBox/>
        </div>

      </div>

      <PaymentMethodModal ref={paymentMethodModalRef}/>
    </React.Fragment>
  );
};

export {IndividualBillingDetails};
