import * as React from "react";
import { Modal } from "@web/components/Modal";
import { ApiError, BLANK_LEDGER, Ledger } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { parseApiError } from "shared/utils/api_errors";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { useNavigate } from "react-router-dom";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import { useDispatch } from "react-redux";
import { useReloadBootstrapData } from "shared/hooks/use_reload";
import { useCreateLedgerMutation, useUpdateLedgerMutation } from "shared/state/endpoints/app/ledgers_api";
import { MSG_ledgerNameLabel } from "shared/strings/ledgers";

interface IProps {
}

interface ILedgerEditorModal  {
  show: (ledger: Ledger) => any;
  hide: () => any;
}

const LedgerEditorModal = React.forwardRef<ILedgerEditorModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<Ledger>(BLANK_LEDGER);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [updateLedger] = useUpdateLedgerMutation();
  const [createLedger] = useCreateLedgerMutation();
  const {formatMessage} = useIntlFormatters();
  const reloadBootstrap = useReloadBootstrapData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useImperativeHandle<any, ILedgerEditorModal>(ref, () => ({
    show: (localLedger: Ledger) => {
      setValue(localLedger);
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    try {
      let isNew: boolean = false;
      let ledger: Ledger | null = null;
      setSaving(true);
      if (value.id) {
        ledger = await updateLedger({ledger: {id: value.id, name: value.name}}).unwrap();
      } else {
        ledger = await createLedger({ledger: {name: value.name}}).unwrap();
        isNew = true;
      }
      await reloadBootstrap();
      setVisible(false);
      if (isNew) {
        navigate(`/app/ledgers/${ledger.id}`);
      }
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  if (!value) return null;

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      bodyStyle={{padding: '1rem'}}
      defaultFormAction={save}
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={saving}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
        </div>
      }>

      {errors?.errorType === 'message' && (
        <div className="d-flex flex-row">
          <StyledErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            <div>{errors.message}</div>
          </StyledErrorMessage>
        </div>
      )}

      <FormElement errors={errors} errorPath="ledger.name" className="flex-grow-1"
                   label={formatMessage(MSG_ledgerNameLabel)}>
        <TextInput
          name="name"
          autoFocus
          style={{width: '100%'}}
          value={value.name}
          onChange={e => setValue({...value, name: e.target.value})}/>
      </FormElement>

    </Modal>
  );
});


export {LedgerEditorModal, ILedgerEditorModal};
