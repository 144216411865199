import * as React from "react";
import { PropsWithChildren } from "react";
import { Envelope } from "shared/utils/api_types";
import styled from "styled-components";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import {
  selectActiveEnvelopesForLedger,
  selectArchivedEnvelopesForLedger
} from "shared/state/slices/data_selectors/envelope_data_selectors";

interface IProps {
  ledgerId: string;
  overrideEnvelopes?: Envelope[];
  includeArchived?: boolean | string[]; // either a list of envelope ids, or a boolean to include all archived envelopes
  cellHeight?: string;
  renderEnvelope: (envelope: Envelope) => React.ReactElement;
  renderArchived?: (envelope: Envelope) => React.ReactElement;
  renderEmptySlot?: (x: number, y: number) => React.ReactElement;
  extraRows?: number;
  minRows?: number;
  archivedHeader?: string;
  archivedSubheader?: string;
}

const EnvelopeGrid: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  let activeEnvelopes = useSelector(selectActiveEnvelopesForLedger(props.ledgerId));
  let archivedEnvelopes = useSelector(selectArchivedEnvelopesForLedger(props.ledgerId));
  if (props.overrideEnvelopes) {
    activeEnvelopes = props.overrideEnvelopes;
    archivedEnvelopes = [];
  }

  function getGridFor(envelopes: Envelope[], options: {extraRows?: number, minRows?: number} = {}) {
    const grid: (Envelope | null)[][] = [];
    if (envelopes.length > 0) {
      grid.push([null, null, null, null, null]);
      sortBy(envelopes, ['appY', 'appX']).forEach(env => {
        while (grid.length < env.appY + 1 + (options.extraRows || 0)) {
          grid.push([null, null, null, null, null]);
        }
        grid[env.appY][env.appX] = env;
      });
    }
    if (options.minRows) {
      let gridLen = grid.length;
      for (let i = 0; i < options.minRows - gridLen; i++) {
        grid.push([null, null, null, null, null]);
      }
    }
    return grid;
  }

  function renderGrid(grid: (Envelope | null)[][], options: {archived: boolean} = {archived: false}) {
    return (
      <React.Fragment>
        {grid.map((row, i) => (
          <Row key={i}>
            {row.map((env, j) => (
              <Cell key={j} style={{height: props.cellHeight}}>
                <CellWrapper>
                  {env
                    ? (options.archived ? (props.renderArchived || props.renderEnvelope)(env) : props.renderEnvelope(env))
                    : (!options.archived && props.renderEmptySlot ? props.renderEmptySlot(j, i) : null)}
                </CellWrapper>
              </Cell>
            ))}
          </Row>
        ))}
      </React.Fragment>
    );
  }

  let modifiedArchivedEnvelopes: Envelope[] = [];
  if (props.includeArchived) {
    let counter: number = 0;
    archivedEnvelopes.forEach((env, i) => {
      if ((env.id && Array.isArray(props.includeArchived) && props.includeArchived.includes(env.id)) || (typeof props.includeArchived === 'boolean' && props.includeArchived)) {
        modifiedArchivedEnvelopes.push({
          ...env,
          appX: counter % 5,
          appY: Math.floor(counter / 5)
        });
        counter += 1;
      }
    });
  }

  const grid = getGridFor(activeEnvelopes, {extraRows: props.extraRows, minRows: props.minRows});
  const archivedGrid = getGridFor(modifiedArchivedEnvelopes);

  return (
    <React.Fragment>
      <Container>
        {renderGrid(grid)}
      </Container>
      {archivedGrid.length > 0 && (
        <Container className="archived" style={{paddingTop: '1rem'}}>
          {(props.archivedHeader || props.archivedSubheader) && (
            <div className="heading">
              <h1>{props.archivedHeader}</h1>
              <p>{props.archivedSubheader}</p>
            </div>)}
          {renderGrid(archivedGrid, {archived: true})}
        </Container>
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;

  &.archived {
    .heading {
      padding: 0.25rem;
      margin-bottom: 0.75rem;

      h1 {
        margin: 0;
        font-size: 1.3rem;
        font-weight: bold;
      }

      p {
        margin: 0;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  margin-bottom: 0.75rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Cell = styled.div`
  flex: 0 1 20%;
  position: relative;
  margin-right: 0.75rem;
  &:last-child {
    margin-right: 0;
  }
  height: 6.25rem;
`;

const CellWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export {EnvelopeGrid};
