import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import arrowImg from "shared/images/drawn-arrow.png";
import { useIntlFormatters } from "shared/utils/formatters";
import { StyledRaisedContainer } from "@web/components/styled/StyledRaisedContainer";
import { MSG_addIncomeSource, MSG_addIncomeSourceExplanation } from "shared/strings/budget";
import { useExampleEnvelopes } from "@web/utils/hooks";
import { selectEnvelopesForLedger } from "shared/state/slices/data_selectors/envelope_data_selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { EnvelopeGrid } from "@web/components/shared/EnvelopeGrid";
import { EnvelopeBox } from "@web/components/shared/EnvelopeBox";

interface IProps {}

const EmptyIncomeSourcesExplanation: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const envelopes = useSelector(selectEnvelopesForLedger(ledgerId)).filter(e => !e.archived);
  const exampleEnvelopes = useExampleEnvelopes();

  return (
    <OuterContainer>
      <InnerContainer>
        {envelopes.length === 0 && (
          <EnvelopeGrid
            ledgerId={ledgerId}
            overrideEnvelopes={exampleEnvelopes}
            renderEnvelope={env => (
              <EnvelopeBox envelope={env}/>
            )}/>
        )}
        <Blocker/>
        <ArrowImg src={arrowImg}/>
        <MessageContainer>
          <MessageContent>
            <div>
              <h3>{formatMessage(MSG_addIncomeSource)}</h3>
              <p>{formatMessage(MSG_addIncomeSourceExplanation)}</p>
            </div>
          </MessageContent>
        </MessageContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -1rem;
  margin-top: 0;
`;

const InnerContainer = styled.div`
  position: relative;
  padding: 1rem;
  padding-top: 2rem;
  height: 100%;
`;

const Blocker = styled.div`
  background-color: #fff;
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MessageContainer = styled(StyledRaisedContainer)`
  position: absolute;
  top: 4rem;
  left: 16rem;
  width: 50%;
  background: #fff;
  transform: rotate(-2deg);
  box-shadow: 0 5px 15px #0006;
  margin-bottom: 1rem;

  h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const MessageContent = styled.div`
  position: relative;
  overflow: hidden;
  padding: 2rem;
`;

const ArrowImg = styled.img`
  position: absolute;
  top: 0;
  left: 9rem;
  width: 7rem;
  transform: scaleX(-1) scaleY(-1) rotate(-20deg);
`;

const CashEnvelopeImg = styled.img`
  position: absolute;
  width: 60%;
  left: 20%;
  right: 20%;
  bottom: -5rem;
`;

export {EmptyIncomeSourcesExplanation};
