import * as React from "react";
import { PropsWithChildren } from "react";
import { StyledEnvelopeContainer } from "../styled/StyledEnvelopeContainer";
import styled from "styled-components";
import { Envelope } from "shared/utils/api_types";

interface IProps {
  envelope: Envelope;
  onClick: (env: Envelope) => any;
  subdued?: boolean;
  className?: string;
}

const TinyEnvelope: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  return (
    <Container
      onClick={e => props.onClick(props.envelope)}
      style={{
        opacity: props.subdued ? 0.5 : 1,
        backgroundColor: props.envelope.color,
        textAlign: 'center'
      }}>

      {props.envelope.icon && <Icon>{props.envelope.icon}</Icon>}
      <Name>{props.envelope.name}</Name>

    </Container>
  );
};

const Container = styled(StyledEnvelopeContainer)`
  overflow: hidden;
  padding: 0.5rem;
  position: relative;
`;

const Name = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-all;
`;

const Icon = styled.div`
  font-size: 1rem;
  position: absolute;
  line-height: 1;
  left: 0.25rem;
  bottom: 0.25rem;
`;

export {TinyEnvelope};
