import { PayloadAction } from "@reduxjs/toolkit";
import { IDataState } from "../data_slice";
import { Product } from "../../../utils/api_types";

export const productDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  setProducts: (state, action: PayloadAction<Product[]>) => {
    state.productsHash = {};
    action.payload.forEach(product => {
      state.productsHash[product.productKey] = product;
    });
  },
}
