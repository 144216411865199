import React, { PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertModal, IAlertModal } from "../../modals/alert_modal/AlertModal";
import { selectCurrentUser, selectErrorCount, setApiKey } from "shared/state/store";
import { AlertContext } from "@web/utils/hooks";
import { Outlet } from "react-router";
import { GlobalStyles } from "@web/utils/global_styles";
import styled, { keyframes } from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_serverErrorMessage, MSG_tryAgainLater } from "shared/strings/generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/pro-light-svg-icons";
import { getCachedApiKey } from "shared/utils/local";

const ERROR_THRESHOLD = 1;

export const AppWrapper: React.FunctionComponent<{} & PropsWithChildren> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const alertModalRef = React.useRef<IAlertModal>(null);
  const alertContextValueRef = React.useRef<{ref: React.RefObject<IAlertModal>}>({ref: alertModalRef});
  const user = useSelector(selectCurrentUser);
  const errorCount = useSelector(selectErrorCount);
  const dispatch = useDispatch();
  const {formatMessage} = useIntlFormatters();

  // Load the cached data from localStorage and populate it into the Redux store.
  React.useEffect(() => {
    async function init() {
      setLoading(true);

      const apiKey = await getCachedApiKey();
      if (apiKey) {
        dispatch(setApiKey(apiKey));
      } else {
        dispatch(setApiKey(null));
      }

      setLoading(false);
    }
    void init();
  }, []);

  // Show a blank screen while loading the data.  This SHOULD just be a few milliseconds
  // since it is just loading from localStorage, but technically it is async so it could be
  // any length of time.  We're not showing the <LoadingLayout/> component because showing
  // it twice would restart the animation and look weird.  This should be fast though.
  if (loading) {
    return <div/>;
  }

  return (
    <React.Fragment>
      <GlobalStyles uiScale={user?.uiScale || 100}/>

      <AlertContext.Provider value={alertContextValueRef.current}>
        <ContentContainer showBanner={errorCount > ERROR_THRESHOLD}>
          <ErrorBanner show={errorCount > ERROR_THRESHOLD}>
            <FontAwesomeIcon icon={faFrown}/>
            <div className="ms-4">
              {formatMessage(MSG_serverErrorMessage)}<br/>
              {formatMessage(MSG_tryAgainLater)}
            </div>
          </ErrorBanner>
          <Outlet/>
        </ContentContainer>
      </AlertContext.Provider>

      <AlertModal ref={alertModalRef}/>
    </React.Fragment>
  );
};

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const ContentContainer = styled.div<{ showBanner: boolean }>`
  transition: padding-top 0.5s ease-in-out;
  padding-top: ${props => props.showBanner ? '5rem' : '0'};
`;

const ErrorBanner = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.danger};
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: translateY(${props => props.show ? '0' : '-100%'});
  transition: transform 0.5s ease-in-out;

  svg {
    font-size: 2.5rem;
  }

  div {
    line-height: 1.3em; // intentionally em here
  }
`;
