import * as React from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_biweeklyFrequencyLabel,
  MSG_incomeSourceAmountLabel,
  MSG_incomeSourceFrequencyLabel,
  MSG_incomeSourceNameLabel,
  MSG_monthlyFrequencyLabel,
  MSG_semimonthlyFrequencyLabel,
  MSG_weeklyFrequencyLabel
} from "shared/strings/budget";
import { TextInput } from "@web/components/inputs/TextInput";
import { FormElement } from "@web/components/forms/FormElement";
import { ApiError, IncomeSource, IncomeSourceFrequency } from "shared/utils/api_types";
import { CurrencyInput } from "@web/components/inputs/CurrencyInput";
import { SelectInput } from "@web/components/inputs/SelectInput";
import { IPopover } from "@web/components/Popover";
import { Button } from "@web/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt";
import {
  DeleteIncomeSourceConfirmationPopover
} from "../../popovers/delete_income_source_popover/DeleteIncomeSourceConfirmationPopover";

interface IProps {
  initialIncomeSource: Partial<IncomeSource>;
  hideLabels?: boolean;
  saving?: boolean;
  autoFocus?: boolean;
  errors?: ApiError;
  onDelete?: () => Promise<any>;
  onChange?: (incomeSource: Partial<IncomeSource>) => any;
}

const IncomeSourceInput: React.FunctionComponent<IProps> = (props) => {
  const { formatMessage } = useIntlFormatters();
  const [name, setName] = React.useState<string>(props.initialIncomeSource?.name || '');
  const [amount, setAmount] = React.useState<number>(props.initialIncomeSource?.amount || 0);
  const [frequency, setFrequency] = React.useState<IncomeSourceFrequency>(props.initialIncomeSource?.frequency || 'semimonthly');
  const deleteButtonPopoverRef = React.useRef<IPopover>(null);
  const deleteButtonRef = React.useRef<HTMLButtonElement>(null);
  const id = props.initialIncomeSource?.id;

  return (
    <div className="flex-row align-items-start justify-content-stretch">
      <FormElement errors={props.errors} errorPath="income_source.name"
                   className="flex-grow-1 me-4"
                   label={props.hideLabels ? '' : formatMessage(MSG_incomeSourceNameLabel)}>
        <TextInput
          name="name"
          autoFocus={props.autoFocus}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => props.onChange?.({ id, name, amount, frequency })}
        />
      </FormElement>

      <FormElement errors={props.errors} errorPath="income_source.amount"
                   className="me-4"
                   style={{width: '10rem'}}
                   label={props.hideLabels ? '' : formatMessage(MSG_incomeSourceAmountLabel)}>
        <CurrencyInput
          name="amount"
          value={amount}
          onChange={(val) => setAmount(val)}
          onBlur={() => props.onChange?.({ id, name, amount, frequency })}
        />
      </FormElement>

      <FormElement errors={props.errors} errorPath="income_source.frequency" className="flex-shrink-1"
                   label={props.hideLabels ? '' : formatMessage(MSG_incomeSourceFrequencyLabel)}>
        <SelectInput
          name="frequency"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value as IncomeSourceFrequency)}
          onBlur={() => props.onChange?.({ id, name, amount, frequency })}
        >
          <option value="monthly">{formatMessage(MSG_monthlyFrequencyLabel)}</option>
          <option value="semimonthly">{formatMessage(MSG_semimonthlyFrequencyLabel)}</option>
          <option value="biweekly">{formatMessage(MSG_biweeklyFrequencyLabel)}</option>
          <option value="weekly">{formatMessage(MSG_weeklyFrequencyLabel)}</option>
        </SelectInput>
      </FormElement>

      {props.onDelete && (
        <div>
          {!props.hideLabels && <div className="mb-1">&nbsp;</div>}
          <Button className="ms-4 mb-4"
                  ref={deleteButtonRef}
                  color="secondary"
                  onClick={() => {
                    deleteButtonPopoverRef.current?.show();
                  }}>
            <FontAwesomeIcon icon={faTrashAlt}/>
          </Button>
          <DeleteIncomeSourceConfirmationPopover
            ref={deleteButtonPopoverRef}
            targetRef={deleteButtonRef}
            onConfirm={props.onDelete}
          />
        </div>)}

    </div>
  );
};

export {IncomeSourceInput};
