import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { MSG_billingGotToButton, MSG_billingSubscriptionProblemNotice } from "shared/strings/billing";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";
import { useIntlFormatters } from "shared/utils/formatters";

interface IProps {}

const SubscriptionProblemsNotice: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const navigate = useNavigate();

  if (window.location.pathname.startsWith('/app/settings/billing')) return null;

  return (
    <SubscriptionNotice className="flex-row align-items-center justify-content-center">
      <div style={{width: '50%'}} className="me-4">
        {formatMessage(MSG_billingSubscriptionProblemNotice)}
      </div>
      <Button color="secondary" onClick={() => navigate('/app/settings/billing')}>
        {formatMessage(MSG_billingGotToButton)}
      </Button>
    </SubscriptionNotice>
  );
};

const SubscriptionNotice = styled.div`
  background-color: ${({theme}) => theme.colors.danger };
  color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: ${({theme}) => theme.window.borderRadius };

  @keyframes flicker {
    0% { opacity: 1; }
    0.1% { opacity: 0.5; }
    0.2% { opacity: 1; }
    100% { opacity: 1; }
  }
  animation: flicker 60s infinite;
  animation-delay: 10s;
`;

export {SubscriptionProblemsNotice};

