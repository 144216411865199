export const MSG_transactionsTitle = {
  id: "22a4849c-a61c-422a-b79f-a83abd8010d7",
  description: "The link that takes them to the list of all their transactions.",
  defaultMessage: "Transactions"
};

export const MSG_newTransactionButton = {
  id: "8c45f49a-9890-4d54-8130-5a834a21b2c3",
  defaultMessage: "New Transaction"
};

export const MSG_numUnallocatedButton = {
  id: "c41b95d6-de2b-4258-b2cc-3681340dc651",
  description: "This is a button that shows the number of unallocated transactions.  Clicking on it shows a window that lets them allocate those transactions.",
  defaultMessage: "{count} Unallocated"
};


export const MSG_dateLabel = {
  id: "4773ba24-a3eb-41a9-acd6-6e9c4273160a",
  description: "The label on top of the list of transactions for the 'Date' column.",
  defaultMessage: "Date"
};

export const MSG_descriptionLabel = {
  id: "51dc5274-6703-4eb9-88d7-bb0c4d60ea94",
  description: "The label on top of the list of transactions for the 'Description' column.",
  defaultMessage: "Description"
};

export const MSG_noDescription = {
  id: "923d69bc-293d-45c4-9bcd-0548b835eb42",
  description: "This is a message that is displayed if a transaction has no description entered.  This is a very short message, no longer than it is in English.",
  defaultMessage: "No description"
};

export const MSG_accountLabel = {
  id: "9d2e7de3-12e9-40c6-b656-88977d3bf2db",
  description: "The label on top of the list of transactions for the 'Account' column.",
  defaultMessage: "Account"
};


export const MSG_amountLabel = {
  id: "cdfb0a29-e936-4e17-b1ad-44763ec0b3bd",
  description: "The label on top of the list of transactions for the 'Amount' column.",
  defaultMessage: "Amount"
};

export const MSG_amountMovedLabel = {
  id: "6abf8fe7-9255-486d-8981-ca498f3c8317",
  description: "The label on top of a description of how much money was moved from one envelope to another.",
  defaultMessage: "Amount moved"
};

export const MSG_amountTransferredLabel = {
  id: "1f85af9e-9e1d-4239-be87-cff85f532024",
  description: "The label on top of a description of how much money was transferred from one account to another.",
  defaultMessage: "Amount transferred"
};

export const MSG_movingMoneyQuestion = {
  id: "535daac5-aa7d-4b16-9ed8-ae3fe08b87c1",
  description: "",
  defaultMessage: "How does moving money work?"
};

export const MSG_movingMoneyExplanation = {
  id: "c9923e08-2c4f-4216-92c1-05e38e19d2f6",
  description: "",
  defaultMessage: "Enter a negative amount in one or several envelopes and a positive amount in one or several other envelopes. The total must add up to {amount}."
};

export const MSG_transferMoneyQuestion = {
  id: "c0aad4f3-6445-4b35-a61f-4a551abd331c",
  description: "",
  defaultMessage: "How do transfers work?"
};

export const MSG_transferMoneyExplanation = {
  id: "8a89b539-a3d3-4132-a7e5-e60915466733",
  description: "",
  defaultMessage: "Enter a negative amount in envelopes on the \"From\" tab and a positive amount in envelopes on the \"To\" tab."
};

export const MSG_moveFrom = {
  id: "1898b4c0-3c22-4d28-bfbd-66cebf48673c",
  description: "A label that says they are moving money from this envelope.  This should be VERY short, no longer than it is in English.  Use an abbreviation if needed.",
  defaultMessage: "Move from"
};

export const MSG_moveTo = {
  id: "1898b4c0-3c22-4d28-bfbd-66cebf48673c",
  description: "A label that says they are moving money to this envelope.  This should be VERY short, no longer than it is in English.  Use an abbreviation if needed.",
  defaultMessage: "Move to"
};

export const MSG_movedAmount = {
  id: "f91fe3ef-1442-4884-b0ef-ddb66153aa5f",
  description: "A description of how much money was moved from one envelope to another.",
  defaultMessage: "Moved {amount}"
};

export const MSG_editSelectedButton = {
  id: "67a85d07-d747-431d-98b3-c3ced43346d5",
  description: "The label for a button that lets them make changes to all the selected transactions at one time.",
  defaultMessage: "Edit Selected"
};

export const MSG_addFiltersButton = {
  id: "f7710e3c-c4ed-4562-96f0-b55e4ed21395",
  description: "The label for a button that lets them add filters to their search to narrow down the results.",
  defaultMessage: "Add Filters"
};

export const MSG_anyEnvelopeFilter = {
  id: "382bf240-0765-4fef-85df-3336230874a9",
  description: "The label for a filter where they can search for transactions that are categorized in any envelope.",
  defaultMessage: "Any Envelope"
};

export const MSG_searchTransactionsPlaceholder = {
  id: "7e5d3915-102a-45e3-aa9f-820438a11efa",
  description: "A placeholder text in an input field that lets them search for transactions.",
  defaultMessage: "Search for transactions..."
};


export const MSG_expenseTypeLabel = {
  id: "99635a24-3f94-4b93-91a7-98c372e788ff",
  description: "There are 4 types of transactions in NeoBudget: expense, income, move, and transfer.  This is the label for the expense type.",
  defaultMessage: "Expense"
};

export const MSG_expenseTypeExplanation = {
  id: "5dc8c29a-b7f8-497a-89c4-f846d78f5d7d",
  description: "A very short explanation of an expense.",
  defaultMessage: "Subtract money"
};

export const MSG_incomeTypeLabel = {
  id: "4dd13ac0-a2f7-4c69-95e5-d928f6b97ff3",
  description: "There are 4 types of transactions in NeoBudget: expense, income, move, and transfer.  This is the label for the income type.",
  defaultMessage: "Income"
};

export const MSG_incomeTypeExplanation = {
  id: "858a7493-8889-4df0-9793-09e74335606b",
  description: "A very short explanation of an income transaction",
  defaultMessage: "Add money"
};

export const MSG_moveTypeLabel = {
  id: "30c53c8b-0fa9-410e-8541-7f5afcc3db34",
  description: "There are 4 types of transactions in NeoBudget: expense, income, move, and transfer.  This is the label for the move type.  This type is for moving money from one envelope to another within the same account.",
  defaultMessage: "Move"
};

export const MSG_moveTypeExplanation = {
  id: "729dd8ba-55b1-47d6-bc36-d53180f87481",
  description: "A brief explanation of what a move transaction does.",
  defaultMessage: "Between envelopes"
};

export const MSG_transferTypeLabel = {
  id: "d70e3073-0bf7-4727-9909-8567c5a90c25",
  description: "There are 4 types of transactions in NeoBudget: expense, income, move, and transfer.  This is the label for the transfer type.  This type is for transferring money between two accounts.",
  defaultMessage: "Transfer"
};

export const MSG_transferTypeExplanation = {
  id: "7432c07e-0ad3-45f2-ad57-68015d9d13ec",
  description: "A brief explanation of what a transfer transaction does",
  defaultMessage: "Between accounts"
};

export const MSG_balanceLabel = {
  id: "48206d34-e109-471c-9c04-6115b784973c",
  description: "A label for the current balance of an envelope.  The balance is the total amount of money in this envelope.",
  defaultMessage: "Balance"
};

export const MSG_receivedFromLabel = {
  id: "4e978d65-5515-4631-af40-6a472b963d78",
  description: "A label on top of a description of who a transaction was received from.",
  defaultMessage: "Received from"
};

export const MSG_sentToLabel = {
  id: "0ce68b7d-be02-435e-b3b8-cff2899f2f86",
  description: "A label on top of a description of who a transaction was paid to.",
  defaultMessage: "Paid to"
};

export const MSG_notesLabel = {
  id: "e7cb5762-6479-4e06-ab5b-c48a336daa66",
  description: "A label above a form field where they can add notes about what a transaction is for.",
  defaultMessage: "Notes"
};

export const MSG_showNotesLabel = {
  id: "a0b480ee-0d8b-4818-acc5-150e57783035",
  description: "The label for a button that shows the notes for a transaction.",
  defaultMessage: "Show notes"
};

export const MSG_hideNotesLabel = {
  id: "1f1e42bc-ddf7-42b9-b24d-94dff40196e9",
  description: "The label for a button that hides the notes for a transaction.",
  defaultMessage: "Hide notes"
};

export const MSG_checkNumberShortLabel = {
  id: "4071f6cf-b3d0-4700-a91d-18900b6b1bf0",
  description: "A short label for 'Check Number'.",
  defaultMessage: "Check #"
};

export const MSG_searchByEnvelopeName = {
  id: "a2d8271e-d070-4533-bdfd-0503e4ff83c0",
  description: "",
  defaultMessage: "Search by envelope name..."
};

export const MSG_allocatedDiff = {
  id: "279408a6-5477-4b7a-a578-e1dfb821d5a4",
  description: "",
  defaultMessage: "There is still {amount} that needs to be allocated to your envelopes."
};

export const MSG_allocatedDiffShort = {
  id: "14f1731e-8bb8-4125-ba89-e0b04cb3218a",
  description: "",
  defaultMessage: "{amount} to be allocated"
};

export const MSG_overAllocatedByAmount = {
  id: "7937f73c-a43a-4e74-9b63-13a0d5efc732",
  description: "This message shows up when they allocate more money for a transaction than the total amount of the transaction.",
  defaultMessage: "Over-allocated by {amount}"
};

export const MSG_overAllocatedExplanation = {
  id: "819a4747-4177-4c0c-95e7-323a85df7e7c",
  description: "",
  defaultMessage: "You have allocated more money in the envelopes below than the total amount of this transaction."
};

export const MSG_underAllocatedByAmount = {
  id: "eef24902-c427-4591-a6fa-29e326dba825",
  description: "This message shows up when they have not allocated all of the money for the transaction yet.",
  defaultMessage: "Under-allocated by {amount}"
};

export const MSG_underAllocatedExplanation = {
  id: "997f48de-639a-452a-b520-181b0a423515",
  description: "",
  defaultMessage: "You have not allocated the total amount of this transaction to the envelopes below."
};

export const MSG_archivedEnvelopesTitle = {
  id: "4b76d62c-b008-439d-84b2-334cbf453241",
  description: "The title above the list of envelopes that have been archived.",
  defaultMessage: "Archived Envelopes"
};

export const MSG_archivedEnvelopesExplanation = {
  id: "8b4a5349-fd30-4c93-95f0-b3152250495a",
  description: "",
  defaultMessage: "This transaction used some envelopes that are now archived. These cannot be modified."
};

export const MSG_moveArchivedExplanation = {
  id: "38474d96-aa07-4eed-ad9e-0438333ce84e",
  description: "",
  defaultMessage: "Move these envelopes up to unarchive them."
};


export const MSG_allocationInstructions = {
  id: "737753ac-f670-4ce4-8ffd-3ff06aa24202",
  description: "These are instructions telling them that they need to take the total amount of a transaction and tell us which envelopes to take that money out of.  Note that this translation is very space-sensitive.  Try to make it the same length as the English version.",
  defaultMessage: "Allocate this amount across your envelopes."
};

export const MSG_noTransactionsFoundMatchingQuery = {
  id: "2b0e6b52-6a23-48ac-acd4-d011031d5012",
  description: "",
  defaultMessage: "No transactions were found matching this query."
};

export const MSG_noTransactionsFoundInEnvelope = {
  id: "f02c220f-54c7-40c8-93e9-363bb8e47847",
  description: "",
  defaultMessage: "No transactions were found in this envelope."
};

export const MSG_noTransactionsFound = {
  id: "d064d0f8-6117-4304-9962-a06fc1c70860",
  description: "",
  defaultMessage: "No transactions were found."
};

export const MSG_arithmeticTip = {
  id: "16a3230d-6f91-4f6b-bddb-89604c869b43",
  description: "This is a tip that they can do math in the input fields.",
  defaultMessage: "You can do arithmetic in these fields like: 25+5.31."
};

export const MSG_percentTip = {
  id: "19f13f54-5745-4aee-a106-7daede56d0ea",
  description: "This is a tip that they can use the % sign to input a percent of the total amount.",
  defaultMessage: "You can also allocate a percent of the total like: 10%."
};

export const MSG_importedOnDetails = {
  id: "2b0ce4c4-c7bf-44dd-97a3-23af53766541",
  description: "The date that a transaction was imported from a bank using some sort of automated method, either by uploading a list of transactions or by automatic sync with the bank.",
  defaultMessage: "Imported on {date}"
};

export const MSG_manuallyEnteredOnDetails = {
  id: "9bacdb3a-b09a-45f9-be59-46f82c535e13",
  description: "The date that a transaction was entered by hand (e.g. not imported from a bank but was imported by a person entering all the details).",
  defaultMessage: "Manually entered on {date}"
};

export const MSG_mustHaveTwoAccounts = {
  id: "4c4457da-23ac-431d-bd5d-f49672996d99",
  description: "",
  defaultMessage: "You must have at least two accounts to record a transfer."
};

export const MSG_fromAccountLabel = {
  id: "56e252dc-3be2-4cea-a674-3b34d5c8e6a4",
  description: "This shows which account we are transferring money from.",
  defaultMessage: "From {ledgerName}: {accountName}"
};

export const MSG_toAccountLabel = {
  id: "9912a008-8c06-4624-b67a-6e4384e160bc",
  description: "This shows which account we are transferring money to.",
  defaultMessage: "To {ledgerName}: {accountName}"
};

export const MSG_transferToFrom = {
  id: "e3e64f0f-1215-41c7-bebb-23df9ad2828c",
  description: "",
  defaultMessage: "Transfer from {fromLedgerName}: {fromAccountName} to {toLedgerName}: {toAccountName}"
};

export const MSG_movingMoneyBetweenEnvelopes = {
  id: "f213f6df-27b2-4b36-8949-c875c78eb3b0",
  description: "",
  defaultMessage: "Moving money between envelopes"
};

export const MSG_checkNumberBadge = {
  id: "280d1b83-0c13-4116-a92c-9ca188146a61",
  description: "This tells them the check number for a transaction.  Will read like 'Check #1234'",
  defaultMessage: "Check #{number}"
};

export const MSG_deleteTransactionConfirmation = {
  id: "145c7012-96e2-4f51-9659-9d982259a02d",
  description: "",
  defaultMessage: "{count, plural, =1 {Are you sure you want to delete this transaction?} other {Are you sure you want to delete these # transactions?}}"
};

export const MSG_deleteTransactionConfirmationExplanation = {
  id: "b3a789c9-ea37-448a-bd5d-5f63b1459453",
  description: "",
  defaultMessage: "Deleting a transaction cannot be undone. If you delete a transaction and need it back later, you will need to create a new transaction or import it again from your bank."
};

export const MSG_deleteConfirmationButton = {
  id: "f33eafe8-daa3-45a9-b18a-01fe82a9cc63",
  description: "",
  defaultMessage: "{count, plural, =1 {Yes, delete this transaction} other {Yes, delete these transactions}}"
};

export const MSG_useIncomeAllocationsButton = {
  id: "d1b6f744-f481-476f-bd44-85dc71655aca",
  description: "This is the button that lets them choose which set of income allocations to use when allocating the money from an income source across their envelopes.",
  defaultMessage: "Use Income Allocations"
};

export const MSG_useSpecificIncomeAllocations = {
  id: "3c09f01a-ac83-4060-b7d1-c9a7cb5c49c1",
  description: "This is the label for using a specific income allocation, by name.",
  defaultMessage: "Use {incomeAllocationName}"
};

export const MSG_ordinalOfTheMonth = {
  id: "429649b4-f9f5-4ea0-ba0c-356c07f995fa",
  description: "Here 'count' will be 1st, 2nd, 3rd, etc.  So in English it will read like '1st of the month', or '2nd of the month'.  This indicates which paycheck they are selecting (the first one for the month, the second one, etc)",
  defaultMessage: "{count} of the month"
};

export const MSG_unallocatedTransactionsTitle = {
  id: "9fe7a613-9f74-4317-8d0b-54baebfce2d8",
  description: "The title for the window that lists all of their transactions that have not yet been allocated to envelopes.",
  defaultMessage: "Unallocated Transactions"
};

export const MSG_unallocatedTransactionsExplanation = {
  id: "cb546c8c-6768-4e45-9e79-13634d026d6e",
  description: "",
  defaultMessage: "Drag right to allocate transactions. Click several transactions to allocate many at once."
};

export const MSG_filterUnallocatedPlaceholder = {
  id: "5d651256-ea2c-442f-9e9e-e0b59ad84c86",
  description: "A prompt for them to filter their transactions.  this should be pretty short.",
  defaultMessage: "Filter..."
};

export const MSG_showingXofY = {
  id: "5d80d393-d202-4982-9ae5-deb72a095ea5",
  description: "",
  defaultMessage: "Showing {count} of {total}"
};

export const MSG_splitTitle = {
  id: "394bd47a-cf8b-4a44-8156-8ff4695482ce",
  description: "This is the label for a section where they can drag a transaction if they want to split its allocations into multiple envelopes.",
  defaultMessage: "Split"
};

export const MSG_splitExplanation = {
  id: "6cc33133-f180-4956-902b-05aa8e8a9eb4",
  description: "",
  defaultMessage: "Drag a single transaction here to split it across multiple envelopes."
};

export const MSG_incomeTitle = {
  id: "0a69ea9a-1604-42ec-8ad6-01eccbaa8cce",
  description: "This is the label for a section where they can drag a transaction to indicate that it is budgeted income and use the pre-defined income allocations for allocating this transaction.",
  defaultMessage: "Income"
};

export const MSG_incomeExplanation = {
  id: "4e369523-b9db-4a90-9e35-e2702a12f79c",
  description: "",
  defaultMessage: "Drag one of your income transactions here to split it according to your budget."
};

export const MSG_deleteTitle = {
  id: "112d3e87-b5c0-4e54-990c-f720aaf6c0ff",
  description: "This is the label for a section where they can drag a transaction to delete it.",
  defaultMessage: "Delete"
};

export const MSG_deleteExplanation = {
  id: "bc7391cf-2e39-4382-8a91-cdff1a896a77",
  description: "",
  defaultMessage: "Drag one or many transactions here to delete them."
};

export const MSG_incomesMustBeSplitIndividually = {
  id: "1ae61ca3-b7e7-4a05-98c2-f46df10d6536",
  description: "",
  defaultMessage: "Income transactions must be split individually. Please select only one income transaction at a time."
};

export const MSG_incomesMustBeIncome = {
  id: "dcb579ce-b57c-478e-8ab6-37b389e4722d",
  description: "",
  defaultMessage: "This is not an income transaction. Please select one income transaction to split it as one of your pre-defined income sources."
};

export const MSG_noIncomeSourcesExplanation = {
  id: "a95afe09-2e0e-4934-8cda-9132124ed311",
  description: "",
  defaultMessage: "You do not have any income sources configured.  Please visit the Budget tab and add at least one income source up at the top."
};


export const MSG_transactionsMustSplitIndividually = {
  id: "20175648-bdd3-4c22-a4cf-d0b0b9c2ed57",
  description: "",
  defaultMessage: "Transactions must be split individually. Please select only one transaction for splitting."
};

export const MSG_incomeSourceNotes = {
  id: "313a7e57-0cf5-4a25-b5f6-3603f993cf4f",
  description: "This is a label for a field where they can add notes about what this income source is for.  The ordinal will be like 1st, 2nd, 3rd, etc.",
  defaultMessage: "{ordinal} income from {incomeName} for the month"
};

export const MSG_cannotCreateTransactionWithoutAccounts = {
  id: "efa0c0f7-9150-424e-bb8d-1e96eef8c8fa",
  description: "",
  defaultMessage: "You cannot create a transaction without any accounts.  Please create an account first, then you can add transactions to that account."
};

export const MSG_nothingAllocatedConfirmation = {
  id: "7190f6a0-5b04-484b-ae35-61a3f90e9f66",
  description: "",
  defaultMessage: "You have not allocated any money to your envelopes.  Are you sure you want to save this transaction?"
};
