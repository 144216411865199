import * as React from "react";
import { PropsWithChildren } from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import { selectCurrentUser, selectOtherUsers } from "shared/state/store";
import { ROLE, User } from "shared/utils/api_types";
import { IUserEditorModal, UserEditorModal } from "@web/modals/user_editor_modal/UserEditorModal";
import { ChangePasswordModal, IChangePasswordModal } from "@web/modals/change_password_modal/ChangePasswordModal";
import { IUserInvitationModal, UserInvitationModal } from "@web/modals/user_invitation_modal/UserInvitationModal";
import { StyledTextHeader } from "@web/components/styled/StyledText";
import {
  MSG_deactivatedUsersHeader,
  MSG_inviteAnotherUserButton,
  MSG_otherUsersHeader,
  MSG_yourProfileHeader
} from "shared/strings/users";
import { StyledCard } from "@web/components/styled/StyledCard";
import { UserCardBody } from "./UserCardBody";
import { Button } from "@web/components/Button";
import { useSelector } from "react-redux";

interface IProps {}

const UsersPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const { formatMessage } = useIntlFormatters();
  const currentUser = useSelector(selectCurrentUser) as User;
  const otherUsers = useSelector(selectOtherUsers(currentUser.id));
  const userEditorModalRef = React.useRef<IUserEditorModal>(null);
  const changePasswordModalRef = React.useRef<IChangePasswordModal>(null);
  const userInvitationModalRef = React.useRef<IUserInvitationModal>(null);
  const editable = currentUser.role === ROLE.OWNER;

  const otherActiveUsers = otherUsers.filter((user) => user.active);
  const otherInactiveUsers = otherUsers.filter((user) => !user.active);

  return (
    <div>
      <StyledTextHeader>{formatMessage(MSG_yourProfileHeader)}</StyledTextHeader>
      <StyledCard>
        <UserCardBody
          userId={currentUser.id}
          onEditClick={() => userEditorModalRef.current?.show(currentUser)}
          onChangePasswordClick={() => changePasswordModalRef.current?.show(currentUser)}
        />
      </StyledCard>

      {otherActiveUsers.length > 0 && (
        <React.Fragment>
          <StyledTextHeader className="mt-4">{formatMessage(MSG_otherUsersHeader)}</StyledTextHeader>
          <StyledCard>
            {otherActiveUsers.map((user) => (
              <UserCardBody
                key={user.id}
                userId={user.id}
                onEditClick={editable ? () => {
                  userEditorModalRef.current?.show(user);
                } : undefined}
              />
            ))}
          </StyledCard>
        </React.Fragment>
      )}

      {otherInactiveUsers.length > 0 && (
        <React.Fragment>
          <StyledTextHeader className="mt-4">{formatMessage(MSG_deactivatedUsersHeader)}</StyledTextHeader>
          <StyledCard>
            {otherInactiveUsers.map((user) => (
              <UserCardBody
                key={user.id}
                userId={user.id}
                onEditClick={editable ? () => {
                  userEditorModalRef.current?.show(user);
                } : undefined}
              />
            ))}
          </StyledCard>
        </React.Fragment>
      )}

      {editable && (
        <div className="mt-4">
          <Button onClick={() => userInvitationModalRef.current?.show()}>
            {formatMessage(MSG_inviteAnotherUserButton)}
          </Button>
        </div>)}

      <UserEditorModal ref={userEditorModalRef}/>
      <ChangePasswordModal ref={changePasswordModalRef}/>
      <UserInvitationModal ref={userInvitationModalRef}/>
    </div>
  );
};

export {UsersPage};
