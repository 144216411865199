import { ApiError, ROLE, User } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { parseApiError } from "shared/utils/api_errors";
import { Modal } from "@web/components/Modal";
import { Button } from "@web/components/Button";
import { MSG_cancelButton } from "shared/strings/generic";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import React from "react";
import { FormErrors } from "@web/components/forms/FormErrors";
import { MSG_firstNameLabel, MSG_lastNameLabel } from "shared/strings/signup";
import {
  MSG_invitationSentAlert,
  MSG_inviteAnotherUserButton,
  MSG_observerExplanation,
  MSG_observerLabel,
  MSG_ownerExplanation,
  MSG_ownerLabel,
  MSG_roleLabel,
  MSG_sendInvitationButton,
  MSG_stewardExplanation,
  MSG_stewardLabel,
  MSG_usedForLoginNotice
} from "shared/strings/users";
import { useDispatch, useSelector } from "react-redux";
import { useCreateUserMutation } from "shared/state/endpoints/app/users_api";
import { selectCurrentUser, setUser } from "shared/state/store";
import { MSG_emailFormLabel } from "shared/strings/login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPartyHorn } from "@fortawesome/pro-light-svg-icons/faPartyHorn";
import { faUserPlus } from "@fortawesome/pro-light-svg-icons/faUserPlus";
import { useAlert } from "@web/utils/hooks";

interface IProps {
}

interface IUserInvitationModal  {
  show: (user?: User) => any;
  hide: () => any;
}

const UserInvitationModal = React.forwardRef<IUserInvitationModal, IProps>((props: IProps, ref) => {
  const currentUser = useSelector(selectCurrentUser) as User;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [role, setRole] = React.useState<ROLE>(ROLE.STEWARD);
  const [createUser] = useCreateUserMutation();
  const {formatMessage} = useIntlFormatters();
  const {showAlert} = useAlert();
  const dispatch = useDispatch();

  React.useImperativeHandle<any, IUserInvitationModal>(ref, () => ({
    show: () => {
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
      setFirstName('');
      setLastName('');
      setEmail('');
      setRole(ROLE.STEWARD);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    try {
      setSaving(true);
      let user: User;
      user = await createUser({user: {
        firstName, lastName, email, role
      }}).unwrap();
      dispatch(setUser(user));
      setVisible(false);

      showAlert(formatMessage(MSG_invitationSentAlert, {email: user.email}), faPartyHorn);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      defaultFormAction={save}
      windowStyle={{width: '55rem'}}
      bodyStyle={{padding: '2rem'}}
      footer={
        <div className="text-right row-reverse">
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_sendInvitationButton)}
          </Button>
          <Button color="secondary" disabled={saving}
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
        </div>
      }>

      <div className="text-center mb-6">
        <FontAwesomeIcon icon={faUserPlus} style={{fontSize: '3rem'}}/>
        <h2>{formatMessage(MSG_inviteAnotherUserButton)}</h2>
      </div>

      <FormErrors errors={errors}/>

      <div className="flex-row align-items-start justify-content-start">
        <div className="me-8" style={{width: '55%'}}>
          <FormElement errors={errors} errorPath="user.first_name"
                       label={formatMessage(MSG_firstNameLabel)}>
            <TextInput
              name="first_name"
              autoFocus
              value={firstName}
              onChange={e => setFirstName(e.target.value)}/>
          </FormElement>

          <FormElement errors={errors} errorPath="user.last_name"
                       label={formatMessage(MSG_lastNameLabel)}>
            <TextInput
              name="last_name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}/>
          </FormElement>

          <FormElement errors={errors} errorPath="user.email"
                       note={formatMessage(MSG_usedForLoginNotice)}
                       label={formatMessage(MSG_emailFormLabel)}>
            <TextInput
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}/>
          </FormElement>
        </div>
        <div style={{width: '45%'}}>
          <FormElement errors={errors} errorPath="user.role" label={formatMessage(MSG_roleLabel)}>
            <label className="has-checkbox">
              <input type="radio"
                     name="role"
                     checked={role === ROLE.OWNER}
                     onChange={e => setRole(e.target.checked ? ROLE.OWNER : ROLE.STEWARD)}/>
              <div>
                <div>{formatMessage(MSG_ownerLabel)}</div>
                <div className="text-muted">
                  {formatMessage(MSG_ownerExplanation)}
                </div>
              </div>
            </label>
            <label className="has-checkbox mt-2">
              <input type="radio"
                     name="role"
                     checked={role === ROLE.STEWARD}
                     onChange={e => setRole(e.target.checked ? ROLE.STEWARD : ROLE.STEWARD)}/>
              <div>
                <div>{formatMessage(MSG_stewardLabel)}</div>
                <div className="text-muted">
                  {formatMessage(MSG_stewardExplanation)}
                </div>
              </div>
            </label>
            <label className="has-checkbox mt-2">
              <input type="radio"
                     name="role"
                     checked={role === ROLE.OBSERVER}
                     onChange={e => setRole(e.target.checked ? ROLE.OBSERVER : ROLE.STEWARD)}/>
              <div>
                <div>{formatMessage(MSG_observerLabel)}</div>
                <div className="text-muted">
                  {formatMessage(MSG_observerExplanation)}
                </div>
              </div>
            </label>
          </FormElement>
        </div>
      </div>

    </Modal>
  );
});


export { UserInvitationModal, IUserInvitationModal };
