import { Dayjs } from "dayjs";
import { Group } from "./api_groups_types";

export type ModelErrors = {
  errorType: 'models',
  errors: {[model: string]: {[attr: string]: string[]}}
}

export type ErrorMessage = {
  errorType: 'message';
  message: string;
}

export type ApiError = ModelErrors | ErrorMessage;

export enum FEATURE_LEVEL {
  FREE = 'free',
  ESSENTIAL = 'essential',
  COMPLETE = 'complete',
}

export enum STRIPE_STATUS {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

export enum PAYMENT_METHOD {
  CARD = 'card',
}

export enum BILLING_INTERVAL {
  LIFETIME = 'lifetime',
  MONTH = 'month',
  YEAR = 'year',
}

export enum CARD_BRAND {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  EFTPOS_AU = 'eftpos_au',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}

export enum INVITATION_STATUS {
  ACCEPTED = 'accepted',
  SENT = 'sent',
}

export enum ROLE {
  OWNER = 'owner',
  STEWARD = 'steward',
  OBSERVER = 'observer',
}

export type Price = {
  amount: number,
  currency: string,
}

export type Product = {
  productKey: string;
  featureLevel: FEATURE_LEVEL;
  prices: {[currency: string]: Price};
  interval: BILLING_INTERVAL;
  intervalCount: number;
  legacy: boolean;
};

export type User = {
  id: string;
  active: boolean;
  email: string;
  firstName: string;
  lastName: string;
  role: ROLE;
  invitationStatus: string;
  lastWebAccessAt?: string;
  lastMobileAccessAt?: string;
  profilePictureUrl?: string;
  apiKey?: string;
  uiScale: number;
  flags?: {
    getStartedVisible?: boolean
  },
  subscription?: Subscription;
}

export type Subscription = {
  id: string;
  featureLevel: FEATURE_LEVEL;
  productKey: string;
  scheduledProductKey: string | null;
  currency: string | null;
  stripePublishableKey: string,
  stripeCustomerId: string,
  stripeAccountBalance: number,
  stripeCanceledAt: string,
  stripeCancelAt: string,
  stripePeriodStartedAt: string,
  stripePeriodEndsAt: string,
  stripeStatus: STRIPE_STATUS,
  stripePaymentMethod: PaymentMethod | null,
  stripeLatestFailedPayment: StripePayment | null,
  dateFormat: string,
  timeFormat: string,
  numberFormat: string,
  currencyDecimals: number,
  users?: User[],
  group?: Group,
}

export type StripePayment = {
  amount: number,
  currency: string,
  error?: string,
  dueAt?: string,
  attemptedAt?: string,
  nextAttemptAt?: string,
  paymentMethod?: PaymentMethod | null,
}

export type PaymentMethod = {
  type: PAYMENT_METHOD;
  cardBrand?: CARD_BRAND;
  cardLast4?: string;
  cardExpMonth?: number;
  cardExpYear?: number;
}

export type Ledger = {
  id: string;
  name: string;
  amount: number,
  sequenceNumber: number;
  archived: boolean;
  locked: boolean;
  unallocatedCount?: number;
  accounts?: Account[];
  envelopes?: Envelope[];
  incomeSources?: IncomeSource[];
  incomeAllocations?: IncomeAllocation[];
  budgetedExpenses?: BudgetedExpense[];
}

export const BLANK_LEDGER: Ledger = {
  id: '',
  name: '',
  amount: 0,
  sequenceNumber: 0,
  archived: false,
  locked: false,
}

export type Account = {
  id: string;
  ledgerId: string;
  name: string;
  amount: number;
  sequenceNumber: number | null;
  archived: boolean;
  locked?: boolean;
  lastReconciledAt?: string | null;
  envelopes?: Envelope[];
  unallocatedCount?: number;
  pendingReconcileIds?: string[];
  pendingReconcileAmount?: number;
}

export const BLANK_ACCOUNT: Account = {
  id: '',
  ledgerId: '',
  name: '',
  amount: 0,
  sequenceNumber: null,
  archived: false,
}

export type Envelope = {
  id: string;
  ledgerId: string;
  icon?: string | null;
  name: string;
  color: string;
  amount: number;
  archived: boolean;
  appX: number;
  appY: number;
  mobileSequenceNumber: number;
  locked: boolean;
  createdAt: string;
  updatedAt: string;
}

export const BLANK_ENVELOPE: Partial<Envelope> = {
  name: '',
  color: '#eeeeee',
  amount: 0,
  archived: false,
  mobileSequenceNumber: 0,
  locked: false,
}

export type TransactionKind = 'income' | 'expense' | 'move' | 'transfer';

export type Transaction = {
  id?: string;
  ledgerId?: string;
  accountId: string;
  date: string,
  amount: number,
  description: string;
  notes: string;
  checkNumber?: number;
  allocated: boolean;
  reconciled: boolean;
  imported: boolean;
  kind: TransactionKind;
  createdAt: string;
  updatedAt: string;
  user?: User;
  account?: Account;
  allocations?: Allocation[];
  toAccountId?: string;
  toAllocations?: Allocation[];
};

export type Allocation = {
  envelopeId: string;
  amount: number;
  envelope?: Envelope;
};

export type IncomeSourceFrequency = 'weekly' | 'biweekly' | 'semimonthly' | 'monthly';
export type IncomeSource = {
  id: string;
  ledgerId: string;
  name: string;
  frequency: IncomeSourceFrequency;
  amount: number;
  createdAt: string;
  updatedAt: string;
};

export type BudgetedExpenseFrequency = 'weekly' | 'biweekly' | 'semimonthly' | 'monthly' | 'quarterly' | 'semiannually' | 'annually';
export type BudgetedExpense = {
  id: string;
  ledgerId: string;
  envelopeId: string;
  name: string;
  frequency: BudgetedExpenseFrequency;
  amount: number;
  createdAt: string;
  updatedAt: string;
};

export type IncomeAllocationType = 'fixed' | 'percent';
export type IncomeAllocation = {
  id: string;
  ledgerId: string;
  incomeSourceId: string;
  envelopeId: string;
  sequenceNumber: number;
  method: IncomeAllocationType;
  amount: number;
  createdAt?: string;
  updatedAt?: string;
};

export type ParsedImport = {
  accounts: ParsedImportAccount[]
}

export type ParsedImportAccount = {
  accountDescription: string | null;
  accountNumber: string | null;
  bankNumber: string | null;
  transactions: ParsedImportTransaction[];
}

export type ParsedImportTransaction = {
  amount: number;
  attrHash: string;
  checkNumber: number | null;
  date: Dayjs;
  description: string;
  notes: string;
  matches: ParsedImportMatch[]
  error?: string;
}

export type ParsedImportMatch = {
  id: string;
  amount: number;
  date: Dayjs;
  userId: string | null;
  description: string | null;
  notes: string | null;
  checkNumber: number | null;
  attrHash: string;
}

export type PlanChangePreview = {
  currency: string;
  currentInvoiceAmount: number;
  currentInvoiceBalanceChange: number;
  nextInvoiceAmount: number;
  nextInvoiceAt: string;
  downgradeAtNextBillingCycle: boolean;
};

export type EmojiSuggestion = {
  emoji: string;
  emojiName: string;
  explanation: string;
}

export type HelpArticle = {
  id?: string;
  title?: string;
  slug?: string;
  summary?: string;
  body?: string;
  searchSummary?: string;
  sequenceNumber?: number;
  helpGroupId?: string;
  helpGroup?: HelpGroup;
  createdAt?: string;
  updatedAt?: string;
}

export type HelpGroup = {
  id?: string;
  name?: string;
  slug?: string;
  icon?: string;
  description?: string;
  sequenceNumber?: number;
  totalHelpArticles?: number;
  createdAt?: string;
  updatedAt?: string;
}

export type RemovedModel = {
  removedClass: string;
  removedId: string;
}

export type SyncResponse = {
  ledgers: {[ledgerId: string]: Ledger};
  accounts: {[accountId: string]: Account};
  envelopes: {[envelopeId: string]: Envelope};
  budgetedExpenses: {[budgetedExpenseId: string]: BudgetedExpense};
  incomeSources: {[incomeSourceId: string]: IncomeSource};
  incomeAllocations: {[incomeAllocationId: string]: IncomeAllocation};
  transactions: {[transactionId: string]: Transaction};
  subscription: Subscription | null;
  users: {[userId: string]: User};
  removedModels: RemovedModel[];
};

export type BudgetVsActualDatum = {
  month: string,
  budget: number,
  actual: number,
  difference: number,
};

export type IncomeVsExpenseDatum = {
  month: string,
  income: number,
  expense: number,
  difference: number,
};
