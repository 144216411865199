import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import classNames from "classnames";

interface IProps {
  color: string;
  selected: boolean;
  onClick: (color: string) => any;
}

const ColorSample: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  return (
    <Container onClick={() => props.onClick(props.color)}
               className={classNames({selected: props.selected})}
               style={{backgroundColor: props.color}}/>
  );
};

const Container = styled.div`
  height: 3rem;
  width: 33.33333%;
  border: 1px solid #0003;
  border-left-width: 0;
  border-right-width: 0;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: ${({theme}) => theme.raisedContainer.borderRadius };;
    border-bottom-left-radius: ${({theme}) => theme.raisedContainer.borderRadius };;
    border-left-width: 1px;
  }

  &:last-child {
    border-top-right-radius: ${({theme}) => theme.raisedContainer.borderRadius };;
    border-bottom-right-radius: ${({theme}) => theme.raisedContainer.borderRadius };;
    border-right-width: 1px;
  }

  &.selected, &:hover {
    border-width: 2px;
    border-color: #0006;
  }
`;
export {ColorSample};
