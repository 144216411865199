import * as React from "react";
import { RefObject } from "react";
import { ITextInputProps, TextInput } from "./TextInput";
import { omit } from "lodash";
import { useIntlFormatters } from "shared/utils/formatters";
import styled, { useTheme } from "styled-components";
import { selectCurrentSubscription } from "shared/state/store";
import { useSelector } from "react-redux";

interface INumberInput {
  forceValue: (val: number) => any;
  forceNegativeDefault: () => any;
  select: (options?: {ignoreFocus?: boolean}) => any;
  focus: () => any;
  inputRef: RefObject<HTMLInputElement>;
}

interface INumberInputProps extends Omit<ITextInputProps, 'onChange' | 'value'> {
  value: number | null;
  onChange: (value: number) => any;
  blankZeros?: boolean;
  blankZerosOnFocus?: boolean;
  allowNegative?: boolean;
  precision?: number;
  totalValue?: number;
  hideSeparators?: boolean;
  onPercentTyped?: () => any;
}

const NumberInput = React.forwardRef<INumberInput, INumberInputProps>((props, ref) => {
  const [valueStr, setValueStr] = React.useState<string>('');
  const {parseNumber, formatNumber} = useIntlFormatters();
  const subscription = useSelector(selectCurrentSubscription)!;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const theme = useTheme();

  React.useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setValueStrOrBlank(props.value);
    }
  }, [props.value]);

  React.useImperativeHandle<any, INumberInput>(ref, () => ({
    inputRef,
    forceValue: (val: number) => {
      setValueStrOrBlank(val);
      props.onChange(val);
      setTimeout(() => inputRef.current?.select());
    },
    forceNegativeDefault: () => {
      setValueStr("-");
      props.onChange(0);
    },
    focus: () => {
      inputRef.current?.focus();
    },
    select: (options: {ignoreFocus?: boolean} = {}) => {
      if (options.ignoreFocus || (inputRef.current?.selectionStart && inputRef.current?.selectionStart >= inputRef.current?.value.length)) {
        // select everything except the minus sign at the beginning of the string, if it exists
        if (inputRef.current?.value[0] === '-') {
          inputRef.current?.setSelectionRange(1, inputRef.current?.value.length);
        } else {
          inputRef.current?.select();
        }
      }
    }
  }));

  function setValueStrOrBlank(parsedValue: number | null) {
    let options: any = {hideSeparators: props.hideSeparators};
    if (props.precision) options = {precision: props.precision};
    if (props.blankZeros) {
      setValueStr(parsedValue ? formatNumber(parsedValue || 0, subscription, options) : '');
    } else {
      setValueStr(formatNumber(parsedValue || 0, subscription, options));
    }
  }

  return (
    <NumberInputStyled
      {...omit(props, ['onChange', 'value', 'onFocus'])}
      style={{...props.style, color: (parseNumber(valueStr) < 0 || valueStr[0] === '-') ? theme.colors.negativeEnvelopeTextColor : undefined}}
      ref={inputRef}
      className={props.className}
      value={valueStr}
      onFocus={e => {
        if (props.blankZerosOnFocus && parseNumber(valueStr) === 0) {
          setValueStr('');
        }
        if (props.onFocus) props.onFocus(e);
      }}
      onChange={e => {
        let value = e.target.value;
        let percentTyped = false;
        if (props.onPercentTyped && value[value.length - 1] === '%') {
          percentTyped = true;
          value = value.slice(0, value.length - 1);
        }
        setValueStr(value);
        const parsedValue = parseNumber(value, {totalValue: props.totalValue});
        props.onChange(parsedValue);
        if (percentTyped && props.onPercentTyped) props.onPercentTyped();
      }}
      onBlur={e => {
        const parsedValue = parseNumber(e.target.value, {totalValue: props.totalValue});
        setValueStrOrBlank(parsedValue);
        props.onChange(parsedValue);
        if (props.onBlur) props.onBlur(e);
      }}/>
  );
});

const NumberInputStyled = styled(TextInput)`
`;

export {NumberInput, INumberInputProps, INumberInput};
