import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectUsers = createSelector(
  (state: RootState) => state.data.usersHash,
  (hash) => {
    return Object.values(hash);
  }
);

export const selectUser = (userId: string) => createSelector(
  (state: RootState) => state.data.usersHash,
  (hash) => {
    return hash[userId];
  }
);

export const selectOtherUsers = (userId: string) => createSelector(
  (state: RootState) => state.data.usersHash,
  (hash) => {
    return Object.values(hash).filter(user => user.id !== userId);
  }
);
