import * as React from "react";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { useCreateSubscriptionMutation } from "shared/state/endpoints/app/subscriptions_api";
import { useIntlFormatters } from "shared/utils/formatters";
import { ApiError } from "shared/utils/api_types";
import { useLogin } from "shared/utils/hooks";
import { parseApiError } from "shared/utils/api_errors";
import {
  MSG_firstNameLabel,
  MSG_lastNameLabel,
  MSG_noCreditCardRequired,
  MSG_signUpForFreeTitle,
  MSG_signUpTitle
} from "shared/strings/signup";
import { Form } from "@web/components/forms/Form";
import { FormErrors } from "@web/components/forms/FormErrors";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import { MSG_emailFormLabel, MSG_passwordFormLabel } from "shared/strings/login";
import { MSG_usedForLoginNotice } from "shared/strings/users";
import { Button } from "@web/components/Button";
import styled, { useTheme } from "styled-components";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faLock } from "@fortawesome/pro-light-svg-icons/faLock";
import { faUserPlus } from "@fortawesome/pro-light-svg-icons/faUserPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {}

const SignupPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const theme = useTheme();
  const [signup]: any = useCreateSubscriptionMutation();
  const {formatMessage} = useIntlFormatters();
  const [apiError, setApiError] = React.useState<ApiError | undefined>(undefined);
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [postCode, setPostCode] = React.useState<string>(''); // Honeypot
  const [working, setWorking] = React.useState<boolean>(false);
  const [captchaPassed, setCaptchaPassed] = React.useState<boolean>(false);
  const login = useLogin();
  const navigate = useNavigate();

  React.useEffect(() => {
    try { window.sa_pageview(); } catch (e) { console.log(e); }
  }, []);

  async function handleSubmit() {
    try { window.sa_event("signup"); } catch (e) { console.log(e); }
    setWorking(true);
    try {
      const {user} = await signup({email, password, firstName, lastName, postCode}).unwrap();
      await login(user.apiKey);
      navigate('/app');
    } catch (e) {
      setApiError(parseApiError(e));
    }
    setWorking(false);
  }

  return (
    <SignupContainer>
      <div className="text-center p-4">
        <FontAwesomeIconStyled icon={faUserPlus}/>
        <HeadingStyled>{formatMessage(MSG_signUpTitle)}</HeadingStyled>
        <p>{formatMessage(MSG_noCreditCardRequired)}</p>
      </div>

      <Form onSubmit={handleSubmit}>
        {apiError && <FormErrors errors={apiError} className="mb-4"/>}

        <FormElement label={formatMessage(MSG_firstNameLabel)} errorPath="user.first_name" errors={apiError}>
          <TextInput
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            disabled={working}
            autoFocus
            tabIndex={1}
          />
        </FormElement>

        <FormElement label={formatMessage(MSG_lastNameLabel)} errorPath="user.last_name" errors={apiError}>
          <TextInput
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            disabled={working}
            tabIndex={2}
          />
        </FormElement>

        <FormElement label={formatMessage(MSG_emailFormLabel)}
                     note={formatMessage(MSG_usedForLoginNotice)}
                     errorPath="user.email" errors={apiError}>
          <TextInput
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={working}
            leftElement={<FontAwesomeIcon icon={faEnvelope}/>}
            tabIndex={3}
          />
        </FormElement>

        <FormElement label={formatMessage(MSG_passwordFormLabel)} errorPath="user.password" errors={apiError}>
          <TextInput
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={working}
            leftElement={<FontAwesomeIcon icon={faLock}/>}
            tabIndex={4}
          />
        </FormElement>

        {/* Honeypot */}
        <PostCodeContainer>
          <FormElement label={'Post Code'} errorPath="user.post_code" errors={apiError}>
            <TextInput
              type="text"
              value={postCode}
              onChange={e => setPostCode(e.target.value)}
              disabled={working}
              tabIndex={5}
            />
          </FormElement>
        </PostCodeContainer>

        <div className="mb-6 flex-row align-items-center justify-content-center">
          <ReCAPTCHA
            sitekey="6LeEvYcpAAAAAG8Mov5ENUtOrCEQeMcat6iJd71o"
            onChange={() => {
              setCaptchaPassed(true);
            }}
          />
        </div>

        <div className="flex-row align-items-center justify-content-center">
          <Button tabIndex={6} spinner={working} disabled={working || !captchaPassed}>
            {formatMessage(MSG_signUpForFreeTitle)}
          </Button>
        </div>
      </Form>

    </SignupContainer>
  );
};

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeadingStyled = styled.h1`
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const SignupContainer = styled.div`
  width: 30rem;
`;

const PostCodeContainer = styled.div`
  display: none;
`;

export { SignupPage };
