import { faCloudArrowUp } from "@fortawesome/pro-light-svg-icons/faCloudArrowUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { PropsWithChildren } from "react";
import { MSG_fileTooLarge } from "shared/strings/import";
import { useIntlFormatters } from "shared/utils/formatters";
import { StyledDropbox } from "@web/components/styled/StyledDropbox";
import { MSG_dragYourPhotoHere } from "shared/strings/users";
import { MSG_or, MSG_selectFileButton } from "shared/strings/generic";
import { Button } from "@web/components/Button";
import { useAlert } from "@web/utils/hooks";

interface IProps {
  onUpload: (file: File) => any;
}

const ProfilePictureUploader: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const [dragActive, setDragActive] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {formatMessage} = useIntlFormatters();
  const {showAlert} = useAlert();

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = function (file: File) {
    if (file.size > 10*1024*1024) {
      showAlert(formatMessage(MSG_fileTooLarge));
      return;
    }
    props.onUpload(file);
  };

  return (
    <div className="text-center">
      <form id="form-file-upload"
            onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop}
            onSubmit={(e) => e.preventDefault()}>
        <input type="file" id="input-file-upload" multiple={false}
               style={{display: 'none'}}
               accept=".jpg,.jpeg,.png"
               onChange={handleChange}
               ref={inputRef}/>
        <StyledDropbox className="text-center"
                       onClick={() => inputRef.current?.click()}
                       style={{
                         height: '12rem',
                         padding: '1rem',
                         fontSize: '100%',
                         backgroundColor: dragActive ? '#efefef' : 'inherit'
                       }}>
          <FontAwesomeIcon icon={faCloudArrowUp} style={{fontSize: '200%'}} className="mb-3"/>
          <div>{formatMessage(MSG_dragYourPhotoHere)}</div>
        </StyledDropbox>
        <div className="mt-2 mb-2 text-muted">{formatMessage(MSG_or)}</div>
        <Button color="secondary" onClick={() => inputRef.current?.click()}>
          {formatMessage(MSG_selectFileButton)}
        </Button>

      </form>
    </div>
  );
};

export { ProfilePictureUploader };
