import * as React from "react";
import { PropsWithChildren } from "react";
import { useRouteError } from "react-router-dom";

interface IProps {}

const GenericError: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div>
      <h1>Oops!</h1>
      <p>An unexpected error has occurred!</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export { GenericError };
