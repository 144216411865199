import * as React from "react";
import { Modal } from "@web/components/Modal";
import styled from "styled-components";
import { ApiError } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { parseApiError } from "shared/utils/api_errors";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { useUpdateTransactionMutation } from "shared/state/endpoints/app/transactions_api";
import { MSG_notesLabel, MSG_receivedFromLabel, MSG_sentToLabel } from "shared/strings/transactions";
import { TransactionModalContext } from "shared/utils/transaction_modal_context";

type PartialTransaction = {id?: string, notes: string, description: string, amount: number};
interface IProps {
  ledgerId: string;
}

interface ITransactionTextEditorModal  {
  show: (transaction: PartialTransaction) => any;
  hide: () => any;
}

const TransactionTextEditorModal = React.forwardRef<ITransactionTextEditorModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<PartialTransaction>({id: '', notes: '', description: '', amount: 0});
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [updateTransaction] = useUpdateTransactionMutation();
  const {formatMessage} = useIntlFormatters();
  const transactionModalContext = React.useContext(TransactionModalContext);

  React.useImperativeHandle<any, ITransactionTextEditorModal>(ref, () => ({
    show: (localTransaction: PartialTransaction) => {
      setTransaction(localTransaction);
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    try {
      setSaving(true);
      await updateTransaction({
        ledgerId: props.ledgerId,
        transaction: {
          id: transaction.id,
          description: transaction.description,
          notes: transaction.notes
        }
      }).unwrap();
      await transactionModalContext.reloadParent();
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  if (!transaction) return null;

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      defaultFormAction={save}
      bodyStyle={{padding: '1rem'}}
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={saving}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
        </div>
      }>

      {errors?.errorType === 'message' && (
        <div className="d-flex flex-row">
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            <div>{errors.message}</div>
          </ErrorMessage>
        </div>
      )}

      <FormElement errors={errors} errorPath="transaction.description" className="flex-grow-1"
                   label={formatMessage(transaction.amount < 0 ? MSG_sentToLabel : MSG_receivedFromLabel)}>
        <TextInput
          name="description"
          autoFocus
          style={{width: '100%'}}
          value={transaction.description || ''}
          onChange={e => setTransaction({...transaction, description: e.target.value})}/>
      </FormElement>

      <FormElement errors={errors} errorPath="transaction.notes" className="flex-grow-1"
                   label={formatMessage(MSG_notesLabel)}>
        <TextInput
          rows={5}
          name="notes"
          style={{width: '100%'}}
          value={transaction.notes || ''}
          onChange={e => setTransaction({...transaction, notes: e.target.value})}/>
      </FormElement>

    </Modal>
  );
});

const ErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  color: ${({theme}) => theme.colors.danger };
  display: flex;
  flex-direction: row;
  align-items: center;
  div { margin-left: 0.5rem; }
`;

export {TransactionTextEditorModal, ITransactionTextEditorModal};
