import * as React from "react";
import { PropsWithChildren, RefObject } from "react";
import { usePopper } from "react-popper";
import * as PopperJS from "@popperjs/core";
import styled from "styled-components";

interface IProps extends PropsWithChildren {
  targetRef: RefObject<HTMLElement>,
  placement?: PopperJS.Placement
  disableDismissOnOutsideClick?: boolean;
  onCancel?: () => any;
  maxHeight?: string;
}

interface IPopover {
  show: () => any;
  hide: () => any;
}

let id = 1000;

const Popover = React.forwardRef<IPopover, IProps>((props: IProps, ref) => {
  const idRef = React.useRef<number>(id++);
  const [visible, setVisible] = React.useState<boolean>(false);
  const popperRef = React.useRef<any>(null);
  const [arrowEl, setArrowEl] = React.useState<any>(null);
  const {styles, attributes} = usePopper(props.targetRef.current, popperRef.current, {
    placement: props.placement || 'bottom-start',
    modifiers: [
      {name: 'offset', options: {offset: [0, 8]}},
      {name: 'arrow', options: {element: arrowEl}}
    ],
  });

  React.useEffect(() => {
    if (visible) {
      const detectClick = (e: any) => {
        if (!visible) return;
        if (props.disableDismissOnOutsideClick) return;
        if (!document.getElementById(`popover-${idRef.current}`)?.contains(e.target) && !props.targetRef.current?.contains(e.target)) {
          if (props.onCancel) props.onCancel();
          setVisible(false);
        }
      };
      document.addEventListener("click", detectClick);
      return () => document.removeEventListener("click", detectClick);
    }
  }, [visible, popperRef.current, props.targetRef.current, props.disableDismissOnOutsideClick]);

  React.useImperativeHandle<any, IPopover>(ref, () => ({
    show: () => setVisible(true),
    hide: () => setVisible(false),
  }));

  return (
    <PopoverContainer
      id={`popover-${idRef.current}`}
      ref={popperRef}
      style={{
        ...styles.popper,
        visibility: visible ? 'visible' : 'hidden',
        maxHeight: props.maxHeight || 'auto',
      }}
      {...attributes.popper}>
      {props.children}
      {visible && <div ref={setArrowEl} className="arrow" style={styles.arrow} />}
    </PopoverContainer>
  );
});

const PopoverContainer = styled.div`
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: ${({theme}) => theme.window.borderRadius};
  border-color: ${({theme}) => theme.colors.lightBorder};
  background-color: ${({theme}) => theme.inputContainer.backgroundColor};
  color: ${({theme}) => theme.colors.textColor };
  box-shadow: 0 0.75rem 2rem rgba(0,0,0,0.65);
  overflow-y: scroll;
  z-index: 2000;

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    background-color: #fff;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -4px;
  }
`;

export {Popover, IPopover};
