import * as React from "react";
import { PropsWithChildren } from "react";
import { StyledCardBody, StyledCardIcon } from "@web/components/styled/StyledCard";
import { ROLE, Subscription, User } from "shared/utils/api_types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {
  MSG_billingErrorAttemptedAtLabel,
  MSG_billingErrorAttemptedChargeLabel,
  MSG_billingErrorNextAttemptAtLabel,
  MSG_billingErrorRetryPayment,
  MSG_billingUnexpectedError
} from "shared/strings/billing";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_unexpectedError, MSG_unknownLabel } from "shared/strings/generic";
import { useRetryPaymentMutation } from "shared/state/endpoints/app/subscriptions_api";
import { selectCurrentUser, setCurrentSubscription } from "shared/state/store";
import { parseApiError } from "shared/utils/api_errors";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@web/components/Button";
import { useAlert } from "@web/utils/hooks";

interface IProps {
  subscription: Subscription;
}

const SubscriptionProblemsCardBody: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const { subscription } = props;
  const { formatMessage, formatDate, formatCurrency, formatTime } = useIntlFormatters();
  const [retryPaymentMutation] = useRetryPaymentMutation();
  const [retrying, setRetrying] = React.useState<boolean>(false);
  const {showErrorAlert} = useAlert();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser) as User;
  const editable = currentUser?.role === ROLE.OWNER;

  async function retryPayment() {
    if (!subscription) return;
    setRetrying(true);
    try {
      let newSubscription = await retryPaymentMutation({}).unwrap();
      dispatch(setCurrentSubscription(newSubscription));
    } catch (e) {
      let error = parseApiError(e);
      showErrorAlert(error.errorType === 'message' ? error.message : formatMessage(MSG_unexpectedError));
    }
    setRetrying(false);
  }

  if (subscription.stripeLatestFailedPayment) {
    return (
      <StyledCardBody>
        <div className="flex-row align-items-center justify-content-stretch">
          <StyledCardIcon>
            <FontAwesomeIcon icon={faExclamationTriangle} color={'#fff'}/>
          </StyledCardIcon>
          <div className="flex-grow-1 text-medium">
            <strong>{subscription.stripeLatestFailedPayment.error}</strong>
          </div>
        </div>
        <div className="flex-row align-items-center justify-content-stretch">
          <StyledCardIcon/>
          <div className="flex-grow-1">
            <table>
              <tbody>
              <tr>
                <td className="text-right pe-2">
                  {formatMessage(MSG_billingErrorAttemptedChargeLabel)}
                </td>
                <td>{
                  subscription.stripeLatestFailedPayment.amount
                    ? formatCurrency(subscription.stripeLatestFailedPayment.amount, subscription, {showSymbol: true})
                    : formatMessage(MSG_unknownLabel)
                }</td>
              </tr>
              <tr>
                <td className="text-right pe-2">
                  {formatMessage(MSG_billingErrorAttemptedAtLabel)}
                </td>
                <td>{
                  subscription.stripeLatestFailedPayment.attemptedAt
                    ? formatDate(subscription.stripeLatestFailedPayment.attemptedAt, subscription) + ' ' + formatTime(subscription.stripeLatestFailedPayment.attemptedAt, subscription)
                    : formatMessage(MSG_unknownLabel)
                }</td>
              </tr>
              <tr>
                <td className="text-right pe-2">
                  {formatMessage(MSG_billingErrorNextAttemptAtLabel)}
                </td>
                <td>{
                  subscription.stripeLatestFailedPayment.nextAttemptAt
                    ? formatDate(subscription.stripeLatestFailedPayment.nextAttemptAt, subscription)
                    : formatMessage(MSG_unknownLabel)
                }</td>
              </tr>
              {editable && (
                <tr>
                  <td/>
                  <td className="pt-2">
                    <Button color="secondary" onClick={() => void retryPayment()} disabled={retrying} spinner={retrying}>
                      {formatMessage(MSG_billingErrorRetryPayment)}
                    </Button>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </StyledCardBody>
    );
  } else {
    return (
      <StyledCardBody>
        <div className="flex-row align-items-center justify-content-stretch">
          <StyledCardIcon>
            <FontAwesomeIcon icon={faExclamationTriangle} color={'#fff'}/>
          </StyledCardIcon>
          <div className="flex-grow-1 text-medium">
            <strong>{formatMessage(MSG_billingUnexpectedError)}</strong>
          </div>
        </div>
      </StyledCardBody>
    );
  }
};

export { SubscriptionProblemsCardBody };
