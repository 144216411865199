import * as React from "react";
import { PropsWithChildren } from "react";
import { AppFooter } from "@web/components/shared/AppFooter";

interface IProps {}

const AuthenticatedFooter: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  return (
    <React.Fragment>
      <div/>
      <AppFooter/>
    </React.Fragment>
  );
};

export {AuthenticatedFooter};
