import { ApiError, User } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { parseApiError } from "shared/utils/api_errors";
import { Modal } from "@web/components/Modal";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import React from "react";
import { FormErrors } from "@web/components/forms/FormErrors";
import {
  MSG_changePasswordButton,
  MSG_currentPasswordLabel,
  MSG_newPasswordConfirmationLabel,
  MSG_newPasswordLabel
} from "shared/strings/users";
import { useUpdateUserMutation } from "shared/state/endpoints/app/users_api";
import { faShieldCheck } from "@fortawesome/pro-light-svg-icons/faShieldCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
}

interface IChangePasswordModal  {
  show: (user: User) => any;
  hide: () => any;
}

const ChangePasswordModal = React.forwardRef<IChangePasswordModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [id, setId] = React.useState<string | null>(null);
  const [currentPassword, setCurrentPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [updateUser] = useUpdateUserMutation();
  const {formatMessage} = useIntlFormatters();

  React.useImperativeHandle<any, IChangePasswordModal>(ref, () => ({
    show: (_user: User) => {
      setId(_user.id);
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    if (!currentPassword && !newPassword && !confirmPassword) {
      return;
    };

    try {
      setSaving(true);
      await updateUser({user: {
        id: id as string,
        currentPassword: currentPassword,
        password: newPassword,
        passwordConfirmation: confirmPassword
      }}).unwrap();
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  return (
    <Modal
      isOpen={visible}
      defaultFormAction={save}
      onRequestClose={() => {
        setVisible(false);
      }}
      windowStyle={{width: '25rem'}}
      bodyStyle={{padding: '2rem'}}
      footer={
        <div className="text-right row-reverse">
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
          <Button color="secondary" disabled={saving}
                  onClick={() => setVisible(false)}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
        </div>
      }>

      <div className="text-center mb-6">
        <FontAwesomeIcon icon={faShieldCheck} style={{fontSize: '3rem'}}/>
        <h2>{formatMessage(MSG_changePasswordButton)}</h2>
      </div>

      <FormErrors errors={errors}/>

      <FormElement errors={errors} errorPath="user.current_password"
                   label={formatMessage(MSG_currentPasswordLabel)}>
        <TextInput
          type="password"
          name="current_password"
          autoFocus
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}/>
      </FormElement>

      <FormElement errors={errors} errorPath="user.password"
                   label={formatMessage(MSG_newPasswordLabel)}>
        <TextInput
          type="password"
          name="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}/>
      </FormElement>

      <FormElement errors={errors} errorPath="user.password_confirmation"
                   label={formatMessage(MSG_newPasswordConfirmationLabel)}>
        <TextInput
          type="password"
          name="password_confirmation"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}/>
      </FormElement>

    </Modal>
  );
});


export { ChangePasswordModal, IChangePasswordModal };
