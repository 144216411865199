import styled from "styled-components";

export const StyledRaisedContainer = styled.div`
  border-radius: ${({theme}) => theme.raisedContainer.borderRadius};
  background: #dfdfdf;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 50%), 0 1px 3px #0006;
`;

export const StyledClickableRaisedContainer = styled(StyledRaisedContainer)`
  cursor: pointer;
  position: relative;

  &:active:not(:disabled) {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    .label {
      transform: translateY(1px);
    }
  }

  &:focus:after:not(:disabled) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    outline: 1px dotted #fff8;
    outline-offset: -0.35rem;
  }

  &:active:focus:after:not(:disabled) {
    top: 1px;
    bottom: -1px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
