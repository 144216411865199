import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { ApiError, ErrorMessage, ModelErrors } from "shared/utils/api_types";

interface IProps {
  errors?: ApiError | string | string[];
  className?: string;
}

const FormErrors: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  if (!props.errors) return null;

  let errors: string[] = [];
  // display the generic error messages
  if ((props.errors as ErrorMessage)?.errorType === 'message') {
    errors = [(props.errors as ErrorMessage).message];

  // display the base errors
  } else if ((props.errors as ModelErrors)?.errorType === 'models') {
    errors = Object.values((props.errors as ModelErrors).errors).map((err) => err.base).flat();

  // handle the string errors
  } else {
    if (Array.isArray(props.errors)) {
      errors = props.errors;
    } else if (typeof props.errors === 'string') {
      errors.push(props.errors);
    }
  }

  return (
    <div className={props.className}>
      {errors.map((err, i) => <Error key={i}>{err}</Error>)}
    </div>
  );
};

const Error = styled.div`
  color: ${({theme}) => theme.colors.errorTextColor };
  margin-bottom: 1rem;
`;

export {FormErrors};
