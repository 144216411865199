import * as React from "react";
import { PropsWithChildren } from "react";
import { EnvelopeGrid } from "./EnvelopeGrid";
import { EnvelopeBox } from "./EnvelopeBox";
import { useExampleEnvelopes } from "@web/utils/hooks";
import styled from "styled-components";
import arrowImg from "shared/images/drawn-arrow.png";
import cashEnvelopeImg from "shared/images/cash-envelope.jpg";
import { MSG_emptyAccountMessage, MSG_emptyAccountTitle } from "shared/strings/accounts";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_emptyEnvelopeListOnBudgetScreen, MSG_youNeedSomeEnvelopes } from "shared/strings/budget";
import { MSG_jumpToEnvelopesButton } from "shared/strings/envelopes";
import { Button } from "@web/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { StyledRaisedContainer } from "@web/components/styled/StyledRaisedContainer";
import { ROLE, User } from "shared/utils/api_types";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "shared/state/store";

interface IProps {
  screen: "budget" | "envelopes" | "transactionModal";
  absolute?: boolean;
  onNavigate?: () => any;
}

const EmptyEnvelopeListExplanation: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const envelopes = useExampleEnvelopes();
  const {formatMessage} = useIntlFormatters();
  const ledgerId = useParams<{ ledgerId: string }>().ledgerId as string;
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as User;

  return (
    <OuterContainer absolute={props.absolute}>
      <InnerContainer>
        <EnvelopeGrid
          ledgerId={ledgerId}
          overrideEnvelopes={envelopes}
          renderEnvelope={env => (
            <EnvelopeBox envelope={env}/>
          )}/>

        <Blocker/>
        {props.screen === 'envelopes' && currentUser.role !== ROLE.OBSERVER && <ArrowImg src={arrowImg}/>}

        {props.screen === 'envelopes' && (
          <MessageContainer bottomOriented>
            <MessageContent bottomPadding>
              <div>
                <h3>{formatMessage(MSG_emptyAccountTitle)}</h3>
                <p>{formatMessage(MSG_emptyAccountMessage)}</p>
              </div>
              <CashEnvelopeImg src={cashEnvelopeImg}/>
            </MessageContent>
          </MessageContainer>
        )}
        {(props.screen === 'budget' || props.screen === 'transactionModal') && (
          <MessageContainer>
            <MessageContent>
              <div>
                <h3>{formatMessage(MSG_youNeedSomeEnvelopes)}</h3>
                <p>{formatMessage(MSG_emptyEnvelopeListOnBudgetScreen)}</p>
                <div className="mt-4 text-center">
                  <Button onClick={() => {
                    props.onNavigate && props.onNavigate();
                    navigate(`/app/ledgers/${ledgerId}/envelopes`);
                  }}>
                    {formatMessage(MSG_jumpToEnvelopesButton)}
                  </Button>
                </div>
              </div>
            </MessageContent>
          </MessageContainer>
        )}

      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<{ absolute?: boolean }>`
  position: ${({absolute}) => absolute ? 'absolute' : 'initial'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: ${({absolute}) => absolute ? '0' : '-1rem'};
`;

const InnerContainer = styled.div`
  position: relative;
  padding: 1rem;
`;

const Blocker = styled.div`
  background-color: #fff;
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MessageContainer = styled(StyledRaisedContainer)<{ bottomOriented?: boolean }>`
  position: absolute;
  bottom: ${props => (props.bottomOriented ? '2rem' : 'auto')};
  top: ${props => (props.bottomOriented ? 'auto' : '5rem')};
  left: 16rem;
  width: 50%;
  background: #fff;
  transform: rotate(-2deg);
  box-shadow: 0 5px 15px #0006;
  margin-bottom: 1rem;

  h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const MessageContent = styled.div<{ bottomPadding?: boolean }>`
  position: relative;
  overflow: hidden;
  padding: 1.5rem 2rem ${props => (props.bottomPadding ? '8rem' : '1.5rem')};
`;

const ArrowImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 9rem;
  width: 7rem;
  transform: scaleX(-1) rotate(-20deg);
`;

const CashEnvelopeImg = styled.img`
  position: absolute;
  width: 60%;
  left: 20%;
  right: 20%;
  bottom: -5rem;
`;

export {EmptyEnvelopeListExplanation};
