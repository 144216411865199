import dayjs from "dayjs";
import { remove } from "lodash";
import * as React from "react";

interface ITransactionModalContext {
  registerRefreshParent: (fn: () => Promise<any>) => any;
  unregisterRefreshParent: (fn: () => Promise<any>) => any;
  reloadParent: () => Promise<any>;
  defaultDate: string;
  setDefaultDate: (date: string) => any;
}

const transactionModalRegisteredFns: (() => Promise<any>)[] = [];

const transactionModalContextValue: ITransactionModalContext = {
  registerRefreshParent: (fn) => {
    transactionModalRegisteredFns.push(fn);
  },
  unregisterRefreshParent: (fn) => {
    remove(transactionModalRegisteredFns, (item) => item === fn);
  },
  reloadParent: async () => {
    await Promise.all(transactionModalRegisteredFns.map(fn => fn()));
  },
  defaultDate: dayjs().format("YYYY-MM-DD"),
  setDefaultDate: (date) => {
    transactionModalContextValue.defaultDate = date;
  }
};

const TransactionModalContext = React.createContext<ITransactionModalContext>(transactionModalContextValue);

export {TransactionModalContext, ITransactionModalContext, transactionModalContextValue};
