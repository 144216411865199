import * as React from "react";
import { PropsWithChildren } from "react";
import { CurrencyInput } from "@web/components/inputs/CurrencyInput";
import { SelectInput } from "@web/components/inputs/SelectInput";
import { IncomeAllocation, IncomeAllocationType, IncomeSource } from "shared/utils/api_types";
import { INumberInput } from "@web/components/inputs/NumberInput";
import { useTheme } from "styled-components";
import { frequencyHasExtra, getNumChecks } from "shared/utils/helpers";
import classnames from "classnames";
import { useIntlFormatters } from "shared/utils/formatters";
import { selectCurrentSubscription } from "shared/state/slices/data_selectors/core_data_selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { MSG_amountRemaining } from "shared/strings/budget";
import { selectIncomeAllocationsForLedger } from "shared/state/slices/data_selectors/income_allocation_data_selectors";

interface IProps {
  incomeSource: IncomeSource;
  incomeAllocations: Partial<IncomeAllocation>[];
  index: number;
  onChange: (allocation: Partial<IncomeAllocation>) => any;
  envelopeId: string;
  disabled?: boolean;
}

const FundingInput: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatCurrency, formatMessage} = useIntlFormatters();
  const subscription = useSelector(selectCurrentSubscription)!;
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const allocation = props.incomeAllocations.find((a) => a?.sequenceNumber === props.index);
  const currencyInputRef = React.useRef<INumberInput>(null);
  const numChecks = getNumChecks(props.incomeSource.frequency);
  const hasExtra = frequencyHasExtra(props.incomeSource.frequency);
  const theme = useTheme();
  const isExtra = hasExtra && props.index === numChecks - 1;
  const allIncomeAllocations = useSelector(selectIncomeAllocationsForLedger(ledgerId));

  let remaining = props.incomeSource.amount;
  let hadThisAllocation = false;
  allIncomeAllocations
    .filter((a) => a.incomeSourceId === props.incomeSource.id)
    .forEach((a) => {
      if (a.sequenceNumber === props.index) {
        if (a.envelopeId === props.envelopeId) {
          let amount = allocation?.method === 'percent' ? Math.floor((props.incomeSource.amount || 0) * ((allocation?.amount || 0) / 10000)) : (allocation?.amount || 0);
          remaining -= amount || 0;
          hadThisAllocation = true;
        } else {
          let amount = a.method === 'percent' ? Math.floor((props.incomeSource.amount || 0) * ((a.amount || 0) / 10000)) : a.amount;
          remaining -= amount || 0;
        }
      }
    });
  if (!hadThisAllocation && allocation) {
    let amount = allocation.method === 'percent' ? Math.floor((props.incomeSource.amount || 0) * ((allocation.amount || 0) / 10000)) : allocation.amount;
    remaining -= amount || 0;
  }

  return (
    <td className={classnames("text-right ps-4")}>
      <CurrencyInput
        ref={currencyInputRef}
        value={allocation?.amount || 0}
        disabled={props.disabled}
        style={{
          backgroundColor: isExtra ? theme.inputContainer.highlightedBackgroundColor : 'transparent',
          minWidth: '10rem', maxWidth: '15rem'
        }}
        onFocus={(e) => {
          setTimeout(() => {
            currencyInputRef.current?.select({ignoreFocus: true});
          });
        }}
        onChange={(val) => {
          props.onChange({
            incomeSourceId: props.incomeSource.id,
            envelopeId: props.envelopeId,
            sequenceNumber: props.index,
            method: allocation?.method || 'fixed',
            amount: val
          });
        }}
        onPercentTyped={() => {
          if (!allocation) return;
          props.onChange({...allocation, method: 'percent'});
        }}
        precision={allocation?.method === 'percent' ? 0 : undefined}
        leftElementPadding="4.5rem"
        leftElement={(
          <SelectInput
            tabIndex={-1}
            style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
            value={allocation?.method || 'fixed'}
            disabled={props.disabled}
            onChange={(e) => {
              let amount = allocation?.amount || 0;
              props.onChange({
                incomeSourceId: props.incomeSource.id,
                envelopeId: props.envelopeId,
                sequenceNumber: props.index,
                method: e.target.value as IncomeAllocationType,
                amount: amount
              });
              setTimeout(() => {
                currencyInputRef.current?.inputRef?.current?.focus();
                currencyInputRef.current?.inputRef?.current?.blur();
                currencyInputRef.current?.inputRef?.current?.select();
                currencyInputRef.current?.inputRef?.current?.focus();
              });
            }}
          >
            <option value="fixed">$</option>
            <option value="percent">%</option>
          </SelectInput>
        )}
        rightElement={allocation?.method === 'percent'
          ? <div className="text-muted text-small text-nowrap">%</div>
          : undefined}
      />
      <div className={classnames('text-center', {'text-negative': remaining < 0})}>
        {formatMessage(MSG_amountRemaining, {amount: formatCurrency(remaining, subscription)})}
      </div>
    </td>
  );};

export {FundingInput};
