export const MSG_reportsTitle = {
  id: "ddd96d62-8399-4ce2-ad83-2a09d86b786b",
  description: "The section where they can view reports about their budget.",
  defaultMessage: "Reports"
};

export const MSG_budgetVsActualTitle = {
  id: "a9f62a3c-9d77-4a9f-9ad6-b92a5b0e0368",
  description: "The name of the budget vs actual report.  This is a report that shows how much money you have budgeted for a category vs how much you have spent in that category.",
  defaultMessage: "Budget vs. Actual"
};

export const MSG_incomeVsExpenseTitle = {
  id: "24f06cd6-c7d0-4a18-adb8-c9d434c88898",
  description: "The name of the income vs expense report.  This is a report that shows how much money you have earned vs how much you have spent.",
  defaultMessage: "Income vs. Expense"
};

export const MSG_budgetedExpensesHeader = {
  id: "d5c1d527-119a-4252-8578-eb68e3ce0a97",
  description: "The header for the budgeted expenses section of the budget vs actual report.  This is the section that shows how much money you have budgeted for a category.",
  defaultMessage: "Budgeted Expenses"
};

export const MSG_actualExpensesHeader = {
  id: "7180d2dc-1348-451a-a0ce-5eb84ea19088",
  description: "The header for the actual expenses section of the budget vs actual report.  This is the section that shows how much money you have spent in a category.",
  defaultMessage: "Actual Expenses"
};

export const MSG_differenceHeader = {
  id: "1059ceb9-3261-4436-94ba-1d6b2aee27ce",
  description: "The header for the difference section of the budget vs actual report.  This is the section that shows the difference between how much money you have budgeted for a category vs how much you have spent in that category.",
  defaultMessage: "Difference"
};

export const MSG_allEnvelopesLabel = {
  id: "94c88f84-20b2-4817-9a80-69f6b2164a2d",
  description: "This is the label when they are viewing a report and it is showing all envelopes.  This is the default option in the dropdown.",
  defaultMessage: "All envelopes"
};

export const MSG_pastYearsToDate = {
  id: "fc8b3271-476a-4a86-8a8e-1892dfc2c3cf",
  description: "This is the label when they are viewing a report and it is showing the past X years up to the current month (e.g. to date).",
  defaultMessage: "{years, plural, one {Past 12 months (to date)} other {Past {years} years (to date)}}"
};

export const MSG_lastYears = {
  id: "8a1f33ff-ed20-419c-89e6-94ce1cac2a12",
  description: "This is the label when they are viewing a report and it is showing the last X years.",
  defaultMessage: "{years, plural, one {Last year (Jan-Dec)} other {Last {years} years (Jan-Dec)}}"
};

export const MSG_totalLabel = {
  id: "a90ba814-3415-4baa-9f34-e2aa23cae0dc",
  description: "The label for the total section of the income vs expense report.  This is the section that shows the total amount of money you have earned and spent.",
  defaultMessage: "Total"
};

export const MSG_averageLabel = {
  id: "fdfbf61c-5665-4cec-a813-19268058f2b1",
  description: "The label for the average section of the income vs expense report.  This is the section that shows the average amount of money you have earned and spent.",
  defaultMessage: "Average"
};

