import * as React from "react";
import { PropsWithChildren } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Page } from "@web/components/shared/Page";
import { StyledVerticalOptions } from "@web/components/styled/StyledVerticalOptions";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_helpCenterCaption, MSG_whatIsYourQuestion } from "shared/strings/help";
import { useLazyGetHelpArticlesSearchQuery } from "shared/state/endpoints/app/help_api";
import { TypeAheadTextInputProvider } from "@web/components/inputs/TypeAheadTextInputProvider";
import { HelpArticle as HelpArticleType } from "shared/utils/api_types";
import { faEnvelopeOpenDollar } from "@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons/faNewspaper";
import { faScaleUnbalanced } from "@fortawesome/pro-light-svg-icons/faScaleUnbalanced";
import styled from "styled-components";

const TypeAheadTextInput = TypeAheadTextInputProvider<HelpArticleType>();

interface IProps {}

const HelpCenterRoot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const [query, setQuery] = React.useState<string>('');
  const [articlesSearchQuery] = useLazyGetHelpArticlesSearchQuery();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/app/help_center') {
      navigate('/app/help_center/articles');
    }
  }, [location.pathname]);

  React.useEffect(() => {
    setQuery('');
  }, [location]);

  return (
    <Page
      header={
        <Header>
          <Heading>{formatMessage(MSG_helpCenterCaption)}</Heading>
          <div style={{width: '60%', margin: 'auto'}}>
            <TypeAheadTextInput
              placeholder={formatMessage(MSG_whatIsYourQuestion)}
              autoFocus
              value={query}
              onChange={str => {
                setQuery(str);
              }}
              onSearch={async (str) => {
                return await articlesSearchQuery(str).unwrap();
              }}
              onSelect={article => {
                setQuery('');
                navigate(`/app/help_center/articles/${article.helpGroup?.id}/${article.helpGroup?.slug}/${article.id}/${article.slug}`);
              }}
              parseResult={article => ({
                icon: faNewspaper,
                heading: article.helpGroup?.name || undefined,
                title: article.title || '',
                summaryHtml: article.searchSummary
              })}
            />
          </div>
        </Header>
      }
      sidebar={
        <StyledVerticalOptions className="nowrap" style={{width: '15rem'}}>
          <NavLink className="option" to="/app/help_center/articles">
            <FontAwesomeIcon icon={faEnvelopeOpenDollar}/>
            Articles
          </NavLink>
          {/*<NavLink className="option" to="/app/help_center/forums">*/}
          {/*  <FontAwesomeIcon icon={faCashRegister}/>*/}
          {/*  User Forums*/}
          {/*</NavLink>*/}
          {/*<NavLink className="option text-nowrap" to="/app/help_center/university">*/}
          {/*  <FontAwesomeIcon icon={faCloudArrowUp}/>*/}
          {/*  NeoBudget University*/}
          {/*</NavLink>*/}
          <NavLink className="option" to="/app/help_center/contact">
            <FontAwesomeIcon icon={faScaleUnbalanced}/>
            Report a Problem
          </NavLink>
        </StyledVerticalOptions>
      }>

      <BodyWrapper>
        <Outlet/>
      </BodyWrapper>

    </Page>
  );
};

const Header = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

const BodyWrapper = styled.div`
  padding: 1rem;
`;


export { HelpCenterRoot };
