import { PayloadAction } from "@reduxjs/toolkit";
import { IDataState } from "../data_slice";
import { BudgetedExpense } from "../../../utils/api_types";
import { dataStateSetters } from "./setters";

export const budgetedExpenseDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  resetBudgetedExpense: (state) => {
    state.budgetedExpensesForLedgerHash = {};
  },

  setBudgetedExpense: (state, action: PayloadAction<BudgetedExpense>) => {
    dataStateSetters.setBudgetedExpense(state, action.payload);
  },

  setBudgetedExpensesForLedger: (state, action: PayloadAction<{ledgerId: string, envelopes: BudgetedExpense[]}>) => {
    dataStateSetters.setBudgetedExpensesForLedger(state, action.payload.ledgerId, action.payload.envelopes);
  },

  removeBudgetedExpense: (state, action: PayloadAction<string>) => {
    for (const ledgerId in state.budgetedExpensesForLedgerHash) {
      if (state.budgetedExpensesForLedgerHash[ledgerId][action.payload]) {
        delete state.budgetedExpensesForLedgerHash[ledgerId][action.payload];
      }
    }
  }
};
