import { baseApi } from "../base_api";
import { SyncResponse } from "../../../utils/api_types";
import { fromApiSyncResponse } from "../../../utils/api_transformations";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sync: builder.query<SyncResponse, number>({
      query: (timestamp) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'pending_reconcile_ids');
        params.append('expand[]', 'pending_reconcile_amount');
        params.append('expand[][transactions]', 'account');
        params.append('since', timestamp.toString());
        return ({
          url: `/sync?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return fromApiSyncResponse(response);
      }
    })
  })
});

export const { useSyncQuery, useLazySyncQuery } = extendedApi;
