import * as React from "react";
import { PropsWithChildren } from "react";
import { TextInput } from "@web/components/inputs/TextInput";
import { CurrencyInput } from "@web/components/inputs/CurrencyInput";
import { SelectInput } from "@web/components/inputs/SelectInput";
import { BudgetedExpense, BudgetedExpenseFrequency, ROLE, User } from "shared/utils/api_types";
import {
  MSG_annuallyFrequencyLabel,
  MSG_biweeklyFrequencyLabel,
  MSG_monthlyFrequencyLabel,
  MSG_quarterlyFrequencyLabel,
  MSG_semiannuallyFrequencyLabel,
  MSG_semimonthlyFrequencyLabel,
  MSG_weeklyFrequencyLabel
} from "shared/strings/budget";
import { useIntlFormatters } from "shared/utils/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@web/components/Button";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt";
import { INumberInput } from "@web/components/inputs/NumberInput";
import { selectCurrentUser } from "shared/state/slices/data_selectors/core_data_selectors";
import { useSelector } from "react-redux";

interface IProps {
  expense: Partial<BudgetedExpense>;
  onChange: (expense: Partial<BudgetedExpense>) => any;
  onDelete: () => any;
  autoFocus?: boolean;
}

const BudgetedExpenseInputRow: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const expense = props.expense;
  const currencyInputRef = React.useRef<INumberInput>(null);
  const currentUser = useSelector(selectCurrentUser) as User;

  return (
    <div className="d-flex flex-row align-items-center justify-content-stretch mt-4">
      <TextInput
        className="flex-grow-3"
        autoFocus={props.autoFocus}
        disabled={currentUser.role === ROLE.OBSERVER}
        value={expense.name || ''}
        onChange={(e) => {
          props.onChange({...expense, name: e.target.value});
        }}/>
      <CurrencyInput
        className="ms-4 flex-grow-1 flex-shrink-1"
        ref={currencyInputRef}
        disabled={currentUser.role === ROLE.OBSERVER}
        value={expense.amount || 0}
        onFocus={(e) => {
          if (!expense.amount) {
            currencyInputRef.current?.forceNegativeDefault();
          }
          setTimeout(() => {
            currencyInputRef.current?.select({ignoreFocus: true});
          });
        }}
        onChange={(val) => {
          props.onChange({...expense, amount: val});
        }}/>
      <SelectInput
        className="ms-4 flex-grow-1 flex-shrink-1"
        value={expense.frequency}
        disabled={currentUser.role === ROLE.OBSERVER}
        onChange={(e) => {
          props.onChange({...expense, frequency: e.target.value as BudgetedExpenseFrequency});
        }}>
        <option value="annually">{formatMessage(MSG_annuallyFrequencyLabel)}</option>
        <option value="semiannually">{formatMessage(MSG_semiannuallyFrequencyLabel)}</option>
        <option value="quarterly">{formatMessage(MSG_quarterlyFrequencyLabel)}</option>
        <option value="monthly">{formatMessage(MSG_monthlyFrequencyLabel)}</option>
        <option value="semimonthly">{formatMessage(MSG_semimonthlyFrequencyLabel)}</option>
        <option value="biweekly">{formatMessage(MSG_biweeklyFrequencyLabel)}</option>
        <option value="weekly">{formatMessage(MSG_weeklyFrequencyLabel)}</option>
      </SelectInput>
      {currentUser.role !== ROLE.OBSERVER && (
        <Button className="ms-4"
                color="secondary"
                onClick={props.onDelete}>
          <FontAwesomeIcon icon={faTrashAlt}/>
        </Button>)}
    </div>
  );
};

export {BudgetedExpenseInputRow};
