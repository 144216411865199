import { PayloadAction } from "@reduxjs/toolkit";
import { Transaction } from "../../../utils/api_types";
import { IDataState, TransactionsListType } from "../data_slice";
import { dataStateSetters } from "./setters";

export const transactionDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  resetTransactionsList: (state, action: PayloadAction<{listType: TransactionsListType}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    data.list = [];
    data.totalCount = 0;
    data.page = 1;
  },

  setTransactionsListData: (state, action: PayloadAction<{listType: TransactionsListType, list: Transaction[], totalCount: number, page: number, loading: boolean}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    data.list = action.payload.list;
    data.totalCount = action.payload.totalCount;
    data.page = action.payload.page;
    data.loading = action.payload.loading;
  },

  appendToTransactionsList: (state, action: PayloadAction<{listType: TransactionsListType, transactions: Transaction[]}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    data.list = [...data.list, ...action.payload.transactions];
  },

  setTransactionsListPage: (state, action: PayloadAction<{listType: TransactionsListType, page: number}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    data.page = action.payload.page;
  },

  setTransactionsListTotalCount: (state, action: PayloadAction<{listType: TransactionsListType, totalCount: number}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    data.totalCount = action.payload.totalCount;
  },

  setTransactionsListLoading: (state, action: PayloadAction<{listType: TransactionsListType, loading: boolean}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    data.loading = action.payload.loading;
  },

  setTransaction: (state, action: PayloadAction<{listType: TransactionsListType, transaction: Transaction}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    let index = data.list.findIndex((t) => t.id === action.payload.transaction.id);
    if (action.payload.listType !== 'main') {
      if (index > -1) {
        data.list.splice(index, 1);
      }
    } else {
      if (index > -1) {
        data.list[index] = action.payload.transaction;
      } else {
        data.list.push(action.payload.transaction);
      }
    }
  },

  removeTransaction: (state, action: PayloadAction<{listType: TransactionsListType, transactionId: string}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    let index = data.list.findIndex((t) => t.id === action.payload.transactionId);
    if (index > -1) {
      data.list.splice(index, 1);
    }
  },

  incrementReloadIndicator: (state, action: PayloadAction<{listType: TransactionsListType}>) => {
    const data = dataStateSetters.getTransactionsListData(state, action.payload.listType);
    data.reloadIndicator++;
  },

  setEditingTransaction: (state, action: PayloadAction<Transaction>) => {
    state.editingTransaction = {...action.payload};
  },
};
