export const MSG_freeTierLabel = {
  id: "cbef5a31-62ac-423c-93f9-d7aec7e98595",
  description: "The name of the 'Free' tier of features (our lowest tier)",
  defaultMessage: "Free"
};

export const MSG_freeSubscriptionTitle = {
  id: "896afb84-fced-4f81-91df-df6fbb3cf7dd",
  description: "",
  defaultMessage: "Free Subscription"
};


export const MSG_essentialTierLabel = {
  id: "60962a2a-e865-462a-b0db-c6f0f283ff1e",
  description: "The name of the 'Essential' tier of features (our middle tier).  This name means that it is the essential features that you need to get started.",
  defaultMessage: "Essential"
};

export const MSG_completeTierLabel = {
  id: "1e4571f5-008d-4044-8bd7-303155bf2b82",
  description: "The name of the 'Complete' tier of features (our highest tier).  This name means that it is the complete package, including everything.",
  defaultMessage: "Complete"
};

export const MSG_unknownTierLabel = {
  id: "4740c02a-2dc8-44ac-be06-cc19cac67c96",
  description: "The name of the 'Unknown' tier of features.  This is used when we don't know what tier they are on.",
  defaultMessage: "Unknown"
};


export const MSG_billingTierNumAccounts = {
  id: "1d0c93d5-baac-4c5b-af88-6cd47b516476",
  description: "",
  defaultMessage: "{numAccounts, plural, one {1 account} other {# accounts}}"
};

export const MSG_billingTierUnlimited = {
  id: "93160104-7666-4d2d-8c14-f430cc706682",
  description: "A word that means there is no limit to the number of accounts, envelopes, or users.  This is used in the billing tier section of the pricing page.",
  defaultMessage: "Unlimited"
};

export const MSG_billingTierUnlimitedAccounts = {
  id: "c32d248c-1e2f-40d2-b7a1-bced87d2832a",
  description: "The unlimitedIndicator in English is the word 'Unlimited' but it is a separate value because it is highlighted in a different color.",
  defaultMessage: "{unlimitedIndicator} accounts"
};

export const MSG_billingTierNumEnvelopes = {
  id: "77dbdf97-8e41-4c5d-855f-5ab6947f2726",
  description: "",
  defaultMessage: "{numEnvelopes, plural, one {1 envelope} other {# envelopes}}"
};

export const MSG_billingTierUnlimitedEnvelopes = {
  id: "7c604ea3-7f6f-4912-9790-1ce328722a4a",
  description: "The unlimitedIndicator in English is the word 'Unlimited' but it is a separate value because it is highlighted in a different color.",
  defaultMessage: "{unlimitedIndicator} envelopes"
};

export const MSG_billingTierOneUser = {
  id: "1bda2f1d-49dd-47d4-88c5-a762af809719",
  description: "",
  defaultMessage: "1 user"
};

export const MSG_billingUpToNumUsers = {
  id: "6ababa37-cd4a-4e42-96a5-1de4b7b1eed2",
  description: "The numUsers value is the maximum number of users allowed on this account.  It will be more than 1.",
  defaultMessage: "Up to {numUsers} users"
};

export const MSG_billingTierManualImports = {
  id: "b6b1aa1b-eb89-49c6-aa5b-dd39e8d2b048",
  description: "",
  defaultMessage: "Upload files to import"
};

export const MSG_billingTierAutomaticImports = {
  id: "7094c64a-7a7d-4187-b771-4ad93d1232ac",
  description: "The name of a feature that allows you to automatically sync your bank transactions with your budget.  This is used in the billing tier section of the pricing page.",
  defaultMessage: "Automatic imports"
};

export const MSG_billingTierUnlimitedLedgers = {
  id: "38a30fe6-37c3-4643-bd6d-4f26cd509281",
  description: "The unlimitedIndicator in English is the word 'Unlimited' but it is a separate value because it is highlighted in a different color.",
  defaultMessage: "{unlimitedIndicator} ledgers"
};

export const MSG_billingTierAccountsPerLedger = {
  id: "0e889397-3572-4576-aba5-4c293ba74065",
  description: "",
  defaultMessage: "{numAccounts, plural, one {# account per ledger} other {# accounts per ledger}}"
};

export const MSG_billingTierUnlimitedAccountsPerLedger = {
  id: "730cc4bf-7626-4f99-bc01-262850688135",
  description: "The unlimitedIndicator in English is the word 'Unlimited' but it is a separate value because it is highlighted in a different color.",
  defaultMessage: "{unlimitedIndicator} accounts per ledger"
};


export const MSG_billingTierCreditSweep = {
  id: "12fd9d5a-5d3e-4953-9214-15fad2e320a2",
  description: "This is the name of the feature that automatically moves money around your budget (e.g. sweeps it) to ensure you have enough money to pay off your card each month.",
  defaultMessage: "CreditSweep"
};


export const MSG_billingIntervalForever = {
  id: "238012f7-e3bf-4117-9f14-fa03220147ae",
  description: "To be used to display the billing price and interval when there is no recurrence like $0/forever or €0/forever",
  defaultMessage: "forever"
};


export const MSG_billingIntervalMonth = {
  id: "655f356e-1d8c-4cf3-be06-45d8b25e5bc2",
  description: "To be used to display the billing price and interval like $10/month or €10/month",
  defaultMessage: "month"
};

export const MSG_billingIntervalMonthly = {
  id: "720dcd6e-4e59-4c46-b490-b29f184bf8d3",
  description: "This is the adjective that describes the monthly billing option.  It is used in the billing page to describe the billing interval.",
  defaultMessage: "monthly"
};

export const MSG_billingIntervalMonthOption = {
  id: "6a73611a-e914-4fea-a12d-4db4450b2035",
  description: "When choosing the monthly or annual billing option, this is monthly.",
  defaultMessage: "Monthly Payments"
};

export const MSG_billingIntervalYear = {
  id: "1e661028-b7a8-4164-8616-4832899852f0",
  description: "To be used to display the billing price and interval like $100/year or €100/year",
  defaultMessage: "year"
};

export const MSG_billingIntervalAnnual = {
  id: "71e658b6-925f-4486-8048-afbca0f46be0",
  description: "This is the adjective that describes the annual billing option.  It is used in the billing page to describe the billing interval.",
  defaultMessage: "annual"
};

export const MSG_billingIntervalAnnualOption = {
  id: "63f847e3-9d11-4a64-bc12-0ecaa4948716",
  description: "When choosing the monthly or annual billing option, this is annual.",
  defaultMessage: "Annual Payments"
};

export const MSG_billingIntervalLifetime = {
  id: "0859defe-aa14-4b8d-98b2-06dea5a7fd9d",
  description: "To be used to display the billing price and interval when the interval is forever like $100/lifetime or €100/lifetime",
  defaultMessage: "lifetime"
};

export const MSG_saveAmount = {
  id: "83781e7b-6d89-4fbf-8122-994feff153f5",
  description: "This tells them that if they switch to an annual (yearly) payment plan, they get a discount.  This is the amount of the discount.",
  defaultMessage: "2 months free!"
};


export const MSG_yourCurrentPlan = {
  id: "da3ed571-57e6-4f73-a2d0-e59c2e16fae4",
  description: "This is the label to go above their current subscription plan.  To fit within the needed space, this should be very short if possible.",
  defaultMessage: "Current Plan"
};

export const MSG_selectedPlan = {
  id: "c0e031be-c0c4-4887-aa59-b7a046580fe2",
  description: "This is the label to go above the billing plan that they have selected.",
  defaultMessage: "Selected Plan"
};

export const MSG_reviewChangeButton = {
  id: "0d33e01b-ef83-44a8-955f-b941f2f9b6d8",
  description: "This is the label for a button that lets them view the changes they have made before saving them.",
  defaultMessage: "Review Change"
};


export const MSG_billingDetailsTitle = {
  id: "b2ca84bf-ccce-4725-ba45-7b8aa0dac27d",
  description: "",
  defaultMessage: "Billing Details"
};


export const MSG_billingChangePlan = {
  id: "6f2ab78b-9cdb-4131-b05f-f64555b02222",
  description: "The label for the button to change their subscription plan",
  defaultMessage: "Change Plan"
};

export const MSG_billingCancelPlan = {
  id: "79efca21-1e55-453a-9cc6-969304229aa8",
  description: "",
  defaultMessage: "Cancel Subscription"
};

export const MSG_billingGrandfatheredRate = {
  id: "17aa3b34-4dcf-4309-97ee-17113fd55dd1",
  description: "This is a label that will be displayed on the billing page if they are on a grandfathered rate.  This is a special rate that we gave to some of our earliest customers.",
  defaultMessage: "Grandfathered Rate"
};

export const MSG_billingLifetimePlan = {
  id: "224d32f1-15c7-4bc3-99e5-2b1132372fc1",
  description: "",
  defaultMessage: "Lifetime Plan"
};

export const MSG_billingLifetimePlanExplanation = {
  id: "be67ad42-d4f9-4e41-bf44-caab23e6dac4",
  description: "",
  defaultMessage: "You are currently enrolled in a lifetime plan, which means you will not be charged for your current subscription. You will continue to have access to your account for as long as we are in business, or unless you decide to choose a different set of features."
};


export const MSG_billingSavingsAmount = {
  id: "d19dcbeb-7673-4708-b9e2-1a66b017ac17",
  description: "A description of the amount they are save.",
  defaultMessage: "Saves {amount}/{interval}!"
};

export const MSG_billingGrandfatheredRateExplanation = {
  id: "06bd108b-b933-4402-971b-d01dc56deb68",
  description: "",
  defaultMessage: "You are currently enrolled in a grandfathered rate plan, which is scheduled to expire on September 1, 2024. Until that date, or unless you choose to cancel or alter your subscription, you will continue to be billed at this rate. Please note that on September 1, 2024, if you haven't made any changes, we will automatically update your plan to our new pricing tiers."
};

export const MSG_billingCancelPlanConfirmation = {
  id: "0fde7c0e-0d4f-42d3-a666-ae0ebc2144c5",
  description: "",
  defaultMessage: "You will still have access to your account until the end of your current billing period.  Are you sure you want to cancel your subscription?"
};

export const MSG_billingCancelledNotice = {
  id: "adcecb34-3f5c-4029-a67b-b373e3d3940c",
  description: "",
  defaultMessage: "Your subscription has been cancelled.  You will still have access to your account until the end of your current billing period then you will be converted to the free plan."
};

export const MSG_billingDangerZone = {
  id: "5ebb3fd8-c37e-4674-926e-de84c311be63",
  description: "A section of the billing page that has the most dangerous actions like canceling your subscription.  This is the label for that section.",
  defaultMessage: "Danger Zone"
};

export const MSG_billingForgetMe = {
  id: "9a8b1a2d-18ca-4fb5-a432-ae2b49cf041c",
  description: "The label for a button where we will delete all of their data from our system.  This is the most dangerous action on the billing page.",
  defaultMessage: "Forget Me"
};

export const MSG_billingForgetMeWarning = {
  id: "d9f8d139-14fe-4406-9c12-c3766f21c28b",
  description: "",
  defaultMessage: "This will cancel your subscription and delete all of your data from our system.  This cannot be undone."
};

export const MSG_billingForgetMeConfirm = {
  id: "dae479dd-7652-4f6f-89a2-c125a18e818b",
  description: "",
  defaultMessage: "This will cancel your subscription immediately and delete all of your data from our system.  This cannot be undone.  Are you sure you want to do this?"
};


export const MSG_billingForgetMeConfirmation = {
  id: "a3967196-923d-48df-ad91-01d4c1a7c194",
  description: "",
  defaultMessage: "Are you sure you want to cancel your subscription and delete all of your data from our system?  This cannot be undone."
};


export const MSG_billingPolicyTitle = {
  id: "ebb92d4c-3b3e-4cd4-8b8d-eb7d26c95b8a",
  description: "",
  defaultMessage: "Our Billing Policy"
};

export const MSG_billingPolicyLine1 = {
  id: "455035b7-2bdf-4c71-b4fd-0b6377749c88",
  description: "This is the first line of our billing policy under the heading 'Billing Policy'.",
  defaultMessage: "It's pretty simple. We will charge you each month (or year) automatically. You can cancel at any time and no future charges will be made. When your subscription expires you will be downgraded to the free plan. You can upgrade at any point to regain full access to all features, and your history will be retained."
};

export const MSG_billingPolicyLine2 = {
  id: "e6c9dc4e-3ef1-4367-b7af-e05677082645",
  description: "This is the second line of our billing policy under the heading 'Billing Policy'.",
  defaultMessage: "Our free plan will always be free. We are committed to providing an ad-free experience and we do not sell your data. We believe good budgeting software should be accessible to everyone. We also hope you'll love it enough to consider upgrading for the extra features!"
};

export const MSG_billingPolicyLine3 = {
  id: "103c92c0-2c72-4e5c-8c89-0502fc6df9a6",
  description: "This is the third line of our billing policy under the heading 'Billing Policy'.",
  defaultMessage: "Be sure to read the full {termsOfServiceLink} and {privacyPolicyLink} for all the details."
};

export const MSG_billingPaymentMethodLabel = {
  id: "2236c72b-3779-4775-98a5-f7342838b6b6",
  description: "",
  defaultMessage: "Payment method:"
};

export const MSG_billingExpiresLabel = {
  id: "64b0a6a1-9edc-463a-b5d8-167a1954b550",
  description: "A label that goes before the expiration date on a credit card.",
  defaultMessage: "Exp {month}/{year}"
};

export const MSG_addPaymentMethodNotice = {
  id: "8e5c68eb-98db-4965-ba3f-c8b8e83a1d2a",
  description: "",
  defaultMessage: "Next, add a payment method then review the changes to continue."
};

export const MSG_billingNextPaymentLabel = {
  id: "90942f65-a2df-4d06-b9ea-1b615876c738",
  description: "",
  defaultMessage: "Next payment:"
};

export const MSG_billingNever = {
  id: "25b80470-d282-494f-ad4b-19c1ba5c4c0c",
  description: "A word that means that there will never be a next payment.  This is used in the billing page to indicate that they are on a free plan like 'Next payment: never'.",
  defaultMessage: "never"
};


export const MSG_billingAccountCreditLabel = {
  id: "775892b4-ba55-427d-af34-4e297aa672ed",
  description: "This is the label for the amount of money that they have in their account that will be applied to their next invoice.  This is used in the billing page.",
  defaultMessage: "Account credit:"
};

export const MSG_billingAccountCreditExplanation = {
  id: "66907d01-c294-424f-a662-f030705707fe",
  description: "This is the explanation for the account credit that they have in their account.  This is used in the billing page.",
  defaultMessage: "This amount will be credited to your next invoice."
};

export const MSG_billingAccountCreditGroupExplanation = {
  id: "76edad67-c582-48cc-8732-1e52a845b7f7",
  description: "This is the explanation for the account credit that they have in their account.  This is used in the billing page.",
  defaultMessage: "When you converted to this group plan, you had some remaining credit on your account.  This credit will be applied to future charges for your subscription should you ever detach from this group."
};


export const MSG_billingNone = {
  id: "8028edff-aec7-4bcb-aff9-4b144d6e0f59",
  description: "The word 'none' as in 'Next payment: None'",
  defaultMessage: "None"
};

export const MSG_billingAddPaymentMethodButton = {
  id: "ec84c874-806f-4be5-91a6-5a4a6d8e71ce",
  description: "The label for a button that lets them add a payment method.",
  defaultMessage: "Add Payment Method"
};

export const MSG_billingCardNumberLabel = {
  id: "08fd8ce6-6df1-4ee7-9735-af7375e62052",
  description: "The label for the credit card number field.",
  defaultMessage: "Card number"
};

export const MSG_billingCardExpirationLabel = {
  id: "e585ca5b-50b5-4dde-be18-6db78f5b6847",
  description: "The label for the expiration date of the credit card.",
  defaultMessage: "Expiration"
};

export const MSG_billingCardCvcLabel = {
  id: "65f02e5e-529f-484d-b31e-38f8e3b8507b",
  description: "The label for the CVC code on the back of the credit card.",
  defaultMessage: "CVC"
};

export const MSG_billingUndo = {
  id: "1dc553a4-08de-4bdc-a981-546a5e6eec8c",
  description: "If they have scheduled their subscription to be canceled, this button lets them undo that.",
  defaultMessage: "Undo"
};

export const MSG_billingCancelOnDate = {
  id: "7e589002-3f47-425b-9673-9716f71de145",
  defaultMessage: "Your subscription will be canceled on {date}."
};

export const MSG_billingCancelOnDateShort = {
  id: "1c36fc06-b798-4a92-acce-7f4c25880e91",
  defaultMessage: "Canceling on {date}."
};

export const MSG_billingPreviewProratedChargeToday = {
  id: "2bd0c10c-81f9-4a32-9864-6f4fe96e03f1",
  description: "In this message, the {amount} is the amount they will be charged today.  The {date} is the date that they will be charged the full amount for the new plan.  The {newPlanName} is the name of the new plan they are upgrading to.  The {interval} is the interval of the new plan like 'monthly' or 'annual'.",
  defaultMessage: "Your subscription will be immediately changed to the {newPlanName} {interval} plan. Today, you will be charged a prorated amount of {amount} for the remainder of your current billing period. Your next full payment for the {newPlanName} {interval} plan will be on {date}."
};

export const MSG_billingPreviewProratedChargeTodayIntervalChanged = {
  id: "2bd0c10c-81f9-4a32-9864-6f4fe96e03f1",
  description: "In this message, the {amount} is the amount they will be charged today.  The {date} is the date that they will be charged the full amount for the new plan.  The {newPlanName} is the name of the new plan they are upgrading to.  The {interval} is the interval of the new plan like 'monthly' or 'annual'.",
  defaultMessage: "Your subscription will be immediately changed to the {newPlanName} {interval} plan. Today, you will be charged a prorated amount of {amount}. Your next full payment for the {newPlanName} {interval} plan will be on {date}."
};

export const MSG_scheduledChangeNotice = {
  id: "6aaa5b9b-f293-4080-b371-7962cafd70b2",
  description: "",
  defaultMessage: "Your subscription is scheduled to be downgraded to the {newPlanName} {newInterval} plan on {date}."
};

export const MSG_billingCancelDowngradeButton = {
  id: "7ebc211d-5721-4327-b1b3-7f6cdb347b7b",
  description: "The label for a button that lets them cancel a scheduled downgrade.",
  defaultMessage: "Cancel Downgrade"
};


export const MSG_billingPreviewNoCharge = {
  id: "c6f56d1a-5179-4c38-a531-c0a398651413",
  description: "",
  defaultMessage: `When your current billing period ends, you will be downgraded to the {newPlanName} plan.  You have already paid for the {currentPlanName} plan and will have access to its features until {date}.`
};

export const MSG_billingPreviewProratedAmountChargedToday = {
  id: "480a4bb2-171c-4974-8bef-e2e53b56e070",
  description: "",
  defaultMessage: "Your subscription will be immediately changed to the {newPlanName} {newInterval} plan. Today, you will be billed a prorated amount of {amount}."
};

export const MSG_billingPreviewAmountChargedToday = {
  id: "480a4bb2-171c-4974-8bef-e2e53b56e070",
  description: "",
  defaultMessage: "Your subscription will be immediately changed to the {newPlanName} {newInterval} plan. Today, you will be billed {amount}."
};

export const MSG_billingPreviewProratedAmountChargedTodayWithCredit = {
  id: "480a4bb2-171c-4974-8bef-e2e53b56e070",
  description: "",
  defaultMessage: "Your subscription will be immediately changed to the {newPlanName} {newInterval} plan. Today, you will be billed a prorated amount of {amount} and {creditAmount} of your account credit will be used."
};

export const MSG_billingPreviewAmountChargedTodayWithCredit = {
  id: "480a4bb2-171c-4974-8bef-e2e53b56e070",
  description: "",
  defaultMessage: "Your subscription will be immediately changed to the {newPlanName} {newInterval} plan. Today, you will be billed {amount} and {creditAmount} of your account credit will be used."
};

export const MSG_billingPreviewNextFullPayment = {
  id: "dd1d6731-8077-43d2-b828-fb98070e8705",
  description: "",
  defaultMessage: "Your next full payment for the {newPlanName} {newInterval} plan will be on {date}."
};

export const MSG_billingPreviewDowngradeAtPeriodEnd = {
  id: "e294ffd7-46f9-48b6-a1fe-54145bb997b2",
  description: "",
  defaultMessage: `You will not be billed anything today.  When your current billing period ends, you will be changed to the {newPlanName} {newInterval} plan.  You have already paid for the {currentPlanName} {currentInterval} plan which will be active until {date}.`
};

export const MSG_billingPreviewCreditAddedNotice = {
  id: "d700c09a-e678-4bd3-9759-8f9db950ad51",
  description: "",
  defaultMessage: "A credit of {amount} will be added to your account and will be applied to your next invoice."
};

export const MSG_billingPreviewCreditUsedNotice = {
  id: "881d4d31-90d3-42d8-9117-9f64b29ba867",
  description: "",
  defaultMessage: "{amount} of your account credit will also be used."
};

export const MSG_billingSubscriptionProblemNotice = {
  id: "ff3fa8ee-444b-425c-bf2d-ec6d2d11e82b",
  description: "",
  defaultMessage: "There is a problem with your subscription.  Please visit the billing page to resolve it and ensure that your subscription does not expire."
};

export const MSG_billingGotToButton = {
  id: "d89aa60f-e183-4ae5-a6ef-96e8aaf4585f",
  description: "",
  defaultMessage: "Go to Billing"
};

export const MSG_billingErrorAttemptedChargeLabel = {
  id: "6252471e-f56d-402a-9f6d-f88c2b51b2ad",
  description: "The label for when we tried to charge their credit card.",
  defaultMessage: "Attempted charge:"
};

export const MSG_billingErrorAttemptedAtLabel = {
  id: "d06fef29-33a9-4588-9800-7114459db1b1",
  description: "The label for when the last attempt to charge their credit card was.",
  defaultMessage: "Last attempted:"
};

export const MSG_billingErrorNextAttemptAtLabel = {
  id: "11f693f1-ac9d-4239-b85b-a6118cfcb61b",
  description: "The label for when the next attempt to charge their credit card will be.",
  defaultMessage: "Next attempt:"
};

export const MSG_billingErrorPaymentMethod = {
  id: "c4ef7e92-2b3e-4fb2-9f9f-3176987dc458",
  description: "The label for the payment method that we tried to charge.",
  defaultMessage: "Attempted with:"
};

export const MSG_billingErrorRetryPayment = {
  id: "46774150-0a92-4961-97b1-f3f356fce68c",
  description: "The label for a button that lets them retry a failed payment.",
  defaultMessage: "Retry Payment"
};

export const MSG_billingUnexpectedError = {
  id: "72e2ee6a-d348-42df-bce0-17383163975a",
  description: "",
  defaultMessage: "An unexpected error occurred with your subscription.  Please contact support for assistance at support@neobudget.com."
};

export const MSG_billingLockedEnvelopeTopNotice = {
  id: "a8bd756c-c7b3-4ff9-9402-806d899b43f2",
  description: "",
  defaultMessage: "You are on the free plan which is limited to:"
};

export const MSG_billingLockedEnvelopeFreePlanLimits1 = {
  id: "7e3d5c8c-aea3-44c4-80b5-b8af929b1039",
  description: "",
  defaultMessage: "1 account"
};

export const MSG_billingLockedEnvelopeFreePlanLimits2 = {
  id: "e40cff4c-8506-41f3-8c8f-5d690aea0444",
  description: "",
  defaultMessage: "10 envelopes (the first 10 slots)"
};

export const MSG_billingLockedEnvelopeFreePlanLimits3 = {
  id: "c645bcea-86af-4101-a30e-2527905dda60",
  description: "",
  defaultMessage: "1 user"
};

export const MSG_billingLockedEnvelopeBottomNotice = {
  id: "aa60d47f-13bb-4964-aff7-dee33a68c1e1",
  description: "",
  defaultMessage: "To gain full access, please switch to one of our paid plans. If you previously had an upgraded subscription, all of your existing data is still available for viewing and exporting."
};

export const MSG_billingUpgradeNowButton = {
  id: "8265092d-661a-4a48-acd2-5713cd8379e2",
  description: "",
  defaultMessage: "Upgrade Now"
};

export const MSG_detachTitle = {
  id: "21045466-71cc-4fcb-8c98-bf9d335dd36e",
  description: "A button label that lets them detach from a group (e.g. remove themselves from a group plan)",
  defaultMessage: "Detach"
};

export const MSG_detachExplanation = {
  id: "e39f9e7c-0a66-464e-bf5c-efd8f81b9372",
  description: "",
  defaultMessage: "Your group administrator covers the cost of your NeoBudget subscription at a discount as long as you are attached to this group.  You can detach from the group at any time.  When you do, all of your data will be preserved, but you will be downgraded to our free plan.  You can upgrade your plan at any time to regain access to premium features."
};

export const MSG_confirmDetachExplanation = {
  id: "997e9cd0-ab13-4182-a3b7-5e3581c1178c",
  description: "",
  defaultMessage: "Are you sure you want to detach from this group?\n\nYour data will be saved, but you will be downgraded to our free plan.  You can upgrade your plan at any time to regain access to premium features."
};
