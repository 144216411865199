import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectIncomeSource = (incomeSourceId: string) => createSelector(
  (state: RootState) => state.data.incomeSourcesForLedgerHash,
  (incomeSourcesForLedgerHash) => {
    for (const ledgerId in incomeSourcesForLedgerHash) {
      if (incomeSourcesForLedgerHash[ledgerId][incomeSourceId]) {
        return incomeSourcesForLedgerHash[ledgerId][incomeSourceId];
      }
    }
    return null;
  }
)

export const selectIncomeSourcesForLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.incomeSourcesForLedgerHash,
  (incomeSourcesForLedgerHash) => {
    return Object.values(incomeSourcesForLedgerHash[ledgerId])
  }
);
