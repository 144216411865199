import { baseApi } from "../base_api";
import { EmojiSuggestion, Envelope } from "../../../utils/api_types";
import { fromApiEmojiSuggestion, fromApiEnvelope, toApiEnvelope } from "../../../utils/api_transformations";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEnvelopesForLedger: builder.query<{envelopes: Envelope[]}, {ledgerId?: string}>({
      query: (args) => {
        return ({
          url: `/ledgers/${args.ledgerId}/envelopes`,
        });
      },
      transformResponse: (response: any) => {
        return {
          totalCount: response.items.length,
          page: 1,
          envelopes: response.items.map(fromApiEnvelope)
        }
      }
    }),

    getEnvelope: builder.query<Envelope, {ledgerId: string, envelopeId: string}>({
      query: ({ledgerId, envelopeId}) => ({
        url: `/ledgers/${ledgerId}/envelopes/${envelopeId}`,
      }),
      transformResponse: (response: any) => {
        return fromApiEnvelope(response['envelope']);
      },
    }),

    getEmojiSuggestions: builder.query<{suggestions: EmojiSuggestion[]}, {ledgerId: string, term: string, excluding?: string}>({
      query: ({ledgerId, term, excluding}) => ({
        url: `/ledgers/${ledgerId}/envelopes/emoji_suggestions`,
        params: {term, excluding},
      }),
      transformResponse: (response: any) => {
        return {
          suggestions: response.suggestions.map(fromApiEmojiSuggestion)
        }
      }
    }),

    updateEnvelope: builder.mutation<Envelope, {ledgerId: string, envelope: Partial<Envelope>}>({
      query: ({ledgerId, envelope}) => ({
        url: `/ledgers/${ledgerId}/envelopes/${envelope.id}`,
        method: 'PATCH',
        body: {envelope: toApiEnvelope(envelope)},
      }),
      transformResponse: (response: any) => {
        return fromApiEnvelope(response['envelope']);
      },
    }),

    updateEnvelopes: builder.mutation<{success: boolean, errors: string[]}, {ledgerId: string, envelopes: Partial<Envelope>[]}>({
      query: ({ledgerId, envelopes}) => ({
        url: `/ledgers/${ledgerId}/envelopes`,
        method: 'PATCH',
        body: {envelopes: envelopes.map(toApiEnvelope)},
      }),
      transformResponse: (response: any) => {
        return {
          success: response['success'],
          errors: response['errors'] || [],
        };
      },
    }),

    createEnvelope: builder.mutation<Envelope, {ledgerId: string, envelope: Partial<Envelope>}>({
      query: ({ledgerId, envelope}) => ({
        url: `/ledgers/${ledgerId}/envelopes`,
        method: 'POST',
        body: {envelope: toApiEnvelope(envelope)},
      }),
      transformResponse: (response: any) => {
        return fromApiEnvelope(response['envelope']);
      },
    }),

    deleteEnvelope: builder.mutation<{success: boolean}, {ledgerId: string, envelopeId: string}>({
      query: ({ledgerId, envelopeId}) => ({
        url: `/ledgers/${ledgerId}/envelopes/${envelopeId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: any) => {
        return {success: true};
      },
    }),

  })
});

export const {
  useGetEnvelopesForLedgerQuery, useLazyGetEnvelopesForLedgerQuery,
  useGetEnvelopeQuery, useLazyGetEnvelopeQuery,
  useGetEmojiSuggestionsQuery, useLazyGetEmojiSuggestionsQuery,
  useUpdateEnvelopeMutation,
  useUpdateEnvelopesMutation,
  useCreateEnvelopeMutation,
  useDeleteEnvelopeMutation,
} = extendedApi;
