import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectBudgetedExpense = (budgetedExpenseId: string) => createSelector(
  (state: RootState) => state.data.budgetedExpensesForLedgerHash,
  (budgetedExpensesForLedgerHash) => {
    for (const ledgerId in budgetedExpensesForLedgerHash) {
      if (budgetedExpensesForLedgerHash[ledgerId][budgetedExpenseId]) {
        return budgetedExpensesForLedgerHash[ledgerId][budgetedExpenseId];
      }
    }
    return null;
  }
)

export const selectBudgetedExpensesForLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.budgetedExpensesForLedgerHash[ledgerId],
  (budgetedExpensesForLedger) => {
    return Object.values(budgetedExpensesForLedger)
  }
);
