import * as React from "react";
import { PropsWithChildren, RefObject } from "react";
import { StyledClickableRaisedContainer } from "./styled/StyledRaisedContainer";
import styled from "styled-components";
import { Spinner } from "./Spinner";
import { opacify } from "color2k";

interface IProps extends PropsWithChildren {
  color?: 'secondary' | 'primary' | 'success' | 'warning' | 'danger';
  spinner?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  link?: boolean;
  onClick?: (e?: React.MouseEvent) => any;
  ref?: RefObject<any>;
}

const Button = React.forwardRef<any,IProps>((props, ref) => {
  let className = [props.className, props.color || 'primary'].join(' ');
  if (props.fullWidth) {
    className += ' full-width';
  }

  if (props.link) {
    return (
      <StyledLinkButton as="button"
                        disabled={props.disabled}
                        tabIndex={props.tabIndex}
                        className={className}
                        ref={ref}
                        onClick={props.onClick}>
        <div className="label">
          {props.children}
        </div>
        {props.spinner && (
          <StyledSpinner className={className}>
            <Spinner/>
          </StyledSpinner>)}
      </StyledLinkButton>
    );
  } else {
    return (
      <StyledButton as="button"
                    disabled={props.disabled}
                    tabIndex={props.tabIndex}
                    className={className}
                    ref={ref}
                    onClick={props.onClick}>
        <div className="label">
          {props.children}
        </div>
        {props.spinner && (
          <StyledSpinner className={className}>
            <Spinner/>
          </StyledSpinner>)}
      </StyledButton>
    );
  }
});

export const StyledButton = styled(StyledClickableRaisedContainer)`
  padding: 0.5rem 1rem;
  display: inline-block;
  color: #fff;
  position: relative;
  background: ${({theme}) => theme.colors.primary};

  &.input {
    background: #fff;
    box-shadow: none;
    border: 1px solid ${({theme}) => theme.colors.lightBorder};
  }
  &.secondary {
    background: ${({theme}) => theme.colors.secondary};
    color: ${({theme}) => theme.colors.textColor};
    &:focus:after {
      outline-color: ${({theme}) => opacify(theme.colors.textColor, -0.5)} !important;
    }
  }
  &.success { background: ${({theme}) => theme.colors.success} }
  &.warning { background: ${({theme}) => theme.colors.warning} }
  &.danger { background: ${({theme}) => theme.colors.danger} }

  &:disabled { opacity: 0.65 }

  &.full-width { width: 100%; display: block; }
`;

export const StyledLinkButton = styled(StyledButton)`
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  color: ${({theme}) => theme.colors.textColor};
  opacity: 0.7;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  &.secondary { color: ${({theme}) => theme.colors.textColor} }
  &.success { color: ${({theme}) => theme.colors.success} }
  &.warning { color: ${({theme}) => theme.colors.warning} }
  &.danger { color: ${({theme}) => theme.colors.danger} }

  &:hover {
    opacity: 1;
  }
`;

const StyledSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({theme}) => theme.colors.primary}dd;
  &.secondary {
    color: ${({theme}) => theme.colors.textColor};
    background: ${({theme}) => theme.colors.secondary}bb;
  }
  &.success { background: ${({theme}) => theme.colors.success}dd }
  &.warning { background: ${({theme}) => theme.colors.warning}dd }
  &.danger { background: ${({theme}) => theme.colors.danger}dd }
  border-radius: ${({theme}) => theme.raisedContainer.borderRadius};
  margin: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockButton = styled(Button)`
  display: block;
  width: 100%;
`;

export {Button, BlockButton};
