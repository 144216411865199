export const MSG_settingsTitle = {
  id: "f27732fd-1f06-40ca-8d03-1752060e37ad",
  description: "The title for the 'Settings' section where they can configure various details about their account.",
  defaultMessage: "Settings"
};

export const MSG_usersTitle = {
  id: "39bff043-fe11-4d1f-8e2b-f3421dff2f94",
  description: "The settings section where they can configure users.",
  defaultMessage: "Users"
};

export const MSG_connectionsTitle = {
  id: "9b98b83e-d7c3-4bc7-9856-7c23361c640f",
  description: "The settings section where they can configure connections to their banking institutions",
  defaultMessage: "Connections"
};

export const MSG_appearanceTitle = {
  id: "c8530b64-63b6-440e-b78f-9049c27d91ee",
  description: "The settings section where they can configure their number and date formatting preferences, as well as font size and other display preferences.",
  defaultMessage: "Appearance"
};


export const MSG_billingTitle = {
  id: "b04a37bc-6323-4184-a352-4bc7916825f7",
  description: "The settings section where they can configure their billing (e.g. how they pay for the product)",
  defaultMessage: "Billing"
};


export const MSG_balanceLabelColon = {
  id: "e4040da2-c172-4b29-9e30-fb3ca97bdc66",
  description: "The current balance of a bank account.",
  defaultMessage: "Balance: {amount}"
};

export const MSG_endingInLabel = {
  id: "29217823-e667-4bfc-9989-91be222cb677",
  description: "A label for a bank account with a masked account number.  We only show the last few digits.  It will read in english like 'Ending in 1234' for account number 389291234.",
  defaultMessage: "Ending in {mask}"
};

export const MSG_launchPlaidLinkLabel = {
  id: "798e1572-6478-40fd-910e-e900e6610b6f",
  description: "This is the button that lets them add a new connection to one of their bank accounts.",
  defaultMessage: "Add a Connection"
};

export const MSG_linkAccountTitle = {
  id: "d3198b68-ffbd-45c1-bde5-c7cf86ebd04f",
  description: "The button label for linking a NeoBudget account with a Plaid Account (which handles syncing transactions from their bank)",
  defaultMessage: "Link Account"
};

export const MSG_alreadyLinkedLabel = {
  id: "15b12d43-4b75-4245-9726-1676647b34de",
  description: "This tells them that a NeoBudget account is already linked with a bank account.",
  defaultMessage: "(already linked)"
};

export const MSG_unexpectedProblemLinkingAccounts = {
  id: "0386826f-e4af-451c-a3f7-6abd5aebca05",
  description: "",
  defaultMessage: "There was an unexpected problem linking to your bank account.  Please reload the page and try again."
};

export const MSG_unlinkButton = {
  id: "4a5ca609-bd77-4037-ada0-98d0ffc22812",
  description: "",
  defaultMessage: "Unlink"
};

export const MSG_unlinkPlaidAccountConfirmation = {
  id: "caf1823b-5cc2-41e2-9ccb-7f5739fcd6bd",
  description: "",
  defaultMessage: "Are you sure you want to unlink this account connection?"
};

export const MSG_unlinkPlaidAccountConfirmationExplanation = {
  id: "d18c13ae-62bb-47d3-97f6-04032013e8ee",
  description: "",
  defaultMessage: "Unlinking this connection will cause transactions stop automatically syncing with your bank account."
};

export const MSG_regionalSettingsTitle = {
  id: "d0ccaaf4-228b-40ad-a2ce-d793c3e897f1",
  description: "The title of the section where they can change their regional settings (e.g. date format, currency format, etc.)",
  defaultMessage: "Regional Settings"
};

export const MSG_displaySettingsTitle = {
  id: "73e4a970-0cd3-4f45-9d26-f06d8f41d7fe",
  description: "The title of the section where they can change their display settings (e.g. font size, UI scale, etc.)",
  defaultMessage: "Display Settings"
};

export const MSG_dateFormatLabel = {
  id: "fbdeab64-97ba-442b-ae84-320d2864bd58",
  description: "The label for the date format setting.  This is the setting that lets them choose how to display dates (e.g. 1/1/2020 vs 2020-01-01).",
  defaultMessage: "Date format"
};

export const MSG_timeFormatLabel = {
  id: "1b3a2a24-673b-477e-a1e6-8c3f09763c45",
  description: "The label for the time format setting.  This is the setting that lets them choose how to display times (e.g. 12:00 vs 24:00).",
  defaultMessage: "Time format"
};

export const MSG_timeFormatLabel12Hour = {
  id: "1de9fcd2-39be-4718-b4d6-6931d85afca9",
  description: "The label for the 12-hour time format setting.  This is the setting that lets them choose how to display times (e.g. 12:00 vs 24:00).",
  defaultMessage: "12-hour format"
};

export const MSG_timeFormatLabel24Hour = {
  id: "716f9267-ae04-42dd-8c09-0624c7febdc9",
  description: "The label for the 24-hour time format setting.  This is the setting that lets them choose how to display times (e.g. 12:00 vs 24:00).",
  defaultMessage: "24-hour format"
};


export const MSG_numberFormatLabel = {
  id: "ae8491c1-af10-4988-bacc-d64f4925f2dd",
  description: "The label for the number format setting.  This is the setting that lets them choose how to display numbers (e.g. 1,000.00 vs 1.000,00).",
  defaultMessage: "Number format"
};

export const MSG_decimalPlacesLabel = {
  id: "7e9dbd9d-9f66-4abb-8574-3476c2d8eb14",
  description: "The label for the decimal places setting for displaying currency.  This is the setting that lets them choose how many decimal places to display for currency (e.g. 1.00 vs 100).",
  defaultMessage: "Currency decimals"
};

export const MSG_decimalsValue = {
  id: "82c04a37-ac52-46a1-a209-63c6f16040a9",
  description: "This is used to display the selected value for the 'currency decimals' setting.",
  defaultMessage: "{count, plural, one {# decimal} other {# decimals}}"
};

export const MSG_decimalPlacesExplanation = {
  id: "10082df0-b226-435b-9a8f-a81d363c78c1",
  description: "",
  defaultMessage: "Some currencies have no decimal places (e.g. Japanese Yen) while others have 2 (e.g. US Dollar, Euro)."
};

export const MSG_interfaceScaleLabel = {
  id: "2bd0b5c1-583d-4598-9e30-78bbe7e9496d",
  description: "The label for the interface scale setting.  This is the setting that lets them make the text and buttons bigger or smaller.",
  defaultMessage: "Interface scale"
};

export const MSG_featuresTitle = {
  id: "9f7d7755-89b5-4ed1-9734-29d73319c7c2",
  description: "The title for the section where they can enable or disable features.",
  defaultMessage: "Features"
};

export const MSG_enabledLabel = {
  id: "4441f19a-1557-420b-862e-3ba9bf11e75c",
  description: "The label for the enabled/disabled setting.  This is the setting that lets them enable or disable a feature.",
  defaultMessage: "Enabled"
};

export const MSG_disabledLabel = {
  id: "c89559dd-c6ae-46aa-9e10-61d310388d70",
  description: "The label for the enabled/disabled setting.  This is the setting that lets them enable or disable a feature.",
  defaultMessage: "Disabled"
};

export const MSG_globalSettings = {
  id: "e8218da3-0b97-4955-91e7-7d38a086b84e",
  description: "This is used to indicate that a setting is global (i.e. it applies to all users).",
  defaultMessage: "Global"
};

export const MSG_globalSettingsExplanation = {
  id: "ae0b882f-9148-4566-82eb-dae2a9a090cf",
  description: "This is used to explain that a setting is global (i.e. it applies to all users).",
  defaultMessage: "These settings apply to all users."
};

export const MSG_personalSettings = {
  id: "12477e40-266f-4eff-8ac6-c44d1712f0b6",
  description: "This is used to indicate that a setting is personal (i.e. it applies only to the current user).",
  defaultMessage: "Personal"
};

export const MSG_personaSettingsExplanation = {
  id: "7b4c5562-23bc-4875-b1f6-10ea7fc7290a",
  description: "This is used to explain that a setting is personal (i.e. it applies only to the current user).",
  defaultMessage: "These settings apply only to you."
};


export const MSG_ledgerSettingsTitle = {
  id: "1f79b23e-e6aa-4000-92e9-69bc2ac2b6ba",
  description: "The title for the section where they can configure ledger settings.",
  defaultMessage: "Ledger Settings"
};

export const MSG_thisLedgerTitle = {
  id: "05fb57b1-5102-40aa-8a46-28ed0176d851",
  description: "The title for the section where they can configure settings for the current ledger.",
  defaultMessage: "This Ledger"
};

export const MSG_accountsTitle = {
  id: "da3a8b4b-9b5b-48a3-9918-523023615bc8",
  description: "",
  defaultMessage: "Accounts"
};

export const MSG_noAccounts = {
  id: "2962ec2e-b480-4935-b31b-e196db701ea6",
  description: "",
  defaultMessage: "No accounts have been created yet for this ledger."
};
