import * as React from "react";
import { PropsWithChildren } from "react";
import { selectCurrentSubscription, selectProducts } from "shared/state/store";
import { Spinner } from "@web/components/Spinner";
import { useSelector } from "react-redux";
import {
  IndividualBillingDetails
} from "@web/pages/app/authenticated/settings/billing/components/IndividualBillingDetails";
import { GroupBillingDetails } from "./components/GroupBillingDetails";

interface IProps {}

const BillingPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const subscription = useSelector(selectCurrentSubscription);
  const allProducts = useSelector(selectProducts);

  if (!subscription || !allProducts) {
    return <Spinner/>;
  }

  if (subscription.group) {
    return <GroupBillingDetails/>;
  } else {
    return <IndividualBillingDetails/>;
  }
};

export {BillingPage};
