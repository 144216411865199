import {
  BILLING_INTERVAL,
  BudgetedExpense,
  CARD_BRAND,
  Envelope,
  IncomeAllocation,
  IncomeSource,
  PlanChangePreview,
  Product,
  STRIPE_STATUS,
  Subscription,
  TransactionKind
} from "./api_types";
import {
  MSG_expenseTypeLabel,
  MSG_incomeTypeLabel,
  MSG_moveTypeLabel,
  MSG_transferTypeLabel
} from "../strings/transactions";
import { MessageDescriptor } from "react-intl";
import {
  MSG_biweeklyFrequencyLabel,
  MSG_monthlyFrequencyLabel,
  MSG_semimonthlyFrequencyLabel,
  MSG_weeklyFrequencyLabel
} from "../strings/budget";
import {
  ICalculatedBudgetSummary,
  ICalculatedBudgetValues,
  ICalculatedEnvelopeFundingValues,
  ICalculatedExpensesSummary,
  ICalculatedFundingSummary,
  ICalculatedIncomeSourceFundingValues,
  ICalculatedIncomeSummary,
  IDeviceInfo
} from "./interfaces";
import { IFormatCurrencyOptions } from "./formatters";
import {
  MSG_billingIntervalAnnual,
  MSG_billingIntervalLifetime,
  MSG_billingIntervalMonth,
  MSG_billingIntervalMonthly,
  MSG_billingIntervalYear,
  MSG_billingNever,
  MSG_billingPreviewAmountChargedToday,
  MSG_billingPreviewAmountChargedTodayWithCredit,
  MSG_billingPreviewCreditAddedNotice,
  MSG_billingPreviewDowngradeAtPeriodEnd,
  MSG_billingPreviewNextFullPayment,
  MSG_billingPreviewProratedAmountChargedToday,
  MSG_billingPreviewProratedAmountChargedTodayWithCredit,
  MSG_completeTierLabel,
  MSG_essentialTierLabel,
  MSG_freeTierLabel,
  MSG_unknownTierLabel
} from "../strings/billing";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { faCcDinersClub } from "@fortawesome/free-brands-svg-icons/faCcDinersClub";
import { faCcDiscover } from "@fortawesome/free-brands-svg-icons/faCcDiscover";
import { faCcJcb } from "@fortawesome/free-brands-svg-icons/faCcJcb";
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons/faCcMastercard";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons/faCcVisa";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { MSG_timeFormatLabel12Hour, MSG_timeFormatLabel24Hour } from "../strings/settings";
import { NB_CONFIG } from "shared/utils/config";
import { faCcAmex } from "@fortawesome/free-brands-svg-icons/faCcAmex";

dayjs.extend(customParseFormat);

export function getTransactionKindLabelMessage(kind: TransactionKind): MessageDescriptor {
  switch (kind) {
    case 'income': return MSG_incomeTypeLabel;
    case 'expense': return MSG_expenseTypeLabel;
    case 'move': return MSG_moveTypeLabel;
    case 'transfer': return MSG_transferTypeLabel;
    default: return MSG_incomeTypeLabel;
  }
}

export function getFrequencyLabel(frequency: any, formatMessage: any) {
  switch (frequency) {
    case 'monthly': return formatMessage(MSG_monthlyFrequencyLabel);
    case 'semimonthly': return formatMessage(MSG_semimonthlyFrequencyLabel);
    case 'biweekly': return formatMessage(MSG_biweeklyFrequencyLabel);
    case 'weekly': return formatMessage(MSG_weeklyFrequencyLabel);
    default: return '';
  };
}

export function getNumChecks(frequency: any, options: {fractional?: boolean} = {fractional: false}) {
  let numChecks = 1;
  switch (frequency) {
    case 'weekly': numChecks = 52/12; break;
    case 'biweekly': numChecks = 52/12/2; break;
    case 'semimonthly': numChecks = 2; break;
    case 'monthly': numChecks = 1; break;
    case 'quarterly': numChecks = 1/3; break;
    case 'semiannually': numChecks = 1/6; break;
    case 'annually': numChecks = 1/12; break;
  }
  if (options.fractional) {
    return numChecks;
  } else {
    return Math.ceil(numChecks);
  }
}

export function frequencyHasExtra(frequency: any) {
  return ['weekly', 'biweekly'].includes(frequency);
}

export function calculateBudgetValues(
  incomeSources: IncomeSource[],
  incomeAllocations: Partial<IncomeAllocation>[],
  budgetedExpenses: Partial<BudgetedExpense>[],
  envelopes: Envelope[],
): ICalculatedBudgetValues {
  let values: ICalculatedBudgetValues = {};

  // Set up the remainders for each paycheck for each income source.  We will
  // use this to calculate the "unallocated" envelope.  Prefill with the
  // income sources amounts themselves, and we will subtract from this as we
  // go and will be left with the remainder.
  let calculatedRemainders: ICalculatedIncomeSourceFundingValues = {};
  incomeSources.forEach(incomeSource => {
    if (!incomeSource.id) return;
    let incomeSourceId: string = incomeSource.id;
    calculatedRemainders[incomeSourceId] = {};
    const numChecks = getNumChecks(incomeSource.frequency);
    for (let i = 0; i < numChecks; i++) {
      calculatedRemainders[incomeSourceId][i] = incomeSource.amount;
    }
  });
  values['unallocated'] = {
    funding: calculatedRemainders,
    fundingSummary: {amount: 0, extra: 0, average: 0, minChecks: 0, maxChecks: 0},
    expensesSummary: {average: 0},
  };

  let incomeTotal = 0;

  // Now iterate over all the envelopes and calculate the funding values for
  // each envelope.  Also decrement any amounts from the unallocated remainders.
  envelopes.forEach(envelope => {
    if (!envelope || !envelope.id || envelope.archived) return;

    let fundingSummary: ICalculatedFundingSummary = {amount: 0, extra: 0, average: 0, minChecks: 0, maxChecks: 0};
    let expensesSummary: ICalculatedExpensesSummary = {average: 0};

    let envelopeValue: ICalculatedEnvelopeFundingValues = values[envelope.id] = {
      funding: {},
      fundingSummary: fundingSummary,
      expensesSummary: expensesSummary,
    }

    incomeSources.forEach(incomeSource => {
      if (!incomeSource.id) return;
      let incomeSourceId: string = incomeSource.id;
      envelopeValue.funding[incomeSource.id] = {};
      const numChecks = getNumChecks(incomeSource.frequency, {fractional: true});
      const hasExtra = numChecks !== Math.floor(numChecks);
      const lastCheckIndex = Math.ceil(numChecks) - 1;
      fundingSummary.minChecks = Math.max(fundingSummary.minChecks, Math.floor(numChecks));
      fundingSummary.maxChecks = Math.max(fundingSummary.maxChecks, Math.ceil(numChecks));

      incomeAllocations.forEach(ia => {
        if (typeof ia.sequenceNumber === 'undefined') return;
        if (typeof ia.amount === 'undefined') return;

        if (ia.envelopeId === envelope.id && ia.incomeSourceId === incomeSource.id && ia.sequenceNumber <= numChecks) {
          envelopeValue.funding[incomeSourceId][ia.sequenceNumber] = ia.amount;
          calculatedRemainders[incomeSourceId][ia.sequenceNumber] -= ia.amount;
          let amount = ia.amount;
          if (ia.method === 'percent') {
            amount = incomeSource.amount * (ia.amount / 10000);
          }

          if (hasExtra && ia.sequenceNumber === lastCheckIndex) {
            incomeTotal += amount
            fundingSummary.extra += amount;
            fundingSummary.average += amount * (numChecks - Math.floor(numChecks));
          } else {
            fundingSummary.amount += amount;
            fundingSummary.average += amount;
          }
        }
      })
    });
    budgetedExpenses.forEach(be => {
      if (typeof be.amount === 'undefined') return;
      if (be.envelopeId === envelope.id) {
        const numChecks = getNumChecks(be.frequency, {fractional: true});
        expensesSummary.average += be.amount * numChecks;
      }
    });

    // Round the averages.  We are doing the funky rounding for expenses because
    // we want to round away from zero
    fundingSummary.average = Math.round(fundingSummary.average);
    expensesSummary.average = -1 * Math.round(-1 * expensesSummary.average);
  });

  return values;
}

export function calculateMonthlyTotals(
  incomeSources: IncomeSource[],
  calculatedValues: ICalculatedBudgetValues
): ICalculatedBudgetSummary {
  let monthlyIncome: ICalculatedIncomeSummary = {amount: 0, extra: 0, average: 0, minChecks: 0, maxChecks: 0};
  let monthlyExpenses: ICalculatedExpensesSummary = {average: 0};
  let monthlyFunding: ICalculatedFundingSummary = {amount: 0, extra: 0, average: 0, minChecks: 0, maxChecks: 0};

  incomeSources.forEach(incomeSource => {
    if (!incomeSource.id) return;
    const numChecks = getNumChecks(incomeSource.frequency, {fractional: true});
    const hasExtra = numChecks !== Math.floor(numChecks);
    const lastCheckIndex = Math.ceil(numChecks) - 1;
    monthlyFunding.minChecks = monthlyIncome.minChecks = Math.max(monthlyIncome.minChecks, Math.floor(numChecks));
    monthlyFunding.maxChecks = monthlyIncome.maxChecks = Math.max(monthlyIncome.maxChecks, Math.ceil(numChecks));
    for (let i = 0; i < Math.ceil(numChecks); i++) {
      const incomeSourceValue = incomeSource.amount || 0;
      if (hasExtra && i === lastCheckIndex) {
        monthlyIncome.extra += incomeSourceValue;
        monthlyIncome.average += incomeSourceValue * (numChecks - Math.floor(numChecks));
      } else {
        monthlyIncome.amount += incomeSourceValue;
        monthlyIncome.average += incomeSourceValue;
      }
    }
  });

  Object.keys(calculatedValues).forEach(envelopeId => {
    if (envelopeId === 'unallocated') return;
    const envelopeValue = calculatedValues[envelopeId];
    monthlyFunding.amount += envelopeValue.fundingSummary.amount;
    monthlyFunding.extra += envelopeValue.fundingSummary.extra;
    monthlyFunding.average += envelopeValue.fundingSummary.average;
    monthlyExpenses.average += envelopeValue.expensesSummary.average;
  });


  return {monthlyIncome, monthlyExpenses, monthlyFunding};
}

export function isLifetimePlan(product: Product | string) {
  let productKey = typeof product === 'string' ? product : product.productKey;
  if (productKey === 'standard-lifetime') return true;
  return false;
}

export function findProduct(productKey: string, products: Product[]) {
  return products.find(p => p.productKey === productKey);
}

export function getPlanName(productKey: string, products: Product[], formatMessage: (messageDescriptor: MessageDescriptor, values?: any) => string) {
  let product = findProduct(productKey, products);
  if (product) {
    switch (product.featureLevel) {
      case 'complete': return formatMessage(MSG_completeTierLabel);
      case 'essential': return formatMessage(MSG_essentialTierLabel);
      case 'free': return formatMessage(MSG_freeTierLabel);
      default: return formatMessage(MSG_unknownTierLabel);
    }
  } else {
    return formatMessage(MSG_unknownTierLabel);
  }
}

export function getIntervalLabel(productKey: string, products: Product[], formatMessage: (messageDescriptor: MessageDescriptor, values?: any) => string) {
  let product = products.find(p => p.productKey === productKey);
  if (product) {
    switch (product.interval) {
      case BILLING_INTERVAL.MONTH: return formatMessage(MSG_billingIntervalMonth);
      case BILLING_INTERVAL.YEAR: return formatMessage(MSG_billingIntervalYear);
      case BILLING_INTERVAL.LIFETIME: return formatMessage(MSG_billingIntervalLifetime);
      default: return formatMessage(MSG_unknownTierLabel);
    }
  } else {
    return formatMessage(MSG_unknownTierLabel);
  }
}

export function getIntervalAdjectiveLabel(productKey: string, products: Product[], formatMessage: (messageDescriptor: MessageDescriptor, values?: any) => string) {
  let product = products.find(p => p.productKey === productKey);
  if (product) {
    switch (product.interval) {
      case BILLING_INTERVAL.MONTH: return formatMessage(MSG_billingIntervalMonthly);
      case BILLING_INTERVAL.YEAR: return formatMessage(MSG_billingIntervalAnnual);
      case BILLING_INTERVAL.LIFETIME: return formatMessage(MSG_billingIntervalLifetime);
      default: return formatMessage(MSG_unknownTierLabel);
    }
  } else {
    return formatMessage(MSG_unknownTierLabel);
  }
}

export function previewSummary(
  preview: PlanChangePreview,
  currentProductKey: string,
  newProductKey: string,
  products: Product[],
  subscription: Subscription,
  formatMessage: (messageDescriptor: MessageDescriptor, values?: any) => string,
  formatCurrency: (value: number, subscription: Subscription, options?: IFormatCurrencyOptions) => string,
  formatDate: (value: string | Dayjs, subscription: Subscription) => string,
) {
  let messages: string[] = [];

  if (preview.downgradeAtNextBillingCycle) {
    messages.push(formatMessage(MSG_billingPreviewDowngradeAtPeriodEnd, {
      newPlanName: getPlanName(newProductKey, products, formatMessage),
      newInterval: getIntervalAdjectiveLabel(newProductKey, products, formatMessage),
      currentPlanName: getPlanName(currentProductKey, products, formatMessage),
      currentInterval: getIntervalAdjectiveLabel(currentProductKey, products, formatMessage),
      date: preview.nextInvoiceAt ? formatDate(preview.nextInvoiceAt, subscription) : formatMessage(MSG_billingNever),
    }));
  } else {
    let newProduct = findProduct(newProductKey, products);
    let newProductAmount = newProduct?.prices.usd.amount || 0;
    if (preview.currentInvoiceBalanceChange < 0) {
      messages.push(formatMessage(newProductAmount == preview.currentInvoiceAmount ? MSG_billingPreviewAmountChargedTodayWithCredit : MSG_billingPreviewProratedAmountChargedTodayWithCredit, {
        amount: formatCurrency(preview.currentInvoiceAmount, subscription, {showSymbol: true}),
        newPlanName: getPlanName(newProductKey, products, formatMessage),
        newInterval: getIntervalAdjectiveLabel(newProductKey, products, formatMessage),
        date: preview.nextInvoiceAt ? formatDate(preview.nextInvoiceAt, subscription) : formatMessage(MSG_billingNever),
        creditAmount: formatCurrency(-preview.currentInvoiceBalanceChange, subscription, {showSymbol: true}),
      }));
    } else {
      messages.push(formatMessage(newProductAmount == preview.currentInvoiceAmount ? MSG_billingPreviewAmountChargedToday : MSG_billingPreviewProratedAmountChargedToday, {
        amount: formatCurrency(preview.currentInvoiceAmount, subscription, {showSymbol: true}),
        newPlanName: getPlanName(newProductKey, products, formatMessage),
        newInterval: getIntervalAdjectiveLabel(newProductKey, products, formatMessage),
        date: preview.nextInvoiceAt ? formatDate(preview.nextInvoiceAt, subscription) : formatMessage(MSG_billingNever),
      }));

      if (preview.currentInvoiceBalanceChange > 0) {
        messages.push(formatMessage(MSG_billingPreviewCreditAddedNotice, {
          amount: formatCurrency(preview.currentInvoiceBalanceChange, subscription, {showSymbol: true}),
        }));
      }
    }

    messages.push(formatMessage(MSG_billingPreviewNextFullPayment, {
      newPlanName: getPlanName(newProductKey, products, formatMessage),
      newInterval: getIntervalAdjectiveLabel(newProductKey, products, formatMessage),
      currentPlanName: getPlanName(currentProductKey, products, formatMessage),
      currentInterval: getIntervalAdjectiveLabel(currentProductKey, products, formatMessage),
      date: preview.nextInvoiceAt ? formatDate(preview.nextInvoiceAt, subscription) : formatMessage(MSG_billingNever),
    }));
  }


  return messages.join(' ');
}

export function getFormattedCardBrand(brand?: CARD_BRAND) {
  switch (brand) {
    case CARD_BRAND.VISA: return 'Visa';
    case CARD_BRAND.MASTERCARD: return 'Mastercard';
    case CARD_BRAND.AMEX: return 'American Express';
    case CARD_BRAND.DISCOVER: return 'Discover';
    case CARD_BRAND.JCB: return 'JCB';
    case CARD_BRAND.DINERS: return 'Diners Club';
    case CARD_BRAND.EFTPOS_AU: return 'eftpos'; // au payment method: https://auspayplus.com.au/brands/eftpos
    default: return 'Unknown';
  }
}

export function getCardBrandIcon(brand: CARD_BRAND): any {
  switch (brand) {
    case CARD_BRAND.VISA: return faCcVisa;
    case CARD_BRAND.MASTERCARD: return faCcMastercard;
    case CARD_BRAND.AMEX: return faCcAmex;
    case CARD_BRAND.DISCOVER: return faCcDiscover;
    case CARD_BRAND.JCB: return faCcJcb;
    case CARD_BRAND.DINERS: return faCcDinersClub;
    default: return faCreditCard;
  }
}

export function hasSubscriptionProblems(subscription: Subscription) {
  return subscription.stripeStatus === STRIPE_STATUS.PAST_DUE ||
    subscription.stripeLatestFailedPayment;
}

export function generateRandomString(len: number) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < len; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return randomString;
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function getFullName(user: {firstName: string, lastName: string} | null) {
  if (!user) return '';
  return `${user.firstName} ${user.lastName}`;
}

export function convertDateFormatToDateFns(format: string) {
  switch (format) {
    case 'MMM D, YYYY': return 'MMM d, yyyy';
    case 'MMM D YYYY': return 'MMM d yyyy';
    case 'D MMM YYYY': return 'd MMM yyyy';
    case 'D. MMM YYYY': return 'd. MMM yyyy';
    case 'YYYY年M月D日': return 'yyyy年M月d日';
    case 'MM/DD/YYYY': return 'MM/dd/yyyy';
    case 'DD/MM/YYYY': return 'dd/MM/yyyy';
    case 'YYYY-MM-DD': return 'yyyy-MM-dd';
    default: return 'MMM d, yyyy';
  }
}

export const DATE_FORMATS: string[] = [
  'MMM D, YYYY',
  'MMM D YYYY',
  'D MMM YYYY',
  'D. MMM YYYY',
  'YYYY年M月D日',
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'YYYY-MM-DD',
];

export const TIME_FORMATS: string[] = [
  'h:mm A',
  'H:mm',
  'HH:mm',
];

export function getTimeFormatLabel(format: string, formatMessage: (messageDescriptor: MessageDescriptor, values?: any) => string) {
  switch (format) {
    case 'h:mm A': return formatMessage(MSG_timeFormatLabel12Hour);
    case 'H:mm': return formatMessage(MSG_timeFormatLabel24Hour);
    case 'HH:mm': return formatMessage(MSG_timeFormatLabel24Hour);
    default: return formatMessage(MSG_timeFormatLabel12Hour);
  }
}

export const NUMBER_FORMATS: string[] = [
  '1,234.56',
  '1.234,56',
  '1 234.56',
  '1 234,56',
];

export function isDateFormatMonthFirst(format: string) {
  if (['D MMM YYYY', 'D. MMM YYYY', 'DD/MM/YYYY'].includes(format)) {
    return false;
  }
  return true;
}

export function parseDateInputString(value: string, subscription: Subscription) {
  if (!value) {
    return
  }

  let monthFirst = isDateFormatMonthFirst(subscription.dateFormat);
  let str = value;
  let matches, date;

  if (str.match(/[\/\\]/)) {
    let parts = str.split(/[\/\\]/);
    if (parts.length === 2) { parts[2] = "2000"; }
    if (parts[2].length === 2) { parts[2] = "20"+parts[2]; }
    if (monthFirst) {
      date = dayjs(parts.join('/'), 'M/D/YYYY');
    } else {
      date = dayjs(parts.join('/'), 'D/M/YYYY');
    }
  } else if (
    (matches = str.match(/^(\d{1})(\d{1})$/)) ||
    (matches = str.match(/^(\d{1})(\d{2})$/)) ||
    (matches = str.match(/^(\d{2})(\d{2})$/))
  ) {
    if (monthFirst) {
      str = matches[1]+'/'+matches[2]+'/2000';
    } else {
      str = matches[2]+'/'+matches[1]+'/2000';
    }
    date = dayjs(str, 'M/D/YYYY');
  } else if (matches = str.match(/^(\d{2})(\d{2})(\d{2})$/)) {
    if (monthFirst) {
      str = matches[1]+'/'+matches[2]+'/20'+matches[3];
    } else {
      str = matches[2]+'/'+matches[1]+'/20'+matches[3];
    }
    date = dayjs(str, 'M/D/YYYY');
  } else if (matches = str.match(/^(\d{2})(\d{2})(\d{4})$/)) {
    if (monthFirst) {
      str = matches[1] + '/' + matches[2] + '/' + matches[3];
    } else {
      str = matches[2] + '/' + matches[1] + '/' + matches[3];
    }
    date = dayjs(str, 'M/D/YYYY');
  } else if (matches = str.match(/^(\d{2,4})-((\d{1,2})(-(\d{1,2}))?)?$/)) {
    let year = matches[1].length === 2 ? '20' + matches[1] : matches[1];
    str = year + '-' + (matches[3] || '01') + '-' + (matches[5] || '01');
    date = dayjs(str, 'YYYY-MM-DD');
  } else {
    date = dayjs(str);
  }

  if (date.year() < 2005) {
    date = date.year(dayjs().year());
    if (date.isAfter(dayjs().add(6, 'month'))) {
      date = date.year(dayjs().year() - 1);
    }
  }

  if (date.isValid()) {
    return date;
  }

  return null;
}


export function getWebVersionStr() {
  return `${NB_CONFIG.webVersion.major}.${NB_CONFIG.webVersion.minor}.${NB_CONFIG.webVersion.patch}`;
}

export function getMobileVersionStr() {
  return `${NB_CONFIG.mobileVersion.major}.${NB_CONFIG.mobileVersion.minor}.${NB_CONFIG.mobileVersion.patch}`;
}

let mobileDeviceInfo: IDeviceInfo | null = null;
export function cacheMobileDeviceInfo(obj: IDeviceInfo) {
  mobileDeviceInfo = obj;
}

export function getMobileDeviceInfo() {
  return mobileDeviceInfo;
}
