import { baseApi } from "../base_api";
import { IncomeSource } from "../../../utils/api_types";
import { fromApiIncomeSource, toApiIncomeSource } from "../../../utils/api_transformations";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getIncomeSourcesForLedger: builder.query<{
      totalCount: number,
      page: number,
      incomeSources: IncomeSource[]
    }, {
      ledgerId?: string,
      page?: number,
      per?: number
    }>({
      query: (args) => {
        let params = new URLSearchParams();
        params.append('page', `${args.page || 1}`);
        params.append('per', `${args.per || 50}`);
        return ({
          url: `/ledgers/${args.ledgerId}/income_sources?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return {
          totalCount: response['total_count'],
          page: response['page'],
          incomeSources: response.items.map(fromApiIncomeSource)
        }
      }
    }),

    updateIncomeSource: builder.mutation<IncomeSource, {ledgerId: string, incomeSource: Partial<IncomeSource>}>({
      query: ({ledgerId, incomeSource}) => ({
        url: `/ledgers/${ledgerId}/income_sources/${incomeSource.id}?expand[]=allocations`,
        method: 'PATCH',
        body: {'income_source': toApiIncomeSource(incomeSource)},
      }),
      transformResponse: (response: any) => {
        return fromApiIncomeSource(response['income_source']);
      },
    }),

    createIncomeSource: builder.mutation<IncomeSource, {ledgerId: string, incomeSource: Partial<IncomeSource>}>({
      query: ({ledgerId, incomeSource}) => ({
        url: `/ledgers/${ledgerId}/income_sources?expand[]=allocations`,
        method: 'POST',
        body: {'income_source': toApiIncomeSource(incomeSource)},
      }),
      transformResponse: (response: any) => {
        return fromApiIncomeSource(response['income_source']);
      },
    }),

    deleteIncomeSource: builder.mutation<{success: boolean}, {ledgerId: string, incomeSourceId: string}>({
      query: ({ledgerId, incomeSourceId}) => ({
        url: `/ledgers/${ledgerId}/income_sources/${incomeSourceId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: any) => {
        return {success: true};
      },
    }),
  })
});

export const {
  useGetIncomeSourcesForLedgerQuery, useLazyGetIncomeSourcesForLedgerQuery,
  useUpdateIncomeSourceMutation,
  useCreateIncomeSourceMutation,
  useDeleteIncomeSourceMutation
} = extendedApi;
