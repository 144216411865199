import * as React from "react";
import { Modal } from "@web/components/Modal";
import { StyledWindowContainer } from "@web/components/styled/StyledWindowContainer";
import styled from "styled-components";
import { darken, lighten } from "color2k";
import { useIntlFormatters } from "shared/utils/formatters";
import { getTransactionKindLabelMessage } from "shared/utils/helpers";
import { Account, ApiError, Ledger, ROLE, Transaction, User } from "shared/utils/api_types";
import { Button } from "@web/components/Button";
import { EnvelopeGrid } from "@web/components/shared/EnvelopeGrid";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EnvelopeInput } from "./EnvelopeInput";
import { TextInput } from "@web/components/inputs/TextInput";
import {
  MSG_allocatedDiff,
  MSG_amountLabel,
  MSG_amountMovedLabel,
  MSG_amountTransferredLabel,
  MSG_archivedEnvelopesExplanation,
  MSG_archivedEnvelopesTitle,
  MSG_arithmeticTip,
  MSG_cannotCreateTransactionWithoutAccounts,
  MSG_checkNumberShortLabel,
  MSG_dateLabel,
  MSG_descriptionLabel,
  MSG_fromAccountLabel,
  MSG_importedOnDetails,
  MSG_incomeSourceNotes,
  MSG_manuallyEnteredOnDetails,
  MSG_movingMoneyBetweenEnvelopes,
  MSG_movingMoneyExplanation,
  MSG_movingMoneyQuestion,
  MSG_mustHaveTwoAccounts,
  MSG_notesLabel,
  MSG_nothingAllocatedConfirmation,
  MSG_percentTip,
  MSG_receivedFromLabel,
  MSG_sentToLabel,
  MSG_toAccountLabel,
  MSG_transferMoneyExplanation,
  MSG_transferMoneyQuestion,
  MSG_transferToFrom,
  MSG_useIncomeAllocationsButton
} from "shared/strings/transactions";
import { INumberInput, NumberInput } from "@web/components/inputs/NumberInput";
import dayjs from "dayjs";
import { FormElement } from "@web/components/forms/FormElement";
import { DateInput } from "@web/components/inputs/DateInput";
import { CurrencyInput } from "@web/components/inputs/CurrencyInput";
import { usePopper } from "react-popper";
import arrowImg from "shared/images/drawn-arrow-red.png";
import { parseApiError } from "shared/utils/api_errors";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TransactionModalContext, transactionModalContextValue } from "shared/utils/transaction_modal_context";
import classNames from "classnames";
import {
  MSG_closeButton,
  MSG_deleteButton,
  MSG_saveAndCloseButton,
  MSG_saveAndNewButton,
  MSG_saveButton,
  MSG_unknownLabel
} from "shared/strings/generic";
import { TransactionKindPopover } from "@web/components/selectors/TransactionKindPopover";
import { IPopover } from "@web/components/Popover";
import { faLightbulb } from "@fortawesome/pro-light-svg-icons/faLightbulb";
import {
  DeleteTransactionConfirmationPopover,
  IDeleteTransactionConfirmationPopover
} from "@web/components/selectors/DeleteTransactionConfirmationPopover";
import { IncomeSourceSequencePopover } from "@web/components/selectors/IncomeSourceSequencePopover";
import { useReloadLedger } from "shared/hooks/use_reload";
import {
  selectAccountsByLedger,
  selectActiveEnvelopesForLedger,
  selectCurrentSubscription,
  selectCurrentUser,
  selectLedger,
  selectLedgers
} from "shared/state/store";
import { useAlert } from "@web/utils/hooks";
import { ProfilePicture } from "@web/components/shared/ProfilePicture";
import { EmptyEnvelopeListExplanation } from "@web/components/shared/EmptyEnvelopeListExplanation";
import { useSaveTransaction } from "shared/utils/hooks";
import { SelectInput } from "@web/components/inputs/SelectInput";
import { sortBy } from "lodash";
import { ChangeAccountButton } from "@web/modals/transaction_editor_modal/ChangeAccountButton";

interface IProps {
  newTransactionEditorModal?: ITransactionEditorModal;
}

interface ITransactionEditorModal {
  show: (transaction: Partial<Transaction>, onClose?: () => void) => any;
  hide: () => any;
}

const TransactionEditorModal = React.forwardRef<ITransactionEditorModal, IProps>((props: IProps, ref) => {
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const transactionModalContext = React.useContext(TransactionModalContext);
  const amountInputRef = React.useRef<INumberInput>(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<Partial<Transaction> | null>(null);
  const [description, setDescription] = React.useState<string>('');
  const ledgers = useSelector(selectLedgers);
  const accountsByLedger = useSelector(selectAccountsByLedger);
  const ledger = useSelector(selectLedger(ledgerId));
  const accounts = sortBy(Object.values(accountsByLedger[ledgerId]), 'sequenceNumber');
  const [accountId, setAccountId] = React.useState<string>(accounts[0]?.id);
  const account = accountsByLedger[ledgerId]?.[accountId];
  const envelopes = useSelector(selectActiveEnvelopesForLedger(ledgerId));
  const subscription = useSelector(selectCurrentSubscription)!;
  const {formatMessage, formatCurrency, formatDate, formatOrdinal} = useIntlFormatters();
  const [targetEl, setTargetEl] = React.useState<any>(null);
  const [popperEl, setPopperEl] = React.useState<any>(null);
  const [arrowEl, setArrowEl] = React.useState<any>(null);
  const [error, setError] = React.useState<ApiError | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [nextAction, setNextAction] = React.useState<'new' | 'close'>('close');
  const newTransactionKindRef = React.useRef<IPopover>(null);
  const newTransactionButtonRef = React.useRef<any>(null);
  const descriptionInputRef = React.useRef<HTMLInputElement>(null);
  const deleteButtonRef = React.useRef<HTMLInputElement>(null);
  const deleteButtonPopoverRef = React.useRef<IDeleteTransactionConfirmationPopover>(null);
  const incomeSourcesRef = React.useRef<HTMLInputElement>(null);
  const incomeSourcesPopoverRef = React.useRef<IPopover>(null);
  const [descriptionEntered, setDescriptionEntered] = React.useState<boolean>(false);
  const [toLedger, setToLedger] = React.useState<Ledger | null>(null);
  const [toAccount, setToAccount] = React.useState<Account | null>(null);
  const toLedgerEnvelopes = useSelector(selectActiveEnvelopesForLedger(toAccount?.ledgerId || ''));
  const [transferActiveAccountId, setTransferActiveAccountId] = React.useState<string | undefined>(ledgerId);
  const currentUser = useSelector(selectCurrentUser) as User;
  const reloadAccount = useReloadLedger();
  const saveTransaction = useSaveTransaction();
  const [onClose, setOnClose] = React.useState<undefined | (() => void)>(undefined);
  const {showAlert} = useAlert();
  const {styles: popperStyles, attributes: popperAttributes, forceUpdate} = usePopper(targetEl, popperEl, {
    placement: 'bottom',
    modifiers: [
      {name: 'offset', options: {offset: [0, 8]}},
      {name: 'arrow', options: {element: arrowEl}}
    ],
  });

  let numVisibleEnvelopes = envelopes.length;
  if (transaction?.kind === 'transfer' && transferActiveAccountId !== ledgerId) {
    numVisibleEnvelopes = toLedgerEnvelopes.length;
  }

  const allocations: {[envelopeId: string]: number} = {};
  let allocatedAmount = 0, allocatedNegativeAmount = 0;
  transaction?.allocations?.forEach(a => {
    if (a.amount) {
      allocations[a.envelopeId] = a.amount;
      allocatedAmount += a.amount;
    }
    if (a.amount < 0) {
      allocatedNegativeAmount += Math.abs(a.amount);
    }
  });
  let allocatedDiff = allocatedAmount - (transaction?.amount || 0);

  const toAllocations: {[envelopeId: string]: number} = {};
  let toAllocatedAmount = 0;
  transaction?.toAllocations?.forEach(a => {
    if (a.amount) {
      toAllocations[a.envelopeId] = a.amount;
      toAllocatedAmount += a.amount;
    }
  });
  let toAllocatedDiff = toAllocatedAmount - (transaction?.amount || 0);

  React.useEffect(() => {
    const timer = setTimeout(() => { if (forceUpdate) forceUpdate(); });
    return () => clearTimeout(timer);
  }, [allocatedDiff]);

  React.useEffect(() => {
    if (!transaction?.amount) return;
    if (transaction.amount > 0) {
      setTransaction({...transaction, kind: 'income'});
    } else if (transaction.amount < 0) {
      setTransaction({...transaction, kind: 'expense'});
    }
  }, [transaction?.amount]);

  React.useEffect(() => {
    if (!descriptionEntered) {
      if (transaction?.kind === 'transfer') {
        setDescription(formatMessage(MSG_transferToFrom, {
          fromLedgerName: ledger.name,
          toLedgerName: toLedger?.name,
          toAccountName: toAccount?.name,
          fromAccountName: ledger.name
        }));
      }
    }
  }, [transaction?.kind, toAccount, ledger]);

  React.useImperativeHandle<any, ITransactionEditorModal>(ref, () => ({
    show: (t: Partial<Transaction>, onClose?: () => void) => {
      if (accounts.length === 0) {
        showAlert(formatMessage(MSG_cannotCreateTransactionWithoutAccounts));
        return;
      }

      let localTransaction = {...t};
      if (!localTransaction.accountId) localTransaction.accountId = accounts[0].id;
      if (!localTransaction.date) localTransaction.date = transactionModalContextValue.defaultDate;
      if (!localTransaction.amount) localTransaction.amount = 0;
      if (!localTransaction.description) localTransaction.description = '';
      if (!localTransaction.notes) localTransaction.notes = '';
      if (!localTransaction.allocated) localTransaction.allocated = false;
      if (!localTransaction.reconciled) localTransaction.reconciled = false;

      setOnClose(onClose);
      setError(null);
      setSaving(false);
      setDescription(localTransaction.description || '');
      setDescriptionEntered(localTransaction.description ? true : false);
      setAccountId(localTransaction.accountId);
      setTransaction(localTransaction);
      if (localTransaction.kind === 'transfer') {
        let numAccounts = Object.values(accountsByLedger).reduce((acc, val) => acc + Object.keys(val).length, 0);
        if (numAccounts < 2) {
          showAlert(formatMessage(MSG_mustHaveTwoAccounts));
          return;
        }
        let allAccounts = Object.values(accountsByLedger).reduce((acc, val) => [...acc, ...Object.values(val)], [] as Account[]);
        let _toAccount = allAccounts.filter(a => a.id !== localTransaction.accountId)?.[0];
        let _toLedger = ledgers.find(l => l.id === _toAccount.ledgerId)!;
        setToLedger(_toLedger);
        setToAccount(_toAccount);
        setTransferActiveAccountId(ledgerId);
      } else if (localTransaction.kind === 'move' && !localTransaction.description) {
        setDescription(formatMessage(MSG_movingMoneyBetweenEnvelopes));
      }
      show();

      setTimeout(() => {
        descriptionInputRef.current?.select();
        descriptionInputRef.current?.focus();
      });
    },

    hide: () => {
      hide();
    }
  }));

  function show() {
    setVisible(true);
  }

  function hide() {
    setVisible(false);
    onClose?.();
  }

  async function save(localNextAction: 'new' | 'close' = 'close') {
    if (!allocatedAmount && transaction?.amount !== 0) {
      if (!window.confirm(formatMessage(MSG_nothingAllocatedConfirmation))) return;
    }

    setNextAction(localNextAction || 'close');
    setSaving(true);
    try {
      let localTransaction = {
        ...transaction,
        accountId: accountId,
        description: description,
      };
      await saveTransaction(ledgerId, localTransaction, allocations, {toAccount: toAccount || undefined, allocatedAmount});
      void transactionModalContext.reloadParent();
      void reloadAccount(ledgerId);
      hide();
    } catch (e) {
      setError(parseApiError(e));
      setSaving(false);
    }
  }

  async function afterDelete() {
    await transactionModalContext.reloadParent();
    hide();
  }

  if (!ledger || !envelopes || !transaction || !account) return null;

  let descriptionLabel: string;
  if (transaction.kind === 'income') {
    descriptionLabel = formatMessage(MSG_receivedFromLabel);
  } else if (transaction.kind === 'expense') {
    descriptionLabel = formatMessage(MSG_sentToLabel);
  } else {
    descriptionLabel = formatMessage(MSG_descriptionLabel);
  }

  return (
    <Modal
      isOpen={visible}
      defaultFormAction={save}
      onRequestClose={hide}
      windowStyle={{width: '65rem', minHeight: '10rem'}}
      bodyStyle={{padding: transaction.kind === 'transfer' ? '0 0 1rem 0' : '1rem 0'}}
      customContainer={(children) => (
        <React.Fragment>
          <RibbonContainer className={transaction.kind}>
            <div className="ribbon-bg ribbon-top"/>
            <div className="ribbon-bg ribbon-middle"/>
            <div className="ribbon-bg ribbon-left"/>
            <div className="ribbon-bg ribbon-right"/>
            <div className="text-label">
              {formatMessage(getTransactionKindLabelMessage(transaction?.kind || 'expense'))}
            </div>
          </RibbonContainer>
          <WindowContainer className={transaction.kind}>
            {children}
          </WindowContainer>
        </React.Fragment>
      )}
      header={
        <React.Fragment>
          <div className="d-flex flex-row">
            <FormElement label={descriptionLabel}
                         className="flex-grow-5 me-2">
              <TextInput
                tabIndex={10}
                ref={descriptionInputRef}
                value={description || ''}
                disabled={saving || currentUser.role === ROLE.OBSERVER}
                onChange={e => {
                  setDescription(e.target.value);
                  setDescriptionEntered(true);
                }}
              />
            </FormElement>

            {transaction.kind === 'expense' && (
              <FormElement className="flex-grow-1 flex-shrink-1 me-2"
                           label={formatMessage(MSG_checkNumberShortLabel)}>
                <NumberInput
                  tabIndex={20}
                  hideSeparators
                  blankZeros
                  disabled={currentUser.role === ROLE.OBSERVER}
                  value={transaction.checkNumber || null}
                  onChange={num => setTransaction({...transaction, checkNumber: num})}
                />
              </FormElement>)}

            <FormElement style={{width: '10rem'}} className="me-2"
                         label={formatMessage(MSG_dateLabel)}>
              <DateInput
                tabIndex={30}
                value={dayjs(transaction.date)}
                disabled={currentUser.role === ROLE.OBSERVER}
                onChange={(date) => {
                  if (!date.isValid()) return;
                  transactionModalContextValue.setDefaultDate(date.format('YYYY-MM-DD'));
                  setTransaction({
                    ...transaction,
                    date: date.format('YYYY-MM-DD')
                  });
                }}
              />
            </FormElement>

            <FormElement style={{width: '10rem'}}
                         label={formatMessage(
                           transaction.kind === 'move' ? MSG_amountMovedLabel :
                             (transaction.kind === 'transfer' ? MSG_amountTransferredLabel : MSG_amountLabel))}
                         ref={setTargetEl}>
              {transaction.kind === 'move' || transaction.kind === 'transfer' ? (
                <CurrencyInput
                  value={(transaction.kind === 'move' ? allocatedNegativeAmount : -1 * allocatedAmount) || 0}
                  tabIndex={40}
                  disabled={true}
                  onChange={num => null}
                />
              ) : (
                <CurrencyInput
                  value={transaction.amount || 0}
                  tabIndex={40}
                  ref={amountInputRef}
                  disabled={currentUser.role === ROLE.OBSERVER}
                  onChange={num => {
                    setTransaction({...transaction, amount: num});
                  }}
                  onFocus={(e) => {
                    if (!transaction.amount) {
                      if (transaction?.kind === 'expense' && !allocatedAmount) {
                        amountInputRef.current?.forceNegativeDefault();
                      } else {
                        amountInputRef.current?.forceValue(allocatedAmount / 100);
                        amountInputRef.current?.inputRef?.current?.select();
                      }
                    }
                    setTimeout(() => {
                      amountInputRef.current?.select();
                    });
                  }}
                />
              )}
            </FormElement>
          </div>
          <div className="d-flex flex-row">
            <FormElement className={classNames("mb-0 me-2", {'flex-grow-1': ['move', 'transfer', 'income'].includes(transaction.kind as string), 'flex-grow-2': !['move', 'transfer', 'income'].includes(transaction.kind as string)})}>
              <TextInput
                rows={2}
                tabIndex={50}
                value={transaction.notes || ''}
                placeholder={formatMessage(MSG_notesLabel)}
                disabled={currentUser.role === ROLE.OBSERVER}
                onChange={e => setTransaction({...transaction, notes: e.target.value})}
              />
            </FormElement>
            <div className="flex-row align-items-start flex-grow-1">
              {(transaction.kind === 'income' || transaction.kind === 'expense') && (
                <div className="">
                  <SelectInput name="accountId" style={{backgroundColor: '#fff'}}
                               className="me-2"
                               style={{maxWidth: '12rem'}}
                               value={accountId}
                               onChange={(e) => {
                                 setAccountId(e.target.value);
                               }}>
                    {Object.values(accounts).map(account => (
                      <option key={account.id} value={account.id}>{account.name}</option>
                    ))}
                  </SelectInput>
                </div>
              )}

              {transaction.kind === 'income' && currentUser.role !== ROLE.OBSERVER && (
                <React.Fragment>
                  <div className="flex-grow-1"/>
                  <Button ref={incomeSourcesRef}
                          className="me-2"
                          onClick={() => incomeSourcesPopoverRef.current?.show()}>
                    {formatMessage(MSG_useIncomeAllocationsButton)}
                  </Button>
                  <IncomeSourceSequencePopover
                    ref={incomeSourcesPopoverRef}
                    targetRef={incomeSourcesRef}
                    ledgerId={ledger.id}
                    onSelect={(incomeSource, sequenceNumber, incomeAllocations) => {
                      let newTransaction = {
                        ...transaction,
                        description: incomeSource.name,
                        notes: formatMessage(MSG_incomeSourceNotes, {ordinal: formatOrdinal(sequenceNumber), incomeName: incomeSource.name})
                      };
                      if (!transaction.amount) {
                        newTransaction.amount = incomeSource.amount;
                      }
                      newTransaction.allocations = incomeAllocations
                        .filter(a => {
                          let env = envelopes.find(e => e.id === a.envelopeId);
                          return env && !env.archived;
                        })
                        .map(a => ({
                          envelopeId: a.envelopeId,
                          amount: a.method === 'percent' ? Math.floor((newTransaction?.amount || 0) * (a.amount / 10000)) : a.amount,
                        }));
                      setTransaction(newTransaction);
                      setDescription(newTransaction.description || '');
                    }}
                  />
                </React.Fragment>)}

              {transaction.kind === 'move' && (
                <div className="text-small">
                  <b>{formatMessage(MSG_movingMoneyQuestion)}</b>{' '}
                  {formatMessage(MSG_movingMoneyExplanation, {amount: formatCurrency(0, subscription)})}
                </div>)}

              {transaction.kind === 'transfer' && (
                <div className="text-small">
                  <b>{formatMessage(MSG_transferMoneyQuestion)}</b>{' '}
                  {formatMessage(MSG_transferMoneyExplanation)}
                </div>)}
            </div>
          </div>
          {error?.errorType === 'message' && (
            <div className="d-flex flex-row">
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationTriangle}/>
                <div>{error.message}</div>
              </ErrorMessage>
            </div>
          )}
          {error?.errorType === 'models' && error.errors.transaction?.base && (
            <div className="d-flex flex-row">
              {error.errors.transaction.base.map((str, i) => (
                <ErrorMessage key={i}>
                  <FontAwesomeIcon icon={faExclamationTriangle}/>
                  <div>{str}</div>
                </ErrorMessage>
              ))}
            </div>
          )}
          {error?.errorType === 'models' && error.errors['to_transaction']?.base && (
            <div className="d-flex flex-row">
              {error.errors['to_transaction'].base.map((str, i) => (
                <ErrorMessage key={i}>
                  <FontAwesomeIcon icon={faExclamationTriangle}/>
                  <div>{str}</div>
                </ErrorMessage>
              ))}
            </div>
          )}

          {transaction.kind === 'transfer' ? (
            !!(allocatedDiff + toAllocatedDiff) && Object.keys(allocations).length > 0 && (
              <AllocationNoticeContainer ref={setPopperEl} style={popperStyles.popper}{...popperAttributes.popper}>
                <div className="explanation">
                  {formatMessage(MSG_allocatedDiff, {amount: <b>{formatCurrency(-1 * allocatedDiff - toAllocatedDiff, subscription)}</b>})}
                </div>
                <div ref={setArrowEl} className="arrow" style={popperStyles.arrow} />
              </AllocationNoticeContainer>)
          ) : (
            !!allocatedDiff && Object.keys(allocations).length > 0 && (
              <AllocationNoticeContainer ref={setPopperEl} style={popperStyles.popper}{...popperAttributes.popper}>
                <div className="explanation">
                  {formatMessage(MSG_allocatedDiff, {amount: <b>{formatCurrency(-1 * allocatedDiff, subscription)}</b>})}
                </div>
                <div ref={setArrowEl} className="arrow" style={popperStyles.arrow} />
              </AllocationNoticeContainer>)
          )}
        </React.Fragment>
      }
      footer={
        <div className="d-flex flex-row justify-content-space-between align-items-center">
          {transaction.id ? (
            <div className="d-flex flex-row align-items-center">
              {currentUser.role !== ROLE.OBSERVER && (
                <Button color="danger" className="me-8" tabIndex={10000} ref={deleteButtonRef}
                        onClick={() => deleteButtonPopoverRef.current?.show(transaction.id as string)}>
                  {formatMessage(MSG_deleteButton)}
                </Button>)}

              <DeleteTransactionConfirmationPopover
                ref={deleteButtonPopoverRef}
                targetRef={deleteButtonRef}
                ledgerId={ledgerId}
                afterDelete={afterDelete}
              />

              <div className="flex-row align-items-center">
                {transaction.user && <ProfilePicture size={'2.4rem'} imageUrl={transaction.user.profilePictureUrl}/>}
                <div className="ms-2">
                  {transaction.user && <div>{transaction.user.firstName} {transaction.user.lastName}</div>}
                  <div>
                    {transaction.imported
                      ? formatMessage(MSG_importedOnDetails, {
                        date: transaction.updatedAt ? formatDate(transaction.updatedAt, subscription) : formatMessage(MSG_unknownLabel)})
                      : formatMessage(MSG_manuallyEnteredOnDetails, {
                        date: transaction.updatedAt ? formatDate(transaction.updatedAt, subscription) : formatMessage(MSG_unknownLabel)})}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center">
              <FontAwesomeIcon icon={faLightbulb} style={{fontSize: '2rem'}} className="me-4"/>
              <div>
                <div>{formatMessage(MSG_arithmeticTip)}</div>
                <div>{formatMessage(MSG_percentTip)}</div>
              </div>
            </div>
          )}

          {currentUser.role === ROLE.OBSERVER ? (
            <div className="text-right">
              <Button color="secondary" onClick={() => setVisible(false)} className="me-2" disabled={saving} tabIndex={10000}>
                {formatMessage(MSG_closeButton)}
              </Button>
            </div>
          ) : (
            <div className="text-right">
              <Button color="secondary" onClick={() => setVisible(false)} className="me-2" disabled={saving} tabIndex={10000}>Cancel</Button>
              {transaction.id ? (
                <Button onClick={() => save('close')} spinner={saving} disabled={saving} tabIndex={10001}>{formatMessage(MSG_saveButton)}</Button>
              ) : (
                <React.Fragment>
                  <Button onClick={() => { newTransactionKindRef.current?.show(); }}
                          tabIndex={10001}
                          spinner={saving && nextAction === 'new'}
                          ref={newTransactionButtonRef}
                          disabled={saving}
                          className="me-2">
                    {formatMessage(MSG_saveAndNewButton)}
                  </Button>
                  <Button onClick={() => { void save('close'); }}
                          tabIndex={10002}
                          spinner={saving && nextAction !== 'new'}
                          disabled={saving}>
                    {formatMessage(MSG_saveAndCloseButton)}
                  </Button>

                  <TransactionKindPopover
                    ref={newTransactionKindRef}
                    targetRef={newTransactionButtonRef}
                    onSelect={async (kind) => {
                      await save('new');
                      props.newTransactionEditorModal?.show({kind});
                    }}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      }>
        {transaction.kind === 'transfer' ? (
          <div>
            <TabHeader>
              <Tab className={classNames({active: transferActiveAccountId === ledgerId})}
                   onClick={() => setTransferActiveAccountId(ledgerId)}>
                {formatMessage(MSG_fromAccountLabel, {ledgerName: ledger.name, accountName: account.name})}
              </Tab>
              <Tab className={classNames({active: transferActiveAccountId !== ledgerId})}
                   onClick={() => setTransferActiveAccountId(toAccount?.id)}>
                {formatMessage(MSG_toAccountLabel, {ledgerName: toLedger?.name, accountName: toAccount?.name})}

                <ChangeAccountButton
                  ledgerId={toLedger?.id || ''}
                  accountId={toAccount?.id || ''}
                  fromLedgerId={ledgerId}
                  fromAccountId={accountId}
                  onChange={(lid, aid) => {
                    setToLedger(ledgers.find(l => l.id === lid)!);
                    setToAccount(accountsByLedger[lid][aid]!)
                  }}
                />
              </Tab>
            </TabHeader>
            <div className="p-4" style={numVisibleEnvelopes === 0 ? {position: 'relative', minHeight: '22rem'} : {}}>
              {numVisibleEnvelopes === 0 && (
                <EmptyEnvelopeListExplanation onNavigate={hide} screen="transactionModal" absolute/>)}

              {transferActiveAccountId === ledgerId ? (
                <EnvelopeGrid
                  ledgerId={ledgerId}
                  includeArchived={transaction.allocations?.map(a => a.envelopeId)}
                  renderEnvelope={env => (
                    <EnvelopeInput
                      tabIndex={100 + (env.appY * 5 + env.appX)}
                      name={env.name}
                      icon={env.icon}
                      color={env.color}
                      amount={env.amount}
                      locked={env.locked}
                      unallocatedAmount={allocatedDiff}
                      showDirection={true}
                      defaultNegativeInput={true}
                      value={(env.id && allocations[env.id]) || 0}
                      totalValue={transaction.amount}
                      disabled={env.archived || currentUser.role === ROLE.OBSERVER}
                      customFocusValue={(lprops, lref) => {
                        if (!lprops.value) {
                          if (allocatedDiff <= 0) {
                            lref.current?.forceNegativeDefault();
                            return false;
                          }
                          lref.current?.forceValue(-1 * allocatedDiff / 100);
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      onChange={v => {
                        if (!env.id) return;
                        allocations[env.id] = v;
                        setTransaction({
                          ...transaction,
                          allocations: Object.keys(allocations).map(k => ({envelopeId: k, amount: allocations[k]}))
                        });
                      }}
                    />
                  )}/>
                ) : (
                  <EnvelopeGrid
                    ledgerId={toAccount?.ledgerId || ''}
                    includeArchived={transaction.toAllocations?.map(a => a.envelopeId)}
                    renderEnvelope={env => (
                      <EnvelopeInput
                        tabIndex={100 + (env.appY * 5 + env.appX)}
                        name={env.name}
                        color={env.color}
                        icon={env.icon}
                        amount={env.amount}
                        locked={env.locked}
                        disabled={env.archived || currentUser.role === ROLE.OBSERVER}
                        unallocatedAmount={toAllocatedAmount}
                        showDirection={true}
                        value={(env.id && toAllocations[env.id]) || 0}
                        totalValue={transaction.amount}
                        customFocusValue={(lprops, lref) => {
                          if (!lprops.value) {
                            if (allocatedDiff - toAllocatedDiff < 0) {
                              lref.current?.forceValue(-1 * (allocatedDiff + toAllocatedDiff) / 100);
                            }
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        onChange={v => {
                          if (!env.id) return;
                          toAllocations[env.id] = v;
                          setTransaction({
                            ...transaction,
                            toAllocations: Object.keys(toAllocations).map(k => ({envelopeId: k, amount: toAllocations[k]}))
                          });
                        }}
                      />
                    )}/>
                )}
            </div>
          </div>
        ) : (
          <div className="p-4 pt-2" style={numVisibleEnvelopes === 0 ? {position: 'relative', minHeight: '22rem'} : {}}>
            {numVisibleEnvelopes === 0 && (
              <EmptyEnvelopeListExplanation onNavigate={hide} screen="transactionModal" absolute/>)}

            <EnvelopeGrid
              ledgerId={ledgerId}
              includeArchived={transaction.allocations?.map(a => a.envelopeId)}
              archivedHeader={formatMessage(MSG_archivedEnvelopesTitle)}
              archivedSubheader={formatMessage(MSG_archivedEnvelopesExplanation)}
              renderEnvelope={env => (
                <EnvelopeInput
                  defaultNegativeInput={transaction.kind === 'expense' || transaction.kind === 'move'}
                  showDirection={transaction.kind === 'move'}
                  tabIndex={100 + (env.appY * 5 + env.appX)}
                  name={env.name}
                  color={env.color}
                  icon={env.icon}
                  amount={env.amount}
                  locked={env.locked}
                  disabled={env.archived || currentUser.role === ROLE.OBSERVER}
                  unallocatedAmount={allocatedDiff}
                  value={(env.id && allocations[env.id]) || 0}
                  totalValue={transaction.amount}
                  onChange={v => {
                    if (!env.id) return;
                    allocations[env.id] = v;
                    setTransaction({
                      ...transaction,
                      allocations: Object.keys(allocations).map(k => ({envelopeId: k, amount: allocations[k]}))
                    });
                  }}
                />
              )}/>
          </div>
        )}

    </Modal>
  );
});

const RibbonContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0.5rem;
  left: -2.5rem;
  right: 100%;
  height: 15rem;

  .ribbon-bg {
    position: absolute;
    background: ${({theme}) => lighten(theme.colors.incomeColor, 0.1) };
  }

  .ribbon-top {
    top: -1.5rem;
    left: 0;
    width: 4rem;
    height: 4rem;
    transform-origin: bottom left;
    transform: rotate(45deg);
    border-top: 1px solid ${({theme}) => darken(theme.colors.incomeColor, 0.3) };
    background: ${({theme}) => darken(theme.colors.incomeColor, 0.2) };
  }

  .ribbon-middle {
    top: 2.5rem;
    left: 0;
    right: 0;
    bottom: 1.5rem;
  }

  .ribbon-left {
    transform: rotate(-45deg);
    transform-origin: bottom left;
    width: 2rem;
    height: 2rem;
    bottom: 0;
  }

  .ribbon-right {
    transform: rotate(45deg);
    transform-origin: bottom right;
    width: 2rem;
    height: 2rem;
    right: 0;
    bottom: 0;
  }

  .text-label {
    position: absolute;
    top: 3.5rem;
    right: 100%;
    white-space: nowrap;
    color: #fff;
    transform: rotate(270deg);
    transform-origin: right top;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.5rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  }

  &.expense {
    .ribbon-bg {
      background: ${({theme}) => darken(theme.colors.expenseColor, 0.1) };
    }

    .ribbon-top {
      border-top: 1px solid ${({theme}) => darken(theme.colors.expenseColor, 0.3) };
      background: ${({theme}) => darken(theme.colors.expenseColor, 0.2) };
    }
  }

  &.income {
    .ribbon-bg {
      background: ${({theme}) => lighten(theme.colors.incomeColor, 0.05) };
    }

    .ribbon-top {
      border-top: 1px solid ${({theme}) => darken(theme.colors.incomeColor, 0.2) };
      background: ${({theme}) => darken(theme.colors.incomeColor, 0.1) };
    }
  }

  &.move {
    .ribbon-bg {
      background: ${({theme}) => darken(theme.colors.moveColor, 0.1) };
    }

    .ribbon-top {
      border-top: 1px solid ${({theme}) => darken(theme.colors.moveColor, 0.3) };
      background: ${({theme}) => darken(theme.colors.moveColor, 0.2) };
    }
  }

  &.transfer {
    .ribbon-bg {
      background: ${({theme}) => darken(theme.colors.transferColor, 0.1) };
    }

    .ribbon-top {
      border-top: 1px solid ${({theme}) => darken(theme.colors.transferColor, 0.3) };
      background: ${({theme}) => darken(theme.colors.transferColor, 0.2) };
    }
  }
`;

const WindowContainer = styled(StyledWindowContainer)`
  padding: 0;
  border-color: ${({theme}) => theme.colors.expenseColor };
  min-height: 20rem;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  &.expense {
    border-color: ${({theme}) => theme.colors.expenseColor };
  }

  &.income {
    border-color: ${({theme}) => theme.colors.incomeColor };
  }

  &.move {
    border-color: ${({theme}) => theme.colors.moveColor };
  }

  &.transfer {
    border-color: ${({theme}) => theme.colors.transferColor };
  }
`;

const AllocationNoticeContainer = styled.div`
  position: relative;
  border-radius: ${({theme}) => theme.inputContainer.borderRadius};
  background-color: ${({theme}) => theme.colors.danger};
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,0.5);
  padding: 0.5rem 1rem;
  color: #fff;
  text-align: center;

  .explanation {
    max-width: 20rem;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    background-color: ${({theme}) => theme.colors.danger};
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -4px;
  }
`;

const AllocationExplanationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ArrowImgContainer = styled.img.attrs({src: arrowImg})`
  position: absolute;
  top: -1.5rem;
  right: 0;
  transform: rotate(10deg);
  height: 9rem;
`;

const ExplanationContainer = styled.div`
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.2em;
  font-weight: bold;
  color: ${({theme}) => theme.colors.danger };;
  position: absolute;
  top: 0;
  right: 4rem;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  max-width: 15rem;
  transform: rotate(-3deg);
`;

const ErrorMessage = styled.div`
  margin-top: 0.5rem;
  color: ${({theme}) => theme.colors.danger };
  display: flex;
  flex-direction: row;
  align-items: center;
  div { margin-left: 0.5rem; }
`;

const TabHeader = styled.div`
  background-color: #aaa;
  color: ${({theme}) => theme.tabBar.textColor };
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
`;

const Tab = styled.div`
  padding: 0.5rem 1rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
  border-top-left-radius: ${({theme}) => theme.raisedContainer.borderRadius };
  border-top-right-radius: ${({theme}) => theme.raisedContainer.borderRadius };
  background-color: ${({theme}) => theme.tabBar.activeTabBackgroundColor };
  cursor: pointer;
  margin-right: 0.5rem;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
`;

export {TransactionEditorModal, ITransactionEditorModal};
