import { Transaction } from "shared/utils/api_types";
import * as React from "react";

export interface IAllocationModalContext {
  toggleSelection: (transaction: Transaction) => any;
  selected: Transaction[],
  setSelected: (selected: Transaction[]) => any;
  dragging: Transaction | null,
  setDragging: (transaction: Transaction | null) => any;
  working: Transaction[],
  setWorking: (working: Transaction[]) => any;
  reset: () => any,
}

let initialValue: IAllocationModalContext = {
  toggleSelection: (transaction) => null,
  selected: [],
  setSelected: (selected) => null,
  dragging: null,
  setDragging: (transaction) => null,
  working: [],
  setWorking: (working) => null,
  reset: () => null,
};

export const AllocationModalContext = React.createContext<IAllocationModalContext>(initialValue);

