import { ApiError, ROLE, User } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { parseApiError } from "shared/utils/api_errors";
import { Modal } from "@web/components/Modal";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import React from "react";
import { FormErrors } from "@web/components/forms/FormErrors";
import { MSG_firstNameLabel, MSG_lastNameLabel } from "shared/strings/signup";
import {
  MSG_changeProfilePictureButton,
  MSG_observerExplanation,
  MSG_observerLabel,
  MSG_ownerExplanation,
  MSG_ownerLabel,
  MSG_profilePictureLabel,
  MSG_roleLabel,
  MSG_stewardExplanation,
  MSG_stewardLabel,
  MSG_usedForLoginNotice
} from "shared/strings/users";
import { ProfilePicture } from "@web/components/shared/ProfilePicture";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserMutation } from "shared/state/endpoints/app/users_api";
import { selectCurrentUser, selectUser, setUser } from "shared/state/store";
import { MSG_emailFormLabel } from "shared/strings/login";
import {
  IProfilePictureEditorModal,
  ProfilePictureEditorModal
} from "../profile_picture_editor_modal/ProfilePictureEditorModal";

interface IProps {
}

interface IUserEditorModal  {
  show: (user: User) => any;
  hide: () => any;
}

const UserEditorModal = React.forwardRef<IUserEditorModal, IProps>((props: IProps, ref) => {
  const currentUser = useSelector(selectCurrentUser) as User;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [id, setId] = React.useState<string | undefined>(undefined);
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [owner, setOwner] = React.useState<boolean>(false);
  const [role, setRole] = React.useState<ROLE>(ROLE.STEWARD);
  const [updateUser] = useUpdateUserMutation();
  const {formatMessage} = useIntlFormatters();
  const dispatch = useDispatch();
  const profilePictureEditorModalRef = React.useRef<IProfilePictureEditorModal>(null);
  const user = useSelector(selectUser(id as string));

  React.useImperativeHandle<any, IUserEditorModal>(ref, () => ({
    show: (_user: User) => {
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
      setId(_user.id);
      setFirstName(_user.firstName);
      setLastName(_user.lastName);
      setEmail(_user.email);
      setRole(_user.role);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    try {
      setSaving(true);
      let user: User;
      user = await updateUser({user: {
        id, firstName, lastName, email, role
      }}).unwrap();
      dispatch(setUser(user));
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  return (
    <Modal
      isOpen={visible}
      defaultFormAction={save}
      onRequestClose={() => setVisible(false)}
      windowStyle={{width: '45rem'}}
      bodyStyle={{padding: '2rem'}}
      footer={
        <div className="text-right row-reverse">
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
          <Button color="secondary" disabled={saving}
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
        </div>
      }>

      <FormErrors errors={errors}/>

      <div className="flex-row align-items-start justify-content-start">
        <div className="text-center" style={{marginRight: '3rem'}}>
          <div onClick={() => profilePictureEditorModalRef.current?.show(user)} style={{cursor: 'pointer'}}>
            <FormElement errors={errors} errorPath="user.image_url"
                         label={formatMessage(MSG_profilePictureLabel)}>
              <ProfilePicture imageUrl={user?.profilePictureUrl} size={'10rem'}/>
            </FormElement>
          </div>
          <Button color="secondary" onClick={() => profilePictureEditorModalRef.current?.show(user)}>
            {formatMessage(MSG_changeProfilePictureButton)}
          </Button>
        </div>
        <div className="flex-grow-1">
          <FormElement errors={errors} errorPath="user.first_name"
                       label={formatMessage(MSG_firstNameLabel)}>
            <TextInput
              name="first_name"
              autoFocus
              value={firstName}
              onChange={e => setFirstName(e.target.value)}/>
          </FormElement>

          <FormElement errors={errors} errorPath="user.last_name"
                       label={formatMessage(MSG_lastNameLabel)}>
            <TextInput
              name="last_name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}/>
          </FormElement>

          <FormElement errors={errors} errorPath="user.email"
                       note={formatMessage(MSG_usedForLoginNotice)}
                       label={formatMessage(MSG_emailFormLabel)}>
            <TextInput
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}/>
          </FormElement>

          {currentUser.role === ROLE.OWNER && (
            <FormElement errors={errors} errorPath="user.role" label={formatMessage(MSG_roleLabel)}>
              <label className="has-checkbox">
                <input type="radio"
                       name="role"
                       checked={role === ROLE.OWNER}
                       disabled={currentUser.id === id}
                       onChange={e => setRole(e.target.checked ? ROLE.OWNER : ROLE.STEWARD)}/>
                <div>
                  <div>{formatMessage(MSG_ownerLabel)}</div>
                  <div className="text-muted">
                    {formatMessage(MSG_ownerExplanation)}
                  </div>
                </div>
              </label>
              <label className="has-checkbox mt-2">
                <input type="radio"
                       name="role"
                       checked={role === ROLE.STEWARD}
                       disabled={currentUser.id === id}
                       onChange={e => setRole(e.target.checked ? ROLE.STEWARD : ROLE.STEWARD)}/>
                <div>
                  <div>{formatMessage(MSG_stewardLabel)}</div>
                  <div className="text-muted">
                    {formatMessage(MSG_stewardExplanation)}
                  </div>
                </div>
              </label>
              <label className="has-checkbox mt-2">
                <input type="radio"
                       name="role"
                       checked={role === ROLE.OBSERVER}
                       disabled={currentUser.id === id}
                       onChange={e => setRole(e.target.checked ? ROLE.OBSERVER : ROLE.STEWARD)}/>
                <div>
                  <div>{formatMessage(MSG_observerLabel)}</div>
                  <div className="text-muted">
                    {formatMessage(MSG_observerExplanation)}
                  </div>
                </div>
              </label>
            </FormElement>
          )}
        </div>
      </div>

      <ProfilePictureEditorModal ref={profilePictureEditorModalRef}/>
    </Modal>
  );
});


export { UserEditorModal, IUserEditorModal };
