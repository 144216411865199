export const MSG_newAccountButton = {
  id: "6960714c-b922-4b84-acc3-1060d489badd",
  description: "The label for a button that lets them add a new account (bank, credit card, etc).",
  defaultMessage: "New Account"
};

export const MSG_editAccountButton = {
  id: "d8f9909e-28e3-4297-931a-05fb790262af",
  description: "The label for a button that lets them edit an existing account (bank, credit card, etc)",
  defaultMessage: "Edit Account"
};

export const MSG_deleteAccountButton = {
  id: "db33cea1-599e-44a2-bf1b-0a1467e94c23",
  description: "the label for a button that lets them delete an account",
  defaultMessage: "Delete Account"
};


export const MSG_accountNameLabel = {
  id: "1858f169-eda3-4978-8b6b-0019901538da",
  description: "The label for a form field where they can edit the account name.",
  defaultMessage: "Account name"
};


export const MSG_numUnallocatedTitle = {
  id: "de330b82-6bd5-440e-8ca8-f5f160b67028",
  description: "The label for a button that lets them see their transactions that have not been allocated to an envelope yet.",
  defaultMessage: "{count} Unallocated"
};

export const MSG_unallocatedLabel = {
  id: "5824ff49-e19d-4bfe-b421-0e676a4b5f81",
  description: "A label for a transaction to say that it has not yet been allocated to some envelopes.",
  defaultMessage: "Unallocated"
};

export const MSG_allAccountsLabel = {
  id: "c5b84def-3300-400c-9bf3-60979cc44e3f",
  description: "The label for a button that lets them view all their accounts in one place.",
  defaultMessage: "All accounts"
};


export const MSG_deleteAccountConfirmation = {
  id: "8d94461a-9276-4755-aebe-9a7de1fec7d3",
  description: "",
  defaultMessage: "Are you sure you want to delete this account?"
};

export const MSG_deleteAccountConfirmationExplanation = {
  id: "8d94461a-9276-4755-aebe-9a7de1fec7d3",
  description: "",
  defaultMessage: "Deleting this account will permanently remove all of its transactions as well.  Are you sure?"
};

export const MSG_yesDeleteIt = {
  id: "185dc765-fb3f-4913-a7b2-797c8334bb39",
  description: "A button label that confirms they want to delete an account.",
  defaultMessage: "Yes, delete it!"
};

export const MSG_emptyAccountTitle = {
  id: "24848e84-3d84-43a0-9f2b-611304a82e44",
  description: "",
  defaultMessage: "Add your own envelopes"
};

export const MSG_emptyAccountMessage = {
  id: "25aed917-43bd-4d4c-b4a6-ecaffa8a7334",
  description: "",
  defaultMessage: "NeoBudget uses envelopes to organize expenses.  Think of an envelope in NeoBudget as a real envelope full of cash. You spend money by taking it out of an envelope."
};

export const MSG_emptyManageEnvelopesTitle = {
  id: "0a60bea0-d2af-44c2-b148-28a71e8832b9",
  description: "",
  defaultMessage: "Add your first envelope"
};

export const MSG_emptyManageEnvelopesMessage = {
  id: "de6c3ff7-2ab0-4292-9e66-a219585d6a4e",
  description: "",
  defaultMessage: "This is where you can customize your budget by creating and organizing your own envelopes. If you don't know where to start click \"New Envelope\" for some suggestions."
};

export const MSG_noAccountsCreated = {
  id: "e060adc9-9cad-48ce-ab6a-b810d124d744",
  description: "",
  defaultMessage: "No accounts have been created."
};

export const MSG_selectAnAccountLabel = {
  id: "badeb133-5fed-4d92-9434-88426a905a71",
  description: "The label for a dropdown that lets them select an account.",
  defaultMessage: "Select an account"
};

export const MSG_addAnAccountMessage = {
  id: "29e2cf75-75f9-41c4-bd46-c2cc2dac9e4c",
  description: "",
  defaultMessage: "Add an account to get started."
};
