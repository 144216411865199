import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import * as React from "react";
import { StyledCard, StyledCardBody } from "../styled/StyledCard";

interface IProps<T> {
  className?: string;
  value: T;
  explanation?: string;
  options: {value: T, label: string, sublabel?: string}[];
  onChange: (value: T) => any;
}

const OptionSelectorProvider = <T extends unknown>() => {
  return (props: IProps<T>) => {
    return (
      <StyledCard className={`no-border ${props.className}`}>
        {props.explanation && <StyledCardBody>{props.explanation}</StyledCardBody>}

        {props.options.map((option, i) => (
          <StyledCardBody
            key={i}
            className={classnames('clickable flex-row align-items-center', {active: props.value === option.value})}
            onClick={() => props.onChange(option.value)}>

            {props.value === option.value
              ? <FontAwesomeIcon icon={faCheckCircle} className="text-primary"/>
              : <FontAwesomeIcon icon={faCircle}/>}

            <div className="ms-4">
              <div className="clickable-title">{option.label}</div>
              {option.sublabel && <div className="text-small">{option.sublabel}</div>}
            </div>

          </StyledCardBody>
        ))}
      </StyledCard>
    );
  }
};

export {OptionSelectorProvider};
