import * as React from "react";
import { PropsWithChildren } from "react";
import {
  StyledCardSettingsActions,
  StyledCardSettingsBody,
  StyledCardSettingsIcon,
  StyledCardSettingsLabel,
  StyledCardSettingsValue
} from "@web/components/styled/StyledCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faKeyboard } from "@fortawesome/pro-light-svg-icons";
import { ApiError, ROLE } from "shared/utils/api_types";
import { selectCurrentUser } from "shared/state/slices/data_selectors/core_data_selectors";
import { useDispatch, useSelector } from "react-redux";
import { ISettingsEditorModal, SettingsEditorModal } from "@web/modals/settings_editor_modal/SettingsEditorModal";
import { useIntlFormatters } from "shared/utils/formatters";
import { useParams } from "react-router";
import { selectLedger } from "shared/state/slices/data_selectors/ledger_data_selectors";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import { useUpdateLedgerMutation } from "shared/state/endpoints/app/ledgers_api";
import { parseApiError } from "shared/utils/api_errors";
import { setLedger } from "shared/state/slices/data_slice";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { MSG_ledgerNameLabel } from "shared/strings/ledgers";

interface IProps {}

const LedgerNameSettings: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const ledgerId = useParams<{ledgerId: string}>().ledgerId!;
  const ledger = useSelector(selectLedger(ledgerId));
  const modalRef = React.useRef<ISettingsEditorModal>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const currentUser = useSelector(selectCurrentUser);
  const editable = currentUser?.role === ROLE.OWNER;
  const [newName, setNewName] = React.useState<string>(ledger.name);
  const [updateLedger] = useUpdateLedgerMutation();
  const [saving, setSaving] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);

  async function save() {
    try {
      setSaving(true);
      const l = await updateLedger({ledger: {id: ledgerId, name: newName}}).unwrap();
      dispatch(setLedger(l));
      modalRef.current?.hide();
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  function cancel() {
    modalRef.current?.hide();
  }

  return (
    <React.Fragment>
      <StyledCardSettingsBody className={editable ? "clickable" : ''}
                              onClick={editable ? () => {
                                setNewName(ledger.name);
                                modalRef.current?.show();
                                setTimeout(() => inputRef.current?.focus(), 50);
                              }: undefined}>
        <StyledCardSettingsIcon>
          <FontAwesomeIcon icon={faKeyboard}/>
        </StyledCardSettingsIcon>
        <StyledCardSettingsLabel className="clickable-title text-nowrap">
          {formatMessage(MSG_ledgerNameLabel)}
        </StyledCardSettingsLabel>
        <StyledCardSettingsValue>
          {ledger.name}
        </StyledCardSettingsValue>
        {editable && (
          <StyledCardSettingsActions>
            <FontAwesomeIcon icon={faEdit}/>
          </StyledCardSettingsActions>)}
      </StyledCardSettingsBody>

      <SettingsEditorModal save={save} onCancel={cancel} ref={modalRef} width="25rem">
        <div style={{padding: '1rem', paddingBottom: 0}}>
          {errors?.errorType === 'message' && (
            <div className="d-flex flex-row">
              <StyledErrorMessage>
                <FontAwesomeIcon icon={faExclamationTriangle}/>
                <div>{errors.message}</div>
              </StyledErrorMessage>
            </div>
          )}

          <FormElement errors={errors} label={formatMessage(MSG_ledgerNameLabel)}>
            <TextInput ref={inputRef} value={newName} onChange={e => setNewName(e.currentTarget.value)}/>
          </FormElement>
        </div>
      </SettingsEditorModal>
    </React.Fragment>
  );
};

export {LedgerNameSettings};
