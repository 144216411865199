export const MSG_reportAProblemTitle = {
  id: "be2d67d8-6306-42c5-8df0-f36444dcc24b",
  description: "",
  defaultMessage: "Report A Problem"
};

export const MSG_reportAProblemExplanation = {
  id: "748018c6-8570-4dd5-9e64-ef6aa6fe6446",
  description: "",
  defaultMessage: "This will send a message to our support team who will investigate your issue and get back with you as soon as possible."
};

export const MSG_reportAProblemReply = {
  id: "1d4f1002-2d90-4e86-87ca-a86525cbcd8b",
  description: "",
  defaultMessage: "We will reply by email to {email}."
};



export const MSG_shortSummaryOfProblem = {
  id: "24808ec4-9358-45d3-a44f-95c5466b281d",
  description: "The label for the subject field on the support form",
  defaultMessage: "Please provide a short summary of your problem (10-20 words max)"
};

export const MSG_longExplanationOfProblem = {
  id: "e1816625-443b-4b9e-ace1-6adedb3b084c",
  description: "",
  defaultMessage: "Please provide a detailed explanation of your problem.  Include any error messages you have received, what page you were on, and the steps you took to get to this point."
};


export const MSG_reportAProblemSent = {
  id: "dd365c20-0e73-432d-80a3-23eacda3975f",
  description: "",
  defaultMessage: "Your message has been sent!"
};

export const MSG_reportAProblemSentExplanation = {
  id: "e1bbfd54-ca08-4a07-90d7-6ae32e099f22",
  description: "",
  defaultMessage: "We will get back with you as soon as possible.  You can expect a reply by email to {email}."
};

