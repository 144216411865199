import * as React from "react";
import { PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-solid-svg-icons/faLoader";

interface IProps {
  centered?: boolean;
  large?: boolean;
  padded?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const Spinner: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const spinnerEl = <FontAwesomeIcon icon={faLoader} spin className={props.className} style={props.style}/>;

  let classNames: string[] = [];
  if (props.centered) classNames.push('text-center');
  if (props.large) classNames.push('text-large');
  if (props.padded) classNames.push('p-4');

  if (classNames.length) {
    return <div className={classNames.join(' ')}>{spinnerEl}</div>;
  } else {
    return spinnerEl;
  }
};

export {Spinner};
