import * as React from "react";
import { Modal } from "@web/components/Modal";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_okButton } from "shared/strings/generic";

interface IProps {
  onConfirm: () => Promise<any>;
}

interface IConfirmationModal  {
  show: (message: any) => any;
  hide: () => any;
}

const ConfirmationModal = React.forwardRef<IConfirmationModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [working, setWorking] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>(null);
  const {formatMessage} = useIntlFormatters();

  React.useImperativeHandle<any, IConfirmationModal>(ref, () => ({
    show: (localMessage: any) => {
      setVisible(true);
      setMessage(localMessage);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  const onConfirm = async () => {
    setWorking(true);
    try {
      await props.onConfirm();
      setVisible(false);
    } catch (e) {
      console.error(e);
    } finally {
      setWorking(false);
    }
  };

  return (
    <Modal
      windowStyle={{width: '30rem', minHeight: '10rem'}}
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      bodyStyle={{padding: '1rem', paddingBottom: '2rem'}}
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={working}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={onConfirm}
                  disabled={working} spinner={working}>
            {formatMessage(MSG_okButton)}
          </Button>
        </div>
      }>

      {message}

    </Modal>
  );
});

export {ConfirmationModal, IConfirmationModal};
