import { PayloadAction } from "@reduxjs/toolkit";
import { IDataState } from "../data_slice";
import { dataStateSetters } from "./setters";
import { Account } from "../../../utils/api_types";

export const accountDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  resetAccounts: (state) => {
    state.accountsForLedgerHash = {};
    state.reconciledTotalForAccountsHash = {};
  },

  setAccount: (state, action: PayloadAction<Account>) => {
    dataStateSetters.setAccount(state, action.payload);
  },

  setAccountsForLedger: (state, action: PayloadAction<{ledgerId: string, accounts: Account[]}>) => {
    dataStateSetters.setAccountsForLedger(state, action.payload.ledgerId, action.payload.accounts);
  },

  setReconciledTotalForLedger: (state, action: PayloadAction<{ledgerId: string, accountId: string, total: number}>) => {
    state.reconciledTotalForAccountsHash[action.payload.ledgerId][action.payload.accountId] = action.payload.total;
  },

  removeAccount: (state, action: PayloadAction<string>) => {
    for (const ledgerId in state.accountsForLedgerHash) {
      if (state.accountsForLedgerHash[ledgerId][action.payload]) {
        delete state.accountsForLedgerHash[ledgerId][action.payload];
      }
    }

    for (const ledgerId in state.reconciledTotalForAccountsHash) {
      if (state.reconciledTotalForAccountsHash[ledgerId][action.payload]) {
        delete state.reconciledTotalForAccountsHash[ledgerId][action.payload];
      }
    }
  }
}
