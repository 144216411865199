import { TransactionsListType } from "../data_slice";
import { Transaction } from "../../../utils/api_types";
import { RootState } from "../../store";

export const selectTransactionsList = (listType: TransactionsListType) => (state: RootState): Transaction[] => {
  return state.data[`${listType}TransactionsList`].list;
};

export const selectTransactionsListPage = (listType: TransactionsListType) => (state: RootState) => {
  return state.data[`${listType}TransactionsList`].page;
};

export const selectTransactionsListSort = (listType: TransactionsListType) => (state: RootState) => {
  return state.data[`${listType}TransactionsList`].sort;
};

export const selectTransactionsListDirection = (listType: TransactionsListType) => (state: RootState) => {
  return state.data[`${listType}TransactionsList`].dir;
};

export const selectTransactionsListTotalCount = (listType: TransactionsListType) => (state: RootState) => {
  return state.data[`${listType}TransactionsList`].totalCount;
};

export const selectTransactionsListLoading = (listType: TransactionsListType) => (state: RootState) => {
  return state.data[`${listType}TransactionsList`].loading;
};

export const selectTransactionsListReloadIndicator = (listType: TransactionsListType) => (state: RootState) => {
  return state.data[`${listType}TransactionsList`].reloadIndicator;
};

export const selectEditingTransaction = (state: RootState) => {
  return state.data.editingTransaction;
}
