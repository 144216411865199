import React, { PropsWithChildren } from "react";
import "./styles.css";
import { Provider } from "react-redux";
import { store } from "shared/state/store";
import { ThemeProvider } from "styled-components";
import { NeoBudgetTheme } from "shared/brands/NeoBudget.theme";
import { AppWrapper } from "./AppWrapper";
import { IntlProvider } from "react-intl";

interface IProps {}

export const AppRoot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  console.log('x');
  return (
    <ThemeProvider theme={NeoBudgetTheme}>
      <Provider store={store}>
        <IntlProvider locale="en-US" defaultLocale="en-US" messages={{}}>
          <AppWrapper/>
        </IntlProvider>
      </Provider>
    </ThemeProvider>
  );
};
