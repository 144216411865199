import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { TransactionKind } from "shared/utils/api_types";
import { StyledBadge } from "../styled/StyledBadge";

interface IProps {
  kind: TransactionKind;
}

const TransactionKindBadge: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  let Component = StyledBadge;
  switch (props.kind) {
    case 'expense': Component = Expense; break;
    case 'income': Component = Income; break;
    case 'move': Component = Move; break;
    case 'transfer': Component = Transfer; break;
  }
  return (
    <Component>
      {props.kind.toUpperCase()}
    </Component>
  );
};

const Expense = styled(StyledBadge)`
  background-color: ${({theme}) => theme.colors.expenseColor };
  color: #fff;
`;

const Income = styled(StyledBadge)`
  background-color: ${({theme}) => theme.colors.incomeColor };
  color: #fff;
`;

const Move = styled(StyledBadge)`
  background-color: ${({theme}) => theme.colors.moveColor };
  color: #fff;
`;

const Transfer = styled(StyledBadge)`
  background-color: ${({theme}) => theme.colors.transferColor };
  color: #fff;
`;

export {TransactionKindBadge};
