import React from "react";
import { useLazySyncQuery } from "../state/endpoints/app/sync_api";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementReloadIndicator,
  removeAccount,
  removeBudgetedExpense,
  removeEnvelope,
  removeIncomeAllocation,
  removeIncomeSource,
  removeTransaction,
  selectApiKey,
  setAccount,
  setBudgetedExpense,
  setEnvelope,
  setIncomeAllocation,
  setIncomeSource,
  setTransaction,
  setUser
} from "../state/store";
import { setCurrentSubscription, setLedger } from "../state/slices/data_slice";
import { useReloadLedgers } from "../hooks/use_reload";

export function useSync(): void {
  const lastActivityRef = React.useRef<Date>(new Date());
  const lastSyncRef = React.useRef<number>(new Date().getTime() - 10000); // 10 seconds ago
  const syncIntervalRef = React.useRef<number>(5000);
  const isActiveRef = React.useRef<boolean>(true);
  const syncTimeoutRef = React.useRef<any>(undefined);
  const dispatch = useDispatch();
  const [syncQuery] = useLazySyncQuery();
  const reloadAccounts = useReloadLedgers();
  const apiKey = useSelector(selectApiKey);
  const idleCheckRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (window?.addEventListener) {
      window.addEventListener('mousemove', updateLastActivity);
      window.addEventListener('mousedown', updateLastActivity);
      window.addEventListener('keydown', updateLastActivity);
      window.addEventListener('scroll', updateLastActivity);
      window.addEventListener('touchstart', updateLastActivity);
      clearTimeout(syncTimeoutRef.current);
      idleCheckRef.current = setInterval(idleCheck, 1000);
    }
    void sync();
  }, [apiKey]);

  const updateLastActivity = function() {
    lastActivityRef.current = new Date();
  };

  const idleCheck = function() {
    const now = new Date();
    let lastActiveState = isActiveRef.current;
    isActiveRef.current = lastActivityRef.current > new Date(now.getTime() - 5*60*1000);

    // If there is a state change (from false to true or vice versa)
    if (isActiveRef.current !== lastActiveState) {
      if (isActiveRef.current) {
        syncIntervalRef.current = 5*1000;
        console.log('[SYNC: IDLE TIMER]: user is now ACTIVE.');
      } else {
        syncIntervalRef.current = 60*1000;
        console.log('[SYNC: IDLE TIMER]: user is now INACTIVE.');
      }
      sync();
    }
  };

  const sync = async function() {
    clearTimeout(syncTimeoutRef.current);

    if (!apiKey) {
      console.log('[SYNC]: SYNC DISABLED (logged out)');
      return;
    }

    let timestamp = Math.floor(lastSyncRef.current/1000);
    let currentSync = new Date();

    try {
      const response = await syncQuery(timestamp).unwrap();

      Object.keys(response.ledgers).forEach(id => {
        console.log('[SYNC]: ledger', id, response.ledgers[id]);
        dispatch(setLedger(response.ledgers[id]));
      });

      Object.keys(response.accounts).forEach(id => {
        console.log('[SYNC]: account', id, response.accounts[id]);
        dispatch(setAccount(response.accounts[id]));
      });

      Object.keys(response.envelopes).forEach(id => {
        console.log('[SYNC]: envelope', id, response.envelopes[id]);
        dispatch(setEnvelope(response.envelopes[id]));
      });

      Object.keys(response.budgetedExpenses).forEach(id => {
        console.log('[SYNC]: budgeted expense', id, response.budgetedExpenses[id]);
        dispatch(setBudgetedExpense(response.budgetedExpenses[id]));
      });

      Object.keys(response.incomeSources).forEach(id => {
        console.log('[SYNC]: income source', id, response.incomeSources[id]);
        dispatch(setIncomeSource(response.incomeSources[id]));
      });

      Object.keys(response.incomeAllocations).forEach(id => {
        console.log('[SYNC]: income allocation', id, response.incomeAllocations[id]);
        dispatch(setIncomeAllocation(response.incomeAllocations[id]));
      });

      Object.keys(response.users).forEach(id => {
        console.log('[SYNC]: user', id, response.users[id]);
        dispatch(setUser(response.users[id]));
      });

      Object.keys(response.transactions).forEach(id => {
        console.log('[SYNC]: transaction', id, response.transactions[id]);
        dispatch(setTransaction({listType: 'main', transaction: response.transactions[id]}));
        dispatch(setTransaction({listType: 'unallocated', transaction: response.transactions[id]}));
        dispatch(setTransaction({listType: 'unreconciled', transaction: response.transactions[id]}));

        dispatch(incrementReloadIndicator({listType: 'main'}));
        dispatch(incrementReloadIndicator({listType: 'unallocated'}));
        dispatch(incrementReloadIndicator({listType: 'unreconciled'}));
      });

      if (response.subscription) {
        console.log('[SYNC]: subscription', response.subscription);
        dispatch(setCurrentSubscription(response.subscription));
        void reloadAccounts();
      }


      response.removedModels.forEach(obj => {
        console.log(`[SYNC]: removed ${obj.removedClass}`, obj.removedId);
        switch (obj.removedClass) {
          case 'Account': dispatch(removeAccount(obj.removedId)); break;
          case 'Envelope': dispatch(removeEnvelope(obj.removedId)); break;
          case 'BudgetedExpense': dispatch(removeBudgetedExpense(obj.removedId)); break;
          case 'IncomeSource': dispatch(removeIncomeSource(obj.removedId)); break;
          case 'IncomeAllocation': dispatch(removeIncomeAllocation(obj.removedId)); break;
          case 'Transaction':
            dispatch(removeTransaction({listType: 'main', transactionId: obj.removedId}));
            dispatch(removeTransaction({listType: 'unallocated', transactionId: obj.removedId}));
            dispatch(removeTransaction({listType: 'unreconciled', transactionId: obj.removedId}));
            break;
          default:console.log(`No action for removedClass: ${obj.removedClass}`);
        }
      });


      // Set the last sync time to one second before the current sync time
      // to ensure that we don't miss any changes.  This does mean that we
      // may get duplicate changes, but that is okay.
      lastSyncRef.current = currentSync.getTime() - 1000;
      setTimeout(sync, syncIntervalRef.current);
    } catch (e) {
      console.log('[SYNC]: error', e);
    }
  };

  return;
}
