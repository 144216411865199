import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

interface IProps {
  imageUrl?: string;
  size: React.CSSProperties["width"];
}

const ProfilePicture: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {imageUrl, size} = props;

  if (imageUrl) {
    return (
      <Container style={{width: size, height: size}}>
        <img src={imageUrl}/>
      </Container>
    );
  } else {
    return (
      <Container style={{width: size, height: size, opacity: 0.5}}>
        <FontAwesomeIcon icon={faUser}/>
      </Container>
    );
  }
};

const Container = styled.div`
  background-color: #dfdfdf;
  color: #000;
  border-radius: 100%;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    opacity: 0.35;
    width: 80%;
    height: 80%;
  }
`;

export {ProfilePicture};
