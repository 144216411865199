import * as React from "react";
import { Modal } from "@web/components/Modal";
import { EnvelopeGrid } from "@web/components/shared/EnvelopeGrid";
import { EnvelopeEmptySlot } from "@web/components/shared/EnvelopeEmptySlot";
import { MSG_manageEnvelopesButton } from "shared/strings/envelopes";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_closeButton } from "shared/strings/generic";
import { Button } from "@web/components/Button";
import { EnvelopeEditable } from "@web/components/shared/EnvelopeEditable";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import withScrolling, { createHorizontalStrength, createVerticalStrength } from "react-dnd-scrolling";
import { EnvelopeArchived } from "@web/components/shared/EnvelopeArchived";
import { MSG_archivedEnvelopesTitle, MSG_moveArchivedExplanation } from "shared/strings/transactions";
import { EnvelopeEditorModal, IEnvelopeEditorModal } from "../envelope_editor_modal/EnvelopeEditorModal";
import { EmptyManageEnvelopesExplanation } from "./EmptyManageEnvelopesExplanation";
import { useSelector } from "react-redux";
import { FreePlanNoticePopover } from "@web/popovers/locked_envelope_popover/FreePlanNoticePopover";
import { IPopover } from "@web/components/Popover";
import { FEATURE_LEVEL } from "shared/utils/api_types";
import { selectAccountsForLedger, selectCurrentSubscription, selectEnvelopesForLedger } from "shared/state/store";

interface IProps {
  ledgerId: string;
}

interface IManageEnvelopesModal {
  show: () => any;
  hide: () => any;
}

const ScrollingComponent = withScrolling('div');
const vStrength = createVerticalStrength(50);
const hStrength = createHorizontalStrength(50);

const ManageEnvelopesModal = React.forwardRef<IManageEnvelopesModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const {formatMessage} = useIntlFormatters();
  const envelopeEditorModalRef = React.useRef<IEnvelopeEditorModal>(null);
  const accounts = useSelector(selectAccountsForLedger(props.ledgerId));
  const subscription = useSelector(selectCurrentSubscription);
  const lockedPopoverRef = React.useRef<IPopover>(null);
  const lockedOverlayRef = React.useRef<any>(null);
  const envelopes = useSelector(selectEnvelopesForLedger(props.ledgerId));

  React.useImperativeHandle<any, IManageEnvelopesModal>(ref, () => ({
    show: () => {
      setVisible(true);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  return (
    <DndProvider backend={HTML5Backend}>
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      windowStyle={{width: '65rem'}}
      bodyStyle={{padding: '1rem 0'}}
      header={
        <h1>{formatMessage(MSG_manageEnvelopesButton)}</h1>
      }
      footer={
        <div className="text-right">
          <Button onClick={() => { setVisible(false); }}>
            {formatMessage(MSG_closeButton)}
          </Button>
        </div>
      }>

      <div style={{position: 'relative'}}>
        <ScrollingComponent verticalStrength={vStrength} horizontalStrength={hStrength}
                            style={{padding: '1rem'}}>
          <EnvelopeGrid
            ledgerId={props.ledgerId}
            extraRows={1}
            minRows={4}
            includeArchived={true}
            archivedHeader={formatMessage(MSG_archivedEnvelopesTitle)}
            archivedSubheader={formatMessage(MSG_moveArchivedExplanation)}
            renderEmptySlot={(x, y) => {
              let onClick: any = undefined;
              if (subscription?.featureLevel === FEATURE_LEVEL.FREE && (y >= 2 || props.ledgerId !== accounts[0].ledgerId)) {
                onClick = () => {
                  // Doing a setTimeout to give the popover (if it is already visible) a chance
                  // to register the click-off and hide itself.
                  setTimeout(() => {
                    lockedOverlayRef.current = document.getElementById(`empty-slot-${x}-${y}`);
                    lockedPopoverRef.current?.show();
                  });
                };
              }
              return (
                <EnvelopeEmptySlot
                  x={x} y={y}
                  id={`empty-slot-${x}-${y}`}
                  ledgerId={props.ledgerId}
                  envelopeEditorModalRef={envelopeEditorModalRef}
                  onClick={onClick}
                />
              );
            }}
            renderEnvelope={env => (
              <EnvelopeEditable
                envelopeEditorModalRef={envelopeEditorModalRef}
                ledgerId={props.ledgerId}
                envelope={env}
              />
            )}
            renderArchived={env => (
              <EnvelopeArchived envelope={env}/>
            )}
          />
        </ScrollingComponent>

        {!envelopes.filter(e => !e.archived).length &&
          <EmptyManageEnvelopesExplanation/>}
      </div>
      <EnvelopeEditorModal ledgerId={props.ledgerId} ref={envelopeEditorModalRef}/>
      <FreePlanNoticePopover
        ref={lockedPopoverRef}
        targetRef={lockedOverlayRef}
      />
    </Modal>
    </DndProvider>
  );
});

export {ManageEnvelopesModal, IManageEnvelopesModal};
