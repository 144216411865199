import { Area } from 'react-easy-crop'

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * Converted to TypeScript by @lehresman via ChatGPT
 *
 * @param imageSrc - Image File url
 * @param pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param rotation - optional rotation parameter
 */

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue: number): number {
  return (degreeValue * Math.PI) / 180;
}

export default async function getCroppedFile(
  imageSrc: string,
  pixelCrop: Area,
  rotation: number = 0
): Promise<File> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Could not get canvas context");
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // Crop the image
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // Scale down the image if it's larger than 512x512
  const maxSideSize = 512;
  let scaleWidth = pixelCrop.width;
  let scaleHeight = pixelCrop.height;

  if (scaleWidth > maxSideSize || scaleHeight > maxSideSize) {
    if (scaleWidth > scaleHeight) {
      scaleHeight *= maxSideSize / scaleWidth;
      scaleWidth = maxSideSize;
    } else {
      scaleWidth *= maxSideSize / scaleHeight;
      scaleHeight = maxSideSize;
    }
  }

  const scaleCanvas = document.createElement("canvas");
  scaleCanvas.width = scaleWidth;
  scaleCanvas.height = scaleHeight;

  const scaleCtx = scaleCanvas.getContext("2d");
  if (!scaleCtx) {
    throw new Error("Could not get canvas context");
  }

  scaleCtx.drawImage(canvas, 0, 0, scaleWidth, scaleHeight);

  return new Promise((resolve) => {
    scaleCanvas.toBlob(
      (blob) => {
        if (!blob) {
          return;
        }
        resolve(new File([blob], "image.jpg", {type: 'image/jpeg'}));
      },
      "image/jpeg",
      0.8
    );
  });
}
