import { createSlice } from "@reduxjs/toolkit";
import {
  Account,
  BudgetedExpense,
  Envelope,
  IncomeAllocation,
  IncomeSource,
  Ledger,
  Product,
  Subscription,
  Transaction,
  User
} from "../../utils/api_types";
import { coreDataReducers } from "./data_reducers/core_data_reducers";
import { productDataReducers } from "./data_reducers/product_data_reducers";
import { ledgerDataReducers } from "./data_reducers/ledger_data_reducers";
import { accountDataReducers } from "./data_reducers/account_data_reducers";
import { envelopeDataReducers } from "./data_reducers/envelope_data_reducers";
import { budgetedExpenseDataReducers } from "./data_reducers/budgeted_expense_reducers";
import { incomeSourceDataReducers } from "./data_reducers/income_source_reducers";
import { incomeAllocationDataReducers } from "./data_reducers/income_allocation_reducers";
import { userDataReducers } from "./data_reducers/user_data_reducers";
import { transactionDataReducers } from "./data_reducers/transaction_data_reducers";
import { hideNewTransactionKindSelector, resetState, showNewTransactionKindSelector } from "../global_actions";

export type TransactionsListType = 'main' | 'unallocated' | 'unreconciled';
export type TransactionListSortType = 'date' | 'amount' | 'description';
export type TransactionListSortDirection = 'asc' | 'desc';

export interface ITransactionsState {
  list: any[],
  totalCount: number,
  page: number,
  sort: TransactionListSortType,
  dir: TransactionListSortDirection,
  loading: boolean,
  reloadIndicator: number,
}

export interface ITransactionSliceHelpers {
  reset: (listType: TransactionsListType) => any,
  append: (listType: TransactionsListType, transactions: Transaction[]) => any,
  setPage: (listType: TransactionsListType, page: number) => any,
  setTotalCount: (listType: TransactionsListType, totalCount: number) => any,
  setLoading: (listType: TransactionsListType, loading: boolean) => any,
  selectPage: (listType: TransactionsListType, state: IDataState) => number,
  selectReloadIndicator: (listType: TransactionsListType, state: IDataState) => number,
  incrementReloadIndicator: (listType: TransactionsListType) => any,
};

export interface IDataState {
  errorCount: number,
  bootstrapping: boolean;
  apiKey: string | null,
  currentSubscription: Subscription | null,
  currentUser: User | null,
  productsHash: {[productKey: string]: Product},
  ledgersHash: {[ledgerId: string]: Ledger},
  accountsForLedgerHash: {[ledgerId: string]: {[accountId: string]: Account}},
  reconciledTotalForAccountsHash: {[ledgerId: string]: {[accountId: string]: number}},
  envelopesForLedgerHash: {[ledgerId: string]: {[envelopeId: string]: Envelope}},
  budgetedExpensesForLedgerHash: {[ledgerId: string]: {[budgetedExpenseId: string]: BudgetedExpense}},
  incomeSourcesForLedgerHash: {[ledgerId: string]: {[incomeSourceId: string]: IncomeSource}},
  incomeAllocationsForLedgerHash: {[ledgerId: string]: {[incomeAllocationId: string]: IncomeAllocation}},
  usersHash: {[userId: string]: User},
  newTransactionKindSelectorVisible: boolean,
  mainTransactionsList: ITransactionsState,
  unallocatedTransactionsList: ITransactionsState,
  unreconciledTransactionsList: ITransactionsState,
  editingTransaction: Partial<Transaction>,
}

const initialState: IDataState = {
  errorCount: 0,
  bootstrapping: true,
  apiKey: null,
  currentSubscription: null,
  currentUser: null,
  productsHash: {},
  ledgersHash: {},
  accountsForLedgerHash: {},
  reconciledTotalForAccountsHash: {},
  envelopesForLedgerHash: {},
  budgetedExpensesForLedgerHash: {},
  incomeSourcesForLedgerHash: {},
  incomeAllocationsForLedgerHash: {},
  usersHash: {},
  newTransactionKindSelectorVisible: false,
  editingTransaction: {},
  mainTransactionsList: {
    list: [],
    totalCount: 0,
    page: 1,
    loading: false,
    reloadIndicator: 0,
    sort: 'date',
    dir: 'desc',
  },
  unallocatedTransactionsList: {
    list: [],
    totalCount: 0,
    page: 1,
    loading: false,
    reloadIndicator: 0,
    sort: 'date',
    dir: 'desc',
  },
  unreconciledTransactionsList: {
    list: [],
    totalCount: 0,
    page: 1,
    loading: false,
    reloadIndicator: 0,
    sort: 'date',
    dir: 'desc',
  },
}

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);
    builder.addCase(showNewTransactionKindSelector, (state) => {
      return {...state, newTransactionKindSelectorVisible: true};
    });
    builder.addCase(hideNewTransactionKindSelector, (state) => {
      return {...state, newTransactionKindSelectorVisible: false};
    });
  },
  reducers: {
    ...coreDataReducers,
    ...productDataReducers,
    ...ledgerDataReducers,
    ...accountDataReducers,
    ...envelopeDataReducers,
    ...budgetedExpenseDataReducers,
    ...incomeSourceDataReducers,
    ...incomeAllocationDataReducers,
    ...userDataReducers,
    ...transactionDataReducers,
  }
});

export const {
  // Core Actions
  setBootstrapping,
  setApiKey,
  setCurrentSubscription,
  setCurrentUser,
  incrementErrorCount,
  resetErrorCount,

  // Product Actions
  setProducts,

  // Ledger Actions
  setLedger,
  setLedgers,

  // Account Actions
  resetAccounts,
  setAccount,
  setAccountsForLedger,
  setReconciledTotalForLedger,
  removeAccount,

  // Envelope Actions
  setEnvelope,
  setEnvelopesForLedger,
  removeEnvelope,

  // Budgeted Expense Actions
  setBudgetedExpense,
  setBudgetedExpensesForLedger,
  removeBudgetedExpense,

  // Income Source Actions
  setIncomeSource,
  setIncomeSourcesForLedger,
  removeIncomeSource,

  // Income Allocation Actions
  setIncomeAllocation,
  setIncomeAllocationsForLedger,
  removeIncomeAllocation,

  // User Actions
  setUser,
  setUsers,

  // Transaction Actions
  resetTransactionsList,
  setTransactionsListData,
  appendToTransactionsList,
  setTransactionsListPage,
  setTransactionsListTotalCount,
  setTransactionsListLoading,
  setTransaction,
  removeTransaction,
  incrementReloadIndicator,
  setEditingTransaction,
} = dataSlice.actions;

export * from './data_selectors/core_data_selectors';
export * from './data_selectors/product_data_selectors';
export * from './data_selectors/ledger_data_selectors';
export * from './data_selectors/account_data_selectors';
export * from './data_selectors/envelope_data_selectors';
export * from './data_selectors/budgeted_expense_data_selectors';
export * from './data_selectors/income_source_data_selectors';
export * from './data_selectors/income_allocation_data_selectors';
export * from './data_selectors/user_data_selectors';
export * from './data_selectors/transaction_data_selectors';
