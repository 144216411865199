import * as React from "react";
import { PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MSG_deleteExplanation, MSG_deleteTitle } from "shared/strings/transactions";
import { useIntlFormatters } from "shared/utils/formatters";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons/faTrashCan";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import {
  DeleteTransactionConfirmationPopover,
  IDeleteTransactionConfirmationPopover
} from "@web/components/selectors/DeleteTransactionConfirmationPopover";
import { AllocationModalContext } from "./context";
import { compact } from "lodash";
import { StyledDropbox } from "@web/components/styled/StyledDropbox";
import { useReloadLedger } from "shared/hooks/use_reload";

interface IProps {
  accountId: string;
  hoverFocusBorder?: boolean;
  afterDelete?: () => any;
  afterDrop?: () => void;
}

const DeleteDropbox: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const context = React.useContext(AllocationModalContext);
  const {formatMessage} = useIntlFormatters();
  const popoverRef = React.useRef<IDeleteTransactionConfirmationPopover>(null);
  const deleteBoxPopoverRef = React.useRef<HTMLDivElement>(null);
  const reloadAccount = useReloadLedger();
  const selectedIds = compact(context.selected.map(t => t.id));
  let isOver, dropRef;
  [{isOver}, dropRef] = useDrop(() => ({
      accept: 'unallocated-transaction',
      drop: onDrop,
      collect: monitor => ({
        isOver: monitor.isOver(),
      })
    }
  ), [selectedIds]);

  async function onDrop() {
    if (selectedIds.length) {
      context.setWorking(context.selected);
      popoverRef.current?.show(selectedIds);
    }
  }

  return (
    <React.Fragment>
      <DeleteTransactionConfirmationPopover
        ref={popoverRef}
        targetRef={deleteBoxPopoverRef}
        ledgerId={props.accountId}
        afterDelete={async () => {
          await reloadAccount(props.accountId);
          context.reset();
          props.afterDelete?.();
          props.afterDrop?.();
        }}
        afterCancel={() => {
          context.setWorking([]);
          props.afterDrop?.();
        }}
      />

      <StyledDropbox ref={dropRef}
                     style={{width: '33.33%'}}
                     className={classNames({active: isOver, 'hover-focus-border': props.hoverFocusBorder})}
                     onClick={onDrop}>
        <div className="title" ref={deleteBoxPopoverRef}>
          <FontAwesomeIcon icon={faTrashCan} className="me-2"/>
          {formatMessage(MSG_deleteTitle)}
        </div>
        <div>
          {formatMessage(MSG_deleteExplanation)}
        </div>
      </StyledDropbox>
    </React.Fragment>
  );
};

export {DeleteDropbox};
