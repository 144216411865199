import * as React from "react";
import { forwardRef, RefObject } from "react";
import { IPopover, Popover } from "@web/components/Popover";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_splitAcrossAllPaychecksIncludingExtras, MSG_splitAcrossNormalPaychecks } from "shared/strings/budget";
import { StyledVerticalOptions } from "@web/components/styled/StyledVerticalOptions";
import classnames from "classnames";

interface IProps {
  targetRef: RefObject<any>;
  onSelect: (includingExtra: boolean) => Promise<any>;
}

const SplitFundingPopover = forwardRef<IPopover, IProps>((props, ref) => {
  const popoverRef = React.useRef<IPopover>(null);
  const {formatMessage} = useIntlFormatters();
  const [isWorking, setIsWorking] = React.useState<boolean>(false);

  React.useImperativeHandle<any, IPopover>(ref, () => ({
    show: () => popoverRef.current?.show(),
    hide: () => popoverRef.current?.hide(),
  }));

  async function onClick(includingExtra: boolean) {
    setIsWorking(true);
    await props.onSelect(includingExtra);
    popoverRef.current?.hide();
    setIsWorking(false);
  }

  return (
    <Popover ref={popoverRef}
             targetRef={props.targetRef}
             placement={'top'}>
      <StyledVerticalOptions className={classnames('noborder', {disabled: isWorking})}>
        <div className="option" onClick={() => void onClick(true)}>
          {formatMessage(MSG_splitAcrossAllPaychecksIncludingExtras)}
        </div>
        <div className="option" onClick={() => void onClick(false)}>
          {formatMessage(MSG_splitAcrossNormalPaychecks)}
        </div>
      </StyledVerticalOptions>
    </Popover>
  );
});

export {SplitFundingPopover};
