import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  appendToTransactionsList,
  selectApiKey,
  selectCurrentUser,
  selectLedgers,
  selectTransactionsListPage,
  selectTransactionsListReloadIndicator,
  setTransactionsListData,
  setTransactionsListLoading,
  setTransactionsListPage,
  setTransactionsListTotalCount,
  TransactionListSortDirection,
  TransactionListSortType,
  TransactionsListType
} from "shared/state/store";
import { useLocation, useNavigate } from "react-router-dom";
import { Envelope, Transaction } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_clothingEnvelopeName,
  MSG_diningOutEnvelopeName,
  MSG_electricEnvelopeName,
  MSG_entertainmentEnvelopeName,
  MSG_groceriesEnvelopeName,
  MSG_homeImprovementEnvelopeName,
  MSG_internetEnvelopeName,
  MSG_maintenanceEnvelopeName,
  MSG_mortgageEnvelopeName,
  MSG_personalCareEnvelopeName,
  MSG_phoneEnvelopeName,
  MSG_propaneEnvelopeName,
  MSG_spendingMoneyEnvelopeName,
  MSG_travelEnvelopeName,
  MSG_waterEnvelopeName
} from "shared/strings/envelopes";
import { sortBy } from "lodash";
import { useLazyGetTransactionsQuery } from "shared/state/endpoints/app/transactions_api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import { IAlertModal } from "../modals/alert_modal/AlertModal";

export function useQueryString<T>(): T {
  const { search } = useLocation();
  return React.useMemo<T>(() => Object.fromEntries(new URLSearchParams(search)) as T, [search]);
}

export function useExampleEnvelopes(): Envelope[] {
  const {formatMessage} = useIntlFormatters();

  return [
    {id: '01', name: formatMessage(MSG_groceriesEnvelopeName), ledgerId: 'fake', amount: 27644, appX: 0, appY: 0, color: '#ffff8a', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '02', name: formatMessage(MSG_personalCareEnvelopeName), ledgerId: 'fake', amount: 4381, appX: 0, appY: 1, color: '#ffff8a', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '03', name: formatMessage(MSG_clothingEnvelopeName), ledgerId: 'fake', amount: -2734, appX: 0, appY: 2, color: '#ffff8a', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},

    {id: '04', name: formatMessage(MSG_entertainmentEnvelopeName), ledgerId: 'fake', amount: 16765, appX: 1, appY: 0, color: '#ffff8a', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '05', name: formatMessage(MSG_diningOutEnvelopeName), ledgerId: 'fake', amount: 7866, appX: 1, appY: 1, color: '#ffff8a', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '06', name: formatMessage(MSG_spendingMoneyEnvelopeName), ledgerId: 'fake', amount: 3475, appX: 1, appY: 2, color: '#ffff8a', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},

    {id: '07', name: formatMessage(MSG_travelEnvelopeName), ledgerId: 'fake', amount: 127500, appX: 2, appY: 0, color: '#98db4c', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '08', name: formatMessage(MSG_maintenanceEnvelopeName), ledgerId: 'fake', amount: 37815, appX: 2, appY: 1, color: '#98db4c', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '09', name: formatMessage(MSG_homeImprovementEnvelopeName), ledgerId: 'fake', amount: 7989, appX: 2, appY: 2, color: '#98db4c', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},

    {id: '10', name: formatMessage(MSG_mortgageEnvelopeName), ledgerId: 'fake', amount: 125000, appX: 3, appY: 0, color: '#a4b1bc', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '11', name: formatMessage(MSG_electricEnvelopeName), ledgerId: 'fake', amount: 19859, appX: 3, appY: 1, color: '#a4b1bc', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '12', name: formatMessage(MSG_waterEnvelopeName), ledgerId: 'fake', amount: 3625, appX: 3, appY: 2, color: '#a4b1bc', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},

    {id: '13', name: formatMessage(MSG_propaneEnvelopeName), ledgerId: 'fake', amount: 14151, appX: 4, appY: 0, color: '#a4b1bc', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '14', name: formatMessage(MSG_phoneEnvelopeName), ledgerId: 'fake', amount: 12478, appX: 4, appY: 1, color: '#a4b1bc', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
    {id: '15', name: formatMessage(MSG_internetEnvelopeName), ledgerId: 'fake', amount: 5995, appX: 4, appY: 2, color: '#a4b1bc', archived: false, mobileSequenceNumber: 1, locked: false, updatedAt: '', createdAt: ''},
  ];
}

export const TRANSACTIONS_PER = 50;
export function useTransactionPager(listType: TransactionsListType, params: {ledgerId: string, accountId?: string, eid?: string, q?: string, onlyUnallocated?: boolean, onlyUnreconciled?: boolean, per?: number, sort?: TransactionListSortType, dir?: TransactionListSortDirection}): {
  fetchToPage: (p: number) => Promise<void>,
  fetchNextPage: () => Promise<void>,
} {
  const page = useSelector(selectTransactionsListPage(listType));
  const dispatch = useDispatch();
  const [trigger] = useLazyGetTransactionsQuery();
  const reloadIndicator = useSelector(selectTransactionsListReloadIndicator(listType));
  const firstReloadIndicatorRef = React.useRef<boolean>(true);

  React.useEffect(() => {
    if (!firstReloadIndicatorRef.current) {
      void fetchToPage(page);
    }
    firstReloadIndicatorRef.current = false;
  }, [reloadIndicator]);

  const fetchPage = React.useCallback(async (p: number) => {
    return trigger({
      ledgerId: params.ledgerId as string,
      accountId: params.accountId || '',
      envelopeId: params.eid,
      q: params.q,
      page: p,
      sort: params.sort,
      dir: params.dir,
      per: params.per || TRANSACTIONS_PER,
      onlyUnallocated: params.onlyUnallocated,
      onlyUnreconciled: params.onlyUnreconciled
    });
  }, [params.eid, params.accountId, params.q, params.ledgerId, params.onlyUnallocated, params.onlyUnreconciled, params.per, params.sort, params.dir]);

  const fetchToPage = React.useCallback(async (p: number) => {
    dispatch(setTransactionsListLoading({listType, loading: true}));
    let newTransactions: Transaction[] = [];
    let queries: Promise<any>[] = [];
    let pages: any[] = [];
    for (let i = 1; i <= p; i++) {
      queries.push((async () => {
        const {data: pageData} = await fetchPage(i);
        pages.push(pageData);
      })());
    }
    await Promise.all(queries);
    sortBy(pages, ['page']).forEach(pageData => {
      newTransactions = [...newTransactions, ...(pageData?.transactions || [])];
    });
    dispatch(setTransactionsListData({
      listType,
      list: newTransactions,
      totalCount: pages[0]?.totalCount || 0,
      page: p,
      loading: false,
    }));
  }, [fetchPage]);

  async function fetchNextPage() {
    dispatch(setTransactionsListLoading({listType, loading: true}));
    const {data: pageData} = await fetchPage(page + 1);
    dispatch(appendToTransactionsList({listType, transactions: pageData?.transactions || []}));
    dispatch(setTransactionsListPage({listType, page: page + 1}));
    dispatch(setTransactionsListTotalCount({listType, totalCount: pageData?.totalCount || 0 || 0}));
    dispatch(setTransactionsListLoading({listType, loading: false}));
  }

  return {fetchToPage, fetchNextPage};
}

export const AlertContext = React.createContext< {
  ref: React.RefObject<IAlertModal> | null,
}>({
  ref: null,
});

export function useAlert() {
  const context = React.useContext(AlertContext);
  return {
    showErrorAlert: (message: React.ReactNode | string) => {
      context.ref?.current?.show(message, faExclamationTriangle, {error: true});
    },
    showAlert: (message: React.ReactNode | string, icon?: IconProp) => {
      context.ref?.current?.show(message, icon);
    }
  };
}

export function useNavigateToDefaultRoute() {
  const apiKey = useSelector(selectApiKey);
  const ledgers = useSelector(selectLedgers);
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);

  return function() {
    if (!apiKey) {
      navigate('/app/login');
    } else if (currentUser?.flags?.getStartedVisible) {
      navigate('/app/get_started');
    } else if (ledgers.length > 0) {
      navigate(`/app/ledgers/${ledgers[0].id}/envelopes`);
    } else {
      navigate('/app/help_center');
    }
  }
}
