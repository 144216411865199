import { baseApi } from "../base_api";

export const supportApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendSupportRequest: builder.mutation<{success: boolean}, {message: string, subject: string}>({
      query: ({message, subject}) => {
        return ({
          url: `/support/contact`,
          method: 'POST',
          body: {message, subject},
        });
      },
      transformResponse: (response: any) => {
        return response;
      }
    }),
  })
});

export const {
  useSendSupportRequestMutation,
} = supportApi;
