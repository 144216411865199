import * as React from "react";
import { forwardRef, RefObject } from "react";
import { IncomeAllocation, IncomeSource } from "shared/utils/api_types";
import { IPopover, Popover } from "../Popover";
import { useIntlFormatters } from "shared/utils/formatters";
import styled from "styled-components";
import { MSG_useSpecificIncomeAllocations } from "shared/strings/transactions";
import { darken } from "color2k";
import { useSelector } from "react-redux";
import {
  selectCurrentSubscription,
  selectIncomeAllocationsForLedger,
  selectIncomeSourcesForLedger
} from "shared/state/store";
import { getNumChecks } from "shared/utils/helpers";

interface IProps {
  ledgerId: string;
  targetRef: RefObject<any>;
  onCancel?: () => any;
  onSelect: (incomeSource: IncomeSource, sequenceNumber: number, incomeAllocations: IncomeAllocation[]) => any;
}

const IncomeSourceSequencePopover = forwardRef<IPopover, IProps>((props, ref) => {
  const popoverRef = React.useRef<IPopover>(null);
  const subscription = useSelector(selectCurrentSubscription)!;
  const {formatOrdinal, formatMessage, formatCurrency} = useIntlFormatters();
  const incomeSources = useSelector(selectIncomeSourcesForLedger(props.ledgerId));
  const incomeAllocations = useSelector(selectIncomeAllocationsForLedger(props.ledgerId));

  React.useImperativeHandle<any, IPopover>(ref, () => ({
    show: () => popoverRef.current?.show(),
    hide: () => popoverRef.current?.hide(),
  }));

  const options: {label: string, sequenceNumber: number, source: IncomeSource, allocations: IncomeAllocation[]}[] = [];
  incomeSources.forEach(source => {
    let numChecks = getNumChecks(source.frequency);
    for (let i = 0; i < numChecks; i++) {
      options.push({
        label: `${source.name} (${formatOrdinal(i + 1)}): ${formatCurrency(source.amount, subscription)}`,
        sequenceNumber: i,
        source: source,
        allocations: incomeAllocations.filter(a => a.incomeSourceId === source.id && a.sequenceNumber === i) || []
      });
    }
  });

  return (
    <Popover ref={popoverRef}
             targetRef={props.targetRef}
             placement={'bottom'}
             maxHeight={'18rem'}
             onCancel={props.onCancel}>
      <Container>
        {options.map((option, i) => (
          <Item key={i} onClick={() => {
            props.onSelect(option.source, option.sequenceNumber + 1, option.allocations);
            popoverRef.current?.hide();
          }}>
            {formatMessage(MSG_useSpecificIncomeAllocations, {incomeAllocationName: option.label})}
          </Item>
        ))}
      </Container>
    </Popover>
  );
});

const Container = styled.div`
  padding: 0.5rem;
  max-width: 35rem;
`;

const Item = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 2px;
  border-radius: ${({theme}) => theme.raisedContainer.borderRadius };
  background-color: ${({theme}) => theme.colors.lightBackgroundColor };
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => darken(theme.colors.lightBackgroundColor, 0.1) };
  }
`;

export {IncomeSourceSequencePopover};
