import * as React from "react";
import { PropsWithChildren } from "react";
import { useDragLayer, useDrop, XYCoord } from "react-dnd";
import { CSSProperties } from "styled-components";
import { DraggableTransaction } from "./DraggableTransaction";
import { Transaction } from "shared/utils/api_types";

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

interface IProps {
  transactions: Transaction[];
  maxWidth: number;
}

const TransactionDragLayer: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const { initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const [, bodyDropRef] = useDrop(() => ({
    accept: 'unallocated-transaction',
    drop: () => {
      // no op
    }
  }));

  React.useEffect(() => {
    bodyDropRef(document.body);
    return () => { bodyDropRef(null); };
  }, []);

  if (!props.transactions.length) return null;

  return (
    <div style={layerStyles}>
      <div style={{...getItemStyles(initialOffset, currentOffset), position: 'relative', padding: 0, margin: 0}}>
        {props.transactions[2] && (
          <div style={{position: 'absolute', bottom: -20, left: 20}}>
            <DraggableTransaction transaction={props.transactions[2]}
                                  isPreview={true}
                                  maxWidth={props.maxWidth}/>
          </div>)}
        {props.transactions[1] && (
          <div style={{position: 'absolute', bottom: -10, left: 10}}>
            <DraggableTransaction transaction={props.transactions[1]}
                                  isPreview={true}
                                  maxWidth={props.maxWidth}/>
          </div>)}
        <DraggableTransaction transaction={props.transactions[0]}
                              maxWidth={props.maxWidth}
                              isPreview={true}
                              showCount={props.transactions.length > 1 ? props.transactions.length : undefined}/>
      </div>
    </div>
  );
};

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

export {TransactionDragLayer};
