import { PayloadAction } from "@reduxjs/toolkit";
import { Subscription, User } from "../../../utils/api_types";
import { IDataState } from "../data_slice";
import { dataStateSetters } from "./setters";

export const coreDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  incrementErrorCount: (state) => {
    state.errorCount += 1;
  },

  resetErrorCount: (state) => {
    state.errorCount = 0;
  },

  setBootstrapping: (state, action: PayloadAction<boolean>) => {
    state.bootstrapping = action.payload;
  },

  setApiKey: (state, action: PayloadAction<string | null>) => {
    state.apiKey = action.payload;
  },

  setCurrentSubscription: (state, action: PayloadAction<Subscription>) => {
    state.currentSubscription = action.payload;
  },

  setCurrentUser: (state, action: PayloadAction<User>) => {
    state.currentUser = action.payload;
    dataStateSetters.setUser(state, action.payload);
  }
}
