import * as React from "react";
import { PropsWithChildren } from "react";
import {
  MSG_averageTitle,
  MSG_extraIncome,
  MSG_extraIncomeExplanation,
  MSG_fundedWithExtra1,
  MSG_fundedWithExtra2b,
  MSG_incomeWithExtra,
  MSG_maximumTitle,
  MSG_minimumTitle,
  MSG_monthlyExpensesExplanation,
  MSG_monthlyExpensesTitle,
  MSG_monthlyFundingExplanation,
  MSG_monthlyFundingExplanationWithExtra,
  MSG_monthlyFundingTitle,
  MSG_monthlyIncomeExplanation,
  MSG_monthlyIncomeExplanationWithExtra,
  MSG_monthlyIncomeTitle,
  MSG_monthsWithNChecks
} from "shared/strings/budget";
import { ICalculatedBudgetSummary, ICalculatedBudgetValues } from "shared/utils/interfaces";
import { StyledEnvelopeContainer } from "@web/components/styled/StyledEnvelopeContainer";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_editButton } from "shared/strings/generic";
import {
  IIncomeSourcesEditorModal,
  IncomeSourcesEditorModal
} from "@web/modals/income_sources_editor_modal/IncomeSourcesEditorModal";
import { selectCurrentSubscription, selectCurrentUser, selectIncomeSourcesForLedger } from "shared/state/store";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { faAsterisk } from "@fortawesome/pro-solid-svg-icons/faAsterisk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { ContextHelp } from "@web/components/shared/ContextHelp";
import classnames from "classnames";
import { ROLE, User } from "shared/utils/api_types";
import { faGrid, faList } from "@fortawesome/pro-light-svg-icons";

interface IProps {
  viewType: 'grid' | 'list';
  onChangeViewType: (viewType: 'grid' | 'list') => void;
  envelopeValues: ICalculatedBudgetValues;
  summaryValues: ICalculatedBudgetSummary;
}

const BudgetSummary: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const {formatMessage, formatCurrency} = useIntlFormatters()
  const incomeSources = useSelector(selectIncomeSourcesForLedger(ledgerId as string));
  const incomeSourcesEditorRef = React.useRef<IIncomeSourcesEditorModal>(null);
  const subscription = useSelector(selectCurrentSubscription)!;
  const currentUser = useSelector(selectCurrentUser) as User;

  const unallocated = props.summaryValues.monthlyIncome.average - props.summaryValues.monthlyFunding.average;

  return (
    <div className="flex-row align-items-center justify-content-stretch">
      <SummaryContainer>
        <SummaryCell>
          <SummaryContent style={{marginRight: '1rem'}}>
            <div className="flex-row align-items-center justify-content-center">
              {formatMessage(MSG_monthlyIncomeTitle)}
              <ContextHelp className="ms-1"
                           text={props.summaryValues.monthlyIncome.extra
                             ? formatMessage(MSG_monthlyIncomeExplanationWithExtra)
                             : formatMessage(MSG_monthlyIncomeExplanation)}/>
            </div>
            <div className="flex-row align-items-center justify-content-center">
              {props.summaryValues.monthlyIncome.extra ? (
                <React.Fragment>
                  <Amount data-tooltip-id={`extra-income-summary`}
                          className={classnames({negative: props.summaryValues.monthlyIncome.average < 0})}>
                    {formatCurrency(props.summaryValues.monthlyIncome.average, subscription)}
                    <FontAwesomeIcon icon={faAsterisk}/>
                  </Amount>
                  <Tooltip delayShow={100} id={`extra-income-summary`} className="tooltip-wrapper" place="bottom">
                    {formatMessage(MSG_incomeWithExtra)}
                    <br/><br/>
                    <div>
                      {formatMessage(MSG_averageTitle)}: <b>{formatCurrency(props.summaryValues.monthlyIncome.average, subscription)}</b>
                    </div>
                    <div>
                      {formatMessage(MSG_minimumTitle)}: <b>{formatCurrency(props.summaryValues.monthlyIncome.amount, subscription)}</b>
                      <span className="ms-2" style={{opacity: 0.75}}>({formatMessage(MSG_monthsWithNChecks, {count: props.summaryValues.monthlyIncome.minChecks})})</span>
                    </div>
                    <div>
                      {formatMessage(MSG_maximumTitle)}: <b>{formatCurrency(props.summaryValues.monthlyIncome.amount + props.summaryValues.monthlyIncome.extra, subscription)}</b>
                      <span className="ms-2" style={{opacity: 0.75}}>({formatMessage(MSG_monthsWithNChecks, {count: props.summaryValues.monthlyIncome.maxChecks})})</span>
                    </div>
                  </Tooltip>
                </React.Fragment>
              ) : (
                <Amount className={classnames({negative: props.summaryValues.monthlyIncome.amount < 0})}>
                  {formatCurrency(props.summaryValues.monthlyIncome.amount, subscription)}
                </Amount>
              )}
            </div>
          </SummaryContent>
          {currentUser.role !== ROLE.OBSERVER && (
            <Button onClick={() => {
              incomeSourcesEditorRef.current?.show(ledgerId, incomeSources);
            }}>
              {formatMessage(MSG_editButton)}
            </Button>)}
        </SummaryCell>
        <SummaryCellSeparator>|</SummaryCellSeparator>
        <SummaryCell>
          <SummaryContent>
            <div className="flex-row align-items-center justify-content-center">
              {formatMessage(MSG_monthlyExpensesTitle)}
              <ContextHelp className="ms-1"
                           text={formatMessage(MSG_monthlyExpensesExplanation)}/>
            </div>
            <div className="flex-row align-items-center justify-content-center">
              <Amount className={classnames({negative: props.summaryValues.monthlyExpenses.average < 0})}>
                {formatCurrency(props.summaryValues.monthlyExpenses.average, subscription)}
              </Amount>
            </div>
          </SummaryContent>
        </SummaryCell>
        <SummaryCellSeparator>|</SummaryCellSeparator>
        <SummaryCell>
          <SummaryContent>
            <div className="flex-row align-items-center justify-content-center">
              {formatMessage(MSG_monthlyFundingTitle)}
              <ContextHelp className="ms-1"
                           text={props.summaryValues.monthlyFunding.extra
                             ? formatMessage(MSG_monthlyFundingExplanationWithExtra)
                             : formatMessage(MSG_monthlyFundingExplanation)}/>
            </div>
            <div className="flex-row align-items-center justify-content-center">
              {props.summaryValues.monthlyFunding.extra ? (
                <React.Fragment>
                  <Amount data-tooltip-id={`extra-funding-summary`}
                          className={classnames({negative: props.summaryValues.monthlyFunding.average < 0})}>
                    {formatCurrency(props.summaryValues.monthlyFunding.average, subscription)}
                    <FontAwesomeIcon icon={faAsterisk}/>
                  </Amount>
                  <Tooltip delayShow={100} id={`extra-funding-summary`} className="tooltip-wrapper" place="bottom">
                    {formatMessage(MSG_fundedWithExtra1)}
                    <br/><br/>
                    {formatMessage(MSG_fundedWithExtra2b)}
                    <br/><br/>
                    <div>
                      {formatMessage(MSG_averageTitle)}: <b>{formatCurrency(props.summaryValues.monthlyFunding.average, subscription)}</b>
                    </div>
                    <div>
                      {formatMessage(MSG_minimumTitle)}: <b>{formatCurrency(props.summaryValues.monthlyFunding.amount, subscription)}</b>
                      <span className="ms-2" style={{opacity: 0.75}}>({formatMessage(MSG_monthsWithNChecks, {count: props.summaryValues.monthlyFunding.minChecks})})</span>
                    </div>
                    <div>
                      {formatMessage(MSG_maximumTitle)}: <b>{formatCurrency(props.summaryValues.monthlyFunding.amount + props.summaryValues.monthlyFunding.extra, subscription)}</b>
                      <span className="ms-2" style={{opacity: 0.75}}>({formatMessage(MSG_monthsWithNChecks, {count: props.summaryValues.monthlyFunding.maxChecks})})</span>
                    </div>
                  </Tooltip>

                </React.Fragment>
              ) : (
                <Amount className={classnames({negative: props.summaryValues.monthlyFunding.amount < 0})}>
                  {formatCurrency(props.summaryValues.monthlyFunding.amount, subscription)}
                </Amount>
              )}
            </div>
          </SummaryContent>
        </SummaryCell>
      </SummaryContainer>

      <StyledEnvelopeContainer
        style={{
          cursor: 'default',
          // to match the grid layout
          width: 'calc((100% - (0.75rem * 4)) / 5)',
          height: '6.25rem',
        }}>
        <Name>
          {formatMessage(MSG_extraIncome)}
          <ContextHelp className="ms-1"
                       text={formatMessage(MSG_extraIncomeExplanation)}/>
        </Name>
        <Amount className={classnames({negative: unallocated < 0})}>
          {formatCurrency(unallocated, subscription)}
        </Amount>
      </StyledEnvelopeContainer>

      <TabContainer>
        <TabItem className={classnames({active: props.viewType === 'grid'})}
                 onClick={() => props.onChangeViewType('grid')}>
          <FontAwesomeIcon icon={faGrid}/>
        </TabItem>
        <TabItem className={classnames({active: props.viewType === 'list'})}
                 onClick={() => props.onChangeViewType('list')}>
          <FontAwesomeIcon icon={faList}/>
        </TabItem>
      </TabContainer>

      <IncomeSourcesEditorModal ref={incomeSourcesEditorRef}/>
    </div>
  );
};

const Name = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Amount = styled.div`
  font-size: 165%;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  &.negative {
    color: ${({theme}) => theme.colors.negativeEnvelopeTextColor };
  }

  svg {
    margin-left: 0.15rem;
    font-size: 60%;
  }
`;


const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  flex-grow: 1;
  background: ${({theme}) => theme.colors.lightBackgroundColor};
  border-top-left-radius: ${({theme}) => theme.window.borderRadius};
  border-bottom-left-radius: ${({theme}) => theme.window.borderRadius};
  height: 5.5rem;
  padding-left: 1rem;
  padding-right: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SummaryCell = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SummaryCellSeparator = styled.div`
  flex-shrink: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  opacity: 0.15;
`;

const SummaryIcon = styled.div`
  font-size: 1.5rem;
`;

const SummaryContent = styled.div`
  text-align: center;
`;

const TabContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0.5rem;
  width: 3rem;
  height: 5.5rem;
  padding: 0;
  margin-left: 1rem;
`;

const TabItem = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  background: ${({theme}) => theme.colors.lightBackgroundColor};
  border-radius: ${({theme}) => theme.window.borderRadius};

  &:hover {
    background: ${({theme}) => theme.colors.darkBackgroundColor};
  }

  &.active {
    background: ${({theme}) => theme.colors.primary};
    color: #fff;
  }
`;

export { BudgetSummary };
