import * as React from "react";
import { forwardRef, RefObject } from "react";
import { IPopover, Popover } from "@web/components/Popover";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_cancelButton } from "shared/strings/generic";
import { Button } from "@web/components/Button";
import { useDeleteAccountMutation } from "shared/state/endpoints/app/accounts_api";
import {
  MSG_deleteAccountConfirmation,
  MSG_deleteAccountConfirmationExplanation,
  MSG_yesDeleteIt
} from "shared/strings/accounts";
import { useReloadLedgers } from "shared/hooks/use_reload";

interface IProps {
  targetRef: RefObject<any>;
  afterDelete?: () => Promise<any>;
  ledgerId: string;
  accountId: string;
}

const DeleteAccountConfirmationPopover = forwardRef<IPopover, IProps>((props, ref) => {
  const popoverRef = React.useRef<IPopover>(null);
  const {formatMessage} = useIntlFormatters();
  const [isWorking, setIsWorking] = React.useState<boolean>(false);
  const [deleteAccount] = useDeleteAccountMutation();
  const reloadAccounts = useReloadLedgers();

  React.useImperativeHandle<any, IPopover>(ref, () => ({
    show: () => popoverRef.current?.show(),
    hide: () => popoverRef.current?.hide(),
  }));

  async function onClick() {
    setIsWorking(true);
    await deleteAccount({ledgerId: props.ledgerId, accountId: props.accountId});
    await reloadAccounts();
    await props.afterDelete?.();
    popoverRef.current?.hide();
    setIsWorking(false);
  }

  return (
    <Popover ref={popoverRef}
             targetRef={props.targetRef}
             disableDismissOnOutsideClick={true}
             placement={'bottom'}>
      <Container>
        <Question>{formatMessage(MSG_deleteAccountConfirmation)}</Question>
        <Explanation>{formatMessage(MSG_deleteAccountConfirmationExplanation)}</Explanation>
        <div className="d-flex flex-row">
          <Button color="secondary"
                  disabled={isWorking}
                  onClick={() => popoverRef.current?.hide()}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button color="danger" onClick={onClick} disabled={isWorking} spinner={isWorking}>
            {formatMessage(MSG_yesDeleteIt)}
          </Button>
        </div>
      </Container>
    </Popover>
  );
});

const Container = styled.div`
  padding: 1rem;
  max-width: 35rem;
`;

const Question = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${({theme}) => theme.colors.danger };
`;

const Explanation = styled.div`
  margin-bottom: 1rem;
`;

export {DeleteAccountConfirmationPopover};
