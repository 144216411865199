import * as React from "react";
import { PropsWithChildren } from "react";
import {
  MSG_billingCancelDowngradeButton,
  MSG_billingGrandfatheredRate,
  MSG_billingIntervalForever,
  MSG_billingIntervalMonth,
  MSG_billingIntervalYear,
  MSG_billingLifetimePlan,
  MSG_billingSavingsAmount,
  MSG_billingTierAutomaticImports,
  MSG_billingTierCreditSweep,
  MSG_billingTierManualImports,
  MSG_billingTierNumAccounts,
  MSG_billingTierNumEnvelopes,
  MSG_billingTierOneUser,
  MSG_billingTierUnlimited,
  MSG_billingTierUnlimitedAccounts,
  MSG_billingTierUnlimitedEnvelopes,
  MSG_billingUpToNumUsers,
  MSG_completeTierLabel,
  MSG_essentialTierLabel,
  MSG_freeTierLabel,
  MSG_scheduledChangeNotice,
  MSG_selectedPlan,
  MSG_yourCurrentPlan
} from "shared/strings/billing";
import { useIntlFormatters } from "shared/utils/formatters";
import { BILLING_INTERVAL, FEATURE_LEVEL, Product, Subscription } from "shared/utils/api_types";
import styled from "styled-components";
import classnames from "classnames";
import { getIntervalAdjectiveLabel, getPlanName, isLifetimePlan } from "shared/utils/helpers";
import { selectCurrentSubscription, selectProducts, setCurrentSubscription } from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import {
  useCancelScheduledPlanChangeMutation,
  useUndoCancelPlanMutation
} from "shared/state/endpoints/app/subscriptions_api";
import { parseApiError } from "shared/utils/api_errors";
import { MSG_unexpectedError } from "shared/strings/generic";
import { Button } from "@web/components/Button";
import { useAlert } from "@web/utils/hooks";
import { Group } from "shared/utils/api_groups_types";

interface IProps {
  product: Product | null;
  group?: Group;
  currentProduct?: Product | null;
  scheduledProduct?: Product | null;
  subscription?: Subscription;
  annualSavings?: number;
  currentPlanLocation?: 'top' | 'bottom';
  selectedKey?: string | 'free';
  onSelect?: (productKey: string | 'free') => any;
  blocked?: boolean;
}

const BillingTier: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {product} = props;
  const {formatMessage, formatCurrency, formatDate} = useIntlFormatters();
  const currentPlanLocation = props.currentPlanLocation || 'bottom';
  const subscription = useSelector(selectCurrentSubscription)!;
  const allProducts = useSelector(selectProducts);
  const [cancelScheduledChangeMutation] = useCancelScheduledPlanChangeMutation();
  const [undoCancelPlanMutation] = useUndoCancelPlanMutation();
  const [cancelling, setCancelling] = React.useState<boolean>(false);
  const {showErrorAlert} = useAlert();
  const dispatch = useDispatch();

  let label: any, amount: any, features: any[];

  if (!product) {
    label = formatMessage(MSG_freeTierLabel);
    features = [
      formatMessage(MSG_billingTierOneUser),
      formatMessage(MSG_billingTierNumAccounts, {numAccounts: 1}),
      formatMessage(MSG_billingTierNumEnvelopes, {numEnvelopes: 10}),
      formatMessage(MSG_billingTierManualImports),
    ];
  } else if (product.featureLevel === FEATURE_LEVEL.ESSENTIAL) {
    label = formatMessage(MSG_essentialTierLabel);
    features = [
      formatMessage(MSG_billingUpToNumUsers, {numUsers: <Highlight>{2}</Highlight>}),
      formatMessage(MSG_billingTierUnlimitedAccounts, {unlimitedIndicator: <Highlight>{formatMessage(MSG_billingTierUnlimited)}</Highlight>}),
      formatMessage(MSG_billingTierUnlimitedEnvelopes, {unlimitedIndicator: <Highlight>{formatMessage(MSG_billingTierUnlimited)}</Highlight>}),
      // formatMessage(MSG_billingTierAccountsPerLedger, {numAccounts: 1}),
      formatMessage(MSG_billingTierManualImports),
    ];
  } else if (product.featureLevel === FEATURE_LEVEL.COMPLETE) {
    label = formatMessage(MSG_completeTierLabel);
    features = [
      formatMessage(MSG_billingUpToNumUsers, {numUsers: <Highlight>{5}</Highlight>}),
      formatMessage(MSG_billingTierUnlimitedAccounts, {unlimitedIndicator: <Highlight>{formatMessage(MSG_billingTierUnlimited)}</Highlight>}),
      formatMessage(MSG_billingTierUnlimitedEnvelopes, {unlimitedIndicator: <Highlight>{formatMessage(MSG_billingTierUnlimited)}</Highlight>}),
      // formatMessage(MSG_billingTierUnlimitedAccountsPerLedger, {unlimitedIndicator: <Highlight>{formatMessage(MSG_billingTierUnlimited)}</Highlight>}),
      <Highlight key={1}>{formatMessage(MSG_billingTierAutomaticImports)}</Highlight>,
      <Highlight key={2}>{formatMessage(MSG_billingTierCreditSweep)}</Highlight>,
    ];
  } else {
    return null;
  }

  let isGrandfathered = false;
  let isLifetime = false;
  let isSelected = false;
  if (props.selectedKey === 'free' && !product) isSelected = true;
  if (product && props.selectedKey === product.productKey) isSelected = true;

  let displayAmount = product?.prices['usd'].amount || 0;
  let displayInterval = formatMessage(MSG_billingIntervalMonth);
  if (product) {
    if (product.interval === BILLING_INTERVAL.YEAR) {
      displayInterval = formatMessage(MSG_billingIntervalYear);
    } else if (product.interval === BILLING_INTERVAL.LIFETIME) {
      displayInterval = formatMessage(MSG_billingIntervalForever);
    }
    if (product.interval !== BILLING_INTERVAL.LIFETIME && product.intervalCount > 1) {
      displayInterval = `${product.intervalCount}-${displayInterval}`;
    }
  } else {
    displayInterval = formatMessage(MSG_billingIntervalForever);
  }

  let isCurrent = false;
  if (props.currentProduct &&
    props.currentProduct.featureLevel === product?.featureLevel &&
    (props.currentProduct.interval === product?.interval || props.currentProduct.interval === BILLING_INTERVAL.LIFETIME)) {
    isCurrent = true;
  }
  if (props.currentProduct === null && props.product === null) {
    isCurrent = true;
  }

  if (isCurrent) {
    isCurrent = true;
    if (props.currentProduct) {
      displayAmount = props.currentProduct.prices['usd'].amount;
      displayInterval = formatMessage(MSG_billingIntervalMonth);
      if (props.currentProduct.interval === BILLING_INTERVAL.YEAR) {
        displayInterval = formatMessage(MSG_billingIntervalYear);
      } else if (props.currentProduct.interval === BILLING_INTERVAL.LIFETIME) {
        displayInterval = formatMessage(MSG_billingIntervalForever);
      }
      if (props.currentProduct.interval !== BILLING_INTERVAL.LIFETIME && props.currentProduct.intervalCount > 1) {
        displayInterval = `${props.currentProduct.intervalCount}-${displayInterval}`;
      }
      if (props.currentProduct.legacy) {
        isGrandfathered = true;
      }
      if (isLifetimePlan(props.currentProduct)) {
        isLifetime = true;
      }
    } else {
      displayAmount = 0;
      displayInterval = formatMessage(MSG_billingIntervalForever);
    }
  }

  amount = (
    <React.Fragment>
      {formatCurrency(displayAmount, subscription, {showSymbol: true})}
      <TierAmountInterval>/{displayInterval}</TierAmountInterval>
    </React.Fragment>
  );

  function onClick() {
    if (isCurrent) return;
    if (isSelected) return;
    if (!props.onSelect) return;
    props.onSelect(product?.productKey || 'free');
  }

  async function cancelScheduledChange() {
    if (!subscription) return;
    setCancelling(true);
    try {
      let newSubscription: Subscription;
      if (props.scheduledProduct) {
        newSubscription = await cancelScheduledChangeMutation({}).unwrap();
      } else {
        newSubscription = await undoCancelPlanMutation({}).unwrap();
      }
      if (newSubscription) {
        dispatch(setCurrentSubscription(newSubscription));
      }
    } catch (e) {
      let error = parseApiError(e);
      showErrorAlert(error.errorType === 'message' ? error.message : formatMessage(MSG_unexpectedError));
    }
    setCancelling(false);
  }

  return (
    <Tier
      className={classnames({
        current: isCurrent,
        clickable: !isSelected && !isCurrent && !!props.onSelect,
        selected: isSelected
      })}
      onClick={props.blocked ? undefined : onClick}
    >
      {isCurrent && currentPlanLocation === 'top' && (
        <TierCurrentIndicator>
          {formatMessage(MSG_yourCurrentPlan)}
        </TierCurrentIndicator>
      )}
      <TierLabel>{label}</TierLabel>

      {!props.group ? (
        <React.Fragment>
          <TierAmount>{amount}</TierAmount>
          {props.annualSavings && !props.currentProduct?.legacy && product?.interval === BILLING_INTERVAL.YEAR && !isLifetime && (
            <TierSavings>{
              formatMessage(MSG_billingSavingsAmount, {
                amount: formatCurrency(props.annualSavings, subscription, {showSymbol: true}),
                interval: formatMessage(MSG_billingIntervalYear)
              })
            }</TierSavings>
          )}
        </React.Fragment>
      ) : (
        <div className="mb-2"/>
      )}

      <TierBody>
        {isGrandfathered && <Badge>{formatMessage(MSG_billingGrandfatheredRate)}</Badge>}
        {isLifetime && <Badge>{formatMessage(MSG_billingLifetimePlan)}</Badge>}

        {features.map((feature, index) => (
          <div key={index}>{feature}</div>
        ))}

        {(props.scheduledProduct || props.subscription?.stripeCancelAt) && subscription && allProducts && (
          <ScheduledChangeNotice>
            {props.scheduledProduct ? (
              formatMessage(MSG_scheduledChangeNotice, {
                newPlanName: getPlanName(props.scheduledProduct.productKey, allProducts, formatMessage),
                newInterval: getIntervalAdjectiveLabel(props.scheduledProduct.productKey, allProducts, formatMessage),
                date: formatDate(subscription?.stripePeriodEndsAt, subscription)
              })
            ) : (
              formatMessage(MSG_scheduledChangeNotice, {
                newPlanName: formatMessage(MSG_freeTierLabel),
                newInterval: formatMessage(MSG_billingIntervalForever),
                date: props.subscription?.stripePeriodEndsAt ? formatDate(props.subscription.stripePeriodEndsAt, subscription) : 'Unknown Date'
              })
            )}
            <div className="mt-4">
              <Button color="secondary" onClick={cancelScheduledChange} disabled={cancelling} spinner={cancelling}>
                {formatMessage(MSG_billingCancelDowngradeButton)}
              </Button>
            </div>
          </ScheduledChangeNotice>
        )}

        {props.children && <TierChildren>{props.children}</TierChildren>}
      </TierBody>

      {isCurrent && currentPlanLocation === 'bottom' && (
        <TierCurrentIndicator>
          {formatMessage(MSG_yourCurrentPlan)}
        </TierCurrentIndicator>
      )}
      {isSelected && (
        <TierSelectedIndicator>
          {formatMessage(MSG_selectedPlan)}
        </TierSelectedIndicator>
      )}

      {props.blocked && (
        <TierBlocker>
          <p>Coming soon!</p>
        </TierBlocker>)}
    </Tier>
  );

};

const Tier = styled.div`
  position: relative;
  border: 4px solid ${({theme}) => theme.innerSidebar.borderColor};
  text-align: center;
  padding: 0;
  border-radius: ${({theme}) => theme.raisedContainer.borderRadius};
  width: 18rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.current {
    border-color: ${({theme}) => theme.colors.warning};
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      border-color: #999;
    }
  }

  &.selected {
    border-color: ${({theme}) => theme.colors.primary};
  }
`;

const TierLabel = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.5rem;
  background: ${({theme}) => theme.innerSidebar.backgroundColor};
`;

const TierAmount = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem;
`;

const TierAmountInterval = styled.span`
  font-size: 1rem;
  font-weight: normal;
`;

const TierBody = styled.div`
  padding: 1rem;
  padding-top: 0;
  background: #fff;
  flex-grow: 1;
`;

const TierChildren = styled.div`
  padding-top: 1rem;
`;

const TierSavings = styled.div`
  font-weight: bold;
  color: ${({theme}) => theme.colors.warning};
  padding: 0.5rem;
  padding-top: 0;
`;

const TierCurrentIndicator = styled.div`
  background: ${({theme}) => theme.colors.warning};
  color: #fff;
  padding: 0.5rem;
  font-weight: bold;
`;

const TierSelectedIndicator = styled.div`
  background: ${({theme}) => theme.colors.primary};
  color: #fff;
  padding: 0.5rem;
  font-weight: bold;
`;

const Highlight = styled.span`
  color: ${({theme}) => theme.colors.primary};
  font-weight: bold;
`;

const Badge = styled.div`
  background: ${({theme}) => theme.colors.warning};
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  display: inline-block;
`;

const ScheduledChangeNotice = styled.div`
  background: ${({theme}) => theme.colors.warning};
  color: #fff;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
  padding: 1rem;
  margin-top: 1rem;

  a {
    color: #fff;
    font-weight: bold;
    &:hover {
      opacity: 0.75;
    }
  }
`;

const TierBlocker = styled.div`
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  margin: -1rem;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    transform: rotate(-7deg);
    font-weight: bold;
    color: $warning_color;
    font-size: 1.6rem;
    text-transform: uppercase;
    background: #fffa;
    box-shadow: 0 0 15px #fffa;
  }
`;

export {BillingTier};
