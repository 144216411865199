import { configureStore, Middleware } from '@reduxjs/toolkit'
import { baseApi } from "./endpoints/base_api";
import { dataSlice, incrementErrorCount, resetErrorCount } from "./slices/data_slice";
import { globalSlice } from "./slices/global_slice";

const errorHandlingMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  // Let the action pass through
  next(action);

  // Check if the action is from our API
  if (action.type.startsWith(baseApi.reducerPath)) {
    // Check if the action represents a failed request
    if (action.type.startsWith('api/executeQuery/')) {
      if (action.type.endsWith('rejected')) {
        const errorResponse = action.payload;
        // Check if the error is a 500-level error
        if (errorResponse && errorResponse.status >= 500 || errorResponse.originalStatus >= 500) {
          // Perform special actions here
          storeAPI.dispatch(incrementErrorCount(storeAPI.getState()));
        } else {
          // For other errors, reset the counter
          storeAPI.dispatch(resetErrorCount(storeAPI.getState()));
        }
      } else if (action.type.endsWith('fulfilled')) {
        // For successful requests, reset the counter
        storeAPI.dispatch(resetErrorCount(storeAPI.getState()));
      }
    }
  }
};

export const store = configureStore({
  reducer: {
    data: dataSlice.reducer,
    global: globalSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(baseApi.middleware, errorHandlingMiddleware);
  },
})

export * from "./slices/data_slice";

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

