import * as React from "react";
import { PropsWithChildren } from "react";
import { Transaction } from "shared/utils/api_types";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import classNames from "classnames";
import { StyledRaisedContainer } from "@web/components/styled/StyledRaisedContainer";
import { MSG_editButton } from "shared/strings/generic";
import { ITransactionTextEditorModal } from "../transaction_text_editor_modal/TransactionTextEditorModal";
import { useDrag } from "react-dnd";
import { Spinner } from "@web/components/Spinner";
import { getEmptyImage } from "react-dnd-html5-backend";
import { AllocationModalContext } from "./context";
import { selectCurrentSubscription } from "shared/state/store";
import { useSelector } from "react-redux";

interface IProps {
  transaction: Transaction;
  editorRef?: React.RefObject<ITransactionTextEditorModal>
  showCount?: number;
  maxWidth?: number;
  isPreview?: boolean;
}

const DraggableTransaction: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const context = React.useContext(AllocationModalContext);
  const subscription = useSelector(selectCurrentSubscription)!;
  const {formatCurrency, formatDate, formatMessage} = useIntlFormatters();
  const transaction = props.transaction;
  const isSelected = !!context.selected.find(t => t.id === transaction.id) || context.dragging?.id === transaction.id;
  const isWorking = !!context.working.find(t => t.id === transaction.id);
  const [{ opacity }, dragRef, preview] = useDrag(() => ({
    type: 'unallocated-transaction',
    item: () => {
      context.setDragging(transaction);
      return transaction;
    },
    end: () => {
      context.setDragging(null);
    },
    isDragging: (monitor) => {
      return monitor.getItem()?.id === transaction.id || isSelected;
    },
    collect: (monitor) => ({
      opacity: !props.isPreview && monitor.isDragging() ? 0.25 : 1,
    })
  }), [isSelected, context.dragging, transaction]);

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <Container ref={dragRef} style={{width: props.maxWidth}}>
      <InnerContainer style={{opacity, margin: props.isPreview ? 0 : undefined}}
                      className={classNames({working: isWorking, selected: isSelected})}
                      onClick={() => {
                        context.toggleSelection(transaction);
                      }}>
        {!props.isPreview && (
          <div style={{flexShrink: 1}}>
            <input type="checkbox" checked={isSelected} readOnly/>
          </div>)}
        <LeftSection>
          <DescriptionContainer>{transaction.description}</DescriptionContainer>
          {transaction.notes && <NotesContainer>{transaction.notes}</NotesContainer>}
          <div className="d-flex flex-row">
            <DateContainer>{formatDate(transaction.date, subscription)}</DateContainer>
            {props.editorRef?.current && (
              <EditButton className="edit-button" onClick={(e) => {
                e.stopPropagation();
                return props.editorRef?.current?.show(transaction);
              }}>
                {formatMessage(MSG_editButton)}
              </EditButton>)}
          </div>
        </LeftSection>

        <RightSection>
          <AmountContainer className={classNames({negative: transaction.amount < 0})}>
            {formatCurrency(transaction.amount, subscription)}
          </AmountContainer>
          <AccountContainer>
            {transaction.account?.name}
          </AccountContainer>
        </RightSection>

        {props.showCount && <Counter>{props.showCount}</Counter>}
      </InnerContainer>

      {isWorking && (<SpinnerContainer><Spinner centered/></SpinnerContainer>)}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const InnerContainer = styled(StyledRaisedContainer)`
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  input[type="checkbox"] {
    margin-right: 0.5rem;
  }

  &:hover {
    .edit-button {
      display: inline-block;
    }
  }

  &.working {
    opacity: 0.25 !important;
  }

  &.selected {
    background-color: ${({theme}) => theme.unallocatedWindow.dropboxActiveBackgroundColor };
  }
`;

const LeftSection = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 0.5rem;
`;

const NotesContainer = styled.div`
  font-size: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateContainer = styled.div`
  font-size: 80%;
  opacity: 0.75;
`;

const AmountContainer = styled.div`
  text-align: right;
  font-weight: bold;
  font-size: 110%;

  &.negative {
    color: ${({theme}) => theme.colors.negativeTextColor };
  }
`;

const AccountContainer = styled.div`
  font-size: 80%;
  opacity: 0.75;
  text-align: right;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DescriptionContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EditButton = styled.span`
  font-weight: bold;
  text-decoration: underline;
  font-size: 80%;
  margin-left: 0.5rem;
  cursor: pointer;
  display: none;

  &:hover {
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120%;
`;

const Counter = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  border-radius: 100%;
  background-color: #c00;
  color: #fff;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
`;

export {DraggableTransaction};
