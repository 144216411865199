import { PayloadAction } from "@reduxjs/toolkit";
import { Ledger } from "../../../utils/api_types";
import { IDataState } from "../data_slice";
import { dataStateSetters } from "./setters";

export const ledgerDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  setLedger: (state, action: PayloadAction<Ledger>) => {
    dataStateSetters.setLedger(state, action.payload);
  },

  setLedgers: (state, action: PayloadAction<Ledger[]>) => {
    dataStateSetters.setLedgers(state, action.payload);
  }
};
