import { baseApi } from "../base_api";
import { Ledger, Product, Subscription, User } from "../../../utils/api_types";
import { fromApiLedger, fromApiProduct, fromApiSubscription, fromApiUser } from "../../../utils/api_transformations";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    bootstrap: builder.query<{currentUser: User, users: User[], subscription: Subscription, ledgers: Ledger[], products: Product[]}, void>({
      query: () => {
        let params = new URLSearchParams();
        params.append('expand[]', 'current_user');
        params.append('expand[]', 'subscription');
        params.append('expand[]', 'users');
        params.append('expand[][ledgers][]', 'envelopes');
        params.append('expand[][ledgers][]', 'income_sources');
        params.append('expand[][ledgers][]', 'income_allocations');
        params.append('expand[][ledgers][]', 'budgeted_expenses');
        params.append('expand[][ledgers][]', 'unallocated_count');
        params.append('expand[][ledgers][][accounts][]', 'unallocated_count');
        params.append('expand[][ledgers][][accounts][]', 'pending_reconcile_ids');
        params.append('expand[][ledgers][][accounts][]', 'pending_reconcile_amount');

        return ({
          url: `/bootstrap?${params.toString()}`,
        });
      },

      transformResponse: (response: any) => {
        return {
          products: response.products.map(fromApiProduct),
          currentUser: fromApiUser(response['current_user']),
          users: response['users'].map(fromApiUser),
          subscription: fromApiSubscription(response['subscription']),
          ledgers: response['ledgers'].map(fromApiLedger),
        }
      }
    }),
  }),
  overrideExisting: false,
});

export const { useBootstrapQuery, useLazyBootstrapQuery } = extendedApi;

