import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyledTextHeader } from "@web/components/styled/StyledText";
import { MSG_accountsTitle, MSG_noAccounts, MSG_thisLedgerTitle } from "shared/strings/settings";
import {
  StyledCard,
  StyledCardSettingsBody,
  StyledCardSettingsIcon,
  StyledCardSettingsLabel
} from "@web/components/styled/StyledCard";
import { useIntlFormatters } from "shared/utils/formatters";
import { LedgerNameSettings } from "@web/pages/app/authenticated/ledger/settings/LedgerNameSettings";
import { selectAccountsForLedger } from "shared/state/slices/data_selectors/account_data_selectors";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { AccountSettingsItem } from "./AccountSettingsItem";
import { faEmptySet, faMerge, faPlusCircle, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@web/components/Button";
import { MSG_combineLedgersButton, MSG_deleteLedgerButton } from "shared/strings/ledgers";
import { DeleteLedgerConfirmationPopover } from "@web/popovers/delete_ledger_popover/DeleteLedgerConfirmationPopover";
import { IPopover } from "@web/components/Popover";
import { selectCurrentUser } from "shared/state/slices/data_selectors/core_data_selectors";
import { BLANK_ACCOUNT, ROLE } from "shared/utils/api_types";
import { MSG_newAccountButton } from "shared/strings/accounts";
import { AccountEditorModal, IAccountEditorModal } from "@web/modals/account_editor_modal/AccountEditorModal";
import { CombineLedgersModal, ICombineLedgersModal } from "@web/modals/combine_ledgers_modal/CombineLedgersModal";

interface IProps {}

const LedgerSettingsPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const { formatMessage } = useIntlFormatters();
  const ledgerId = useParams<{ledgerId: string}>().ledgerId!;
  const accounts = useSelector(selectAccountsForLedger(ledgerId));
  const deleteButtonPopoverRef = React.useRef<IPopover>(null);
  const deleteButtonRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const editable = currentUser?.role === ROLE.OWNER;
  const accountEditorModalRef = React.useRef<IAccountEditorModal>(null);
  const combineLedgersModalRef = React.useRef<ICombineLedgersModal>(null);

  return (
    <div className="flex-row align-items-start justify-content-stretch">
      <Column style={{width: '45%'}}>
        <div className="flex-row align-items-center mb-4">
          <StyledTextHeader className="mb-0">
            {formatMessage(MSG_thisLedgerTitle)}
          </StyledTextHeader>
        </div>
        <StyledCard>
          <LedgerNameSettings/>
        </StyledCard>

        {editable && (
          <React.Fragment>
            <div className="d-flex flex-row align-items-center justify-content-space-between mt-2">
              <Button onClick={() => { deleteButtonPopoverRef.current?.show(); }} link
                      className="me-2" ref={deleteButtonRef}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-1"/>
                {formatMessage(MSG_deleteLedgerButton)}
              </Button>

              <Button onClick={() => combineLedgersModalRef.current?.show()}>
                <FontAwesomeIcon icon={faMerge} className="me-1"/>
                {formatMessage(MSG_combineLedgersButton)}
              </Button>
            </div>

            <DeleteLedgerConfirmationPopover
              ref={deleteButtonPopoverRef}
              targetRef={deleteButtonRef}
              ledgerId={ledgerId}
              afterDelete={async () => {
                navigate('/app');
              }}
            />
          </React.Fragment>)}
      </Column>
      <Column style={{width: '55%'}}>
        <div className="flex-row align-items-center mb-4">
          <StyledTextHeader className="mb-0">
            {formatMessage(MSG_accountsTitle)}
          </StyledTextHeader>
        </div>

        <StyledCard>
          {accounts.length === 0 && (
            <StyledCardSettingsBody>
              <StyledCardSettingsIcon>
                <FontAwesomeIcon icon={faEmptySet}/>
              </StyledCardSettingsIcon>
              <StyledCardSettingsLabel>
                {formatMessage(MSG_noAccounts)}
              </StyledCardSettingsLabel>
            </StyledCardSettingsBody>
          )}

          {accounts.map((account, i) => (
            <AccountSettingsItem key={account.id} account={account} currentIndex={i}/>
          ))}
        </StyledCard>

        <div className="text-center mt-2">
          <Button link onClick={() => {
            accountEditorModalRef.current?.show({...BLANK_ACCOUNT, ledgerId: ledgerId!});
          }}>
            <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
            {formatMessage(MSG_newAccountButton)}
          </Button>
        </div>

        <AccountEditorModal ref={accountEditorModalRef}/>
        <CombineLedgersModal ref={combineLedgersModalRef}/>
      </Column>
    </div>
  );
};

const Column = styled.div`
  margin-right: 2rem;
`;

export { LedgerSettingsPage };
