import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

interface IProps {
  className?: string;
}

const Pulse: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  return (
    <PulseContainer className={props.className}>
      {props.children}
    </PulseContainer>
  );
};

const PulseContainer = styled.div`
  border-radius: ${({theme}) => theme.raisedContainer.borderRadius };;
  box-shadow: 0 0 5px rgba(255,255,255);
  animation: pulse 2s infinite;
`;

export {Pulse};
