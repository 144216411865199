import * as React from "react";
import { forwardRef, RefObject } from "react";
import { IPopover, Popover } from "../Popover";
import {
  MSG_deleteConfirmationButton,
  MSG_deleteTransactionConfirmation,
  MSG_deleteTransactionConfirmationExplanation
} from "shared/strings/transactions";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "../Button";
import { MSG_cancelButton } from "shared/strings/generic";
import { useDeleteTransactionMutation } from "shared/state/endpoints/app/transactions_api";
import { flatten } from "lodash";

interface IDeleteTransactionConfirmationPopover {
  show: (ids: string | string[]) => any;
  hide: () => any;
}

interface IProps {
  targetRef: RefObject<any>;
  beforeDelete?: () => Promise<any>;
  afterDelete?: () => Promise<any>;
  afterCancel?: () => any;
  ledgerId: string;
}

const DeleteTransactionConfirmationPopover = forwardRef<IDeleteTransactionConfirmationPopover, IProps>((props, ref) => {
  const popoverRef = React.useRef<IPopover>(null);
  const {formatMessage} = useIntlFormatters();
  const [transactionIds, setTransactionIds] = React.useState<string[]>([]);
  const [isWorking, setIsWorking] = React.useState<boolean>(false);
  const [deleteTransaction] = useDeleteTransactionMutation();

  React.useImperativeHandle<any, IDeleteTransactionConfirmationPopover>(ref, () => ({
    show: (ids: string | string[]) => {
      setTransactionIds(flatten([ids]));
      return popoverRef.current?.show();
    },
    hide: () => popoverRef.current?.hide(),
  }));

  async function onClick() {
    setIsWorking(true);
    if (props.beforeDelete) await props.beforeDelete();
    await Promise.all(transactionIds.map(id => deleteTransaction({ledgerId: props.ledgerId, transactionId: id})));
    if (props.afterDelete) void props.afterDelete();
    popoverRef.current?.hide();
    setIsWorking(false);
  }

  return (
    <Popover ref={popoverRef}
             targetRef={props.targetRef}
             disableDismissOnOutsideClick={true}
             placement={'bottom'}>
      <Container>
        <Question>{formatMessage(MSG_deleteTransactionConfirmation, {count: transactionIds.length})}</Question>
        <Explanation>{formatMessage(MSG_deleteTransactionConfirmationExplanation)}</Explanation>
        <div className="d-flex flex-row">
          <Button color="secondary"
                  disabled={isWorking}
                  onClick={() => {
                    popoverRef.current?.hide();
                    if (props.afterCancel) props.afterCancel();
                  }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button color="danger" onClick={onClick} disabled={isWorking} spinner={isWorking}>
            {formatMessage(MSG_deleteConfirmationButton, {count: transactionIds.length})}
          </Button>
        </div>
      </Container>
    </Popover>
  );
});

const Container = styled.div`
  padding: 1rem;
  max-width: 35rem;
`;

const Question = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${({theme}) => theme.colors.danger };
`;

const Explanation = styled.div`
  margin-bottom: 1rem;
`;

export {DeleteTransactionConfirmationPopover, IDeleteTransactionConfirmationPopover};
