import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

interface IProps {
  className?: string;
  value: any;
  options: {label: any, value: any, sublabel?: any}[];
  onChange: (value: any) => void;
}

const HorizontalOptionsInput: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const [value, setValue] = React.useState<any>(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <InputContainer className={props.className}>
      <OptionsWrapper>
        {props.options.map((options, i) => {
          return (
            <Option key={i}
                    className={value === options.value ? 'active' : ''}
                    onClick={() => {
                      props.onChange(options.value);
                    }}>
              <div className="label">{options.label}</div>
              {options.sublabel && <div className="sublabel">{options.sublabel}</div>}
            </Option>
          );
        })}
      </OptionsWrapper>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const OptionsWrapper = styled.div`
  border-style: solid;
  border-width: 1px;
  border-radius: ${({theme}) => theme.inputContainer.borderRadius};
  border-color: ${({theme}) => theme.colors.lightBorder};
  background-color: ${({theme}) => theme.inputContainer.backgroundColor};
  padding: 0.25rem;
  font-weight: bold;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  &::placeholder {
    color: ${({theme}) => theme.colors.lightBorder};
  }

  &:focus {
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 0.125em ${({theme}) => theme.colors.primary}44;
  }

  &:disabled {
    opacity: 0.75;
  }
`;

const Option = styled.div`
  display: inline-block;
  padding: 0.5rem 1.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label {
  }

  .sublabel {
    font-size: 90%;
    font-weight: normal;
  }

  &:not(:last-child) {
    margin-right: 0.25rem;
  }

  &:hover {
    background: ${({theme}) => theme.colors.lightBackgroundColor};
  }
  &.active {
    background: ${({theme}) => theme.colors.primary};
    color: #fff;
    border-color: ${({theme}) => theme.colors.primary};
  }
`;

export {HorizontalOptionsInput};
