import * as React from "react";
import { Modal } from "@web/components/Modal";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { Elements } from '@stripe/react-stripe-js';
import { CardForm, ICardForm } from "./CardForm";
import { loadStripe } from "@stripe/stripe-js";
import { NB_CONFIG } from "shared/utils/config";

const stripe = loadStripe(NB_CONFIG.stripePublishableKey);

interface IProps {
  saveButtonLabel?: string;
}

interface IPaymentMethodModal  {
  show: () => any;
  hide: () => any;
}

const PaymentMethodModal = React.forwardRef<IPaymentMethodModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [working, setWorking] = React.useState<boolean>(false);
  const {formatMessage} = useIntlFormatters();
  const cardFormRef = React.useRef<ICardForm>(null);

  React.useImperativeHandle<any, IPaymentMethodModal>(ref, () => ({
    show: () => {
      setVisible(true);
      cardFormRef.current?.reset();
    },

    hide: () => {
      setVisible(false);
    }
  }));

  const save = async () => {
    setWorking(true);
    try {
      if (await cardFormRef.current?.save()) {
        setVisible(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setWorking(false);
    }
  };

  return (
    <Modal
      defaultFormAction={save}
      windowStyle={{width: '38rem', minHeight: '10rem'}}
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      bodyStyle={{padding: '1rem', paddingBottom: '2rem'}}
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={working}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={save}
                  disabled={working} spinner={working}>
            {props.saveButtonLabel || formatMessage(MSG_saveButton)}
          </Button>
        </div>
      }>

      <Elements stripe={stripe}>
        <CardForm ref={cardFormRef}/>
      </Elements>

    </Modal>
  );
});

export {PaymentMethodModal, IPaymentMethodModal};
