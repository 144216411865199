import { IDataState, TransactionsListType } from "../data_slice";
import {
  Account,
  BudgetedExpense,
  Envelope,
  IncomeAllocation,
  IncomeSource,
  Ledger,
  User
} from "../../../utils/api_types";

// These are a collection of setters that are used ONLY inside the reducers.  They are
// pulled out here so we can reuse some setter logic in multiple reducers, but should
// never be used outside of the reducers.

export const dataStateSetters = {
  setLedger(state: IDataState, ledger: Ledger) {
    state.ledgersHash[ledger.id] = ledger;
    if (ledger.accounts) dataStateSetters.setAccountsForLedger(state, ledger.id, ledger.accounts);
    if (ledger.envelopes) dataStateSetters.setEnvelopesForLedger(state, ledger.id, ledger.envelopes);
    if (ledger.budgetedExpenses) dataStateSetters.setBudgetedExpensesForLedger(state, ledger.id, ledger.budgetedExpenses);
    if (ledger.incomeSources) dataStateSetters.setIncomeSourcesForLedger(state, ledger.id, ledger.incomeSources);
    if (ledger.incomeAllocations) dataStateSetters.setIncomeAllocationsForLedger(state, ledger.id, ledger.incomeAllocations);
  },

  setLedgers(state: IDataState, ledgers: Ledger[]) {
    state.ledgersHash = {};
    ledgers.forEach(ledger => {
      dataStateSetters.setLedger(state, ledger);
    });
  },

  setAccount(state: IDataState, account: Account) {
    if (!state.accountsForLedgerHash[account.ledgerId]) {
      state.accountsForLedgerHash[account.ledgerId] = {};
    }
    state.accountsForLedgerHash[account.ledgerId][account.id] = account;
  },

  setAccountsForLedger(state: IDataState, ledgerId: string, accounts: Account[]) {
    state.accountsForLedgerHash[ledgerId] = {};
    state.reconciledTotalForAccountsHash[ledgerId] ||= {};
    accounts.forEach(account => {
      dataStateSetters.setAccount(state, account);
    });
  },

  setEnvelope(state: IDataState, envelope: Envelope) {
    if (!state.envelopesForLedgerHash[envelope.ledgerId]) {
      state.envelopesForLedgerHash[envelope.ledgerId] = {};
    }
    state.envelopesForLedgerHash[envelope.ledgerId][envelope.id] = envelope;
  },

  setEnvelopesForLedger(state: IDataState, ledgerId: string, envelopes: Envelope[]) {
    state.envelopesForLedgerHash[ledgerId] = {};
    envelopes.forEach(envelope => {
      dataStateSetters.setEnvelope(state, envelope);
    });
  },

  setBudgetedExpense(state: IDataState, budgetedExpense: BudgetedExpense) {
    if (!state.budgetedExpensesForLedgerHash[budgetedExpense.ledgerId]) {
      state.budgetedExpensesForLedgerHash[budgetedExpense.ledgerId] = {};
    }
    state.budgetedExpensesForLedgerHash[budgetedExpense.ledgerId][budgetedExpense.id] = budgetedExpense;
  },

  setBudgetedExpensesForLedger(state: IDataState, ledgerId: string, budgetedExpenses: BudgetedExpense[]) {
    state.budgetedExpensesForLedgerHash[ledgerId] = {};
    budgetedExpenses.forEach(budgetedExpense => {
      dataStateSetters.setBudgetedExpense(state, budgetedExpense);
    });
  },

  setIncomeSource(state: IDataState, incomeSource: IncomeSource) {
    if (!state.incomeSourcesForLedgerHash[incomeSource.ledgerId]) {
      state.incomeSourcesForLedgerHash[incomeSource.ledgerId] = {};
    }
    state.incomeSourcesForLedgerHash[incomeSource.ledgerId][incomeSource.id] = incomeSource;
  },

  setIncomeSourcesForLedger(state: IDataState, ledgerId: string, incomeSources: IncomeSource[]) {
    state.incomeSourcesForLedgerHash[ledgerId] = {};
    incomeSources.forEach(incomeSource => {
      dataStateSetters.setIncomeSource(state, incomeSource);
    });
  },

  setIncomeAllocation(state: IDataState, incomeAllocation: IncomeAllocation) {
    if (!state.incomeAllocationsForLedgerHash[incomeAllocation.ledgerId]) {
      state.incomeAllocationsForLedgerHash[incomeAllocation.ledgerId] = {};
    }
    state.incomeAllocationsForLedgerHash[incomeAllocation.ledgerId][incomeAllocation.id] = incomeAllocation;
  },

  setIncomeAllocationsForLedger(state: IDataState, ledgerId: string, incomeAllocations: IncomeAllocation[]) {
    state.incomeAllocationsForLedgerHash[ledgerId] = {};
    incomeAllocations.forEach(incomeAllocation => {
      dataStateSetters.setIncomeAllocation(state, incomeAllocation);
    });
  },

  setUser(state: IDataState, user: User) {
    state.usersHash[user.id] = user;
    if (user.id === state.currentUser?.id) {
      state.currentUser = user;
    }
  },

  getTransactionsListData(state: IDataState, listType: TransactionsListType) {
    switch (listType) {
      case "main":
        return state.mainTransactionsList;
      case "unallocated":
        return state.unallocatedTransactionsList;
      case "unreconciled":
        return state.unreconciledTransactionsList;
      default:
        throw new Error(`Invalid transactions list type: ${listType}`);
    }
  }
};
