import * as React from "react";
import { PropsWithChildren } from "react";
import { IncomeAllocation, IncomeSource, ROLE, User } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_splitAmountEvenly } from "shared/strings/budget";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkButton } from "@web/components/LinkButton";
import { frequencyHasExtra, getNumChecks } from "shared/utils/helpers";
import { SplitFundingPopover } from "../../popovers/split_funding_popover/SplitFundingPopover";
import { IPopover } from "@web/components/Popover";
import classnames from "classnames";
import { selectCurrentSubscription, selectCurrentUser } from "shared/state/store";
import { useSelector } from "react-redux";
import { FundingInput } from "@web/modals/envelope_budget_editor_modal/FundingInput";
import styled from "styled-components";

interface IProps {
  envelopeId: string;
  incomeSource: IncomeSource;
  incomeAllocations: Partial<IncomeAllocation>[];
  numChecks: number;
  onChange: (allocation: Partial<IncomeAllocation>) => any;
  onSplitEvenly: (includingExtra?: boolean) => any;
  totalExpenses: number;
  index: number;
}

const MonthlyFundingInputRow: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatFrequency, formatMessage, formatCurrency} = useIntlFormatters();
  const subscription = useSelector(selectCurrentSubscription)!;
  const numChecks = getNumChecks(props.incomeSource.frequency);
  const hasExtra = frequencyHasExtra(props.incomeSource.frequency);
  const splitFundingPopoverRef = React.useRef<IPopover>(null);
  const splitEvenlyButtonRef = React.useRef<HTMLButtonElement>(null);
  const currentUser = useSelector(selectCurrentUser) as User;

  return (
    <RowStyle>
      <td className={classnames("text-right", {"pt-4": props.index > 0})}>
        <div className="text-nowrap-ellipsis"
             style={{width: '13rem'}}>{props.incomeSource.name}</div>
        <div className="text-small text-muted text-nowrap">
          {formatFrequency(props.incomeSource.amount, props.incomeSource.frequency, subscription)}
        </div>
        {!!props.totalExpenses && currentUser.role !== ROLE.OBSERVER && (
          <div className="text-small text-muted text-nowrap">
            {hasExtra ? (
              <React.Fragment>
                <LinkButton ref={splitEvenlyButtonRef}
                            onClick={() => splitFundingPopoverRef.current?.show()}
                            className="text-small">
                  {formatMessage(MSG_splitAmountEvenly, {amount: formatCurrency(-1 * props.totalExpenses, subscription)})}
                </LinkButton>
                &nbsp;<FontAwesomeIcon icon={faAngleRight}/>

                <SplitFundingPopover
                  ref={splitFundingPopoverRef}
                  targetRef={splitEvenlyButtonRef}
                  onSelect={props.onSplitEvenly}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <LinkButton onClick={() => props.onSplitEvenly()} className="text-small">
                  {formatMessage(MSG_splitAmountEvenly, {amount: formatCurrency(-1 * props.totalExpenses, subscription)})}
                </LinkButton>
                &nbsp;<FontAwesomeIcon icon={faAngleRight}/>
              </React.Fragment>
            )}
          </div>)}
      </td>
      {Array.from(Array(numChecks).keys()).map((i) => (
        <FundingInput
          key={i}
          index={i}
          disabled={currentUser.role === ROLE.OBSERVER}
          incomeSource={props.incomeSource}
          incomeAllocations={props.incomeAllocations}
          onChange={props.onChange}
          envelopeId={props.envelopeId}
        />
      ))}
      <td width="25%">&nbsp;</td>
    </RowStyle>
  );
};

const RowStyle = styled.tr`
  td {
    padding: 1rem 0;
    // border-top: 1px solid ${(props) => props.theme.colors.lightBackgroundColor};
  }
`;

export {MonthlyFundingInputRow};
