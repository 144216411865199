import { baseApi } from "../base_api";
import { BudgetVsActualDatum, IncomeVsExpenseDatum } from "../../utils/api_types";

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetVsActualReport: builder.query<{months: {[date: string]: BudgetVsActualDatum}}, {ledgerId: string, envelopeId?: string | null, range?: string}>({
      query: ({ledgerId, envelopeId, range}) => {
        let params = new URLSearchParams();
        if (envelopeId) params.append('envelope_id', envelopeId);
        if (range) params.append('range', range);
        return ({
          url: `/reports/${ledgerId}/budget_vs_actual?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return response;
      }
    }),

    getIncomeVsExpenseReport: builder.query<{months: {[date: string]: IncomeVsExpenseDatum}}, {ledgerId: string, envelopeId?: string | null, range?: string}>({
      query: ({ledgerId, envelopeId, range}) => {
        let params = new URLSearchParams();
        if (envelopeId) params.append('envelope_id', envelopeId);
        if (range) params.append('range', range);
        return ({
          url: `/reports/${ledgerId}/income_vs_expense?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return response;
      }
    }),
  })
});

export const {
  useGetBudgetVsActualReportQuery, useLazyGetBudgetVsActualReportQuery,
  useGetIncomeVsExpenseReportQuery, useLazyGetIncomeVsExpenseReportQuery,
} = reportsApi;
