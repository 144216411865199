import * as React from "react";
import { PropsWithChildren } from "react";

interface IProps {
  onSubmit?: () => any;
}

const Form: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit && props.onSubmit();
  };

  return (
    <form onSubmit={onSubmit} style={{flexGrow: 1}}>
      {props.children}
    </form>
  );
};

export {Form};
