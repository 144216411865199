export const MSG_budgetTitle = {
  id: "97b48fca-c61f-4c56-a5fe-3bd2346316e9",
  description: "The section of the site where they can plan out their budget.",
  defaultMessage: "Budget"
};

export const MSG_monthlyIncomeTitle = {
  id: "57360bdd-b0d0-4b48-b5a4-3fd4b19c4488",
  description: "This is the title of a section where they can see their total monthly income.",
  defaultMessage: "Monthly Income"
};

export const MSG_monthlyIncomeExplanation = {
  id: "f986df37-7cc9-430b-a865-561f51f58f5d",
  description: "",
  defaultMessage: "This is the total amount of money you expect to receive each month from your income sources.  It is the sum of your income sources."
};

export const MSG_monthlyIncomeExplanationWithExtra = {
  id: "8b7e0529-eeb6-40bd-b102-479074f0508f",
  description: "",
  defaultMessage: "Since you have income sources that have an extra paycheck, this is the average amount you expect to receive each month based on the income sources you have configured."
};

export const MSG_monthlyExpensesTitle = {
  id: "de7d8bd1-bb11-44d3-b27e-c3b2e364b9f2",
  description: "This is the title of a section where they can see their total monthly expenses.",
  defaultMessage: "Monthly Expenses"
};

export const MSG_monthlyExpensesExplanation = {
  id: "112ab46c-8eb8-4b3a-b041-8492b52c5cd7",
  description: "",
  defaultMessage: "This is the total amount of money you expect to spend each month.  It is the sum of your expenses for all of your envelopes."
};


export const MSG_monthlyFundingTitle = {
  id: "83b45c76-f54a-4898-8ce8-4c361e99a5f9",
  description: "This is the title of a section where they can see their total monthly funding.",
  defaultMessage: "Monthly Funding"
};

export const MSG_monthlyFundingExplanation = {
  id: "84d7f384-6d7c-4732-98a3-0a38f68f2e97",
  description: "",
  defaultMessage: "This is the total amount of money you expect to fund your budget with each month out of your income sources.  It is the sum of your funding amounts for all of your envelopes."
};

export const MSG_monthlyFundingExplanationWithExtra = {
  id: "997e4256-4900-40a6-9c2a-7dc692bbd31c",
  description: "",
  defaultMessage: "Since you have funding amounts that have an extra paycheck, this is the average amount you expect to fund your budget with each month based on the funding amounts you have configured."
};


export const MSG_incomeSourcesTitle = {
  id: "ace70252-bbc3-420c-96e3-e93f86740075",
  description: "The title of the section where they can add their income sources.",
  defaultMessage: "Income Sources"
};

export const MSG_incomeSourceTitle = {
  id: "3f865c9e-ec71-4a3a-8a06-8a92266bddf4",
  description: "",
  defaultMessage: "Income Source"
};

export const MSG_addIncomeSourceTitle = {
  id: "16889240-3b1b-4f4c-bfc3-5958bb893af5",
  description: "",
  defaultMessage: "Add Income Source"
};

export const MSG_addIncomeSourceToGetStarted = {
  id: "72542541-d7b2-4a41-98de-c380c1c542d6",
  description: "",
  defaultMessage: "Add an income source to get started."
};


export const MSG_annuallyFrequencyLabel = {
  id: "810c42dd-dc6c-4457-9f1b-1e2fe8a506e0",
  description: "The label for a frequency of 'annually' meaning something occurs once per year.",
  defaultMessage: "once per year"
};

export const MSG_semiannuallyFrequencyLabel = {
  id: "810c42dd-dc6c-4457-9f1b-1e2fe8a506e0",
  description: "The label for a frequency of 'semiannually' meaning something occurs twice per year.",
  defaultMessage: "twice per year"
};

export const MSG_quarterlyFrequencyLabel = {
  id: "810c42dd-dc6c-4457-9f1b-1e2fe8a506e0",
  description: "The label for a frequency of 'quarterly' meaning something occurs once per quarter.",
  defaultMessage: "once per quarter"
};

export const MSG_monthlyFrequencyLabel = {
  id: "810c42dd-dc6c-4457-9f1b-1e2fe8a506e0",
  description: "The label for a frequency of 'monthly' meaning something occurs once per month.",
  defaultMessage: "once per month"
};

export const MSG_semimonthlyFrequencyLabel = {
  id: "810c42dd-dc6c-4457-9f1b-1e2fe8a506e0",
  description: "The label for a frequency of 'semi-monthly' meaning something occurs twice per month.",
  defaultMessage: "twice per month"
};

export const MSG_biweeklyFrequencyLabel = {
  id: "810c42dd-dc6c-4457-9f1b-1e2fe8a506e0",
  description: "The label for a frequency of 'bi-weekly' meaning something occurs every other week.",
  defaultMessage: "every two weeks"
};

export const MSG_weeklyFrequencyLabel = {
  id: "810c42dd-dc6c-4457-9f1b-1e2fe8a506e0",
  description: "The label for a frequency of 'weekly' meaning something occurs once per week.",
  defaultMessage: "every week"
};

export const MSG_incomeSourcesExplanation = {
  id: "37023bba-f539-4aa8-b578-7b26cfb8a67e",
  description: "The explanation of what an income source is.",
  defaultMessage: "These are your sources of income that you expect to receive on a regular basis. They will usually be a paycheck from your employer."
};

export const MSG_irregularIncomeExplanation = {
  id: "bae9b31a-8689-4597-939d-4989d8510e3b",
  description: "",
  defaultMessage: "If your paycheck isn't the same every time, just enter the average amount you expect to receive.  You can adjust it when you record each actual paycheck."
};

export const MSG_incomeSourceNameLabel = {
  id: "6e901220-115b-42d9-9f82-2f48f98aaee2",
  description: "The label for the name of the income source.",
  defaultMessage: "Name"
};

export const MSG_incomeSourceAmountLabel = {
  id: "8cdd4e5c-1c4c-4806-846d-135b9f718ce8",
  description: "The label for the amount of the income source.",
  defaultMessage: "Amount"
};

export const MSG_incomeSourceFrequencyLabel = {
  id: "268d410f-c8d6-4e68-a0b7-99bc6fb26a3e",
  description: "The label for the frequency of the income source.",
  defaultMessage: "Frequency"
};

export const MSG_deleteIncomeSourceConfirmation = {
  id: "7840cd9b-612e-457b-a14a-ab6193aa8454",
  description: "",
  defaultMessage: "Are you sure you want to delete this income source?"
};

export const MSG_deleteIncomeSourceConfirmationExplanation = {
  id: "205c6aac-583c-4fdc-b59f-b59468a69e1e",
  description: "",
  defaultMessage: "All of the allocations you have configured for this income source will be permanently deleted as well."
};

export const MSG_addIncomeSource = {
  id: "3f07a6ac-f2ff-438a-b04f-ab7da8617048",
  description: "",
  defaultMessage: "Add an income source"
};

export const MSG_addIncomeSourceExplanation = {
  id: "4da57301-a365-4fd7-8c40-e06bb40001ec",
  description: "",
  defaultMessage: "A budget consists of two things: expenses and funding those expenses from your income sources.  To get started defining your budget, you'll need to add at least one income source."
};

export const MSG_missingIncomeSourcesExplanation = {
  id: "125226a9-95c8-4fc7-825e-6aec297c7718",
  description: "",
  defaultMessage: "Add an income source to start funding your expenses.  In NeoBudget, your expenses are funded by your income sources.  You can create income sources on the previous screen."
};

export const MSG_youNeedSomeEnvelopes = {
  id: "708e7861-e976-470c-8e3f-9d4438ce3b35",
  description: "",
  defaultMessage: "You need to create some envelopes"
};

export const MSG_emptyEnvelopeListOnBudgetScreen = {
  id: "854fa953-f88d-4149-a6e6-bef776f15898",
  description: "",
  defaultMessage: "You don't have any envelopes yet.  Go to the Envelopes tab to create some then come back here to define your budget."
};

export const MSG_clickEnvelopesForBudget = {
  id: "b83118ee-c8f1-4652-998d-685ce61209c8",
  description: "",
  defaultMessage: "Click an envelope to edit its budget"
};

export const MSG_clickEnvelopesForBudgetExplanation = {
  id: "30204bd1-8724-453c-b03d-24ba9d12e1a9",
  description: "",
  defaultMessage: "You can click on each individual envelope to edit its expenses and funding from your income sources."
};



export const MSG_budgetedExpensesAndFundingTitle = {
  id: "68ada3f4-5063-46f0-9079-7d0038fa4a73",
  description: "The title of the section where they can add their budgeted expenses and funding sources.",
  defaultMessage: "Budgeted Expenses and Funding"
};

export const MSG_totalMonthlyExpensesLabel = {
  id: "71967c30-d061-4a3e-9313-6b87e84806ee",
  description: "The label for the total monthly expenses section of the budgeted expenses and funding section.  In english, there is a colon after the word 'Expenses' because it is followed an amount.  In other languages, this may not be the case.",
  defaultMessage: "Total monthly expenses:"
};

export const MSG_totalMonthlyFunding = {
  id: "225c03c4-f8f7-43e3-b989-5f79194cd89d",
  description: "This is the total amount of money they are funding their budget with each month.  In english, there is a colon after the word 'Funding' because it is followed an amount.  In other languages, this may not be the case.",
  defaultMessage: "Total monthly funding:"
};

export const MSG_newExpenseTitle = {
  id: "dce47e9c-483e-4f27-85a4-512e7c835966",
  description: "The label for a button that lets them create a new expense.",
  defaultMessage: "New Expense"
};

export const MSG_noExpensesForEnvelope = {
  id: "eec50e38-85f4-4f1a-a7e0-6e47ba282dfa",
  description: "",
  defaultMessage: "No expenses have been added to this envelope yet."
};


export const MSG_addExpenseToGetStarted = {
  id: "88e7ba37-052f-4167-bb40-c90ea2ea2a34",
  description: "",
  defaultMessage: "Add an expense to get started."
};

export const MSG_expenseAmountExplanation = {
  id: "190d346e-6390-4461-857a-05fdc9cfa835",
  description: "",
  defaultMessage: "This is the amount you need to fund each month to pay for these expenses."
};

export const MSG_fundedAmountWarning = {
  id: "fc63cac2-275e-4aaa-8b5a-fee130c5000b",
  description: "",
  defaultMessage: "There is not enough money funded to cover the budgeted expenses\n"
};

export const MSG_splitAmountEvenly = {
  id: "f545b375-d981-4270-afc7-3a7943a932fb",
  description: "The label for a button that lets them split an amount of money evenly across all paychecks.",
  defaultMessage: "Split {amount} evenly"
};

export const MSG_splitAmountEvenlyAcrossAllChecks = {
  id: "65448dcd-8e31-4df5-80d5-7916073fb07b",
  description: "",
  defaultMessage: "Split {amount} evenly across all checks"
};


export const MSG_splitAcrossAllPaychecksIncludingExtras = {
  id: "38b7fd1a-78ea-4215-af52-6199a08de344",
  description: "When paid every two weeks, sometimes there are 2 paychecks per month and sometimes there are 3.  This label is for a button that lets them split an amount of money across all paychecks including the third (extra) paycheck.",
  defaultMessage: "Split evenly across all paychecks (including extras)"
};

export const MSG_splitAcrossNormalPaychecks = {
  id: "5bb2d632-a573-4fb5-bdd6-be932c0fb6a5",
  description: "This will split an amount of money across all paychecks except the third (extra) paycheck.  This is for when they are paid every two weeks and sometimes there are 2 paychecks per month and sometimes there are 3.",
  defaultMessage: "Split evenly across normal paychecks"
};


export const MSG_ordinalCheckTitle = {
  id: "e0f214e0-d4fa-44da-98c4-c40770d33cf7",
  description: "This is a label for the sequence of paychecks.  It will read something like '1st check', '2nd check', etc.",
  defaultMessage: "{ordinal} check"
};

export const MSG_extraColon = {
  id: "245ecb48-3549-43b2-bc4c-986593ce234c",
  description: "This is the label for an 'extra' paycheck (e.g. if you get a 3rd check in a month when paid every two weeks).  This should be a very short word or abbreviation.",
  defaultMessage: "Extra:"
};

export const MSG_avgColon = {
  id: "84a54d5d-e8c7-44b7-8086-0d8583c5c724",
  description: "This is the label for an 'average' amount funded per month.  This should be a very short word or abbreviation.",
  defaultMessage: "Avg:"
};

export const MSG_avgWithExtraColon = {
  id: "10eea81a-cfe0-447b-8fba-96823a77b5bb",
  description: "This is the label for an 'average' amount funded per month including the extra paycheck (e.g. if you get a 3rd check in a month when paid every two weeks).  This should be a very short word or abbreviation.",
  defaultMessage: "Avg. with extra:"
};


export const MSG_extraIncome = {
  id: "c5cbc0fa-4fca-4ba8-91ce-053524f0ec27",
  description: "This is a label for a section of the budget that shows how much money they have left over after funding their budget.",
  defaultMessage: "Extra Income"
};

export const MSG_extraIncomeExplanation = {
  id: "fd5241c0-9411-4309-b366-22fb84d56a4b",
  description: "",
  defaultMessage: "This is the amount of money you have left over after funding your budget.  This is your income minus your funding amounts."
};


export const MSG_expensesLabel = {
  id: "671c61fd-c6b9-47e5-8fbb-beb631debf12",
  description: "A label for the section of the budget where we show their expenses each month for a specific envelope.",
  defaultMessage: "Expenses"
};

export const MSG_fundingLabel = {
  id: "640d0176-9e01-4441-b720-58c721bf3ddf",
  description: "A label for the section of the budget where we show their funding each month for a specific envelope.",
  defaultMessage: "Funding"
};

export const MSG_notEnoughFundingExplanation = {
  id: "ebe8e30a-ad93-4360-a224-8769f5830cce",
  description: "",
  defaultMessage: "This envelope does not have enough funding to cover its expenses."
};

export const MSG_fundedWithExtra1 = {
  id: "c3f8574d-4f48-4aa7-8997-54b142f4eacc",
  description: "",
  defaultMessage: "This funding is an average that includes an occasional extra paycheck.  It may not be funded this amount every month, but will average to this amount in a year."
};

export const MSG_fundedWithExtra2a = {
  id: "d697b348-c065-44a8-bbff-16dff8361312",
  description: "",
  defaultMessage: "If you want this to always be funded the full amount, edit your funding for this envelope to remove the extra paycheck."
};

export const MSG_fundedWithExtra2b = {
  id: "126406eb-84aa-4f42-bd4b-d1b19e946af2",
  description: "",
  defaultMessage: "If you want this to always be funded the full amount, edit the funding of your envelopes to remove the extra paychecks."
};

export const MSG_incomeWithExtra = {
  id: "e4c431f4-b4e2-4f70-89de-82096aad7b88",
  description: "",
  defaultMessage: "This income is an average that includes an occasional extra paycheck.  It may not be this amount every month, but will average to this amount in a year."
};

export const MSG_amountRemaining = {
  id: "443d6a16-9089-41c0-8019-14d5bca990b5",
  description: "This is displayed when they are allocating money to an envelope and it shows how much money is left to allocate.",
  defaultMessage: "{amount} remaining"
};


export const MSG_minimumTitle = {
  id: "eefaf8e7-b603-4a69-af54-495ff9f51ffa",
  description: "This is the title of a section where they can see the minimum amount of money they expect to receive each month from their income sources.",
  defaultMessage: "Minimum"
};

export const MSG_maximumTitle = {
  id: "ba60a408-98ab-4adb-9de6-459bfb9bdd9f",
  description: "This is the title of a section where they can see the maximum amount of money they expect to receive each month from their income sources.",
  defaultMessage: "Maximum"
};

export const MSG_averageTitle = {
  id: "e9329060-e49e-4238-8f99-52d1313c80cc",
  description: "This is the title of a section where they can see the average amount of money they expect to receive each month from their income sources over the course of a year.",
  defaultMessage: "Average"
};

export const MSG_monthsWithNChecks = {
  id: "0d96b950-ffcc-4448-acfd-c4da5c866192",
  description: "This is a label for an amount of expected income based on the number of paychecks they receive each month.",
  defaultMessage: "{count, plural, one {months with 1 check} other {months with {count} checks}}"
};

