import * as React from "react";
import { PropsWithChildren } from "react";
import {
  StyledCardSettingsActions,
  StyledCardSettingsBody,
  StyledCardSettingsIcon,
  StyledCardSettingsLabel,
  StyledCardSettingsValue
} from "@web/components/styled/StyledCard";
import { MSG_dateFormatLabel } from "shared/strings/settings";
import { useIntlFormatters } from "shared/utils/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons/faEdit";
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar";
import dayjs from "dayjs";
import { ISettingsEditorModal, SettingsEditorModal } from "@web/modals/settings_editor_modal/SettingsEditorModal";
import { useUpdateSubscriptionMutation } from "shared/state/endpoints/app/subscriptions_api";
import { selectCurrentSubscription, selectCurrentUser, setCurrentSubscription } from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { DATE_FORMATS } from "shared/utils/helpers";
import { OptionSelectorProvider } from "@web/components/shared/OptionSelector";
import { ROLE } from "shared/utils/api_types";

interface IProps {}

const DateFormatSettings: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage, formatDate} = useIntlFormatters();
  const modalRef = React.useRef<ISettingsEditorModal>(null);
  const subscription = useSelector(selectCurrentSubscription)!;
  const [value, setValue] = React.useState<string>(subscription.dateFormat);
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const editable = currentUser?.role === ROLE.OWNER;

  React.useEffect(() => setValue(subscription.dateFormat), [subscription.dateFormat]);

  async function save() {
    const subscription = await updateSubscription({dateFormat: value}).unwrap();
    dispatch(setCurrentSubscription(subscription));
  }

  function cancel() {
    setValue(subscription.dateFormat);
  }

  const date = dayjs().endOf('month');
  const OptionsSelector = OptionSelectorProvider<string>();
  return (
    <React.Fragment>
      <StyledCardSettingsBody className={editable ? "clickable" : ''}
                              onClick={editable ? () => modalRef.current?.show() : undefined}>
        <StyledCardSettingsIcon>
          <FontAwesomeIcon icon={faCalendar}/>
        </StyledCardSettingsIcon>
        <StyledCardSettingsLabel className="clickable-title">
          {formatMessage(MSG_dateFormatLabel)}
        </StyledCardSettingsLabel>
        <StyledCardSettingsValue>
          {formatDate(date, subscription)}
        </StyledCardSettingsValue>
        {editable && (
          <StyledCardSettingsActions>
            <FontAwesomeIcon icon={faEdit}/>
          </StyledCardSettingsActions>)}
      </StyledCardSettingsBody>

      <SettingsEditorModal save={save} onCancel={cancel} ref={modalRef} width="20rem">
        <OptionsSelector
          value={value}
          onChange={setValue}
          options={DATE_FORMATS.map(format => ({
            value: format,
            label: formatDate(date, subscription, {format})
          }))}
        />
      </SettingsEditorModal>
    </React.Fragment>
  );
};

export {DateFormatSettings};
