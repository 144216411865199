import * as React from "react";
import { PropsWithChildren } from "react";
import styled, { useTheme } from "styled-components";
import { AppFooter } from "@web/components/shared/AppFooter";
import { StyledWindowContainer } from "@web/components/styled/StyledWindowContainer";

// Since our unauthenticated routes are not all nested under a single route, we can't use
// react-router for nesting.  Yet, we still want to have a single component that wraps
// all of our unauthenticated routes.  To simulate how the react-router nesting works, we
// can use the `element` prop to pass in the component that should be rendered at the
// current level of nesting.
interface IProps {
  element: React.ReactNode;
}

const UnauthenticatedRoot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const theme = useTheme();

  return (
    <Layout>
      <LogoImage src={theme.brand.logoImg}/>
      <Content>
        {props.element}
      </Content>
      <AppFooter stacked/>
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: 4rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const LogoImage = styled.img`
  margin: 0 auto;
  margin-bottom: 0.5rem;
  display: block;
  height: 3.75rem;
  max-width: 90%;
`;

const Content = styled(StyledWindowContainer)`
  display: inline-block;
  min-width: 26.25rem;
  max-width: 95%;
  text-align: left;
`;

export {UnauthenticatedRoot};
