import * as React from "react";
import { PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentSubscription } from "shared/state/slices/data_selectors/core_data_selectors";
import { Product } from "shared/utils/api_types";
import { selectProducts } from "shared/state/slices/data_selectors/product_data_selectors";
import { BillingTier } from "../BillingTier";
import { StyledTextHeader } from "@web/components/styled/StyledText";
import {
  MSG_billingAccountCreditExplanation,
  MSG_billingAccountCreditGroupExplanation,
  MSG_billingAccountCreditLabel,
  MSG_billingDetailsTitle,
  MSG_confirmDetachExplanation,
  MSG_detachExplanation,
  MSG_detachTitle
} from "shared/strings/billing";
import { useIntlFormatters } from "shared/utils/formatters";
import { StyledCard, StyledCardBody, StyledCardIcon } from "@web/components/styled/StyledCard";
import { Button } from "@web/components/Button";
import { ForgetMeBox } from "@web/pages/app/authenticated/settings/billing/components/ForgetMeBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar, faUsers } from "@fortawesome/pro-light-svg-icons";
import { ContextHelp } from "@web/components/shared/ContextHelp";
import { MSG_howDoesThisWork, MSG_unexpectedError, MSG_whatIsThis } from "shared/strings/generic";
import { useDetachGroupMutation } from "shared/state/endpoints/app/subscriptions_api";
import { setCurrentSubscription } from "shared/state/slices/data_slice";
import { parseApiError } from "shared/utils/api_errors";
import { useAlert } from "@web/utils/hooks";

interface IProps {}

const GroupBillingDetails: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const subscription = useSelector(selectCurrentSubscription)!;
  const products = useSelector(selectProducts);
  const {formatMessage, formatCurrency} = useIntlFormatters();
  const [detaching, setDetaching] = React.useState<boolean>(false);
  const [detachMutation] = useDetachGroupMutation();
  const dispatch = useDispatch();
  const {showErrorAlert} = useAlert();

  const group = subscription?.group!;

  const detach = async () => {
    if (confirm(formatMessage(MSG_confirmDetachExplanation))) {
      setDetaching(true);
      try {
        const subscription = await detachMutation().unwrap();
        dispatch(setCurrentSubscription(subscription));
      } catch (e) {
        let error = parseApiError(e);
        showErrorAlert(error.errorType === 'message' ? error.message : formatMessage(MSG_unexpectedError));
      }
      setDetaching(false);
    }
  }

  let groupProduct: Product | null = null;
  if (group.productKey.match(/essential/)) {
    groupProduct = products.find(product => product.productKey === 'essential-monthly-v4') || null;
  }

  return (
    <div className="flex-row align-items-start justify-content-start">
      <BillingTier
        product={groupProduct}
        group={group}
        currentPlanLocation="top"
        currentProduct={groupProduct}
      />

      <div className="flex-grow-1" style={{marginLeft: '2rem'}}>

        <StyledTextHeader className="text-center">{formatMessage(MSG_billingDetailsTitle)}</StyledTextHeader>

        <StyledCard>
          <StyledCardBody>
            <div className="flex-row align-items-center justify-content-stretch">
              <StyledCardIcon>
                <FontAwesomeIcon icon={faUsers}/>
              </StyledCardIcon>

              <div className="flex-grow-1 me-4">
                <div>
                  You are part of a group plan provided by <b>{group.name}</b>.
                </div>
              </div>

              <Button color="secondary" onClick={() => detach()} disabled={detaching} spinner={detaching}>
                {formatMessage(MSG_detachTitle)}
              </Button>
            </div>
            <div className="text-muted mt-4">
              <b>{formatMessage(MSG_howDoesThisWork)}</b>{' '}
              {formatMessage(MSG_detachExplanation)}
            </div>
          </StyledCardBody>
          {!!subscription.stripeAccountBalance && (
            <StyledCardBody>
              <div className="flex-row align-items-center justify-content-stretch">
                <StyledCardIcon>
                  <FontAwesomeIcon icon={faSackDollar}/>
                </StyledCardIcon>

                <div className="flex-grow-1">
                  {formatMessage(MSG_billingAccountCreditLabel)}{' '}
                  <strong>{formatCurrency(subscription.stripeAccountBalance, subscription, {showSymbol: true})}</strong>{' '}
                  <ContextHelp text={formatMessage(MSG_billingAccountCreditExplanation)}/>
                </div>
              </div>
              <div className="text-muted mt-4">
                <b>{formatMessage(MSG_whatIsThis)}</b>{' '}
                {formatMessage(MSG_billingAccountCreditGroupExplanation)}
              </div>
            </StyledCardBody>)}
        </StyledCard>

        <ForgetMeBox/>

      </div>
    </div>
  );
};

export { GroupBillingDetails };
