import * as React from "react";
import { PropsWithChildren } from "react";
import {
  StyledCardSettingsBody,
  StyledCardSettingsIcon,
  StyledCardSettingsLabel,
  StyledCardSettingsValue
} from "@web/components/styled/StyledCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faBank, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { Account, ApiError, ROLE } from "shared/utils/api_types";
import { selectCurrentUser } from "shared/state/slices/data_selectors/core_data_selectors";
import { useDispatch, useSelector } from "react-redux";
import { ISettingsEditorModal, SettingsEditorModal } from "@web/modals/settings_editor_modal/SettingsEditorModal";
import { useIntlFormatters } from "shared/utils/formatters";
import { useParams } from "react-router";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import { parseApiError } from "shared/utils/api_errors";
import { selectAccountsForLedger, setAccount, setAccountsForLedger } from "shared/state/slices/data_slice";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { MSG_accountNameLabel } from "shared/strings/accounts";
import { Button } from "@web/components/Button";
import { MSG_editButton } from "shared/strings/generic";
import { useUpdateAccountMutation } from "shared/state/endpoints/app/accounts_api";
import {
  DeleteAccountConfirmationPopover
} from "@web/popovers/delete_account_popover/DeleteAccountConfirmationPopover";
import { IPopover } from "@web/components/Popover";

interface IProps {
  account: Account;
  currentIndex: number;
}

const AccountSettingsItem: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const ledgerId = useParams<{ledgerId: string}>().ledgerId!;
  const modalRef = React.useRef<ISettingsEditorModal>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const currentUser = useSelector(selectCurrentUser);
  const editable = currentUser?.role === ROLE.OWNER;
  const [newName, setNewName] = React.useState<string>(props.account.name);
  const [saving, setSaving] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [updateAccount] = useUpdateAccountMutation();
  const accounts = useSelector(selectAccountsForLedger(ledgerId));
  const deleteButtonPopoverRef = React.useRef<IPopover>(null);
  const deleteButtonRef = React.useRef<HTMLButtonElement>(null);

  async function save() {
    try {
      setSaving(true);
      const a = await updateAccount({account: {id: props.account.id, ledgerId, name: newName}}).unwrap();
      dispatch(setAccount(a));
      modalRef.current?.hide();
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  function cancel() {
    modalRef.current?.hide();
  }

  async function savePositions(newAccounts: Account[], e: React.MouseEvent | undefined) {
    if (currentUser?.role === ROLE.OBSERVER) return;

    let _accounts = [...newAccounts];
    _accounts.sort((a, b) => {
      if (a === null || b === null) return 0;
      if ((a.sequenceNumber || 0) < (b.sequenceNumber || 0)) return -1;
      if ((a.sequenceNumber || 0) > (b.sequenceNumber || 0)) return 1;
      return 0;
    });
    _accounts = _accounts.map((a, i) => {
      a.sequenceNumber = i;
      return a;
    });
    dispatch(setAccountsForLedger({ledgerId, accounts: _accounts}));
    e?.stopPropagation();
    e?.preventDefault();

    _accounts.forEach((a, i) => {
      if (accounts[i].id !== a.id) {
        void updateAccount({account: {id: a.id, ledgerId, sequenceNumber: i}});
      }
    });
  }

  function moveUp(e: React.MouseEvent | undefined, pos: number) {
    void savePositions(accounts.map((a, i) => {
      let newPos = i;
      if (i === pos) newPos = i - 1;
      else if (i === pos - 1) newPos = i + 1;
      return {...a, sequenceNumber: newPos};
    }), e);
  }

  function moveDown(e: React.MouseEvent | undefined, pos: number) {
    void savePositions(accounts.map((a, i) => {
      let newPos = i;
      if (i === pos) newPos = i + 1;
      else if (i === pos + 1) newPos = i - 1;
      return {...a, sequenceNumber: newPos};
    }), e);
  }

  return (
    <React.Fragment>
      <StyledCardSettingsBody>
        <StyledCardSettingsIcon>
          <FontAwesomeIcon icon={faBank}/>
        </StyledCardSettingsIcon>
        <StyledCardSettingsLabel className="clickable-title">
          {props.account.name}
        </StyledCardSettingsLabel>
        {editable && (
          <StyledCardSettingsValue className="flex-row align-items-center">
            <Button
              link
              ref={deleteButtonRef}
              className="me-4"
              onClick={() => {
                deleteButtonPopoverRef.current?.show();
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt}/>
            </Button>

            <Button
              onClick={editable ? () => {
                setNewName(props.account.name);
                modalRef.current?.show();
                setTimeout(() => inputRef.current?.focus(), 50);
              }: undefined}
            >
              {formatMessage(MSG_editButton)}
            </Button>

            <div className="flex-column align-items-center justify-content-center ms-4">
              <Button link onClick={(e) => moveUp(e, props.currentIndex)}>
                <FontAwesomeIcon icon={faArrowUp}/>
              </Button>
              <Button link onClick={(e) => moveDown(e, props.currentIndex)}>
                <FontAwesomeIcon icon={faArrowDown}/>
              </Button>
            </div>
          </StyledCardSettingsValue>
        )}
      </StyledCardSettingsBody>

      <DeleteAccountConfirmationPopover
        ref={deleteButtonPopoverRef}
        targetRef={deleteButtonRef}
        ledgerId={ledgerId!}
        accountId={props.account.id!}
      />

      <SettingsEditorModal save={save} onCancel={cancel} ref={modalRef} width="25rem">
        <div style={{padding: '1rem', paddingBottom: 0}}>
          {errors?.errorType === 'message' && (
            <div className="d-flex flex-row">
              <StyledErrorMessage>
                <FontAwesomeIcon icon={faExclamationTriangle}/>
                <div>{errors.message}</div>
              </StyledErrorMessage>
            </div>
          )}

          <FormElement errors={errors} label={formatMessage(MSG_accountNameLabel)}>
            <TextInput ref={inputRef} value={newName} onChange={e => setNewName(e.currentTarget.value)}/>
          </FormElement>
        </div>
      </SettingsEditorModal>
    </React.Fragment>
  );
};

export {AccountSettingsItem};
