import * as React from "react";
import { PropsWithChildren } from "react";
import { StyledEnvelopeContainer } from "@web/components/styled/StyledEnvelopeContainer";
import { Envelope } from "shared/utils/api_types";
import styled from "styled-components";
import {
  MSG_averageTitle,
  MSG_expensesLabel,
  MSG_fundedWithExtra1,
  MSG_fundedWithExtra2a,
  MSG_fundingLabel,
  MSG_maximumTitle,
  MSG_minimumTitle,
  MSG_monthsWithNChecks,
  MSG_notEnoughFundingExplanation
} from "shared/strings/budget";
import { useIntlFormatters } from "shared/utils/formatters";
import { ICalculatedExpensesSummary, ICalculatedFundingSummary } from "shared/utils/interfaces";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faAsterisk } from "@fortawesome/pro-solid-svg-icons/faAsterisk";
import { FreePlanNoticePopover } from "@web/popovers/locked_envelope_popover/FreePlanNoticePopover";
import { IPopover } from "@web/components/Popover";
import { Tooltip } from "react-tooltip";
import { selectCurrentSubscription } from "shared/state/store";
import { useSelector } from "react-redux";


interface IProps {
  fundingValues: ICalculatedFundingSummary;
  expenseValues: ICalculatedExpensesSummary
  envelope: Partial<Envelope>;
  onClick?: () => any;
}

const EnvelopeBudgetSummary: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const lockedOverlayRef = React.useRef<any>(null);
  const lockedPopoverRef = React.useRef<IPopover>(null);
  const {formatMessage, formatCurrency} = useIntlFormatters();
  const subscription = useSelector(selectCurrentSubscription)!;
  const {envelope} = props;

  const fundingValue = props.fundingValues.average;
  const expensesValue = props.expenseValues.average;

  return (
    <React.Fragment>
      <EnvelopeContainer
        data-tooltip-id={`envelope-${envelope.id}`}
        style={{backgroundColor: props.envelope.color}}
        className={classnames({
          'has-focus-border': expensesValue || fundingValue,
          'focus-border-danger': expensesValue + fundingValue < 0,
        })}
        onClick={() => {
          if (envelope.locked) {
            lockedPopoverRef.current?.show();
          } else {
            props.onClick?.();
          }
        }}
      >
        <Name className="envelope-summary-name">{envelope.name}</Name>
        <div className="flex-row align-items-start mt-2" style={{width: '100%'}}>
          <div style={{width: '50%'}}>
            <Label>{formatMessage(MSG_expensesLabel)}</Label>
            <Amount className={classnames({negative: expensesValue < 0})}>{
              expensesValue
                ? formatCurrency(expensesValue, subscription)
                : <Dash>&mdash;</Dash>
            }</Amount>
          </div>
          <div style={{width: '50%'}}>
            <Label>{formatMessage(MSG_fundingLabel)}</Label>
            {fundingValue ? (
              props.fundingValues.extra ? (
                <React.Fragment>
                  <Amount className={classnames({negative: fundingValue < 0})}>
                    {formatCurrency(fundingValue, subscription)}
                    <FontAwesomeIcon icon={faAsterisk}/>
                  </Amount>
                </React.Fragment>
              ) : (
                <Amount className={classnames({negative: fundingValue < 0})}>
                  {formatCurrency(fundingValue, subscription)}
                </Amount>
              )
            ) : (
              <Amount><Dash>&mdash;</Dash></Amount>
            )}
          </div>
        </div>

        {expensesValue + fundingValue < 0 && (
          <React.Fragment>
            <Icon style={{color: '#fff'}}><FontAwesomeIcon icon={faCircle}/></Icon>
            <Icon className="text-danger">
              <FontAwesomeIcon icon={faExclamationCircle}
                               data-tooltip-content={formatMessage(MSG_notEnoughFundingExplanation)}
                               data-tooltip-id={`under-funded-${envelope.id}`}/>
            </Icon>
            <Tooltip delayShow={100}
                     id={`under-funded-${envelope.id}`}
                     className="tooltip-wrapper"/>
          </React.Fragment>
        )}

        {envelope.locked && (
          <React.Fragment>
            <LockedOverlay ref={lockedOverlayRef}>
              <FontAwesomeIcon icon={faLock}/>
            </LockedOverlay>
            <FreePlanNoticePopover
              ref={lockedPopoverRef}
              targetRef={lockedOverlayRef}
            />
          </React.Fragment>)}
      </EnvelopeContainer>

      {!!props.fundingValues.extra && (
        <Tooltip delayShow={100} id={`envelope-${envelope.id}`} className="tooltip-wrapper">
          <div>
            {formatMessage(MSG_fundedWithExtra1)}<br/><br/>
            {formatMessage(MSG_fundedWithExtra2a)}
          </div>
          <br/>
          <div>
            {formatMessage(MSG_averageTitle)}: <b>{formatCurrency(props.fundingValues.average, subscription)}</b>
          </div>
          <div>
            {formatMessage(MSG_minimumTitle)}: <b>{formatCurrency(props.fundingValues.amount, subscription)}</b>
            <span className="ms-2"
                  style={{opacity: 0.75}}>({formatMessage(MSG_monthsWithNChecks, {count: props.fundingValues.minChecks})})</span>
          </div>
          <div>
            {formatMessage(MSG_maximumTitle)}: <b>{formatCurrency(props.fundingValues.amount + props.fundingValues.extra, subscription)}</b>
            <span className="ms-2"
                  style={{opacity: 0.75}}>({formatMessage(MSG_monthsWithNChecks, {count: props.fundingValues.maxChecks})})</span>
          </div>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

const EnvelopeContainer = styled(StyledEnvelopeContainer)`
  transition: opacity 0.25s ease-in-out;

  &.dimmed {
    opacity: 0.25;
  }

  &:hover {
    opacity: 1;
  }
`;

const Name = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  font-size: 0.85rem;
  text-align: center;
  opacity: 0.5;
`;

const Icon = styled.div`
  position: absolute;
  line-height: 1;
  right: -0.5rem;
  top: -0.5rem;
  font-size: 1.5rem;
`;

const Amount = styled.div`
  text-align: center;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.negative {
    color: ${({theme}) => theme.colors.negativeEnvelopeTextColor};
    //text-shadow:
    //  -1px 1px 0 rgba(255, 255, 255, 0.25),
    //  1px 1px 0 rgba(255, 255, 255, 0.25);
  }

  svg {
    margin-left: 0.15rem;
    font-size: 0.75rem;
  }
`;

const Dash = styled.div`
  opacity: 0.25;
`;

const LockedOverlay = styled.div`
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  bottom: -0.25rem;
  right: -0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.65);
  color: ${({theme}) => theme.colors.warning};
  cursor: pointer;

  svg {
    font-size: 200%;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
  }
`;

export {EnvelopeBudgetSummary};
