import styled from "styled-components";
import { darken } from "color2k";

export const LinkButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  display: inline;
  color: ${({theme}) => theme.colors.linkColor };
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({theme}) => darken(theme.colors.linkColor, .15) };
  }
`;
