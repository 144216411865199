export const MSG_envelopesTitle = {
  id: "42d84177-7b6e-40fb-a3bd-fe2168371b8e",
  description: "The plural title for the section that lists all their envelopes.",
  defaultMessage: "Envelopes"
};

export const MSG_envelopeTitle = {
  id: "c9cdd806-2e2d-4631-83d5-193f422094af",
  description: "The singular title for the section that lists all their envelopes.",
  defaultMessage: "Envelope"
};


export const MSG_manageEnvelopesButton = {
  id: "e0e32048-81d3-4c10-a9dc-4f59bcc58796",
  description: "",
  defaultMessage: "Manage Envelopes"
};

export const MSG_jumpToEnvelopesButton = {
  id: "78b29bc8-3c5b-4d75-9865-7bf83b57f0e4",
  description: "This is a button that navigates them to the Envelopes screen",
  defaultMessage: "Jump To Envelopes"
};


export const MSG_newEnvelopeButton = {
  id: "c297489a-8156-4a83-85a9-a4716d204049",
  description: "A button that lets them create a new envelope",
  defaultMessage: "New Envelope"
};

export const MSG_archivedLabel = {
  id: "ea52aace-5e89-4376-b5e7-4e33cb7197c2",
  description: "A label for an archived envelope.",
  defaultMessage: "Archived"
};

export const MSG_archiveButton = {
  id: "755b17a1-b5b6-4020-987a-3431c7b98936",
  description: "The label for a button that will archive an envelope",
  defaultMessage: "Archive"
};

export const MSG_envelopeNameLabel = {
  id: "435a10a2-4386-4e2e-ac2b-fda00f7ba777",
  description: "The label for the form field for the name of an envelope.",
  defaultMessage: "Name"
};

export const MSG_envelopeColorLabel = {
  id: "974bfb38-c632-465a-8901-2b60e9443b9e",
  description: "The label for the form field where they can select the color for an envelope.",
  defaultMessage: "Color"
};

export const MSG_commonEnvelopesLabel = {
  id: "68ffcef1-0d51-483f-9321-d6b3def3747b",
  description: "This is a label for a list of common envelope names",
  defaultMessage: "Common Envelopes"
};


export const MSG_homeExpensesCategory = {
  id: "a9fc1f89-3a57-4469-8949-081010867ebb",
  description: "A category of common envelope names.",
  defaultMessage: "Home Expenses"
};

export const MSG_livingExpensesCategory = {
  id: "ac79aac3-fc2a-4708-9b0e-d61d542975b8",
  description: "A category of common envelope names.",
  defaultMessage: "Living Expenses"
};

export const MSG_SavingsCategory = {
  id: "4e1aff4e-c794-4cdb-944e-04ae58dfddc9",
  description: "A category of common envelope names.",
  defaultMessage: "Savings"
};

export const MSG_regularBillsCategory = {
  id: "a4f1a701-4eee-4d74-9757-c1a76b14055d",
  description: "A category of common envelope names.",
  defaultMessage: "Regular Bills"
};

export const MSG_transportationCategory = {
  id: "2f22cc36-7e5e-4a17-9085-8ded9a8fa323",
  description: "A category of common envelope names.",
  defaultMessage: "Transportation"
};


export const MSG_mortgageEnvelopeName = {
  id: "f4fb382f-8210-4eae-bd71-7e6533ca8417",
  description: "An example of a common envelope name.",
  defaultMessage: "Mortgage"
};
export const MSG_rentEnvelopeName = {
  id: "49c1f28b-456c-450a-8354-d062e7514a85",
  description: "An example of a common envelope name.",
  defaultMessage: "Rent"
};
export const MSG_homeInsuranceEnvelopeName = {
  id: "27a791cb-80de-4e91-99a9-da194703dcdf",
  description: "An example of a common envelope name.",
  defaultMessage: "Home Insurance"
};
export const MSG_propertyTaxEnvelopeName = {
  id: "be6c0b46-f5ef-4e15-ba39-290077dd2c48",
  description: "An example of a common envelope name.",
  defaultMessage: "Property Tax"
};
export const MSG_homeImprovementEnvelopeName = {
  id: "91c34772-8ec3-40d2-b4f0-957c5e0645d0",
  description: "An example of a common envelope name.",
  defaultMessage: "Home Improvement"
};
export const MSG_maintenanceEnvelopeName = {
  id: "761a2a2c-0efb-427b-a49b-5306d56b2df5",
  description: "An example of a common envelope name.",
  defaultMessage: "Maintenance"
};
export const MSG_groceriesEnvelopeName = {
  id: "d6f9c366-b32b-4ca7-8000-506aaf2fd39a",
  description: "An example of a common envelope name.",
  defaultMessage: "Groceries"
};
export const MSG_personalCareEnvelopeName = {
  id: "255d1e85-5d93-4046-b252-718dc683be8e",
  description: "An example of a common envelope name.",
  defaultMessage: "Personal Care"
};
export const MSG_clothingEnvelopeName = {
  id: "77818c59-7291-48be-9dd7-e7567e79a068",
  description: "An example of a common envelope name.",
  defaultMessage: "Clothing"
};
export const MSG_entertainmentEnvelopeName = {
  id: "6df88fe9-4826-43c8-96b6-16f77db63b0b",
  description: "An example of a common envelope name.",
  defaultMessage: "Entertainment"
};
export const MSG_diningOutEnvelopeName = {
  id: "a044cedc-40b6-49e3-ba6c-528f59b386c1",
  description: "An example of a common envelope name.",
  defaultMessage: "Dining Out"
};
export const MSG_spendingMoneyEnvelopeName = {
  id: "0ab225a8-d6a6-40bb-bf6c-ac2b27fc39b5",
  description: "An example of a common envelope name.",
  defaultMessage: "Spending Money"
};
export const MSG_travelEnvelopeName = {
  id: "b3126ab6-3c69-47c6-8971-016ae96943d7",
  description: "An example of a common envelope name.",
  defaultMessage: "Travel"
};
export const MSG_retirementEnvelopeName = {
  id: "733c47ac-ca20-4df8-b19b-b0032c7ac87d",
  description: "An example of a common envelope name.",
  defaultMessage: "Retirement"
};
export const MSG_bigProjectsEnvelopeName = {
  id: "3edf4795-991d-421a-a346-f1701a7b73fd",
  description: "An example of a common envelope name.",
  defaultMessage: "Big Projects"
};
export const MSG_emergencyEnvelopeName = {
  id: "872eace0-0962-465c-9207-4f1ead252b87",
  description: "An example of a common envelope name.",
  defaultMessage: "Emergency"
};
export const MSG_electricEnvelopeName = {
  id: "39d12812-4575-4f3d-94a6-fcd2daf4beda",
  description: "An example of a common envelope name.",
  defaultMessage: "Electric"
};
export const MSG_waterEnvelopeName = {
  id: "192f795a-7207-499f-8883-97acd1974917",
  description: "An example of a common envelope name.",
  defaultMessage: "Water"
};
export const MSG_propaneEnvelopeName = {
  id: "5d4cd6ad-d46f-44ad-b530-7fc6f3dcccac",
  description: "An example of a common envelope name.",
  defaultMessage: "Propane"
};
export const MSG_phoneEnvelopeName = {
  id: "e8c5d5ea-052f-4303-b7a8-ec83c879af9e",
  description: "An example of a common envelope name.",
  defaultMessage: "Phone"
};
export const MSG_internetEnvelopeName = {
  id: "234eb9fc-53c2-4052-bfcb-b1ba3b11ee37",
  description: "An example of a common envelope name.",
  defaultMessage: "Internet"
};
export const MSG_carGasEnvelopeName = {
  id: "dc53da7f-e7da-4114-9334-4b98cd23af3c",
  description: "An example of a common envelope name.",
  defaultMessage: "Car Gas"
};
export const MSG_carInsuranceEnvelopeName = {
  id: "2bbd3148-8f16-41cc-b5f2-ceebf1007eba",
  description: "An example of a common envelope name.",
  defaultMessage: "Car Insurance"
};
export const MSG_carPaymentEnvelopeName = {
  id: "b003be2d-6eb3-434e-9fd2-0b570473f7ab",
  description: "An example of a common envelope name.",
  defaultMessage: "Car Payment"
};
export const MSG_carMaintenanceEnvelopeName = {
  id: "e4d75c0c-709b-42d4-8a3d-cdca8079d4f6",
  description: "An example of a common envelope name.",
  defaultMessage: "Car Maintenance"
};

export const MSG_currentBalanceTitle = {
  id: "e2f0f0ca-15f4-4283-b402-f3ef774ab821",
  description: "The title for the current balance of an envelope.",
  defaultMessage: "Current Balance"
};

export const MSG_generatingIconSuggestions = {
  id: "12c7bcf9-0b7d-45ec-b9c6-ec5b172fe8d6",
  description: "",
  defaultMessage: "Generating icon suggestions for {term}..."
};

export const MSG_MSG_suggestButton = {
  id: "9cd8d2ac-274d-4885-8e29-bb19d430f49b",
  description: "The button that offers icon suggestions",
  defaultMessage: "Suggest"
};

export const MSG_suggestMoreButton = {
  id: "50b247b9-fab8-4cd7-8b96-d299eec73b42",
  description: "A button that lets them generate more suggestions for icons.",
  defaultMessage: "Suggest More"
};

export const MSG_suggestionErrorTryAgain = {
  id: "18a14603-053a-43e7-868f-2604b0aa6c32",
  description: "",
  defaultMessage: "There was an unexpected error generating suggestions.  Please try again."
};

export const MSG_dragToMove = {
  id: "31e3cc77-dfc0-450f-86c3-f43dc61e911a",
  description: "A very short instruction that they should drag to move an envelope.  The text of this needs to be no more than 15 characters long.",
  defaultMessage: "Drag to move"
};

export const MSG_selectAnEnvelopeTitle = {
  id: "847dc984-bea2-495a-a1bf-08289f8ddecf",
  description: "The title for the Envelope Selector modal.",
  defaultMessage: "Select an Envelope"
};

export const MSG_maxEnvelopesMobile = {
  id: "633d7210-d58d-49be-949d-bb11160b4c09",
  description: "",
  defaultMessage: "You cannot create more envelopes through the mobile app at this time."
};

export const MSG_thisEnvelopeCannotBeUsedMobile = {
  id: "8819fe78-d5ef-4837-848f-0196ce4b27a8",
  description: "",
  defaultMessage: "This envelope cannot be used through the mobile app."
};

export const MSG_selectEnvelopeTitle = {
  id: "7c66bde9-e17f-4b5f-b4a4-236693d8b756",
  description: "The label for the envelope selector when no envelope is selected.",
  defaultMessage: "Select envelope"
};
