import * as React from "react";
import { PropsWithChildren } from "react";
import { useParams } from "react-router";
import { useLazyGetBudgetVsActualReportQuery } from "shared/state/endpoints/app/reports_api";
import { Spinner } from "@web/components/Spinner";
import { useIntlFormatters } from "shared/utils/formatters";
import { selectCurrentSubscription } from "shared/state/slices/data_selectors/core_data_selectors";
import { useSelector } from "react-redux";
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledTableRow
} from "@web/components/styled/StyledTable";
import classnames from "classnames";
import {
  MSG_actualExpensesHeader,
  MSG_allEnvelopesLabel,
  MSG_averageLabel,
  MSG_budgetedExpensesHeader,
  MSG_differenceHeader,
  MSG_lastYears,
  MSG_pastYearsToDate,
  MSG_totalLabel
} from "shared/strings/reports";
import { BudgetVsActualDatum } from "shared/utils/api_types";
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components";
import { EnvelopeSelector } from "@web/components/selectors/EnvelopeSelector";
import { SelectInput } from "@web/components/inputs/SelectInput";

interface IProps {}

const BudgetVsActualReport: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgerId = useParams<{ ledgerId: string }>().ledgerId as string;
  const [getReport] = useLazyGetBudgetVsActualReportQuery();
  const [report, setReport] = React.useState<{months: {[date: string]: BudgetVsActualDatum}} | null>(null);
  const theme = useTheme();
  const subscription = useSelector(selectCurrentSubscription)!;
  const {formatMonth, formatCurrency, formatMessage} = useIntlFormatters();
  const [envelopeId, setEnvelopeId] = React.useState<string | null>(null);
  const [range, setRange] = React.useState<string>('1yr-to-date');

  React.useEffect(() => {
    async function execute() {
      try {
        setReport(await getReport({ledgerId, envelopeId, range}).unwrap());
      } catch (e) {
        console.error(e);
      }
    }
    void execute();
  }, [envelopeId, range]);

  const data = React.useMemo(() => {
    if (!report) {
      return [];
    }
    return Object.keys(report.months).map((month) => {
      return {
        month: formatMonth(month+'-01', subscription),
        budget: -report.months[month].budget,
        actual: -report.months[month].actual,
        difference: -report.months[month].difference
      };
    });
  }, [report]);

  const totalBudget = React.useMemo(() => {
    if (!report) { return 0; }
    return Object.values(report.months).reduce((acc, curr) => acc + curr.budget, 0);
  }, [report]);
  const averageBudget = React.useMemo(() => {
    if (!report) { return 0; }
    return totalBudget / Object.keys(report.months).length;
  }, [report, totalBudget]);

  const totalActual = React.useMemo(() => {
    if (!report) { return 0; }
    return Object.values(report.months).reduce((acc, curr) => acc + curr.actual, 0);
  }, [report]);
  const averageActual = React.useMemo(() => {
    if (!report) { return 0; }
    return totalActual / Object.keys(report.months).length;
  }, [report, totalActual]);

  const totalDifference = React.useMemo(() => {
    if (!report) { return 0; }
    return Object.values(report.months).reduce((acc, curr) => acc + curr.difference, 0);
  }, [report]);
  const averageDifference = React.useMemo(() => {
    if (!report) { return 0; }
    return totalDifference / Object.keys(report.months).length;
  }, [report, totalDifference]);

  return (
    <div>
      {report ? (
        <div>
          <div className="flex-row">
            <EnvelopeSelector
              ledgerId={ledgerId}
              emptyLabel={formatMessage(MSG_allEnvelopesLabel)}
              placement="bottom"
              envelopeId={envelopeId}
              onChange={setEnvelopeId}
            />
            <SelectInput
              className="ms-4"
              value={range}
              onChange={(e) => setRange(e.target.value)}>
              <option value="1yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 1})}</option>
              <option value="2yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 2})}</option>
              <option value="5yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 5})}</option>
              <option value="10yr-to-date">{formatMessage(MSG_pastYearsToDate, {years: 10})}</option>
              <option value="1yr-full">{formatMessage(MSG_lastYears, {years: 1})}</option>
              <option value="2yr-full">{formatMessage(MSG_lastYears, {years: 2})}</option>
              <option value="5yr-full">{formatMessage(MSG_lastYears, {years: 5})}</option>
              <option value="10yr-full">{formatMessage(MSG_lastYears, {years: 10})}</option>
            </SelectInput>
          </div>

          <div style={{width: '100%', height: '25rem'}} className="mt-8">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={300}
                data={data}
                margin={{left: 25, right: 50}}
              >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                  dataKey="month"
                />
                <YAxis
                  tickFormatter={(tick) => {
                    return formatCurrency(-tick, subscription, {removeDecimals: true});
                  }}
                />
                <YAxis yAxisId="right" hide={true}/>
                <Tooltip
                  formatter={(value) => {
                    return formatCurrency(-value, subscription);
                  }}
                  labelFormatter={(label) => {
                    return formatMonth(label+'-01', subscription);
                  }}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  name={formatMessage(MSG_budgetedExpensesHeader)}
                  dataKey="budget"
                  stroke="#999"
                  strokeWidth={4}
                  dot={false}/>
                <Bar
                  isAnimationActive={false}
                  name={formatMessage(MSG_actualExpensesHeader)}
                  dataKey="actual"
                  fill={theme.colors.negativeEnvelopeTextColor}/>
                <Line
                  yAxisId="right"
                  isAnimationActive={false}
                  type="monotone"
                  name={formatMessage(MSG_differenceHeader)}
                  dataKey="difference"
                  stroke={theme.colors.textColor}
                  strokeWidth={0}
                  dot={false}
                  activeDot={{ r: 0, strokeWidth: 0 }}/>
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          <StyledTable className="mt-4">
            <StyledTableHead>
              <StyledTableHeadRow>
                <StyledTableHeadCell style={{width: '20%'}}></StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}}></StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}} className="text-right">
                  {formatMessage(MSG_budgetedExpensesHeader)}
                </StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}} className="text-right">
                  {formatMessage(MSG_actualExpensesHeader)}
                </StyledTableHeadCell>
                <StyledTableHeadCell style={{width: '20%'}} className="text-right">
                  {formatMessage(MSG_differenceHeader)}
                </StyledTableHeadCell>
              </StyledTableHeadRow>
            </StyledTableHead>
            <StyledTableBody>
              {Object.keys(report.months).map((month) => {
                return (
                  <StyledTableRow key={month}>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>{formatMonth(month+'-01', subscription)}</StyledTableCell>
                    <StyledTableCell className={classnames('text-right', {'text-negative': report.months[month].budget < 0})}
                                     style={{width: '20%'}}>
                      {formatCurrency(report.months[month].budget, subscription)}
                    </StyledTableCell>
                    <StyledTableCell className={classnames('text-right', {'text-negative': report.months[month].actual < 0})}
                                     style={{width: '20%'}}>
                      {formatCurrency(report.months[month].actual, subscription)}
                    </StyledTableCell>
                    <StyledTableCell className={classnames('text-right', {'text-negative': report.months[month].difference < 0})}
                                     style={{width: '20%'}}>
                      {formatCurrency(report.months[month].difference, subscription)}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
              <StyledTableRow style={{borderTopColor: theme.colors.textColor, borderTopWidth: 2}}>
                <StyledTableCell>{formatMessage(MSG_totalLabel)}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell className={classnames('text-right text-bold', {'text-negative': totalBudget < 0})}>
                  {formatCurrency(totalBudget, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right text-bold', {'text-negative': totalActual < 0})}>
                  {formatCurrency(totalActual, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right text-bold', {'text-negative': totalDifference < 0})}>
                  {formatCurrency(totalDifference, subscription)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow style={{borderTopColor: theme.colors.textColor}}>
                <StyledTableCell>{formatMessage(MSG_averageLabel)}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell className={classnames('text-right', {'text-negative': averageBudget < 0})}>
                  {formatCurrency(averageBudget, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right', {'text-negative': averageActual < 0})}>
                  {formatCurrency(averageActual, subscription)}
                </StyledTableCell>
                <StyledTableCell className={classnames('text-right', {'text-negative': averageDifference < 0})}>
                  {formatCurrency(averageDifference, subscription)}
                </StyledTableCell>
              </StyledTableRow>
            </StyledTableBody>
          </StyledTable>
        </div>
      ) : (
        <div className="text-center mt-4">
          <Spinner large/>
        </div>
      )}
    </div>
  );
};

export {BudgetVsActualReport};
