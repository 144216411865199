import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;

  &.hide-first-row-border {
    tr:first-child {
      border-top: 0;
    }
  }

  &.no-row-borders {
    tr {
      border-top: 0;
    }
  }
`;

export const StyledTableHead = styled.thead`
  border-bottom: 1px solid #efefef;
  margin: 0;

  &.no-bottom-border {
    border-bottom: 0;
  }
`;

export const StyledTableBody = styled.tbody`
  margin: 0;
`;

export const StyledTableCell = styled.td`
  padding: 0.35rem 0.5rem;
  text-align: left;
  vertical-align: top;

  &.light-bg {
    background-color: ${({theme}) => theme.colors.lightBackgroundColor };
  }
  &.light-bg-gradiant {
    background: linear-gradient(to right, white 0%, ${({theme}) => theme.colors.lightBackgroundColor } 100%);
  }
`;

export const StyledRightFadeTableCell = styled(StyledTableCell)`
  position: relative;
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
`;

export const StyledTableRow = styled.tr`
  border-top: 1px solid #efefef;

  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: ${({theme}) => theme.colors.rowHighlightColor };;
    }
    &:hover ${StyledRightFadeTableCell} {
      ::after {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), ${({theme}) => theme.colors.rowHighlightColor });
      }
    }
  }

  &.light-bg {
    background-color: ${({theme}) => theme.colors.lightBackgroundColor };
  }
`;

export const StyledTableHeadRow = styled(StyledTableRow)`
  border-top: 0;
  border-bottom: 2px solid #efefef;

  &.no-bottom-border {
    border-bottom: 0;
  }
`;

export const StyledTableHeadCell = styled(StyledTableCell).attrs({as: 'th'})`
  font-weight: bold;
  vertical-align: bottom;
`;
