import * as React from "react";
import { IntlContext } from "react-intl";
import dayjs, { Dayjs } from "dayjs";
import { evaluate } from 'mathjs';
import {
  MSG_fifthOrdinal,
  MSG_firstOrdinal,
  MSG_fourthOrdinal,
  MSG_secondOrdinal,
  MSG_thirdOrdinal
} from "../strings/generic";
import { IncomeSourceFrequency, Subscription } from "./api_types";
import {
  MSG_biweeklyFrequencyLabel,
  MSG_monthlyFrequencyLabel,
  MSG_semimonthlyFrequencyLabel,
  MSG_weeklyFrequencyLabel
} from "../strings/budget";

export interface IFormatNumberOptions {format?: string, precision?: number, hideSeparators?: boolean, removeDecimals?: boolean};
function formatNumber(value: number, subscription: Subscription, options: IFormatNumberOptions = {}): string {
  let negative = false;
  if (value < 0) {
    negative = true;
    value = -1 * value;
  }

  let str;
  if (typeof options.precision !== 'undefined') {
    str = value.toFixed(options.precision);
  } else {
    str = `${value}`;
  }

  let valueStr = str;
  if (!options.hideSeparators) {
    const format = options.format || subscription.numberFormat;
    let groupSep = ",";
    let decimalSep = ".";
    if (format === '1.234,56') {
      groupSep = ".";
      decimalSep = ",";
    } else if (format === '1 234,56') {
      groupSep = " ";
      decimalSep = ",";
    } else if (format === '1 234.56') {
      groupSep = " ";
      decimalSep = ".";
    }

    let rx = /\B(?=(\d{3})+(?!\d))/g;

    let parts = str.split(/[., ]/);
    parts[0] = parts[0].replace(rx, groupSep);
    valueStr = parts.join(decimalSep);
  }

  if (options.removeDecimals) {
    valueStr = valueStr.replace(/[.,]\d+$/, '');
  }

  if (negative) {
    return `-${valueStr}`;
  } else {
    return valueStr;
  }
}

function parseNumber(valueStr: string, options: {totalValue?: number} = {}) {
  try {
    valueStr = valueStr.replace(/[^0-9.,\-+\/\*/(/)]/g, '');
    let val = evaluate(valueStr.replace(/,/g, ''));
    if (valueStr.match(/%\s*$/) && options.totalValue !== undefined) {
      return options.totalValue * Math.abs(val);
    } else {
      return val;
    }
  } catch (e) {
    return 0;
  }
}

function parseCurrency(valueStr: string) {
  return Math.round(parseNumber(valueStr) * 100);
}

export interface IFormatPercentOptions {hideSymbol?: boolean}
function formatPercent(value: number, subscription: Subscription, options: IFormatPercentOptions = {}): string {
  let symbol = '%';
  if (options?.hideSymbol) symbol = '';
  return formatNumber(value / 100, subscription) + symbol;
}

export interface IFormatCurrencyOptions {showSymbol?: boolean, removeDecimals?: boolean}
function formatCurrency(value: number, subscription: Subscription, options: IFormatCurrencyOptions = {}): string {
  const divisor = Math.pow(10, subscription.currencyDecimals);
  let valueStr = formatNumber(value / divisor, subscription, {precision: subscription.currencyDecimals, removeDecimals: options.removeDecimals});
  let symbol = '$';
  if (!options?.showSymbol) symbol = '';

  if (value < 0) {
    return `-${symbol}${valueStr.replace(/^-/, '')}`;
  } else {
    return `${symbol}${valueStr}`;
  }
}

function formatDate(value: string | Dayjs, subscription: Subscription, options?: {format?: string}) {
  if (options?.format) {
    return dayjs(value).format(options.format);
  } else {
    return dayjs(value).format(subscription.dateFormat);
  }
}

function formatMonth(value: string | Dayjs, subscription: Subscription) {
  return dayjs(value).format('MMM YYYY');
}

function formatTime(value: string | Dayjs, subscritpion: Subscription, options?: {format?: string}) {
  if (options?.format) {
    return dayjs(value).format(options.format);
  } else {
    return dayjs(value).format(subscritpion.timeFormat);
  }
}

function formatOrdinal(value: number, intl: any): string {
  if (value === 1) return intl.formatMessage(MSG_firstOrdinal);
  if (value === 2) return intl.formatMessage(MSG_secondOrdinal);
  if (value === 3) return intl.formatMessage(MSG_thirdOrdinal);
  if (value === 4) return intl.formatMessage(MSG_fourthOrdinal);
  if (value === 5) return intl.formatMessage(MSG_fifthOrdinal);

  return `${value}`;
}

function formatFrequency(amount: number, frequency: IncomeSourceFrequency, subscription: Subscription, intl: any, options?: IFormatCurrencyOptions) {
  let msg = MSG_monthlyFrequencyLabel;
  switch (frequency) {
    case "monthly": msg = MSG_monthlyFrequencyLabel; break;
    case "semimonthly": msg = MSG_semimonthlyFrequencyLabel; break;
    case "biweekly": msg = MSG_biweeklyFrequencyLabel; break;
    case "weekly": msg = MSG_weeklyFrequencyLabel; break;
  }
  return `${formatCurrency(amount, subscription, options)} ${intl.formatMessage(msg)}`;
}

export function useIntlFormatters() {
  const intl = React.useContext(IntlContext);

  return {
    formatMessage: intl.formatMessage,
    formatNumber: formatNumber,
    formatPercent: formatPercent,
    formatCurrency: formatCurrency,
    formatDate: formatDate,
    formatMonth: formatMonth,
    formatTime: formatTime,
    parseNumber: parseNumber,
    formatOrdinal: (value: number) => formatOrdinal(value, intl),
    parseCurrency: parseCurrency,
    formatFrequency: (amount: number, frequency: IncomeSourceFrequency, subscription: Subscription, options?: IFormatCurrencyOptions) => formatFrequency(amount, frequency, subscription, intl, options),
  }
}

