import styled from "styled-components";

const StyledDropbox = styled.div`
  border-radius: ${({theme}) => theme.unallocatedWindow.dropboxBorderRadius };
  border-width: 2px;
  border-color: ${({theme}) => theme.colors.lightBorder };
  border-style: dashed;
  color: ${({theme}) => theme.colors.mutedTextColor };
  padding: 1rem;
  text-align: center;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  font-size: 85%;
  cursor: pointer;
  background: #fff;

  &:first-child { margin-left: 0; }
  &:last-child { margin-right: 0; }

  .title {
    font-weight: bold;
    font-size: 120%;
  }

  &.active, &.hover-focus-border:hover {
    border-color: ${({theme}) => theme.unallocatedWindow.dropboxActiveTextColor };
    border-style: solid;
    background: ${({theme}) => theme.unallocatedWindow.dropboxActiveBackgroundColor };
    color: ${({theme}) => theme.unallocatedWindow.dropboxActiveTextColor };

    &.forbidden {
      cursor: not-allowed;
      background: #fff;
      border-color: ${({theme}) => theme.colors.danger };
      color: ${({theme}) => theme.colors.danger };
      border-style: dashed;
    }
  }
`;

const StyledHorizontalDropbox = styled(StyledDropbox)`
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
`;

export {StyledDropbox, StyledHorizontalDropbox};
