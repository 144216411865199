import * as React from "react";
import { PropsWithChildren } from "react";
import { useGetHelpGroupsQuery } from "shared/state/endpoints/app/help_api";
import { useIntlFormatters } from "shared/utils/formatters";
import { Spinner } from "@web/components/Spinner";
import { StyledCard, StyledCardNavLinkBody } from "@web/components/styled/StyledCard";
import { MSG_numHelpArticles } from "shared/strings/help";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getHelpGroupIcon, GroupDescription, GroupIcon, GroupName } from "./helpers";

interface IProps {}

const HelpCenterPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {data: groups, isFetching} = useGetHelpGroupsQuery();
  const {formatMessage} = useIntlFormatters();

  return (
    <React.Fragment>
      {isFetching ? (
        <div className="text-center">
          <Spinner large/>
        </div>
      ) : (
        <StyledCard>
          {groups?.map(group => (
            <StyledCardNavLinkBody to={`${group.id}/${group.slug}`} key={group.id} className="clickable">
              <div className="flex-row align-items-center">
                <GroupIcon>
                  <FontAwesomeIcon icon={getHelpGroupIcon(group.icon)}/>
                </GroupIcon>
                <div className="flex-grow-1">
                  <GroupName className="clickable-title">{group.name}</GroupName>
                  <GroupDescription>{group.description}</GroupDescription>
                </div>
                <div style={{marginLeft: '2rem'}}>
                  {formatMessage(MSG_numHelpArticles, {numArticles: group.totalHelpArticles})}
                </div>
              </div>
            </StyledCardNavLinkBody>
          ))}
        </StyledCard>
      )}
    </React.Fragment>
  );
};

export {HelpCenterPage};
