import styled from "styled-components";

export const StyledInput = styled.input`
  border-style: solid;
  border-width: 1px;
  border-radius: ${({theme}) => theme.inputContainer.borderRadius};
  border-color: ${({theme}) => theme.colors.lightBorder};
  background-color: ${({theme}) => theme.inputContainer.backgroundColor};
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-weight: bold;

  &::placeholder {
    color: ${({theme}) => theme.colors.lightBorder};
  }

  &:focus {
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 0.125em ${({theme}) => theme.colors.primary}44;
  }

  &:disabled {
    opacity: 0.75;
  }
`;
