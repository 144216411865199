import { faFaceAnguished } from "@fortawesome/pro-light-svg-icons/faFaceAnguished";
import { faFaceSpiralEyes } from "@fortawesome/pro-light-svg-icons/faFaceSpiralEyes";
import { faFaceThinking } from "@fortawesome/pro-light-svg-icons/faFaceThinking";
import { faFaceWeary } from "@fortawesome/pro-light-svg-icons/faFaceWeary";
import { faFaceWoozy } from "@fortawesome/pro-light-svg-icons/faFaceWoozy";
import { faFaceWorried } from "@fortawesome/pro-light-svg-icons/faFaceWorried";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_pageNotFound1, MSG_pageNotFound2 } from "shared/strings/generic";
import { useNavigateToDefaultRoute } from "@web/utils/hooks";
import { selectBootstrapping } from "shared/state/slices/data_selectors/core_data_selectors";
import { LoadingPage } from "@web/pages/app/authenticated/LoadingPage";
import { useSelector } from "react-redux";


interface IProps {}

const PageNotFound: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const navigateToDefaultRoute = useNavigateToDefaultRoute();
  const bootstrapping = useSelector(selectBootstrapping);

  React.useEffect(() => {
    if (location.pathname.match(/\/app\/?$/) ||
        location.pathname.match(/\/app\/ledgers\/?$/) ||
        location.pathname.match(/\/app\/ledgers\/[a-zA-Z0-9\-]+\/?$/)
    ) {
      navigateToDefaultRoute();
    }
  }, []);

  // Show the fancy loading spinner while we are waiting.  Since this is doing a couple
  // round trips to the server, it could take a bit depending on network speeds.  This
  // nice animation makes that wait more pleasant.
  if (bootstrapping) {
    return <LoadingPage/>;
  }

  const icon = [
    faFaceAnguished,
    faFaceSpiralEyes,
    faFaceWeary,
    faFaceWoozy,
    faFaceThinking,
    faFaceWorried
  ][Math.floor(Math.random() * 6)];

  return (
    <Container>
      <Icon><FontAwesomeIcon icon={icon}/></Icon>
      <div>{formatMessage(MSG_pageNotFound1)}</div>
      <div>{formatMessage(MSG_pageNotFound2)}</div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-top: 2rem;
  font-size: 1.5rem;
  color: ${({theme}) => theme.colors.danger};
  text-align: center;
`;

const Icon = styled.div`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

export {PageNotFound};
