import {
    Account,
    Allocation,
    ApiError,
    BudgetedExpense,
    EmojiSuggestion,
    Envelope,
    FEATURE_LEVEL,
    HelpArticle,
    HelpGroup,
    IncomeAllocation,
    IncomeSource,
    Ledger,
    ParsedImport,
    ParsedImportAccount,
    ParsedImportMatch,
    ParsedImportTransaction,
    PaymentMethod,
    PlanChangePreview,
    Price,
    Product,
    StripePayment,
    Subscription,
    SyncResponse,
    Transaction,
    User
} from "./api_types";
import dayjs from "dayjs";
import {
    Group,
    GroupAdmin,
    GroupInvitation,
    GroupPlanChangePreview,
    GroupSubscription,
    GroupUser
} from "./api_groups_types";

export function fromApiError(attrs: any): ApiError {
  if (attrs) {
    if (attrs['error_type'] === 'model') {
      return {errorType: 'models', errors: attrs['errors']};
    } else if (attrs['error_type'] === 'message') {
      return {errorType: 'message', message: attrs['message']};
    }
  }
  return {errorType: 'message', message: 'Unexpected error occurred.'};
}

export function fromApiPlanChangePreview(attrs: any): PlanChangePreview {
  return {
    currency: attrs['currency'],
    currentInvoiceAmount: attrs['current_invoice_amount'],
    currentInvoiceBalanceChange: attrs['current_invoice_balance_change'],
    nextInvoiceAmount: attrs['next_invoice_amount'],
    nextInvoiceAt: attrs['next_invoice_at'],
    downgradeAtNextBillingCycle: attrs['downgrade_at_next_billing_cycle'],
  }
}

export function fromApiProduct(attrs: any): Product {
  let prices: {[currency: string]: Price} = {};
  Object.values(attrs['prices']).forEach((price: any) => {
    prices[price['currency']] = {
      currency: price['currency'],
      amount: price['amount'],
    }
  });

  return {
    productKey: attrs['product_key'],
    featureLevel: attrs['feature_level'],
    prices: prices,
    interval: attrs['interval'],
    intervalCount: attrs['interval_count'],
    legacy: attrs['legacy'],
  }
}

export function fromApiUser(attrs: any): User {
  const user: User = {
    id: attrs['id'],
    active: attrs['active'] || false,
    email: attrs['email'],
    firstName: attrs['first_name'],
    lastName: attrs['last_name'],
    role: attrs['role'],
    invitationStatus: attrs['invitation_status'],
    profilePictureUrl: attrs['profile_picture_url'],
    lastWebAccessAt: attrs['last_web_access_at'],
    lastMobileAccessAt: attrs['last_mobile_access_at'],
    uiScale: attrs['ui_scale'],
  }

  if (attrs['flags']) {
    user.flags = {};
    if (attrs['flags']['get_started_visible'] !== undefined) user.flags.getStartedVisible = attrs['flags']['get_started_visible'];
  }

  if (attrs['subscription']) user.subscription = fromApiSubscription(attrs['subscription']);
  if (attrs['api_key']) user.apiKey = attrs['api_key'];
  return user;
}

export function toApiUser(user: Partial<User>): any {
  const obj: any = {};
  if (user.email !== undefined) obj.email = user.email;
  if (user.firstName !== undefined) obj.first_name = user.firstName;
  if (user.lastName !== undefined) obj.last_name = user.lastName;
  if (user.role !== undefined) obj.role = user.role;
  if (user.active !== undefined) obj.active = user.active;
  if (user.uiScale !== undefined) obj.ui_scale = user.uiScale;
  if (user.flags) {
    obj.flags = {};
    if (user.flags.getStartedVisible !== undefined) obj.flags['get_started_visible'] = user.flags.getStartedVisible;
  }
  return obj;
}

export function fromApiStripePayment(attrs: any): StripePayment {
  return {
    amount: attrs['amount'],
    currency: attrs['currency'],
    error: attrs['error'],
    dueAt: attrs['due_at'],
    attemptedAt: attrs['attempted_at'],
    nextAttemptAt: attrs['next_attempt_at'],
    paymentMethod: attrs['payment_method'] ? fromApiPaymentMethod(attrs['payment_method']) : null,
  }
}

export function fromApiPaymentMethod(attrs: any): PaymentMethod {
  const paymentMethod: PaymentMethod = {
    type: attrs['type'],
  }
  if (attrs['card_brand']) paymentMethod.cardBrand = attrs['card_brand'];
  if (attrs['card_last4']) paymentMethod.cardLast4 = attrs['card_last4'];
  if (attrs['card_exp_month']) paymentMethod.cardExpMonth = attrs['card_exp_month'];
  if (attrs['card_exp_year']) paymentMethod.cardExpYear = attrs['card_exp_year'];
  return paymentMethod;
}

export function fromApiSubscription(attrs: any): Subscription {
  const sub: Subscription = {
    id: attrs.id,
    featureLevel: attrs['feature_level'] as FEATURE_LEVEL,
    productKey: attrs['product_key'],
    scheduledProductKey: attrs['scheduled_product_key'],
    currency: attrs.currency,
    stripePublishableKey: attrs['stripe_publishable_key'],
    stripeCustomerId: attrs['stripe_customer_id'],
    stripeAccountBalance: attrs['stripe_account_balance'] || 0,
    stripeCanceledAt: attrs['stripe_canceled_at'] || null,
    stripeCancelAt: attrs['stripe_cancel_at'] || null,
    stripePeriodStartedAt: attrs['stripe_period_started_at'] || null,
    stripePeriodEndsAt: attrs['stripe_period_ends_at'] || null,
    stripeStatus: attrs['stripe_status'],
    stripePaymentMethod: attrs['stripe_payment_method'] ? fromApiPaymentMethod(attrs['stripe_payment_method']) : null,
    stripeLatestFailedPayment: attrs['stripe_latest_failed_payment'] ? fromApiStripePayment(attrs['stripe_latest_failed_payment']) : null,
    dateFormat: attrs['date_format'],
    timeFormat: attrs['time_format'],
    numberFormat: attrs['number_format'],
    currencyDecimals: attrs['currency_decimals'],
  }
  if (attrs.users) sub.users = attrs.users.map(fromApiUser);
  if (attrs.group) sub.group = fromApiGroup(attrs.group);
  return sub;
}

export function fromApiLedger(attrs: any): Ledger {
  const ledger: Ledger = {
    id: attrs['id'],
    name: attrs['name'],
    amount: attrs['amount'],
    archived: attrs['archived'],
    locked: attrs['locked'],
    sequenceNumber: attrs['sequence_number'],
  }
  if (attrs['accounts']) ledger.accounts = attrs['accounts'].map(fromApiAccount);
  if (attrs['envelopes']) ledger.envelopes = attrs['envelopes'].map(fromApiEnvelope);
  if (attrs['income_sources']) ledger.incomeSources = attrs['income_sources'].map(fromApiIncomeSource);
  if (attrs['income_allocations']) ledger.incomeAllocations = attrs['income_allocations'].map(fromApiIncomeAllocation);
  if (attrs['budgeted_expenses']) ledger.budgetedExpenses = attrs['budgeted_expenses'].map(fromApiBudgetedExpense);
  if (attrs['unallocated_count']) ledger.unallocatedCount = attrs['unallocated_count'];
  return ledger;
}

export function toApiLedger(ledger: Partial<Ledger>): any {
  const obj: any = {};
  if (ledger.id !== undefined) obj.id = ledger.id;
  if (ledger.name !== undefined) obj.name = ledger.name;
  if (ledger.archived !== undefined) obj.archived = ledger.archived;
  if (ledger.sequenceNumber !== undefined) obj.sequence_number = ledger.sequenceNumber;
  return obj;
}

export function fromApiAccount(attrs: any): Account {
  const account: Account = {
    id: attrs['id'],
    ledgerId: attrs['ledger_id'],
    name: attrs['name'],
    amount: attrs['amount'],
    archived: attrs['archived'],
    sequenceNumber: attrs['sequence_number'],
    lastReconciledAt: attrs['last_reconciled_at'],
  }
  if (attrs['locked']) account.locked = attrs['locked'];
  if (attrs['envelopes']) account.envelopes = attrs['envelopes'].map(fromApiEnvelope);
  if (attrs['unallocated_count']) account.unallocatedCount = attrs['unallocated_count'];
  if (attrs['pending_reconcile_ids']) account.pendingReconcileIds = attrs['pending_reconcile_ids'];
  if (attrs['pending_reconcile_amount']) account.pendingReconcileAmount = attrs['pending_reconcile_amount'];
  return account;
}

export function toApiAccount(account: Partial<Account>): any {
  const obj: any = {};
  if (account.id !== undefined) obj.id = account.id;
  if (account.name !== undefined) obj.name = account.name;
  if (account.archived !== undefined) obj.archived = account.archived;
  if (account.sequenceNumber !== undefined) obj.sequence_number = account.sequenceNumber;
  if (account.lastReconciledAt !== undefined) obj.last_reconciled_at = account.lastReconciledAt;
  return obj;
}

export function fromApiEnvelope(attrs: any): Envelope {
  return {
    id: attrs['id'],
    ledgerId: attrs['ledger_id'],
    name: attrs['name'],
    color: attrs['color'],
    icon: attrs['icon'],
    amount: attrs['amount'],
    archived: attrs['archived'],
    appX: attrs['app_x'],
    appY: attrs['app_y'],
    mobileSequenceNumber: attrs['mobile_sequence_number'],
    locked: attrs['locked'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  }
}

export function toApiEnvelope(envelope: Partial<Envelope>): any {
  const obj: any = {}
  if (envelope.id !== undefined) obj.id = envelope.id;
  if (envelope.name !== undefined) obj.name = envelope.name;
  if (envelope.color !== undefined) obj.color = envelope.color;
  if (envelope.icon !== undefined) obj.icon = envelope.icon;
  if (envelope.archived !== undefined) obj.archived = envelope.archived;
  if (envelope.appX !== undefined) obj.app_x = envelope.appX;
  if (envelope.appY !== undefined) obj.app_y = envelope.appY;
  if (envelope.mobileSequenceNumber !== undefined) obj.mobile_sequence_number = envelope.mobileSequenceNumber;
  return obj;
}

export function fromApiTransaction(attrs: any): Transaction {
  const transaction: Transaction = {
    id: attrs['id'],
    accountId: attrs['account_id'],
    date: attrs['date'],
    amount: attrs['amount'],
    description: attrs['description'],
    notes: attrs['notes'],
    checkNumber: attrs['check_number'],
    allocated: attrs['allocated'],
    reconciled: attrs['reconciled'],
    imported: attrs['imported'],
    kind: attrs['kind'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  };
  if (attrs['user']) {
    transaction.user = fromApiUser(attrs['user']);
  }
  if (attrs['allocations']) {
    transaction.allocations = attrs['allocations'].map(fromApiAllocation);
  }
  if (attrs['account']) {
    transaction.account = fromApiAccount(attrs['account']);
  }
  return transaction;
}

export function toApiTransaction(transaction: Partial<Transaction>): any {
  const obj: any = {};
  if (transaction.id !== undefined) obj.id = transaction.id;
  if (transaction.accountId !== undefined) obj.account_id = transaction.accountId;
  if (transaction.kind !== undefined) obj.kind = transaction.kind;
  if (transaction.date !== undefined) obj.date = dayjs(transaction.date).format('YYYY-MM-DD');
  if (transaction.amount !== undefined) obj.amount = transaction.amount;
  if (transaction.description !== undefined) obj.description = transaction.description;
  if (transaction.notes !== undefined) obj.notes = transaction.notes;
  if (transaction.checkNumber !== undefined) obj.check_number = transaction.checkNumber;
  if (transaction.reconciled !== undefined) obj.reconciled = transaction.reconciled;
  if (transaction.allocations !== undefined) obj.allocations = transaction.allocations.map(toApiAllocation);
  if (transaction.toAccountId !== undefined) obj.to_account_id = transaction.toAccountId;
  if (transaction.toAllocations !== undefined) obj.to_allocations = transaction.toAllocations.map(toApiAllocation);
  return obj;
}

export function fromApiAllocation(attrs: any): Allocation {
  const allocation: Allocation = {
    envelopeId: attrs['envelope_id'],
    amount: attrs['amount'],
  };
  if (attrs['envelope']) {
    allocation.envelope = fromApiEnvelope(attrs['envelope']);
  }
  return allocation;
}

export function toApiAllocation(allocation: Allocation) {
  return {
    'envelope_id': allocation.envelopeId,
    'amount': allocation.amount
  }
}

export function fromApiIncomeSource(attrs: any): IncomeSource {
  const incomeSource: IncomeSource = {
    id: attrs['id'],
    ledgerId: attrs['ledger_id'],
    name: attrs['name'],
    frequency: attrs['frequency'],
    amount: attrs['amount'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  };
  return incomeSource;
}

export function toApiIncomeSource(incomeSource: Partial<IncomeSource>): any {
  const obj: any = {};
  if (incomeSource.id !== undefined) obj.id = incomeSource.id;
  if (incomeSource.name !== undefined) obj.name = incomeSource.name;
  if (incomeSource.frequency !== undefined) obj.frequency = incomeSource.frequency;
  if (incomeSource.amount !== undefined) obj.amount = incomeSource.amount;
  return obj;
}

export function fromApiBudgetedExpense(attrs: any): BudgetedExpense {
  const budgetedExpense: BudgetedExpense = {
    id: attrs['id'],
    ledgerId: attrs['ledger_id'],
    envelopeId: attrs['envelope_id'],
    name: attrs['name'],
    amount: attrs['amount'],
    frequency: attrs['frequency'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  };
  return budgetedExpense;
}

export function toApiBudgetedExpense(budgetedExpense: Partial<BudgetedExpense>): any {
  const obj: any = {};
  if (budgetedExpense.id !== undefined) obj.id = budgetedExpense.id;
  if (budgetedExpense.envelopeId !== undefined) obj.envelope_id = budgetedExpense.envelopeId;
  if (budgetedExpense.name !== undefined) obj.name = budgetedExpense.name;
  if (budgetedExpense.amount !== undefined) obj.amount = budgetedExpense.amount;
  if (budgetedExpense.frequency !== undefined) obj.frequency = budgetedExpense.frequency;
  return obj;
}

export function fromApiIncomeAllocation(attrs: any): IncomeAllocation {
  const incomeAllocation: IncomeAllocation = {
    id: attrs['id'],
    ledgerId: attrs['ledger_id'],
    incomeSourceId: attrs['income_source_id'],
    envelopeId: attrs['envelope_id'],
    sequenceNumber: attrs['sequence_number'],
    method: attrs['method'],
    amount: attrs['amount'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  };
  return incomeAllocation;
}

export function toApiIncomeAllocation(incomeAllocation: Partial<IncomeAllocation>): any {
  const obj: any = {};
  if (incomeAllocation.id !== undefined) obj.id = incomeAllocation.id;
  if (incomeAllocation.envelopeId !== undefined) obj.envelope_id = incomeAllocation.envelopeId;
  if (incomeAllocation.incomeSourceId !== undefined) obj.income_source_id = incomeAllocation.incomeSourceId;
  if (incomeAllocation.amount !== undefined) obj.amount = incomeAllocation.amount;
  if (incomeAllocation.sequenceNumber !== undefined) obj.sequence_number = incomeAllocation.sequenceNumber;
  if (incomeAllocation.method !== undefined) obj.method = incomeAllocation.method;
  return obj;
}

export function fromApiParsedImport(attrs: any): ParsedImport {
  return {
    accounts: attrs.accounts.map(fromApiParsedImportAccount)
  }
}

export function fromApiParsedImportAccount(attrs: any): ParsedImportAccount {
  return {
    accountDescription: attrs['account_description'],
    accountNumber: attrs['account_number'],
    bankNumber: attrs['bank_number'],
    transactions: attrs['transactions'].map(fromApiParsedImportTransaction)
  };
}

export function fromApiParsedImportTransaction(attrs: any): ParsedImportTransaction {
  return {
    amount: attrs['amount'],
    attrHash: attrs['attr_hash'],
    checkNumber: attrs['check_number'],
    date: dayjs(attrs['date']),
    description: attrs['description'],
    notes: attrs['notes'],
    matches: attrs['matches'].map(fromApiParsedImportMatch)
  };
}

export function fromApiParsedImportMatch(attrs: any): ParsedImportMatch {
  return {
    id: attrs['id'],
    amount: attrs['amount'],
    date: dayjs(attrs['date']),
    userId: attrs['user_id'],
    description: attrs['description'],
    notes: attrs['notes'],
    checkNumber: attrs['check_number'],
    attrHash: attrs['attr_hash'],
  }
}

export function fromApiEmojiSuggestion(attrs: any): EmojiSuggestion {
  return {
    emoji: attrs['emoji'],
    emojiName: attrs['emoji_name'],
    explanation: attrs['explanation'],
  }
}

export function fromApiHelpArticle(attrs: any): HelpArticle {
  const adminHelpArticle: HelpArticle = {
    id: attrs['id'],
    title: attrs['title'],
    summary: attrs['summary'],
    body: attrs['body'],
    slug: attrs['slug'],
    searchSummary: attrs['search_summary'],
    sequenceNumber: attrs['sequence_number'],
    helpGroupId: attrs['help_group_id'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  }
  if (attrs['help_group']) {
    adminHelpArticle.helpGroup = fromApiHelpGroup(attrs['help_group']);
  }
  return adminHelpArticle;
}

export function toApiHelpArticle(attrs: HelpArticle): any {
  const helpArticle: any = {};
  if (attrs.id) helpArticle.id = attrs.id;
  if (attrs.title) helpArticle.title = attrs.title;
  if (attrs.summary) helpArticle.summary = attrs.summary;
  if (attrs.body) helpArticle.body = attrs.body;
  if (attrs.slug) helpArticle.slug = attrs.slug;
  if (typeof attrs.sequenceNumber === 'number') helpArticle.sequence_number = attrs.sequenceNumber;
  return helpArticle;
}

export function fromApiHelpGroup(attrs: any): HelpGroup {
  const adminHelpGroup: HelpGroup = {
    id: attrs['id'],
    name: attrs['name'],
    slug: attrs['slug'],
    icon: attrs['icon'],
    description: attrs['description'],
    sequenceNumber: attrs['sequence_number'],
    totalHelpArticles: attrs['total_help_articles'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  }
  return adminHelpGroup;
}

export function toApiHelpGroup(attrs: HelpGroup): any {
  const helpGroup: any = {};
  if (attrs.id) helpGroup.id = attrs.id;
  if (attrs.name) helpGroup.name = attrs.name;
  if (attrs.slug) helpGroup.slug = attrs.slug;
  if (attrs.icon) helpGroup.icon = attrs.icon;
  if (attrs.description) helpGroup.description = attrs.description;
  if (typeof attrs.sequenceNumber === 'number') helpGroup.sequence_number = attrs.sequenceNumber;
  return helpGroup;
}

export function fromApiSyncResponse(attrs: any): SyncResponse {
  return {
    ledgers: attrs['ledgers']?.reduce((acc: {[ledgerId: string]: Ledger}, ledgerAttrs: any) => {
      acc[ledgerAttrs['id']] = fromApiLedger(ledgerAttrs);
      return acc;
    }, {}) || {},
    accounts: attrs['accounts']?.reduce((acc: {[accountId: string]: Account}, accountAttrs: any) => {
      acc[accountAttrs['id']] = fromApiAccount(accountAttrs);
      return acc;
    }, {}) || {},
    envelopes: attrs['envelopes']?.reduce((acc: {[envelopeId: string]: Envelope}, envelopeAttrs: any) => {
      acc[envelopeAttrs['id']] = fromApiEnvelope(envelopeAttrs);
      return acc;
    }, {}) || {},
    budgetedExpenses: attrs['budgeted_expenses']?.reduce((acc: {[budgetedExpenseId: string]: BudgetedExpense}, budgetedExpenseAttrs: any) => {
      acc[budgetedExpenseAttrs['id']] = fromApiBudgetedExpense(budgetedExpenseAttrs);
      return acc;
    }, {}) || {},
    incomeSources: attrs['income_sources']?.reduce((acc: {[incomeSourceId: string]: IncomeSource}, incomeSourceAttrs: any) => {
      acc[incomeSourceAttrs['id']] = fromApiIncomeSource(incomeSourceAttrs);
      return acc;
    }, {}) || {},
    incomeAllocations: attrs['income_allocations']?.reduce((acc: {[incomeAllocationId: string]: IncomeAllocation}, incomeAllocationAttrs: any) => {
      acc[incomeAllocationAttrs['id']] = fromApiIncomeAllocation(incomeAllocationAttrs);
      return acc;
    }, {}) || {},
    transactions: attrs['transactions']?.reduce((acc: {[transactionId: string]: Transaction}, transactionAttrs: any) => {
      acc[transactionAttrs['id']] = fromApiTransaction(transactionAttrs);
      return acc;
    }, {}) || {},
    subscription: attrs['subscription'] ? fromApiSubscription(attrs['subscription']) : null,
    users: attrs['users']?.reduce((acc: {[userId: string]: User}, userAttrs: any) => {
      acc[userAttrs['id']] = fromApiUser(userAttrs);
      return acc;
    }, {}) || {},
    removedModels: attrs['removed_models']?.map((removedModelAttrs: any) => {
      return {
        removedClass: removedModelAttrs['removed_class'],
        removedId: removedModelAttrs['removed_id'],
      }
    })
  }
}

export function toApiGroup(attrs: Group): any {
  const group: any = {};
  if (attrs.id) group.id = attrs.id;
  if (attrs.name) group.name = attrs.name;
  if (attrs.logoUrl) group.logo_url = attrs.logoUrl;
  if (attrs.timezone) group.timezone = attrs.timezone;
  return group;
}

export function fromApiGroup(attrs: any): Group {
  const group: Group = {
    id: attrs['id'],
    name: attrs['name'],
    logoUrl: attrs['logo_url'],
    timezone: attrs['timezone'],
    productKey: attrs['product_key'],
    maxSubscriptions: attrs['max_subscriptions'],
    stripeCustomerId: attrs['stripe_customer_id'],
    stripeSubscriptionId: attrs['stripe_subscription_id'],
    stripeStatus: attrs['stripe_status'],
    stripePeriodEndsAt: attrs['stripe_period_ends_at'],
    stripePeriodStartedAt: attrs['stripe_period_started_at'],
    stripeCanceledAt: attrs['stripe_canceled_at'],
    stripeCancelAt: attrs['stripe_cancel_at'],
    stripePaymentMethods: attrs['stripe_payment_methods'],
    stripeAccountBalance: attrs['stripe_account_balance'],
    hasActivePlan: attrs['has_active_plan'],
    createdAt: attrs['created_at'],
    updatedAt: attrs['updated_at'],
  }

  if (attrs['stripe_latest_failed_payment']) {
    group.stripeLatestFailedPayment = {
      amount: attrs['stripe_latest_failed_payment']['amount'],
      attemptedAt: attrs['stripe_latest_failed_payment']['attempted_at'],
      dueAt: attrs['stripe_latest_failed_payment']['due_at'],
      error: attrs['stripe_latest_failed_payment']['error'],
    };
  }

  return group;
}

export function toApiGroupAdmin(attrs: Partial<GroupAdmin>): any {
  const admin: any = {};
  if (attrs.id) admin.id = attrs.id;
  if (attrs.email) admin.email = attrs.email;
  if (attrs.firstName) admin.first_name = attrs.firstName;
  if (attrs.lastName) admin.last_name = attrs.lastName;
  if (typeof attrs.owner !== 'undefined') admin.owner = attrs.owner;
  if (typeof attrs.active !== 'undefined') admin.active = attrs.active;
  return admin;
}

export function fromApiGroupAdmin(attrs: any): GroupAdmin {
  const admin: GroupAdmin = {
    id: attrs['id'],
    email: attrs['email'],
    firstName: attrs['first_name'],
    lastName: attrs['last_name'],
    owner: !!attrs['owner'],
    active: attrs['active'],
    invitationStatus: attrs['invitation_status'],
    invitationExpiresAt: attrs['invitation_expires_at'],
  }
  if (attrs['api_key']) admin.apiKey = attrs['api_key'];
  return admin;
}

export function fromApiGroupPlanChangePreview(attrs: any): GroupPlanChangePreview {
  return {
    currency: attrs['currency'],
    currentInvoiceAmount: attrs['current_invoice_amount'],
    currentInvoiceBalanceChange: attrs['current_invoice_balance_change'],
    nextInvoiceAmount: attrs['next_invoice_amount'],
    nextInvoiceAt: attrs['next_invoice_at'],
  }
}

export function fromApiGroupUser(attrs: any): GroupUser {
  return {
    id: attrs['id'],
    email: attrs['email'],
    firstName: attrs['first_name'],
    lastName: attrs['last_name'],
    role: attrs['role'],
    active: attrs['active'],
    invitationStatus: attrs['invitation_status'],
    invitationExpiresAt: attrs['invitation_expires_at'],
    createdAt: attrs['created_at'],
  }
}

export function fromApiGroupSubscription(attrs: any): GroupSubscription {
  return {
    id: attrs['id'],
    groupUsers: attrs['group_users'].map(fromApiGroupUser),
  }
}

export function toApiGroupInvitation(attrs: Partial<GroupInvitation>): any {
  const invitation: any = {};
  if (attrs.email) invitation.email = attrs.email;
  if (attrs.firstName) invitation.first_name = attrs.firstName;
  if (attrs.lastName) invitation.last_name = attrs.lastName;
  return invitation;
}

export function fromApiGroupInvitation(attrs: any): GroupInvitation {
  return {
    id: attrs['id'],
    email: attrs['email'],
    firstName: attrs['first_name'],
    lastName: attrs['last_name'],
    invitationStatus: attrs['invitation_status'],
    invitationExpiresAt: attrs['invitation_expires_at'],
  }
}
