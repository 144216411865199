import styled from "styled-components";
import { readableColor } from "color2k";

const StyledBadge = styled.div`
  display: inline-block;
  background-color: ${({theme}) => theme.colors.secondary };
  color: ${({theme}) => readableColor(theme.colors.secondary) };
  border-radius: 0.25rem;
  padding: 0 0.35rem;
  font-size: 0.75rem;
  text-transform: uppercase;

  &.warning {
    background-color: ${({theme}) => theme.colors.warning };
    color: #fff;
  }

  &.danger {
    background-color: ${({theme}) => theme.colors.danger };
    color: #fff;
  }

  &.success {
    background-color: ${({theme}) => theme.colors.success };
    color: #fff;
  }

  &.info {
    background-color: ${({theme}) => theme.colors.info };
    color: #fff;
  }
`;

export {StyledBadge};
