import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getSelectorsByUserAgent } from 'react-device-detect'
import {
  getCachedApiKey,
  getCachedGroupsApiKey,
  getCachedOpsApiKey,
  getCachedSessionId,
  getCachedSupportSession
} from "../../utils/local";
import { getMobileDeviceInfo, getMobileVersionStr, getWebVersionStr } from "../../utils/helpers";
import { NB_CONFIG } from "../../utils/config";

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: NB_CONFIG.apiRoot,
    prepareHeaders: async (headers) => {
      const apiKey = await getCachedApiKey();
      if (apiKey) {
        headers.set('NB-API-KEY', apiKey);
      }

      const opsApiKey = await getCachedOpsApiKey();
      if (opsApiKey) {
        headers.set('NB-OPS-API-KEY', opsApiKey);
      }

      const groupsApiKey = await getCachedGroupsApiKey();
      if (groupsApiKey) {
        headers.set('NB-GROUP-API-KEY', groupsApiKey);
      }

      const sessionId = await getCachedSessionId();
      if (sessionId) {
        headers.set('NB-SESSION-ID', sessionId);
      }

      const supportSession = await getCachedSupportSession();
      if (supportSession) {
        headers.set('NB-SUPPORT-SESSION', 'true');
      }

      // If this is a web client, add some extra headers
      let parts = [];
      if (typeof document !== 'undefined' && typeof navigator !== 'undefined') {
        const {browserName, browserVersion} = getSelectorsByUserAgent(navigator.userAgent);
        const screenSize = `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`;
        parts = ['web', getWebVersionStr(), browserName, browserVersion, screenSize].map((p) => p.replace(/;/g, '/'))
        headers.set('NB-CLIENT', parts.join(';'));
      } else {
        parts = ['mobile', getMobileVersionStr()]
        const deviceInfo = getMobileDeviceInfo()
        if (deviceInfo) {
          parts = [...parts, deviceInfo.osName, deviceInfo.osVersion, deviceInfo.platformApiLevel, deviceInfo.deviceType];
        }
      }
      headers.set('NB-CLIENT', parts.join(';'));

      return headers;
    },
  }),
  endpoints: (builder) => ({
  }),
})

