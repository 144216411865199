import * as React from "react";
import { PropsWithChildren } from "react";
import { ICalculatedBudgetValues } from "shared/utils/interfaces";
import {
  EnvelopeBudgetEditorModal,
  IEnvelopeBudgetEditorModal
} from "@web/modals/envelope_budget_editor_modal/EnvelopeBudgetEditorModal";
import { selectIncomeSourcesForLedger } from "shared/state/slices/data_selectors/income_source_data_selectors";
import { getNumChecks } from "shared/utils/helpers";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectActiveEnvelopesForLedger } from "shared/state/slices/data_selectors/envelope_data_selectors";
import { EnvelopeBudgetSummary } from "@web/pages/app/authenticated/ledger/budget/EnvelopeBudgetSummary";
import { sortBy } from "lodash";
import { useIntlFormatters } from "shared/utils/formatters";
import styled from "styled-components";
import { selectCurrentSubscription, selectIncomeAllocationsForLedger } from "shared/state/store";
import { MSG_extraIncome } from "shared/strings/budget";
import { MUTED_TEXT_COLOR, NEGATIVE_ENVELOPE_TEXT_COLOR, TEXT_COLOR } from "NeoBudget/utils/constants";

interface IProps {
  envelopeValues: ICalculatedBudgetValues;
}

const BudgetEnvelopesList: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const envelopeBudgetEditorRef = React.useRef<IEnvelopeBudgetEditorModal>(null);
  const incomeSources = useSelector(selectIncomeSourcesForLedger(ledgerId));
  const incomeAllocations = useSelector(selectIncomeAllocationsForLedger(ledgerId));
  const activeEnvelopes = sortBy(useSelector(selectActiveEnvelopesForLedger(ledgerId)), 'name');
  const subscription = useSelector(selectCurrentSubscription)!;
  const {formatMessage, formatOrdinal, formatCurrency, formatPercent} = useIntlFormatters();
  const [hoveredEnvelopeId, setHoveredEnvelopeId] = React.useState<string | null>(null);

  let numChecks = 1;
  incomeSources.forEach((incomeSource) => {
    numChecks = Math.max(numChecks, getNumChecks(incomeSource.frequency));
  });

  let numIncomeSourceRows = Math.max(4, incomeSources.length);

  return (
    <React.Fragment>
      <HeaderRow>
        <div className="flex-row align-items-center">
          <EnvelopeContainer/>
          <IncomeSourcesContainer>
            <IncomeSourceRow>
              <IncomeSourceName/>
              {Array.from({length: numChecks}).map((_, i) => (
                <IncomeAllocation key={i}>
                  <b>{formatOrdinal(i + 1)}</b>
                </IncomeAllocation>
              ))}
            </IncomeSourceRow>
          </IncomeSourcesContainer>
        </div>
        <div className="flex-row align-items-center mt-4">
          <EnvelopeContainer>
            <ExtraIncomeBox>
              <b>{formatMessage(MSG_extraIncome)}:</b>
            </ExtraIncomeBox>
          </EnvelopeContainer>
          <IncomeSourcesContainer>
            {incomeSources.map((incomeSource, i) => {
              let localNumChecks = Math.ceil(getNumChecks(incomeSource.frequency, {fractional: true}));
              return (
                <IncomeSourceRow key={i}>
                  <IncomeSourceName>{incomeSource.name || <span>&nbsp;</span>}</IncomeSourceName>
                  {Array.from({length: localNumChecks}).map((_, i) => {
                    //incomeAllocations.find(ia => ia.incomeSourceId === incomeSource.id && ia.sequenceNumber === i && ia.envelopeId === env.id);
                    // calculate the amount for this income source and check number that has not been allocated.
                    let allocatedAmount = incomeAllocations
                      ?.filter(ia => ia.incomeSourceId === incomeSource.id && ia.sequenceNumber === i)
                      ?.reduce((acc, ia) => {
                        if (ia.method === 'percent') {
                          return acc + (ia.amount / 10000) * incomeSource.amount;
                        } else {
                          return acc + ia.amount;
                        }
                      }, 0);
                    let amount = incomeSource.amount - (allocatedAmount || 0);

                    return (
                      <IncomeAllocation key={i} style={{
                        color: amount < 0 ? NEGATIVE_ENVELOPE_TEXT_COLOR : (amount > 0 ? TEXT_COLOR : MUTED_TEXT_COLOR),
                        fontWeight: amount != 0 ? 'bold' : 'normal'
                      }}>
                        {formatCurrency(amount, subscription)}
                      </IncomeAllocation>
                    );
                  })}
                </IncomeSourceRow>
              );
            })}
          </IncomeSourcesContainer>
        </div>
      </HeaderRow>

      {activeEnvelopes.map((env) => (
        <Row key={env.id} className="clickable" onClick={(e) => {
          e.stopPropagation();
          envelopeBudgetEditorRef.current?.show(env);
        }}>
          <EnvelopeContainer>
            <EnvelopeBudgetSummary
              envelope={env}
              fundingValues={props.envelopeValues[env.id].fundingSummary}
              expenseValues={props.envelopeValues[env.id].expensesSummary}
            />
          </EnvelopeContainer>
          <IncomeSourcesContainer>
            {incomeSources.map((incomeSource, i) => {
              let localNumChecks = Math.ceil(getNumChecks(incomeSource.frequency, {fractional: true}));
              return (
                <IncomeSourceRow key={i}>
                  <IncomeSourceName>{incomeSource.name || <span>&nbsp;</span>}</IncomeSourceName>
                  {Array.from({length: localNumChecks}).map((_, i) => {
                    let alloc = incomeAllocations.find(ia => ia.incomeSourceId === incomeSource.id && ia.sequenceNumber === i && ia.envelopeId === env.id);
                    let amount = alloc?.amount || 0;

                    return (
                      <IncomeAllocation key={i} style={{
                        color: amount < 0 ? NEGATIVE_ENVELOPE_TEXT_COLOR : (amount > 0 ? TEXT_COLOR : MUTED_TEXT_COLOR),
                        fontWeight: amount != 0 ? 'bold' : 'normal'
                      }}>
                        {alloc ? (
                          <React.Fragment>
                            {alloc && alloc.method == 'percent'
                              ? formatPercent(amount, subscription)
                              : formatCurrency(amount, subscription)}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {formatCurrency(0, subscription)}
                          </React.Fragment>
                        )}
                      </IncomeAllocation>
                    );
                  })}
                </IncomeSourceRow>
              );
            })}
          </IncomeSourcesContainer>
        </Row>
      ))}

      {/*<table style={{width: '100%'}}>*/}
      {/*  <StickyHeaderTr>*/}
      {/*    <td/>*/}
      {/*    <HeaderCell>{formatMessage(MSG_incomeSourcesTitle)}</HeaderCell>*/}
      {/*    {Array.from({length: numChecks}).map((_, i) => (*/}
      {/*      <HeaderCheckCell key={i} style={{width: 100}}>{formatOrdinal(i + 1)}</HeaderCheckCell>*/}
      {/*    ))}*/}
      {/*    <td/>*/}
      {/*  </StickyHeaderTr>*/}
      {/*  <tr>*/}
      {/*    <td colSpan={numChecks + 3} style={{height: '0.5rem'}}/>*/}
      {/*  </tr>*/}
      {/*  {activeEnvelopes.map((env) => (*/}
      {/*    <React.Fragment key={env.id}>*/}
      {/*      {Array.from({length: numIncomeSourceRows}).map((_, i) => {*/}
      {/*        const incomeSource = incomeSources[i];*/}
      {/*        return (*/}
      {/*          <Row key={i}*/}
      {/*               onMouseEnter={() => setHoveredEnvelopeId(env.id)}*/}
      {/*               onMouseLeave={() => setHoveredEnvelopeId(null)}*/}
      {/*               className={hoveredEnvelopeId === env.id ? 'hovered' : ''}*/}
      {/*               onClick={(e) => {*/}
      {/*                 e.stopPropagation();*/}
      {/*                 envelopeBudgetEditorRef.current?.show(env);*/}
      {/*               }}>*/}
      {/*            {i === 0 && (*/}
      {/*              <td style={{paddingRight: '1rem', width: '12.5rem'}}*/}
      {/*                  rowSpan={numIncomeSourceRows}>*/}
      {/*                <EnvelopeBudgetSummary*/}
      {/*                  envelope={env}*/}
      {/*                  fundingValues={props.envelopeValues[env.id].fundingSummary}*/}
      {/*                  expenseValues={props.envelopeValues[env.id].expensesSummary}*/}
      {/*                />*/}
      {/*              </td>*/}
      {/*            )}*/}

      {/*            <IncomeSourceName width="1%">*/}
      {/*              <IncomeSourceNameWrapper>*/}
      {/*                {incomeSource?.name || <span>&nbsp;</span>}*/}
      {/*              </IncomeSourceNameWrapper>*/}
      {/*            </IncomeSourceName>*/}
      {/*            {Array.from({length: numChecks}).map((_, i) => {*/}
      {/*              let incomeNumChecks = Math.ceil(getNumChecks(incomeSource?.frequency, {fractional: true}));*/}
      {/*              let alloc = incomeAllocations.find(ia => ia.incomeSourceId === incomeSource?.id && ia.sequenceNumber === i && ia.envelopeId === env.id);*/}
      {/*              let amount = alloc?.amount || 0;*/}
      {/*              return (*/}
      {/*                <IncomeCell key={i}*/}
      {/*                            style={{*/}
      {/*                              width: 100,*/}
      {/*                              color: amount < 0 ? NEGATIVE_TEXT_COLOR : (amount > 0 ? TEXT_COLOR : MUTED_TEXT_COLOR),*/}
      {/*                              fontWeight: amount != 0 ? 'bold' : 'normal'*/}
      {/*                            }}>*/}
      {/*                  {incomeSource && incomeNumChecks >= i + 1 ? (*/}
      {/*                    <React.Fragment>*/}
      {/*                      {alloc ? (*/}
      {/*                        <React.Fragment>*/}
      {/*                          {alloc && alloc.method == 'percent'*/}
      {/*                            ? formatPercent(amount, subscription)*/}
      {/*                            : formatCurrency(amount, subscription)}*/}
      {/*                        </React.Fragment>*/}
      {/*                      ) : (*/}
      {/*                        <React.Fragment>*/}
      {/*                          {formatCurrency(0, subscription)}*/}
      {/*                        </React.Fragment>*/}
      {/*                      )}*/}
      {/*                    </React.Fragment>*/}
      {/*                  ) : ''}*/}
      {/*                </IncomeCell>*/}
      {/*              );*/}
      {/*            })}*/}
      {/*            <td/>*/}
      {/*          </Row>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*      <tr>*/}
      {/*        <td colSpan={numChecks + 3} style={{height: '0.5rem'}}>*/}
      {/*          <Line/>*/}
      {/*        </td>*/}
      {/*      </tr>*/}
      {/*    </React.Fragment>*/}
      {/*  ))}*/}
      {/*</table>*/}
      <EnvelopeBudgetEditorModal
        ledgerId={ledgerId}
        numChecks={numChecks}
        ref={envelopeBudgetEditorRef}
      />
    </React.Fragment>
  );
};

// const StickyHeaderTr = styled.tr`
//   position: sticky;
//   top: 0;
//   background-color: ${props => props.theme.colors.lightBackgroundColor};
//   box-shadow: 0 -5px 0 5px #fff;
//   border-radius: 5px;
//
//   td:last-child {
//     padding-right: 0.5rem;
//   }
// `;
//
// const HeaderCell = styled.td`
//   font-weight: bold;
//   padding-top: 0.5rem;
//   padding-left: 0.5rem;
//   padding-bottom: 0.5rem;
// `;
// const HeaderCheckCell = styled(HeaderCell)`
//   text-align: right;
// `;
//
// const Row = styled.tr`
//   cursor: pointer;
//
//   &.hovered {
//     background-color: ${props => props.theme.colors.lightBackgroundColor};
//   }
// `;
//
// const IncomeSourceName = styled.td`
//   padding-left: 0.5rem;
// `;
//
// const IncomeSourceNameWrapper = styled.div`
//   width: 200px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// `;
//
// const IncomeCell = styled.td`
//   padding-left: 0.5rem;
//   text-align: right;
//
//   &:last-child {
//     padding-right: 0.5rem;
//   }
// `;
//
// const Line = styled.hr`
//   border: 0;
//   border-bottom: 1px solid #ddd;
//   margin: 0.5rem 0;
// `;


const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;

  &.clickable {
    &:hover {
      background-color: #f9f9f9;
    }
  }
`;

const HeaderRow = styled.div`
  padding: 0.5rem;
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.colors.lightBackgroundColor};
  border-radius: 8px;
  box-shadow: 0 0 0 2px #fff;
  z-index: 1;
  border: 0;
  margin-bottom: 1rem;
`;

const EnvelopeContainer = styled.div`
  width: 12.5rem;
  padding-right: 1rem;
`;

const ExtraIncomeBox = styled.div`
  width: 12.5rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IncomeSourcesContainer = styled.div`
`;

const IncomeSourceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const IncomeSourceName = styled.div`
  width: 12.5rem;
  padding-left: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const IncomeAllocation = styled.div`
  padding-left: 0.5rem;
  text-align: right;
  width: 6.5rem;
`;

export {BudgetEnvelopesList};
