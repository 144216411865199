import * as React from "react";
import { PropsWithChildren } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useReloadLedger } from "shared/hooks/use_reload";
import { useIntlFormatters } from "shared/utils/formatters";
import { IPopover } from "@web/components/Popover";
import {
  ITransactionEditorModal,
  TransactionEditorModal
} from "@web/modals/transaction_editor_modal/TransactionEditorModal";
import { AllocationModal, IAllocationModal } from "@web/modals/allocation_modal/AllocationModal";
import { TransactionModalContext, transactionModalContextValue } from "shared/utils/transaction_modal_context";
import { Page } from "@web/components/shared/Page";
import { Pulse } from "@web/components/shared/Pulse";
import { Button } from "@web/components/Button";
import { MSG_newTransactionButton, MSG_numUnallocatedButton, MSG_transactionsTitle } from "shared/strings/transactions";
import { TransactionKindPopover } from "@web/components/selectors/TransactionKindPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBank } from "@fortawesome/pro-light-svg-icons/faBank";
import { faCashRegister } from "@fortawesome/pro-light-svg-icons/faCashRegister";
import { faChartLine } from "@fortawesome/pro-light-svg-icons/faChartLine";
import { faCloudArrowUp } from "@fortawesome/pro-light-svg-icons/faCloudArrowUp";
import { faEnvelopeOpenDollar } from "@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar";
import { faPiggyBank } from "@fortawesome/pro-light-svg-icons/faPiggyBank";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { faScaleUnbalanced } from "@fortawesome/pro-light-svg-icons/faScaleUnbalanced";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import styled from "styled-components";
import { StyledVerticalOptions } from "@web/components/styled/StyledVerticalOptions";
import { MSG_envelopesTitle } from "shared/strings/envelopes";
import { MSG_importTitle } from "shared/strings/import";
import { MSG_reconcileTitle } from "shared/strings/reconcile";
import { MSG_budgetTitle } from "shared/strings/budget";
import { MSG_reportsTitle } from "shared/strings/reports";
import { NavLink } from "react-router-dom";
import {
  resetTransactionsList,
  selectAccountsForLedger,
  selectCurrentSubscription,
  selectCurrentUser,
  selectLedger
} from "shared/state/store";
import { NestedNavLink } from "@web/components/shared/NestedNavLink";
import { BLANK_ACCOUNT, ROLE, User } from "shared/utils/api_types";
import { AccountEditorModal, IAccountEditorModal } from "@web/modals/account_editor_modal/AccountEditorModal";
import { faGear } from "@fortawesome/pro-light-svg-icons";
import { MSG_newAccountButton, MSG_noAccountsCreated } from "shared/strings/accounts";
import { StyledCard, StyledCardBody } from "@web/components/styled/StyledCard";
import { MSG_ledgerSettingsTitle } from "shared/strings/settings";

interface IProps {}

export const LedgerContext = React.createContext<{
  transactionEditorModalRef: React.RefObject<ITransactionEditorModal>
}>({transactionEditorModalRef: React.createRef<ITransactionEditorModal>()})

const LedgersRoot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {ledgerId, accountId} = useParams<{ledgerId: string, accountId?: string}>();
  const ledger = useSelector(selectLedger(ledgerId!));
  const subscription = useSelector(selectCurrentSubscription)!;
  const reloadLedger = useReloadLedger();
  const {formatCurrency, formatMessage} = useIntlFormatters();
  const newTransactionKindRef = React.useRef<IPopover>(null);
  const newTransactionButtonRef = React.useRef<any>(null);
  const transactionEditorModalRef = React.useRef<ITransactionEditorModal>(null);
  const allocationModalRef = React.useRef<IAllocationModal>(null);
  const currentUser = useSelector(selectCurrentUser) as User;
  const accounts = useSelector(selectAccountsForLedger(ledgerId!));
  const accountEditorModalRef = React.useRef<IAccountEditorModal>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === `/app/ledgers/${ledgerId}`) {
      navigate(`/app/ledgers/${ledgerId}/envelopes`);
    }
  }, [location.pathname, ledgerId]);

  React.useEffect(() => {
    if (!ledger || ledger.archived) {
      navigate('/app');
    } else {
      // Reset the transaction lists so that they are reloaded when the user navigates back to the
      // transactions page. Without this, the cached version of the transaction list from the
      // previous ledger they were viewing will briefly be displayed before the new ledger's
      // transactions are loaded.
      dispatch(resetTransactionsList({listType: 'main'}));
      dispatch(resetTransactionsList({listType: 'unreconciled'}));
      dispatch(resetTransactionsList({listType: 'unallocated'}));
      // Reload all the cached ledger data.
      void reloadLedger(ledger.id);
    }
  }, [ledgerId, ledger?.archived]);

  if (!ledger) {
    // navigateToDefaultRoute();
    return null;
  };

  return (
    <TransactionModalContext.Provider value={transactionModalContextValue}>
      <LedgerContext.Provider value={{transactionEditorModalRef}}>
        <Page
          header={
            <Header>
              <LeftContainer>
                <AccountName><FontAwesomeIcon icon={faBank}/> {ledger.name}:</AccountName>
                <AccountAmount>{formatCurrency(ledger.amount, subscription)}</AccountAmount>
              </LeftContainer>
              {currentUser.role !== ROLE.OBSERVER && (
                <RightContainer>
                  {!!ledger.unallocatedCount &&
                    <Pulse className="me-2">
                      <Button color="danger" onClick={() => allocationModalRef.current?.show()}>
                        <FontAwesomeIcon icon={faExclamationTriangle}/>{' '}
                        {formatMessage(MSG_numUnallocatedButton, {count: ledger.unallocatedCount})}
                      </Button>
                    </Pulse>}
                  <span ref={newTransactionButtonRef}>
                    <Button onClick={() => newTransactionKindRef.current?.show()}>
                      <FontAwesomeIcon icon={faPlusCircle}/>{' '}
                      {formatMessage(MSG_newTransactionButton)}
                    </Button>
                  </span>
                </RightContainer>
              )}

              <TransactionKindPopover
                ref={newTransactionKindRef}
                targetRef={newTransactionButtonRef}
                onSelect={(kind) => {
                  console.log(accountId);
                  transactionEditorModalRef.current?.show({
                    kind,
                    accountId: accountId && accountId !== 'all' ? accountId : undefined,
                  });
                }}
              />

            </Header>
          }
          sidebar={
            <React.Fragment>
              <StyledVerticalOptions className="nowrap" style={{width: '12.5rem'}}>
                <NavLink className="option" to="envelopes" end>
                  <FontAwesomeIcon icon={faEnvelopeOpenDollar}/>
                  {formatMessage(MSG_envelopesTitle)}
                </NavLink>
                <NavLink className="option" to="transactions/all">
                  <FontAwesomeIcon icon={faCashRegister}/>
                  {formatMessage(MSG_transactionsTitle)}
                </NavLink>
                <NavLink className="option" to="budget">
                  <FontAwesomeIcon icon={faPiggyBank}/>
                  {formatMessage(MSG_budgetTitle)}
                </NavLink>
                {currentUser.role !== ROLE.OBSERVER && (
                  <NavLink className="option" to="import">
                    <FontAwesomeIcon icon={faCloudArrowUp}/>
                    {formatMessage(MSG_importTitle)}
                  </NavLink>)}
                {currentUser.role !== ROLE.OBSERVER && (
                  <NavLink className="option" to="reconcile">
                    <FontAwesomeIcon icon={faScaleUnbalanced}/>
                    {formatMessage(MSG_reconcileTitle)}
                  </NavLink>)}
                <NestedNavLink className="option"
                               to="reports/budget_vs_actual"
                               rootPath={`/app/ledgers/${ledgerId}/reports`}>
                  <FontAwesomeIcon icon={faChartLine}/>
                  {formatMessage(MSG_reportsTitle)}
                </NestedNavLink>
                <NestedNavLink className="option"
                               to="settings"
                               rootPath={`/app/ledgers/${ledgerId}/settings`}>
                  <FontAwesomeIcon icon={faGear}/>
                  {formatMessage(MSG_ledgerSettingsTitle)}
                </NestedNavLink>
              </StyledVerticalOptions>

              {accounts.length > 0 && (
                <StyledVerticalOptions className="nowrap mt-4" style={{width: '12.5rem'}}>
                  {accounts.map((account, i) => (
                    <NestedNavLinkAccount key={account.id} to={`transactions/${account.id}`} rootPath={`transactions/${account.id}`} className="option clickable">
                      <div className="flex-row align-items-center">
                        <FontAwesomeIcon icon={faBank}/>
                        <div>
                          <div className="clickable-title">{account.name}</div>
                          <div className="text-muted">{formatCurrency(account.amount, subscription)}</div>
                        </div>
                      </div>
                    </NestedNavLinkAccount>
                  ))}
                </StyledVerticalOptions>
              )}

              {accounts.length > 0 && (
                <div className="mt-2 ms-4">
                  <Button link onClick={() => {
                    accountEditorModalRef.current?.show({...BLANK_ACCOUNT, ledgerId: ledgerId!});
                  }}>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
                    {formatMessage(MSG_newAccountButton)}
                  </Button>
                </div>
              )}

              {accounts.length === 0 && (
                <StyledCard className="mt-4" style={{width: '12.5rem'}}>
                  <StyledCardBody className="flex-column align-items-center justify-content-center text-center">
                    {formatMessage(MSG_noAccountsCreated)}

                    <Button className="mt-2" onClick={() => {
                      accountEditorModalRef.current?.show({...BLANK_ACCOUNT, ledgerId: ledgerId!});
                    }}>
                      {formatMessage(MSG_newAccountButton)}
                    </Button>

                  </StyledCardBody>
                </StyledCard>
              )}
            </React.Fragment>
          }
        >

          <BodyWrapper>
            <Outlet/>
          </BodyWrapper>

          <TransactionEditorModal
            ref={transactionEditorModalRef}
            newTransactionEditorModal={transactionEditorModalRef.current || undefined}
          />

          <AllocationModal
            ledgerId={ledger.id}
            ref={allocationModalRef}
            transactionEditorModalRef={transactionEditorModalRef}
          />

          <AccountEditorModal
            ref={accountEditorModalRef}/>

        </Page>
      </LedgerContext.Provider>
    </TransactionModalContext.Provider>
  );
};

const HoverShowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;

const AccountName = styled.div`
  font-size: 160%;
  margin-right: 1rem;
  opacity: 0.75;
`;

const AccountAmount = styled.div`
  font-weight: bold;
  font-size: 160%;
`;

const BodyWrapper = styled.div`
  padding: 1rem;
  height: 100%;
`;

const NestedNavLinkAccount = styled(NestedNavLink)`
  :hover {
    text-decoration: none !important;
    cursor: pointer;
  }
`;

export {LedgersRoot};
