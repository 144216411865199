import * as React from "react";
import { Modal } from "@web/components/Modal";
import { ApiError } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import { selectCurrentSubscription, selectLedger, selectLedgers, } from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "@web/utils/hooks";
import styled from "styled-components";
import { faCircle, faMerge } from "@fortawesome/pro-light-svg-icons";
import {
  MSG_combineLedgersButton,
  MSG_combineLedgersExplanation,
  MSG_combineLedgersWarning,
  MSG_currentLedgerTitle,
  MSG_noOtherLedgers,
  MSG_otherLedgersExplanation
} from "shared/strings/ledgers";
import { useParams } from "react-router";
import { StyledCard, StyledCardBody } from "@web/components/styled/StyledCard";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { GREEN_COLOR, MUTED_TEXT_COLOR } from "NeoBudget/utils/constants";
import { useCombineLedgersMutation } from "shared/state/endpoints/app/ledgers_api";
import { parseApiError } from "shared/utils/api_errors";
import { useReloadBootstrapData } from "shared/hooks/use_reload";

interface IProps {
}

interface ICombineLedgersModal  {
  show: () => any;
  hide: () => any;
}

const CombineLedgersModal = React.forwardRef<ICombineLedgersModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const ledgerId = useParams<{ledgerId: string}>().ledgerId!;
  const ledger = useSelector(selectLedger(ledgerId))!;
  const ledgers = useSelector(selectLedgers);
  const {formatMessage} = useIntlFormatters();
  const [selected, setSelected] = React.useState<{[ledgerId: string]: boolean}>({});
  const [combineMutation] = useCombineLedgersMutation();
  const reloadBootstrapData = useReloadBootstrapData();
  const subscription = useSelector(selectCurrentSubscription)!;
  const dispatch = useDispatch();
  const {showErrorAlert} = useAlert();

  React.useImperativeHandle<any, ICombineLedgersModal>(ref, () => ({
    show: () => {
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function combine() {
    if (!confirm(formatMessage(MSG_combineLedgersWarning))) {
      return;
    }32

    setSaving(true);
    try {
      await combineMutation({ledgerId, combineWith: Object.keys(selected).filter((k) => selected[k])}).unwrap();
      await reloadBootstrapData();
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
    }
    setSaving(false);
  }

  const otherLedgers = ledgers.filter((l) => l.id !== ledgerId);

  return (
    <Modal
      windowStyle={{width: '35rem', minHeight: '10rem'}}
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      bodyStyle={{padding: '1rem', paddingBottom: '2rem', display: 'block'}}
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={saving}
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={combine}
                  disabled={saving || Object.values(selected).filter(v => v).length === 0} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
        </div>
      }>

      <div className="text-center p-4">
        <FontAwesomeIconStyled icon={faMerge}/>
        <HeadingStyled>{formatMessage(MSG_combineLedgersButton)}</HeadingStyled>
      </div>
      <p>{formatMessage(MSG_combineLedgersExplanation)}</p>

      <p className="mt-4"><strong>{formatMessage(MSG_currentLedgerTitle)}:</strong> {ledger.name}</p>

      <p className="mt-4 mb-2">{formatMessage(MSG_otherLedgersExplanation)}</p>
      <StyledCard>
        {otherLedgers.length === 0 && (
          <StyledCardBody>
            {formatMessage(MSG_noOtherLedgers)}
          </StyledCardBody>
        )}
        {otherLedgers.map((l) => (
          <StyledCardBody key={l.id} className="flex-row justify-content-space-between align-items-center clickable"
                          onClick={() => setSelected({...selected, [l.id]: !selected[l.id]})}>
            <div>
              <div className="clickable-title">{l.name}</div>
            </div>
            <div>
              {selected[l.id]
                ? <FontAwesomeIcon icon={faCheckCircle} style={{color: GREEN_COLOR}}/>
                : <FontAwesomeIcon icon={faCircle} style={{color: MUTED_TEXT_COLOR}}/>}
            </div>
          </StyledCardBody>
        ))}
      </StyledCard>

      {errors?.errorType === 'message' && (
        <div className="d-flex flex-row">
          <StyledErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            <div>{errors.message}</div>
          </StyledErrorMessage>
        </div>
      )}

    </Modal>
  );
});

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeadingStyled = styled.h1`
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export { CombineLedgersModal, ICombineLedgersModal };
