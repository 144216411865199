import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { StyledEnvelopeContainer } from "../styled/StyledEnvelopeContainer";
import { Envelope, Transaction } from "shared/utils/api_types";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FreePlanNoticePopover } from "../../popovers/locked_envelope_popover/FreePlanNoticePopover";
import { IPopover } from "../Popover";
import { selectCurrentSubscription } from "shared/state/store";
import { useSelector } from "react-redux";

interface IProps {
  envelope: Partial<Envelope>;
  onClick?: () => any;
  className?: string;
  acceptDrop?: string;
  onDrop?: (item: {transactions: Transaction[]}) => Promise<any>;
  hoverFocusBorder?: boolean;
  style?: React.CSSProperties;
}

const EnvelopeBox: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const lockedOverlayRef = React.useRef<any>(null);
  const lockedPopoverRef = React.useRef<IPopover>(null);
  const {formatCurrency} = useIntlFormatters();
  const subscription = useSelector(selectCurrentSubscription)!;
  const envelope = props.envelope;
  let isOver, dropRef;
  if (props.onDrop && props.acceptDrop) {
    [{isOver}, dropRef] = useDrop(() => ({
      accept: props.acceptDrop as string,
      drop: props.onDrop as any,
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }), [props.onDrop]);
  }

  return (
    <StyledEnvelopeContainer
      style={{backgroundColor: envelope.color, ...props.style}}
      className={classNames({
        'has-focus-border': isOver,
        'hover-focus-border': props.hoverFocusBorder,
        locked: envelope.locked,
      })}
      onContextMenu={e => {
        e.preventDefault();
        e.stopPropagation();
        console.log('context menu for', envelope.name);
      }}
      onClick={() => {
        if (envelope.locked) {
          lockedPopoverRef.current?.show();
        } else {
          props.onClick?.();
        }
      }} ref={dropRef}>
      {envelope.icon && <Icon>{envelope.icon}</Icon>}
      <Name>{envelope.name}</Name>
      <Amount className={(envelope?.amount || 0) < 0 ? 'negative' : 'positive'}>
        {formatCurrency(envelope?.amount || 0, subscription)}
      </Amount>
      {envelope.locked && (
        <React.Fragment>
          <LockedOverlay ref={lockedOverlayRef}>
            <FontAwesomeIcon icon={faLock}/>
          </LockedOverlay>
          <FreePlanNoticePopover
            ref={lockedPopoverRef}
            targetRef={lockedOverlayRef}
          />
        </React.Fragment>)}

      {/*<StashIndicator>*/}
      {/*  /!*<Star icon={faStar} />*!/*/}
      {/*  /!*<Warning icon={faCircleExclamation} />*!/*/}
      {/*  <FillIndicator value={6} />*/}
      {/*</StashIndicator>*/}
    </StyledEnvelopeContainer>
  );
};

const Icon = styled.div`
  font-size: 1.5rem;
  position: absolute;
  line-height: 1;
  left: 0.25rem;
  bottom: 0.25rem;
`;

const Name = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Amount = styled.div`
  font-size: 165%;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.negative {
    color: ${({theme}) => theme.colors.negativeEnvelopeTextColor };
  }
`;

const LockedOverlay = styled.div`
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  bottom: -0.25rem;
  right: -0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.65);
  color: ${({theme}) => theme.colors.warning};
  cursor: pointer;

  svg {
    font-size: 200%;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
  }
`;

const StashIndicator = styled.div`
  position: absolute;
  border-radius: 3px;
  overflow: hidden;
  top: 3px;
  right: 3px;
  bottom: 3px;
  width: 0.4rem;
  background: #0006;
  border: 1px solid #0006;
`;

const FillIndicator = styled.div<{value: number}>`
  position: absolute;
  top: ${props => (props.value / 9) * 100}%;
  right: 0;
  bottom: 0;
  background-color: #0f0;
  width: 100%;
`;

// const StashIndicator = styled.div`
//   position: absolute;
//   top: -1.7rem;
//   right: -1.7rem;
//   height: 3.4rem;
//   width: 3.4rem;
//   background: #0004;
//   transform: rotate(45deg);
// `;
//
// const Star = styled(FontAwesomeIcon)`
//   color: gold;
//   position: absolute;
//   left: 50%;
//   bottom: -7%;
//   transform: translate(-50%, -50%) rotate(-45deg);
// `;
//
// const Warning = styled(FontAwesomeIcon)`
//   color: red;
//   background-color: #fff;
//   border-radius: 100%;
//   position: absolute;
//   left: 50%;
//   bottom: -7%;
//   transform: translate(-50%, -50%) rotate(-45deg);
// `;

export {EnvelopeBox};
