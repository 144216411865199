import * as React from "react";
import { Modal } from "@web/components/Modal";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_fileNameLabel,
  MSG_fileSizeLabel,
  MSG_importAccountNumber,
  MSG_importAccountTransactionCount,
  MSG_importedCount,
  MSG_importIntoAccountLabel,
  MSG_importTitle,
  MSG_importTransactionAlreadyImported,
  MSG_importTransactionCheckNumberExists,
  MSG_importTransactionSimilarTransactionExists,
  MSG_importX,
  MSG_selectImportAccount,
  MSG_uploadingLabel,
  MSG_whichToImportQuestion
} from "shared/strings/import";
import { NB_CONFIG } from "shared/utils/config";
import { fromApiParsedImport } from "shared/utils/api_transformations";
import { useSelector } from "react-redux";
import { selectAccountsForLedger, selectApiKey, selectCurrentSubscription } from "shared/state/store";
import { Spinner } from "@web/components/Spinner";
import { humanFileSize } from "@web/utils/formatters";
import { ParsedImport } from "shared/utils/api_types";
import { StyledTable, StyledTableBody, StyledTableCell, StyledTableRow } from "@web/components/styled/StyledTable";
import { useTheme } from "styled-components";
import { Button } from "@web/components/Button";
import {
  MSG_cancelButton,
  MSG_closeButton,
  MSG_selectAll,
  MSG_selectLikely,
  MSG_selectNone
} from "shared/strings/generic";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledBadge } from "@web/components/styled/StyledBadge";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { useNavigate } from "react-router-dom";
import { useReloadLedgers } from "shared/hooks/use_reload";
import { extractErrorMessages, parseApiError } from "shared/utils/api_errors";
import { useAlert } from "@web/utils/hooks";
import { FormElement } from "@web/components/forms/FormElement";
import { SelectInput } from "@web/components/inputs/SelectInput";

interface IProps {
  ledgerId: string;
}

interface IImportModal {
  show: (file: File, initialAccountId: string) => any;
  hide: () => any;
}

const ImportModal = React.forwardRef<IImportModal, IProps>((props: IProps, ref) => {
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState<boolean>(false);
  const {formatMessage, formatDate, formatCurrency} = useIntlFormatters();
  const reloadAccounts = useReloadLedgers();
  const subscription = useSelector(selectCurrentSubscription)!;
  const accounts = useSelector(selectAccountsForLedger(props.ledgerId));
  const apiKey = useSelector(selectApiKey);
  const [accountId, setAccountId] = React.useState<string | undefined>(undefined);
  const [file, setFile] = React.useState<File | null>(null);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [parsedImport, setParsedImport] = React.useState<ParsedImport | null>(null);
  const [accountIndex, setAccountIndex] = React.useState<number | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<boolean[]>([]);
  const [importing, setImporting] = React.useState<boolean>(false);
  const [successCount, setSuccessCount] = React.useState<number | null>(null);
  const [errors, setErrors] = React.useState<string[][]>([]);
  const theme = useTheme();
  const {showErrorAlert} = useAlert();

  React.useImperativeHandle<any, IImportModal>(ref, () => ({
    show: (f: File, initialAccountId: string) => {
      setParsedImport(null);
      setAccountIndex(null);
      setVisible(true);
      setSuccessCount(null);
      setImporting(false);
      setErrors([]);
      setAccountId(initialAccountId);
      setUploading(false);
      setFile(f);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  React.useEffect(() => {
    if (file && visible && accountId) {
      setSelectedRows([]);
      void uploadFile(file);
    }
  }, [visible, accountId]);

  React.useEffect(() => {
    selectLikely();
  }, [accountIndex]);

  const uploadFile = async function (f: File) {
    setAccountIndex(null);
    setUploading(true);
    let formData = new FormData();
    formData.append('file', f);
    const response = await fetch(`${NB_CONFIG.apiRoot}/ledgers/${props.ledgerId}/accounts/${accountId}/import/parse`, {
      method: "POST",
      body: formData,
      headers: {'NB-API-KEY': apiKey as string}
    });

    setUploading(false);
    if (response.status !== 200) {
      showErrorAlert(extractErrorMessages(parseApiError(await response.json())));
      setVisible(false);
    } else {
      const parsed = fromApiParsedImport(await response.json());
      setParsedImport(parsed);
      setAccountIndex(parsed.accounts.length === 1 ? 0 : null);
    }
  };

  const selectAll = function () {
    if (!parsedImport || accountIndex === null) return;
    let rows: boolean[] = [];
    parsedImport.accounts[accountIndex].transactions.forEach((txn, i) => {
      rows[i] = true;
    });
    setSelectedRows(rows);
  };

  const selectLikely = function () {
    if (!parsedImport || accountIndex === null) return;
    let rows: boolean[] = [];
    parsedImport.accounts[accountIndex].transactions.forEach((txn, i) => {
      rows[i] = txn.matches.length === 0;
    });
    setSelectedRows(rows);
  };

  const selectNone = function () {
    setSelectedRows([]);
  };

  const setSelectedRow = function (index: number, checked: boolean) {
    let rows = [...selectedRows];
    rows[index] = checked;
    setSelectedRows(rows);
  };

  const doImport = async function () {
    if (accountIndex === null || !parsedImport) return;
    setImporting(true);
    const transactions = selectedRows
      .map((checked, i) => checked ? parsedImport.accounts[accountIndex].transactions[i] : null)
      .filter((txn) => txn !== null)
      .map((txn) => ({
        account_id: accountId,
        amount: txn?.amount,
        date: txn?.date.format('YYYY-MM-DD'),
        description: txn?.description,
        notes: txn?.notes,
        check_number: txn?.checkNumber,
        attr_hash: txn?.attrHash,
      }));

    const response = await fetch(`${NB_CONFIG.apiRoot}/ledgers/${props.ledgerId}/transactions`, {
      method: "POST",
      body: JSON.stringify({transactions}),
      headers: {
        'NB-API-KEY': apiKey as string,
        'Content-Type': 'application/json'
      }
    });

    const json_body = await response.json();
    if (json_body['success']) {
      setSuccessCount(transactions.length || null);
    } else {
      setErrors(json_body.errors.map((e: any) => e.errors));
    }
    setImporting(false);
  };

  const close = function () {
    setVisible(false);
    void reloadAccounts();
    navigate(`/app/ledgers/${props.ledgerId}/transactions/all`);
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      windowStyle={{width: '47rem'}}
      bodyStyle={{padding: '1rem'}}
      header={
        <h2>{formatMessage(MSG_importTitle)}</h2>
      }
      footer={
        successCount ? (
          <div className="d-flex flex-row align-items-center justify-content-end">
            <Button color="primary" onClick={close} className="me-1">
              {formatMessage(MSG_closeButton)}
            </Button>
          </div>
        ) : (
          parsedImport ? (
            accountIndex === null ? (
              <div className="d-flex flex-row align-items-center justify-content-end">
                <Button color="primary" onClick={() => setVisible(false)} className="me-1">
                  {formatMessage(MSG_cancelButton)}
                </Button>
              </div>
            ) : (
              <div className="d-flex flex-row align-items-center justify-content-space-between">
                <div>
                  <Button color="secondary" onClick={selectAll} className="me-1" disabled={importing}>
                    {formatMessage(MSG_selectAll)}
                  </Button>
                  <Button color="secondary" onClick={selectLikely} className="me-1" disabled={importing}>
                    {formatMessage(MSG_selectLikely)}
                  </Button>
                  <Button color="secondary" onClick={selectNone} disabled={importing}>
                    {formatMessage(MSG_selectNone)}
                  </Button>
                </div>

                <div>
                  {importing && <Spinner className="me-2"/>}
                  <Button color="secondary" onClick={() => setVisible(false)} className="me-1" disabled={importing}>
                    {formatMessage(MSG_cancelButton)}
                  </Button>
                  <Button color="primary" onClick={doImport} disabled={importing || !selectedRows.filter(r => r).length}>
                    {formatMessage(MSG_importX, {count: selectedRows.filter(r => r).length})}
                  </Button>
                </div>
              </div>
            )
          ) : undefined)
      }
    >

      {successCount ? (
        <div className="d-flex flex-column align-items-center p-4">
          <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.success} className="text-xlarge"/>
          <div className="mt-2">{formatMessage(MSG_importedCount, {count: successCount})}</div>
        </div>
      ) : (
        parsedImport ? (
          <React.Fragment>
            <FormElement label={formatMessage(MSG_importIntoAccountLabel)}>
              <SelectInput
                value={accountId}
                onChange={(e) => setAccountId(e.target.value)}
              >
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>{account.name}</option>
                ))}
              </SelectInput>
            </FormElement>

            {uploading ? (
              <div className="d-flex flex-column align-items-center p-4">
                <Spinner large />
              </div>
            ) : (
              accountIndex === null ? (
                <div>
                  <h3 className="text-center mb-3">
                    {formatMessage(MSG_selectImportAccount, {numAccounts: parsedImport.accounts.length})}
                    {' '}
                    {formatMessage(MSG_whichToImportQuestion)}
                  </h3>
                  <StyledTable>
                    <StyledTableBody>
                      {parsedImport.accounts.map((account, i) => (
                        <StyledTableRow key={i}
                                        className={importing ? undefined : "clickable"}
                                        onClick={importing ? undefined : () => setAccountIndex(i)}>
                          <StyledTableCell>
                            {account.accountDescription && (
                              <span className="me-2">{account.accountDescription}</span>)}
                            {account.accountDescription && account.accountNumber && (
                              <span className="me-2">&mdash;</span>)}
                            {account.accountNumber && (
                              <span className="me-2">{formatMessage(MSG_importAccountNumber, {number: account.accountNumber})}</span>)}
                            <StyledBadge>
                              {formatMessage(MSG_importAccountTransactionCount, {count: account.transactions.length})}
                            </StyledBadge>
                          </StyledTableCell>
                          <StyledTableCell className="text-right">
                            <FontAwesomeIcon icon={faChevronRight}/>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </StyledTableBody>
                  </StyledTable>
                </div>
              ) : (
                <StyledTable>
                  <StyledTableBody>
                    {parsedImport.accounts[accountIndex].transactions.map((txn, i) => (
                      <React.Fragment key={i}>
                        <StyledTableRow
                          className={importing ? undefined : "clickable"}
                          onClick={importing ? undefined : () => setSelectedRow(i, !selectedRows[i])}
                          style={{
                            borderTopWidth: i === 0 ? 0 : 1,
                            cursor: importing ? 'inherit' : 'pointer',
                            opacity: selectedRows[i] ? 1 : 0.5
                          }}>
                          <StyledTableCell style={{width: '0.1%'}}>
                            <input type="checkbox"
                                  checked={!!selectedRows[i]}
                                  disabled={importing}
                                  onChange={e => setSelectedRow(i, e.target.checked)}
                                  style={{cursor: 'pointer'}}
                                  onClick={(e: React.MouseEvent) => e.stopPropagation()}/>
                          </StyledTableCell>
                          <StyledTableCell style={{width: '0.1%'}} className="text-nowrap">
                            {formatDate(txn.date, subscription)}
                          </StyledTableCell>
                          <StyledTableCell style={{maxWidth: '23rem'}}>
                            <div style={{overflow: 'hidden'}}>
                              <div className="text-nowrap-ellipsis">
                                {txn.description}
                              </div>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell className="text-right"
                                          style={{color: txn.amount < 0 ? theme.colors.negativeTextColor : 'inherit'}}>
                            {formatCurrency(txn.amount, subscription)}
                          </StyledTableCell>
                        </StyledTableRow>
                        {errors[i] ? (
                          <StyledTableRow style={{
                            borderTopWidth: 0,
                            opacity: selectedRows[i] ? 1 : 0.5
                          }}>
                            <StyledTableCell/>
                            <StyledTableCell colSpan={3} className="text-alert-red">
                              {errors[i].map((error, j) => (
                                <div key={j} className="d-flex flex-row align-items-center justify-content-stretch">
                                  <FontAwesomeIcon icon={faExclamationTriangle}/>
                                  <div className="ms-3">{error}</div>
                                </div>
                              ))}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          txn.matches && txn.matches.length > 0 && (
                            <StyledTableRow style={{
                              borderTopWidth: 0,
                              opacity: selectedRows[i] ? 1 : 0.5
                            }}>
                              <StyledTableCell/>
                              <StyledTableCell colSpan={3} className="text-alert-red">
                                <div className="d-flex flex-row justify-content-stretch">
                                  <FontAwesomeIcon icon={faExclamationTriangle} style={{height: '1.5rem'}}/>
                                  <div className="ms-3">
                                    <div className="text-bold">
                                      {(() => {
                                        const match = txn.matches[0];
                                        if (match.attrHash === txn.attrHash) {
                                          return formatMessage(MSG_importTransactionAlreadyImported);
                                        } else if (match.checkNumber && match.checkNumber === txn.checkNumber) {
                                          return formatMessage(MSG_importTransactionCheckNumberExists);
                                        } else {
                                          return formatMessage(MSG_importTransactionSimilarTransactionExists);
                                        }
                                      })()}
                                    </div>
                                    <div>{txn.matches[0].description}</div>
                                    <div>{txn.matches[0].notes}</div>
                                    <div>
                                      {formatCurrency(txn.matches[0].amount, subscription)}
                                      <span className="ms-4"/>
                                      {formatDate(txn.matches[0].date, subscription)}
                                    </div>
                                  </div>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        )}
                      </React.Fragment>
                    ))}
                  </StyledTableBody>
                </StyledTable>
              )
            )}
          </React.Fragment>
        ) : (
          <div className="text-center">
            <div className="d-flex flex-row align-items-center justify-content-center mb-3 text-bold">
              <Spinner/>
              <div className="ms-2">{formatMessage(MSG_uploadingLabel)}</div>
            </div>
            <div>{formatMessage(MSG_fileNameLabel, {fileName: file?.name})}</div>
            <div>{formatMessage(MSG_fileSizeLabel, {fileSize: humanFileSize(file?.size || 0)})}</div>
          </div>
        )
      )}

    </Modal>
  );
});

export {ImportModal, IImportModal};
