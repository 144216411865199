import * as React from "react";
import { PropsWithChildren, RefObject } from "react";
import { StyledEnvelopeContainer } from "@web/components/styled/StyledEnvelopeContainer";
import styled from "styled-components";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_balanceLabel, MSG_moveFrom, MSG_moveTo } from "shared/strings/transactions";
import { CurrencyInput } from "@web/components/inputs/CurrencyInput";
import classNames from "classnames";
import classnames from "classnames";
import { INumberInput } from "@web/components/inputs/NumberInput";
import { selectCurrentSubscription } from "shared/state/store";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

interface IProps {
  name: string;
  color: string;
  icon?: string | null;
  amount: number;
  locked?: boolean;
  unallocatedAmount: number;
  value: number;
  onChange: (val: number) => any;
  defaultNegativeInput?: boolean;
  showDirection?: boolean;
  disabled?: boolean;
  className?: string;
  totalValue?: number;
  customFocusValue?: (props: IProps, ref: RefObject<INumberInput>) => boolean;
  tabIndex?: any;
}

const EnvelopeInput: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage, formatCurrency} = useIntlFormatters();
  const numericInputRef = React.useRef<INumberInput>(null);
  const subscription = useSelector(selectCurrentSubscription)!;

  const showDirection = props.showDirection && !!props.value;

  return (
    <Container style={{backgroundColor: props.color, textAlign: 'center'}}
               className={classNames({'has-focus-border': props.value, 'has-direction-indicator': showDirection})}
               onClick={e => {
                 if (numericInputRef.current?.inputRef?.current !== e.target) {
                   numericInputRef.current?.focus();
                   numericInputRef.current?.select();
                 }
               }}>

      {showDirection ? (
        <div className={classNames("direction", {positive: props.value > 0, negative: props.value <= 0})}>
          {props.value <= 0 ? formatMessage(MSG_moveFrom) : formatMessage(MSG_moveTo)}
        </div>
      ) : (
        <React.Fragment>
          {props.icon && <Icon>{props.icon}</Icon>}
        </React.Fragment>
      )}
      <div className={classnames("content", {'with-move-label': showDirection && !!props.value})}>
        <Name>{props.name}</Name>
        <CurrencyInput value={props.value}
                       ref={numericInputRef}
                       tabIndex={props.tabIndex}
                       className="mb-0"
                       disabled={props.disabled || props.locked}
                       onChange={(val) => {
                         props.onChange(val);
                       }}
                       totalValue={props.totalValue}
                       style={{width: '85%'}}
                       onFocus={(e) => {
                         if (!props.value) {
                           if (props.customFocusValue) {
                             let keepGoing = props.customFocusValue(props, numericInputRef);
                             if (!keepGoing) return;
                           } else {
                             if (props.defaultNegativeInput && !props.unallocatedAmount) {
                               numericInputRef.current?.forceNegativeDefault();
                               return; // do not select
                             } else {
                               numericInputRef.current?.forceValue(-1 * props.unallocatedAmount / 100);
                             }
                           }
                         }
                         setTimeout(() => {
                           numericInputRef.current?.select({ignoreFocus: true});
                         });
                       }}/>
        <Balance className={props.amount < 0 ? 'negative' : 'positive'}>
          <span className="label">{formatMessage(MSG_balanceLabel)}:</span>{' '}
          <span className="amount">{formatCurrency(props.amount, subscription)}</span>
        </Balance>

        {props.locked && (
          <div className="locked-overlay">
            <FontAwesomeIcon icon={faLock}/>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled(StyledEnvelopeContainer)`
  position: relative;

  .content {
    position: absolute;
    top: 0;
    left: 0.75rem;
    right: 0.75rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.with-move-label {
      left: 1.5rem !important;
    }
  }

  .locked-overlay {
    position: absolute;
    top: -1rem;
    left: -1rem;
    bottom: -1rem;
    right: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.65);
    color: ${({theme}) => theme.colors.warning};

    svg {
      font-size: 200%;
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
    }
  }

  .direction {
    writing-mode: vertical-rl;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-bottom-right-radius: ${({theme}) => theme.raisedContainer.innerBorderRadius };
    border-top-right-radius: ${({theme}) => theme.raisedContainer.innerBorderRadius };
    transform: rotate(180deg);
    color: #fff;
    font-weight: bold;
    font-size: 0.7rem;
    line-height: 1.25rem;
    border-left: 1px solid #0003;
    border-bottom: 1px solid #fff3;
    text-transform: uppercase;

    &.positive {
      background-color: ${({theme}) => theme.colors.incomeColor };
    }
    &.negative {
      background-color: ${({theme}) => theme.colors.expenseColor };
    }
  }

  &.has-direction-indicator {
    .content {
      position: absolute;
      top: 0;
      left: 1rem;
      right: 0;
      bottom: 0;
    }
  }

  input {
    border-color: rgba(0,0,0,0.5);
    padding: 0.2rem 0.4rem;

    &:focus {
      box-shadow: none;
      border-color: rgba(0,0,0,0.5);
      outline: 1px dotted #000;
      outline-offset: -0.25rem;
    }
  }
`;

const Name = styled.div`
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.9rem;
`;

const Balance = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.9rem;

  .amount {
    font-weight: bold;
  }

  &.negative {
    .amount {
      color: ${({theme}) => theme.colors.negativeEnvelopeTextColor};
    }
  }
`;

const Icon = styled.div`
  font-size: 1.5rem;
  position: absolute;
  line-height: 1;
  left: 0.25rem;
  bottom: 0.25rem;
`;

export {EnvelopeInput};
