export const MSG_getStartedTitle = {
  id: "ad163b63-eb69-43c5-8108-2a7865dcacd1",
  description: "The title of our guide that will help users get started with the product.",
  defaultMessage: "Get Started"
};

export const MSG_welcomeToNeoBudgetTitle = {
  id: "09395956-8f49-498e-8883-07e901699539",
  description: "A title for our getting started guide that welcomes them to the product.",
  defaultMessage: "Welcome to NeoBudget!"
};

export const MSG_nowWhatQuestion = {
  id: "e86f4e46-117d-4bae-9e5b-688788b2390a",
  description: "A label for a section that explains what they should do next.  This is intended to be phrased like a question that the user would ask.",
  defaultMessage: "Now what?!"
};


export const MSG_closeThisGuideButton = {
  id: "ecec30bd-ee01-44c8-a4a9-51cdee1a2b26",
  description: "The text for a button that will close the getting started guide.",
  defaultMessage: "Close This Guide"
};

export const MSG_checkOutMobileApp = {
  id: "1dec8601-2af2-49d7-b970-249c6e29f325",
  description: "A message that encourages users to check out the mobile app.",
  defaultMessage: "Be sure to check out or mobile app on your phone!"
};

export const MSG_searchForMobileApp = {
  id: "8498bf83-0039-4d91-b07a-c8b2242ae70a",
  description: "A message that encourages users to search for the mobile app in the app store on their phone or tablet.",
  defaultMessage: "Or just search for \"NeoBudget\" in the app store on your device."
};
