import { useLazyBootstrapQuery } from "../state/endpoints/app/bootstrap_api";
import { useDispatch } from "react-redux";
import { useLazyGetReconciledTotalQuery } from "../state/endpoints/app/accounts_api";
import { useLazyGetEnvelopeQuery, useLazyGetEnvelopesForLedgerQuery } from "../state/endpoints/app/envelopes_api";
import { useLazyGetIncomeSourcesForLedgerQuery } from "../state/endpoints/app/income_sources_api";
import {
  setBootstrapping,
  setCurrentSubscription,
  setCurrentUser,
  setEnvelope,
  setEnvelopesForLedger,
  setIncomeSourcesForLedger,
  setLedger,
  setLedgers,
  setProducts,
  setReconciledTotalForLedger,
  setUsers
} from "../state/store";
import { useLazyGetLedgerQuery, useLazyGetLedgersQuery } from "../state/endpoints/app/ledgers_api";
import { useLogout } from "shared/utils/hooks";
import { getCachedApiKey } from "../utils/local";

export function useReloadBootstrapData() {
  const [bootstrapQuery]: any = useLazyBootstrapQuery();
  const dispatch = useDispatch();
  const logout = useLogout();

  return async () => {
    const apiKey = await getCachedApiKey();

    if (apiKey) {
      dispatch(setBootstrapping(true));
      const {data} = await bootstrapQuery(undefined);
      if (!data) {
        await logout();
        return;
      }
      if (data?.currentUser) dispatch(setCurrentUser(data.currentUser))
      if (data?.users) dispatch(setUsers(data.users))
      if (data?.subscription) dispatch(setCurrentSubscription(data.subscription))
      if (data?.products) dispatch(setProducts(data.products));
      if (data?.ledgers) dispatch(setLedgers(data.ledgers));
      dispatch(setBootstrapping(false));
    }
  };
}

export function useReloadLedger() {
  const [getLedger] = useLazyGetLedgerQuery();
  const dispatch = useDispatch();

  return async (ledgerId: string) => {
    try {
      const ledger = await getLedger(ledgerId).unwrap();
      dispatch(setLedger(ledger));
    } catch (e) {
      // noop
    }
  };
}

export function useReloadLedgers() {
  const [getLedgers] = useLazyGetLedgersQuery();
  const dispatch = useDispatch();

  return async () => {
    try {
      const ledgers = await getLedgers().unwrap();
      dispatch(setLedgers(ledgers));
    } catch (e) {
      // noop
    }
  };
}

export function useReloadReconciledTotal(ledgerId: string) {
  const [getReconciledTotal] = useLazyGetReconciledTotalQuery();
  const dispatch = useDispatch();

  return async (accountId: string) => {
    try {
      const total = await getReconciledTotal({ledgerId, accountId}).unwrap();
      dispatch(setReconciledTotalForLedger({ledgerId, accountId, total: total}));
    } catch (e) {
      // noop
    }
  };
}

export function useReloadEnvelopesForLedger(ledgerId: string) {
  const [getEnvelopesForLedger] = useLazyGetEnvelopesForLedgerQuery();
  const dispatch = useDispatch();

  return async () => {
    try {
      const {data} = await getEnvelopesForLedger({ledgerId});
      if (data?.envelopes) {
        setEnvelopesForLedger({ledgerId, envelopes: data.envelopes});
      }
    } catch (e) {
      // noop
    }
  };
}

export function useReloadEnvelope(ledgerId: string, envelopeId: string) {
  const [getEnvelope] = useLazyGetEnvelopeQuery();
  const dispatch = useDispatch();

  return async () => {
    try {
      dispatch(setEnvelope(await getEnvelope({ledgerId, envelopeId}).unwrap()));
    } catch (e) {
      // noop
    }
  };
}

export function useReloadIncomeSourcesForLedger(ledgerId: string) {
  const [getIncomeSourcesForLedger] = useLazyGetIncomeSourcesForLedgerQuery();
  const dispatch = useDispatch();

  return async () => {
    try {
      const {data} = await getIncomeSourcesForLedger({ledgerId});
      if (data?.incomeSources) {
        setIncomeSourcesForLedger({ledgerId, incomeSources: data.incomeSources});
      }
    } catch (e) {
      // noop
    }
  };
}

