import * as React from "react";
import { Modal } from "@web/components/Modal";
import { Account, ApiError, BLANK_ACCOUNT } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton } from "shared/strings/generic";
import { useCreateAccountMutation, useUpdateAccountMutation } from "shared/state/endpoints/app/accounts_api";
import { parseApiError } from "shared/utils/api_errors";
import { MSG_accountNameLabel, MSG_deleteAccountButton } from "shared/strings/accounts";
import { FormElement } from "@web/components/forms/FormElement";
import { TextInput } from "@web/components/inputs/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { useNavigate } from "react-router-dom";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import { useDispatch } from "react-redux";
import { useReloadBootstrapData } from "shared/hooks/use_reload";
import {
  DeleteAccountConfirmationPopover
} from "@web/popovers/delete_account_popover/DeleteAccountConfirmationPopover";
import { IPopover } from "@web/components/Popover";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

interface IProps {
}

interface IAccountEditorModal  {
  show: (account: Account) => any;
  hide: () => any;
}

const AccountEditorModal = React.forwardRef<IAccountEditorModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<Account>(BLANK_ACCOUNT);
  const [accountId, setAccountId] = React.useState<string | undefined>(undefined);
  const [ledgerId, setLedgerId] = React.useState<string | undefined>(undefined);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [updateAccount] = useUpdateAccountMutation();
  const [createAccount] = useCreateAccountMutation();
  const {formatMessage} = useIntlFormatters();
  const reloadBootstrap = useReloadBootstrapData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteButtonPopoverRef = React.useRef<IPopover>(null);
  const deleteButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useImperativeHandle<any, IAccountEditorModal>(ref, () => ({
    show: (localAccount: Account) => {
      setValue(localAccount);
      setLedgerId(localAccount.ledgerId);
      setAccountId(localAccount.id);
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function save() {
    try {
      setSaving(true);
      if (value.id) {
        await updateAccount({account: {id: value.id, ledgerId: value.ledgerId, name: value.name}}).unwrap();
      } else {
        await createAccount({account: {name: value.name, ledgerId: value.ledgerId}}).unwrap();
      }
      await reloadBootstrap();
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  if (!value) return null;

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      bodyStyle={{padding: '1rem'}}
      defaultFormAction={save}
      footer={
        <div className="flex-row">
          {ledgerId && accountId && (
            <Button onClick={() => { deleteButtonPopoverRef.current?.show(); }} color="danger" link
                    className="me-2" ref={deleteButtonRef}>
              <FontAwesomeIcon icon={faTrashAlt}/>{' '}
              {formatMessage(MSG_deleteAccountButton)}
            </Button>
          )}

          <div className="flex-grow-1"/>

          <Button color="secondary" disabled={saving}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
        </div>
      }>

      {errors?.errorType === 'message' && (
        <div className="d-flex flex-row">
          <StyledErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            <div>{errors.message}</div>
          </StyledErrorMessage>
        </div>
      )}

      <FormElement errors={errors} errorPath="account.name" className="flex-grow-1"
                   label={formatMessage(MSG_accountNameLabel)}>
        <TextInput
          name="name"
          autoFocus
          style={{width: '100%'}}
          value={value.name}
          onChange={e => setValue({...value, name: e.target.value})}/>
      </FormElement>

      {ledgerId && accountId && (
        <DeleteAccountConfirmationPopover
          ref={deleteButtonPopoverRef}
          targetRef={deleteButtonRef}
          ledgerId={ledgerId!}
          accountId={accountId!}
          afterDelete={async () => {
            setVisible(false);
          }}
        />
      )}

    </Modal>
  );
});


export {AccountEditorModal, IAccountEditorModal};
