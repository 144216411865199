export const GREEN_COLOR = '#5c9a2b';
export const TEXT_COLOR = '#303030';
export const SUCCESS_COLOR = GREEN_COLOR;
export const INFO_COLOR = '#5BB7D5';
export const WARNING_COLOR = '#E6AF00';
export const DANGER_COLOR = '#cb4330';
export const SECONDARY_COLOR = '#dfdfdf';
export const DARK_SECONDARY_COLOR = '#bfbfbf';
export const MUTED_TEXT_COLOR = '#999';
export const BACKGROUND_TEXT_COLOR = '#707070';
export const BACKGROUND_COLOR = '#222';
export const LIGHT_BORDER_COLOR = '#dbdbdb';
export const ERROR_BACKGROUND_COLOR = '#fbf0ef';
export const ERROR_TEXT_COLOR = '#be3f2d';
export const LIGHT_BACKGROUND_COLOR = '#efefef';
export const EXPENSE_COLOR = '#cb4330';
export const INCOME_COLOR = GREEN_COLOR;
export const MOVE_COLOR = '#E6AF00';
export const TRANSFER_COLOR = '#E6AF00';
export const NEGATIVE_TEXT_COLOR = '#cc0000';
export const NEGATIVE_ENVELOPE_TEXT_COLOR = '#ff0000';
export const FOCUSED_TAB_BACKGROUND_COLOR = GREEN_COLOR;
export const FOCUSED_TAB_TEXT_COLOR = '#fff';
export const UNFOCUSED_TAB_BACKGROUND_COLOR = '#395523';
export const UNFOCUSED_TAB_TEXT_COLOR = '#799662';

export const GlobalStyles = {
  borderRadius: 6,
  borderWidth: 6,
  normalFontSize: 15,
  standardSpacer: 15,
  fontFamily: 'Open-Sans-Regular',
  fontFamilyBold: 'Open-Sans-Bold',
};
