import * as React from "react";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { useIntlFormatters } from "shared/utils/formatters";
import { useAuthenticateMutation } from "shared/state/endpoints/app/users_api";
import { ApiError } from "shared/utils/api_types";
import { ITextInputRef, TextInput } from "@web/components/inputs/TextInput";
import { useLogin } from "shared/utils/hooks";
import { parseApiError } from "shared/utils/api_errors";
import {
  MSG_createAnAccountLink,
  MSG_emailFormLabel,
  MSG_forgotYourPasswordLink,
  MSG_loginButton,
  MSG_loginTitle,
  MSG_notRegisteredQuestion,
  MSG_passwordFormLabel
} from "shared/strings/login";
import { FormErrors } from "@web/components/forms/FormErrors";
import { FormElement } from "@web/components/forms/FormElement";
import { Button } from "@web/components/Button";
import { faUserUnlock } from "@fortawesome/pro-light-svg-icons/faUserUnlock";
import { faLock } from "@fortawesome/pro-light-svg-icons/faLock";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Form } from "@web/components/forms/Form";

interface IProps {}

const LoginPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const [authenticate]: any = useAuthenticateMutation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [apiError, setApiError] = React.useState<ApiError | null>(null);
  const emailRef = React.useRef<ITextInputRef>(null);
  const navigate = useNavigate();
  const login = useLogin();

  const submit = async () => {
    setLoading(true);
    try {
      const data = await authenticate({email, password}).unwrap();
      if (data.user?.apiKey) {
        await login(data.user.apiKey);
        navigate('/app');
      } else {
        setTimeout(() => {
          emailRef.current?.select();
          emailRef.current?.focus();
        }, 10);
      }
    } catch (e) {
      setApiError(parseApiError(e));
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="text-center p-4">
        <FontAwesomeIconStyled icon={faUserUnlock}/>
        <HeadingStyled>{formatMessage(MSG_loginTitle)}</HeadingStyled>
      </div>
      <Form onSubmit={submit}>
        {apiError && <FormErrors errors={apiError} className="mb-4"/>}

        <FormElement label={formatMessage(MSG_emailFormLabel)}>
          <TextInput
            ref={emailRef}
            autoFocus
            tabIndex={1}
            disabled={loading}
            value={email}
            onChange={e => setEmail(e.target.value)}
            leftElement={<FontAwesomeIcon icon={faEnvelope}/>}/>
        </FormElement>

        <FormElement label={formatMessage(MSG_passwordFormLabel)}>
          <TextInput
            tabIndex={2}
            type="password"
            disabled={loading}
            value={password}
            onChange={e => setPassword(e.target.value)}
            leftElement={<FontAwesomeIcon icon={faLock}/>}/>
        </FormElement>

        <div className="flex-row align-items-center justify-content-stretch">
          <div className="flex-grow-1">
            <div className="text-nowrap">
              <Link to="/app/password_reset" tabIndex={10}>
                {formatMessage(MSG_forgotYourPasswordLink)}
              </Link>
            </div>
            <div className="text-nowrap">
              {formatMessage(MSG_notRegisteredQuestion)}{' '}
              <Link to="/app/signup" tabIndex={11}>
                {formatMessage(MSG_createAnAccountLink)}
              </Link>
            </div>
          </div>
          <Button tabIndex={3} spinner={loading} disabled={loading}>
            {formatMessage(MSG_loginButton)}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const HeadingStyled = styled.h1`
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export {LoginPage};
