import * as React from "react";
import { RefObject } from "react";
import {
  StyledRightFadeTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledTableRow
} from "../styled/StyledTable";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_accountLabel,
  MSG_amountLabel,
  MSG_checkNumberBadge,
  MSG_dateLabel,
  MSG_descriptionLabel,
  MSG_movedAmount,
  MSG_noDescription,
  MSG_noTransactionsFound,
  MSG_noTransactionsFoundInEnvelope,
  MSG_noTransactionsFoundMatchingQuery
} from "shared/strings/transactions";
import { TransactionKindBadge } from "./TransactionKindBadge";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { useTheme } from "styled-components";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from "../Spinner";
import { StyledBadge } from "../styled/StyledBadge";
import { MSG_notReconciledLabel, MSG_reconciledLabel } from "shared/strings/reconcile";
import { MSG_unallocatedLabel } from "shared/strings/accounts";
import { TRANSACTIONS_PER, useTransactionPager } from "@web/utils/hooks";
import { ITransactionEditorModal } from "../../modals/transaction_editor_modal/TransactionEditorModal";
import { sortBy, uniq } from 'lodash';
import { ISelection } from "shared/utils/interfaces";
import { useSelector } from "react-redux";
import { useReloadLedger } from "shared/hooks/use_reload";
import { Tooltip } from "react-tooltip";
import {
  selectAccount,
  selectCurrentSubscription,
  selectTransactionsList,
  selectTransactionsListLoading,
  selectTransactionsListPage,
  selectTransactionsListTotalCount,
  TransactionListSortDirection,
  TransactionListSortType,
  TransactionsListType
} from "shared/state/store";
import { faCaretDown, faCaretUp, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { TransactionModalContext } from "shared/utils/transaction_modal_context";

interface IProps {
  ledgerId: string;
  accountId?: string;
  envelopeId?: string;
  queryStr?: string;
  onlyUnreconciled?: boolean;
  transactionEditorModalRef: RefObject<ITransactionEditorModal>;
  onSelectionChanged?: (selection: ISelection) => void;
  disabled?: boolean;
  emptyMessage?: string;
  emptyMessageForQuery?: string;
  emptyMessageInEnvelope?: string;
  setTransactionCount?: (count: number) => void;
}

interface ITransactionList {
  selectAll: () => any;
  selectNone: () => any;
  getSelection: () => ISelection;
  fetchToPage: (page: number) => Promise<any>;
  resetLocalSelection: () => any;
}

const TransactionList = React.forwardRef<ITransactionList, IProps>((props: IProps, ref) => {
  let listType: TransactionsListType = 'main';
  if (props.onlyUnreconciled) listType = 'unreconciled';

  const subscription = useSelector(selectCurrentSubscription)!;
  const transactionModalContext = React.useContext(TransactionModalContext);
  const theme = useTheme();
  const {formatMessage, formatDate, formatCurrency} = useIntlFormatters();
  const reloadAccount = useReloadLedger();
  const [selection, setSelection] = React.useState<ISelection>({base: 'none', ids: []});
  const account = useSelector(selectAccount(props.accountId));
  const firstRenderRef = React.useRef<boolean>(true);
  const transactions = useSelector(selectTransactionsList(listType));
  const page = useSelector(selectTransactionsListPage(listType));
  const totalCount = useSelector(selectTransactionsListTotalCount(listType));
  const loading = useSelector(selectTransactionsListLoading(listType));
  const [sort, setSort] = React.useState<TransactionListSortType>('date');
  const [dir, setDir] = React.useState<TransactionListSortDirection>('desc');
  const {fetchToPage, fetchNextPage} = useTransactionPager(listType, {
    ledgerId: props.ledgerId, accountId: props.accountId, q: props.queryStr, eid: props.envelopeId, onlyUnreconciled: props.onlyUnreconciled, sort, dir
  });

  React.useImperativeHandle<any, ITransactionList>(ref, () => ({
    selectAll: () => {
      let newSelection: ISelection = {base: 'all', ids: []};
      setSelection(newSelection);
      if (props.onSelectionChanged) props.onSelectionChanged(newSelection);
    },
    selectNone: () => {
      let newSelection: ISelection = {base: 'none', ids: []};
      setSelection(newSelection);
      if (props.onSelectionChanged) props.onSelectionChanged(newSelection);
    },
    getSelection: () => {
      return selection;
    },
    resetLocalSelection: () => {
      setSelection({base: 'none', ids: []});
    },
    fetchToPage: fetchToPage
  }));

  React.useEffect(() => {
    async function refresh() {
      await fetchToPage(page);
      void reloadAccount(props.ledgerId);
    }

    transactionModalContext.registerRefreshParent(refresh);
    return () => transactionModalContext.unregisterRefreshParent(refresh);
  }, [page, props.queryStr, props.envelopeId, props.accountId]);

  React.useEffect(() => {
    void fetchToPage(1);
  }, [props.queryStr, props.envelopeId, props.accountId, sort, dir]);

  React.useEffect(() => {
    if (props.setTransactionCount && !isNaN(parseInt(`${totalCount}`))) props.setTransactionCount(totalCount || 0);
  }, [totalCount]);

  React.useEffect(() => {
    // if account.pendingReconcileIds is not the same as the selection, update the selection
    if (account?.pendingReconcileIds && JSON.stringify(sortBy(account.pendingReconcileIds)) !== JSON.stringify(sortBy(selection.ids))) {
      console.log('reconcileIds', account?.pendingReconcileIds);
      setSelection({base: 'none', ids: account.pendingReconcileIds});
    }
  }, [JSON.stringify(account?.pendingReconcileIds || [])]);

  const toggleSort = React.useCallback((newSort: TransactionListSortType) => {
    if (newSort === sort) {
      setDir(dir === 'asc' ? 'desc' : 'asc');
    } else {
      setSort(newSort);
      setDir('desc');
    }
  }, [sort, dir]);

  // #####################################################

  const allowSelection = !!props.onSelectionChanged;

  return (
    <React.Fragment>
      <InfiniteScroll
        dataLength={transactions.length}
        next={fetchNextPage}
        hasMore={page * TRANSACTIONS_PER < (totalCount || 0)}
        loader={<Spinner padded centered/>}>
        <StyledTable style={{tableLayout: 'fixed'}}>
          <StyledTableHead>
            <StyledTableHeadRow>
              {allowSelection && (
                <StyledTableHeadCell style={{width: '4%'}}>
                  <div/>
                </StyledTableHeadCell>)}
              <StyledTableHeadCell style={{width: '13%'}}>
                <div className="flex-row align-items-center clickable" onClick={() => toggleSort('date')}>
                  <span className="clickable-title">{formatMessage(MSG_dateLabel)}</span>
                  {sort === 'date' && <FontAwesomeIcon icon={dir === 'desc' ? faCaretDown : faCaretUp} className="ms-1"/>}
                </div>
              </StyledTableHeadCell>
              <StyledTableHeadCell>
                <div className="flex-row align-items-center clickable" onClick={() => toggleSort('description')}>
                  <span className="clickable-title">{formatMessage(MSG_descriptionLabel)}</span>
                  {sort === 'description' && <FontAwesomeIcon icon={dir === 'desc' ? faCaretDown : faCaretUp} className="ms-1"/>}
                </div>
              </StyledTableHeadCell>
              <StyledTableHeadCell style={{width: '13%'}}>
                <div className="flex-row align-items-center justify-content-end clickable" onClick={() => toggleSort('amount')}>
                  <span className="clickable-title">{formatMessage(MSG_amountLabel)}</span>
                  {sort === 'amount' && <FontAwesomeIcon icon={dir === 'desc' ? faCaretDown : faCaretUp} className="ms-1"/>}
                </div>
              </StyledTableHeadCell>
              <StyledTableHeadCell style={{width: '13%'}}>
                {formatMessage(MSG_accountLabel)}
              </StyledTableHeadCell>
              <StyledTableHeadCell style={{width: '9%'}} className="text-center">
              </StyledTableHeadCell>
              <StyledTableHeadCell style={{width: '4%'}}>
                <div/>
              </StyledTableHeadCell>
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {transactions?.map(t => {
              let allocAmount = t.allocations?.find(a => a.envelopeId === props.envelopeId)?.amount || 0;
              let movedAmount = t.allocations?.reduce((m, a) => { return m + (a.amount < 0 ? Math.abs(a.amount) : 0); }, 0);
              return (
                <StyledTableRow key={t.id} onClick={() => {
                  if (props.disabled) return;
                  return props.transactionEditorModalRef.current?.show(t);
                }} className="clickable">
                  {allowSelection && (
                    <StyledTableCell onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                    }}>
                      <input type="checkbox"
                             disabled={props.disabled}
                             style={{cursor: 'pointer'}}
                             checked={(
                               selection.base === 'all' && !selection.ids.includes(t.id as string)
                             ) || (
                               selection.base === 'none' && selection.ids.includes(t.id as string)
                             )}
                             onChange={(e) => {
                               e.stopPropagation();
                               const tid = t.id as string;
                               let newSelection: ISelection;
                               if (selection.base === 'none') {
                                 if (e.target.checked) {
                                   newSelection = {base: 'none', ids: uniq(selection.ids.concat([tid]))};
                                 } else {
                                   newSelection = {base: 'none', ids: selection.ids.filter(id => id !== tid)};
                                 }
                               } else {
                                 if (e.target.checked) {
                                   newSelection = {base: 'all', ids: selection.ids.filter(id => id !== tid)};
                                 } else {
                                   newSelection = {base: 'all', ids: uniq(selection.ids.concat([tid]))};
                                 }
                               }
                               setSelection(newSelection);
                               if (props.onSelectionChanged) props.onSelectionChanged(newSelection);
                             }}/>
                    </StyledTableCell>
                  )}
                  <StyledTableCell className="text-nowrap">
                    {formatDate(t.date, subscription)}
                  </StyledTableCell>
                  <StyledRightFadeTableCell style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'hidden'}}
                                            className="flex-row align-items-center">
                    {!t.allocated && (
                      <span className="me-2">
                        <FontAwesomeIcon icon={faExclamationTriangle} color={theme.colors.warning}
                                         data-tip={formatMessage(MSG_unallocatedLabel)}/>
                      </span>)}
                    {t.checkNumber &&
                      <StyledBadge className="me-2">{formatMessage(MSG_checkNumberBadge, {number: t.checkNumber})}</StyledBadge>}
                    {t.description || <span className="text-muted text-uppercase text-small">{formatMessage(MSG_noDescription)}</span>}
                    {t.notes && <span className="ms-4 text-muted">{t.notes}</span>}
                  </StyledRightFadeTableCell>
                  <StyledTableCell className="text-right"
                                   style={{
                                     color: t.amount < 0 ? theme.colors.negativeTextColor : 'inherit'
                                   }}>
                    {t.kind === 'move' && !props.envelopeId
                      ? <StyledBadge>{formatMessage(MSG_movedAmount, {amount: formatCurrency(movedAmount || 0, subscription)})}</StyledBadge>
                      : <span style={{color: (props.envelopeId ? allocAmount : t.amount) < 0 ? theme.colors.negativeTextColor : 'inherit'}}>{formatCurrency(props.envelopeId ? allocAmount : t.amount, subscription)}</span>}
                  </StyledTableCell>
                  <StyledTableCell className="text-nowrap">
                    <div className="text-nowrap-ellipsis" style={{maxWidth: 100}}>
                      {t.account?.name}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className="text-nowrap text-center">
                    <TransactionKindBadge kind={t.kind}/>
                  </StyledTableCell>
                  <StyledTableCell>
                    {t.reconciled
                      ? <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.success}
                                         data-tooltip-content={formatMessage(MSG_reconciledLabel)}
                                         data-tooltip-id={`reconciled-${t.id}`}/>
                      : <FontAwesomeIcon icon={faExclamationCircle} color={theme.colors.secondary}
                                         data-tooltip-content={formatMessage(MSG_notReconciledLabel)}
                                         data-tooltip-id={`reconciled-${t.id}`}/>}
                    <Tooltip place="left"
                             delayShow={100}
                             className="tooltip-wrapper"
                             id={`reconciled-${t.id}`}/>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
      </InfiniteScroll>

      {!transactions.length && (
        loading ? (
          <Spinner padded centered large/>
        ) : (
          <EmptyListMessage>
            {props.queryStr
              ? (props.emptyMessageForQuery || formatMessage(MSG_noTransactionsFoundMatchingQuery))
              : (props.envelopeId
                ? (props.emptyMessageInEnvelope || formatMessage(MSG_noTransactionsFoundInEnvelope))
                : (props.emptyMessage || formatMessage(MSG_noTransactionsFound)))}
          </EmptyListMessage>
        )
      )}
    </React.Fragment>
  );
});

const EmptyListMessage = styled.div`
  text-align: center;
  opacity: 0.5;
  padding: 1rem;
`;

export {TransactionList, ITransactionList};
