import * as React from "react";
import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { selectAccountsByLedger, selectLedgers } from "shared/state/store";
import { StyledBadge } from "@web/components/styled/StyledBadge";
import { MSG_changeButton } from "shared/strings/generic";
import { IPopover, Popover } from "@web/components/Popover";
import { useIntlFormatters } from "shared/utils/formatters";
import styled from "styled-components";

interface IProps {
  ledgerId: string;
  accountId: string;
  fromLedgerId: string;
  fromAccountId: string;
  onChange: (ledgerId: string, accountId: string) => any;
}

const ChangeAccountButton: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgers = useSelector(selectLedgers);
  const accountsByLedger = useSelector(selectAccountsByLedger);
  const changeToAccountRef = React.useRef<HTMLDivElement>(null);
  const changeToAccountPopoverRef = React.useRef<IPopover>(null);
  const {formatMessage, formatCurrency, formatDate, formatOrdinal} = useIntlFormatters();

  const list: {ledgerId: string, accountId: string, label: string}[] = [];
  ledgers.forEach(ledger => {
    Object.values(accountsByLedger[ledger.id]).forEach(account => {
      list.push({
        ledgerId: ledger.id,
        accountId: account.id,
        label: `${ledger.name}: ${account.name}`,
      });
    });
  });

  if (list.length < 2) {
    return null;
  }

  return (
    <React.Fragment>
      <StyledBadge className="ms-2"
                   ref={changeToAccountRef}
                   onClick={() => changeToAccountPopoverRef.current?.show()}>
        {formatMessage(MSG_changeButton)}
      </StyledBadge>

      <Popover targetRef={changeToAccountRef}
               ref={changeToAccountPopoverRef}>
        <AccountList>
          {list
            .filter(l => l.accountId !== props.fromAccountId)
            .map(l => {
              return (
                <AccountOption key={l.accountId} onClick={() => {
                  props.onChange(l.ledgerId, l.accountId);
                  changeToAccountPopoverRef.current?.hide();
                }}>
                  {l.label}
                </AccountOption>
              );
            })}
        </AccountList>
      </Popover>
    </React.Fragment>
  );
};

const AccountList = styled.div`
  padding: 1rem;
`;

const AccountOption = styled.div`
  min-width: 10rem;
  padding: 1rem 1.5rem;
  margin-bottom: 2px;
  background-color: ${({theme}) => theme.colors.lightBackgroundColor };
  border-radius: ${({theme}) => theme.raisedContainer.borderRadius };
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
export {ChangeAccountButton};
