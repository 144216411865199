import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectReconcileBankBalances = (state: RootState) => state.global.reconcileBankBalanceByAccount;

export const selectReconcileBankBalanceByAccount = (accountId: string) => createSelector(
  (state: RootState) => state.global.reconcileBankBalanceByAccount,
  (hash) => {
    return hash[accountId];
  }
);
