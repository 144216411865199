import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyledEnvelopeContainer } from "../styled/StyledEnvelopeContainer";
import { useDrag } from "react-dnd";
import { Envelope } from "shared/utils/api_types";
import { MSG_archivedLabel } from "shared/strings/envelopes";
import { useIntlFormatters } from "shared/utils/formatters";

interface IProps {
  envelope: Envelope;
  className?: string;
}

const EnvelopeArchived: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();

  const envelope = props.envelope;
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'envelope',
      item: envelope,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.25 : 1
      })
    }),
    []
  );

  return (
    <StyledEnvelopeContainer style={{backgroundColor: envelope.color, opacity}}
                             ref={dragRef}>
      <Name>{envelope.name}</Name>
      <Label>{formatMessage(MSG_archivedLabel)}</Label>
    </StyledEnvelopeContainer>
  );
};

const Name = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

export {EnvelopeArchived};
