import * as React from "react";
import { PropsWithChildren } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useGetHelpArticleQuery, useGetHelpGroupQuery } from "shared/state/endpoints/app/help_api";
import { useIntlFormatters } from "shared/utils/formatters";
import { Spinner } from "@web/components/Spinner";
import { ArticleTitle } from "./helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { MSG_helpHome } from "shared/strings/help";

interface IProps {}
const HelpCenterArticlePage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {const params = useParams<{groupId: string, articleId: string}>();
  const {data: article, isFetching} = useGetHelpArticleQuery(params.articleId as string);
  const {data: group, isFetching: isFetchingGroup} = useGetHelpGroupQuery(params.groupId as string);
  const {formatMessage} = useIntlFormatters();

  return (
    <React.Fragment>
      {isFetching || isFetchingGroup ? (
        <div className="text-center">
          <Spinner large/>
        </div>
      ) : (
        <React.Fragment>
          <div className="flex-row align-items-center mb-4">
            <NavLink to="/app/help_center/articles">{formatMessage(MSG_helpHome)}</NavLink>

            <FontAwesomeIcon icon={faChevronRight} className="ms-4 me-4"/>

            <NavLink to={`/app/help_center/articles/${group?.id}/${group?.slug}`}
                     className="clickable-title">{group?.name}</NavLink>

          </div>

          <ArticleTitle>{article?.title}</ArticleTitle>

          <div
            className="trix-content"
            dangerouslySetInnerHTML={{__html: article?.body as string}}/>

        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export { HelpCenterArticlePage };
