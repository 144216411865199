import * as React from "react";
import { Modal } from "@web/components/Modal";
import { ApiError, BILLING_INTERVAL, FEATURE_LEVEL, Product, Subscription } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_privacyPolicy, MSG_termsOfService } from "shared/strings/generic";
import { parseApiError } from "shared/utils/api_errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { StyledErrorMessage } from "@web/components/styled/StyledErrorMessage";
import {
  selectCurrentProduct,
  selectCurrentSubscription,
  selectProducts,
  setCurrentSubscription,
} from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { BillingTier } from "@web/pages/app/authenticated/settings/billing/BillingTier";
import styled, { useTheme } from "styled-components";
import {
  MSG_addPaymentMethodNotice,
  MSG_billingIntervalAnnualOption,
  MSG_billingIntervalForever,
  MSG_billingIntervalMonthOption,
  MSG_billingLifetimePlan,
  MSG_billingLifetimePlanExplanation,
  MSG_billingNever,
  MSG_billingPolicyLine1,
  MSG_billingPolicyLine2,
  MSG_billingPolicyLine3,
  MSG_billingPolicyTitle,
  MSG_billingPreviewDowngradeAtPeriodEnd,
  MSG_freeTierLabel,
  MSG_reviewChangeButton,
  MSG_saveAmount
} from "shared/strings/billing";
import { HorizontalOptionsInput } from "@web/components/inputs/HorizontalOptionsInput";
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { getIntervalAdjectiveLabel, getPlanName, isLifetimePlan, previewSummary } from "shared/utils/helpers";
import {
  useCancelPlanMutation,
  useChangePlanMutation,
  useLazyPreviewPlanChangeQuery
} from "shared/state/endpoints/app/subscriptions_api";
import { ConfirmationModal, IConfirmationModal } from "../confirmation_modal/ConfirmationModal";
import { IPaymentMethodModal, PaymentMethodModal } from "../payment_method_modal/PaymentMethodModal";
import { PaymentMethodCardBody } from "@web/pages/app/authenticated/settings/billing/PaymentMethodCardBody";
import { StyledCard } from "@web/components/styled/StyledCard";
import arrowImg from "shared/images/drawn-arrow-red.png";
import { useAlert } from "@web/utils/hooks";

interface IProps {
}

interface IChangePlanModal  {
  show: () => any;
  hide: () => any;
}

const ChangePlanModal = React.forwardRef<IChangePlanModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiError | undefined>(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const currentProduct = useSelector(selectCurrentProduct);
  const [selectedKey, setSelectedKey] = React.useState<string | undefined | 'free'>(undefined);
  const [interval, setInterval] = React.useState<BILLING_INTERVAL>(BILLING_INTERVAL.MONTH);
  const {formatMessage, formatCurrency, formatDate} = useIntlFormatters();
  const allProducts = useSelector(selectProducts);
  const currentSubscription = useSelector(selectCurrentSubscription);
  const [previewPlanChange] = useLazyPreviewPlanChangeQuery();
  const [changePlanMutation] = useChangePlanMutation();
  const [cancelPlanMutation] = useCancelPlanMutation();
  const confirmationModalRef = React.useRef<IConfirmationModal>(null);
  const paymentMethodModalRef = React.useRef<IPaymentMethodModal>(null);
  const subscription = useSelector(selectCurrentSubscription)!;
  const dispatch = useDispatch();
  const theme = useTheme();
  const {showErrorAlert} = useAlert();

  if (currentProduct === undefined || !allProducts) return null;

  React.useImperativeHandle<any, IChangePlanModal>(ref, () => ({
    show: () => {
      setVisible(true);
      setErrors(undefined);
      setSaving(false);
      setSelectedKey(undefined);
      if (currentProduct?.interval === BILLING_INTERVAL.MONTH || currentProduct?.interval === BILLING_INTERVAL.YEAR) {
        setInterval(currentProduct.interval);
      } else {
        setInterval(BILLING_INTERVAL.MONTH);
      }
    },

    hide: () => {
      setVisible(false);
    }
  }));

  async function reviewChange() {
    if (!selectedKey) return;

    try {
      setSaving(true);

      let str: string;
      if (selectedKey === 'free') {
        if (!subscription) {
          showErrorAlert('An unexpected error occurred (ER1281).');
          setSaving(true);
          return;
        } else {
          str = formatMessage(MSG_billingPreviewDowngradeAtPeriodEnd, {
            newPlanName: formatMessage(MSG_freeTierLabel),
            newInterval: formatMessage(MSG_billingIntervalForever),
            currentPlanName: currentProduct ? getPlanName(currentProduct.productKey, allProducts, formatMessage) : formatMessage(MSG_freeTierLabel),
            currentInterval: currentProduct ? getIntervalAdjectiveLabel(currentProduct.productKey, allProducts, formatMessage) : formatMessage(MSG_billingIntervalForever),
            date: subscription.stripePeriodEndsAt ? formatDate(subscription.stripePeriodEndsAt, subscription) : formatMessage(MSG_billingNever),
          });
        }
      } else {
        const preview = await previewPlanChange({productKey: selectedKey}).unwrap();
        str = previewSummary(
          preview,
          currentSubscription?.productKey as string,
          selectedKey,
          allProducts as Product[],
          subscription,
          formatMessage,
          formatCurrency,
          formatDate
        );
      }

      confirmationModalRef.current?.show(str);
      setSaving(false);
    } catch (e) {
      setErrors(parseApiError(e));
      setSaving(false);
    }
  }

  async function executeChange() {
    if (!selectedKey) return;
    try {
      let newSubscription: Subscription;
      if (selectedKey === 'free') {
        newSubscription = await cancelPlanMutation({}).unwrap();
      } else {
        newSubscription = await changePlanMutation({productKey: selectedKey}).unwrap();
      }
      dispatch(setCurrentSubscription(newSubscription));
      setVisible(false);
    } catch (e) {
      setErrors(parseApiError(e));
    }
    setSaving(false);
  }

  if (!allProducts || !subscription) return null;

  const products = allProducts.filter(p => !p.legacy && p.interval === interval);
  const essentialProduct = products.find(p => p.featureLevel === FEATURE_LEVEL.ESSENTIAL);
  const completeProduct = products.find(p => p.featureLevel === FEATURE_LEVEL.COMPLETE);

  if (!essentialProduct || !completeProduct) return null;
  const essentialMonthlyProduct = allProducts.find(p => !p.legacy && p.featureLevel === FEATURE_LEVEL.ESSENTIAL && p.interval === BILLING_INTERVAL.MONTH);
  const essentialAnnualProduct = allProducts.find(p => !p.legacy && p.featureLevel === FEATURE_LEVEL.ESSENTIAL && p.interval === BILLING_INTERVAL.YEAR);
  const essentialSavings = essentialMonthlyProduct && essentialAnnualProduct ? (essentialMonthlyProduct.prices['usd'].amount * 12) - essentialAnnualProduct.prices['usd'].amount : 0;
  const completeMonthlyProduct = allProducts.find(p => !p.legacy && p.featureLevel === FEATURE_LEVEL.COMPLETE && p.interval === BILLING_INTERVAL.MONTH);
  const completeAnnualProduct = allProducts.find(p => !p.legacy && p.featureLevel === FEATURE_LEVEL.COMPLETE && p.interval === BILLING_INTERVAL.YEAR);
  const completeSavings = completeMonthlyProduct && completeAnnualProduct ? (completeMonthlyProduct.prices['usd'].amount * 12) - completeAnnualProduct.prices['usd'].amount : 0;

  return (
    <Modal
      windowStyle={{width: '60rem', minHeight: '10rem'}}
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      bodyStyle={{padding: '1rem', paddingBottom: '2rem'}}
      footer={
        <div className="text-right">
          <Button color="secondary" disabled={saving}
                  onClick={() => { setVisible(false); }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          <Button onClick={reviewChange}
                  disabled={saving || !selectedKey || !subscription.stripePaymentMethod} spinner={saving}>
            {formatMessage(MSG_reviewChangeButton)}
          </Button>
        </div>
      }>

      {errors?.errorType === 'message' && (
        <div className="d-flex flex-row">
          <StyledErrorMessage>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            <div>{errors.message}</div>
          </StyledErrorMessage>
        </div>
      )}

      <div>
        <HorizontalOptionsInput
          className="mb-4"
          onChange={setInterval}
          value={interval}
          options={[
            {
              value: BILLING_INTERVAL.MONTH,
              label: formatMessage(MSG_billingIntervalMonthOption)
            }, {
              value: BILLING_INTERVAL.YEAR,
              label: formatMessage(MSG_billingIntervalAnnualOption),
              sublabel: ((currentProduct && !currentProduct.legacy) ? (
                <React.Fragment>
                  <FontAwesomeIcon icon={faStar} color={theme.colors.warning}/>{' '}
                  {formatMessage(MSG_saveAmount)}
                </React.Fragment>
              ) : (
                null
              ))
            },
          ]}
        />
      </div>

      <TiersContainer>
        <BillingTier
          product={null}
          currentProduct={currentProduct}
          selectedKey={selectedKey}
          onSelect={key => { setSelectedKey(key || 'free'); }}
        />
        <BillingTier
          product={essentialProduct}
          currentProduct={currentProduct}
          annualSavings={essentialSavings}
          selectedKey={selectedKey}
          onSelect={key => { setSelectedKey(key || 'free'); }}
        />
        <BillingTier
          product={completeProduct}
          currentProduct={currentProduct}
          annualSavings={completeSavings}
          selectedKey={selectedKey}
          // onSelect={key => { setSelectedKey(key || 'free'); }}
          blocked
        />
      </TiersContainer>

      <StyledCard className="mt-4" style={{marginLeft: '7rem', marginRight: '7rem', overflow: 'visible', borderWidth: 4}}>
        <PaymentMethodCardBody
          subscription={subscription}
          paymentMethodModalRef={paymentMethodModalRef}
        />
      </StyledCard>

      {selectedKey && !subscription.stripePaymentMethod && (
        <PaymentMethodNotice>
          <PaymentMethodNoticeText>
            {formatMessage(MSG_addPaymentMethodNotice)}
          </PaymentMethodNoticeText>
          <ArrowImg src={arrowImg} width="30"/>
        </PaymentMethodNotice>
      )}

      {currentProduct && isLifetimePlan(currentProduct) && (
        <BillingPolicyContainer>
          <h3>{formatMessage(MSG_billingLifetimePlan)}</h3>
          <p>{formatMessage(MSG_billingLifetimePlanExplanation)}</p>
        </BillingPolicyContainer>
      )}

      {/*{currentProduct?.legacy && (*/}
      {/*  <BillingPolicyContainer>*/}
      {/*    <h3>{formatMessage(MSG_billingGrandfatheredRate)}</h3>*/}
      {/*    <p>{formatMessage(MSG_billingGrandfatheredRateExplanation)}</p>*/}
      {/*  </BillingPolicyContainer>*/}
      {/*)}*/}

      <BillingPolicyContainer>
        <h3>{formatMessage(MSG_billingPolicyTitle)}</h3>
        <p>{formatMessage(MSG_billingPolicyLine1)}</p>
        <p>{formatMessage(MSG_billingPolicyLine2)}</p>
        <p>{formatMessage(MSG_billingPolicyLine3, {
          termsOfServiceLink: <a href="/terms" target="_blank">{formatMessage(MSG_termsOfService)}</a>,
          privacyPolicyLink: <a href="/privacy" target="_blank">{formatMessage(MSG_privacyPolicy)}</a>,
        })}</p>
      </BillingPolicyContainer>

      <ConfirmationModal
        onConfirm={executeChange}
        ref={confirmationModalRef}/>

      <PaymentMethodModal ref={paymentMethodModalRef}/>

    </Modal>
  );
});

const TiersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
`;

const BillingPolicyContainer = styled.div`
  margin: 1rem;
  margin-bottom: 0;
  h3 {
    margin: 0;
  }
  p {
    margin-top: 0.75rem;
  }
`;

const PaymentMethodNotice = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-left: 7rem;
  margin-right: 7rem;
  height: 3rem;
`;

const PaymentMethodNoticeText = styled.div`
  position: absolute;
  top: 0;
  right: 3rem;
  width: 350px;
  text-align: right;
  font-weight: bold;
  font-style: italic;
  font-size: 1.2rem;
  transform: rotate(-3deg);
  color: ${({theme}) => theme.colors.danger};
`;

const ArrowImg = styled.img`
  position: absolute;
  top: -2rem;
  right: 0.5rem;
  height: 4rem;
  transform: scaleY(-1) rotate(20deg);
`;

export {ChangePlanModal, IChangePlanModal};
