import { User } from "./api_types"

export const atLeastSteward = (user: User | null): boolean => {
  if (!user) return false;
  if (user.role === 'steward' || user.role === 'owner') {
    return true;
  } else {
    return false;
  }
}

export const atLeastOwner = (user: User | null): boolean => {
  if (!user) return false;
  if (user.role === 'owner') {
      return true;
  } else {
      return false;
  }
}
