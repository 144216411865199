import { PayloadAction } from "@reduxjs/toolkit";
import { IDataState } from "../data_slice";
import { IncomeAllocation } from "../../../utils/api_types";
import { dataStateSetters } from "./setters";

export const incomeAllocationDataReducers: {[name: string]: (state: IDataState, action: PayloadAction<any>) => any} = {
  resetIncomeAllocation: (state) => {
    state.incomeAllocationsForLedgerHash = {};
  },

  setIncomeAllocation: (state, action: PayloadAction<IncomeAllocation>) => {
    dataStateSetters.setIncomeAllocation(state, action.payload);
  },

  setIncomeAllocationsForLedger: (state, action: PayloadAction<{ledgerId: string, envelopes: IncomeAllocation[]}>) => {
    dataStateSetters.setIncomeAllocationsForLedger(state, action.payload.ledgerId, action.payload.envelopes);
  },

  removeIncomeAllocation: (state, action: PayloadAction<string>) => {
    for (const ledgerId in state.incomeAllocationsForLedgerHash) {
      if (state.incomeAllocationsForLedgerHash[ledgerId][action.payload]) {
        delete state.incomeAllocationsForLedgerHash[ledgerId][action.payload];
      }
    }
  }
};
