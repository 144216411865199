
interface ISemanticVersion {
  major: number;
  minor: number;
  patch: number;
}

interface INBConfig {
  webVersion: ISemanticVersion;
  mobileVersion: ISemanticVersion;
  apiRoot: string;
  stripePublishableKey: string;
}

let NB_CONFIG: INBConfig = require("../config.json");

// If we are in development mode, then anything in config.dev.json should override values in config.json.
// But any missing values in config.dev.json should fall back to the values in config.json.
//
// If a ../config.dev.json file exists, include it and make any values it contains override
// the values in config.json.  If it does not exist, then just use config.json.  This file is
// excluded from the repository (via .gitignore) so that developers can have their own.
try {
  const DEV_NB_CONFIG: INBConfig = require("../config.dev.json");
  NB_CONFIG = {...NB_CONFIG, ...DEV_NB_CONFIG};
} catch (e) {
  console.warn("config.dev.json not found, using config.json");
}

export { NB_CONFIG };
