import * as React from "react";
import { PropsWithChildren } from "react";
import { BudgetSummary } from "./BudgetSummary";
import { BudgetEnvelopesGrid } from "./BudgetEnvelopesGrid";
import {
  selectBudgetedExpensesForLedger,
  selectEnvelopesForLedger,
  selectIncomeAllocationsForLedger,
  selectIncomeSourcesForLedger
} from "shared/state/store";
import { calculateBudgetValues, calculateMonthlyTotals } from "shared/utils/helpers";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { EmptyIncomeSourcesExplanation } from "./EmptyIncomeSourcesExplanation";
import { EmptyEnvelopeListExplanation } from "@web/components/shared/EmptyEnvelopeListExplanation";
import { EmptyExpensesAndFundingExplanation } from "./EmptyExpensesAndFundingExplanation";
import { BudgetEnvelopesList } from "./BudgetEnvelopesList";

interface IProps {}

const BudgetPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const incomeSources = useSelector(selectIncomeSourcesForLedger(ledgerId));
  const incomeAllocations = useSelector(selectIncomeAllocationsForLedger(ledgerId));
  const budgetedExpenses = useSelector(selectBudgetedExpensesForLedger(ledgerId));
  const envelopes = useSelector(selectEnvelopesForLedger(ledgerId)).filter(e => !e.archived);
  const [viewType, setViewType] = React.useState<'grid' | 'list'>(localStorage.getItem('BUDGET_VIEW_TYPE') as 'grid' | 'list' || 'grid');

  const envelopeValues = calculateBudgetValues(incomeSources, incomeAllocations, budgetedExpenses, envelopes);
  const summaryValues = calculateMonthlyTotals(incomeSources, envelopeValues);

  const changeViewType = (viewType: 'grid' | 'list') => {
    setViewType(viewType);
    localStorage.setItem('BUDGET_VIEW_TYPE', viewType);
  };

  return (
    <div className="flex-column align-items-stretch justify-content-stretch" style={{height: '100%'}}>
      <BudgetSummary
        viewType={viewType}
        onChangeViewType={changeViewType}
        envelopeValues={envelopeValues}
        summaryValues={summaryValues}
      />
      <RelativeContainer>
        {viewType === 'list'
          ? <BudgetEnvelopesList envelopeValues={envelopeValues}/>
          : <BudgetEnvelopesGrid envelopeValues={envelopeValues}/>}
        {incomeSources.length === 0 && (
          <EmptyIncomeSourcesExplanation/>)}
        {incomeSources.length > 0 && envelopes.length === 0 && (
          <EmptyEnvelopeListExplanation screen="budget"/>)}
        {incomeSources.length > 0 && envelopes.length > 0 && summaryValues.monthlyExpenses.average === 0 && summaryValues.monthlyFunding.average === 0 && (
          <EmptyExpensesAndFundingExplanation viewType={viewType}/>)}
      </RelativeContainer>
    </div>
  );
};

const RelativeContainer = styled.div`
  position: relative;
  padding-top: 2rem;
  flex-grow: 1;
`;

export {BudgetPage};
