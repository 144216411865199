import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import arrowImg from "shared/images/drawn-arrow.png";
import { useIntlFormatters } from "shared/utils/formatters";
import { StyledRaisedContainer } from "@web/components/styled/StyledRaisedContainer";
import { MSG_clickEnvelopesForBudget, MSG_clickEnvelopesForBudgetExplanation } from "shared/strings/budget";
import { selectEnvelopesForLedger } from "shared/state/slices/data_selectors/envelope_data_selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

interface IProps {
  viewType: 'grid' | 'list';
}

const EmptyExpensesAndFundingExplanation: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const params = useParams<{ledgerId: string}>();
  const envelopes = useSelector(selectEnvelopesForLedger(params.ledgerId as string)).filter(e => !e.archived);
  const {formatMessage} = useIntlFormatters();

  // sort the envelopes by appX then appY, and find the one that has the lowest appX and appY
  const lowestEnvelope = envelopes.sort((a, b) => {
    if (a.appX === b.appX) {
      return a.appY - b.appY;
    } else {
      return a.appX - b.appX;
    }
  })[0];

  let yPosRems = 7 * (lowestEnvelope.appY + 1);
  let xPosPercent = (lowestEnvelope.appX / 5) * 100 + 6;
  if (props.viewType === 'list') yPosRems += 8;

  return (
    <OuterContainer>
      <InnerContainer>
        <ArrowImg src={arrowImg} xPosPercent={xPosPercent} yPosRems={yPosRems}/>
        <MessageContainer xPosPercent={xPosPercent} yPosRems={yPosRems}>
          <MessageContent>
            <div>
              <h3>{formatMessage(MSG_clickEnvelopesForBudget)}</h3>
              <p>{formatMessage(MSG_clickEnvelopesForBudgetExplanation)}</p>
            </div>
          </MessageContent>
        </MessageContainer>
      </InnerContainer>
    </OuterContainer>
  );
};


const OuterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

const InnerContainer = styled.div`
  position: relative;
  padding-top: 2rem;
`;

const MessageContainer = styled(StyledRaisedContainer)<{xPosPercent?: number, yPosRems?: number}>`
  position: absolute;
  top: ${({yPosRems}) => yPosRems ? `${yPosRems + 5}rem` : 'auto'};
  left: ${({xPosPercent}) => xPosPercent ? `${xPosPercent + 10}%` : 'auto'};
  width: 50%;
  background: #fff;
  transform: rotate(-2deg);
  box-shadow: 0 5px 15px #0006;
  margin-bottom: 1rem;
  pointer-events: all;

  h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const MessageContent = styled.div`
  position: relative;
  overflow: hidden;
  padding: 2rem;
`;

const ArrowImg = styled.img<{xPosPercent?: number, yPosRems?: number}>`
  position: absolute;
  top: ${({yPosRems}) => yPosRems ? `${yPosRems + 1}rem` : 'auto'};
  left: ${({xPosPercent}) => xPosPercent ? `${xPosPercent}%` : 'auto'};
  width: 7rem;
  transform: scaleX(-1) scaleY(-1) rotate(-20deg);
`;

const CashEnvelopeImg = styled.img`
  position: absolute;
  width: 60%;
  left: 20%;
  right: 20%;
  bottom: -5rem;
`;

export {EmptyExpensesAndFundingExplanation};
