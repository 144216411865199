export const MSG_reconcileTitle = {
  id: "cdd090fb-dd42-465c-ba48-98b695e43b86",
  description: "This is the title of the section where they can reconcile their bank statements.",
  defaultMessage: "Reconcile"
};

export const MSG_reconciledLabel = {
  id: "16616685-b67e-457d-9845-984c447faccb",
  description: "",
  defaultMessage: "Reconciled"
};

export const MSG_notReconciledLabel = {
  id: "ec1b07ef-09f8-4665-b65a-55d5d7255e06",
  description: "",
  defaultMessage: "Not yet reconciled"
};


export const MSG_reconcilePreviousBalanceLabel = {
  id: "3c870cb8-4000-43f5-8303-f4ec66e92c3f",
  description: "",
  defaultMessage: "Previous balance:"
};

export const MSG_reconcileSelectedTotalLabel = {
  id: "6527c0f0-5dc6-424f-a7bd-451907523f69",
  description: "",
  defaultMessage: "Selected total:"
};

export const MSG_reconcileBankBalanceLabel = {
  id: "751a4133-06ee-48fa-9eb3-dce06917e057",
  description: "",
  defaultMessage: "Bank balance:"
};

export const MSG_lastReconciledOnDate = {
  id: "75cee862-74fd-42b0-824c-8d5df4a7a632",
  description: "",
  defaultMessage: "Last reconciled on {date}"
};

export const MSG_reconcileEnterCurrentBankBalance = {
  id: "c5a43623-4f2d-4490-9067-263a34e3e196",
  description: "",
  defaultMessage: "Enter current bank balance"
};

export const MSG_reconcileThisShouldBe = {
  id: "51d5bccc-65ed-44ac-bf4d-b3d4c11d4eec",
  description: "",
  defaultMessage: "This should be {amount}"
};

export const MSG_reconcileBankBalanceMore = {
  id: "5c33ca1d-aa59-43a3-8699-25ec08d9bfea",
  description: "",
  defaultMessage: "The bank balance you have entered is more than your previous balance plus the selected transactions."
};

export const MSG_reconcileSelectedTransactionsMore = {
  id: "8f5bb187-10c7-4de5-9784-5b599c32eef9",
  description: "",
  defaultMessage: "Your previous balance plus the selected transactions are more than the bank balance you have entered."
};

export const MSG_noUnreconciledTransactionsFoundMatchingQuery = {
  id: "2b0e6b52-6a23-48ac-acd4-d011031d5012",
  description: "",
  defaultMessage: "No unreconciled transactions were found matching this query."
};

export const MSG_noUnreconciledTransactionsFoundInEnvelope = {
  id: "f02c220f-54c7-40c8-93e9-363bb8e47847",
  description: "",
  defaultMessage: "No unreconciled transactions were found in this envelope."
};

export const MSG_noUnreconciledTransactionsFound = {
  id: "d064d0f8-6117-4304-9962-a06fc1c70860",
  description: "",
  defaultMessage: "No unreconciled transactions were found."
};

export const MSG_allReconciledForAccount = {
  id: "927912b6-7fee-427a-a62a-dc74813b6b04",
  description: "",
  defaultMessage: "All transactions in this account have been reconciled."
};

