import * as React from "react";
import { PropsWithChildren, RefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntlFormatters } from "shared/utils/formatters";
import {
  MSG_incomeExplanation,
  MSG_incomesMustBeIncome,
  MSG_incomesMustBeSplitIndividually,
  MSG_incomeSourceNotes,
  MSG_incomeTitle,
  MSG_noIncomeSourcesExplanation
} from "shared/strings/transactions";
import { faMoneyBill } from "@fortawesome/pro-light-svg-icons/faMoneyBill";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import { IncomeSourceSequencePopover } from "@web/components/selectors/IncomeSourceSequencePopover";
import { IPopover } from "@web/components/Popover";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ITransactionEditorModal } from "../transaction_editor_modal/TransactionEditorModal";
import { AllocationModalContext } from "./context";
import { Transaction } from "shared/utils/api_types";
import { StyledDropbox } from "@web/components/styled/StyledDropbox";
import { selectEnvelopesForLedger, selectIncomeSourcesForLedger, selectLedger } from "shared/state/store";
import { useAlert } from "@web/utils/hooks";

interface IProps {
  transactionEditorModalRef: RefObject<ITransactionEditorModal>;
  hoverFocusBorder?: boolean;
  forbidden?: boolean;
  afterDrop?: () => void;
}

const IncomeDropbox: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const context = React.useContext(AllocationModalContext);
  const {formatMessage, formatOrdinal} = useIntlFormatters();
  const incomeSourcesRef = React.useRef<HTMLInputElement>(null);
  const incomeSourcesPopoverRef = React.useRef<IPopover>(null);
  const params = useParams<{ledgerId: string}>();
  const incomeSources = useSelector(selectIncomeSourcesForLedger(params.ledgerId as string))
  const envelopes = useSelector(selectEnvelopesForLedger(params.ledgerId as string));
  const ledger = useSelector(selectLedger(params.ledgerId as string));
  const [selectedTransaction, setSelectedTransaction] = React.useState<Transaction | null>(null);
  const {showAlert} = useAlert();
  let isOver, dropRef;
  [{isOver}, dropRef] = useDrop(() => ({
      accept: 'unallocated-transaction',
      drop: onDrop,
      collect: monitor => ({
        isOver: monitor.isOver(),
      })
    }
  ), [props.forbidden, context.selected]);

  function onDrop() {
    if (context.selected.length > 1) {
      showAlert(formatMessage(MSG_incomesMustBeSplitIndividually));
      props.afterDrop?.();
      return;
    }
    if (context.selected[0]?.amount <= 0) {
      showAlert(formatMessage(MSG_incomesMustBeIncome));
      props.afterDrop?.();
      return;
    }
    if (incomeSources.length === 0) {
      showAlert(formatMessage(MSG_noIncomeSourcesExplanation));
      props.afterDrop?.();
      return;
    }
    if (props.forbidden || context.selected.length === 0) return;
    context.setWorking(context.selected);
    setSelectedTransaction(context.selected[0]);
    incomeSourcesPopoverRef.current?.show();
  }

  if (!ledger) return null;

  return (
    <React.Fragment>
      <StyledDropbox ref={dropRef}
                     style={{width: '33.33%'}}
                     className={classNames({active: isOver, 'hover-focus-border': props.hoverFocusBorder, forbidden: props.forbidden})}
                     onClick={(e) => {
                       e.stopPropagation();
                       e.preventDefault();
                       onDrop();
                     }}>
        <div ref={incomeSourcesRef}>
          <div className="title">
            <FontAwesomeIcon icon={faMoneyBill} className="me-2"/>
            {formatMessage(MSG_incomeTitle)}
          </div>
          <div>
            {formatMessage(MSG_incomeExplanation)}
          </div>
        </div>
      </StyledDropbox>

      <IncomeSourceSequencePopover
        ref={incomeSourcesPopoverRef}
        targetRef={incomeSourcesRef}
        ledgerId={ledger.id}
        onCancel={() => {
          context.setWorking([]);
          props.afterDrop?.();
        }}
        onSelect={(incomeSource, sequenceNumber, incomeAllocations) => {
          let newTransaction = {
            ...selectedTransaction,
            description: incomeSource.name,
            notes: formatMessage(MSG_incomeSourceNotes, {ordinal: formatOrdinal(sequenceNumber), incomeName: incomeSource.name})
          };
          if (!selectedTransaction?.amount) {
            newTransaction.amount = incomeSource.amount;
          }
          newTransaction.allocations = incomeAllocations
            .filter(a => {
              let env = envelopes.find(e => e.id === a.envelopeId);
              return env && !env.archived;
            })
            .map(a => ({
              envelopeId: a.envelopeId,
              amount: a.method === 'percent' ? Math.floor((newTransaction?.amount || 0) * (a.amount / 10000)) : a.amount,
            }));
          context.setWorking([]);
          props.transactionEditorModalRef.current?.show(newTransaction, props.afterDrop);
        }}
      />
    </React.Fragment>
  );
};

export {IncomeDropbox};
