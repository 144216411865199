import * as React from "react";
import { PropsWithChildren } from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import { TextInput } from "@web/components/inputs/TextInput";
import styled from "styled-components";
import {
  MSG_longExplanationOfProblem,
  MSG_reportAProblemExplanation,
  MSG_reportAProblemReply,
  MSG_reportAProblemSent,
  MSG_reportAProblemSentExplanation,
  MSG_reportAProblemTitle,
  MSG_shortSummaryOfProblem
} from "shared/strings/support";
import { MSG_send } from "shared/strings/login";
import { Button } from "@web/components/Button";
import { selectCurrentUser } from "shared/state/slices/data_selectors/core_data_selectors";
import { useSelector } from "react-redux";
import { useSendSupportRequestMutation } from "shared/state/endpoints/app/support_api";
import { parseApiError } from "shared/utils/api_errors";
import { MSG_unexpectedError } from "shared/strings/generic";
import { useAlert } from "@web/utils/hooks";

interface IProps {}

const ContactPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const [subject, setSubject] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');
  const [sending, setSending] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState<boolean>(false);
  const [sendSupportRequest]: any = useSendSupportRequestMutation();
  const {showErrorAlert} = useAlert();
  const user = useSelector(selectCurrentUser)!;
  const {formatMessage} = useIntlFormatters();

  async function send() {
    setSending(true);
    try {
      await sendSupportRequest({subject, message}).unwrap();
      setSent(true);
    } catch (e) {
      let error = parseApiError(e);
      showErrorAlert(error.errorType === 'message' ? error.message : formatMessage(MSG_unexpectedError));
    } finally {
      setSending(false);
    }
  }

  return (
    <Container>
      <h1>{formatMessage(MSG_reportAProblemTitle)}</h1>

      {sent ? (
        <React.Fragment>
          <p className="mt-4 text-bold">{formatMessage(MSG_reportAProblemSent)}</p>
          <p className="mt-4">{formatMessage(MSG_reportAProblemSentExplanation, {email: user.email})}</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            {formatMessage(MSG_reportAProblemExplanation)}{' '}
            <b>{formatMessage(MSG_reportAProblemReply, {email: user.email})}</b>
          </p>
          <p className="mt-8 mb-4">{formatMessage(MSG_shortSummaryOfProblem)}</p>
          <TextInput
            autoFocus
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />

          <p className="mt-4 mb-2">{formatMessage(MSG_longExplanationOfProblem)}</p>
          <TextInput
            rows={14}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />

          <div className="text-right mb-4">
            <Button onClick={send}>{formatMessage(MSG_send)}</Button>
          </div>
        </React.Fragment>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 40rem;
  margin: auto;
`;

export {ContactPage};
