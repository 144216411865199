import { User } from "shared/utils/api_types";
import { useIntlFormatters } from "shared/utils/formatters";
import { Modal } from "@web/components/Modal";
import { Button } from "@web/components/Button";
import { MSG_cancelButton, MSG_saveButton, MSG_unexpectedError } from "shared/strings/generic";
import React from "react";
import { selectApiKey, setUser } from "shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { ProfilePictureUploader } from "./ProfilePictureUploader";
import Cropper, { Area } from 'react-easy-crop'
import { fileToBase64 } from "shared/utils/helpers";
import styled, { useTheme } from "styled-components";
import getCroppedFile from "@web/utils/images";
import { NB_CONFIG } from "shared/utils/config";
import { fromApiError, fromApiUser } from "shared/utils/api_transformations";
import { extractErrorMessages } from "shared/utils/api_errors";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { faClose } from "@fortawesome/pro-light-svg-icons/faClose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAlert } from "@web/utils/hooks";

interface IProps {
  onChange?: (image: string) => any;
  onSave?: () => any;
}

interface IProfilePictureEditorModal  {
  show: (user: User) => any;
  hide: () => any;
}

const ProfilePictureEditorModal = React.forwardRef<IProfilePictureEditorModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [id, setId] = React.useState<string | undefined>(undefined);
  const {formatMessage} = useIntlFormatters();
  const dispatch = useDispatch();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [image, setImage] = React.useState<string | null>(null);
  const [file, setFile] = React.useState<File | null>(null);
  const [progress, setProgress] = React.useState<number>(0);
  const apiKey = useSelector(selectApiKey);
  const theme = useTheme();
  const {showErrorAlert} = useAlert();

  React.useImperativeHandle<any, IProfilePictureEditorModal>(ref, () => ({
    show: (_user: User) => {
      setVisible(true);
      setSaving(false);
      setImage(null);
      setId(_user.id);
      setProgress(0);
    },

    hide: () => {
      setVisible(false);
    }
  }));

  const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    setFile(await getCroppedFile(image as string, croppedAreaPixels));
  };

  const save = async () => {
    if (!file) return;

    setSaving(true);
    let formData = new FormData();
    formData.append('file', file);

    // Step 4: Upload the image
    let xhr = new XMLHttpRequest();
    xhr.open('POST', `${NB_CONFIG.apiRoot}/users/${id}/upload_profile_picture`, true);
    xhr.setRequestHeader('NB-API-KEY', apiKey as string);

    // Step 5: Update progress
    xhr.upload.onprogress = function(event) {
      setProgress(event.loaded / event.total * 100);
    };

    // Step 6: Handle the server response
    xhr.onload = function() {
      if (xhr.status === 200) {
        let response = JSON.parse(xhr.responseText);
        dispatch(setUser(fromApiUser(response.user)));
        setVisible(false);
      } else {
        showErrorAlert(extractErrorMessages(fromApiError(JSON.parse(xhr.responseText)))[0] || formatMessage(MSG_unexpectedError));
      }
      setProgress(0);
      setSaving(false);
    };

    xhr.send(formData);
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => setVisible(false)}
      windowStyle={{width: '35rem'}}
      bodyStyle={{padding: '2rem'}}
      footer={
        <div className="text-right row-reverse align-items-center">
          <Button onClick={save}
                  disabled={saving} spinner={saving}>
            {formatMessage(MSG_saveButton)}
          </Button>
          <Button color="secondary" disabled={saving}
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="me-2">
            {formatMessage(MSG_cancelButton)}
          </Button>
          {!!progress && <div className="me-4">{progress}%</div>}
        </div>
      }>

      {image ? (
        <React.Fragment>
          <CropperContainer>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              maxZoom={5}
              aspect={1}
              cropShape="round"
              objectFit="cover"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <CloseButton onClick={() => setImage(null)}>
              <FontAwesomeIcon icon={faClose}/>
            </CloseButton>
          </CropperContainer>
          <div className="mt-4" style={{width: '80%', margin: 'auto'}}>
            <Slider
              value={zoom}
              step={0.01}
              min={1}
              max={5}
              onChange={(value) => setZoom(value as number)}
              trackStyle={{backgroundColor: theme.colors.primary}}
              handleStyle={{borderColor: theme.colors.primary, backgroundColor: theme.colors.primary, boxShadow: 'none'}}
            />
          </div>
        </React.Fragment>
      ) :(
        <ProfilePictureUploader
          onUpload={async (file) => {
            try {
              setImage(await fileToBase64(file));
            } catch (e) {
              console.log(e);
            }
          }}
        />
      )}

    </Modal>
  );
});

const CropperContainer = styled.div`
  position: relative;
  margin: auto;
  width: 15rem;
  height: 15rem;
`;

const CloseButton = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid ${({theme}) => theme.colors.textColor};
  color: ${({theme}) => theme.colors.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1rem;
  right: -1rem;
  font-size: 2rem;
  cursor: pointer;
`;

export { ProfilePictureEditorModal, IProfilePictureEditorModal };
