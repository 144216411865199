import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectLedger = (ledgerId: string) => createSelector(
  (state: RootState) => state.data.ledgersHash,
  (ledgersHash) => ledgersHash[ledgerId]
);

export const selectLedgers = createSelector(
  (state: RootState) => state.data.ledgersHash,
  (ledgersHash) => Object.values(ledgersHash)
);
