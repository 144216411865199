import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyledEnvelopeContainer } from "@web/components/styled/StyledEnvelopeContainer";
import { useDrag } from "react-dnd";
import { Envelope } from "shared/utils/api_types";
import { MSG_archiveButton } from "shared/strings/envelopes";
import { MSG_editButton, MSG_unexpectedError } from "shared/strings/generic";
import { useIntlFormatters } from "shared/utils/formatters";
import { useUpdateEnvelopeMutation } from "shared/state/endpoints/app/envelopes_api";
import { parseApiError } from "shared/utils/api_errors";
import { Spinner } from "@web/components/Spinner";
import { IEnvelopeEditorModal } from "@web/modals/envelope_editor_modal/EnvelopeEditorModal";
import { useReloadLedgers } from "shared/hooks/use_reload";
import { useAlert } from "@web/utils/hooks";

interface IProps {
  ledgerId: string;
  envelope: Envelope;
  className?: string;
  envelopeEditorModalRef: React.RefObject<IEnvelopeEditorModal>;
}

const EnvelopeEditable: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const envelope = props.envelope;
  const [updateEnvelope] = useUpdateEnvelopeMutation();
  const {formatMessage} = useIntlFormatters();
  const [working, setWorking] = React.useState<boolean>(false);
  const reloadLedgers = useReloadLedgers();
  const {showAlert} = useAlert();

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'envelope',
      item: envelope,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.25 : 1
      })
    }),
    []
  );

  async function archive() {
    try {
      setWorking(true);
      await updateEnvelope({
        ledgerId: props.ledgerId,
        envelope: {id: envelope.id, archived: true}
      }).unwrap();
      await reloadLedgers();
    } catch (e) {
      const err = parseApiError(e);
      if (err.errorType === 'models') {
        showAlert(err.errors.envelope?.base[0] || formatMessage(MSG_unexpectedError));
      } else if (err.errorType === 'message') {
        showAlert(err.message);
      } else {
        showAlert(formatMessage(MSG_unexpectedError));
      }
    }
    setWorking(false);
  }

  function edit() {
    props.envelopeEditorModalRef.current?.show(envelope);
  }

  return (
    <StyledEnvelopeContainer style={{backgroundColor: envelope.color, opacity, cursor: 'move'}}
                             ref={dragRef}>
      {envelope.icon && <Icon>{envelope.icon}</Icon>}
      <Name>{envelope.name}</Name>
      <Actions>
        {working ? (
          <Spinner/>
        ) : (
          <React.Fragment>
            <FakeLink onClick={archive}>{formatMessage(MSG_archiveButton)}</FakeLink>
            <FakeLink onClick={edit}>{formatMessage(MSG_editButton)}</FakeLink>
          </React.Fragment>
        )}
      </Actions>
    </StyledEnvelopeContainer>
  );
};

const Icon = styled.div`
  font-size: 1.5rem;
  position: absolute;
  line-height: 1;
  left: 0.25rem;
  bottom: 0.25rem;
`;

const Name = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Actions = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`;

const FakeLink = styled.div`
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  margin-right: 1rem;
  opacity: 0.65;
  font-size: 80%;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
  }
`;
export {EnvelopeEditable};
