import * as React from "react";
import { PropsWithChildren } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useGetHelpArticlesQuery, useGetHelpGroupQuery } from "shared/state/endpoints/app/help_api";
import { useIntlFormatters } from "shared/utils/formatters";
import { Spinner } from "@web/components/Spinner";
import { StyledCard, StyledCardNavLinkBody } from "@web/components/styled/StyledCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons/faNewspaper";
import { MSG_helpHome } from "shared/strings/help";
import { ArticleIcon, GroupName } from "./helpers";
import styled from "styled-components";

interface IProps {}

const HelpCenterGroupPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const params = useParams<{groupId: string}>();
  const {data: articles, isFetching} = useGetHelpArticlesQuery({helpGroupId: params.groupId as string});
  const {data: group, isFetching: isFetchingGroup} = useGetHelpGroupQuery(params.groupId as string);
  const {formatMessage} = useIntlFormatters();

  return (
    <React.Fragment>
      {isFetching || isFetchingGroup ? (
        <div className="text-center">
          <Spinner large/>
        </div>
      ) : (
        <React.Fragment>
          <div className="flex-row align-items-center mb-4">
            <NavLink to="/app/help_center/articles">{formatMessage(MSG_helpHome)}</NavLink>

            <FontAwesomeIcon icon={faChevronRight} className="ms-4 me-4"/>

            <GroupName>{group?.name}</GroupName>

          </div>

          <StyledCard>
            {articles?.map(article => (
              <StyledCardNavLinkBody to={`/app/help_center/articles/${group?.id}/${group?.slug}/${article.id}/${article.slug}`}
                                     key={article.id}
                                     className="clickable">
                <div className="flex-row align-items-center">
                  <ArticleIcon>
                    <FontAwesomeIcon icon={faNewspaper}/>
                  </ArticleIcon>
                  <div>
                    <div className="flex-grow-1 clickable-title text-bold mb-2">
                      {article.title}
                    </div>
                    <LimitedLines>
                      {article.body?.replace(/<[^>]+>/g, '')}
                    </LimitedLines>
                  </div>
                </div>
              </StyledCardNavLinkBody>
            ))}
          </StyledCard>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};


const LimitedLines = styled.div`
  // hide overflow and show an ellipsis after 3 lines
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // number of lines to show
  -webkit-box-orient: vertical;
  word-break: break-word;
  opacity: 0.3;
  max-height: 3rem;
`;

export { HelpCenterGroupPage };
