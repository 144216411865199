import { baseApi } from "../base_api";
import { Transaction } from "../../../utils/api_types";
import { fromApiTransaction, toApiTransaction } from "../../../utils/api_transformations";
import { TransactionListSortDirection, TransactionListSortType } from "state/store";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<{
      totalCount: number,
      page: number,
      transactions: Transaction[],
      sort: TransactionListSortType,
      dir: TransactionListSortDirection,
    }, {
      ledgerId: string,
      accountId?: string,
      envelopeId?: string,
      q?: string,
      page?: number,
      per?: number,
      sort?: TransactionListSortType,
      dir?: TransactionListSortDirection,
      onlyUnallocated?: boolean;
      onlyUnreconciled?: boolean;
    }>({
      query: (args) => {
        let params = new URLSearchParams();
        params.append('page', `${args.page || 1}`);
        params.append('per', `${args.per || 50}`);
        if (args.sort) params.append('sort', args.sort);
        if (args.dir) params.append('dir', args.dir);
        params.append('expand[]', 'allocations');
        params.append('expand[]', 'user');
        params.append('expand[]', 'account');
        if (args.onlyUnallocated) params.append('only_unallocated', '1');
        if (args.onlyUnreconciled) params.append('only_unreconciled', '1');
        if (args.envelopeId) params.append('envelope_id', args.envelopeId);
        if (args.accountId) params.append('account_id', args.accountId);
        if (args.q) params.append('q', args.q);
        return ({
          url: `/ledgers/${args.ledgerId}/transactions?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return {
          totalCount: response['total_count'],
          page: response['page'],
          sort: response['sort'],
          dir: response['dir'],
          transactions: response.items.map(fromApiTransaction)
        }
      }
    }),

    transaction: builder.query<Transaction, {ledgerId: string, transactionId: string}>({
      query: ({ledgerId, transactionId}) => ({
        url: `/ledgers/${ledgerId}/transactions/${transactionId}?expand[]=allocations`
      }),
      transformResponse: (response: any) => {
        return fromApiTransaction(response['transaction']);
      },
    }),

    updateTransaction: builder.mutation<Transaction, {ledgerId: string, transaction: Partial<Transaction>}>({
      query: ({ledgerId, transaction}) => ({
        url: `/ledgers/${ledgerId}/transactions/${transaction.id}?expand[]=allocations`,
        method: 'PATCH',
        body: {transaction: toApiTransaction(transaction)},
      }),
      transformResponse: (response: any) => {
        return fromApiTransaction(response['transaction']);
      },
    }),

    updateTransactions: builder.mutation<{success: boolean, errors: string[]}, {ledgerId: string, transactions: Partial<Transaction>[]}>({
      query: ({ledgerId, transactions}) => ({
        url: `/ledgers/${ledgerId}/transactions?expand[]=allocations`,
        method: 'PATCH',
        body: {transactions: transactions.map(toApiTransaction)},
      }),
      transformResponse: (response: any) => {
        return {
          success: response['success'],
          errors: response['errors'] || [],
        };
      },
    }),

    createTransaction: builder.mutation<Transaction, {ledgerId: string, transaction: Partial<Transaction>}>({
      query: ({ledgerId, transaction}) => ({
        url: `/ledgers/${ledgerId}/transactions?expand[]=allocations`,
        method: 'POST',
        body: {transaction: toApiTransaction(transaction)},
      }),
      transformResponse: (response: any) => {
        return fromApiTransaction(response['transaction']);
      },
    }),

    deleteTransaction: builder.mutation<{success: boolean}, {ledgerId: string, transactionId: string}>({
      query: ({ledgerId, transactionId}) => ({
        url: `/ledgers/${ledgerId}/transactions/${transactionId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: any) => {
        return {success: true};
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTransactionsQuery, useGetTransactionsQuery,
  useLazyTransactionQuery, useTransactionQuery,
  useUpdateTransactionMutation,
  useUpdateTransactionsMutation,
  useCreateTransactionMutation,
  useDeleteTransactionMutation,
} = extendedApi;

