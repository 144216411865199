import { createGlobalStyle } from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import { lighten, opacify } from "color2k";

export const GlobalStyles = createGlobalStyle<{uiScale: number}>`
  html {
    box-sizing: border-box;
    font-size: max(min(2vh, 1.1vw, ${({uiScale}) => ((uiScale/100)*3 + 12) + 'px'}), ${({uiScale}) => ((uiScale/100)*3 + 9) + 'px'});
    font-family: "Open Sans", Helvetica, sans-serif;
    overflow-y: scroll;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    line-height: 1.5em;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  input, button, textarea, select, option {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 1rem;
    outline: none;
    color: ${({theme}) => theme.colors.textColor };
  }

  ::selection {
    background-color: ${({theme}) => opacify(lighten(theme.colors.primary, 0.25), -0.5) };
  }

  input[type="checkbox"], input[type="radio"] {
    accent-color: ${({theme}) => theme.colors.primary };
    margin: 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer !important;
  }

  label[for] {
    cursor: pointer;
  }

  ol, ul {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    background: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.textColor}
  }

  a {
    color: ${({theme}) => theme.colors.linkColor};
  }
  a:hover {
    color: ${({theme}) => theme.colors.textColor};
  }
  a:focus {
    outline: 1px dotted ${({theme}) => theme.colors.primary};
    outline-offset: 2px;
  }

  .m-0 { margin: 0 !important; }
  .m-1 { margin: 0.25rem !important; }
  .m-2 { margin: 0.5rem !important; }
  .m-3 { margin: 0.75rem !important; }
  .m-4 { margin: 1rem !important; }
  .m-5 { margin: 1.25rem !important; }
  .m-6 { margin: 1.5rem !important; }
  .m-7 { margin: 1.75rem !important; }
  .m-8 { margin: 2rem !important; }

  .mb-0 { margin-bottom: 0 !important; }
  .mb-1 { margin-bottom: 0.25rem !important; }
  .mb-2 { margin-bottom: 0.5rem !important; }
  .mb-3 { margin-bottom: 0.75rem !important; }
  .mb-4 { margin-bottom: 1rem !important; }
  .mb-5 { margin-bottom: 1.25rem !important; }
  .mb-6 { margin-bottom: 1.5rem !important; }
  .mb-7 { margin-bottom: 1.75rem !important; }
  .mb-8 { margin-bottom: 2rem !important; }

  .mt-0 { margin-top: 0 !important; }
  .mt-1 { margin-top: 0.25rem !important; }
  .mt-2 { margin-top: 0.5rem !important; }
  .mt-3 { margin-top: 0.75rem !important; }
  .mt-4 { margin-top: 1rem !important; }
  .mt-5 { margin-top: 1.25rem !important; }
  .mt-6 { margin-top: 1.5rem !important; }
  .mt-7 { margin-top: 1.75rem !important; }
  .mt-8 { margin-top: 2rem !important; }

  .ms-0 { margin-left: 0 !important; }
  .ms-1 { margin-left: 0.25rem !important; }
  .ms-2 { margin-left: 0.5rem !important; }
  .ms-3 { margin-left: 0.75rem !important; }
  .ms-4 { margin-left: 1rem !important; }
  .ms-5 { margin-left: 1.25rem !important; }
  .ms-6 { margin-left: 1.5rem !important; }
  .ms-7 { margin-left: 1.75rem !important; }
  .ms-8 { margin-left: 2rem !important; }

  .me-0 { margin-right: 0 !important; }
  .me-1 { margin-right: 0.25rem !important; }
  .me-2 { margin-right: 0.5rem !important; }
  .me-3 { margin-right: 0.75rem !important; }
  .me-4 { margin-right: 1rem !important; }
  .me-5 { margin-right: 1.25rem !important; }
  .me-6 { margin-right: 1.5rem !important; }
  .me-7 { margin-right: 1.75rem !important; }
  .me-8 { margin-right: 2rem !important; }

  .p-0 { padding: 0 !important; }
  .p-1 { padding: 0.25rem !important; }
  .p-2 { padding: 0.5rem !important; }
  .p-3 { padding: 0.75rem !important; }
  .p-4 { padding: 1rem !important; }
  .p-5 { padding: 1.25rem !important; }
  .p-6 { padding: 1.5rem !important; }
  .p-7 { padding: 1.75rem !important; }
  .p-8 { padding: 2rem !important; }

  .pb-0 { padding-bottom: 0 !important; }
  .pb-1 { padding-bottom: 0.25rem !important; }
  .pb-2 { padding-bottom: 0.5rem !important; }
  .pb-3 { padding-bottom: 0.75rem !important; }
  .pb-4 { padding-bottom: 1rem !important; }
  .pb-5 { padding-bottom: 1.25rem !important; }
  .pb-6 { padding-bottom: 1.5rem !important; }
  .pb-7 { padding-bottom: 1.75rem !important; }
  .pb-8 { padding-bottom: 2rem !important; }

  .pt-0 { padding-top: 0 !important; }
  .pt-1 { padding-top: 0.25rem !important; }
  .pt-2 { padding-top: 0.5rem !important; }
  .pt-3 { padding-top: 0.75rem !important; }
  .pt-4 { padding-top: 1rem !important; }
  .pt-5 { padding-top: 1.25rem !important; }
  .pt-6 { padding-top: 1.5rem !important; }
  .pt-7 { padding-top: 1.75rem !important; }
  .pt-8 { padding-top: 2rem !important; }

  .ps-0 { padding-left: 0 !important; }
  .ps-1 { padding-left: 0.25rem !important; }
  .ps-2 { padding-left: 0.5rem !important; }
  .ps-3 { padding-left: 0.75rem !important; }
  .ps-4 { padding-left: 1rem !important; }
  .ps-5 { padding-left: 1.25rem !important; }
  .ps-6 { padding-left: 1.5rem !important; }
  .ps-7 { padding-left: 1.75rem !important; }
  .ps-8 { padding-left: 2rem !important; }

  .pe-0 { padding-right: 0 !important; }
  .pe-1 { padding-right: 0.25rem !important; }
  .pe-2 { padding-right: 0.5rem !important; }
  .pe-3 { padding-right: 0.75rem !important; }
  .pe-4 { padding-right: 1rem !important; }
  .pe-5 { padding-right: 1.25rem !important; }
  .pe-6 { padding-right: 1.5rem !important; }
  .pe-7 { padding-right: 1.75rem !important; }
  .pe-8 { padding-right: 2rem !important; }

  .text-muted { color: ${({theme}) => theme.colors.mutedTextColor }; }
  .text-uppercase { text-transform: uppercase; }
  .text-center { text-align: center !important; }
  .text-left { text-align: left !important; }
  .text-right { text-align: right !important; }
  .text-nowrap { white-space: nowrap !important; }
  .text-nowrap-ellipsis { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  .text-small { font-size: 0.9rem; line-height: 1.4em; }
  .text-medium { font-size: 1.2rem; }
  .text-large { font-size: 1.5rem; }
  .text-xlarge { font-size: 2rem; }
  .text-bold { font-weight: bold; }
  .text-italic { font-style: italic; }
  .text-alert-red { color: ${({theme}) => theme.colors.errorTextColor }; }
  .text-danger { color: ${({theme}) => theme.colors.danger }; }
  .text-warning { color: ${({theme}) => theme.colors.warning }; }
  .text-success { color: ${({theme}) => theme.colors.success }; }
  .text-primary { color: ${({theme}) => theme.colors.primary }; }
  .text-negative { color: ${({theme}) => theme.colors.negativeEnvelopeTextColor }; }

  .flex-wrap { flex-wrap: wrap; }
  .flex-row { display: flex; flex-direction: row; }
  .flex-column { display: flex; flex-direction: column; }
  .align-items-center { align-items: center; }
  .align-items-start { align-items: start; }
  .align-items-end { align-items: end; }
  .align-items-stretch { align-items: stretch; }
  .justify-content-center { justify-content: center; }
  .justify-content-start { justify-content: start; }
  .justify-content-end { justify-content: end; }
  .justify-content-stretch { justify-content: stretch; }
  .justify-content-space-between { justify-content: space-between; }
  .justify-content-space-around { justify-content: space-around; }
  .flex-grow-1 { flex-grow: 1; flex-basis: 0; }
  .flex-grow-2 { flex-grow: 2; flex-basis: 0; }
  .flex-grow-3 { flex-grow: 3; flex-basis: 0; }
  .flex-grow-4 { flex-grow: 4; flex-basis: 0; }
  .flex-grow-5 { flex-grow: 5; flex-basis: 0; }
  .flex-shrink-1 { flex-shrink: 1; flex-basis: 0; }
  .row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .ReactModal__Content {
    transform: scale(0.75);
    transition: transform 100ms ease-in-out;
  }

  .ReactModal__Content--after-open {
    transform: scale(1);
  }

  .ReactModal__Content--before-close {
    transform: scale(0.75);
  }

  .react-datepicker-popper {
    z-index: 5000;
  }

  .react-datepicker {
    font-family: inherit;
    font-size: 1rem;
    color: inherit;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

    .react-datepicker__current-month {
      font-size: 1rem;
    }

    .react-datepicker__day--selected {
      background-color: ${({theme}) => theme.colors.primary };
      outline-color: ${({theme}) => theme.colors.primary };
      &:hover {
        background-color: ${({theme}) => theme.colors.primary };
      }
    }

    .react-datepicker__day--highlighted {
      background-color: ${({theme}) => theme.colors.primary };
      outline-color: ${({theme}) => theme.colors.primary };
      &:hover {
        background-color: ${({theme}) => theme.colors.primary };
      }
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${({theme}) => lighten(theme.colors.primary, 0.1) };
      outline-color: ${({theme}) => lighten(theme.colors.primary, 0.1) };
      &:hover {
        background-color: ${({theme}) => lighten(theme.colors.primary, 0.1) };
        outline-color: ${({theme}) => lighten(theme.colors.primary, 0.1) };
      }
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  .clickable {
    cursor: pointer !important;

    &:hover .clickable-title {
      text-decoration: underline !important;
    }

    .hover-show {
      opacity: 0;
    }

    &:hover .hover-show {
      opacity: 1;
    }
  }

  .trix-content {
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    h1 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }

    blockquote {
      border: 0 solid #ccc;
      border-left-width: $quote-border-width;
      margin-left: $quote-margin-start;
      padding-left: $quote-padding-start;
    }

    [dir=rtl] blockquote,
    blockquote[dir=rtl] {
      border-width: 0;
      border-right-width: $quote-border-width;
      margin-right: $quote-margin-start;
      padding-right: $quote-padding-start;
    }

    ul {
      list-style: disc;
      ul {
        list-style: circle;
        ul {
          list-style: square;
          ul {
            list-style: disc;
          }
        }
      }
    }
    ol {
      list-style: decimal;
      ol {
        list-style: lower-alpha;
        ol {
          list-style: lower-roman;
          ol {
            list-style: decimal;
          }
        }
      }
    }
    li {
      margin-left: 1rem;
    }

    [dir=rtl] li {
      margin-right: 1rem;
    }

    pre {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      font-family: monospace;
      font-size: 0.9rem;
      padding: 0.5rem;
      white-space: pre;
      background-color: #eee;
      overflow-x: auto;
    }

    img {
      border-radius: ${({theme}) => theme.window.borderRadius};
      max-width: 100%;
      width: auto;
      max-height: 20rem;
      height: auto;
    }

    .attachment {
      display: inline-block;
      position: relative;
      max-width: 100%;

      a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:visited:hover {
          color: inherit;
        }
      }
    }

    .attachment__caption {
      text-align: center;

      .attachment__name + .attachment__size {
        &::before {
          content: ' \\2022 ';
        }
      }
    }

    .attachment--preview {
      width: 100%;
      text-align: center;

      .attachment__caption {
        color: #666;
        font-size: 0.9rem;
      }
    }

    .attachment--file {
      color: #333;
      line-height: 1;
      margin: 0 2px 2px 2px;
      padding: 0.4rem 1rem;
      border: 1px solid #bbb;
      border-radius: 5px;
    }

    .attachment-gallery {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .attachment {
        flex: 1 0 33%;
        padding: 0 0.5rem;
        max-width: 33%;
      }

      &.attachment-gallery--2,
      &.attachment-gallery--4 {
        .attachment {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
  }

  .muse-video-player {
    border-radius: 1rem;
    overflow: hidden;
  }

  .tooltip-wrapper {
    text-align: left;
    max-width: 25rem;
    z-index: 25000;
    white-space: normal;
  }
`;

