export const MSG_yourProfileHeader = {
  id: "f04980c1-9df3-4df6-ace3-a57fd4b93f70",
  description: "A heading over the user's profile page",
  defaultMessage: "Your Profile"
};

export const MSG_otherUsersHeader = {
  id: "2bffee67-677f-4a78-806b-93f441b62ba1",
  description: "A heading over the other users' profile pages",
  defaultMessage: "Other Users"
};

export const MSG_deactivatedUsersHeader = {
  id: "589d1125-a7bd-4fb6-9876-19377d039293",
  description: "A heading over the deactivated users' profile pages.  There are users who are not permitted to log in.",
  defaultMessage: "Deactivated Users"
};


export const MSG_inviteAnotherUserButton = {
  id: "b2b07d7b-6584-498f-ba5d-8781993dcc3f",
  description: "A button to send an invitation another user to join the account",
  defaultMessage: "Invite Another User"
};

export const MSG_roleLabel = {
  id: "208344dc-ee87-4f4f-b3a7-fd2418b8791a",
  description: "The label for the section where the user's role (which controls their permissions and access level) is displayed.",
  defaultMessage: "Role"
};


export const MSG_ownerLabel = {
  id: "71847d9e-8546-4f24-aac7-2159af721652",
  description: "The name of a role for the person that owns this account.",
  defaultMessage: "Owner"
};

export const MSG_ownerExplanation = {
  id: "819ca646-55a0-42dd-bdaf-fcdc2e8bd96b",
  description: "",
  defaultMessage: "Owners are able to manage everything in the account, including settings, billing details, and budgets."
};

export const MSG_stewardLabel = {
  id: "c40156b8-307e-44bd-83d7-a26f97303bb2",
  description: "The name of a role for the person who manages the budgets.  This user role is not able to change settings or manage the subscription, but can update anything related tot he budgets.",
  defaultMessage: "Steward"
};

export const MSG_stewardExplanation = {
  id: "5b7f8f8f-d917-424a-95cc-0a196b74fd2d",
  description: "",
  defaultMessage: "Stewards are able to manage ledgers and budgets, but not settings, other users, or billing details."
};

export const MSG_observerLabel = {
  id: "f6a36deb-a0eb-4cee-b9c1-848af27e3e1b",
  description: "The name of a role for the person who can view the budgets, but not make any changes.",
  defaultMessage: "Observer"
};

export const MSG_observerExplanation = {
  id: "23ff5c42-d9e5-4174-bf76-678e96782d59",
  description: "",
  defaultMessage: "Observers are able to view everything, but not make any changes."
};

export const MSG_lastBrowserAccessLabel = {
  id: "06b61067-b6f4-4082-9573-1d05e259ee95",
  description: "A label to display the last time the user accessed the web app through a web browser.",
  defaultMessage: "Last browser access:"
};

export const MSG_lastMobileAccessLabel = {
  id: "fc18d8c4-9278-4f8e-88e3-b2b0be22e974",
  description: "A label to display the last time the user accessed the web app through a mobile app.",
  defaultMessage: "Last mobile access:"
};

export const MSG_neverLoginLabel = {
  id: "6e67299e-d5db-4dd3-855e-527a389fced7",
  description: "What to display when they have never logged in to the web or mobile app.  This is displayed after 'Last mobile access:' or 'Last web access:'",
  defaultMessage: "Never"
};

export const MSG_changePasswordButton = {
  id: "697e1eb7-93a7-4df2-b11e-1f151642fc16",
  description: "The button label to change the user's password.",
  defaultMessage: "Change Password"
};

export const MSG_disableButton = {
  id: "7f2696a5-6da1-45ac-9cf4-368d08c9c598",
  description: "The button to disable access to the user's account.",
  defaultMessage: "Disable"
};

export const MSG_profilePictureLabel = {
  id: "0ac8f3ed-5630-44cd-bc4c-40921331c073",
  description: "The label over where they can upload a profile picture.",
  defaultMessage: "Profile picture"
};

export const MSG_changeProfilePictureButton = {
  id: "ddf28b7f-9596-45a4-90d3-f96510905080",
  description: "The button to change the user's profile picture.",
  defaultMessage: "Change"
};

export const MSG_deactivateLabel = {
  id: "90b645ae-83c3-4905-af21-cff8aefe8e7a",
  description: "The term to describe a user that has been deactivated and can no longer access the account.",
  defaultMessage: "Deactivated"
};

export const MSG_deactivateButton = {
  id: "a8a7b219-7d55-49f3-bc8b-fb4badd9f53d",
  description: "The button to deactivate a user's access to the account.",
  defaultMessage: "Deactivate"
};

export const MSG_activateButton = {
  id: "f705eff3-374c-4079-844f-7270baf364dd",
  description: "The button to activate a user's access to the account.",
  defaultMessage: "Activate"
};

export const MSG_currentPasswordLabel = {
  id: "85b0648d-cc4c-4f7a-90bf-65ce879afa8a",
  description: "The label for the field to enter the current password.",
  defaultMessage: "Current password"
};

export const MSG_newPasswordLabel = {
  id: "cc19c54d-d8c6-4c92-ae33-33b81b0fec18",
  description: "The label for the field to enter the new password.",
  defaultMessage: "New password"
};

export const MSG_newPasswordConfirmationLabel = {
  id: "ef5f3e59-7c75-4ae8-91d0-7d1263decc21",
  description: "The label for the field to confirm the new password.",
  defaultMessage: "Confirm new password"
};

export const MSG_usedForLoginNotice = {
  id: "c85955da-4a90-48f3-93d3-93dce5f7d112",
  description: "",
  defaultMessage: "This is the email address used for log in."
};

export const MSG_sendInvitationButton = {
  id: "c89e6548-0283-442f-8877-8f291f5a8ccc",
  description: "The button to send an invitation to join the account.",
  defaultMessage: "Send Invitation"
};

export const MSG_resendInvitationButton = {
  id: "a50cc807-2b0b-4945-b8d6-c637d7cd4754",
  description: "The button to resend an invitation to join the account.",
  defaultMessage: "Resend Invitation"
};

export const MSG_invitedLabel = {
  id: "31d292e7-f5a7-4336-8b29-82287c72bbf4",
  description: "A label to indicate that the user has been invited to join the account.",
  defaultMessage: "Invited"
};

export const MSG_invitationSentAlert = {
  id: "8d0b3cf5-5e9f-4987-b245-fc305924fb58",
  description: "",
  defaultMessage: "An invitation has been emailed to {email}.  Please allow a few minutes for it to arrive."
};

export const MSG_invitationHeader = {
  id: "e4c2d5b8-b6d8-4c66-b6e9-2f64aad3b063",
  description: "",
  defaultMessage: "Invitation"
};

export const MSG_invitationExplanation = {
  id: "365e47f6-8d2f-4c41-9e73-248af3649be4",
  description: "",
  defaultMessage: "{name} has invited you to collaborate on their budget.  Please fill out the form below to accept the invitation."
};

export const MSG_someoneLabel = {
  id: "1c578683-f911-429d-b707-3917c0101d55",
  description: "A placeholder to use when a name is not available.",
  defaultMessage: "Someone"
};

export const MSG_acceptInvitationButton = {
  id: "f4a936f0-1a8e-469b-beff-a0c50b6c51c7",
  description: "The label for a button that accepts an invitation to join an account.",
  defaultMessage: "Accept Invitation"
};

export const MSG_chooseAPassword = {
  id: "5b17f982-15ff-420f-908f-fe905da0399a",
  description: "",
  defaultMessage: "Choose a password"
};

export const MSG_acceptConfirmation = {
  id: "5ec9ddbd-e9c9-4af2-94ea-1b4750fd8979",
  description: "",
  defaultMessage: "Your invitation has been accepted.  You can now log in using the email and password you just configured."
};

export const MSG_dragYourPhotoHere = {
  id: "3a1f78cd-8af2-439a-a4e3-e00d7869448a",
  description: "",
  defaultMessage: "Drag your profile photo here."
};

