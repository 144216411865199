import { baseApi } from "../base_api";
import { PlanChangePreview, Subscription, User } from "../../../utils/api_types";
import { fromApiPlanChangePreview, fromApiSubscription, fromApiUser } from "../../../utils/api_transformations";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation<{user: User}, {firstName: string, lastName: string, email: string, password: string, postCode?: string}>({
      query: (params) => {
        let built_params: any = {
          first_name: params.firstName,
          last_name: params.lastName,
          email: params.email,
          password: params.password,
          password_confirmation: params.password,
          expand: {users: ['api_key']}
        };
        // Only add the honeypot field if it's not empty
        if (params.postCode) {
          built_params['post_code'] = params.postCode;
        }

        return ({
          url: `/subscription`,
          method: 'POST',
          body: built_params,
        });
      },
      transformResponse: (response: any) => {
        return {
          user: fromApiUser(response.subscription.users[0]),
        }
      }
    }),

    updateSubscription: builder.mutation<Subscription, {dateFormat?: string, timeFormat?: string, numberFormat?: string, currencyDecimals?: number}>({
      query: (params) => {
        let built_params: any = {
          date_format: params.dateFormat,
          time_format: params.timeFormat,
          number_format: params.numberFormat,
          currency_decimals: params.currencyDecimals,
        };

        return ({
          url: `/subscription`,
          method: 'PUT',
          body: built_params,
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),

    previewPlanChange: builder.query<PlanChangePreview, {productKey: string | 'free'}>({
      query: (args) => {
        let params = new URLSearchParams();
        params.append('new_product_key', `${args.productKey}`);
        return ({
          url: `/subscription/preview_plan_change?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return fromApiPlanChangePreview(response);
      }
    }),

    setPaymentMethod: builder.mutation<Subscription, {paymentMethodId: string}>({
      query: (params) => {
        return ({
          url: `/subscription/set_payment_method`,
          method: 'POST',
          body: {
            payment_method_id: params.paymentMethodId,
          },
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),

    changePlan: builder.mutation<Subscription, {productKey: string | 'free'}>({
      query: (params) => {
        return ({
          url: `/subscription/change_plan`,
          method: 'POST',
          body: {
            new_product_key: params.productKey,
          },
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),

    cancelScheduledPlanChange: builder.mutation<Subscription, {}>({
      query: () => {
        return ({
          url: `/subscription/cancel_scheduled_plan_change`,
          method: 'POST',
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),

    cancelPlan: builder.mutation<Subscription, {}>({
      query: () => {
        return ({
          url: `/subscription/cancel_plan`,
          method: 'POST',
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),

    undoCancelPlan: builder.mutation<Subscription, {}>({
      query: () => {
        return ({
          url: `/subscription/undo_cancel_plan`,
          method: 'POST',
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),

    retryPayment: builder.mutation<Subscription, {}>({
      query: () => {
        return ({
          url: `/subscription/retry_payment`,
          method: 'POST',
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),

    forgetMe: builder.mutation<{}, {}>({
      query: () => {
        return ({
          url: `/subscription/forget_me`,
          method: 'POST',
        });
      },
      transformResponse: (response: any) => {
        return {};
      }
    }),

    detachGroup: builder.mutation<Subscription, void>({
      query: () => {
        return ({
          url: `/subscription/detach_group`,
          method: 'POST',
        });
      },
      transformResponse: (response: any) => {
        return fromApiSubscription(response['subscription']);
      }
    }),
  }),
  overrideExisting: false,
});

export const {
  usePreviewPlanChangeQuery, useLazyPreviewPlanChangeQuery,
  useChangePlanMutation,
  useCancelScheduledPlanChangeMutation,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useSetPaymentMethodMutation,
  useCancelPlanMutation,
  useUndoCancelPlanMutation,
  useRetryPaymentMutation,
  useForgetMeMutation,
  useDetachGroupMutation,
} = extendedApi;

