export const MSG_ledgerNameLabel = {
  id: "f18905c8-8f57-4c04-b127-b5fb959ff118",
  description: "A label for the name of a ledger.",
  defaultMessage: "Ledger name"
};

export const MSG_editLedgerButton = {
  id: "de9f618b-7d44-4d44-94b5-e3f28f5f9788",
  description: "A button that allows the user to edit the name and other settings of a ledger.",
  defaultMessage: "Edit Ledger"
};

export const MSG_deleteLedgerButton = {
  id: "25298cbd-1108-4651-be24-fc1da915fc41",
  description: "A button that allows the user to permanently delete a ledger, along with all of its accounts, envelopes, and transactions.  This action cannot be undone.",
  defaultMessage: "Delete Ledger"
};

export const MSG_newLedgerButton = {
  id: "b5502420-0ead-4856-9192-4207a7350478",
  description: "A button that allows the user to create a new ledger.",
  defaultMessage: "New Ledger"
};

export const MSG_deleteLedgerConfirmation = {
  id: "8868beb4-cc3d-481c-8b2d-fde722af00dd",
  description: "",
  defaultMessage: "Are you sure you want to delete this ledger?"
};

export const MSG_deleteLedgerConfirmationExplanation = {
  id: "9ee04df1-05ec-4fc2-b20b-bea3afa119c6",
  description: "",
  defaultMessage: "Deleting this ledger will permanently remove all of its envelopes, accounts, transactions, and settings.  Are you sure?"
};

export const MSG_combineLedgersButton = {
  id: "4b57aa72-0af1-4659-8e81-3ae4b71a7a6b",
  description: "The name of a button that allows the user to combine two ledgers into a single ledger, which will merge all of the accounts, envelopes, and transactions from both ledgers into a single ledger.",
  defaultMessage: "Combine Ledgers"
};

export const MSG_combineLedgersExplanation = {
  id: "41ecdd93-c6a0-4401-962c-f222e10698db",
  description: "",
  defaultMessage: "This will combine all of the accounts, envelopes, transactions, and budgets from other ledgers into this ledger."
};

export const MSG_combineLedgersWarning = {
  id: "d89227f3-ff62-4679-9582-3a54eacffb8a",
  description: "",
  defaultMessage: "Once ledgers are combined, they cannot be separated.  Are you sure you want to continue?"
};

export const MSG_currentLedgerTitle = {
  id: "5513b42b-6edd-4d1e-b16d-d4e1f563253c",
  description: "The title of the section that shows the name of the current ledger.  This section is displayed on the ledger settings page.",
  defaultMessage: "Current Ledger"
};

export const MSG_otherLedgersExplanation = {
  id: "df8536e1-f65b-4537-a2b6-6e6f349d6c10",
  description: "",
  defaultMessage: "Select the ledgers you want to combine into this one."
};

export const MSG_noOtherLedgers = {
  id: "0090ed59-70c1-47e0-8265-073b9fd70a8b",
  description: "",
  defaultMessage: "No other ledgers exist that can be combined into this one."
};
