import * as React from "react";
import { PropsWithChildren } from "react";
import { useIntlFormatters } from "shared/utils/formatters";
import { selectCurrentSubscription, selectCurrentUser } from "shared/state/store";
import { getFullName, hasSubscriptionProblems } from "shared/utils/helpers";
import { SubscriptionProblemsNotice } from "@web/components/shared/SubscriptionProblemsNotice";
import { MSG_loggedInAs, MSG_logoutButton } from "shared/strings/login";
import { ProfilePicture } from "@web/components/shared/ProfilePicture";
import { LinkButton } from "@web/components/LinkButton";
import styled, { useTheme } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLogout } from "shared/utils/hooks";

interface IProps {}

const AuthenticatedHeader: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const theme = useTheme();
  const logout = useLogout();
  const navigate = useNavigate();
  const subscription = useSelector(selectCurrentSubscription);
  const currentUser = useSelector(selectCurrentUser);

  if (!subscription) return null;

  const fullName = getFullName(currentUser);

  return (
    <React.Fragment>
      <div/>
      <Container className="mb-2">
        {hasSubscriptionProblems(subscription) &&
          <SubscriptionProblemsNotice/>}
        <div className="flex-row align-items-end justify-content-space-between">
          <div className="flex-row align-items-center">
            <LogoImage src={theme.brand.logoImg}/>
            {subscription.group && (
              <React.Fragment>
                <div className="ms-4 me-4">|</div>
                <GroupLogoImage src={subscription.group.logoUrl}/>
              </React.Fragment>
            )}
          </div>
          <div className="flex-row align-items-center">
            <div className="text-right">
              <div>
                <LogoutButton onClick={async () => {
                  await logout();
                  navigate('/app/login');
                }}>
                  {formatMessage(MSG_logoutButton)}
                </LogoutButton>
              </div>
              {fullName && (
                <div>
                  {formatMessage(MSG_loggedInAs, {name: fullName})}
                </div>
              )}
            </div>
            <div className="ms-3 me-2">
              <Link to="/app/settings/users">
                <ProfilePicture imageUrl={currentUser?.profilePictureUrl} size={38}/>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

const LogoImage = styled.img`
  display: block;
  height: 3.25rem;
`;

const GroupLogoImage = styled.img`
  display: block;
  height: 3.25rem;
`;

const Container = styled.div`
  color: ${({theme}) => theme.colors.backgroundTextColor };
`;

const LogoutButton = styled(LinkButton)`
  color: ${({theme}) => theme.colors.backgroundTextColor };
`;


export {AuthenticatedHeader};
