import { useIntlFormatters } from "shared/utils/formatters";
import { Button } from "@web/components/Button";
import { MSG_okButton } from "shared/strings/generic";
import React from "react";
import { Modal } from "@web/components/Modal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

interface IProps {
}

interface IAlertModal {
  show: (message: React.ReactNode | string, icon?: IconProp, options?: {error?: boolean}) => any;
  hide: () => any;
}

let lastId: number = 0;
function generateKey(): string {
  return `alert-modal-${++lastId}`;
}

const AlertModal = React.forwardRef<IAlertModal, IProps>((props: IProps, ref) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<React.ReactNode | string | null>(null);
  const [icon, setIcon] = React.useState<IconProp | null>(null);
  const [error, setError] = React.useState<boolean>(false);
  const {formatMessage} = useIntlFormatters();
  const keyRef = React.useRef<string>(generateKey());

  React.useImperativeHandle<any, IAlertModal>(ref, () => ({
    show: (message, icon?: IconProp, options?: {error?: boolean}) => {
      setMessage(message);
      setIcon(icon || null);
      setError(!!options?.error);
      setVisible(true);
      // Generate a new key to force the modal to have a new portal which will make
      // it so that the modal is always on top of other modals.
      keyRef.current = generateKey();
    },

    hide: () => {
      close();
    }
  }));

  const close = () => {
    setVisible(false);
  }

  return (
    <Modal
      key={keyRef.current}
      autoFocus
      isOpen={visible}
      defaultFormAction={close}
      onRequestClose={close}
      windowStyle={{width: icon ? '34rem' : '28rem'}}
      bodyStyle={{padding: '2rem'}}
      footer={
        <div className="text-right">
          <Button onClick={() => setVisible(false)}>
            {formatMessage(MSG_okButton)}
          </Button>
        </div>
      }>

      <div className={classnames("flex-row align-items-center justify-content-center", {'text-alert-red': error})}>
        {icon && <FontAwesomeIconStyled icon={icon} style={{marginRight: '2rem'}}/>}
        <div>{message}</div>
      </div>
    </Modal>
  );
});

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

export { AlertModal, IAlertModal };
