import { baseApi } from "../base_api";
import { BudgetedExpense, IncomeAllocation, Ledger } from "../../../utils/api_types";
import {
  fromApiLedger,
  toApiBudgetedExpense,
  toApiIncomeAllocation,
  toApiLedger
} from "../../../utils/api_transformations";

export const ledgersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLedgers: builder.query<Ledger[], void>({
      query: () => {
        let params = new URLSearchParams();
        params.append('expand[][accounts][]', 'unallocated_count');
        params.append('expand[][accounts][]', 'pending_reconcile_ids');
        params.append('expand[][accounts][]', 'pending_reconcile_amount');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        return ({
          url: `/ledgers?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return response.ledgers.map(fromApiLedger)
      }
    }),

    getLedger: builder.query<Ledger, string>({
      query: (id) => {
        let params = new URLSearchParams();
        params.append('expand[][accounts][]', 'unallocated_count');
        params.append('expand[][accounts][]', 'pending_reconcile_ids');
        params.append('expand[][accounts][]', 'pending_reconcile_amount');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        return ({
          url: `/ledgers/${id}?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return fromApiLedger(response.ledger);
      },
    }),

    updateLedger: builder.mutation<Ledger, {ledger: Partial<Ledger>}>({
      query: ({ledger}) => {
        let params = new URLSearchParams();
        params.append('expand[][accounts][]', 'unallocated_count');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        return ({
          url: `/ledgers/${ledger.id}?${params.toString()}`,
          method: 'PATCH',
          body: {ledger: toApiLedger(ledger)},
        });
      },
      transformResponse: (response: any) => {
        return fromApiLedger(response.ledger);
      },
    }),

    createLedger: builder.mutation<Ledger, {ledger: Partial<Ledger>}>({
      query: ({ledger}) => {
        let params = new URLSearchParams();
        params.append('expand[][accounts][]', 'unallocated_count');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        return ({
          url: `/ledgers?${params.toString()}`,
          method: 'POST',
          body: {ledger: toApiLedger(ledger)},
        });
      },
      transformResponse: (response: any) => {
        return fromApiLedger(response.ledger);
      },
    }),

    deleteLedger: builder.mutation<{success: boolean}, {ledgerId: string}>({
      query: ({ledgerId}) => {
        let params = new URLSearchParams();
        params.append('expand[][accounts][]', 'unallocated_count');
        params.append('expand[]', 'unallocated_count');
        params.append('expand[]', 'envelopes');
        params.append('expand[]', 'income_sources');
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        return ({
          url: `/ledgers/${ledgerId}?${params.toString()}`,
          method: 'DELETE',
        });
      },
      transformResponse: (response: any) => {
        return {success: true};
      },
    }),

    updateBudgetForEnvelope: builder.mutation<{}, {ledgerId: string, envelopeId: string, incomeAllocations: Partial<IncomeAllocation>[], budgetedExpenses: Partial<BudgetedExpense>[]}>({
      query: ({ledgerId, envelopeId, incomeAllocations, budgetedExpenses}) => {
        let params = new URLSearchParams();
        params.append('expand[]', 'income_allocations');
        params.append('expand[]', 'budgeted_expenses');
        return ({
          url: `/ledgers/${ledgerId}/update_budget_for_envelope/${envelopeId}?${params.toString()}`,
          method: 'POST',
          body: {
            income_allocations: incomeAllocations.map(toApiIncomeAllocation),
            budgeted_expenses: budgetedExpenses.map(toApiBudgetedExpense),
          },
        });
      },
    }),

    combineLedgers: builder.mutation<Ledger, {ledgerId: string, combineWith: string[]}>({
      query: ({ledgerId, combineWith}) => {
        return ({
          url: `/ledgers/${ledgerId}/combine`,
          method: 'POST',
          body: {combine_with: combineWith},
        });
      },

      transformResponse: (response: any) => {
        return fromApiLedger(response.ledger);
      }
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLedgersQuery, useLazyGetLedgersQuery,
  useGetLedgerQuery, useLazyGetLedgerQuery,
  useUpdateLedgerMutation,
  useCreateLedgerMutation,
  useDeleteLedgerMutation,
  useUpdateBudgetForEnvelopeMutation,
  useCombineLedgersMutation,
} = ledgersApi;
