import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledCard = styled.div`
  border-radius: ${({theme}) => theme.window.borderRadius };
  border: 1px solid ${({theme}) => theme.colors.lightBorder };
  overflow: hidden;

  &.danger {
    background: ${({theme}) => theme.colors.danger};
    border-color: ${({theme}) => theme.colors.danger};
    color: #fff;
  }

  &.no-border {
    border: 0;
  }
`;

export const BorderlessStyledCard = styled(StyledCard)`
  border: none;
`;

export const StyledCardHeader = styled.div`
  background: ${({theme}) => theme.colors.lightBackgroundColor };
  padding: 0.5rem 1rem;
`;

export const StyledCardBody = styled.div`
  padding: 1rem;
  background-color: #fff;

  &.no-padding {
    padding: 0;
  }

  &:not(:first-child) {
    border-top: 1px solid ${({theme}) => theme.colors.lightBorder};
  }

  &.shaded {
    background: ${({theme}) => theme.colors.lightBackgroundColor};
  }
`;

export const StyledCardSettingsBody = styled(StyledCardBody)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
`;

export const StyledCardSettingsIcon = styled.div`
  font-size: 1.5rem;
  width: 2rem;
  margin-right: 1rem;
  text-align: center;
`;

export const StyledCardSettingsLabel = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

export const StyledCardSettingsValue = styled.div`
  flex-shrink: 1;
  text-align: right;
  margin-right: 1rem;
  color: ${({theme}) => theme.colors.mutedTextColor};
`;

export const StyledCardSettingsActions = styled.div`
  flex-shrink: 1;
  text-align: right;
`;

export const StyledCardNavLinkBody = styled(NavLink)`
  display: block;
  padding: 1rem;

  &.no-padding {
    padding: 0;
  }

  &:not(:first-child) {
    border-top: 1px solid ${({theme}) => theme.colors.lightBorder};
  }

  color: ${({theme}) => theme.colors.textColor};
  text-decoration: none;

  &:active, &:focus {
    outline: none;
  }
`;


export const StyledCardIcon = styled.div`
  font-size: 1.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
  text-align: center;
`;

