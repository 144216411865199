import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntlFormatters } from "shared/utils/formatters";
import { MSG_newEnvelopeButton } from "shared/strings/envelopes";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import { useUpdateEnvelopeMutation } from "shared/state/endpoints/app/envelopes_api";
import { parseApiError } from "shared/utils/api_errors";
import { MSG_unexpectedError } from "shared/strings/generic";
import { useDispatch, useSelector } from "react-redux";
import { BLANK_ENVELOPE, Envelope } from "shared/utils/api_types";
import { IEnvelopeEditorModal } from "../../modals/envelope_editor_modal/EnvelopeEditorModal";
import { useReloadLedger } from "shared/hooks/use_reload";
import { selectLedger, setEnvelope } from "shared/state/store";
import { useAlert } from "@web/utils/hooks";

interface IProps {
  ledgerId: string;
  id?: string;
  x: number,
  y: number,
  envelopeEditorModalRef: React.RefObject<IEnvelopeEditorModal>;
  onClick?: () => any;
}

const EnvelopeEmptySlot: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();
  const [updateEnvelope] = useUpdateEnvelopeMutation();
  const reloadLedger = useReloadLedger();
  const ledger = useSelector(selectLedger(props.ledgerId));
  const dispatch = useDispatch();
  const {showErrorAlert} = useAlert();
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: 'envelope',
    drop: async (envelopeItem: Envelope) => {
      if (!envelopeItem.id) return;
      try {
        // optimistically update the envelope with the new position
        dispatch(setEnvelope({
          ...envelopeItem,
          id: envelopeItem.id,
          ledgerId: props.ledgerId,
          appX: props.x,
          appY: props.y,
          archived: false
        }));

        // issue the network update
        await updateEnvelope({
          ledgerId: props.ledgerId,
          envelope: {id: envelopeItem.id, appX: props.x, appY: props.y, archived: false}
        }).unwrap();

        // update from the api
        await reloadLedger(props.ledgerId);
      } catch (e) {
        // there was an error, so roll back the optimistic update
        dispatch(setEnvelope(envelopeItem));

        // handle the error
        const err = parseApiError(e);
        if (err.errorType === 'models') {
          showErrorAlert(err.errors.envelope?.base[0] || formatMessage(MSG_unexpectedError));
        }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }), []);

  if (!ledger) return null;

  return (
    <React.Fragment>
      <Container className={classNames({'is-over': isOver})} ref={dropRef}
                 id={props.id}
                 onClick={() => {
                   if (props.onClick) {
                     props.onClick();
                     return;
                   }

                   props.envelopeEditorModalRef.current?.show({
                     ...BLANK_ENVELOPE, appX: props.x, appY: props.y
                   });
                 }}>
        <InnerContainer>
          <FontAwesomeIcon icon={faPlusCircle} className="me-1"/>
          <span className="label">{formatMessage(MSG_newEnvelopeButton)}</span>
        </InnerContainer>
      </Container>
    </React.Fragment>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${({theme}) => theme.raisedContainer.borderRadius };
  border: 1px dashed ${({theme}) => theme.colors.mutedTextColor };
  cursor: pointer;

  &.is-over {
    background: ${({theme}) => theme.colors.lightBackgroundColor };;
  }

  &:hover {
    .label {
      text-decoration: underline;
    }
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  color: ${({theme}) => theme.colors.mutedTextColor };
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export {EnvelopeEmptySlot};
