import { baseApi } from "../base_api";
import { fromApiHelpArticle, fromApiHelpGroup } from "../../../utils/api_transformations";
import { HelpArticle, HelpGroup } from "../../../utils/api_types";

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getHelpGroup: builder.query<HelpGroup, string>({
      query: (id) => `/help_groups/${id}`,
      transformResponse: (response: any) => {
        return fromApiHelpGroup(response['help_group'])
      }
    }),

    getHelpGroups: builder.query<HelpGroup[], void>({
      query: () => {
        let params = new URLSearchParams();
        return ({
          url: `/help_groups?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return response.items.map(fromApiHelpGroup)
      }
    }),

    getHelpArticle: builder.query<HelpArticle, string>({
      query: (id) => `/help_articles/${id}`,
      transformResponse: (response: any) => {
        return fromApiHelpArticle(response['help_article'])
      }
    }),

    getHelpArticles: builder.query<HelpArticle[], {helpGroupId: string}>({
      query: (args) => {
        let params = new URLSearchParams();
        params.append('help_group_id', args.helpGroupId);
        return ({
          url: `/help_articles?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return response.items.map(fromApiHelpArticle)
      },
    }),

    getHelpArticlesSearch: builder.query<HelpArticle[], string>({
      query: (query) => {
        let params = new URLSearchParams();
        params.append('query', query);
        params.append('expand[]', 'help_group');
        return ({
          url: `/help_articles/search?${params.toString()}`,
        });
      },
      transformResponse: (response: any) => {
        return response.items.map(fromApiHelpArticle)
      }
    }),
  }),
});

export const {
  useGetHelpGroupQuery,
  useGetHelpGroupsQuery,
  useGetHelpArticleQuery,
  useGetHelpArticlesQuery, useLazyGetHelpArticlesQuery,
  useGetHelpArticlesSearchQuery, useLazyGetHelpArticlesSearchQuery
} = extendedApi;
