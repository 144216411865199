import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import styled from "styled-components";

interface IProps {
  museId: string;
}

interface IVideoModal {
  show: () => any;
}

let nextId: number = 0;

const VideoModal = React.forwardRef<IVideoModal, IProps>((props: IProps, ref) => {
  const idRef = React.useRef<number>(nextId++);
  const [player, setPlayer] = React.useState<any>(null);
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (typeof MusePlayer !== 'undefined') {
      setPlayer(MusePlayer({
        container: document.getElementById(`video-player-${idRef.current}`),
        video: props.museId,
        autoplay: false,
        title: false,
        search: false,
        links: false,
        logo: false,
        share: false,
        loop: false,
        sizing: 'fit',
      }));
    } else {
      setPlayer(null);
    }
  }, []);

  React.useImperativeHandle<any, IVideoModal>(ref, () => ({
    show: () => {
      setVisible(true);
      if (player) {
        player.seek(0);
        setTimeout(() => player.play(), 500);
      } else {
        alert('Video player could not load.');
      }
    },
  }));

  return (
    <Container style={{display: visible ? 'block' : 'none'}}>
      <div id={`video-player-${idRef.current}`} style={{width: '80%', height: '80%', margin: 'auto', marginTop: '3rem'}}></div>

      <CloseButton onClick={() => {
        if (player) {
          player.pause();
        }
        setVisible(false);
      }}>
        <FontAwesomeIcon icon={faTimesCircle}/>
      </CloseButton>
    </Container>
  );
});

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999999;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 3rem;
  cursor: pointer;
`;

export { VideoModal, IVideoModal};

