import * as React from "react";
import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EnvelopeGrid } from "@web/components/shared/EnvelopeGrid";
import { EnvelopeBox } from "@web/components/shared/EnvelopeBox";
import { Button } from "@web/components/Button";
import { MSG_manageEnvelopesButton } from "shared/strings/envelopes";
import { useIntlFormatters } from "shared/utils/formatters";
import { IManageEnvelopesModal, ManageEnvelopesModal } from "@web/modals/manage_envelopes_modal/ManageEnvelopesModal";
import { EmptyEnvelopeListExplanation } from "@web/components/shared/EmptyEnvelopeListExplanation";
import { selectCurrentUser, selectEnvelopesForLedger, selectLedger } from "shared/state/store";
import styled from "styled-components";
import { User } from "shared/utils/api_types";
import { atLeastSteward } from "shared/utils/roles";

interface IProps {}

const EnvelopesPage: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgerId = useParams<{ledgerId: string}>().ledgerId!;
  const ledger = useSelector(selectLedger(ledgerId));
  const envelopes = useSelector(selectEnvelopesForLedger(ledgerId));
  const {formatMessage} = useIntlFormatters();
  const manageEnvelopesModalRef = React.useRef<IManageEnvelopesModal>(null);
  const currentUser = useSelector(selectCurrentUser) as User;
  const navigate = useNavigate();

  return (
    <Container>
      {envelopes.filter(e => !e.archived).length ? (
        <EnvelopeGrid
          ledgerId={ledgerId}
          renderEnvelope={env => (
            <EnvelopeBox
              envelope={env}
              onClick={() => {
                navigate(`/app/ledgers/${ledgerId}/transactions/all?eid=${env.id}`);
              }}
            />
          )}/>
      ) : (
        <EmptyEnvelopeListExplanation screen="envelopes"/>
      )}

      {atLeastSteward(currentUser) && (
        <div className="pt-4">
          <Button onClick={() => { manageEnvelopesModalRef.current?.show(); }}>
            {formatMessage(MSG_manageEnvelopesButton)}
          </Button>
        </div>)}

      <ManageEnvelopesModal ledgerId={ledger.id} ref={manageEnvelopesModalRef}/>
    </Container>
  );
};

const Container = styled.div`
`;

export {EnvelopesPage};
