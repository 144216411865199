import * as React from "react";
import { RefObject } from "react";
import { ITextInputProps, TextInput } from "./TextInput";
import { debounce, omit } from "lodash";
import styled from "styled-components";
import { Spinner } from "../Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ITypeAheadTextInput {
  inputRef: RefObject<HTMLInputElement>;
}

interface ITypeAheadTextInputProps<T> extends Omit<ITextInputProps, 'onChange' | 'value' | 'onSelect' | 'onSearch'> {
  value: string;
  onChange: (value: string) => any;
  onSearch: (query: string) => Promise<T[]>;
  onSelect: (value: T) => any;
  parseResult: (value: T) => {heading?: string, title: string, summaryHtml?: string, icon?: IconProp};
}

const TypeAheadTextInputProvider = <T extends unknown>() => {
  return React.forwardRef<ITypeAheadTextInput, ITypeAheadTextInputProps<T>>((props, ref) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<T[]>([]);
    const [searching, setSearching] = React.useState<boolean>(false);
    const [highlightedIndex, setHighlightedIndex] = React.useState<number>(-1);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      if (props.value) {
        setSearching(true);
        setVisible(true);
        search(props.value);
      } else {
        setSearching(false);
        setVisible(false);
        setResults([]);
      }
    }, [props.value]);

    const search = React.useCallback(debounce(async (query: string) => {
      setSearching(true);
      const results = await props.onSearch(query);
      setSearching(false);
      setResults(results);
    }, 500), []);

    const handleKeyDown = (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, results.length - 1));
          break;
        case 'ArrowUp':
          setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
          break;
        case 'Enter':
          if (highlightedIndex >= 0) {
            props.onSelect(results[highlightedIndex]);
            setVisible(false);
            event.preventDefault();
          }
          break;
        case 'Escape':
          setVisible(false);
          break;
        default:
          break;
      }
    };

    return (
      <Container>
        <TypeAheadTextInputStyled
          {...omit(props, ['onChange', 'value', 'onSearch', 'onSelect'])}
          ref={inputRef}
          className={props.className}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={() => setVisible(true)}
          onBlur={() => setVisible(false)}
          onKeyDown={handleKeyDown}
        />

        {visible && (searching || results.length > 0) && (
          <ResultsList>
            {searching && results.length === 0 && (
              <div className="text-left ms-4">
                <Spinner/>
              </div>)}
            {results.map((result, index) => {
              const item = props.parseResult(result);
              return (
                <ResultItem key={index}
                            highlighted={index === highlightedIndex}
                            onMouseDown={(e) => {
                              props.onSelect(result);
                              setVisible(false);
                            }}>
                  {item.icon && <ResultIcon><FontAwesomeIcon icon={item.icon}/></ResultIcon>}
                  <div className="flex-grow-1">
                    {item.heading && (
                      <ResultHeading>{item.heading}</ResultHeading>
                    )}
                    <ResultTitle>{item.title}</ResultTitle>
                    {item.summaryHtml && (
                      <ResultSummary dangerouslySetInnerHTML={{__html: item.summaryHtml}}/>
                    )}
                  </div>
                </ResultItem>
              );
            })}
          </ResultsList>
        )}

      </Container>
    );
  });
}

const Container = styled.div`
  position: relative;
  color: ${({theme}) => theme.colors.textColor};
`;

const TypeAheadTextInputStyled = styled(TextInput)`
`;

const ResultsList = styled.ul`
  position: absolute;
  list-style: none;
  margin: 0;
  margin-top: 0.25rem;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-style: solid;
  border-width: 1px;
  border-radius: ${({theme}) => theme.inputContainer.borderRadius};
  border-color: ${({theme}) => theme.colors.lightBorder};
  background-color: ${({theme}) => theme.inputContainer.backgroundColor};
  padding: 0.5rem;
  z-index: 100;
`;

const ResultItem = styled.li<{ highlighted: boolean }>`
  text-align: left;
  padding: 10px;
  background: ${({ highlighted }) => (highlighted ? '#f0f0f0' : 'white')};
  border-radius: ${({theme}) => theme.inputContainer.borderRadius};
  cursor: pointer;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background: ${({ highlighted }) => (highlighted ? '#f0f0f0' : '#f8f8f8')};
  }
`;

const ResultIcon = styled.div`
  margin-right: 1rem;
  font-size: 2rem;
`;

const ResultHeading = styled.div`
  font-size: 0.85rem;
  font-style: italic;
`;

const ResultTitle = styled.div`
  font-weight: bold;
`;

const ResultSummary = styled.div`
  font-size: 0.85rem;
  color: ${({theme}) => theme.colors.mutedTextColor};
  b {
    background-color: #fe8;
  }
`;

export {TypeAheadTextInputProvider, ITypeAheadTextInputProps, ITypeAheadTextInput};
