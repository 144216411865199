import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { PropsWithChildren } from "react";
import { Tooltip } from "react-tooltip";

interface IProps {
  text: string;
  place?: "top" | "bottom" | "left" | "right";
  className?: string;
  style?: React.CSSProperties;
}

let helpIdCoutner = 0;

const ContextHelp: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const idRef = React.useRef<string>(`context-help-${helpIdCoutner++}`);
  const idStr = `context-help-${idRef.current}`;

  return (
    <React.Fragment>
      <span data-tooltip-content={props.text}
            data-tooltip-id={idStr}
            className={props.className}
            style={props.style}>
        <FontAwesomeIcon icon={faQuestionCircle}/>
      </span>
      <Tooltip id={idStr} delayShow={100} place={props.place}
               className="tooltip-wrapper"/>
    </React.Fragment>
  );
};

export {ContextHelp};
