import * as React from "react";
import { PropsWithChildren } from "react";
import { EnvelopeGrid } from "@web/components/shared/EnvelopeGrid";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectIncomeSourcesForLedger } from "shared/state/store";
import { EnvelopeBudgetSummary } from "./EnvelopeBudgetSummary";
import { ICalculatedBudgetValues } from "shared/utils/interfaces";
import {
  EnvelopeBudgetEditorModal,
  IEnvelopeBudgetEditorModal
} from "@web/modals/envelope_budget_editor_modal/EnvelopeBudgetEditorModal";
import { getNumChecks } from "shared/utils/helpers";

interface IProps {
  envelopeValues: ICalculatedBudgetValues;
}

const BudgetEnvelopesGrid: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const ledgerId = useParams<{ledgerId: string}>().ledgerId as string;
  const envelopeBudgetEditorRef = React.useRef<IEnvelopeBudgetEditorModal>(null);
  const incomeSources = useSelector(selectIncomeSourcesForLedger(ledgerId));

  let numChecks = 1;
  incomeSources.forEach((incomeSource) => {
    numChecks = Math.max(numChecks, getNumChecks(incomeSource.frequency));
  });

  return (
    <React.Fragment>
      <EnvelopeGrid
        ledgerId={ledgerId}
        renderEnvelope={env => (
          <EnvelopeBudgetSummary
            envelope={env}
            fundingValues={props.envelopeValues[env.id].fundingSummary}
            expenseValues={props.envelopeValues[env.id].expensesSummary}
            onClick={() => {
              envelopeBudgetEditorRef.current?.show(env);
            }}
          />
        )}
      />
      <EnvelopeBudgetEditorModal
        ledgerId={ledgerId}
        numChecks={numChecks}
        ref={envelopeBudgetEditorRef}
      />
    </React.Fragment>
  );
};

export {BudgetEnvelopesGrid};
