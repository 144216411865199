import { ILocalStorageDriver } from "./interfaces";
import { generateRandomString } from "./helpers";

let sessionId = generateRandomString(32);

export let localStorageDriver: ILocalStorageDriver = {
  getItem: async (key: string) => null,
  setItem: async (key: string, value: string) => {},
  removeItem: async (key: string) => {},
};

export function setLocalStorageDriver(_storage: ILocalStorageDriver) {
  localStorageDriver = _storage;
}

export async function getCachedApiKey(): Promise<string | null> {
  return localStorageDriver.getItem('NB_API_KEY');
}

export async function getCachedSessionId(): Promise<string | null> {
  return sessionId;
}

export async function getCachedSupportSession(): Promise<boolean> {
  return await localStorageDriver.getItem('NB_SUPPORT_SESSION') === 'true';
}

export async function getCachedOpsApiKey(): Promise<string | null> {
  return await localStorageDriver.getItem('NB_ADMIN_API_KEY');
}

export async function getCachedGroupsApiKey(): Promise<string | null> {
  return await localStorageDriver.getItem('NB_GROUPS_API_KEY');
}
