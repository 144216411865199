import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import * as React from "react";
import { Label } from "@web/components/forms/Label";
import {
  MSG_billingCardCvcLabel,
  MSG_billingCardExpirationLabel,
  MSG_billingCardNumberLabel
} from "shared/strings/billing";
import { StyledCard, StyledCardBody } from "@web/components/styled/StyledCard";
import { useIntlFormatters } from "shared/utils/formatters";
import styled, { useTheme } from "styled-components";
import { ApiError } from "shared/utils/api_types";
import { FormErrors } from "@web/components/forms/FormErrors";
import { useSetPaymentMethodMutation } from "shared/state/endpoints/app/subscriptions_api";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { opacify } from "color2k";
import classnames from "classnames";
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar";
import { setCurrentSubscription } from "shared/state/store";
import { useDispatch } from "react-redux";
import { MSG_unexpectedError } from "shared/strings/generic";
import { parseApiError } from "shared/utils/api_errors";

interface IProps {}

interface ICardForm  {
  save: () => Promise<any>;
  reset: () => any;
}

const CardForm = React.forwardRef<ICardForm, IProps>((props: IProps, ref) => {
  const {formatMessage} = useIntlFormatters();
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [apiError, setApiError] = React.useState<ApiError | null>(null);
  const [setPaymentMethod] = useSetPaymentMethodMutation();
  const [cardNumberFocused, setCardNumberFocused] = React.useState<boolean>(false);
  const [cardExpFocused, setCardExpFocused] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useImperativeHandle<any, ICardForm>(ref, () => ({
    reset: () => {
      setApiError(null);
    },

    save: async (): Promise<boolean> => {
      if (!stripe || !elements) return false;

      try {
        const pm = await (stripe as any).createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement),
        });

        if (pm.error) {
          throw {errorType: 'message', message: pm.error};
        }

        const subscription = await setPaymentMethod({paymentMethodId: pm.paymentMethod.id}).unwrap();
        dispatch(setCurrentSubscription(subscription));
        return true;
      } catch (e: any) {
        if (e.data?.error_type === 'message') {
          setApiError(parseApiError(e));
        } else if (e.errorType === 'message' && e.message?.message) {
          setApiError({errorType: 'message', message: e.message?.message});
        } else {
          setApiError({errorType: 'message', message: formatMessage(MSG_unexpectedError)});
        }
        return false;
      }
    },
  }));

  return (
    <div>
      {apiError && <FormErrors errors={apiError} className="mb-3"/>}

      <Label>{formatMessage(MSG_billingCardNumberLabel)}</Label>
      <StyledCard>
        <StyledCardBody style={{padding: '0.5rem'}} className="flex-row align-items-center justify-content-stretch">
          <LeftContainer className={classnames({focused: cardNumberFocused})}>
            <FontAwesomeIcon icon={faCreditCard}/>
          </LeftContainer>
          <div style={{flexGrow: 1}}>
            <CardNumberElement
              onReady={(el) => el.focus()}
              onFocus={() => setCardNumberFocused(true)}
              onBlur={() => setCardNumberFocused(false)}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: theme.colors.textColor,
                    '::placeholder': {color: theme.colors.mutedTextColor},
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>
        </StyledCardBody>
      </StyledCard>

      <div className="d-flex flex-row align-items-center justify-content-start mt-4">
        <div style={{width: 200}}>
          <Label>{formatMessage(MSG_billingCardExpirationLabel)}</Label>
          <StyledCard>
            <StyledCardBody style={{padding: '0.5rem'}} className="flex-row align-items-center justify-content-stretch">
              <LeftContainer className={classnames({focused: cardExpFocused})}>
                <FontAwesomeIcon icon={faCalendar}/>
              </LeftContainer>
              <div style={{flexGrow: 1}}>
                <CardExpiryElement
                  onFocus={() => setCardExpFocused(true)}
                  onBlur={() => setCardExpFocused(false)}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: theme.colors.textColor,
                        '::placeholder': {color: theme.colors.mutedTextColor},
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </StyledCardBody>
          </StyledCard>
        </div>
        <div className="ms-4" style={{width: 150}}>
          <Label>{formatMessage(MSG_billingCardCvcLabel)}</Label>
          <StyledCard>
            <StyledCardBody style={{padding: '0.5rem'}}>
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: theme.colors.textColor,
                      '::placeholder': {color: theme.colors.mutedTextColor},
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
            </StyledCardBody>
          </StyledCard>
        </div>
      </div>
    </div>
  );
});

const LeftContainer = styled.div`
  width: 1.5rem;
  color: ${({theme}) => opacify(theme.colors.textColor, -0.75)};
  &.focused {
    color: ${({theme}) => theme.colors.textColor};
  }
`;

export {CardForm, ICardForm};
