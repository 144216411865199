import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyledRaisedContainer } from "@web/components/styled/StyledRaisedContainer";
import arrowImg from "shared/images/drawn-arrow.png";
import cashEnvelopeImg from "shared/images/cash-envelope.jpg";
import { MSG_emptyManageEnvelopesMessage, MSG_emptyManageEnvelopesTitle } from "shared/strings/accounts";
import { useIntlFormatters } from "shared/utils/formatters";

interface IProps {}

const EmptyManageEnvelopesExplanation: React.FunctionComponent<IProps & PropsWithChildren> = (props) => {
  const {formatMessage} = useIntlFormatters();

  return (
    <React.Fragment>
      <ArrowImg src={arrowImg}/>

      <MessageContainer>
        <MessageContent>
          <div>
            <h3>{formatMessage(MSG_emptyManageEnvelopesTitle)}</h3>
            <p>{formatMessage(MSG_emptyManageEnvelopesMessage)}</p>
          </div>
          <CashEnvelopeImg src={cashEnvelopeImg}/>
        </MessageContent>
      </MessageContainer>
    </React.Fragment>
  );
};

const MessageContainer = styled(StyledRaisedContainer)`
  position: absolute;
  top: 9rem;
  left: 15rem;
  width: 50%;
  background: #fff;
  transform: rotate(2deg);
  box-shadow: 0 5px 15px #0006;
  margin-bottom: 1rem;

  h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const MessageContent = styled.div`
  position: relative;
  overflow: hidden;
  padding: 1.5rem 2rem 8rem;
`;

const ArrowImg = styled.img`
  position: absolute;
  top: 6rem;
  left: 8rem;
  width: 7rem;
  transform: scaleX(-1) scaleY(-1) rotate(-20deg);
`;

const CashEnvelopeImg = styled.img`
  position: absolute;
  width: 60%;
  left: 20%;
  right: 20%;
  bottom: -5rem;
`;

export {EmptyManageEnvelopesExplanation};
